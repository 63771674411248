import * as React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';

import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';

export const MOBILE_MENU_ITEM_HEIGHT = 41;

export const Button = styled(WrapperButton)`
  font-weight: 500;
  text-align: left;

  :hover {
    opacity: 0.5;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }
`;

export const MobileMenu = styled(Flex)`
  height: 100%;
  flex-direction: column;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
`;

export type IconStyleModifier =
  | { regular: boolean; }
  | { solid: boolean; }
  | { light: boolean; }
  | { thin: boolean; }
  | { duotone: boolean; };

type MobileNavButtonProps = {
  icon?: IconProps['icon'];
  onClick: any;
  testId?: string;
  iconStyleModifier?: IconStyleModifier;
  children: React.ReactNode;
};

export const MobileNavButton = ({ icon, onClick, children, testId, iconStyleModifier }: MobileNavButtonProps) => (
  <Box sx={{ borderTop: 'gray' }}>
    <Button onClick={onClick} px={3} height="40px" width="100%" data-test={testId}>
      <Flex alignItems="center">
        {icon && <Icon fontSize={4} icon={icon} mr={2} fixedWidth {...iconStyleModifier} />}
        {children}
      </Flex>
    </Button>
  </Box>
);
