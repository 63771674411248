export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const SortingAccessor = {
  creationDate: 'creationDate',
  issueDate: 'issueDate',
  firstStageDeadline: 'firstStageDeadline',
  finalDeadline: 'finalDeadline',
  bidDeadline: 'bidDeadline',
  automatedReference: 'automatedReference',
  requestName: 'requestName',
  templateName: 'templateName',
  updateDate: 'updateDate',
} as const;

export type Sorting = {
  accessor: string;
  direction: SortingDirection;
};
