import { useMemo } from 'react';
import { find } from 'lodash';
import { FlexProps } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelHeader, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { ContractProvider } from '@deepstream/ui/modules/Contracts/contract';
import { ContractHooksProvider } from '@deepstream/ui/modules/Contracts/ContractHooksProvider';
import { ContractTeamUserModal } from '@deepstream/ui/modules/Contracts/Team/ContractTeamUserModal';
import { AdminContractDetails } from './AdminContractDetails';
import { Page } from '../../Page';
import * as Title from '../../title';
import { useAdminContract } from './adminContract';
import { contractCompanyRoute } from '../../AppRouting';
import { CompanyLink } from '../../CompanyLink';
import { ContractLink } from './ContractLink';
import { AdminContractUsersTable } from './AdminContractUsersTable';

const PageTitle = ({ flexDirection, contractId, company }: {
  flexDirection?: FlexProps['flexDirection'];
  contractId: string;
  company: CompanyMinimized;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          mr={flexDirection === 'column' ? 0 : 2}
          size={flexDirection === 'column' ? 'large' : 'small'}
          company={company}
        />
      </CompanyLink>
      <ContractLink
        contractId={contractId}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Contract
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
        />
      </ContractLink>
    </Title.Container>
  );
};

const ContractCompanyPropertyList = ({ isRecipient }: { isRecipient: boolean }) => {
  const contractCompanyProperties = useMemo(
    () => ([
      { name: 'Role', value: isRecipient ? 'Recipient' : 'Sender' },
    ]),
    [isRecipient],
  );

  return (
    <PropertyList properties={contractCompanyProperties} />
  );
};

export const AdminContractCompanyPage = () => {
  const { contractId, companyId } = contractCompanyRoute.useParams();
  const [ref, inView, entry] = useInView();
  const { isExtraSmall } = useDeviceSize();
  const addUserModal = useModalState();

  if (!contractId) {
    throw new Error('A contractId is required');
  }

  const { data: contract, error, status } = useAdminContract(contractId);

  const sender = find(contract?.senders, { _id: companyId });
  const recipient = find(contract?.recipients, { _id: companyId });
  const company = sender || recipient;
  const isRecipient = !sender;

  return (
    <ContractHooksProvider>
      <Page
        subHeading={contract && company && entry && !inView && (
          <ContractProvider contract={contract}>
            <PageTitle contractId={contractId} company={company} />
          </ContractProvider>
        )}
      >
        {status === 'loading' ? (
          <Panel>
            <PanelText><Loading /></PanelText>
          </Panel>
        ) : status === 'error' ? (
          <Panel heading="Error">
            <PanelText color="danger">
              <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
            </PanelText>
          </Panel>
        ) : (contract && company) ? (
          <ContractProvider contract={contract}>
            <div ref={ref}>
              <PageTitle
                flexDirection="column"
                contractId={contractId}
                company={company}
              />
            </div>

            <Panel heading="General" mb={4}>
              <ContractCompanyPropertyList isRecipient={isRecipient} />
            </Panel>

            <Panel mb={4}>
              <PanelHeader heading="Team">
                <Button
                  ml={isExtraSmall ? 3 : '24px'}
                  variant="primary"
                  iconLeft="plus"
                  onClick={addUserModal.open}
                  small
                >
                  Add user
                </Button>
              </PanelHeader>
              <PanelDivider />
              <AdminContractUsersTable />
              {addUserModal.isOpen && (
                <ContractTeamUserModal
                  close={addUserModal.close}
                  companyId={companyId}
                />
              )}
            </Panel>

            <Panel heading="Details" mb={4}>
              <AdminContractDetails />
            </Panel>
          </ContractProvider>
        ) : null}
      </Page>
    </ContractHooksProvider>
  );
};
