import { ExchangeProvider, RfxOtherSection } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { ContextType, useHooks } from '@deepstream/ui/useHooks';
import { AdminDocumentsTable } from './AdminDocumentsTable';
import { AdminContractDocumentsTable } from './modules/Contracts/AdminContractDocumentsTable';

const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const TableWrapper = styled.div<any>`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: ${props => props.isLast ? '-16px' : '0px'};
`;

export const AdminDocumentSection = ({ isLast }: { isLast?: boolean }) => {
  const { contextType, useSection } = useHooks();
  const { exchangeDefIds, providedBy } = useSection<RfxOtherSection>();

  return (
    <>
      <Subtext mb={exchangeDefIds && exchangeDefIds.length ? 3 : 2}>
        Additional information?
        <Icon
          icon={providedBy === ExchangeProvider.BOTH ? 'check' : 'times'}
          style={{ marginLeft: 10 }}
        />
      </Subtext>
      {exchangeDefIds && exchangeDefIds.length ? (
        <TableWrapper isLast={isLast}>
          <PanelDivider />
          {contextType === ContextType.CONTRACT ? (
            <AdminContractDocumentsTable exchangeDefIds={exchangeDefIds} />
          ) : (
            <AdminDocumentsTable exchangeDefIds={exchangeDefIds} />
          )}
          {!isLast && <PanelDivider />}
        </TableWrapper>
      ) : (
        <Subtext>No documents yet</Subtext>
      )}
    </>
  );
};
