import { BidStatus, UniversalBidStatus, PageStatus, StageApprovalStatus, ApprovalResponseStatus, RfqStatus } from './types';

export interface StatusIconConfig {
  value: string; // fontAwesome 5 used in react component
  color?: string; // color name defined in @deepstream/ui/src/theme.ts
  isRegular?: boolean;
  isSharp?: boolean;
}

export interface StatusConfig<TStatus extends BidStatus | UniversalBidStatus | PageStatus | StageApprovalStatus | ApprovalResponseStatus> {
  status: TStatus;
  badgeClass?: string;
  isDeprecated?: boolean;
  icon?: StatusIconConfig;
}

export interface StatusesConfig<
  TStatus extends BidStatus | UniversalBidStatus | PageStatus | StageApprovalStatus | ApprovalResponseStatus,
> {
  [key: string]: StatusConfig<TStatus>;
}

export const pageStatusesConfig: StatusesConfig<PageStatus> = {
  [PageStatus.COMPLETE]: {
    // 'Complete'
    status: PageStatus.COMPLETE,
    icon: {
      color: 'success',
      value: 'check-circle',
    },
  },
  [PageStatus.WAITING]: {
    // 'Incomplete'
    status: PageStatus.WAITING,
    icon: {
      color: 'subtext',
      value: 'circle-dashed',
    },
  },
  [PageStatus.REVIEW]: {
    // 'Action Required', not user-specific
    status: PageStatus.REVIEW,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
  [PageStatus.EMPTY]: {
    // undefined
    status: PageStatus.EMPTY,
  },
  [PageStatus.ACTION_REQUIRED]: {
    // 'Action Required', user-specific: current user can respond
    status: PageStatus.REVIEW,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
  [PageStatus.WAITING_FOR_TEAM]: {
    // 'Waiting for team', user-specific: current user cannot respond
    status: PageStatus.REVIEW,
    icon: {
      value: 'spinner',
      color: 'subtext',
    },
  },
};

export const bidStatusesConfig: StatusesConfig<BidStatus> = {
  [BidStatus.DELIVERED]: {
    // 'Delivered'
    status: BidStatus.DELIVERED,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.BLOCKED]: {
    // 'Blocked'
    status: BidStatus.BLOCKED,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.BOUNCED]: {
    // 'Bounced'
    status: BidStatus.BOUNCED,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.OPENED]: {
    // 'Opened'
    status: BidStatus.OPENED,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.WITHDRAWN]: {
    // 'Withdrawn'
    status: BidStatus.WITHDRAWN,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [BidStatus.NO_RESPONSE]: {
    // 'No response'
    status: BidStatus.NO_RESPONSE,
    badgeClass: 'badge-warning',
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [BidStatus.INTERESTED]: {
    // 'Interested'
    status: BidStatus.INTERESTED,
    badgeClass: 'badge-primary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.NO_INTEND_TO_BID]: {
    // 'Declined to bid'
    status: BidStatus.NO_INTEND_TO_BID,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [BidStatus.INTEND_TO_BID]: {
    // 'In progress'
    status: BidStatus.INTEND_TO_BID,
    badgeClass: 'badge-primary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.IN_PROGRESS]: {
    // 'In progress'
    status: BidStatus.IN_PROGRESS,
    badgeClass: 'badge-primary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.COMPLETE]: {
    // 'Complete'
    status: BidStatus.COMPLETE,
    badgeClass: 'badge-primary',
    icon: {
      color: 'success',
      value: 'check-circle',
    },
  },
  [BidStatus.BID_SUBMITTED]: {
    // 'Bid submitted'
    status: BidStatus.BID_SUBMITTED,
    badgeClass: 'badge-primary',
    isDeprecated: true,
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [BidStatus.AWARDED]: {
    // 'Awarded'
    status: BidStatus.AWARDED,
    badgeClass: 'badge-success',
    icon: {
      color: 'warning',
      value: 'trophy',
    },
  },
  [BidStatus.UNSUCCESSFUL]: {
    // 'Unsuccessful'
    status: BidStatus.UNSUCCESSFUL,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [BidStatus.BID_DEADLINE_MISSED]: {
    // 'Bid deadline missed'
    status: BidStatus.BID_DEADLINE_MISSED,
    badgeClass: 'badge-danger',
    isDeprecated: true,
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [BidStatus.WAITING_FOR_BUYER]: {
    // 'Waiting for buyer'
    status: BidStatus.WAITING_FOR_BUYER,
    icon: {
      value: 'spinner',
      color: 'subtext',
    },
  },
};

// override the icons and labels for supplier view
export const supplierBidStatuses: StatusesConfig<BidStatus> = {
  ...bidStatusesConfig,
  [BidStatus.NO_RESPONSE]: {
    // 'No response'
    status: BidStatus.NO_RESPONSE,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
  [BidStatus.INTEND_TO_BID]: {
    // 'Incomplete'
    status: BidStatus.INTEND_TO_BID,
    icon: {
      color: 'warning',
      value: 'exclamation-triangle',
    },
  },
  [BidStatus.IN_PROGRESS]: {
    // 'Incomplete'
    status: BidStatus.IN_PROGRESS,
    icon: {
      color: 'warning',
      value: 'exclamation-triangle',
    },
  },
};

export const universalBidStatusesConfig: StatusesConfig<UniversalBidStatus> = {
  [UniversalBidStatus.DELIVERED]: {
    // 'Delivered'
    status: UniversalBidStatus.DELIVERED,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.BLOCKED]: {
    // 'Blocked'
    status: UniversalBidStatus.BLOCKED,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.BOUNCED]: {
    // 'Bounced'
    status: UniversalBidStatus.BOUNCED,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.OPENED]: {
    // 'Opened'
    status: UniversalBidStatus.OPENED,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.WITHDRAWN]: {
    // 'Withdrawn'
    status: UniversalBidStatus.WITHDRAWN,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [UniversalBidStatus.NO_RESPONSE]: {
    // 'No response'
    status: UniversalBidStatus.NO_RESPONSE,
    badgeClass: 'badge-warning',
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [UniversalBidStatus.INTERESTED]: {
    // 'Interested'
    status: UniversalBidStatus.INTERESTED,
    badgeClass: 'badge-primary',
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.NO_INTEND_TO_BID]: {
    // 'Declined to bid'
    status: UniversalBidStatus.NO_INTEND_TO_BID,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [UniversalBidStatus.ACTIVE]: {
    // 'Active'
    status: UniversalBidStatus.ACTIVE,
    badgeClass: 'badge-primary',
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [UniversalBidStatus.BID_SUBMITTED]: {
    // 'Bid submitted'
    status: UniversalBidStatus.BID_SUBMITTED,
    badgeClass: 'badge-primary',
    isDeprecated: true,
    icon: {
      color: 'subtext',
      value: 'spinner',
    },
  },
  [UniversalBidStatus.AWARDED]: {
    // 'Awarded'
    status: UniversalBidStatus.AWARDED,
    badgeClass: 'badge-success',
    icon: {
      color: 'warning',
      value: 'trophy',
    },
  },
  [UniversalBidStatus.UNSUCCESSFUL]: {
    // 'Unsuccessful'
    status: UniversalBidStatus.UNSUCCESSFUL,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [UniversalBidStatus.BID_DEADLINE_MISSED]: {
    // 'Bid deadline missed'
    status: UniversalBidStatus.BID_DEADLINE_MISSED,
    badgeClass: 'badge-danger',
    isDeprecated: true,
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
};

// override the icons and labels for supplier view
export const universalSupplierBidStatuses: StatusesConfig<UniversalBidStatus> = {
  ...universalBidStatusesConfig,
  [UniversalBidStatus.NO_RESPONSE]: {
    // 'No response'
    status: UniversalBidStatus.NO_RESPONSE,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
};

export const stageApprovalStatuses: StatusesConfig<StageApprovalStatus> = {
  [StageApprovalStatus.PENDING]: {
    status: StageApprovalStatus.PENDING,
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [StageApprovalStatus.COMPLETE]: {
    status: StageApprovalStatus.COMPLETE,
    icon: {
      color: 'primary',
      isRegular: true,
      value: 'check-circle',
    },
  },
  [StageApprovalStatus.CANCELLED]: {
    status: StageApprovalStatus.CANCELLED,
    icon: {
      color: 'subtext',
      value: 'xmark',
      isSharp: true,
    },
  },
};

export const approvalResponseStatus: StatusesConfig<ApprovalResponseStatus> = {
  [ApprovalResponseStatus.PENDING]: {
    status: ApprovalResponseStatus.PENDING,
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [ApprovalResponseStatus.APPROVED]: {
    status: ApprovalResponseStatus.APPROVED,
    icon: {
      color: 'success',
      isRegular: true,
      value: 'check-circle',
    },
  },
  [ApprovalResponseStatus.REJECTED]: {
    status: ApprovalResponseStatus.REJECTED,
    icon: {
      color: 'danger',
      value: 'circle-xmark',
      isRegular: true,
    },
  },
};

export const requestStatusesConfig = {
  [RfqStatus.AWARDED]: {
    status: RfqStatus.AWARDED,
    icon: {
      value: 'trophy',
      color: 'warning',
    },
  },
  [RfqStatus.LIVE]: {
    status: RfqStatus.LIVE,
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [RfqStatus.CLOSED]: {
    status: RfqStatus.CLOSED,
    icon: {
      value: 'times',
      color: 'subtext',
    },
  },
  [RfqStatus.DRAFT]: {
    status: RfqStatus.DRAFT,
    icon: {
      value: 'pencil',
      color: 'subtext',
    },
  },
  [RfqStatus.DELETED]: {
    status: RfqStatus.DELETED,
    icon: {
      value: 'trash',
      color: 'subtext',
    },
  },
  [RfqStatus.DEADLINES_PASSED]: {
    status: RfqStatus.DEADLINES_PASSED,
    icon: {
      value: 'clock-o',
      color: 'danger',
    },
  },
} as const;

export const LIVE_SENT_REQUESTS_STATUSES = [
  RfqStatus.LIVE,
  RfqStatus.DEADLINES_PASSED,
  RfqStatus.AWARDED,
  RfqStatus.CLOSED,
];
