import { SearchCompanies } from '@deepstream/ui/SearchCompanies';
import { Page } from './Page';
import { useAdminNavigation } from './AppRouting';

export const SearchPage = () => {
  const { navigateToCompany } = useAdminNavigation();

  return (
    <Page>
      <SearchCompanies navigateToCompany={companyId => navigateToCompany(companyId)} />
    </Page>
  );
};
