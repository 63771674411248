import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { RequestLinkCell } from './RequestLinkCell';
import { useAdminNavigation } from './AppRouting';

type TemplateRequestsTableProps = {
  requests: any[];
};

export const TemplateRequestsTable = ({ requests }: TemplateRequestsTableProps) => {
  const { t } = useTranslation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'autoReferenceNumber',
        Header: 'Ref.',
        accessor: 'sentDashboard.autoReferenceNumber',
        width: 80,
      },
      {
        Header: 'Request name',
        accessor: 'receivedDashboard.subject',
        Cell: nestCells(TruncateCell, RequestLinkCell),
        sortType: 'caseInsensitive',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => t(`request.status.${get(item, 'meta.extendedStatus')}`),
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: 'sentDashboard.bidDeadline',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 222 : 162,
      },
    ],
    [isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={requests}
        onRowClick={request => navigation.navigateToRequest(request._id)}
        isSortable
        hiddenColumns={!isExtraLarge && !isLarge ? ['status', 'deadline'] : []}
      />
    </BasicTableStyles>
  );
};
