import { Flex } from 'rebass/styled-components';
import DeepStreamLogo from '../DeepStreamLogo';
import { Loading } from './Loading';
import { ErrorMessage } from './ErrorMessage';

export function FullScreenLoading({ loadingText }: { loadingText?: string }) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" style={{ gap: 20 }}>
      <DeepStreamLogo variant="black" />
      <Loading color="black" loadingText={loadingText} />
    </Flex>
  );
}

export function FullScreenError({ error }: { error: string }) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" style={{ gap: 20 }}>
      <DeepStreamLogo variant="black" />
      <ErrorMessage fontSize={2} error={error} />
    </Flex>
  );
}
