import * as React from 'react';
import { Flex, Box, SxStyleProp } from 'rebass/styled-components';

import variantsMap from './SvgComponents';

export type PictogramVariantsType = keyof typeof variantsMap;

export const Pictogram = ({
  variant,
  label,
  labelSx = {},
  labelPosition = 'bottom',
}: {
  variant: PictogramVariantsType;
  label?: React.ReactNode;
  labelSx?: SxStyleProp;
  labelPosition?: 'bottom' | 'right';
}) => {
  const Component = variantsMap[variant];

  return (
    <Flex
      flexDirection={labelPosition === 'bottom' ? 'column' : 'row'}
      alignItems="center"
      justifyContent={labelPosition === 'bottom' ? 'center' : 'flex-start'}
      sx={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        gap: 3,
      }}
    >
      <Component />
      {label && (
        <Box
          sx={{
            fontWeight: 500,
            fontSize: 2,
            whiteSpace: 'pre-wrap',
            ...labelSx,
          }}
        >
          {label}
        </Box>
      )}
    </Flex>
  );
};
