import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';

type ExpandableListProps = {
  rows: any[];
  defaultNumVisibleRows?: number;
  renderRow: any;
  toggleButtonStyle?: any;
};

export const ExpandableList: React.FC<ExpandableListProps> = ({
  rows,
  defaultNumVisibleRows = 10,
  renderRow,
  toggleButtonStyle = {},
}) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const visibleRows = isExpanded ? rows : rows.slice(0, defaultNumVisibleRows);

  return (
    <>
      {visibleRows.map(renderRow)}
      {rows.length > defaultNumVisibleRows && (
        <WrapperButton
          onClick={toggleExpanded}
          sx={{
            padding: '5px 10px',
            color: 'primary',
            width: '100%',
            textAlign: 'left',
            fontSize: 2,
            fontFamily: 'primary',
            fontWeight: 500,
            lineHeight: 1.3,
            ...toggleButtonStyle,
          }}
        >
          <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} mr={2} />
          {isExpanded ? t('seeLess') : t('seeMore')}
        </WrapperButton>
      )}
    </>
  );
};
