/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Icon } from '../../elements/icon/Icon';
import { WrapperButton } from '../../elements/button/WrapperButton';
import {
  SECTION_PADDING_TOP,
} from './constants';
import {
  TExpandableRowDataBase,
  TOriginalColumnDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  isExpandable,
  DataCellProps,
  GridDataAndCommands,
} from './utils';

const RenderDataCellDefaultBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
    data,
    columnIndex,
    rowIndex,
    style,
  }: {
  data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<any, any, any>>;
    FirstColumnCell: React.FunctionComponent<DataCellProps<any, any, any>>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, activeCellIndices } = data;

  if (rowIndex === 0 || row === null) {
    return null;
  }

  const isActive = Boolean(
    activeCellIndices &&
    activeCellIndices.rowIndex === rowIndex &&
    activeCellIndices.columnIndex === columnIndex,
  );

  const areSubRowsEmpty = isEmpty((row.original as any).subRows);

  if (columnIndex === 0) {
    const { top } = row;

    const { FirstColumnCell } = data;

    return isExpandable(row)
      ? (
        <div
          aria-rowindex={rowIndex + 1}
          aria-colindex={columnIndex + 1}
          role="gridcell"
          aria-expanded={!!row.isExpanded}
          className={clsx(
            'rowgroup-first',
            'column-first',
            (!row.isExpanded || areSubRowsEmpty) && 'rowgroup-last',
            row.original.rowType,
          )}
          style={{
            position: 'absolute',
            top: `${top + SECTION_PADDING_TOP}px`,
            height: row.height - SECTION_PADDING_TOP,
            left: `${data.bodyPaddingLeft}px`,
            width: column.width - data.bodyPaddingLeft,
            padding: 0,
          }}
        >
          {areSubRowsEmpty ? (
            null
          ) : (
            <WrapperButton
              aria-label={`${row.isExpanded ? 'Collapse' : 'Expand'} section`}
              sx={{
                height: '100%',
                minWidth: '32px',
                ':focus:not(:focus-visible)': {
                  outline: 0,
                  boxShadow: 'none',
                },
              }}
              onClick={() => {
                data.activateCellAndEnsureVisibility({ rowIndex });
                data.toggleCollapsedRowState(row.original._id, rowIndex);
              }}
            >
              <Icon
                icon={row.isExpanded ? 'caret-down' : 'caret-right'}
                color="text"
                fixedWidth
                fontSize={4}
              />
            </WrapperButton>
          )}
          <div
            style={{
              width: '100%',
              paddingRight: row.original.rowType ? undefined : '8px',
              paddingLeft: areSubRowsEmpty && !row.original.rowType ? '8px' : undefined,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <FirstColumnCell
              row={row}
              column={column}
              isActive={false}
              editedCell={null}
            />
          </div>
        </div>
      )
      : (
        <div
          id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
          aria-rowindex={rowIndex + 1}
          aria-colindex={columnIndex + 1}
          role="gridcell"
          className={clsx(
            'column-first',
            (row.isLast || areSubRowsEmpty) && 'rowgroup-last',
            row.original.isSubHeader && 'subheader',
            row.original.noPadding && 'no-padding',
          )}
          style={{
            position: 'absolute',
            top: `${top}px`,
            left: `${data.bodyPaddingLeft}px`,
            width: `${column.width - data.bodyPaddingLeft}px`,
            height: `${row.height}px`,
          }}
          onClick={() => {
            data.activateCellAndEnsureVisibility({ rowIndex, columnIndex });
          }}
        >
          <FirstColumnCell row={row} column={column} isActive={false} />
        </div>
      );
  }

  // TODO simplify!
  const rowTop = columnIndex < data.frozenLeftColumnCount
    ? row.top
    : parseFloat(style.top as string);

  return isExpandable(row)
    ? (
      <div
        id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
        aria-rowindex={rowIndex + 1}
        aria-colindex={columnIndex + 1}
        aria-expanded={!!row.isExpanded}
        role="gridcell"
        className={clsx(
          'rowgroup-first',
          (!row.isExpanded || areSubRowsEmpty) && 'rowgroup-last',
          column.isLast && 'column-last',
          isActive && 'active',
          row.original.rowType,
        )}
        style={{
          ...style,
          top: `${rowTop + SECTION_PADDING_TOP}px`,
          height: row.height - SECTION_PADDING_TOP,
        }}
        onClick={() => {
          data.activateCellAndEnsureVisibility({ rowIndex, columnIndex });
        }}
      >
        <DataCell row={row} column={column} isActive={isActive} />
      </div>
    )
    : (
      <div
        id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
        aria-rowindex={rowIndex + 1}
        aria-colindex={columnIndex + 1}
        role="gridcell"
        className={clsx(
          column.isLast && row.isLast && 'rowgroup-last column-last',
          isActive && 'active',
          row.original.isSubHeader && 'subheader',
          row.original.noPadding && 'no-padding',
        )}
        style={{
          ...style,
          top: `${rowTop}px`,
          height: isActive && !data.staticRowHeights ? undefined : style.height,
          minHeight: style.height,
          maxHeight: isActive ? row.height + 20 : undefined,
        }}
        onClick={() => {
          data.activateCellAndEnsureVisibility({ rowIndex, columnIndex });
        }}
      >
        <DataCell
          row={row}
          column={column}
          isActive={isActive}
          editedCell={data.editedCell}
        />
      </div>
    );
};

export const RenderDataCellDefault =
  React.memo(RenderDataCellDefaultBase) as typeof RenderDataCellDefaultBase;
