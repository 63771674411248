import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Text, FlexProps } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useConfirmDialog } from '@deepstream/ui/ui/useModalState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { ContractProvider } from '@deepstream/ui/modules/Contracts/contract';
import { ContractStatus } from '@deepstream/common/contract';
import { ContractHooksProvider } from '@deepstream/ui/modules/Contracts/ContractHooksProvider';
import { useAdminContractQueryKey } from '@deepstream/ui/modules/Contracts/useContract';
import { AdminContractDetails } from './AdminContractDetails';
import { AdminContractPropertyList } from './AdminContractPropertyList';
import { Page } from '../../Page';
import * as Title from '../../title';
import { useAdminContract } from './adminContract';
import { contractRoute, useAdminNavigation } from '../../AppRouting';
import { AdminContractSenderTable } from './AdminContractSenderTable';
import { AdminContractRecipientTable } from './AdminContractRecipientTable';

const CachePanel = ({ contractId }: { contractId: string }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();

  const [deleteCache, { isLoading }] = useMutation(
    () => adminApi.deleteContractCache({ contractId }),
    {
      onSuccess: () => toaster.success('Cache cleared successfully'),
      onError: () => toaster.success('Could not clear cache'),
    },
  );

  return (
    <Panel heading="Cache" padded mt={4}>
      <Button small variant="danger" disabled={isLoading} onClick={deleteCache}>
        Clear cache
      </Button>
    </Panel>
  );
};

export const PageTitle = ({
  flexDirection,
}: {
  flexDirection?: FlexProps['flexDirection'];
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Contract
      size={flexDirection === 'column' ? 'large' : 'small'}
    />
  </Title.Container>
);

export const AdminContractPage = () => {
  const adminApi = useAdminApi();
  const { contractId } = contractRoute.useParams();
  const navigation = useAdminNavigation();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const [ref, inView, entry] = useInView();

  if (!contractId) {
    throw new Error('A contractId is required');
  }

  const queryKey = useAdminContractQueryKey(contractId);
  const { data: contract, error, status } = useAdminContract(contractId);

  const [deleteContract] = useMutation(
    adminApi.deleteContract,
    {
      onSuccess: () => toaster.success('Contract successfully deleted'),
      onError: () => toaster.error('An error occurred when trying to delete the contract'),
      onSettled: () => queryClient.invalidateQueries(queryKey),
    },
  );

  const isDeleted = useMemo(
    () => contract?.status === ContractStatus.DELETED,
    [contract],
  );

  return (
    <ContractHooksProvider>
      <Page
        subHeading={contract && entry && !inView && (
          <ContractProvider contract={contract}>
            <PageTitle />
          </ContractProvider>
        )}
      >
        {status === 'loading' ? (
          <Panel>
            <PanelText><Loading /></PanelText>
          </Panel>
        ) : status === 'error' ? (
          <Panel heading="Error">
            <PanelText color="danger">
              <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
            </PanelText>
          </Panel>
        ) : contract ? (
          <ContractProvider contract={contract}>
            <div ref={ref}>
              <PageTitle
                flexDirection="column"
              />
            </div>

            <Panel heading="General" mb={4}>
              <AdminContractPropertyList />
            </Panel>

            <Panel heading="Sender" mb={4}>
              <AdminContractSenderTable
                onRowClick={(sender) => navigation.navigateToContractCompany(contractId, sender._id)}
              />
            </Panel>

            <Panel heading="Recipient" mb={4}>
              <AdminContractRecipientTable
                onRowClick={(recipient) => navigation.navigateToContractCompany(contractId, recipient._id)}
              />
            </Panel>

            <Panel heading="Details" mb={4}>
              <AdminContractDetails />
            </Panel>

            {!isDeleted && (
              <Panel heading="Delete contract" mt={4}>
                <PanelPadding>
                  <Text mb={3} fontSize={2}>
                    Contract will remain in database but will not be visible to any users.
                  </Text>
                  <Button
                    variant="danger"
                    onClick={() => confirm(() => deleteContract({ contractId }))}
                  >
                    Delete
                  </Button>
                </PanelPadding>
                <ConfirmDeleteDialog
                  heading="Delete contract?"
                  message="This cannot be undone"
                  {...dialogProps}
                />
              </Panel>
            )}
          </ContractProvider>
        ) : null}
        <CachePanel contractId={contractId} />
      </Page>
    </ContractHooksProvider>
  );
};
