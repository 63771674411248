import * as React from 'react';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';

export const TextCell: React.FC<CellProps<any>> = ({ cell, column, sx }) => {
  return (
    <Box sx={sx}>
      {cell.value?.toString() ?? (column as any).defaultValue}
    </Box>
  );
};
