import { compact, constant, isEmpty, noop, uniq, without } from 'lodash';
import * as React from 'react';

export enum GridSelectionState {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all',
}

export type GridSelectionContextValue = {
  isSelected: (rowId: string) => boolean;
  // select: (rowId: string) => void;
  // deselect: (rowId: string) => void;
  toggle: (rowId: string) => void;
  // setSelection: (rowIds: string[]) => void;
  getGridSelectionState: () => GridSelectionState;
  setGridSelectionState: (state: GridSelectionState.ALL | GridSelectionState.NONE) => void;
  selectedRowIds: string[];
  allRowIds: (string | null)[];
  setAllRowIds: (rowIds: (string | null)[]) => void;
};

const GridSelectionContext = React.createContext<GridSelectionContextValue>({
  isSelected: constant(false),
  // select: noop,
  // deselect: noop,
  toggle: noop,
  // setSelection: noop,
  getGridSelectionState: () => GridSelectionState.NONE,
  setGridSelectionState: noop,
  selectedRowIds: [],
  allRowIds: [],
  setAllRowIds: noop,
});

// TODO make generic; support range selection and rowId selection

const defaultToggleRow = (rowId: string, rowIds: string[]) =>
  rowIds.includes(rowId)
    ? without(rowIds, rowId)
    : uniq([...rowIds, rowId]);

export const GridSelectionProvider = ({
  initialRowIds,
  toggleRow = defaultToggleRow,
  children,
}: {
  initialRowIds: string[];
  toggleRow?: (rowId: string, rowIds: string[], allRowIds: (string | null)[]) => string[];
  children: React.ReactChild;
}) => {
  const [allRowIds, setAllRowIds] = React.useState<(string | null)[]>(initialRowIds);
  const [selectedRowIds, setSelectedRowIds] =
    React.useState<string[]>([]);

  const value = React.useMemo(() => {
    return {
      isSelected: (rowId: string) => selectedRowIds?.includes(rowId),
      // select: (rowId: string) => setSelectedRowIds(rowIds => uniq([...rowIds, rowId])),
      // deselect: (rowId: string) => setSelectedRowIds(rowIds => without(rowIds, rowId)),
      selectedRowIds,
      toggle: (rowId: string) => setSelectedRowIds(rowIds => toggleRow(rowId, rowIds, allRowIds)),
      getGridSelectionState: () => {
        if (isEmpty(selectedRowIds)) {
          return GridSelectionState.NONE;
        } else if (selectedRowIds.length === compact(allRowIds).length) {
          return GridSelectionState.ALL;
        } else {
          return GridSelectionState.SOME;
        }
      },
      setGridSelectionState: (state: GridSelectionState.ALL | GridSelectionState.NONE) => {
        if (state === GridSelectionState.ALL) {
          setSelectedRowIds(compact(allRowIds));
        } else {
          setSelectedRowIds([]);
        }
      },
      setAllRowIds,
      allRowIds,
    };
  }, [allRowIds, selectedRowIds, toggleRow]);

  return (
    <GridSelectionContext.Provider value={value}>
      {children}
    </GridSelectionContext.Provider>
  );
};

export const useGridSelection = () => {
  const state = React.useContext(GridSelectionContext);
  if (!state) throw new Error('No grid selection context found');
  return state;
};
