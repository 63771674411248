import * as React from 'react';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel } from '@deepstream/ui-kit/elements/Panel';

export type ErrorMessageProps = {
  error?: React.ReactNode | string | null | undefined;
  hide?: boolean;
  style?: any;
  fontSize?: IconTextProps['fontSize'];
  lineHeight?: IconTextProps['lineHeight'];
  gap?: IconTextProps['gap'];
  truncate?: IconTextProps['truncate'];
};

export const ErrorMessage = ({ hide, error, fontSize = 0, lineHeight, gap, style, truncate = false }: ErrorMessageProps) => (
  (!hide && error) ? (
    <IconText
      color="danger"
      icon="exclamation-circle"
      text={error}
      name={error?.toString()}
      truncate={truncate}
      fontSize={fontSize}
      style={style}
      fontWeight="normal"
      lineHeight={lineHeight}
      gap={gap}
    />
  ) : (
    null
  )
);

type ErrorPanelProps = {
  heading?: string;
  error: ErrorMessageProps['error'];
  style?: any;
};

export const ErrorPanel = ({ heading, error, style }: ErrorPanelProps) => (
  <Panel heading={heading} padded style={style}>
    <ErrorMessage error={error} fontSize={2} />
  </Panel>
);
