import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '@deepstream/ui/form/TextField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';

type EditPhoneNumberProps =
  { user: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditPhoneNumberModal: React.FC<EditPhoneNumberProps> = ({ user, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateUserPhoneNumber] = useMutation(
    adminApi.updateUserPhoneNumber,
    {
      onSuccess: () => toaster.success('Phone number updated successfully'),
      onError: () => toaster.error('An error occurred while updating the phone number'),
      onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          phoneNumber: user.phoneNumber,
        }}
        validationSchema={
          yup.object().shape({
            phoneNumber: yup.string().trim(),
          })
        }
        onSubmit={({ phoneNumber }) =>
          updateUserPhoneNumber({
            userId: user._id,
            phoneNumber,
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Edit phone number</ModalHeader>
            <ModalBody>
              <TextField name="phoneNumber" label="Phone number" inputType="tel" />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
