import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { CellProps } from 'react-table';
import { withProps } from '@deepstream/ui-utils/withProps';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink } from '../AdminTableLink';
import { CompanyLinkCell } from '../CompanyLinkCell';
import { UserLinkCell } from '../UserLinkCell';
import { reportsDiscoveryTransactionRoute } from '../AppRouting';

export type DiscoveryLogQuery = {
  companyId: string;
  userId: string;
  transactionId: string;
  promptStrategyVersion: string;
  duration: string;
  numberOfFeedbackEntries: number;
  numberOfResults: string,
  status: string,
  timestamp: Date;
};

export const TransactionIdCell = ({ cell }: CellProps<any>) => {
  return (
    <AdminTableLink
      to={reportsDiscoveryTransactionRoute.to}
      params={{ transactionId: cell.value }}
      onClick={stopPropagation}
    >
      {cell.value}
    </AdminTableLink>
  );
};

export const DiscoveryLogsQueryTable = ({ queries }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
        Cell: TransactionIdCell,
        width: 330,
      },
      {
        Header: 'Company ID',
        accessor: 'companyId',
        Cell: withProps(CompanyLinkCell, { getCompanyId: (row) => row.companyId }),
        width: 140,
      },
      {
        Header: 'User ID',
        accessor: 'userId',
        Cell: withProps(UserLinkCell, { getUserId: (row) => row.userId }),
        width: 240,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        width: 140,
      },
      {
        Header: '# Feedback',
        accessor: 'numberOfFeedbackEntries',
        width: 140,
      },
      {
        Header: '# Results',
        accessor: 'numberOfResults',
        width: 140,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 140,
      },
      {
        Header: 'Prompt',
        accessor: 'promptStrategyVersion',
        width: 180,
      },
      {
        id: 'timestamp',
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: 250,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={queries}
        isSortable
        noFilteredDataPlaceholder="No transactions found"
      />
    </BasicTableStyles>
  );
};
