import { Auth0Provider as Provider } from '@auth0/auth0-react';
import { useEnv } from './env';

export const Auth0Provider = ({ children }) => {
  const {
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE,
    AUTH0_REDIRECT_URL,
  } = useEnv();

  return (
    <Provider
      domain={AUTH0_DOMAIN!}
      clientId={AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: AUTH0_REDIRECT_URL ?? window.location.origin,
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email *:*',
      }}
      useRefreshTokens
      useRefreshTokensFallback
      // Auth0 cookies are namespaced by `clientId` so we can set all environments
      // to use the same top level cookie domain.
      cookieDomain=".deepstreamtech.com"
    >
      {children}
    </Provider>
  );
};
