import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Flex } from 'rebass/styled-components';

import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { IntegrationMarketplaceEventType } from '@deepstream/common';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { ControlledRadioButtonGroup } from '@deepstream/ui/ui/RadioButton';
import { wrap, useAdminApi } from '@deepstream/ui/api';

import { ProductDashboardFunnelMetric, ProductDashboardMetric, ProductDashboardSectionContainer, ProductDashboardSectionHeader } from './ProductDashboardUtils';
import { IntegrationsSelectedTable } from './IntegrationsSelectedTable';
import { SearchesTable } from './SearchesTable';
import { PageOpenedUsersModal } from './PageOpenedUsersModal';
import { IntegrationsSelectedUsersModal } from './IntegrationsSelectedUsersModal';
import { CTAClickedUsersModal } from './CTAClickedUsersModal';
import { LatestIntegrationExecutionsModal } from './LatestIntegrationExecutionsModal';

enum EntityType {
  USERS = 'users',
  COMPANIES = 'companies',
}

enum EventType {
  CTA_CLICKED = 'ctaClicked',
  SELECTED = 'selected',
}

const ConversionFunnel = ({ data }) => {
  const [value, setValue] = useState(EntityType.USERS);
  const pageOpenedUsersModal = useModalState();
  const integrationsSelectedUsersModal = useModalState();
  const ctaClickedUsersModal = useModalState();

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Conversion funnel"
          icon="bars-filter"
          right={(
            <ControlledRadioButtonGroup
              name="Conversion funnel filter"
              onChange={setValue}
              value={value}
              inputs={[
                { label: 'Users', value: 'users' },
                { label: 'Companies', value: 'companies' },
              ]}
            />
          )}
        />

        <ProductDashboardSectionContainer>
          <ProductDashboardFunnelMetric
            label="1. Page opened"
            mainValue={data[value][IntegrationMarketplaceEventType.PAGE_OPENED].total}
            proceededValues={{
              proceeded: data[value][IntegrationMarketplaceEventType.PAGE_OPENED].proceeded,
              notProceeded: data[value][IntegrationMarketplaceEventType.PAGE_OPENED].notProceeded,
            }}
            onViewMoreClick={pageOpenedUsersModal.open}
            tooltip="Counted when a user opens the “Integrations marketplace” page"
          />
          <ProductDashboardFunnelMetric
            label="2. Integrations selected"
            mainValue={data[value][IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED].total}
            proceededValues={{
              proceeded: data[value][IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED].proceeded,
              notProceeded: data[value][IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED].notProceeded,
            }}
            onViewMoreClick={integrationsSelectedUsersModal.open}
            tooltip="Counted when a user selects 1 or more integrations"
          />
          <ProductDashboardFunnelMetric
            label="3. CTA Clicked"
            mainValue={data[value][IntegrationMarketplaceEventType.SCHEDULE_CALL].total}
            totalValue={data[value][IntegrationMarketplaceEventType.PAGE_OPENED].total}
            onViewMoreClick={ctaClickedUsersModal.open}
            tooltip="Counted when a user clicks the CTA button"
          />
        </ProductDashboardSectionContainer>
      </Flex>
      {data?.users?.[IntegrationMarketplaceEventType.PAGE_OPENED]?.uniqueUsers && pageOpenedUsersModal.isOpen && (
        <PageOpenedUsersModal
          isOpen={pageOpenedUsersModal.isOpen}
          onOk={pageOpenedUsersModal.close}
          onCancel={pageOpenedUsersModal.close}
          uniqueUsers={data.users[IntegrationMarketplaceEventType.PAGE_OPENED].uniqueUsers}
          uniqueCompanies={data.companies[IntegrationMarketplaceEventType.PAGE_OPENED]}
        />
      )}
      {data?.users?.[IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED]?.uniqueUsers && integrationsSelectedUsersModal.isOpen && (
        <IntegrationsSelectedUsersModal
          isOpen={integrationsSelectedUsersModal.isOpen}
          onOk={integrationsSelectedUsersModal.close}
          onCancel={integrationsSelectedUsersModal.close}
          uniqueUsers={data.users[IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED].uniqueUsers}
          uniqueCompanies={data.companies[IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED]}
        />
      )}
      {data?.users?.[IntegrationMarketplaceEventType.SCHEDULE_CALL]?.uniqueUsers && ctaClickedUsersModal.isOpen && (
        <CTAClickedUsersModal
          isOpen={ctaClickedUsersModal.isOpen}
          onOk={ctaClickedUsersModal.close}
          onCancel={ctaClickedUsersModal.close}
          uniqueUsers={data.users[IntegrationMarketplaceEventType.SCHEDULE_CALL].uniqueUsers}
          uniquePageOpenedUsers={data.users[IntegrationMarketplaceEventType.PAGE_OPENED].uniqueUsers}
          uniqueCompanies={data.companies[IntegrationMarketplaceEventType.SCHEDULE_CALL]}
          uniquePageOpenedCompanies={data.companies[IntegrationMarketplaceEventType.PAGE_OPENED]}
        />
      )}
    </PanelPadding>
  );
};

const IntegrationsExecutions = () => {
  const adminApi = useAdminApi();
  const { data: companiesWithActiveIntegrations } = useQuery(
    ['companiesWithActiveIntegrations'],
    wrap(adminApi.getCompaniesWithActiveIntegrations),
  );
  const latestExecutionsModal = useModalState();

  const integrationExecutions = useMemo(() => companiesWithActiveIntegrations?.flatMap(company =>
    company.instancesWithLatestExecution.map(instance => ({
      ...instance,
      companyName: company.name,
    })),
  ), [companiesWithActiveIntegrations]);
  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Executions"
          icon="plug-circle-bolt"
          isIconRegular
        />
        <ProductDashboardSectionContainer>
          {companiesWithActiveIntegrations && (
            <ProductDashboardMetric
              value={companiesWithActiveIntegrations.length}
              label="Companies with active integrations"
              subValue="Latest executions"
              onViewMoreClick={companiesWithActiveIntegrations.length ? latestExecutionsModal.open : undefined}
            />
          )}
        </ProductDashboardSectionContainer>
      </Flex>
      {companiesWithActiveIntegrations && latestExecutionsModal.isOpen && (
        <LatestIntegrationExecutionsModal
          isOpen={latestExecutionsModal.isOpen}
          onOk={latestExecutionsModal.close}
          onCancel={latestExecutionsModal.close}
          integrationExecutions={integrationExecutions}
        />
      )}
    </PanelPadding>
  );
};

const IntegrationsSelected = ({ data }) => {
  const [value, setValue] = useState(EventType.CTA_CLICKED);

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Integrations selected"
          icon="plug"
          isIconRegular
          right={(
            <ControlledRadioButtonGroup
              name="Integrations selected filter"
              onChange={setValue}
              value={value}
              inputs={[
                { label: 'CTA Clicked', value: 'ctaClicked' },
                { label: 'Selected', value: 'selected' },
              ]}
            />
          )}
        />
        <IntegrationsSelectedTable integrationsSelected={data[value]} />
      </Flex>
    </PanelPadding>
  );
};

const Searches = ({ data }) => {
  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Searches"
          icon="search"
          isIconRegular
        />
        <SearchesTable searches={data} />
      </Flex>
    </PanelPadding>
  );
};

export const ProductDashboardIntegrationsTabContent = ({ status, data }) => {
  return (
    <>
      <Panel>
        <PanelHeader heading="Integration marketplace" />
        <PanelDivider />
        {status === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data?.integrations?.integrationMarketplaceActivity ? (
        <>
          <ConversionFunnel data={data.data.integrations.integrationMarketplaceActivity.conversionFunnel} />
          <IntegrationsSelected data={data.data.integrations.integrationMarketplaceActivity.integrationsSelected} />
          <Searches data={data.data.integrations.integrationMarketplaceActivity.searches} />
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : (
        null
      )}
      </Panel>
      <Panel>
        <PanelHeader heading="Integration executions" />
        <PanelDivider />
        <IntegrationsExecutions />
      </Panel>
    </>

  );
};
