import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { addRegionNames } from '@deepstream/common/region';
import { useTranslation } from 'react-i18next';
import { useApi } from './api';
import { useCurrentUserLocale } from './useCurrentUser';

export const useGetRegionTags = () => {
  const api = useApi();
  const locale = useCurrentUserLocale();
  const { t } = useTranslation('translation');

  return useCallback(async () => {
    const regionTags = await api.getRegionTags();

    return addRegionNames(regionTags, locale, t);
  }, [api, locale, t]);
};

export const useRegionTags = () => {
  const api = useApi();
  const locale = useCurrentUserLocale();
  const { t } = useTranslation('translation');

  const { data: regionTags } = useQuery(
    ['regionTags'],
    api.getRegionTags,
  );

  return useMemo(() => {
    if (!regionTags) {
      return;
    }

    return addRegionNames(regionTags, locale, t);
  }, [regionTags, locale, t]);
};
