import { forwardRef } from 'react';
import { Button as RebassButton, ButtonProps as RebassButtonProps } from 'rebass/styled-components';

export const WrapperButton = forwardRef<
  HTMLButtonElement,
  RebassButtonProps
>(({
  sx = {},
  ...props
}, ref) => (
  <RebassButton
    ref={ref}
    type="button"
    variant="wrapper"
    sx={{
      cursor: 'pointer',
      padding: 0,
      display: 'inline-block',
      ...sx,
    }}
    {...props}
  />
));
