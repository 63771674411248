import * as React from 'react';
import { upperFirst } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { nestCells } from '@deepstream/ui/nestCells';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CapitalizeCell } from './CapitalizeCell';
import { CompanyLinkCell } from './CompanyLinkCell';
import { UserLinkCell } from './UserLinkCell';

type InvitesTableProps = {
  invitesData: any[];
};

const getCompanyId = ({ company }) => company._id;

export const CompanyUserInvitesTable: React.FC<InvitesTableProps> = ({ invitesData }) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'company',
        Header: 'Company',
        accessor: 'company.name',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(UserLinkCell, nestCells(TruncateCell, ValueOrDashCell)),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: 'email',
        Cell: nestCells(TruncateCell, UserLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'invitedOn',
        Header: 'Invited on',
        accessor: 'invitedOn',
        Cell: DatetimeCell,
        onlyDate: true,
        sort: 'datetime',
        width: 164,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: CapitalizeCell,
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 90,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={invitesData}
        isSortable
        noFilteredDataPlaceholder="No requests match chosen filters"
      />
    </BasicTableStyles>
  );
};
