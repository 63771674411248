import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';

export const useAdminContractsReporting = (companyId, currency) => {
  const adminApi = useAdminApi();

  return useQuery(
    ['adminContractsReporting', { companyId, currency }],
    wrap(adminApi.getContractsReportingData),
  );
};
