import { useQuery } from 'react-query';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { TermsOfServiceStatus } from '@deepstream/common';
import { TermsOfServiceTable } from './TermsOfServiceTable';
import { TermsOfServiceModal } from './TermsOfServiceModal';

export const TermsOfServicePanel = () => {
  const api = useAdminApi();
  const modal = useModalState();

  const { data: termsOfService = [], isLoading, isError } = useQuery(
    ['termsOfService'],
    wrap(api.getTermsOfService),
  );

  const canAddNewVersion = !isLoading && !termsOfService.find(terms => terms.status === TermsOfServiceStatus.DRAFT);

  return (
    <Panel>
      <PanelHeader heading="Terms of Service">
        {canAddNewVersion && (
          <Button
            variant="primary"
            iconLeft="plus"
            small
            onClick={modal.open}
          >
            Add version {termsOfService.length + 1}
          </Button>
        )}
      </PanelHeader>
      <PanelDivider />
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error="An error occurred" />
        </PanelPadding>
      ) : (
        <TermsOfServiceTable termsOfService={termsOfService} />
      )}
      {modal.isOpen && (
        <TermsOfServiceModal
          version={termsOfService.length + 1}
          isOpen={modal.isOpen}
          onCancel={modal.close}
          onSave={modal.close}
        />
      )}
    </Panel>
  );
};
