import * as React from 'react';
import { Text, TextProps } from 'rebass/styled-components';
import { keyBy, noop, reverse, cloneDeep, sortBy } from 'lodash';
import styled, { ThemeContext } from 'styled-components';
import { transparentize } from 'polished';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { toTagTree, getAncestors } from './ui/StructuredTagPicker/utils';
import { ExpandableList } from './ExpandableList';

const Row = styled(Text)<{ isSelected? }>`
  font-size: ${props => props.theme.fontSizes[2]}px;
  height: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  cursor: ${props => props.isSelected ? 'default' : 'pointer'};
  text-transform: capitalize;

  &:hover:not(:focus) {
    background-color: ${props => props.isSelected ? 'initial' : transparentize(0.95, props.theme.colors.primary)};

    & > i {
      color: ${props => props.isSelected ? 'initial' : props.theme.colors.primary};
    }
  }

  &:focus {
    background-color: ${props => props.isSelected ? 'initial' : transparentize(0.85, props.theme.colors.primary)};
  }
`;

const getToggleButtonStyle = (theme, nestingLevel) => ({
  outline: 'none',
  paddingLeft: (nestingLevel + 1) * 20,

  ':hover:not(:focus)': {
    backgroundColor: transparentize(0.95, theme.colors.primary),
  },

  ':focus': {
    backgroundColor: transparentize(0.85, theme.colors.primary),
  },
});

type ListItemProps = TextProps & {
  onSelect?: any;
  nestingLevel: number;
  isSelected?: boolean;
  isAncestor?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({ onSelect = noop, nestingLevel, isSelected, isAncestor, children, ...props }) => {
  const onKeyDown = event => {
    if (event.key === 'Enter') {
      onSelect();
    }
  };

  return (
    <Row
      tabIndex={isSelected ? -1 : 0}
      onClick={onSelect}
      onKeyDown={onKeyDown}
      pl={(nestingLevel + 1) * 20}
      fontWeight={isAncestor || isSelected ? 500 : 'normal'}
      isSelected={isSelected}
      {...props}
    >
      {isAncestor && <Icon icon="chevron-left" width={12} color="subtext" mr={2} />}
      {children}
    </Row>
  );
};

type AncestorListItemsProps = {
  tag: any;
  selectTag: any;
};

const AncestorListItems: React.FC<AncestorListItemsProps> = ({ tag, selectTag }) => {
  const ancestors = React.useMemo(
    () => reverse(getAncestors(tag)),
    [tag],
  );

  return (
    <>
      <ListItem
        nestingLevel={0}
        onSelect={() => selectTag(undefined)}
        isAncestor
        style={{ textTransform: 'initial' }}
      >
        Any product or service
      </ListItem>
      {ancestors.map(tag => (
        <ListItem
          key={tag._id}
          nestingLevel={0}
          onSelect={() => selectTag(tag)}
          isAncestor
        >
          {tag.name}
        </ListItem>
      ))}
    </>
  );
};

type TieredFilterProps = {
  tags?: any[];
  defaultNumVisibleRows?: number;
  onSelect: (tagId: string) => void;
  selectedTagId: string;
};

export const TieredFilter: React.FC<TieredFilterProps> = ({
  tags,
  defaultNumVisibleRows = 10,
  onSelect,
  selectedTagId,
}) => {
  const theme = React.useContext(ThemeContext);

  const tagById = React.useMemo(
    () => cloneDeep(keyBy(tags, '_id')),
    [tags],
  );
  const tree = React.useMemo(
    () => sortBy(toTagTree(tagById), tag => tag.name.toLowerCase()),
    [tagById],
  );

  const selectedTag = tagById[selectedTagId];

  const selectTag = (tag) => {
    onSelect(tag ? tag._id : '');
  };

  const renderRow = nestingLevel => tag => (
    <ListItem
      key={tag._id}
      nestingLevel={nestingLevel}
      onSelect={() => selectTag(tag)}
    >
      {tag.name}
    </ListItem>
  );

  return (
    <>
      {selectedTag ? (
        <>
          <AncestorListItems
            tag={selectedTag}
            selectTag={selectTag}
          />
          <ListItem
            nestingLevel={1}
            isSelected
          >
            {selectedTag.name}
          </ListItem>
          {selectedTag.children && (
            <ExpandableList
              rows={selectedTag.children}
              defaultNumVisibleRows={defaultNumVisibleRows}
              renderRow={renderRow(2)}
              toggleButtonStyle={getToggleButtonStyle(theme, 2)}
            />
          )}
        </>
      ) : (
        <ExpandableList
          rows={tree}
          defaultNumVisibleRows={defaultNumVisibleRows}
          renderRow={renderRow(0)}
          toggleButtonStyle={getToggleButtonStyle(theme, 0)}
        />
      )}
    </>
  );
};
