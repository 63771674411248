import * as React from 'react';
import { CellProps } from 'react-table';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { TermsOfService, TermsOfServiceStatus } from '@deepstream/common';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { nestCells } from '@deepstream/ui/nestCells';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { NotApplicable } from '@deepstream/ui-kit/elements/text/NotApplicable';
import { DeleteMenuItem, DropdownMenu, DropdownMenuItem, EditMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useConfirmDialog, useModalState } from '@deepstream/ui/ui/useModalState';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useMutation } from '@deepstream/ui/useMutation';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useQueryClient } from 'react-query';
import { TermsOfServiceModal } from './TermsOfServiceModal';
import { TermsOfServiceAttachment } from './TermsOfServiceAttachment';
import { MakeTermsLiveModal } from './MakeTermsLiveModal';

const termsStatusConfig = {
  [TermsOfServiceStatus.DRAFT]: {
    icon: 'pencil',
    iconColor: 'subtext',
    label: 'Draft',
  },
  [TermsOfServiceStatus.LIVE]: {
    icon: 'circle',
    iconColor: 'success',
    label: 'Live',
  },
  [TermsOfServiceStatus.REPLACED]: {
    icon: 'arrow-turn-down',
    iconColor: 'secondary',
    label: 'Replaced',
  },
} as const;

const TermsFileCell = ({ row }: CellProps<TermsOfService>) => (
  <TermsOfServiceAttachment
    attachment={row.original.attachments[0]}
    version={row.original.version}
    truncate
    showTooltip
  />
);

const TermsStatusCell = ({ cell: { value } }: CellProps<TermsOfService, TermsOfServiceStatus>) => (
  <IconText
    icon={termsStatusConfig[value].icon}
    iconColor={termsStatusConfig[value].iconColor}
    text={termsStatusConfig[value].label}
  />
);

const TermsActionsCell = ({ row }: CellProps<TermsOfService>) => {
  const { confirm: confirmDelete, ...deleteDialogProps } = useConfirmDialog();
  const editModal = useModalState();
  const makeLiveModal = useModalState();
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const { original: termsOfService } = row;
  const { version } = termsOfService;

  const [deleteTermsOfServiceLive] = useMutation(
    adminApi.deleteTermsOfServiceLive,
    {
      onSettled: () => queryClient.invalidateQueries(['termsOfService']),
      onSuccess: () => toaster.success(`Version ${version} deleted successfully`),
      onError: () => toaster.error(`Version ${version} could not be deleted`),
    },
  );

  return termsOfService.status === TermsOfServiceStatus.DRAFT ? (
    <>
      <DropdownMenu
        small
        rightAligned
        variant="primary-outline"
        buttonText="Actions"
        iconRight="caret-down"
      >
        <EditMenuItem
          onSelect={() => editModal.open()}
        />
        <DropdownMenuItem
          icon="check-circle"
          onSelect={() => makeLiveModal.open()}
        >
          Make live
        </DropdownMenuItem>
        <DeleteMenuItem
          onSelect={() => {
            confirmDelete(() => deleteTermsOfServiceLive({ termsId: termsOfService._id }));
          }}
        />
      </DropdownMenu>
      <ConfirmDeleteDialog
        heading={`Delete version ${version}?`}
        message="This cannot be undone but you can add a new version later."
        cancelButtonText="Go back"
        style={{ content: { width: '450px', maxWidth: '450px', minWidth: '450px' } }}
        {...deleteDialogProps}
      />
      {editModal.isOpen && (
        <TermsOfServiceModal
          initialTermsOfService={termsOfService}
          version={version}
          isOpen={editModal.isOpen}
          onSave={editModal.close}
          onCancel={editModal.close}
        />
      )}
      {makeLiveModal.isOpen && (
        <MakeTermsLiveModal
          termsOfService={termsOfService}
          isOpen={makeLiveModal.isOpen}
          onCancel={makeLiveModal.close}
        />
      )}
    </>
  ) : (
    null
  );
};

export const TermsOfServiceTable = ({
  termsOfService,
}: {
  termsOfService: TermsOfService[];
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Version',
        accessor: 'version',
        width: 70,
      },
      {
        id: 'htmlFile',
        Header: 'Terms HTML file',
        accessor: 'attachmentId',
        Cell: TermsFileCell,
        width: 320,
      },
      {
        Header: 'What’s changed?',
        accessor: 'changesDescription',
        Cell: nestCells(TruncateCell, ValueOrDashCell),
        EmptyCell: NotApplicable,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: TermsStatusCell,
        width: 120,
      },
      {
        Header: ' ',
        Cell: TermsActionsCell,
        width: 120,
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={termsOfService}
      />
    </StaticTableStyles>
  );
};
