import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { isUserSuspended } from '@deepstream/common/user-utils';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

type UserSuspensionProps =
  { user: any; onCancel: any; onSave: any } &
  ModalProps;

export const UserSuspensionModal: React.FC<UserSuspensionProps> = ({ user, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateUserStatus] = useMutation(
    adminApi.updateUserStatus,
    {
      onSuccess: () => toaster.success('Status updated successfully'),
      onError: () => toaster.error('An error occurred when trying to update status'),
      onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
    },
  );

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          isSuspended: isUserSuspended(user),
        }}
        validationSchema={
          yup.object().shape({
            isSuspended: yup.boolean().required('Required'),
          })
        }
        onSubmit={async ({ isSuspended }, { setSubmitting }) => {
          await updateUserStatus({
            userId: user._id,
            status: isSuspended ? 'suspended' : 'active',
          }, {
            onSuccess: onSave,
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Suspended?
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <MessageBlock variant="info" mt={0}>
                  Suspended users cannot sign in or create an account
                </MessageBlock>
                <SwitchField name="isSuspended" aria-label="Is user suspended?" />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
