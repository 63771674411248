import { Text, TextProps } from 'rebass/styled-components';

export const Capitalize = ({
  sx = {},
  ...props
}: TextProps) => (
  <Text
    as="span"
    sx={{
      textTransform: 'capitalize',
      ...sx,
    }}
    {...props}
  />
);
