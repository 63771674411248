import { useState, useMemo } from 'react';
import { isEmpty, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieChartPanel } from './PieChartPanel';
import * as dashboard from './dashboard';
import { useContractsReportingData } from './useContractsReporting';

export const TotalContractsByProductOrService = () => {
  const { t } = useTranslation('reporting');
  const [activeSliceId, setActiveSliceId] = useState<string | null>(null);

  const {
    data: {
      numContractsByProductAndServiceCode,
      productsAndServices,
      numContractsWithProductsAndServices,
    },
  } = useContractsReportingData();

  const numContractsByProductCodeSlices = useMemo(
    () => Object
      .keys(numContractsByProductAndServiceCode)
      .map(code => ({
        id: code,
        value: numContractsByProductAndServiceCode[code],
        label: productsAndServices[code].title,
      })),
    [numContractsByProductAndServiceCode, productsAndServices],
  );

  const sliceById = keyBy(numContractsByProductCodeSlices, slice => slice.id);

  const value = activeSliceId
    ? sliceById[activeSliceId].value
    : !isEmpty(numContractsByProductAndServiceCode)
      ? numContractsWithProductsAndServices
      : null;

  return (
    <PieChartPanel
      heading={t('contractVolume.totalContractsByProduct')}
      headingTestAttribute="total-contracts-by-product"
      slices={numContractsByProductCodeSlices || []}
      chartDescription={t('contractVolume.totalContractsByProduct')}
      onActiveSliceChange={setActiveSliceId}
    >
      <dashboard.LabeledValue
        label={activeSliceId ? t('contractVolume.categoryContracts') : t('contractVolume.totalContracts')}
        mt="28px"
      >
        {value ?? t('noData')}
      </dashboard.LabeledValue>
    </PieChartPanel>
  );
};
