import { ceil } from 'lodash';

/**
 * Get the CSS for the position of an item in a grid with `numColumns`
 * columns where items should be displayed from top to bottom on each column
 * instead of from left to right on rows.
 *
 * Eg for 2 columns (numbers represent the indices of the elements in the original array):
 * Default behavior:
 * 0 1
 * 2 3
 * 4 5
 * 6
 *
 * Resulting behavior using this function:
 * 0 4
 * 1 5
 * 2 6
 * 3
 */
export const getGridItemPosition = (index: number, numItems: number, numColumns: number) => {
  const numRows = ceil(numItems / numColumns);
  const columnStart = ceil((index + 1) / numRows);
  const rowStart = (index % numRows) + 1;

  return {
    gridColumnStart: columnStart,
    gridColumnEnd: columnStart + 1,
    gridRowStart: rowStart,
    gridRowEnd: rowStart + 1,
  };
};
