import { isNumber, isNaN } from 'lodash';
import { BinaryExpression, Expression, FieldReference, Literal, UnaryExpression, formulaParser } from './parser';

interface FormulaContext {
  [key: string]: number | string;
}

export function evaluate(expression: Expression, context: FormulaContext): number {
  switch (expression.type) {
    case 'BinaryExpression':
      return evaluateBinaryExpression(expression, context);
    case 'UnaryExpression':
      return evaluateUnaryExpression(expression, context);
    case 'FieldReference':
      return evaluateFieldReference(expression, context);
    case 'Literal':
      return evaluateLiteral(expression);
    default:
      throw new Error(`Unknown expression type: ${(expression as any).type}`);
  }
}

function evaluateBinaryExpression(
  expression: BinaryExpression,
  context: FormulaContext,
): number {
  const left = evaluate(expression.left, context);
  const right = evaluate(expression.right, context);

  switch (expression.operator) {
    case '+':
      return left + right;
    case '-':
      return left - right;
    case '*':
      return left * right;
    case '/':
      return left / right;
    default:
      throw new Error(`Unknown operator: ${expression.operator}`);
  }
}

function evaluateUnaryExpression(
  expression: UnaryExpression,
  context: FormulaContext,
): number {
  const argument = evaluate(expression.argument, context);

  switch (expression.operator) {
    case '+':
      return argument;
    case '-':
      return -argument;
    default:
      throw new Error(`Unknown operator: ${expression.operator}`);
  }
}

function evaluateFieldReference(
  expression: FieldReference,
  context: FormulaContext,
): number {
  const reference = context[expression.name];

  // This means we have a deeper reference
  if (typeof reference === 'string') {
    return evaluate(formulaParser.parse(reference).expression, context);
  }

  if (!isNumber(reference) || isNaN(reference)) {
    throw new Error(`Field ${expression.name} is not a number`);
  }

  return reference;
}

function evaluateLiteral(expression: Literal): number {
  return expression.value;
}
