export interface SimpleAuthState {
  isLoading: boolean;
  isAuthenticated: boolean;
  token: string | null;
  error: string;
}

export type SimpleAuthAction =
  { type: 'INITIALISED'; token: string | null } |
  { type: 'ERROR'; error: string };

export const reducer = (state: SimpleAuthState, action: SimpleAuthAction): SimpleAuthState => {
  switch (action.type) {
    case 'INITIALISED':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: !!action.token,
        token: action.token,
        error: '',
      };
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
        token: null,
      };
    default:
      return state;
  }
};
