import { useState, useMemo } from 'react';

import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { ExternalCompaniesModal } from '@deepstream/ui/ExternalCompaniesModal';

export const ExternalCompaniesTable = ({ integrationData }) => {
  const [selectedRow, setSelectedRow] = useState({ data: [{}] });
  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'System ID',
        accessor: 'meta.systemId',
      },
    ],
    [],
  );
  const externalCompaniesModal = useModalState();

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        onRowClick={row => {
          setSelectedRow(row);
          externalCompaniesModal.open();
        }}
        data={integrationData}
      />
      <ExternalCompaniesModal
        isOpen={externalCompaniesModal.isOpen}
        onClose={externalCompaniesModal.close}
        data={selectedRow.data}
      />
    </BasicTableStyles>
  );
};
