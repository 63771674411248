import { useReducer, useMemo, useEffect, useCallback } from 'react';
import { omit, mapValues, sortBy, countBy, keyBy } from 'lodash';
import { getAncestors, getChildren, getLevel1Ancestor, toTagTree, getVisibleTags } from './utils';

const isStringArray = (array: any[]) => Array.isArray(array) && array.length && typeof array[0] === 'string';

const toSelectionMap = (tags: any[]) => {
  // Temporary handling of both array of strings (for countries) and array of objects (for products)
  const selectedTagsById = isStringArray(tags) ? keyBy(tags) : keyBy(tags, '_id');
  return mapValues(selectedTagsById, () => true);
};

const getTagsInitialState = (initialSelectedTags: any[] = []) => ({
  // Whether the tags have been loaded or not
  isReady: false,
  // Map of tag id to tag (for fast lookup)
  tagById: null,
  // Tag tree (represented as array of level 1 tags which have references to level 2 tags, etc)
  tree: null,
  // We represent the selected ids as a map instead of an array for fast lookup
  selectedIds: toSelectionMap(initialSelectedTags),
  // The level 1 tag that the level 2/3 tags are being filtered by
  tagFilter: null,
  // Text-based filter which matches against tag name
  textFilter: null,
});

const tagsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'tags-fetched': {
      const tagById = keyBy(action.tags, '_id');
      const tree = sortBy(toTagTree(tagById), 'name');

      return { ...state, isReady: true, tree, tagById };
    }

    case 'tag-filter-changed': {
      return { ...state, tagFilter: action.tagFilter };
    }

    case 'text-filter-changed': {
      return { ...state, textFilter: action.textFilter };
    }

    case 'tag-toggled': {
      if (state.selectedIds[action.tagId]) {
        return { ...state, selectedIds: omit(state.selectedIds, [action.tagId]) };
      } else {
        const tag = state.tagById[action.tagId];

        return {
          ...state,
          selectedIds: {
            ...state.selectedIds,
            ...toSelectionMap([tag, ...getAncestors(tag)]),
          },
        };
      }
    }

    case 'all-tags-selected': {
      if (action.tagId === 'all') {
        return {
          ...state,
          selectedIds: toSelectionMap(Object.values(state.tagById)),
        };
      }

      const tag = state.tagById[action.tagId];
      const children = getChildren(tag);

      return {
        ...state,
        selectedIds: {
          ...state.selectedIds,
          ...toSelectionMap([...children, ...getAncestors(tag)]),
        },
      };
    }

    case 'all-tags-deselected': {
      if (action.tagId === 'all') {
        return {
          ...state,
          selectedIds: {},
        };
      }

      const tag = state.tagById[action.tagId];
      const childrenIds = getChildren(tag).map((tag: any) => tag._id);

      return {
        ...state,
        selectedIds: omit(state.selectedIds, [action.tagId, ...childrenIds]),
      };
    }

    default: {
      throw new Error(`Unknown action type ${action.type}`);
    }
  }
};

const useStructuredTagPickerState = (initialTags: any[], getTags: () => Promise<any[]>) => {
  const [state, dispatch] = useReducer(tagsReducer, getTagsInitialState(initialTags));

  const { isReady, tree, selectedIds, tagById, tagFilter, textFilter } = state;

  /*
   * Action creators
   */

  const setTagFilter = (tagId: string | null) =>
    dispatch({ type: 'tag-filter-changed', tagFilter: tagId });

  const setTextFilter = (text: string) =>
    dispatch({ type: 'text-filter-changed', textFilter: text.trim() });

  const toggleTagSelection = (tagId: string) =>
    dispatch({ type: 'tag-toggled', tagId });

  const selectAll = (tagId: string) =>
    dispatch({ type: 'all-tags-selected', tagId });

  const deselectAll = (tagId: string) =>
    dispatch({ type: 'all-tags-deselected', tagId });

  const loadTags = (tags: any[]) =>
    dispatch({ type: 'tags-fetched', tags });

  /*
   * Effects
   */

  // On mount, grab the the tags from the API
  useEffect(
    () => {
      getTags().then(loadTags);
    },
    [getTags],
  );

  /*
   * Derived state
   */

  // Get the visible subtree
  const visibleTags = useMemo(
    () => {
      if (!tree) return null;

      return getVisibleTags(tree, tagFilter, textFilter);
    },
    [tagFilter, textFilter, tree],
  );

  const selectedTags = useMemo(
    () => {
      if (!tagById) return [];
      // Assumes that the existence of a key (regardless of value) is considered selected.
      return Object.keys(selectedIds)
        .filter(tagId => tagById[tagId])
        .map(tagId => tagById[tagId]);
    },
    [selectedIds, tagById],
  );

  // Each level 1 tag has a counter that is rendered next to the name which
  // indicates the number of selected level 2 tags
  const countsById = useMemo(
    () => {
      const level3Tags = selectedTags.filter(tag => tag.level === 3);

      return countBy(level3Tags, tag => getLevel1Ancestor(tag)._id);
    },
    [selectedTags],
  );

  const areAllChildrenSelected = useCallback((tagId: string) => {
    if (!tagById) return false;

    if (tagId === 'all') {
      return Object.values(tagById).every((tag: any) => !!selectedIds[tag._id]);
    }

    const tag = tagById[tagId];
    const children = getChildren(tag);

    return children.every((tag: any) => !!selectedIds[tag._id]);
  }, [selectedIds, tagById]);

  return {
    visibleTags,
    selectedIds,
    selectedTags,
    isReady,
    countsById,
    tagFilter: tagFilter && tagById[tagFilter], // get full tag so we have access to the tag's name
    textFilter,

    loadTags,
    setTextFilter,
    setTagFilter,
    toggleTagSelection,
    selectAll,
    deselectAll,
    areAllChildrenSelected,
  };
};

export default useStructuredTagPickerState;
