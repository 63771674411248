import { Box, Flex, Text } from 'rebass/styled-components';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

export const DescriptionList = ({ children, ...props }) => (
  <Stack as="dl" gap={3} {...props}>
    {children}
  </Stack>
);

export const DescriptionItem = ({ term, children, ...props }) => (
  <Box {...props}>
    <Text as="dt" mb={1} color="lightNavy" fontSize={1} fontWeight={500}>
      {term}
    </Text>
    <Box as="dd">
      {children}
    </Box>
  </Box>
);

export const InlineDescriptionItem = ({ term, termProps = {}, children, ...props }) => (
  <Flex {...props}>
    <Text as="dt" mb={1} color="lightNavy" fontSize={1} fontWeight={500} {...termProps}>
      {term}
    </Text>
    <Box as="dd">
      {children}
    </Box>
  </Flex>
);
