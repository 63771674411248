import { sumBy, keyBy, mapValues, groupBy, propertyOf, isEmpty, compact, fromPairs, isNil } from 'lodash';
import type { EvaluationCriterionExchangeDefinition, EvaluationPage, Page } from './types';
import { getExchangeFieldValue } from './fields';
import {
  isEvaluationPage,
  isLinkedEvaluationPage,
} from './page';
import { getAverageScore } from './evaluationFields';

export const getPageWeightsTotal = (pages: EvaluationPage[]) =>
  sumBy(pages, page => page.weight || 0);

export const getWeightedScore = (
  exchange: {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
  },
  score: number | null,
  weight: number,
) => {
  if (isNil(score)) {
    return null;
  }

  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');
  const relativePoints = score / maxPoints;

  return weight * relativePoints * 100;
};

export const getWeightedAverageScore = (
  exchange: {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
  },
  weight: number,
) => {
  const score = getAverageScore(exchange);

  return getWeightedScore(exchange, score, weight);
};

export const getEvaluationWeights = (structure) => {
  const pages = structure.pages.filter(isEvaluationPage);

  const sections = compact<any>(
    pages
    .flatMap(page => page.sections)
    .map(propertyOf(structure.sectionById)),
  );

  const exchangeDefs = sections
    .flatMap(section => section.exchangeDefIds)
    .map(propertyOf(structure.exchangeDefById))
    .filter(exchangeDef => exchangeDef && !exchangeDef.isObsolete);

  const exchangeDefBySectionId = groupBy(exchangeDefs, 'sectionId');

  const filteredSectionById = keyBy(
    sections.filter(section => !isEmpty(exchangeDefBySectionId[section._id])),
    section => section._id,
  );

  const filteredPages = pages.filter(page => (
    page.sections.some(sectionId => filteredSectionById[sectionId])
  ));

  const pageWeightsTotal = getPageWeightsTotal(filteredPages);

  const absolutePageWeightById = mapValues(
    keyBy<Page>(filteredPages, page => page._id),
    page => pages.length > 1 && isLinkedEvaluationPage(page)
      ? (page.weight || 0) / pageWeightsTotal
      : 1,
  );

  const relativeSectionWeightById = fromPairs<number>(
    filteredPages.flatMap(page => {
      const pageSections = compact(page.sections.map(propertyOf(filteredSectionById))) as any[];

      const pageSectionsTotalWeight = sumBy(pageSections, 'weight');

      return pageSections.map(section => {
        const sectionWeight = (section.weight || 0) / pageSectionsTotalWeight;

        return [
          section._id,
          sectionWeight,
        ];
      });
    }),
  );

  const absoluteExchangeDefWeightById = fromPairs(
    filteredPages.flatMap(page => {
      const pageWeight = absolutePageWeightById[page._id];

      const pageSections = compact(page.sections.map(propertyOf(filteredSectionById))) as any[];

      return pageSections.flatMap(section => {
        const sectionWeight = relativeSectionWeightById[section._id];
        const sectionExchangeDefs = exchangeDefBySectionId[section._id];

        const sectionExchangeDefsTotalWeight = sumBy(sectionExchangeDefs, exchangeDef => (exchangeDef.weight)) || 0;

        return sectionExchangeDefs.map(exchangeDef => {
          const exchangeDefWeight = (exchangeDef.weight || 0) / sectionExchangeDefsTotalWeight;

          return [
            exchangeDef._id,
            pageWeight * sectionWeight * exchangeDefWeight,
          ];
        });
      });
    }),
  );

  return {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  };
};
