import { QuestionnaireTemplate } from '@deepstream/common/preQual';
import { map } from 'lodash';
import * as React from 'react';

const QuestionnaireTemplateIdContext = React.createContext<string | null>(null);
const QuestionnaireTemplateContext = React.createContext<QuestionnaireTemplate | null>(null);

export const QuestionnaireTemplateIdProvider = ({ templateId, ...props }: { templateId: string; children: React.ReactNode }) => (
  <QuestionnaireTemplateIdContext.Provider value={templateId} {...props} />
);

export const QuestionnaireTemplateProvider = React.memo<{ template: QuestionnaireTemplate; children: React.ReactNode }>(({
  template,
  ...props
}) => (
  <QuestionnaireTemplateContext.Provider value={template} {...props} />
));

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useQuestionnaireTemplateId(a: { required: true }): string;
export function useQuestionnaireTemplateId(): string;
export function useQuestionnaireTemplateId(a: { required: false }): string | null;
export function useQuestionnaireTemplateId({ required = true } = {}) {
  const templateId = React.useContext(QuestionnaireTemplateIdContext);

  if (required && !templateId) {
    throw new Error('A `templateId` has not been set via the `QuestionnaireTemplateIdProvider` component');
  }

  return templateId;
}

export const useQuestionnaireTemplateData = () => {
  const template = React.useContext<QuestionnaireTemplate | null>(QuestionnaireTemplateContext);
  if (!template) throw new Error('No questionnaire template context found');
  return template;
};

/*
 * Contexts and hooks for managing a questionnaire template's state.
 */

type QuestionnaireTemplateStateContextType = {
  isLive: boolean;
  isDraft: boolean;
  isReview: boolean;
  isRevising: boolean;
  editingPanelId: string | null;
};

const QuestionnaireTemplateStateContext = React.createContext<QuestionnaireTemplateStateContextType | null>(null);

type QuestionnaireTemplateActionsContextType = {
  startEditing: (panelId: string) => void;
  stopEditing: () => void;
};

const QuestionnaireTemplateActionsContext = React.createContext<QuestionnaireTemplateActionsContextType | null>(null);

export const QuestionnaireTemplateStateProvider = ({
  isLive = false,
  isReview = false,
  isRevising = false,
  children,
}: {
  isLive?: boolean;
  isReview?: boolean;
  isRevising?: boolean;
  children: React.ReactNode;
}) => {
  const [editingPanelId, setEditingPanelId] = React.useState<string | null>(null);

  const state = React.useMemo(
    () => ({
      isLive,
      isDraft: !isLive,
      isReview,
      isRevising,
      editingPanelId,
    }),
    [isLive, isReview, isRevising, editingPanelId],
  );

  const actions = React.useMemo(
    () => ({
      startEditing: (panelId: string) => {
        setEditingPanelId(panelId);
      },
      stopEditing: () => {
        setEditingPanelId(null);
      },
    }),
    [],
  );

  return (
    <QuestionnaireTemplateStateContext.Provider value={state}>
      <QuestionnaireTemplateActionsContext.Provider value={actions}>
        {children}
      </QuestionnaireTemplateActionsContext.Provider>
    </QuestionnaireTemplateStateContext.Provider>
  );
};

// @ts-ignore ts(2394) FIXME: This overload signature is not compatible with its implementation signature.
export function useQuestionnaireTemplateState(): QuestionnaireTemplateStateContextType | undefined;
export function useQuestionnaireTemplateState(a: { required: true }): QuestionnaireTemplateStateContextType;
export function useQuestionnaireTemplateState(a: { required: false }): QuestionnaireTemplateStateContextType | undefined;
export function useQuestionnaireTemplateState({ required = true } = {}) {
  const state = React.useContext(QuestionnaireTemplateStateContext);

  if (required && !state) throw new Error('No questionnaire template state found');

  if (!state) return {};

  const { isLive, isDraft, isReview, isRevising } = state;

  if (isLive && (isDraft || isReview || isRevising)) {
    throw new Error('Invalid state for a live questionnaire template');
  }

  return state;
}

export const useQuestionnaireTemplateActions = () => {
  const actions = React.useContext(QuestionnaireTemplateActionsContext);

  if (!actions) throw new Error('No questionnaire template actions found');

  return actions;
};

export const useQuestionnaireTemplateExchangeDefs = () => {
  const { exchangeDefById, exchangeDefSequence } = useQuestionnaireTemplateData();
  return map(exchangeDefSequence, id => exchangeDefById[id]);
};

export const useSummary = () => {
  const { summary } = useQuestionnaireTemplateData();

  return summary;
};

export const useExchangeDefById = () => {
  const { exchangeDefById } = useQuestionnaireTemplateData();

  return exchangeDefById;
};

export const useStatus = () => {
  const { status } = useQuestionnaireTemplateData();

  return status;
};
