export enum MultiStageLineItemsEventType {
  BANNER_SEEN = 'multiStageLineItems:bannerSeen',
  CTA_CLICKED = 'multiStageLineItems:ctaClicked',
  FEATURE_ENABLED = 'multiStageLineItems:featureEnabled',
}

export const MultiStageLineItemsEventTypes = [
  MultiStageLineItemsEventType.BANNER_SEEN,
  MultiStageLineItemsEventType.CTA_CLICKED,
  MultiStageLineItemsEventType.FEATURE_ENABLED,
] as const;

export type MultiStageLineItemsTrackingEvent = {
  eventType: MultiStageLineItemsEventType;
  currentCompanyId: string;
  data: {
    entityType: 'request' | 'requestTemplate';
    entityId: string;
  }
};

export type MultiStageLineItemsTrackedUser = {
  _id: string,
  trackingKey: string,
  name: string,
  date: Date,
  entityId: string,
  creatorCompanyId: string,
  creatorCompanyName: string,
  requestName: string,
  proceeded?: boolean,
};
