/* eslint-disable @typescript-eslint/naming-convention */
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { IconValue } from '@deepstream/common';
import { isString } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { FrozenHeaderCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useSortConfig } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { RequiredAsterisk } from '@deepstream/ui-kit/elements/text/RequiredAsterisk';
import styled from 'styled-components';
import { GridSelectionState, useGridSelection } from '@deepstream/ui-kit/grid/EditableGrid/GridSelectionContext';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';

type IconConfig = {
  width: number;
  value: IconValue;
};

const iconConfigByFieldType: Record<string, IconConfig> = {
  string: { width: 12, value: 'text' },
  number: { width: 12, value: 'hashtag' },
  price: { width: 8, value: 'dollar-sign' },
  date: { width: 12, value: 'calendar' },
  boolean: { width: 13, value: 'thumbs-up' },
  unspscCode: { width: 12, value: 'tag' },
  formula: { width: 17, value: 'function' },
};

const FieldTypeIcon = ({ iconConfig }: { iconConfig: IconConfig }) => {
  return (
    <Box sx={{ width: iconConfig.width, position: 'absolute', left: '10px', fontSize: 2 }}>
      <Icon regular color="subtext" icon={iconConfig.value} />
    </Box>
  );
};

export const DisabledHeader = ({
  column,
  showFieldTypeIcon,
  truncate = true,
}: FrozenHeaderCellProps<EditableGridColumn>) => {
  const { t } = useTranslation(['translation']);
  const { label, description, descriptionIcon, fieldType } = column.original;
  // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const iconConfig = showFieldTypeIcon ? iconConfigByFieldType[fieldType] : null;

  const headerContent = (
    <>
      {iconConfig ? (
        <FieldTypeIcon iconConfig={iconConfig} />
      ) : (
        null
      )}
      {label}
    </>
  );

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      width="100%"
      opacity="0.5"
    >
      <Tooltip content={t('request.lineItems.bulkUploadModal.fieldTypeNotSupported')}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          ml={iconConfig ? `${iconConfig.width + 8}px` : null}
          mr={2}
        >
          {truncate ? (
            <Truncate>
              {headerContent}
            </Truncate>
          ) : (
            headerContent
          )}
          {description ? (
            <Box fontSize="10px" fontWeight={400} color="gray" mt="2px">
              {descriptionIcon}
              {description}
            </Box>
          ) : (
            null
          )}
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export const DisabledHeaderWithFieldTypeIcon = withProps(DisabledHeader, { showFieldTypeIcon: true });

export const SimpleHeader = ({
  column,
  info,
  showFieldTypeIcon,
  truncate = true,
}: FrozenHeaderCellProps<EditableGridColumn>) => {
  const theme = useTheme();
  const { label, required, description, descriptionIcon, descriptionSx, fieldType, warnings, errors } = column.original;
  // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const iconConfig = showFieldTypeIcon ? iconConfigByFieldType[fieldType] : null;

  const headerContent = (
    <>
      {iconConfig ? (
        <FieldTypeIcon iconConfig={iconConfig} />
      ) : (
        null
      )}
      {label}
      {required ? (
        <RequiredAsterisk />
      ) : (
        null
      )}
    </>
  );

  return (
    <Flex
      justifyContent="stretch"
      alignItems="center"
      flexDirection="row"
      width="100%"
      backgroundColor={warnings ? 'warningBackground' : undefined}
      // FIXME: remove the padding from the wrapper instead of setting a box shadow here
      sx={warnings ? { boxShadow: `0 0 10px 20px ${theme.colors.warningBackground}` } : undefined}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        ml={iconConfig ? `${iconConfig.width + 8}px` : null}
        mr={2}
        flex={1}
      >
        {truncate ? (
          <Tooltip content={label}>
            <Truncate>
              {headerContent}
            </Truncate>
          </Tooltip>
        ) : (
          headerContent
        )}
        {description ? (
          <Truncate sx={{ fontSize: '10px', fontWeight: 400, color: 'gray', mt: '2px', ...descriptionSx }}>
            {descriptionIcon}
            {description}
          </Truncate>
        ) : (
          null
        )}
      </Flex>
      {errors ? (
        <Icon icon="exclamation-circle" color="danger" ml={2} />
      ) : warnings ? (
        <Tooltip content={`${warnings}`}>
          <Icon icon="warning" color="warning" ml={2} />
        </Tooltip>
      ) : info ? (
        <Tooltip content={info}>
          <Icon regular color="subtext" icon="info-circle" ml={2} />
        </Tooltip>
      ) : (
        null
      )}
    </Flex>
  );
};

const sortIconByDirection = {
  asc: 'sort-asc',
  desc: 'sort-desc',
} as const;

const sortDirectionOrder = [
  null,
  'asc',
  'desc',
];

export const SortableHeader = ({
  column,
  info,
  showFieldTypeIcon,
  truncate = true,
  clamp,
}: FrozenHeaderCellProps<EditableGridColumn>) => {
  const theme = useTheme();
  const { _id, label, required, description, descriptionIcon, descriptionSx, fieldType, warnings, disableSortBy } = column.original;
  // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const iconConfig = showFieldTypeIcon ? iconConfigByFieldType[fieldType] : null;
  const { sortConfig, setSortConfig } = useSortConfig();

  const headerContent = (
    <>
      {iconConfig ? (
        <FieldTypeIcon iconConfig={iconConfig} />
      ) : (
        null
      )}
      {label}
      {required ? (
        <RequiredAsterisk />
      ) : (
        null
      )}
    </>
  );

  return (
    <Flex
      justifyContent="stretch"
      alignItems="center"
      flexDirection="row"
      width="100%"
      backgroundColor={warnings ? 'warningBackground' : undefined}
      // FIXME: remove the padding from the wrapper instead of setting a box shadow here
      sx={warnings ? { boxShadow: `0 0 10px 20px ${theme.colors.warningBackground}` } : undefined}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        ml={iconConfig ? `${iconConfig.width + 8}px` : null}
        mr={2}
        flex={1}
      >
        {clamp ? (
          <Tooltip content={isString(label) ? label : undefined}>
            <Clamp lines={2}>{headerContent}</Clamp>
          </Tooltip>
        ) : truncate ? (
          <Tooltip content={isString(label) ? label : undefined}>
            <Truncate>
              {headerContent}
            </Truncate>
          </Tooltip>
        ) : (
          headerContent
        )}
        {description ? (
          <Truncate sx={{ fontSize: '10px', fontWeight: 400, color: 'gray', mt: '2px', ...descriptionSx }}>
            {descriptionIcon}
            {description}
          </Truncate>
        ) : (
          null
        )}
      </Flex>
      {warnings ? (
        <Tooltip content={`${warnings}`}>
          <Icon icon="warning" color="warning" ml={2} />
        </Tooltip>
      ) : info ? (
        <Tooltip content={info}>
          <Icon regular color="subtext" icon="info-circle" ml={2} />
        </Tooltip>
      ) : (
        null
      )}
      {!disableSortBy && (
        <IconButton
          icon={sortConfig.columnId === _id ? (
            // @ts-ignore ts(2538) FIXME: Type 'null' cannot be used as an index type.
            sortIconByDirection[sortConfig.direction] || 'sort'
          ) : (
            'sort'
          )}
          color="subtext"
          ml={2}
          onClick={() => {
            if (sortConfig.columnId === _id) {
              const currentIndex = sortDirectionOrder.indexOf(sortConfig.direction);
              const nextDirection = sortDirectionOrder[(currentIndex + 1) % sortDirectionOrder.length];

              setSortConfig({
                columnId: _id,
                direction: nextDirection,
              });
            } else {
              setSortConfig({
                columnId: _id,
                direction: 'asc',
              });
            }
          }}
        />
      )}
    </Flex>
  );
};

export const EditableFieldHeader = (props: FrozenHeaderCellProps<EditableGridColumn> & {
  onEditFieldClick?: (column: EditableGridColumn) => void;
  onRemoveFieldClick?: (column: EditableGridColumn) => void;
  removeFieldDisabledTooltip?: string;
  testId?: string; // Used for intercom/e2e tests to target an element
}) => {
  const { t } = useTranslation();
  const {
    column,
    onEditFieldClick,
    onRemoveFieldClick,
    removeFieldDisabledTooltip,
    info,
    testId,
    truncate = true,
  } = props;
  const { label, required, description, descriptionIcon, fieldType } = column.original;
  // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const iconConfig = props.showFieldTypeIcon ? iconConfigByFieldType[fieldType] : null;

  const headerContent = (
    <>
      {iconConfig ? (
        <FieldTypeIcon iconConfig={iconConfig} />
      ) : (
        null
      )}
      {label}
      {required ? (
        <RequiredAsterisk />
      ) : (
        null
      )}
    </>
  );

  return (
    <Flex justifyContent="space-between" alignItems="center" flexDirection="row" width="100%">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        ml={iconConfig ? `${iconConfig.width + 8}px` : null}
        mr={2}
      >
        {truncate ? (
          <Tooltip content={label}>
            <Truncate>
              {headerContent}
            </Truncate>
          </Tooltip>
        ) : (
          headerContent
        )}
        {description ? (
          <Box fontSize="10px" fontWeight={400} color="gray" mt="2px">
            {descriptionIcon}
            {description}
          </Box>
        ) : (
          null
        )}
      </Flex>
      <Box onKeyDown={stopPropagation} flex="0 0 auto">
        {info && (
          <Tooltip content={info}>
            <Icon regular color="subtext" icon="info-circle" mx={2} sx={{ pointerEvents: 'all' }} />
          </Tooltip>
        )}
        <DropdownMenu
          small
          variant="lightGray3-subtle"
          iconLeft="chevron-down"
          sx={{ width: '12px', height: '28px' }}
          menuZIndex={151}
          rightAligned
          data-test={testId}
        >
          {onEditFieldClick ? (
            <DropdownMenuItem
              icon="pencil"
              iconColor="primary"
              onSelect={() => {
                onEditFieldClick(column.original);
              }}
            >
              {t('request.lineItems.editField')}
            </DropdownMenuItem>
          ) : (
            null
          )}
          {onRemoveFieldClick ? (
            <DropdownMenuItem
              tooltip={removeFieldDisabledTooltip}
              icon="close"
              iconColor="danger"
              disabled={Boolean(removeFieldDisabledTooltip)}
              onSelect={() => {
                onRemoveFieldClick(column.original);
              }}
            >
              {t('request.lineItems.removeField')}
            </DropdownMenuItem>
          ) : null}
        </DropdownMenu>
      </Box>
    </Flex>
  );
};

const StyledInput = styled.input`
  accent-color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

export const RowSelectionCheckboxHeader = (props: FrozenHeaderCellProps<EditableGridColumn>) => {
  const inputRef = useRef<HTMLInputElement>();
  const {
    getGridSelectionState,
    setGridSelectionState,
  } = useGridSelection();

  const gridSelectionState = getGridSelectionState();

  useEffect(() => {
    if (inputRef.current) {
      switch (gridSelectionState) {
        case GridSelectionState.NONE:
            inputRef.current.checked = false;
            inputRef.current.indeterminate = false;
            break;
          case GridSelectionState.SOME:
            inputRef.current.checked = false;
            inputRef.current.indeterminate = true;
            break;
          case GridSelectionState.ALL:
            inputRef.current.checked = true;
            inputRef.current.indeterminate = false;
            break;
      }
    }
  }, [gridSelectionState]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
      mb="1px"
    >
      <StyledInput
        type="checkbox"
        // @ts-ignore ts(2769) FIXME: No overload matches this call.
        ref={inputRef}
        onClick={() => {
          switch (gridSelectionState) {
            case GridSelectionState.NONE:
            case GridSelectionState.SOME:
              setGridSelectionState(GridSelectionState.ALL);
              break;
            case GridSelectionState.ALL:
              setGridSelectionState(GridSelectionState.NONE);
              break;
          }
        }}
      />
    </Flex>
  );
};
