import { useCallback } from 'react';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useApi } from '../api';
import { useMutation } from '../useMutation';
import { UploadPurpose } from '../types';

export const useUploadApi = ({ purpose }: { purpose: UploadPurpose }) => {
  const companyId = useCurrentCompanyId()!;
  const api = useApi();

  const upload = useCallback(
    ({ file, onProgress }) => api.upload({ companyId, purpose, file, onProgress }),
    [api, purpose, companyId],
  );

  return useMutation(upload);
};
