import { SidebarConfig } from '../reports/tabsConfig';

export const productDashboardTabs = {
  general: 'general',
  preQualification: 'preQualification',
  requests: 'requests',
  contracts: 'contracts',
  integrations: 'integrations',
};

export const productDashboardTabsConfig: SidebarConfig = {
  key: 'dashboards',
  heading: 'Dashboards',
  tabs: {
    [productDashboardTabs.general]: {
      text: 'General',
      icon: 'crosshairs',
    },
    [productDashboardTabs.preQualification]: {
      text: 'Pre-qualification',
      icon: 'file-check',
    },
    [productDashboardTabs.requests]: {
      text: 'Requests',
      icon: 'file-text-o',
    },
    [productDashboardTabs.contracts]: {
      text: 'Contracts',
      icon: 'file-contract',
      isIconRegular: true,
    },
    [productDashboardTabs.integrations]: {
      text: 'Integrations',
      icon: 'store',
      isIconRegular: true,
    },
  },
};

export const getDashboardTabIndex = (tab: string) => Object.values(productDashboardTabs).indexOf(tab);

export const getDashboardTab = (index: number) => Object.values(productDashboardTabs)[index];
