import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';

type EditSearchVisibilityProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditSearchVisibilityModal: React.FC<EditSearchVisibilityProps> = ({ company, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateSearchVisibility] = useMutation(
    adminApi.updateSearchVisibility,
    {
      onSuccess: () => toaster.success('Search visibility updated successfully'),
      onError: () => toaster.error('An error occurred while updating search visibility'),
      onSettled: () => queryClient.invalidateQueries(['company', { companyId: company._id }]),
    },
  );

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          isHidden: !!company.hidden,
        }}
        validationSchema={
          yup.object().shape({
            isHidden: yup.boolean().required('Required'),
          })
        }
        onSubmit={async ({ isHidden }, { setSubmitting }) => {
          await updateSearchVisibility({
            companyId: company._id,
            isHidden,
          }, {
            onSuccess: onSave,
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Hide from search?
            </ModalHeader>
            <ModalBody>
              <MessageBlock variant="info" mt={0} mb={3}>
                Hidden companies will not appear in search listings
              </MessageBlock>
              <SwitchField name="isHidden" aria-label="Edit search visibility" />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
