import * as React from 'react';
import { debounce, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Flex } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Modal, ModalBody } from '@deepstream/ui-kit/elements/popup/Modal';

export enum ProcessingContext {
  REQUEST = 'request',
  PROCESSING_TASK = 'processingTask',
}

interface GlobalProcessing {
  processingContext: ProcessingContext | null;
  setProcessingContext?: (isProcessing: ProcessingContext) => void;
}

export const GlobalProcessingContext = React.createContext<GlobalProcessing | null>({
  processingContext: null,
  setProcessingContext: noop,
});

export const useGlobalProcessing = () => {
  const globalProcessingActions = React.useContext(GlobalProcessingContext);

  if (!globalProcessingActions?.setProcessingContext) {
    throw new Error('no global processing provider found');
  }

  return globalProcessingActions;
};

export const GlobalProcessingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [processingContext, setProcessingContext] = React.useState<ProcessingContext>();

  const value = React.useMemo(
    () => ({
      processingContext,
      setProcessingContext: debounce(setProcessingContext, 500),
    }),
    [processingContext, setProcessingContext],
  );

  return (
    // @ts-ignore ts(2322) FIXME: Type '{ processingContext: ProcessingContext | undefined; setProcessingContext: DebouncedFunc<React.Dispatch<React.SetStateAction<ProcessingContext | undefined>>>; }' is not assignable to type 'GlobalProcessing'.
    <GlobalProcessingContext.Provider value={value}>
      {children}
    </GlobalProcessingContext.Provider>
  );
};

export const GlobalProcessingModal = () => {
  const { t } = useTranslation('translation');
  const { processingContext } = useGlobalProcessing();

  return (
    <Modal
      preventScroll={true}
      shouldCloseOnEsc={false}
      isOpen={!!processingContext}
      style={{ overlay: { zIndex: 1000 } }}
    >
      <ModalBody>
        <Flex justifyContent="center">
          <IconText
            icon="spinner"
            text={t('processing.heading')}
            gap={2}
            iconFontWeight="500"
            fontSize={4}
            color="black"
            iconSpin
          />
        </Flex>
        {processingContext === ProcessingContext.REQUEST ? (
          <Text>
            {t('processing.requestBody')}
          </Text>
        ) : (
          <Text sx={{ width: '100%', textAlign: 'center' }}>
            {t('processing.genericBody')}
          </Text>
        )}
      </ModalBody>
    </Modal>
  );
};
