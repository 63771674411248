import { reject } from 'lodash';
import { ActionType } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from '../types';

export const rejectObsolete = (history: ExchangeSnapshot['history']) =>
  reject(
    history,
    (action, index, actions) => {
      const nextAction = actions[index + 1];

      return (
        action.type === ActionType.OBSOLETE_ACTION ||
        nextAction?.type === ActionType.OBSOLETE_ACTION
      );
    },
  );
