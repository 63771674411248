import { useReducer, useMemo, useEffect } from 'react';
import { concat, filter, findIndex, isEqual, keyBy, map, without } from 'lodash';
import { useQuery } from 'react-query';
import { TFunction } from 'i18next';
import { ProductTag } from '@deepstream/common/products';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useApi, wrap } from './api';
import { Item } from './List';
import { FilterItem } from './FilteredList';
import { useSsrStaticProducts } from './SsrStaticProductsContext';

// The product code/ID is 8 digit, where each 2 digit pair represents a different level of the product (See `Product` model).
// We get the root of a high level (ie parent) product code by removing the appended "00" substrings.
const getCodeRoot = productId => {
  // Split the product ID in substrings of 2 characters
  const substrings = productId.match(/.{2}/g);

  return filter(
    substrings,
    substring => Boolean(Number(substring)),
  ).join('');
};

// The badge count represents the number of products selected that are below the specified product in the tree,
// but also including itself.
const getBadgeCount = (productId: string, selectedProductIds: string[]) => {
  const codeRoot = getCodeRoot(productId);

  return filter(
    selectedProductIds,
    productId => productId.toString().startsWith(codeRoot),
  ).length;
};

export const productToItem = (product: ProductTag, selectedProductIds: string[], t: TFunction): Item => ({
  _id: product._id,
  label: product.title,
  level: product.level,
  parent: product.parent,
  isSelected: selectedProductIds.includes(product._id),
  numSelectedChildren: getBadgeCount(product._id, selectedProductIds),
  fields: [{
    label: t('general.type'),
    value: t(`productsAndServices.type.${product.type}`),
  }, {
    label: t('productsAndServices.code'),
    value: product._id,
  }],
});

export const productToFilterItem = (product: ProductTag, selectedProductIds: string[], filterId: string, t: TFunction): FilterItem => ({
  _id: product._id,
  label: product.title,
  canToggle: product._id === filterId,
  canSelectFilter: product._id !== filterId,
  isSelected: selectedProductIds.includes(product._id),
  numSelectedChildren: getBadgeCount(product._id, selectedProductIds),
  fields: [{
    label: t('general.type'),
    value: t(`productsAndServices.type.${product.type}`),
  }, {
    label: t('productsAndServices.code'),
    value: product._id,
  }],
});

export const getAncestorIds = (
  productId: string,
  productById: Record<string, ProductTag>,
  ancestors: string[] = [],
): string[] => {
  const parentId = productById[productId].parent;

  return parentId
    ? getAncestorIds(parentId, productById, [parentId, ...ancestors])
    : ancestors;
};

export type ProductsState = {
  filterId: string | null;
  productById: Record<string, ProductTag>;
  selectedProductIds: string[];
  searchText: string;
  searchResultIds: string[];
  hasSearched: boolean;
};

export type ProductsActions = {
  updateProducts: (products: ProductTag[]) => void;
  toggleProduct: (productId: string) => void;
  deselectAll: () => void;
  setFilterId: (filterId: string | null) => void;
  updateSearchText: (text: string) => void;
  updateSearchResults: (results: ProductTag[]) => void;
  clearSearch: () => void;
  updateSelectedProducts: (selectedProductIds: string[]) => void;
};

enum ActionType {
  ALL_PRODUCTS_DESELECTED = 'all-products-deselected',
  FILTER_SET = 'filter-set',
  PRODUCTS_UPDATED = 'products-updated',
  PRODUCT_TOGGLED_MULTI = 'product-toggled-multi',
  PRODUCT_TOGGLED_SINGLE = 'product-toggled-single',
  SEARCH_TEXT_UPDATED = 'search-text-updated',
  SEARCH_RESULTS_UPDATED = 'search-results-updated',
  SEARCH_CLEARED = 'search-cleared',
  SELECTED_PRODUCTS_UPDATED = 'selected-products-updated',
}

type Action =
  { type: ActionType.ALL_PRODUCTS_DESELECTED } |
  { type: ActionType.FILTER_SET; filterId: string | null } |
  { type: ActionType.PRODUCTS_UPDATED; products: ProductTag[] } |
  { type: ActionType.PRODUCT_TOGGLED_MULTI; productId: string } |
  { type: ActionType.PRODUCT_TOGGLED_SINGLE; productId: string } |
  { type: ActionType.SEARCH_TEXT_UPDATED; text: string } |
  { type: ActionType.SEARCH_RESULTS_UPDATED; results: ProductTag[] } |
  { type: ActionType.SEARCH_CLEARED } |
  { type: ActionType.SELECTED_PRODUCTS_UPDATED; selectedProductIds: string[] };

const getInitialState = (): ProductsState => ({
  filterId: null,
  productById: {},
  selectedProductIds: [],
  searchText: '',
  searchResultIds: [],
  hasSearched: false,
});

const getInitialSsrState = (selectedProductIds, products) => (): ProductsState => ({
  filterId: null,
  productById: keyBy(products, '_id'),
  selectedProductIds,
  searchText: '',
  searchResultIds: [],
  hasSearched: false,
});

const productsReducer = (state: ProductsState, action: Action): ProductsState => {
  const { productById, selectedProductIds } = state;

  switch (action.type) {
    case ActionType.ALL_PRODUCTS_DESELECTED:
      return {
        ...state,
        selectedProductIds: [],
      };
    case ActionType.FILTER_SET:
      return {
        ...state,
        filterId: action.filterId,
      };
    case ActionType.PRODUCTS_UPDATED: {
      const newProductsById = keyBy(action.products, '_id');

      return {
        ...state,
        productById: {
          ...productById,
          ...newProductsById,
        },
      };
    }
    case ActionType.PRODUCT_TOGGLED_MULTI: {
      const { productId } = action;

      if (selectedProductIds.includes(productId)) {
        return {
          ...state,
          selectedProductIds: without(selectedProductIds, productId),
        };
      } else {
        // We need to order selected products by id
        const index = findIndex(
          selectedProductIds,
          id => Number(id) > Number(productId),
        );

        const newSelectedProductIds = index === -1
          ? concat(selectedProductIds, productId)
          : [
            ...selectedProductIds.slice(0, index),
            productId,
            ...selectedProductIds.slice(index),
          ];

        return {
          ...state,
          selectedProductIds: newSelectedProductIds,
        };
      }
    }
    case ActionType.PRODUCT_TOGGLED_SINGLE: {
      const { productId } = action;

      if (selectedProductIds.includes(productId)) {
        return {
          ...state,
          selectedProductIds: [],
        };
      } else {
        return {
          ...state,
          selectedProductIds: [productId],
        };
      }
    }
    case ActionType.SEARCH_TEXT_UPDATED: {
      return {
        ...state,
        searchText: action.text,
      };
    }
    case ActionType.SEARCH_RESULTS_UPDATED: {
      const newProductsById = keyBy(action.results, '_id');

      return {
        ...state,
        productById: {
          ...productById,
          ...newProductsById,
        },
        searchResultIds: map(action.results, '_id'),
        hasSearched: true,
      };
    }
    case ActionType.SEARCH_CLEARED: {
      return {
        ...state,
        searchText: '',
        searchResultIds: [],
        hasSearched: false,
      };
    }
    case ActionType.SELECTED_PRODUCTS_UPDATED: {
      return {
        ...state,
        selectedProductIds: action.selectedProductIds,
      };
    }
    default:
      return state;
  }
};

export const useProducts = ({
  initialProducts,
  selectedProductIds,
  searchWithinSelectedProducts,
  multiSelect = true,
}: {
  initialProducts?: ProductTag[];
  selectedProductIds: string[];
  searchWithinSelectedProducts?: boolean; // Used for searching only within the selected products (useful for the readonly view)
  multiSelect?: boolean;
}) => {
  const api = useApi();
  const ssrStaticProducts = useSsrStaticProducts();

  const [state, dispatch] = useReducer(
    productsReducer,
    null,
    ssrStaticProducts ? getInitialSsrState(selectedProductIds, ssrStaticProducts) : getInitialState,
  );

  const { filterId, searchText, hasSearched } = state;

  const {
    data: fetchedProducts = [],
    isError: isProductsError,
    isLoading: isProductsLoading,
    refetch: refetchProducts,
  } = useQuery(
    ['products', { parent: filterId }],
    wrap(api.getProducts),
    {
      staleTime: 24 * 60 * 60 * 1000,
      enabled: !ssrStaticProducts,
    },
  );

  const {
    data: searchData,
    isLoading: isSearching,
    isError: isSearchError,
    refetch: refetchSearch,
  } = useQuery(
    ['searchProducts', {
      text: searchText,
      codes: searchWithinSelectedProducts ? selectedProductIds : undefined,
    }],
    wrap(api.searchProducts),
    {
      enabled: !!searchText.length,
    },
  );

  const products = ssrStaticProducts || fetchedProducts;

  const actions: ProductsActions = useMemo(
    () => ({
      updateProducts: (products) =>
        dispatch({ type: ActionType.PRODUCTS_UPDATED, products }),
      toggleProduct: (productId: string) => {
        if (multiSelect) {
          dispatch({ type: ActionType.PRODUCT_TOGGLED_MULTI, productId });
        } else {
          dispatch({ type: ActionType.PRODUCT_TOGGLED_SINGLE, productId });
        }
      },
      deselectAll: () =>
        dispatch({ type: ActionType.ALL_PRODUCTS_DESELECTED }),
      setFilterId: (filterId: string | null) =>
        dispatch({ type: ActionType.FILTER_SET, filterId }),
      updateSearchText: (text: string) => {
        if (text.length) {
          dispatch({ type: ActionType.SEARCH_TEXT_UPDATED, text });
        } else {
          dispatch({ type: ActionType.SEARCH_CLEARED });
        }
      },
      updateSearchResults: (results: ProductTag[]) =>
        dispatch({ type: ActionType.SEARCH_RESULTS_UPDATED, results }),
      clearSearch: () =>
        dispatch({ type: ActionType.SEARCH_CLEARED }),
      updateSelectedProducts: (selectedProductIds) =>
        dispatch({ type: ActionType.SELECTED_PRODUCTS_UPDATED, selectedProductIds }),
    }),
    [multiSelect],
  );

  useEffect(
    () => {
      if (initialProducts?.length) {
        actions.updateProducts(initialProducts);
      }
    },
    [initialProducts, actions],
  );

  useEffect(
    () => {
      if (products.length) {
        actions.updateProducts(products);
      }
    },
    [products, actions],
  );

  useEffect(
    () => actions.updateSelectedProducts(selectedProductIds),
    [selectedProductIds, actions],
  );

  useWatchValue(
    searchData,
    (data) => {
      if (data) {
        // We need to add the ancestors of the search results to the state because we need to display them
        // in the filters list when selecting one of the results. We excluded the ancestors of the level 4 tags
        // because they can't be selected as filters.
        actions.updateProducts(data.ancestors);
        actions.updateSearchResults(data.results);
      }
    },
    isEqual,
  );

  const showSearchResults = !filterId && !!searchText.length && (!isSearching || hasSearched);

  return useMemo(
    () => ({
      state,
      actions,
      showSearchResults,
      isProductsError,
      isProductsLoading,
      isSearchError,
      isSearching,
      products,
      refetchProducts,
      refetchSearch,
    }),
    [
      state,
      actions,
      showSearchResults,
      isProductsError,
      isProductsLoading,
      isSearchError,
      isSearching,
      products,
      refetchProducts,
      refetchSearch,
    ],
  );
};
