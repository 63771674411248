import * as React from 'react';
import { capitalize } from 'lodash';
import { CompanyAddress } from '@deepstream/ui/ui/CompanyAddress';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { EditCompanyTypeModal } from './EditCompanyTypeModal';
import { EditCompanyAddressModal } from './EditCompanyAddressModal';

export const CompanyProfilePropertyList: React.FC<{ company: any }> = ({ company }) => {
  const editTypeModal = useModalState();
  const editAddressModal = useModalState();

  const generalProperties = React.useMemo(
    () => {
      if (!company) return [];

      const properties = [
        { name: 'Company type', value: capitalize(company.group) },
        { name: 'Address', value: company.address, Component: CompanyAddress, heightAuto: true },
      ];

      return properties;
    },
    [company],
  );

  return (
    <>
      <PropertyList properties={generalProperties}>
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editTypeModal.open} />
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editAddressModal.open} mt="12px" />
      </PropertyList>
      <EditCompanyTypeModal
        onSave={editTypeModal.close}
        onCancel={editTypeModal.close}
        isOpen={editTypeModal.isOpen}
        onRequestClose={editTypeModal.close}
        company={company}
      />
      <EditCompanyAddressModal
        onSave={editAddressModal.close}
        onCancel={editAddressModal.close}
        isOpen={editAddressModal.isOpen}
        onRequestClose={editAddressModal.close}
        company={company}
      />
    </>
  );
};
