import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, wrap } from '../api';
import { useToaster } from '../toast';

export const LatestVersionChecker = ({ appName }: { appName: 'admin' | 'app' | 'onboarding' }) => {
  const api = useApi();

  const { data, isFetched } = useQuery<{ version: string }>({
    queryKey: ['latestVersion', appName],
    queryFn: wrap(api.getServerVersion),
    refetchInterval: 60 * 60 * 1000,
  });

  const toaster = useToaster();
  const { t } = useTranslation();

  const isLatestVersion = (
    !data?.version ||
    !process.env.NX_RELEASE_REV ||
    data.version === process.env.NX_RELEASE_REV
  );

  useEffect(() => {
    if (isFetched && !isLatestVersion) {
      toaster.warn(
        <div>
          {t('newVersionToaster.message')}{' '}
          <a style={{ color: 'white' }} href={window.location.href}>{t('newVersionToaster.action')}</a>.
        </div>,
        { autoDismiss: false },
      );
    }
  }, [t, isLatestVersion, isFetched, toaster]);

  return null;
};
