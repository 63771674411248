import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { ModalForm } from '../../ModalForm';
import { RadioField } from '../../form/RadioField';

export const AddMoreItemsModal = ({
  onCancel,
  onSubmit,
  isOpen,
  heading,
  info,
  ...props
}: ModalProps & {
  onCancel: () => void;
  onSubmit: (pasteMethod: GridPasteMethod) => void;
  heading: string;
  info: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const options = React.useMemo(() => {
    return [GridPasteMethod.EXTEND, GridPasteMethod.CROP]
      .map(pasteMethod => ({
        label: t(`gridEditor.addMoreItemsModal.option.${pasteMethod}.label`),
        value: pasteMethod,
        description: t(`gridEditor.addMoreItemsModal.option.${pasteMethod}.description`),
      }));
  }, [t]);

  return (
    <ModalForm
      heading={heading}
      isOpen={isOpen}
      onCancel={onCancel}
      submitLabel={t('continue', { ns: 'general' })}
      onSubmit={({ pasteMethod }) => onSubmit(pasteMethod)}
      initialValues={{
        pasteMethod: null,
      }}
      validationSchema={
        yup.object().shape({
          pasteMethod: yup.string().required(t('required', { ns: 'general' })),
        })
      }
    >
      <Text>{info}</Text>
      <RadioField
        name="pasteMethod"
        hideLabel
        gap={1}
        options={options}
        labelStyle={{ color: theme.colors.text, fontWeight: 400 }}
        showError
      />
    </ModalForm>
  );
};
