import { size } from 'lodash';

export const stopEvent = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
  event.preventDefault();
  event.stopPropagation();
};

export const stopPropagation = (event: { stopPropagation: () => void }) =>
  event.stopPropagation();

export const isMultiClickEvent = (event: { detail: number }) =>
  event.detail > 1;

export const producesPrintableCharacter = (event: React.KeyboardEvent<HTMLElement>) =>
  size(event.key) === 1 && !event.altKey && !event.ctrlKey && !event.metaKey;
