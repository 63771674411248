import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from '@deepstream/ui-utils/useNetworkState';
import { useToaster } from './toast';

const OfflineToastContent = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t('offlineToaster.title')}{' '}{t('offlineToaster.message')}
    </div>
  );
};

export const NetworkStatusChecker = () => {
  const toaster = useToaster();
  const network = useNetworkState();
  const [offlineWarningToastId, setOfflineWarningToastId] = useState(null);

  useEffect(
    () => {
      if (network.online && offlineWarningToastId) {
        toaster.remove(offlineWarningToastId);
        setOfflineWarningToastId(null);
      } else if (!network.online && !offlineWarningToastId) {
        toaster.warn(
          <OfflineToastContent />,
          { autoDismiss: false },
          toastId => setOfflineWarningToastId(toastId),
        );
      }
    },
    [network, offlineWarningToastId, toaster],
  );

  return null;
};
