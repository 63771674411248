import * as React from 'react';
import { Flex, FlexProps } from 'rebass/styled-components';

const leftRadii = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};

const rightRadii = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};

const innerRadii = {
  ...leftRadii,
  ...rightRadii,
};

const getRadii = (index: number, length: number) => {
  if (length < 2) return {}; // No need for any modifications
  else if (index === 0) return leftRadii; // First child
  else if (index === length - 1) return rightRadii; // Last child
  else return innerRadii; // Inner child
};

const modifyBorderRadii = (children: React.ReactElement) => {
  // toArray drops null children
  const childrenLength = React.Children.toArray(children).length;

  return React.Children
    .toArray(children)
    .map((child: any, index) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      const { style } = child.props as any;

      return React.cloneElement(child as any, {
        style: Object.assign(style || {}, getRadii(index, childrenLength)),
      });
    });
};

export const FormGroup: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex {...props}>
    {modifyBorderRadii(children as React.ReactElement)}
  </Flex>
);
