export enum IntegrationMarketplaceEventType {
  PAGE_OPENED = 'integrationMarketplace:pageOpened',
  INTEGRATION_CARD_SELECTED = 'integrationMarketplace:cardSelected',
  INTEGRATION_CARD_DESELECTED = 'integrationMarketplace:cardDeselected',
  SCHEDULE_CALL = 'integrationMarketplace:scheduleCall',
  SEARCH_INTEGRATION = 'integrationMarketplace:searchIntegration',
}

export enum IntegrationMarketplaceOrigin {
  COMPANY_SETTINGS = 'companySettings',
  NAVBAR_DROPDOWN = 'navbarDropdown',
  EXTERNAL = 'external',
}

export enum ConfigurableIntegrations {
  SLACK = 'Slack',
  MICROSOFT_TEAMS = 'Microsoft Teams',
}

export type IntegrationMarketplaceTrackingEvent = {
  currentCompanyId: string;
  eventType: IntegrationMarketplaceEventType;
  data: {
    searchQuery?: string;
    integrationName?: string;
    selectedIntegrations?: string[];
    searchResults?: string[];
    origin?: string;
  };
};

export const TOKEN_VALID_SECONDS = 300;
