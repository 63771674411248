import * as React from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { UserLinkCell } from './UserLinkCell';
import { CompanyLinkCell } from './CompanyLinkCell';
import { useAdminNavigation } from './AppRouting';

type UsersTableProps = {
  users: any;
};

const getCompanyId = (user: any) => user.roles && Object.keys(user.roles)[0];

export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const { isLarge, isExtraLarge, isSmall, isExtraSmall } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, UserLinkCell),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: TruncateCell,
      },
      {
        Header: 'Company',
        accessor: 'companyName',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
      },
      {
        id: 'lastSeen',
        Header: 'Last active',
        accessor: 'lastSeen',
        Cell: DatetimeCell,
        width: isExtraLarge ? 224 : 164,
      },
    ],
    [isExtraLarge],
  );

  let hiddenColumns = isSmall || isExtraSmall ? ['name'] : [];
  hiddenColumns = !isLarge && !isExtraLarge ? [...hiddenColumns, 'lastSeen'] : hiddenColumns;

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={users}
        isPaginated
        smallPageControls
        onRowClick={user => navigation.navigateToUser(user._id)}
        hiddenColumns={hiddenColumns}
      />
    </BasicTableStyles>
  );
};
