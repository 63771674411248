import { useMemo, forwardRef, useRef, useCallback } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  ChartTypeRegistry,
  BubbleDataPoint,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { isEmpty, isNumber } from 'lodash';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Point } from 'chart.js/dist/core/core.controller';

ChartJS.register(
  ArcElement,
  PieController,
);

export type PieChartSlice = { id: string; value: number; label: string };

const colors = [
  'rgba(158,119,237,1)',
  'rgba(158,119,237,0.875)',
  'rgba(158,119,237,0.75)',
  'rgba(158,119,237,0.5)',
  'rgba(158,119,237,0.375)',
  'rgba(158,119,237,0.25)',
];

const inactiveColors = [
  'rgba(210,210,219,9)',
  'rgba(210,210,219,0.775)',
  'rgba(210,210,219,0.65)',
  'rgba(210,210,219,0.4)',
  'rgba(210,210,219,0.275)',
  'rgba(210,210,219,0.15)',
];

const activeColorWithNoData = 'rgba(226, 232, 239, 1)';

export const getActiveColor = (index: number) => colors[index % colors.length];

export const getInactiveColor = (index: number) =>
  inactiveColors[index % inactiveColors.length];

export const updateActiveSlice = (dataset, activeSliceId: string | null) => {
  if (!dataset.ids.includes(activeSliceId)) {
    dataset.backgroundColor = dataset.activeColors;
    dataset.hoverBackgroundColor = dataset.activeColors;
  } else {
    const backgroundColors = dataset.ids.map((id: string, index: number) => (
      id === activeSliceId
        ? dataset.activeColors[index]
        : dataset.inactiveColors[index]
    ));
    dataset.backgroundColor = backgroundColors;
    dataset.hoverBackgroundColor = backgroundColors;
  }
};

const noDataPlaceholder = {
  datasets: [
    {
      activeColors: [activeColorWithNoData],
      data: [1],
      ids: [],
      borderWidth: 0,
    },
  ],
};

const usePieChartData = (slices: PieChartSlice[]) => {
  return useMemo(() => {
    const activeColors = slices.map((_, index) => getActiveColor(index));
    const inactiveColors = slices.map((_, index) => getInactiveColor(index));

    return {
      labels: slices.map(slice => slice.label),
      datasets: [
        {
          data: slices.map(slice => slice.value),
          backgroundColor: activeColors,
          hoverBackgroundColor: activeColors,
          borderWidth: 0,

          ids: slices.map(slice => slice.id),
          activeColors,
          inactiveColors,
        },
      ],
    };
  }, [slices]);
};

export interface PieChartProps {
  slices: PieChartSlice[];
  description: string;
  onActiveSliceChange: (id: string | null) => void;
}

export type PieChartRef = ChartJSOrUndefined<
  keyof ChartTypeRegistry,
  (number | [number, number] | Point | BubbleDataPoint | null)[], unknown
> | null;

export const PieChart = forwardRef<PieChartRef, PieChartProps>(({
  onActiveSliceChange,
  description,
  slices,
}, ref) => {
  const previousActiveIndex = useRef(null);
  const hasData = !isEmpty(slices);

  // unset active slice when mouse leaves chart
  const handleMouseLeave = useCallback(() => {
    previousActiveIndex.current = null;
    onActiveSliceChange(null);
  }, [onActiveSliceChange]);

  const handleHover = useCallback((event) => {
    if (event.type === 'mousemove') {
      const activeElement = event.chart.getActiveElements()?.[0];

      if (!activeElement || !(activeElement.element instanceof ArcElement)) {
        if (isNumber(previousActiveIndex.current)) {
          previousActiveIndex.current = null;
          onActiveSliceChange(null);
        }
      } else if (
        activeElement.element instanceof ArcElement &&
        activeElement.index !== previousActiveIndex.current
      ) {
        previousActiveIndex.current = activeElement.index;

        const activeSliceId = event.chart.data
          .datasets[activeElement.datasetIndex]
          ?.ids
          ?.[activeElement.index];

        if (activeSliceId) {
          onActiveSliceChange(activeSliceId);
        }
      }
    }
  }, [onActiveSliceChange]);

  const options = useMemo(() => ({
    animation: {
      // omit initial animation when only showing the noDataPlaceholder
      duration: hasData ? 300 : 0,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 10,
        right: 20,
        bottom: 0,
        left: 0,
      },
    },
    onHover: handleHover,
  }), [handleHover, hasData]);

  const data = usePieChartData(slices);

  return (
    <Chart
      onMouseLeave={handleMouseLeave}
      ref={ref}
      type="pie"
      options={options}
      data={hasData ? data : noDataPlaceholder}
      width="100%"
      height="100%"
      fallbackContent={<p>{description}</p>}
    />
  );
});
