import NumberFormatBase, { NumberFormatProps } from 'react-number-format';
import { adjustNumberFormatOptionsToLocale } from '@deepstream/utils';
import { useCurrentUserLocale } from './useCurrentUser';

export const NumberFormat = (props: NumberFormatProps) => {
  const locale = useCurrentUserLocale();

  const adjustedProps = adjustNumberFormatOptionsToLocale(props, locale);

  return (
    <NumberFormatBase {...adjustedProps} />
  );
};
