import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '@deepstream/ui/form/TextField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

type EditNameProps =
  { user: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditNameModal: React.FC<EditNameProps> = ({ user, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateUserName] = useMutation(
    adminApi.updateUserName,
    {
      onSuccess: () => toaster.success('Name updated successfully'),
      onError: () => toaster.error('An error occurred while updating the name'),
      onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
        }}
        validationSchema={
          yup.object().shape({
            firstName: yup.string().trim().required('Required'),
            lastName: yup.string().trim().required('Required'),
          })
        }
        onSubmit={({ firstName, lastName }) =>
          updateUserName({
            userId: user._id,
            firstName,
            lastName,
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Edit name</ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <TextField name="firstName" label="First name" required />
                <TextField name="lastName" label="Last name" required />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
