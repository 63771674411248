import { Box, Flex, FlexProps, SxStyleProp, Text } from 'rebass/styled-components';
import { Icon } from './icon/Icon';

const variantToIcon = {
  info: 'info-circle',
  warn: 'exclamation-triangle',
  error: 'exclamation-circle',
  success: 'check-circle',
} as const;

const variantToColor = {
  info: 'primary',
  warn: 'warning',
  error: 'danger',
  success: 'success',
};

export type MessageBlockProps =
  { variant: 'info' | 'warn' | 'error' | 'success';
    contentSx?: SxStyleProp
   } &
  FlexProps;

export const MessageBlock = ({
  variant = 'info',
  children,
  contentSx,
  ...props
}: MessageBlockProps) => (
  <Flex
    mt={3}
    py={3}
    px={3}
    sx={{
      borderRadius: 4,
      borderColor: 'lightGray',
      borderWidth: 1,
      borderLeftWidth: 5,
      borderStyle: 'solid',
      borderLeftColor: variantToColor[variant],
      backgroundColor: 'white',
    }}
    alignItems="center"
    {...props}
  >
    <Icon
      color={variantToColor[variant]}
      icon={variantToIcon[variant]}
      mr={3}
      flex="0 0 auto"
    />
    <Box sx={{ ...contentSx }}>
      {children}
    </Box>
  </Flex>
);

const variantToIconProps = {
  info: {
    icon: 'info-circle',
    color: 'primary',
  },
  warn: {
    icon: 'exclamation-triangle',
    color: 'warning',
  },
  error: {
    icon: 'exclamation-circle',
    color: 'danger',
    regular: false,
  },
  success: {
    icon: 'check-circle',
    color: 'success',
  },
} as const;

/**
 * Variant of the MessageBlock component matching the design on the new bid pages
 */
export const MessageBlock2 = ({ variant = 'info', children, ...props }: MessageBlockProps) => (
  <Flex
    fontSize={4}
    fontWeight={500}
    lineHeight={1.4}
    {...props}
  >
    <Icon {...variantToIconProps[variant]} mr={2} fontSize={6} flex="0 0 auto" />
    <div>
      {children}
    </div>
  </Flex>
);

/**
 * Variant of the MessageBlock component matching the design on the new award flow pages
 */
export const MessageBlock3 = ({ variant = 'info', children, ...props }: MessageBlockProps) => {
  return (
    <Flex {...props}>
      <Icon {...variantToIconProps[variant]} mr={2} fontSize={6} flex="0 0 auto" />
      <Text fontWeight={500} fontSize={4}>
        {children}
      </Text>
    </Flex>
  );
};
