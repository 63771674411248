import * as React from 'react';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';
import { User } from './types';
import { UserInvitedBadge } from './Badge';

export const TeamUserCellTitle: React.FC<CellProps<User>> = ({ row: { original: user } }) => (
  <>
    {user.name || user.email}
    {user.pendingCreation && (
      <Box display="inline-block" sx={{ position: 'relative', top: '-2px', mb: '-4px' }}>
        <UserInvitedBadge />
      </Box>
    )}
  </>
);
