import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useAdminNavigation } from '../../AppRouting';
import { CompanyLinkCell } from '../../CompanyLinkCell';
import { QuestionnaireTemplateLinkCell } from './QuestionnaireTemplateLinkCell';

type RecentActivityQuestionnaireTemplatesTableProps = {
  templates: QuestionnaireTemplateOverview[];
  companyById: Record<string, CompanyMinimized>;
};

const getCompanyId = (template: QuestionnaireTemplateOverview) => template.meta.creatorId;

export const RecentActivityQuestionnaireTemplatesTable = ({ templates, companyById }: RecentActivityQuestionnaireTemplatesTableProps) => {
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Questionnaire',
        accessor: 'name',
        Cell: nestCells(TruncateCell, QuestionnaireTemplateLinkCell),
      },
      {
        Header: 'Creator',
        accessor: (row: QuestionnaireTemplateOverview) => companyById[row.meta.creatorId].name,
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
      },
      {
        Header: 'No. of suppliers',
        accessor: (row: QuestionnaireTemplateOverview) => row.supplierIds.length,
      },
      {
        Header: 'No. of questions',
        accessor: 'numQuestions',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
    ],
    [companyById],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={templates}
        isPaginated
        smallPageControls
        onRowClick={template => navigation.navigateToQuestionnaireTemplate(template._id)}
      />
    </BasicTableStyles>
  );
};
