import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

const BadgeWrapper = styled(Box)`
  border-width: 1px;
  border-style: solid;
  font-size: 10px;
  font-weight: 500;
  line-height: 0.9em;
  padding: 4px 8px;
  border-radius: 10rem;
  vertical-align: bottom;
  min-width: auto;
  overflow-wrap: normal;
`;

type BadgeProps = {
  tooltip?: any;
  color?: string;
} & BoxProps;

export const Badge: React.FC<BadgeProps> = ({ tooltip, children, color = 'subtext', ...props }) => (
  <Tooltip content={tooltip} placement="top">
    <BadgeWrapper color={color} {...props}>{children}</BadgeWrapper>
  </Tooltip>
);

export const UserInvitedBadge = () => {
  const { t } = useTranslation();

  return (
    <Badge tooltip={t('general.userHasBeenInvited')} ml={1}>
      {t('user.status.invited')}
    </Badge>
  );
};

export const CompanyInvitedBadge = () => {
  const { t } = useTranslation();

  return (
    <Badge tooltip={t('invite.hasNotSignedUpYet')} ml={1}>
      {t('invite.status.invited')}
    </Badge>
  );
};
