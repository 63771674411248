import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { QUESTIONS_PAGE_ID, QuestionnaireTeamMember } from '@deepstream/common/preQual';
import { TruncateCell } from '@deepstream/ui/TruncateCell';

export const AdminQuestionnairePermissionTable = ({
  user,
}: {
  user: QuestionnaireTeamMember & { isOwner: boolean };
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: 'Permission',
        accessor: (user: QuestionnaireTeamMember) => t(`teamManagement.pageRole.${user.roles[QUESTIONS_PAGE_ID]}`),
        Cell: TruncateCell,
      },
    ],
    [t],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={[user]}
      />
    </BasicTableStyles>
  );
};
