import * as React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import clsx from 'clsx';

const TagButton = styled.button<any>`
  font-size: 14px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 14px;
  padding-right: 14px;
  color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.gray};
  border: 1px solid ${props => props.selected ? '' : props.theme.colors.lightGray};
  background-color: ${props => props.selected ? props.theme.colors.primary : props.theme.colors.white};
  cursor: pointer;
`;

type TagProps = {
  id: string;
  name: string;
  selected?: boolean;
  onClick?: () => void;
  isCaseSensitive?: boolean;
};

const Tag: React.FC<TagProps> = ({ id, selected, onClick, name, isCaseSensitive }) => (
  <TagButton id={`tag-${id}`} className="level-3-tag" type="button" onClick={onClick} selected={selected}>
    {isCaseSensitive ? name : capitalize(name)}
    <i
      style={{ marginLeft: '.25rem', width: 12, fontSize: 12 }}
      className={clsx('fa', selected ? 'fa-check' : 'fa-plus')}
    />
  </TagButton>
);

export default Tag;
