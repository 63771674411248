import * as peggy from 'peggy';
// import formulaGrammar from './formula.pegjs';

// TODO: revisit using the formula from file
// nx/typescript has some issues with it still
const formulaGrammar = `
{
  function buildBinaryExpression(head, tail) {
    return tail.reduce(function(result, element) {
      return {
        type: "BinaryExpression",
        operator: element[1],
        left: result,
        right: element[3]
      };
    }, head);
  }
}

Formula
  = __ expression:AdditiveExpression __ { return { type: 'Formula', expression }; }

AdditiveExpression
  = head:MultiplicativeExpression
    tail:(__ AdditiveOperator __ MultiplicativeExpression)*
    { return buildBinaryExpression(head, tail); }
  / MultiplicativeExpression

MultiplicativeExpression
  = head:UnaryExpression
    tail:(__ MultiplicativeOperator __ UnaryExpression)*
    { return buildBinaryExpression(head, tail); }
  / PrimaryExpression

PrimaryExpression
  = NumericLiteral
  / FieldReference
  / "(" __ additive:AdditiveExpression __ ")" { return additive; }

UnaryExpression
  = operator:UnaryOperator argument:UnaryExpression {
        return {
          type: "UnaryExpression",
          operator: operator,
          argument: argument,
        };
      }
  / PrimaryExpression

UnaryOperator
  = AdditiveOperator

NumericLiteral "number"
  = literal:DecimalLiteral !(IdentifierStart / DecimalDigit) {
      return literal;
    }

DecimalLiteral
  = DecimalIntegerLiteral "." DecimalDigit* {
      return { type: "Literal", value: parseFloat(text()) };
    }
  / "." DecimalDigit+ {
      return { type: "Literal", value: parseFloat(text()) };
    }
  / DecimalIntegerLiteral {
      return { type: "Literal", value: parseFloat(text()) };
    }

FieldReference
  =  "{" __ head:IdentifierStart tail:([A-Za-z0-9\\-\\: ])+ "}" { return { type: 'FieldReference', name: head + tail.join('').trim() }; }

AdditiveOperator
  = "+"
  / "-"

MultiplicativeOperator
  = "*"
  / "/"

DecimalIntegerLiteral
  = DecimalDigit+

DecimalDigit
  = [0-9]

IdentifierStart
  = [a-zA-Z0-9]

__ "whitespace"
  = [ \\t\\v\\f]*
`;

export type Expression =
  | BinaryExpression
  | UnaryExpression
  | FieldReference
  | Literal;

export interface Formula {
  type: 'Formula';
  expression: Expression;
}

export interface BinaryExpression {
  type: 'BinaryExpression';
  left: Expression;
  operator: '+' | '-' | '*' | '/';
  right: Expression;
}

export interface UnaryExpression {
  type: 'UnaryExpression';
  operator: '+' | '-';
  argument: Expression;
}

export interface FieldReference {
  type: 'FieldReference';
  name: string;
}

export interface Literal {
  type: 'Literal';
  value: number;
}

export const formulaParser = peggy.generate(formulaGrammar);
