import { cloneDeep, findIndex, isEqual, ListIterateeCustom, reject } from 'lodash';
import * as React from 'react';
import { swap } from '@deepstream/utils/swap';
import { useWatchValue } from '../../hooks/useWatchValue';
import { EditedCell } from '../core/utils';

type CellInputState = {
  rowId: string;
  columnId: string;
  value: any;
};

export interface EditableGridDataContextType<T extends object = any> {
  pendingKeyboardEvent: React.MutableRefObject<React.KeyboardEvent<HTMLDivElement> | null>;
  /**
   * Holds the rowId, columnId, key and value of the currently edited input
   * element.
   *
   * We keep track of this data outside of the cell components to restore the
   * input value when the cell component gets remounted after being unmounted
   * (which occurs when scrolling the grid).
   */
  cellInputState: React.MutableRefObject<CellInputState | null>;
  /**
   * The grid data.
   */
  rowData: T[];
  /**
   * Indicates whether the `rowData` deviates from the `initialRowData`.
   */
  isDirty: boolean;
  /**
   * Minimum number of rows.
   */
  minRows?: number;
  /**
   * Maximum number of rows.
   */
  maxRows?: number;
  /**
   * Resets to row data to match the initial row data.
   */
  resetRowData: () => void;
  /**
   * Column and row IDs of the currently edited cell.
   */
  editedCell: EditedCell | null;
  /**
   * Sets the currently edited cell.
   */
  setEditedCell: React.Dispatch<React.SetStateAction<EditedCell | null>>;
  /**
   * Sets the initial row data which is used to determine whether the
   * row data has been modified.
   */
  setInitialRowData: (rowData: T[]) => void;
  /**
   * Updates the row data.
   */
  setRowData: React.Dispatch<React.SetStateAction<T[]>>
  /**
   * Updates each row with the provided updater function.
   */
  updateEachRowWith: (rowUpdater: (row: T, index: number) => T) => void;
  /**
   * Updates all rows with the provided updater function.
   */
  updateDataWith: (rowsUpdater: (rows: T[]) => T[]) => void;
  /**
   * Appends the provided rows.
   */
  appendRows: (rows: T[]) => void;
  /**
   * Moves the row at `index` to `index + delta`.
   */
  moveRow: (index: number, delta: number) => void;
  /**
   * Creates a new row at `sourceIndex + 1` with the data returned
   * from `createRow`.
   * The `sourceRow` argument of `createRow` is a deep clone of the
   * source row.
   */
  duplicateRow: (sourceIndex: number, createRow: (clonedSourceRow: T) => T) => void;
  /**
   * Removes the row at `index`.
   */
  removeRow: (index: number) => void;
  /**
   * Updates the row at `index` with the provided updater function.
   */
  updateRowAtIndex: (index: number, rowUpdater: (row: T) => T) => void;
  /**
   * Updates the first row for which `predicate` returns truthy with the
   * provided updater function and returns the updated row data.
   */
  updateFirstMatchingRow: (
    predicate: ListIterateeCustom<T, boolean>,
    rowUpdater: (row: T) => T
  ) => T[];
  /**
   * Sets the value of the cell at `rowId` / `columnId` to `value`.
   */
  setCellValue: (rowId: string, columnId: string, value: unknown) => T;
}

export const EditableGridDataContext = React.createContext<EditableGridDataContextType | null>(null);

const throwNoValueSetterProvided = () => {
  throw new Error('[EditableGridDataProvider] No value setter provided');
};

export const EditableGridDataProvider = ({
  enableReinitialize,
  rowData: providedRowData,
  minRows,
  maxRows,
  children,
  setValueInRow = throwNoValueSetterProvided,
}: {
  /**
   * Controls whether the form data should get reset if `rowData` changes.
   */
  enableReinitialize?: boolean;
  rowData: any[];
  minRows?: number;
  maxRows?: number;
  children: any;
  setValueInRow?: (
    row: any,
    columnId: string,
    value: unknown,
  ) => unknown;
}) => {
  // Using ref to prevent updates of initial state
  const initialRowData = React.useRef(providedRowData);

  const cellInputState = React.useRef<CellInputState | null>(null);
  const pendingKeyboardEvent = React.useRef<React.KeyboardEvent<HTMLDivElement> | null>(null);

  // The unsubmitted form row data which can deviate from the initialRowData.
  const [rowData, setRowData] = React.useState(cloneDeep(initialRowData.current));

  const isDirty = React.useMemo(
    () => !isEqual(initialRowData.current, rowData),
    [rowData],
  );

  const [editedCell, setEditedCell] = React.useState<EditedCell | null>(null);

  const updateFirstMatchingRow = React.useCallback((predicate, rowUpdater) => {
    let updatedRows;

    setRowData(rows => {
      const clonedRows = [...rows];
      const index = findIndex(rows, predicate);

      if (index > -1) {
        clonedRows.splice(index, 1, rowUpdater(rows[index]));
      }

      updatedRows = clonedRows;

      return clonedRows;
    });

    return updatedRows;
  }, [setRowData]);

  const setCellValue = React.useCallback((rowId: string, columnId: string, value: unknown) => {
    return updateFirstMatchingRow(
      { _id: rowId },
      row => setValueInRow(row, columnId, value),
    );
  }, [updateFirstMatchingRow, setValueInRow]);

  const updateRowAtIndex = React.useCallback((index, rowUpdater) => {
    setRowData(rows => {
      const clonedRows = [...rows];
      clonedRows.splice(index, 1, rowUpdater(rows[index]));
      return clonedRows;
    });
  }, [setRowData]);

  const resetRowData = React.useCallback(() => {
    setRowData(cloneDeep(initialRowData.current));
    cellInputState.current = null;
    setEditedCell(null);
  }, [setRowData, setEditedCell]);

  const setInitialRowData = React.useCallback((rowData) => {
    initialRowData.current = rowData;
  }, []);

  const updateEachRowWith = React.useCallback((rowUpdater) => {
    setRowData(rows => rows.map(rowUpdater));
  }, [setRowData]);

  const updateDataWith = React.useCallback((rowsUpdater) => {
    setRowData(rowsUpdater);
  }, [setRowData]);

  const appendRows = React.useCallback((rowsToAdd) => {
    setRowData(rows => [...rows, ...rowsToAdd]);
  }, [setRowData]);

  const moveRow = React.useCallback((index, delta) => {
    setRowData(rows => swap(rows, index, index + delta));
  }, [setRowData]);

  const duplicateRow = React.useCallback((sourceIndex, createRow) => {
    setRowData(rows => {
      const sourceRow = rows[sourceIndex];
      const newRow = createRow(cloneDeep(sourceRow));

      return [
        ...rows.slice(0, sourceIndex + 1),
        newRow,
        ...rows.slice(sourceIndex + 1),
      ];
    });
  }, [setRowData]);

  const removeRow = React.useCallback((index) => {
    setRowData(rows => reject(rows, (_, rowIndex) => rowIndex === index));
  }, [setRowData]);

  useWatchValue(
    providedRowData,
    React.useCallback((newProvidedRowData) => {
      if (enableReinitialize) {
        setEditedCell(null);
        cellInputState.current = null;
        setInitialRowData(newProvidedRowData);
        updateDataWith(() => cloneDeep(newProvidedRowData));
      }
    }, [enableReinitialize, setInitialRowData, updateDataWith, setEditedCell]),
  );

  const value = React.useMemo(() => {
    return {
      pendingKeyboardEvent,
      cellInputState,
      rowData,
      isDirty,
      minRows,
      maxRows,
      resetRowData,
      editedCell,
      setEditedCell,
      setInitialRowData,
      setRowData,
      updateEachRowWith,
      updateDataWith,
      appendRows,
      moveRow,
      duplicateRow,
      removeRow,
      updateFirstMatchingRow,
      updateRowAtIndex,
      setCellValue,
    };
  }, [
    rowData,
    isDirty,
    minRows,
    maxRows,
    resetRowData,
    editedCell,
    setEditedCell,
    setInitialRowData,
    setRowData,
    updateEachRowWith,
    updateDataWith,
    appendRows,
    moveRow,
    duplicateRow,
    removeRow,
    updateFirstMatchingRow,
    updateRowAtIndex,
    setCellValue,
  ]);

  return (
    <EditableGridDataContext.Provider value={value}>
      {children}
    </EditableGridDataContext.Provider>
  );
};

export const useEditableGridData = <T extends object = any>() => {
  const state = React.useContext(EditableGridDataContext);
  if (!state) throw new Error('No grid form state found');
  return state as EditableGridDataContextType<T>;
};
