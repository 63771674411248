import { Flex } from 'rebass/styled-components';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { CTAClickedUsersTable } from './CTAClickedUsersTable';
import { renderPercentage } from '../reports/utils';

export const CTAClickedUsersModal = ({
  uniqueUsers,
  uniquePageOpenedUsers,
  uniqueCompanies,
  uniquePageOpenedCompanies,
  ...props
}: Omit<DialogProps, 'heading' | 'body'> & {
  uniqueUsers;
  uniquePageOpenedUsers;
  uniqueCompanies;
  uniquePageOpenedCompanies;
}) => {
  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading="Conversion funnel - 3. CTA Clicked"
      body={(
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Unique users (Step 1)"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniquePageOpenedUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Funnel conversion rate"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: uniqueUsers.length,
                  total: uniquePageOpenedUsers.length,
                })}
              />
            </Flex>
            <Flex>
              <LabeledValue
                label="Unique companies"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueCompanies.total}
                mr={4}
              />
              <LabeledValue
                label="Unique companies (Step 1)"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniquePageOpenedCompanies.total}
                mr={4}
              />
              <LabeledValue
                label="Funnel conversion rate"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: uniqueCompanies.total,
                  total: uniquePageOpenedCompanies.total,
                })}
              />
            </Flex>
          </Flex>
          <CTAClickedUsersTable uniqueUsers={uniqueUsers} />
        </Stack>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
