import { useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, map } from 'lodash';
import { ProductTag } from '@deepstream/common/products';
import { Panel, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { FilteredList, FilterListItem, SearchRow } from './FilteredList';
import { Item } from './List';
import { ProductsActions, ProductsState, productToFilterItem, productToItem, getAncestorIds } from './useProducts';

export const FilteredProductsPanel = ({
  showSearchResults,
  productsState,
  productsActions,
  panelHeight = '450px',
  isReadOnly,
  products,
  isProductsLoading,
  isProductsError,
  isSearching,
  isSearchError,
  refetchProducts,
  refetchSearch,
  panelProps,
}: {
  showSearchResults: boolean;
  productsState: ProductsState;
  productsActions: ProductsActions;
  panelHeight?: string;
  isReadOnly?: boolean;
  products: ProductTag[];
  isProductsLoading: boolean;
  isProductsError: boolean;
  isSearching: boolean;
  isSearchError: boolean;
  refetchProducts: () => void;
  refetchSearch: () => void;
  panelProps?: Partial<PanelProps>;
}) => {
  const { t } = useTranslation();
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelOverflows, setPanelOverflows] = useState(false);

  const { filterId, searchText, searchResultIds, productById, selectedProductIds } = productsState;

  const items: Item[] = useMemo(
    () => {
      const allItems = showSearchResults
        ? searchResultIds.map(productId => productToItem(productById[productId], selectedProductIds, t))
        : products.map(product => productToItem(product, selectedProductIds, t));

      return isReadOnly
        // In readOnly mode we only need to show the products that are selected or have selected children.
        ? filter(
          allItems,
          item => Boolean(item.numSelectedChildren),
        )
        : allItems;
    },
    [products, selectedProductIds, searchResultIds, productById, showSearchResults, isReadOnly, t],
  );

  const filterItems = useMemo(
    () => {
      if (filterId) {
        const productIds = [...getAncestorIds(filterId, productById), filterId];

        return map(
          productIds,
          productId => productToFilterItem(productById[productId], selectedProductIds, filterId, t),
        );
      }

      return [];
    },
    [filterId, productById, selectedProductIds, t],
  );

  useEffect(
    () => {
      const element = panelRef.current;
      const overflows = Boolean(element && element.scrollHeight > element.clientHeight);

      setPanelOverflows(overflows);
    },
    [products, searchResultIds],
  );

  return (
    <Panel ref={panelRef} height={panelHeight} overflowY="auto" {...panelProps}>
      <FilteredList
        items={items}
        depth={4}
        filterItems={filterItems}
        isLoading={isProductsLoading}
        showSearchResults={showSearchResults}
        isError={isProductsError || isSearchError}
        isReadOnly={isReadOnly}
        errorText={t('productsAndServices.errors.cannotLoadTags')}
        emptyListText={!isReadOnly || Boolean(searchText.length) ? t('productsAndServices.noTagsFound') : undefined}
        sx={{
          borderBottom: panelOverflows || !items.length ? '' : 'lightGray',
        }}
        header={
          filterItems.length ? (
            <FilterListItem
              item={{
                _id: 'all',
                label: t('productsAndServices.allProductsAndServices'),
                canSelectFilter: true,
                numSelectedChildren: selectedProductIds.length,
              }}
              onSelectFilter={() => productsActions.setFilterId(null)}
            />
          ) : (
            <SearchRow
              label={t('productsAndServices.allProductsAndServices')}
              isSearching={isSearching}
              canClearSearch={Boolean(searchText.length)}
              initialSearchText={searchText}
              placeholder={t('productsAndServices.searchPlaceholder')}
              numSelectedChildren={selectedProductIds.length}
              onSearch={productsActions.updateSearchText}
              onClearSearch={productsActions.clearSearch}
            />
          )
        }
        onToggleItem={productsActions.toggleProduct}
        onSelectFilter={productsActions.setFilterId}
        onRetry={isSearchError ? refetchSearch : refetchProducts}
      />
    </Panel>
  );
};
