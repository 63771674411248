import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const DoughnutChartRequestCountOverlay = ({
  activeSliceId,
  selectedValue,
  totalValue,
}: {
  activeSliceId: string | null;
  selectedValue: number;
  totalValue: number;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <Text fontSize="36px" fontWeight={500}>
        {localeFormatFactorAsPercent(selectedValue / totalValue, { locale, decimalPlaces: 1 })}
      </Text>
      <Text fontSize={1}>
        {selectedValue} / {t('requests.requestCount', { count: totalValue })}
      </Text>
    </Flex>
  );
};
