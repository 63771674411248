import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Color } from '@deepstream/ui-kit/theme/types';
import { Bold } from '@deepstream/ui/Bold';
import { Box, Flex, Text } from 'rebass/styled-components';

const getPreviousValueColor = (value: number, previousValue: number): Color => {
  if (value > previousValue) {
    return 'success';
  } else if (value < previousValue) {
    return 'danger';
  } else {
    return 'subtext';
  }
};

const PreviousValueDiffIcon = ({ value, previousValue, ...props }: Omit<IconProps, 'icon'> & { value: number, previousValue: number }) => {
  if (value > previousValue) {
    return <Icon icon="arrow-up" {...props} />;
  } else if (value < previousValue) {
    return <Icon icon="arrow-down" {...props} />;
  } else {
    return null;
  }
};

export const MetricsValueWithPreviousValue = ({ value, previousValue, previousValueLabel }) => {
  return (
    <Flex alignItems="center">
      <Box>
        {value}
      </Box>
      <Box ml="24px" lineHeight={1.25} fontWeight={400} color={getPreviousValueColor(value, previousValue)}>
        <Text fontSize={2}>
          <PreviousValueDiffIcon value={value} previousValue={previousValue} mr={1} />
          <Bold>{previousValue}</Bold>
        </Text>
        <Text fontSize={1}>
          {previousValueLabel}
        </Text>
      </Box>
    </Flex>
  );
};
