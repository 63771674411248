import { useMemo } from 'react';
import { FlexProps } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '@deepstream/ui/rfx';
import { getRecipientsWithAuctionAccess } from '@deepstream/common/rfq-utils';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { RfqIdProvider } from '@deepstream/ui/useRfq';
import { useInvalidateQueryOnMessage } from '@deepstream/ui/useInvalidateQueryOnMessage';
import { Page } from './Page';
import { AuctionRecipientsTable } from './AuctionRecipientsTable';
import { RequestSendersTable } from './RequestSendersTable';
import * as Title from './title';
import { useAdminRequest, useAdminRequestQueryKey } from './adminRequest';
import { RequestLink } from './RequestLink';
import { AuctionPropertyList } from './AuctionPropertyList';
import { requestAuctionRoute, useAdminNavigation } from './AppRouting';

export const PageTitle = ({
  flexDirection,
  requestId,
}: {
  flexDirection?: FlexProps['flexDirection'];
  requestId: string;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <Title.Auction
        mr={flexDirection === 'column' ? 0 : 2}
        size={flexDirection === 'column' ? 'large' : 'small'}
      />
      <RequestLink
        requestId={requestId}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Request
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          requestId={requestId}
        />
      </RequestLink>
    </Title.Container>
  );
};

export const AuctionPage = () => {
  const { requestId, auctionId } = requestAuctionRoute.useParams();
  const [ref, inView, entry] = useInView();
  const navigation = useAdminNavigation();

  if (!requestId || !auctionId) {
    throw new Error('A `requestId` and an `auctionId` are required');
  }

  const { data: { structure: rfxStructure } = {}, error, status } = useAdminRequest(requestId);
  const queryKey = useAdminRequestQueryKey(requestId);

  useInvalidateQueryOnMessage(`rfx.${requestId}`, queryKey);

  const auction = useMemo(
    () => rfxStructure?.auction,
    [rfxStructure],
  );

  if (auction && auction._id !== auctionId) {
    throw new Error(`Request ${requestId} does not have an auction with id ${auctionId}`);
  }

  const recipientsWithAuctionAccess = useMemo(
    () => rfxStructure ? getRecipientsWithAuctionAccess(rfxStructure) : [],
    [rfxStructure],
  );

  return (
    <Page
      subHeading={rfxStructure && entry && !inView && (
        <rfx.StructureProvider structure={rfxStructure}>
          <PageTitle requestId={requestId} />
        </rfx.StructureProvider>
      )}
    >
      {status === 'loading' ? (
        <Panel>
          <PanelText><Loading /></PanelText>
        </Panel>
      ) : status === 'error' ? (
        <Panel heading="Error">
          <PanelText color="danger">
            <Icon icon="exclamation-circle" mr={2} />
            {(error as any)?.toString()}
          </PanelText>
        </Panel>
      ) : rfxStructure ? (
        <RfqIdProvider rfqId={requestId}>
          <rfx.StructureProvider structure={rfxStructure}>
            <div ref={ref}>
              <PageTitle
                flexDirection="column"
                requestId={requestId}
              />
            </div>

            <Panel heading="General" mb={4}>
              <AuctionPropertyList />
            </Panel>

            <Panel heading="Senders" mb={4}>
              <RequestSendersTable
                onRowClick={sender => navigation.navigateToRequestCompany(requestId, sender.company._id)}
              />
            </Panel>

            <Panel mb={4}>
              <PanelHeader heading="Recipients" />
              <PanelDivider />
              {recipientsWithAuctionAccess.length ? (
                <AuctionRecipientsTable />
              ) : (
                <PanelText color="gray">No recipients yet</PanelText>
              )}
            </Panel>
          </rfx.StructureProvider>
        </RfqIdProvider>
      ) : (
        null
      )}
    </Page>
  );
};
