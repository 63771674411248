import { useEffect } from 'react';
import { isFunction } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { Box } from 'rebass/styled-components';
import { ErrorMessage } from './Field';

type HiddenFieldProps = {
  name: string;
  value: any | (() => any);
  showError?: boolean;
};

/**
 * Formik-aware hidden field component.
 */
export const HiddenField = ({
  name,
  value,
  showError,
}: HiddenFieldProps) => {
  const { values } = useFormikContext();
  const [field, { error }, { setValue }] = useField({ name });

  useEffect(
    () => {
      const nextValue = isFunction(value) ? value(values) : value;

      if (field.value !== nextValue) {
        setValue(nextValue);
      }
    },
    [values, field.value, setValue, value],
  );

  return showError && error ? (
    <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
      <ErrorMessage error={error} fontWeight="normal" />
    </Box>
  ) : (
    null
  );
};
