import * as React from 'react';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';

export const YesNoCell: React.FC<any> = ({ cell: { value }, yesIcon = null, noIcon = null, color, iconColor, isIconRegular }: any) => (
  <>
    {(yesIcon || noIcon) ? (
      <IconText
        icon={value ? yesIcon : noIcon}
        iconColor={iconColor || (value ? 'success' : 'danger')}
        isIconRegular={isIconRegular}
        color={color || (value ? 'success' : 'danger')}
        text={value ? 'Yes' : 'No'}
      />
    ) : (
      value ? 'Yes' : 'No'
    )}
  </>
);
