import { useTranslation } from 'react-i18next';
import { useToaster } from '@deepstream/ui/toast';
import { callAll } from '@deepstream/utils/callAll';
import { getErrorMessage, getSuccessMessage, UpdateMutationArgs } from '@deepstream/ui/useSaveRequestTeamChanges';
import * as adminRequest from './adminRequest';

export const useSaveAdminChanges = ({ companyId, onSuccess, onError }: UpdateMutationArgs & { companyId: string }) => {
  const toaster = useToaster();
  const { t } = useTranslation();

  return adminRequest.useSaveChanges({
    companyId,
    getChanges: ({ changes }) => changes,
    onSuccess: callAll(
      onSuccess,
      (_, payload) => toaster.success(getSuccessMessage(t, payload?.changes?.[0]?.type)),
    ),
    onError: callAll(
      onError,
      (_, payload) => toaster.error(getErrorMessage(t, payload?.changes?.[0]?.type)),
    ),
  });
};
