import { Link, LinkProps } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { UnstyledLink } from './ui/UnstyledLink';
import { useEnv } from './env';

export const HelpCenterLink = ({ text, path }: { text: string; path: string }) => {
  const { HELP_CENTER_URL } = useEnv();
  const theme = useTheme();

  return (
    <UnstyledLink
      href={`${HELP_CENTER_URL}${path}`}
      target="_blank"
      flex={1}
      sx={{
        // This is only needed for overwriting angular styles
        color: `${theme.colors.text} !important`,
      }}
    >
      <IconText
        icon="question-circle"
        text={text}
        textStyle={{ fontWeight: 500 }}
      />
    </UnstyledLink>
  );
};

export const HelpCenterInlineLink = ({ path, ...props }: LinkProps & { path: string }) => {
  const { HELP_CENTER_URL } = useEnv();
  const theme = useTheme();

  return (
    <Link
      href={`${HELP_CENTER_URL}${path}`}
      target="_blank"
      sx={{
        // This is only needed for overwriting angular styles
        color: `${theme.colors.primary} !important`,
        fontWeight: 500,
      }}
      {...props}
    />
  );
};
