import { useMemo } from 'react';

import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';

export const RfxToExternalEntityDataTable = ({ rfxToExternalEntityCompanyData }) => {
  const rfxData = Object
    .entries(rfxToExternalEntityCompanyData[0].data as Record<string, { externalEntityId: string }>[])
    .map(([rfxId, { externalEntityId }]) => ({
      rfxId,
      externalEntityId,
    }));

    const rfxColumns = useMemo(
      () => [
        {
          Header: 'RFX ID',
          accessor: 'rfxId',
        },
        {
          Header: 'External Entity ID',
          accessor: 'externalEntityId',
        },
      ],
      [],
    );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={rfxColumns}
        data={rfxData}
      />
    </StaticTableStyles>
  );
};
