import { useMemo } from 'react';
import { Flex } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { renderPercentage } from './utils';
import { ClientAdoptionGrid, FeatureAdoption } from './ClientAdoptionGrid';

export const ClientAdoptionRateModal = ({
  heading,
  featureAdoption,
  adoptedColumnLabel,
  detailsColumnLabel,
  hideNonAdopted,
  ...props
}: Omit<DialogProps, 'heading' | 'body'> & {
  heading: string;
  featureAdoption: FeatureAdoption[];
  adoptedColumnLabel?: string;
  detailsColumnLabel?: string;
  hideNonAdopted?: boolean;
}) => {
  const count = featureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length;
  const total = featureAdoption.length;

  const gridData = useMemo(
    () => hideNonAdopted
      ? featureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature)
      : featureAdoption,
    [featureAdoption, hideNonAdopted],
  );

  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading={heading}
      body={(
        <>
          <Flex justifyContent="space-between" mb="20px">
            <LabeledValue
              label="Rate"
              align="left"
              labelProps={{ color: 'text' }}
              value={renderPercentage({ count, total })}
            />
            <Flex>
              <LabeledValue
                label="Yes"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="success" icon="circle-check" regular mr={1} />
                    {count}
                  </>
                )}
                mr={4}
              />
              <LabeledValue
                label="No"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="danger" icon="circle-xmark" regular mr={1} />
                    {total - count}
                  </>
                )}
              />
            </Flex>
          </Flex>
          <ClientAdoptionGrid
            data={gridData}
            adoptedColumnLabel={adoptedColumnLabel}
            detailsColumnLabel={detailsColumnLabel}
            hideAdoptedColumn={hideNonAdopted}
          />
        </>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
