import { createContext, useContext } from 'react';
import { MediaQueries, useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';

export const DeviceContext = createContext<MediaQueries>({} as MediaQueries);

export const DeviceContextProvider = (props: { children: React.ReactNode }) => {
  const deviceSize = useMediaQueries();

  return (
    <DeviceContext.Provider value={deviceSize} {...props} />
  );
};

export const useDeviceSize = () => {
  const deviceSize = useContext(DeviceContext);

  return deviceSize;
};
