import { LotIntentionStatus } from '@deepstream/common/rfq-utils';

const iconProps = {
  [LotIntentionStatus.DECLINED_TO_BID]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
  [LotIntentionStatus.BID_WITHDRAWN]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
  [LotIntentionStatus.BIDDING]: {
    icon: 'circle-check',
    regular: true,
    color: 'primary',
  },
} as const;

export const iconPropsByLotIntentionStatus = {
  buyer: {
    ...iconProps,
    [LotIntentionStatus.NO_RESPONSE]: {
      icon: 'circle-dashed',
      color: 'subtext',
    },
  },
  supplier: {
    ...iconProps,
    [LotIntentionStatus.NO_RESPONSE]: {
      icon: 'exclamation-circle',
      color: 'danger',
    },
  },
} as const;
