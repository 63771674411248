import * as React from 'react';
import { useIsMounting } from './useIsMounting';
import { usePrevious } from './usePrevious';

const isStrictlyEqual = (a: any, b: any) => a === b;

// Watch the `value` and call `onChange` whenever it changes. The `isEqual` function
// determines whether the value has changed.
export const useWatchValue = <T,>(
  value: T,
  onChange: (next: T, previous?: T) => void,
  isEqual = isStrictlyEqual,
  useEffect = React.useEffect,
) => {
  const previousValue = usePrevious<T>(value);

  // Since the `previousValue` always starts with `undefined`, we track the
  // initial render to avoid calling `onChange`
  const isMounting = useIsMounting();

  useEffect(
    () => {
      if (!isMounting && !isEqual(value, previousValue)) {
        onChange(value, previousValue);
      }
    },
    [isMounting, onChange, value, previousValue, isEqual],
  );
};

// Watch the `value` and call `onChange` whenever it changes. The `isEqual` function
// determines whether the value has changed.
export const useWatchValueLayout = (
  value: any,
  onChange: any,
  isEqual = isStrictlyEqual,
) => {
  useWatchValue(value, onChange, isEqual, React.useLayoutEffect);
};
