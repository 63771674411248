import { useState, useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { Text, Flex, FlexProps, Box } from 'rebass/styled-components';
import { uniqueId, sumBy, round } from 'lodash';
import { Color } from '../theme/types';
import { Icon } from '../elements/icon/Icon';
import { Tooltip } from '../elements/popup/Tooltip';

const CIRCLE_RADIUS = 45;
const CIRCLE_CIRCUMFERENCE = Math.round(2 * Math.PI * CIRCLE_RADIUS);

type Variant = 'info' | 'warn' | 'error' | 'success' | 'secondary';

const VariantColorMap: Partial<{ [k in Variant]: Color }> = {
  'secondary': 'lightGray2',
  'info': 'primary',
  'warn': 'warning',
  'error': 'danger',
  'success': 'success',
};

const GaugeContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

const GaugeContent = styled.div`
  position: absolute;
  top: 15%;
  left: 15%;
  right: 15%;
  bottom: 15%;
`;

const ProgressCircle = styled.circle<{
  variant?: Variant,
  hasTooltip?: boolean;
  offset: number;
  value: number,
}>`
  stroke: ${(props) => {
    const color = props.variant ? VariantColorMap[props.variant] : VariantColorMap.info;

    return props.theme.colors[color!];
  }};
  cursor: ${(props) => props.hasTooltip ? 'pointer' : 'default'};
  pointer-events: stroke;
  stroke-width: 10%;
  fill: transparent;
  // svg draws circle from 3o'clock so we need to rotate a bit to start from top
  transform: rotate(-90deg);

  // 1st value = bar width
  // 2nd value = gap between bars - full circle size gap to be sure they do not overlap
  stroke-dasharray: ${(props) => CIRCLE_CIRCUMFERENCE * props.value} ${CIRCLE_CIRCUMFERENCE};
  stroke-dashoffset: ${(props) => CIRCLE_CIRCUMFERENCE * props.offset * -1};
  transition: stroke-dashoffset 0.3s ease-in;

  &:active, &:focus {
    outline: none;
  }
`;

type Value = {
  id: string;
  progress: number;
  variant: Variant;
  label: string;
};

const Label = ({
  text,
  color,
  ...props
}: {
  color: Color,
  text: string;
} & FlexProps) => {
  return (
    <Flex alignItems="center" {...props}>
      <Icon fontSize={1} color={color} icon="circle" />
      <Text fontSize={2} ml={1}>{text}</Text>
    </Flex>
  );
};

const toPercentage = (value, total) => {
  const percentage = (value / total) * 100;

  return round(percentage, 2);
};

export const CircularChart = ({
  values: valuesProp,
  tooltip = (value) => `${value.label}: ${value.progress}`,
  children,
}: {
  values: Omit<Value, 'id'>[];
  tooltip?: ((value: Value) => React.ReactNode) | false;
  children?: React.ReactNode;
}) => {
  const [hoverId, setHoverId] = useState<string | undefined>(undefined);
  const hasTooltip = !!tooltip;

  const values = useMemo(() => {
    return valuesProp.map((value) => ({
      ...value,
      id: uniqueId('value_'),
    }));
  }, [valuesProp]);

  const total = sumBy(values, (value) => value.progress);

  return (
    <Flex justifyContent="space-between" alignItems="flex-start" sx={{ gap: 2 }}>
      <Flex mb={2} flexDirection="column" sx={{ gap: 1 }}>
        {values.map((value) => (
          <Label
            key={value.id}
            text={value.label}
            color={VariantColorMap[value.variant]!}
            sx={{ opacity: hasTooltip && hoverId && hoverId !== value.id ? 0.5 : 1 }}
          />
        ))}
      </Flex>
      <Box sx={{ flex: '1 1 auto', ml: 2 }}>
        <GaugeContainer>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100">
            {values.map((value, i, arr) => {
              const offset = sumBy(arr.slice(0, i), (el) => toPercentage(el.progress, total));
              const valueAsPercentage = toPercentage(value.progress, total);
              //
              // if (!offset || !valueAsPercentage) {
              //   return null;
              // }

              return (
                <Tooltip
                  followCursor
                  key={value.id}
                  content={hasTooltip && (
                    <Text>{tooltip(value)}</Text>
                  )}
                >
                  <ProgressCircle
                    role="progressbar"
                    aria-labelledby={value.label}
                    aria-valuenow={value.progress}
                    aria-valuemax={total}
                    hasTooltip={hasTooltip}
                    onMouseEnter={() => setHoverId(value.id)}
                    onMouseLeave={() => setHoverId(undefined)}
                    rx={0}
                    ry={0}
                    r={CIRCLE_RADIUS}
                    offset={offset / 100}
                    value={valueAsPercentage / 100}
                    variant={value.variant}
                  />
                </Tooltip>
              );
            })}
          </svg>
          {children && (
            <GaugeContent>{children}</GaugeContent>
          )}
        </GaugeContainer>
      </Box>
    </Flex>
  );
};
