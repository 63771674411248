import { mapValues, pickBy } from 'lodash';
import { TFunction } from 'i18next';
import { bidStatusesConfig, requestStatusesConfig, universalBidStatusesConfig } from './statusConfigs';
import { BidStatus, RfqStatus, UniversalBidStatus } from './types';

export const getBidStatusLabels = (t: TFunction) => {
  const filteredConfig = pickBy(bidStatusesConfig, config => !config.isDeprecated);

  return mapValues(
    filteredConfig,
    config => t(`request.bidStatus.${config.status}`),
  ) as Record<BidStatus, string>;
};

export const getUniversalBidStatusLabels = (t: TFunction) => {
  const filteredConfig = pickBy(universalBidStatusesConfig, config => !config.isDeprecated);

  return mapValues(
    filteredConfig,
    config => t(`request.universalBidStatus.${config.status}`),
  ) as Record<UniversalBidStatus, string>;
};

export const getRfqStatusLabels = (t: TFunction) => {
  return mapValues(
    requestStatusesConfig,
    config => t(`request.status.${config.status}`),
  ) as Record<RfqStatus, string>;
};
