import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useContractId, useContractState } from './contract';
import { MutationArgs } from '../../types';
import { useAdminContractQueryKey, useContractQueryKey } from './useContract';
import { useIsAppAdmin } from '../../useIsAppAdmin';

export const useContractMutationArgs = ({ name, onSuccess, onError, onSettled }: { name: string } & MutationArgs) => {
  const { t } = useTranslation('contracts');
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const isAppAdmin = useIsAppAdmin();

  // @ts-ignore ts(2339) FIXME: Property 'isDraft' does not exist on type 'ContractStateContextType | undefined'.
  const { isDraft, isTemplate } = useContractState();
  const contractQueryKey = useContractQueryKey({
    contractId,
    currentCompanyId,
    scope: isDraft ? 'draft' : 'current',
    isTemplate,
  });

  const adminContractQueryKey = useAdminContractQueryKey(contractId);

  const queryKey = isAppAdmin ? adminContractQueryKey : contractQueryKey;

  const queryClient = useQueryClient();

  return {
    mutationKey: queryKey,
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t(`toaster.${name}.success`)),
    ),
    onError: callAll(
      onError,
      () => toaster.error(t(`toaster.${name}.failed`)),
    ),
    onSettled: callAll(
      onSettled,
      () => queryClient.invalidateQueries(queryKey),
    ),
  };
};
