import { pick, values } from 'lodash';
import { ExchangeType } from './rfq-utils/types';
import { IconValue } from './icons';

export enum FieldType {
  STRING = 'string',
  NUMBER = 'number',
  ARRAY = 'array',
  DATE = 'date',
  ADDRESS = 'address',
  ATTACHMENT = 'attachment',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
  PRICE = 'price',
  FORMULA = 'formula',
  UNSPSC_CODE = 'unspscCode',
  SCORE_POINTS = 'scorePoints',
  PERCENTAGE = 'percentage',
}

export const fieldIconByType: Partial<Record<FieldType, IconValue>> = {
  [FieldType.STRING]: 'text',
  [FieldType.NUMBER]: 'hashtag',
  [FieldType.DATE]: 'calendar',
  [FieldType.PRICE]: 'dollar-sign',
  [FieldType.FORMULA]: 'function',
  [FieldType.PERCENTAGE]: 'percent',
};

/**
 * @deprecated
 */
export interface Field {
  key: string;
  label: string;
  labelId?: string;
  type: FieldType;
  isDisplayName?: boolean;
  isOptional?: boolean;
  isHidden?: boolean;
}

export interface ExchangeConfig {
  type: ExchangeType;
  label: string;
  description?: string;
  hasLock?: boolean;
  fields: Field[];
}

export type ExchangesConfig = Record<ExchangeType, ExchangeConfig>;

export const exchangesConfig: ExchangesConfig = {
  [ExchangeType.LINE_ITEM]: {
    type: ExchangeType.LINE_ITEM,
    label: 'Line item',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'quantity', labelId: 'quantity', label: 'Quantity', type: FieldType.NUMBER },
      { key: 'unit', labelId: 'unit', label: 'Unit', type: FieldType.STRING },
      { key: 'targetPrice', labelId: 'targetPricePerUnit', label: 'Target price per unit', type: FieldType.NUMBER },
      { key: 'hasLeadTime', labelId: 'leadTime', label: 'Lead time', type: FieldType.BOOLEAN },
      { key: 'deliveryDate', labelId: 'deliveryDate', label: 'Delivery date', type: FieldType.DATE },
      { key: 'deliveryDateType', labelId: 'deliveryDateType', label: 'Delivery date type', type: FieldType.STRING },
    ],
  },

  [ExchangeType.HIRE_PERIOD]: {
    type: ExchangeType.HIRE_PERIOD,
    label: 'Hire period',
    fields: [
      { key: 'intervalType', labelId: 'type', label: 'Type', type: FieldType.STRING },
      { key: 'amount', labelId: 'amount', label: 'Amount', type: FieldType.NUMBER },
      { key: 'unit', labelId: 'unit', label: 'Unit', type: FieldType.STRING },
      { key: 'quantity', labelId: 'quantity', label: 'Quantity', type: FieldType.NUMBER },
    ],
  },

  [ExchangeType.FEES]: {
    type: ExchangeType.FEES,
    label: 'Fee',
    fields: [
      { key: 'feeType', labelId: 'type', label: 'Type', type: FieldType.STRING },
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING },
    ],
  },

  [ExchangeType.INCLUSIONS]: {
    type: ExchangeType.INCLUSIONS,
    label: 'Inclusion',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'option', labelId: 'option', label: 'Option', type: FieldType.STRING },
    ],
  },

  [ExchangeType.TERMS]: {
    type: ExchangeType.TERMS,
    label: 'Additional term',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
    ],
  },

  [ExchangeType.PAYMENT_STAGE]: {
    type: ExchangeType.PAYMENT_STAGE,
    label: 'Payment milestone',
    fields: [
      { key: 'milestone', labelId: 'paymentMilestone', label: 'Payment milestone', type: FieldType.STRING, isDisplayName: true },
      { key: 'percentage', labelId: 'percentage', label: 'Percentage', type: FieldType.STRING },
      { key: 'days', labelId: 'nettDays', label: 'Nett days', type: FieldType.STRING },
    ],
  },

  [ExchangeType.CURRENCY]: {
    type: ExchangeType.CURRENCY,
    label: 'Currency',
    fields: [
      { key: 'currencies', labelId: 'currencies', label: 'Currencies', type: FieldType.ARRAY },
      { key: 'isFixed', labelId: 'isFixed', label: 'Is fixed?', type: FieldType.BOOLEAN, isHidden: true },
    ],
  },

  [ExchangeType.INCOTERMS]: {
    type: ExchangeType.INCOTERMS,
    label: 'Incoterms',
    fields: [
      { key: 'incoterms', labelId: 'incoterms', label: 'Incoterms', type: FieldType.STRING },
    ],
  },

  [ExchangeType.DELIVERY_DEADLINE]: {
    type: ExchangeType.DELIVERY_DEADLINE,
    label: 'Delivery date',
    fields: [
      { key: 'deliveryBy', labelId: 'date', label: 'Date', type: FieldType.DATE },
    ],
  },

  [ExchangeType.DELIVERY_ADDRESS]: {
    type: ExchangeType.DELIVERY_ADDRESS,
    label: 'Delivery address',
    fields: [
      { key: 'deliveryTo', labelId: 'address', label: 'Address', type: FieldType.ADDRESS },
    ],
  },

  [ExchangeType.INFORMATION]: {
    type: ExchangeType.INFORMATION,
    label: 'For information only',
    description: 'A document that does not require any actions from the counterparty.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.COMPLETE_OR_SIGN]: {
    type: ExchangeType.COMPLETE_OR_SIGN,
    label: 'To complete/sign or deviate',
    description: 'A document that is required to be completed and/or signed by the counterparty. Deviations on the initial form/contract can be offered the counterparty.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: {
    type: ExchangeType.COMPLETE_OR_SIGN_CLOSED,
    label: 'To complete/sign',
    description: 'A document that is required to be completed and/or signed by the counterparty. No ability to deviate or post stand-alone comments.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: {
    type: ExchangeType.COMPLETE_OR_SIGN_LOCKED,
    label: 'To complete/sign',
    description: 'A document that is required to be completed and/or signed by the counterparty. Upon submission, the document will be locked until the unlocking condition is met. Deviations cannot be offered by the counterparty.',
    hasLock: true,
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.ACCEPT_CLOSED]: {
    type: ExchangeType.ACCEPT_CLOSED,
    label: 'To accept',
    description: 'A document that requires the counterparty to accept. No ability to deviate or post stand-alone comments.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.ACCEPT]: {
    type: ExchangeType.ACCEPT,
    label: 'To accept or deviate',
    description: 'A document that requires the counterparty to accept or offer deviations.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.DOCUMENT_REQUEST]: {
    type: ExchangeType.DOCUMENT_REQUEST,
    label: 'Document request',
    description: 'A request for the counterparty to submit the specified document. The counterparty has the option to mark the document as unavailable.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
    ],
  },

  [ExchangeType.DOCUMENT_REQUEST_CLOSED]: {
    type: ExchangeType.DOCUMENT_REQUEST_CLOSED,
    label: 'Document request (closed)',
    description: 'A request for the counterparty to submit the specified document. No ability to deviate on the submitted document or post stand-alone comments.',
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
    ],
  },

  [ExchangeType.DOCUMENT_REQUEST_LOCKED]: {
    type: ExchangeType.DOCUMENT_REQUEST_LOCKED,
    label: 'Document request',
    description: 'A request for the counterparty to submit the specified document. Upon submission, the document will be locked until the unlocking condition is met. The counterparty has the option to mark the document as unavailable.',
    hasLock: true,
    fields: [
      { key: 'category', labelId: 'identifierOrDescription', label: 'Identifier or description', type: FieldType.STRING, isDisplayName: true },
    ],
  },

  [ExchangeType.CHAT]: {
    type: ExchangeType.CHAT,
    label: 'Chat',
    fields: [
      { key: 'name', labelId: 'chat', label: 'Chat ', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.CHAT_NO_RECEIVER_UPLOAD]: {
    type: ExchangeType.CHAT_NO_RECEIVER_UPLOAD,
    label: 'Chat',
    fields: [
      { key: 'name', labelId: 'chat', label: 'Chat ', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD]: {
    type: ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD,
    label: 'Chat',
    fields: [
      { key: 'name', labelId: 'chat', label: 'Chat ', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.INTERNAL_DOCUMENT]: {
    type: ExchangeType.INTERNAL_DOCUMENT,
    label: 'Internal document',
    fields: [
      { key: 'name', labelId: 'name', label: 'Name', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },
  [ExchangeType.CLARIFICATION]: {
    type: ExchangeType.CLARIFICATION,
    label: 'Clarification',
    fields: [
      { key: 'name', labelId: 'clarification', label: 'Clarification ', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.EVALUATION_CRITERION]: {
    type: ExchangeType.EVALUATION_CRITERION,
    label: 'Evaluation criteria',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'maxPoints', labelId: 'points', label: 'Points', type: FieldType.NUMBER },
      { key: 'weight', labelId: 'weight', label: 'Weight', type: FieldType.NUMBER },
    ],
  },
  [ExchangeType.BULLETIN]: {
    type: ExchangeType.BULLETIN,
    label: 'Bulletin',
    fields: [
      { key: 'message', labelId: 'message', label: 'Message', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments', labelId: 'files', label: 'Files', type: FieldType.ARRAY },
    ],
  },
  [ExchangeType.QUESTION]: {
    type: ExchangeType.QUESTION,
    label: 'Question',
    fields: [
      { key: 'description', labelId: 'question', label: 'Question', type: FieldType.STRING, isDisplayName: true },
      { key: 'questionType', labelId: 'type', label: 'Type', type: FieldType.STRING, isDisplayName: true },
      { key: 'isRequired', labelId: 'required', label: 'Required', type: FieldType.BOOLEAN },
      { key: 'schema', labelId: 'schema', label: 'Schema', type: FieldType.OBJECT, isHidden: true },
      { key: 'options', labelId: 'options', label: 'Options', type: FieldType.ARRAY },
      { key: 'allowCustomOption', labelId: 'allowCustomOption', label: 'Allow custom option?', type: FieldType.BOOLEAN },
      { key: 'currencies', labelId: 'specifiedCurrency', label: 'Specified currency', type: FieldType.ARRAY },
      { key: 'visibleFields', labelId: 'fields', label: 'Fields', type: FieldType.ARRAY },
      { key: 'format', labelId: 'format', label: 'Format', type: FieldType.STRING, isHidden: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },

  [ExchangeType.AUCTION_TERMS]: {
    type: ExchangeType.AUCTION_TERMS,
    label: '',
    fields: [
      { key: 'text', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.AUCTION_INFORMATION]: {
    type: ExchangeType.AUCTION_INFORMATION,
    label: '',
    fields: [
      { key: 'text', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
    ],
  },
  [ExchangeType.AUCTION_LINE_ITEM]: {
    type: ExchangeType.AUCTION_LINE_ITEM,
    label: '',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'quantity', labelId: 'quantity', label: 'Quantity', type: FieldType.NUMBER },
      { key: 'unit', labelId: 'unit', label: 'Unit', type: FieldType.STRING },
    ],
  },
  [ExchangeType.CONTRACT]: {
    type: ExchangeType.CONTRACT,
    label: '',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },
  [ExchangeType.LEGACY_CONTRACT]: {
    type: ExchangeType.LEGACY_CONTRACT,
    label: '',
    fields: [
      { key: 'description', labelId: 'description', label: 'Description', type: FieldType.STRING, isDisplayName: true },
      { key: 'attachments[0]', labelId: 'document', label: 'Document', type: FieldType.ATTACHMENT },
    ],
  },
  [ExchangeType.APPROVAL]: {
    type: ExchangeType.APPROVAL,
    label: '',
    fields: [],
  },
  [ExchangeType.STAGE_APPROVAL]: {
    type: ExchangeType.STAGE_APPROVAL,
    label: 'Stage approval',
    fields: [
      { key: 'recipientIds', labelId: 'recipientIds', label: 'Suppliers', type: FieldType.ARRAY },
      { key: 'attachments', labelId: 'attachments', label: 'Attachments', type: FieldType.ARRAY },
      { key: 'message', labelId: 'message', label: 'Message', type: FieldType.STRING },
      { key: 'approverIds', labelId: 'approverIds', label: 'Approvers', type: FieldType.ARRAY },
    ],
  },
};

export const documentExchangeTypes = [
  ExchangeType.INFORMATION,
  ExchangeType.ACCEPT_CLOSED,
  ExchangeType.ACCEPT,
  ExchangeType.COMPLETE_OR_SIGN,
  ExchangeType.COMPLETE_OR_SIGN_LOCKED,
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
  ExchangeType.DOCUMENT_REQUEST,
  ExchangeType.DOCUMENT_REQUEST_CLOSED,
  ExchangeType.DOCUMENT_REQUEST_LOCKED,
];

export const documentSubmissionExchangeTypes = [
  ExchangeType.INFORMATION,
  ExchangeType.ACCEPT_CLOSED,
  ExchangeType.ACCEPT,
  ExchangeType.COMPLETE_OR_SIGN,
  ExchangeType.COMPLETE_OR_SIGN_LOCKED,
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
];

export const informationRequestExchangeTypes = [
  ExchangeType.DOCUMENT_REQUEST,
  ExchangeType.DOCUMENT_REQUEST_CLOSED,
  ExchangeType.DOCUMENT_REQUEST_LOCKED,
];

export const deliveryExchangeTypes = [
  ExchangeType.DELIVERY_ADDRESS,
  ExchangeType.DELIVERY_DEADLINE,
  ExchangeType.INCOTERMS,
];

export const vesselPricingTypes = [
  ExchangeType.HIRE_PERIOD,
  ExchangeType.FEES,
  ExchangeType.INCLUSIONS,
  ExchangeType.TERMS,
];

export const chatExchangeTypes = [
  ExchangeType.CHAT,
  ExchangeType.CHAT_NO_RECEIVER_UPLOAD,
  ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD,
];

export const chatOrClarificationExchangeTypes = [
  ...chatExchangeTypes,
  ExchangeType.CLARIFICATION,
];

export const documentExchangesConfigs: ExchangeConfig[] = values(pick(exchangesConfig, documentExchangeTypes));
