import { useMemo } from 'react';
import { APP_ADMIN_COMPANY_ID } from './constants';
import { useCurrentCompanyId } from './currentCompanyId';

export const isAppAdminCompany = (companyId: string) =>
  companyId === APP_ADMIN_COMPANY_ID;

export const useIsAppAdmin = () => {
  const currentCompanyId = useCurrentCompanyId()!;

  const isAppAdmin = useMemo(
    () => isAppAdminCompany(currentCompanyId),
    [currentCompanyId],
  );

  return isAppAdmin;
};
