import * as React from 'react';
import { LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';

type AdminLineItemsTableProps = {
  lineItemDefs: LineItemExchangeDefinition[];
};

export const AdminLineItemsTable: React.FC<AdminLineItemsTableProps> = ({ lineItemDefs }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        Cell: ValueOrDashCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ValueOrDashCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={lineItemDefs}
        isSortable
        noFilteredDataPlaceholder="No line items match chosen filters"
      />
    </StaticTableStyles>
  );
};
