import { useMemo } from 'react';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { Id } from './Id';

export const SubscriptionPropertyList = ({ subscription }: { subscription: any }) => {
  const companyProperties = useMemo(
    () => {
      if (!subscription) return [];

      const { account, plan } = subscription;

      return [
        { name: 'ID', value: subscription.uuid, Component: Id },
        { name: 'State', value: subscription.state },
        { name: 'Plan', value: `${plan.name} (${plan.code})` },
        { name: 'Account company', value: account.company },
        { name: 'Account name', value: `${account.firstName} ${account.lastName}`.trim() },
        { name: 'Account email', value: account.email },
        { name: 'Period started', value: subscription.currentPeriodStartedAt, Component: Datetime },
        { name: 'Period ends', value: subscription.currentPeriodEndsAt, Component: Datetime },
        { name: 'Term started', value: subscription.currentTermStartedAt, Component: Datetime },
        { name: 'Term ends', value: subscription.currentTermEndsAt, Component: Datetime },
        { name: 'Currency', value: subscription.currency },
      ];
    },
    [subscription],
  );

  return (
    <PropertyList properties={companyProperties} />
  );
};
