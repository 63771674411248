import * as React from 'react';
import { TOriginalColumnDataBase, GridData, TOriginalSubRowDataBase, TExpandableRowDataBase, FrozenHeaderCellProps } from './utils';

type RenderFrozenHeaderCellProps<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  data: GridData<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>;
  FrozenHeaderCell: React.FunctionComponent<FrozenHeaderCellProps<TOriginalColumnData>>;
  index: number;
};

const RenderFrozenHeaderCellBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
    data,
    FrozenHeaderCell,
    index: columnIndex,
  }: RenderFrozenHeaderCellProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
  const column = data.columns[columnIndex];

  return (
    <div
      className="header-cell"
      style={{
        position: 'absolute',
        top: 0,
        left: `${column.left}px`,
        height: 'var(--frozen-header-first-line-height)',
        width: `${column.width}px`,
      }}
    >
      <FrozenHeaderCell column={column} />
    </div>
  );
};

export const RenderFrozenHeaderCell =
  React.memo(RenderFrozenHeaderCellBase) as typeof RenderFrozenHeaderCellBase;
