import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { compact, noop } from 'lodash';

import { IconValue } from '@deepstream/common';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { ErrorMessage, HelperText } from './Field';
import { Switch } from '../ui/Switch';
import { FieldContainer } from './FieldContainer';
import { DefaultSwitchLabelConfigProvider } from '../DefaultSwitchLabelConfigProvider';

type SwitchFieldBaseProps = {
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  description?: string;
  name?: string;
  onChange?: any;
  value?: boolean | undefined;
  text?: string;
  checkedIcon?: boolean | React.ReactElement | undefined;
  uncheckedIcon?: boolean | React.ReactElement | undefined;
  checkedText?: React.ReactNode;
  uncheckedText?: React.ReactNode;
  switchHeight?: number | string;
  useDefaultLabelConfig?: boolean;
  infoTooltip?: React.ReactChild;
  switchTooltip?: React.ReactChild;
  width?: number;
  tooltipIcon?: IconValue;
  appendedText?: string;
};

export const SwitchFieldBase = ({
  disabled,
  error,
  hideError,
  helperText,
  label,
  description,
  name,
  onChange = noop,
  value,
  hideLabel,
  useDefaultLabelConfig = true,
  infoTooltip,
  tooltipIcon,
  ...props
}: SwitchFieldBaseProps) => {
  const id = useUniqueId();
  const resolvedName = name ?? 'switch';

  const descriptionId = description ? `${id}-description` : undefined;
  const helperTextId = helperText ? `${id}-description` : undefined;

  const describedBy = compact([descriptionId, helperTextId]).join(' ') || undefined;

  const fieldElement = (
    <FieldContainer
      name={resolvedName}
      htmlFor={id}
      label={label}
      description={description}
      descriptionId={descriptionId}
      hideLabel={hideLabel}
      infoTooltip={infoTooltip}
      tooltipIcon={tooltipIcon}
    >
      <Switch
        id={id}
        name={resolvedName}
        checked={Boolean(value)}
        onChange={onChange}
        disabled={disabled}
        aria-describedby={describedBy}
        {...props}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} id={helperTextId} />
      ) : (
        null
      )}
    </FieldContainer>
  );

  return useDefaultLabelConfig ? (
    <DefaultSwitchLabelConfigProvider name={resolvedName}>
      {fieldElement}
    </DefaultSwitchLabelConfigProvider>
  ) : (
    fieldElement
  );
};

interface SwitchFieldProps extends SwitchFieldBaseProps {
  name?: string;
  fieldName?: string;
  onChange?: any;
}

/**
 * Formik-aware switch field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const SwitchField = ({
  name,
  fieldName,
  onChange,
  ...props
}: SwitchFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({
    name: resolvedFieldName!,
    type: 'checkbox',
  });

  return (
    <SwitchFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={checked => {
        formik.setValue(checked);
        if (onChange) {
          onChange(checked);
        }
      }}
      value={field.checked}
      {...props}
    />
  );
};

interface MappingSwitchFieldProps<TField> extends SwitchFieldBaseProps {
  name: string;
  /**
   * Maps the value of the Formik form field to the `checked` state of the
   * switch component. Called when rendering the component.
   */
  mapFromField: (value: TField) => boolean;
  /**
   * Maps the `checked` state of the switch component to the value of the
   * Formik form field. Called when handling the `onChange` event.
   */
  mapToField: (checked: boolean) => TField;
}

/**
 * Formik-aware switch field component with mapper callback parameters
 * that allow to use the component with non-boolean form fields.
 */
export const MappingSwitchField = <TField,>({
  name,
  mapFromField,
  mapToField,
  onChange,
  ...props
}: MappingSwitchFieldProps<TField>) => {
  const [field, meta, formik] = useField({ name });

  return (
    <SwitchFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={(checked: boolean) => {
        formik.setValue(mapToField(checked));

        if (onChange) {
          onChange(checked);
        }
      }}
      value={mapFromField(field.value)}
      {...props}
    />
  );
};
