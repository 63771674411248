import { createContext, useContext } from 'react';
import { useUniqueId } from '../../hooks/useUniqueId';

const GridIdPrefixContext = createContext<string | null>(null);

export const GridIdPrefixProvider = ({ children }) => {
  const idPrefix = useUniqueId('eg');

  return (
    <GridIdPrefixContext.Provider value={idPrefix}>
      {children}
    </GridIdPrefixContext.Provider>
  );
};

export const useGridIdPrefix = () => {
  const state = useContext(GridIdPrefixContext);
  if (!state) throw new Error('No grid menu state found');
  return state;
};
