import * as React from 'react';
import {
  ThemeContext as StyledThemeContext,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { Theme } from './types';

export const ThemeProvider = ({
  theme,
  children,
}: {
  theme: Theme;
  children?: React.ReactElement;
}) => (
  <StyledThemeProvider theme={theme}>
    {children}
  </StyledThemeProvider>
);

export const useTheme = (): Theme => {
  const context = React.useContext(StyledThemeContext);

  if (!context) {
    throw new Error('Theme not found');
  }

  return context;
};
