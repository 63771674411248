import { useMemo } from 'react';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

type UserAuth0PropertyListProps = {
  user?: {
    auth0UserIds?: string[];
    auth0IsFederated?: boolean;
  };
};

export const UserAuth0PropertyList = ({ user }: UserAuth0PropertyListProps) => {
  const auth0Properties = useMemo(
    () => {
      if (!user) return [];

      return [
        {
          name: 'IDs',
          value: user.auth0UserIds?.length ? user.auth0UserIds.join(', ') : <EmDash />,
        },
        {
          name: 'Is federated?',
          value: user.auth0UserIds?.length ? user.auth0IsFederated ? 'Yes' : 'No' : <EmDash />,
        },
      ];
    },
    [user],
  );

  return (
    <PropertyList properties={auth0Properties} />
  );
};
