import { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isLinkedEvaluationPage, RfqStatus } from '@deepstream/common/rfq-utils';
import { Text, Box, FlexProps } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useModalState, useConfirmDialog } from '@deepstream/ui/ui/useModalState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from '@deepstream/ui/rfx';
import { IntegrationDataType } from '@deepstream/ui/types';
import { ErrorPanel } from '@deepstream/ui/ui/ErrorMessage';
import { RfqIdProvider } from '@deepstream/ui/useRfq';
import { RequestHooksProvider } from '@deepstream/ui/modules/Request/RequestHooksProvider';
import { useWaitForRfqUnlock } from '@deepstream/ui/useWaitForUnlock';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useInvalidateQueryOnMessage } from '@deepstream/ui/useInvalidateQueryOnMessage';
import { Page } from './Page';
import { RequestPropertyList } from './RequestPropertyList';
import { RequestRecipientsTable } from './RequestRecipientsTable';
import { RequestSendersTable } from './RequestSendersTable';
import { AddCompanyToRequestModal } from './AddCompanyToRequestModal';
import { StagesTable } from './StagesTable';
import { RequestDetails } from './RequestDetails';
import { RequestExchangesTable } from './RequestExchangesTable';
import * as Title from './title';
import { useAdminRequest, useAdminRequestQueryKey } from './adminRequest';
import { IntegrationDataTable } from './IntegrationDataTable';
import { AdminRequestEvaluationPanelContent } from './AdminRequestEvaluationPanelContent';
import { requestRoute, useAdminNavigation } from './AppRouting';
import { LotAndAwardScenarioSettings } from './LotAndAwardScenarioSettings';

const TimersPanel = ({ requestId }: { requestId: string }) => {
  const [timer, setTimer] = useState<number | null>(null);

  const adminApi = useAdminApi();

  const [timeRequest] = useMutation(
    async () => {
      const start = Date.now();
      await adminApi.getAdminRequestOverview({ requestId });
      const end = Date.now();

      setTimer((end - start) / 1000);
    },
  );

  return (
    <Panel heading="Timers" padded mb={4}>
      <Stack gap={2}>
        <Box>
          <Button small onClick={() => timeRequest({})} mr={2}>
            Rfx structure and exchanges
          </Button>
          {timer ? <>{timer}s</> : null}
        </Box>
      </Stack>
    </Panel>
  );
};

const CachePanel = ({ requestId }: { requestId: string }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [updateCache, { isLoading }] = useMutation(
    (payload: { requestId: string, fromScratch?: boolean }) => waitForRfqUnlock({
      command: () => adminApi.updateRequestCache(payload),
    }),
    {
      onSuccess: () => toaster.success('Cache updated successfully'),
      onError: () => toaster.success('Could not update cache'),
    },
  );

  return (
    <Panel heading="Cache" padded>
      <Button small variant="danger" disabled={isLoading} onClick={() => updateCache({ requestId })}>
        Update cache
      </Button>
      <Button small variant="danger" disabled={isLoading} onClick={() => updateCache({ requestId, fromScratch: true })} ml={2}>
        Regenerate cache
      </Button>
    </Panel>
  );
};

export const PageTitle = ({
  flexDirection,
  requestId,
}: {
  flexDirection?: FlexProps['flexDirection'];
  requestId: string;
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Request
      size={flexDirection === 'column' ? 'large' : 'small'}
      requestId={requestId}
    />
  </Title.Container>
);

export const AdminRequestPage = () => {
  const adminApi = useAdminApi();
  const { requestId } = requestRoute.useParams();
  const addCompanyModal = useModalState();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const navigation = useAdminNavigation();
  const [ref, inView, entry] = useInView();

  if (!requestId) {
    throw new Error('A requestId is required');
  }

  const queryKey = useAdminRequestQueryKey(requestId);
  const { data, error, status } = useAdminRequest(requestId);

  useInvalidateQueryOnMessage(`rfx.${requestId}`, queryKey);

  const [deleteRequest] = useMutation(
    adminApi.deleteRequest,
    {
      onSuccess: () => toaster.success('Request successfully deleted'),
      onError: () => toaster.error('An error occurred when trying to delete the request'),
      onSettled: () => queryClient.invalidateQueries(queryKey),
    },
  );

  const isDeleted = useMemo(
    () => data?.meta?.status === RfqStatus.DELETED,
    [data],
  );

  const { data: integrationData, isError: errorLoadingIntegrationData } = useQuery(
    ['integrationData', { rfxId: requestId, type: IntegrationDataType.INTERNAL_LINE_ITEM_TO_EXTERNAL_LINE_ITEM }],
    wrap(adminApi.getIntegrationData),
  );

  const { data: template } = useQuery(
    ['template', { templateId: data?.meta?.fromTemplateId }],
    wrap(adminApi.getTemplate),
    { enabled: Boolean(data?.meta?.fromTemplateId) },
  );

  return (
    <RfqIdProvider rfqId={requestId}>
      <RequestHooksProvider>
        <Page
          subHeading={data && entry && !inView && (
            <rfx.StructureProvider structure={data.structure}>
              <PageTitle requestId={requestId} />
            </rfx.StructureProvider>
          )}
        >
          {status === 'loading' ? (
            <Panel>
              <PanelText><Loading /></PanelText>
            </Panel>
          ) : status === 'error' ? (
            <Panel heading="Error">
              <PanelText color="danger">
                <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
              </PanelText>
            </Panel>
          ) : data ? (
            <rfx.TemplateProvider template={template}>
              <rfx.StructureProvider structure={data.structure}>
                <div ref={ref}>
                  <PageTitle
                    flexDirection="column"
                    requestId={requestId}
                  />
                </div>

                <Panel heading="General" mb={4}>
                  <RequestPropertyList requestId={requestId} meta={data.meta} />
                </Panel>

                <Panel heading="Senders" mb={4}>
                  <RequestSendersTable
                    onRowClick={sender => navigation.navigateToRequestCompany(requestId, sender.company._id)}
                  />
                </Panel>

                <Panel mb={4}>
                  <PanelHeader heading="Recipients">
                    <Button small variant="primary" iconLeft="plus" onClick={addCompanyModal.open}>
                      Add company
                    </Button>
                  </PanelHeader>
                  <PanelDivider />
                  {data.structure.recipients.length ? (
                    <RequestRecipientsTable
                      onRowClick={recipient => navigation.navigateToRequestCompany(requestId, recipient.company._id)}
                    />
                  ) : (
                    <PanelText color="gray">No recipients yet</PanelText>
                  )}
                  <AddCompanyToRequestModal
                    onSave={addCompanyModal.close}
                    onCancel={addCompanyModal.close}
                    isOpen={addCompanyModal.isOpen}
                    onRequestClose={addCompanyModal.close}
                    requestId={requestId}
                  />
                </Panel>

                <Panel heading="Lots and award scenarios" mb={4}>
                  <LotAndAwardScenarioSettings />
                </Panel>

                <Panel heading="Stages" mb={4}>
                  <StagesTable />
                </Panel>

                <Panel heading="Details" mb={4}>
                  <RequestDetails />
                </Panel>

                {data.structure.summary.isLive && (
                  <Panel heading="Exchanges" mb={4}>
                    <rfx.ExchangesProvider exchanges={data.exchanges}>
                      <RequestExchangesTable />
                    </rfx.ExchangesProvider>
                  </Panel>
                )}

                {data.structure.pages.some(isLinkedEvaluationPage) && (
                  <Panel heading="Evaluation" mb={4}>
                    <AdminRequestEvaluationPanelContent />
                  </Panel>
                )}

                {errorLoadingIntegrationData ? (
                  <ErrorPanel error="Could not load integration data" />
                ) : integrationData?.length ? (
                  <Panel heading="Integration Data" mb={4}>
                    <IntegrationDataTable integrationData={integrationData} />
                  </Panel>
                ) : (
                  null
                )}

                {!isDeleted && (
                  <Panel heading="Delete request" mb={4}>
                    <PanelPadding>
                      <Text mb={2} fontSize={2}>
                        Request will remain in database but will not be visible to any users.
                      </Text>
                      <Tooltip content={isDeleted ? 'This request was already deleted' : ''}>
                        <div style={{ display: 'inline-block' }}>
                          <Button
                            variant="danger"
                            onClick={() => confirm(() => deleteRequest({ requestId }))}
                          >
                            Delete
                          </Button>
                        </div>
                      </Tooltip>
                    </PanelPadding>
                    <ConfirmDeleteDialog
                      heading="Delete request?"
                      message="This cannot be undone"
                      {...dialogProps}
                    />
                  </Panel>
                )}
              </rfx.StructureProvider>
            </rfx.TemplateProvider>
          ) : null}
          <TimersPanel requestId={requestId} />

          <CachePanel requestId={requestId} />
        </Page>
      </RequestHooksProvider>
    </RfqIdProvider>
  );
};
