import { useCallback, useMemo } from 'react';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import useDownload from '@deepstream/ui/useDownload';

export const CompanyReportsPropertyList = ({ companyId }: { companyId: string }) => {
  const download = useDownload();

  const downloadLineItemsBidsReport = useCallback(
    async () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const queryParams = new URLSearchParams({ companyId, timezone });
      const url = `/export/line-items-bids/csv?${queryParams.toString()}`;

      await download(url);
    },
    [companyId, download],
  );

  const companyProperties = useMemo(
    () => [{
      name: 'Request bids report (line items)',
      value: '',
      Component: () => null,
      labelWidth: 400,
    }],
    [],
  );

  return (
    <PropertyList properties={companyProperties}>
      <PropertyListAction label="Download" icon="download" onClick={downloadLineItemsBidsReport} />
    </PropertyList>
  );
};
