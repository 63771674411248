import { trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Flex } from 'rebass/styled-components';
import { FilterSelectCombobox, FilterSelectComboboxProps } from '../../../ui/FilterSelectCombobox';
import { UserDetails } from '../../../UserDetails';
import { matchStringValue } from '../../../searchUtils';

export interface UserBase {
  _id: string;
  name: string;
  email: string;
}

export const filterUserItems = (value: string, items: UserBase[]) => {
  const trimmedValue = trim(value);

  return trimmedValue
    ? items.filter(item => matchStringValue(trimmedValue, item.name) || item.email.includes(trimmedValue))
    : items;
};

const getId = (item: UserBase) => item._id;

const UserItem = ({ item, disabled }: { item: UserBase; disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="space-between" alignItems="center" mr={1} width="100%">
      <UserDetails user={item} largeEmailLine emailLineMarginTop={1} />
      {disabled ? (
        <Text ml="10px" flex="0 0 auto" opacity={0.5}>
          {t('userSelect.added')}
        </Text>
      ) : (
        null
      )}
    </Flex>
  );
};

type UserSelectProps = Omit<
  FilterSelectComboboxProps<UserBase>,
  'selectedPlaceholder' |
  'filterPlaceholder' |
  'emptyFilteredItemsMessage' |
  'filterItems' |
  'getId' |
  'Item'
>;

export const UserSelect = ({ ...props }: UserSelectProps) => {
  const { t } = useTranslation();

  return (
    <FilterSelectCombobox
      selectedPlaceholderText={t('userSelect.selectAUser')}
      filterPlaceholder={t('userSelect.searchByNameOrEmail')}
      emptyFilteredItemsMessage={t('userSelect.noUsersFound')}
      filterItems={filterUserItems}
      getId={getId}
      Item={UserItem}
      selectContainerStyle={{
        height: '57px',
      }}
      {...props}
    />
  );
};
