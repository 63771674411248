import { CellProps } from 'react-table';
import { Flex } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Avatar } from './Avatar';

/**
 * Cell component rendering avatar and name
 */
export const SimpleUserCell = ({
  cell,
  row,
}: CellProps<{ _id: string; name: string; jobTitle: string }>) => (
  <Flex sx={{ columnGap: '4px' }} alignItems="center">
    <Avatar
      userId={row.original._id}
      width="xs"
      style={{ boxShadow: 'none' }}
    />
    <Truncate>{cell.value}</Truncate>
  </Flex>
);
