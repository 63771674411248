import { createContext, useContext } from 'react';

export const CurrentCompanyIdContext = createContext<string | null>(null);

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useCurrentCompanyId(): string | null;
export function useCurrentCompanyId(a: { required: true }): string;
export function useCurrentCompanyId(a: { required: false }): string | null;
export function useCurrentCompanyId({ required = false } = {}) {
  const currentCompanyId = useContext(CurrentCompanyIdContext);

  if (required && !currentCompanyId) {
    throw new Error('A `currentCompanyId` has not been set via the `CurrentCompanyIdContext`');
  }

  return currentCompanyId;
}
