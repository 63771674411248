import { get, isEmpty } from 'lodash';
import * as React from 'react';
import { Box, Card, CardProps, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { SupplierListOverview, getRegionName } from '@deepstream/common';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Bold } from './Bold';
import { CompanyLogo } from './CompanyLogo';
import { TableLink } from './TableLink';
import { useSession } from './auth';
import { useCurrentUserLocale } from './useCurrentUser';
import { useSupplierLists } from './modules/SupplierLists/useSupplierLists';
import { listTypeIconProps } from './modules/SupplierLists/utils';

export const CompanyInvitedBadge: React.FC<CardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('invite.hasNotSignedUpYet')}>
      <Card
        {...props}
        sx={{
          height: 20,
          fontSize: 1,
          color: 'gray',
          borderWidth: 1,
          borderColor: 'gray',
          borderStyle: 'solid',
          borderRadius: '10px',
          lineHeight: 1,
          minWidth: 57,
        }}
      >
        <Flex alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
          <Bold>
            {t('invite.status.invited')}
          </Bold>
        </Flex>
      </Card>
    </Tooltip>
  );
};

const ListsTooltipContent = ({ supplierLists }: { supplierLists: SupplierListOverview[] }) => {
  return (
    <Box width="250px">
      {supplierLists.map((list) => (
        <Flex key={list._id} alignItems="center" justifyContent="space-between" sx={{ gap: 2 }}>
          <Truncate>
            {list.name}
          </Truncate>
          <Icon {...listTypeIconProps[list.listType]} fixedWidth flex="0 0 auto" />
        </Flex>
      ))}
    </Box>
  );
};

type CompanyListItemProps = {
  company: any;
  className?: string;
  renderActions?: any;
  navigateToCompany: (companyId: string) => void;
};

export const CompanyListItem = ({
  company,
  navigateToCompany,
  renderActions,
  className,
}: CompanyListItemProps) => {
  const { isAppAdmin } = useSession();
  const { t } = useTranslation(['general', 'translation']);
  const locale = useCurrentUserLocale();

  const navigate = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigateToCompany(company._id);
    },
    [company, navigateToCompany],
  );
  const countryCode = get(company, 'address.country');

  const { supplierLists } = useSupplierLists();

  const selectedSupplierLists = React.useMemo(
    () => (supplierLists || []).filter((supplierList) => supplierList.supplierIds.includes(company._id)),
    [supplierLists, company._id],
  );

  return (
    <Flex as="li" p={20} alignItems="center" className={className} style={{ height: 73 }}>
      <Box mr={10}>
        <CompanyLogo companyId={company._id} size="md" />
      </Box>
      <Box flex={1}>
        <Flex flexDirection="column" justifyContent="center">
          <Flex display="inline-flex" flexDirection="row">
            <Bold lineHeight={1.3} display="inline-block">
              {company.isPending && !isAppAdmin ? (
                <Text color="gray">
                  <Truncate>
                    {company.name}
                  </Truncate>
                </Text>
              ) : (
                <Text color="primary">
                  <Truncate>
                    <TableLink to="" onClick={navigate}>
                      {company.name}
                    </TableLink>
                  </Truncate>
                </Text>
              )}
            </Bold>
            {company.isPending && (
              <CompanyInvitedBadge ml={2} />
            )}
          </Flex>
          <Flex color="gray" fontSize={2} lineHeight={1.3} sx={{ gap: 3 }}>
            {!company.isPending && (
              <Truncate>
                <Icon icon={'map-marker' /* FIXME: use alt */} mr="6px" />
                <Text as="span">
                  {getRegionName(countryCode, locale, t('unknown'))}
                </Text>
              </Truncate>
            )}
            {!isEmpty(selectedSupplierLists) && (
              <Tooltip
                content={<ListsTooltipContent supplierLists={selectedSupplierLists} />}
                allowedPlacements={['top']}
              >
                <Truncate>
                  <Icon icon="list-ul" mr={1} />
                  <Text as="span">
                    {t('network.listCount', { count: selectedSupplierLists.length, ns: 'translation' })}
                  </Text>
                </Truncate>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Box>
      {renderActions && (
        <Box ml={2}>
          {renderActions(company)}
        </Box>
      )}
    </Flex>
  );
};

type CompaniesListProps = {
  companies: any[];
  renderActions?: any;
  navigateToCompany: (companyId: string) => void;
};

const StyledCompanyListItem = styled(CompanyListItem)`
  & + & {
    border-top: 1px solid #E2E8EF;
  }
`;

export const CompaniesList: React.FC<CompaniesListProps> = React.memo(({ companies, navigateToCompany, renderActions }) => (
  <Box as="ul" p={0} style={{ listStyle: 'none' }}>
    {companies.map(company => (
      <StyledCompanyListItem
        key={company._id}
        company={company}
        navigateToCompany={navigateToCompany}
        renderActions={renderActions}
      />
    ))}
  </Box>
));

CompaniesList.displayName = 'CompaniesList';
