import * as React from 'react';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { isMac } from '@deepstream/ui-utils/isMac';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import {
  TExpandableRowDataBase,
  TOriginalColumnDataBase,
  TOriginalSubRowDataBase,
  GridDataAndCommands,
  isExpandable,
  NavigationTarget,
  SelectedRange,
  getNavigationBoundary,
  NavigableRange,
  getMinNavigableStartIndices,
  SelectableRange,
} from './utils';

/**
 * Returns the indices of the cell in the corner opposite to
 * the reference cell.
 */
const getOppositeSelectionCellIndices = ({ start, end, reference }: SelectedRange) => {
  const rowIndex = start.rowIndex === reference.rowIndex
    ? end.rowIndex - 1
    : start.rowIndex;

  const columnIndex = start.columnIndex === reference.columnIndex
    ? end.columnIndex - 1
    : start.columnIndex;

  return {
    rowIndex,
    columnIndex,
  };
};

export const useHandleGridKeyDown = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>(
    data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>,
    onDataCellKeyboardAction: (
      data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>,
      event: React.KeyboardEvent<HTMLDivElement>,
    ) => void,
    selectAll: () => void,
    navigableRange: NavigableRange,
    selectableRange?: SelectableRange,
  ) => React.useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.code) {
      case KeyCode.ARROW_UP:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex, columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex: rowIndex - 1,
            columnIndex,
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: data.activeCellIndices.rowIndex - 1,
            columnIndex: data.activeCellIndices.columnIndex,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.ARROW_DOWN:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex, columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex: rowIndex + 1,
            columnIndex,
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: data.activeCellIndices.rowIndex + 1,
            columnIndex: data.activeCellIndices.columnIndex,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.ARROW_LEFT:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex, columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex,
            columnIndex: columnIndex - 1,
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: data.activeCellIndices.rowIndex,
            columnIndex: data.activeCellIndices.columnIndex - 1,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.ARROW_RIGHT:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex, columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex,
            columnIndex: columnIndex + 1,
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: data.activeCellIndices.rowIndex,
            columnIndex: data.activeCellIndices.columnIndex + 1,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.PAGE_UP:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({ columnIndex }, NavigationTarget.FIRST_ROW_PREVIOUS_PAGE, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({}, NavigationTarget.FIRST_ROW_PREVIOUS_PAGE, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.PAGE_DOWN:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { columnIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({ columnIndex }, NavigationTarget.LAST_ROW_NEXT_PAGE, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({}, NavigationTarget.LAST_ROW_NEXT_PAGE, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.HOME:
        if (selectableRange && data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex: (isMac && event.metaKey) || (!isMac && event.ctrlKey)
              ? navigableRange.startRowIndex
              : rowIndex,
            columnIndex: Math.max(navigableRange.startColumnIndex, selectableRange.startColumnIndex),
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: (isMac && event.metaKey) || (!isMac && event.ctrlKey)
              ? navigableRange.startRowIndex
              : data.activeCellIndices.rowIndex,
            columnIndex: selectableRange
              ? Math.max(navigableRange.startColumnIndex, selectableRange.startColumnIndex)
              : navigableRange.startColumnIndex,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.END:
        if (data.activeCellIndices && event.shiftKey && data.selectedRange) {
          const { rowIndex } = getOppositeSelectionCellIndices(data.selectedRange);

          data.activateCellAndEnsureVisibility({
            rowIndex: (isMac && event.metaKey) || (!isMac && event.ctrlKey)
              ? data.rows.length - 1
              : rowIndex,
            columnIndex: data.columns.length - 1,
          }, null, event);
        } else if (data.activeCellIndices) {
          data.activateCellAndEnsureVisibility({
            rowIndex: (isMac && event.metaKey) || (!isMac && event.ctrlKey)
              ? data.rows.length - 1
              : data.activeCellIndices.rowIndex,
            columnIndex: data.columns.length - 1,
          }, null, event);
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      case KeyCode.SPACE:
      case KeyCode.ENTER:
        if (data.activeCellIndices && data.activeCellIndices.rowIndex !== -1) {
          const row = data.rows[data.activeCellIndices.rowIndex];

          if (row) {
            if (isExpandable(row)) {
              data.toggleCollapsedRowState(row.original._id, data.activeCellIndices.rowIndex);
            } else {
              onDataCellKeyboardAction(data, event);
              return;
            }
          }
        }
        break;
      case KeyCode.TAB:
        if (data.activeCellIndices) {
          const boundary = getNavigationBoundary(data, navigableRange);

          // TODO improve signature of activateCellAndEnsureVisibility (add options object!)

          if (event.shiftKey) {
            // when in first column
            if (data.activeCellIndices.columnIndex === boundary.start.columnIndex) {
              const hasPrecedingRow = data.activeCellIndices.rowIndex > boundary.start.rowIndex;
              // when there's a preceding row, highlight cell in last column of preceding row
              if (hasPrecedingRow || data.selectedRange) {
                data.activateCellAndEnsureVisibility({
                  rowIndex: hasPrecedingRow
                    ? data.activeCellIndices.rowIndex - 1
                    : boundary.end.rowIndex - 1,
                  columnIndex: boundary.end.columnIndex - 1,
                }, null, null, Boolean(data.selectedRange));
              } else {
                onDataCellKeyboardAction(data, event);
                return;
              }
            } else {
              // when not in first column, highlight cell in preceding column
              data.activateCellAndEnsureVisibility({
                rowIndex: data.activeCellIndices.rowIndex,
                columnIndex: data.activeCellIndices.columnIndex - 1,
              }, null, null, Boolean(data.selectedRange));
            }
          } else {
            // when in last column
            // eslint-disable-next-line no-lonely-if
            if (data.activeCellIndices.columnIndex === boundary.end.columnIndex - 1) {
              const hasNextRow = data.activeCellIndices.rowIndex < boundary.end.rowIndex - 1;
              // when there's a next row, highlight cell in first column of next row
              if (hasNextRow || data.selectedRange) {
                data.activateCellAndEnsureVisibility({
                  rowIndex: hasNextRow
                    ? data.activeCellIndices.rowIndex + 1
                    : boundary.start.rowIndex,
                  columnIndex: boundary.start.columnIndex,
                }, null, null, Boolean(data.selectedRange));
              } else {
                onDataCellKeyboardAction(data, event);
                return;
              }
            } else {
              // when not in last column, highlight cell in next column
              data.activateCellAndEnsureVisibility({
                rowIndex: data.activeCellIndices.rowIndex,
                columnIndex: data.activeCellIndices.columnIndex + 1,
              }, null, null, Boolean(data.selectedRange));
            }
          }
        } else {
          data.activateCellAndEnsureVisibility(getMinNavigableStartIndices(navigableRange));
        }
        break;
      default:
        if (event.code === KeyCode.A && ((isMac && event.metaKey) || (!isMac && event.ctrlKey))) {
          selectAll();
        } else {
          if (data.activeCellIndices && data.activeCellIndices.rowIndex !== -1) {
            const row = data.rows[data.activeCellIndices.rowIndex];

            if (row) {
              onDataCellKeyboardAction(data, event);
            }
          }
          // when there's no match, don't stop propagation
          return;
        }
    }

    stopEvent(event);

    return false;
  }, [data, navigableRange, selectableRange, onDataCellKeyboardAction, selectAll]);
