import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { union, filter } from 'lodash';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, SidebarPanelHeader, PanelPadding, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CheckboxList } from './CheckboxList';
import { TieredFilter } from './TieredFilter';
import { useApi } from './api';
import { Loading } from './ui/Loading';
import { useModalState } from './ui/useModalState';
import { ProductsFilterModal } from './ProductsFilterModal';
import { Operator } from './types';
import { useSearchState } from './useCompanySearch';
import { useSystemFeatureFlags, WhenDisabled } from './systemFeatureFlags';
import { HelpCenterInlineLink } from './HelpCenterLink';
import { useCountryOptions } from './ui/countries';

const ProductMigrationInfoBlock = () => {
  const { t } = useTranslation();

  return (
    <MessageBlock variant="info">
      {t('productsAndServices.deprecationInfoBeforeMigration')}
      {' '}
      <HelpCenterInlineLink path="/en/articles/6337679-search-for-new-suppliers-using-product-services-tags">
        {t('general.moreInfo')}
      </HelpCenterInlineLink>
    </MessageBlock>
  );
};

const Header = ({ text }: { text: string }) => (
  <SidebarPanelHeader
    heading={<IconText icon="filter" text={text} gap={2} />}
  />
);

const EmptyFilterList = () => {
  const { t } = useTranslation();

  return (
    <Text color="subtext" fontSize={2}>
      {t('network.noFiltersAvailable')}
    </Text>
  );
};

type CompanyFiltersPanelProps = {
  isInternalNetwork?: boolean;
  updateDeliveryCountries: (countryCodes: string[]) => void;
  updateProductTags: (productTags: string[]) => void;
  updateProducts: (products: string[], operator: Operator | undefined) => void;
  availableFilters?: any;
};

export const CompanyFiltersPanel: React.FC<CompanyFiltersPanelProps> = ({
  isInternalNetwork,
  availableFilters = {},
  updateDeliveryCountries,
  updateProductTags,
  updateProducts,
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const productsFilterModal = useModalState();
  const { deliveryCountries, productTags, products } = useSearchState();
  const systemFeatureFlags = useSystemFeatureFlags();
  const countryOptions = useCountryOptions();

  const { data: tags, isLoading } = useQuery(
    'productTags',
    api.getProductTags,
  );

  const countries = React.useMemo(
    () => {
      // We need to display the selected countries even if they are not part of the selectable filters
      const countryCodes = union(availableFilters?.deliveryCountries || [], deliveryCountries);

      return countryOptions
        .filter(countryOption => countryCodes.includes(countryOption.value))
        .map(countryOption => ({
          _id: countryOption.value,
          name: countryOption.label,
        }));
    },
    [availableFilters, deliveryCountries, countryOptions],
  );

  const availableProductTags = React.useMemo(
    () => filter(
      tags,
      tag => availableFilters.deprecatedProductIds?.includes(tag._id),
    ),
    [availableFilters, tags],
  );

  const selectProductTag = tagId => {
    const productTags = tagId ? [tagId] : [];
    updateProductTags(productTags);
  };

  return systemFeatureFlags ? (
    <>
      <Stack gap={4}>
        <Panel>
          <WhenDisabled feature="productTagMigrationDone">
            <Header text={t('productsAndServices.productsAndServicesOld')} />
            <PanelPadding pt={0} mt={-1}>
              {isLoading ? (
                <Loading fontSize={2} />
              ) : availableProductTags?.length ? (
                <>
                  <TieredFilter
                    tags={availableProductTags}
                    selectedTagId={productTags[0]}
                    onSelect={selectProductTag}
                  />
                  <ProductMigrationInfoBlock />
                </>
              ) : (
                <EmptyFilterList />
              )}
            </PanelPadding>
            <PanelDivider />
          </WhenDisabled>
          <Header
            text={systemFeatureFlags.productTagMigrationDone
              ? t('productsAndServices.productsAndServices')
              : t('productsAndServices.productsAndServicesNew')
            }
          />
          <PanelPadding pt={0} mt={-1}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text>
                {t('productsAndServices.selectedCount', { count: products.length })}
              </Text>
              <EditButton small onClick={productsFilterModal.open} />
            </Flex>
            <WhenDisabled feature="productTagMigrationDone">
              <ProductMigrationInfoBlock />
            </WhenDisabled>
          </PanelPadding>
        </Panel>
        <Panel>
          <Header text={t('network.supplyLocation')} />
          <PanelPadding pt={0} mt={-1}>
            {!availableFilters.deliveryCountries ? (
              <Loading fontSize={2} />
            ) : countries.length ? (
              <CheckboxList
                value={deliveryCountries}
                items={countries}
                onChange={updateDeliveryCountries}
                withClearButton
                expandableList
              />
            ) : (
              <EmptyFilterList />
            )}
          </PanelPadding>
        </Panel>
      </Stack>
      {productsFilterModal.isOpen && (
        <ProductsFilterModal
          isInternalNetwork={isInternalNetwork}
          isOpen={productsFilterModal.isOpen}
          onClose={productsFilterModal.close}
          updateProducts={updateProducts}
        />
      )}
    </>
  ) : (
    null
  );
};
