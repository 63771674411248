import { trim } from 'lodash';

const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const normalizeString = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const matchStringValue = (value: string, itemValue: string) => {
  value = normalizeString(value);
  const regex = new RegExp(escapeRegExp(value), 'i');
  return regex.test(normalizeString(itemValue));
};

export const splitBySearchTerm = (
  value: string,
  searchTerm: string,
  options?: { includeTerm?: boolean },
) => {
  const escapedRegExp = escapeRegExp(searchTerm);
  const regexStr = options?.includeTerm ? `(${escapedRegExp})` : escapedRegExp;
  const regex = new RegExp(regexStr, 'i');
  return value.split(regex);
};

export const filterBySearchText = <T>(
  searchText: string,
  items: T[],
  keys: (keyof T)[],
) => {
  const trimmedSearchText = trim(searchText);

  return trimmedSearchText
    ? items.filter((item) => {
        return keys.some((key) => {
          const value = item[key];
          return value && matchStringValue(trimmedSearchText, value.toString());
        });
      })
    : items;
};
