import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

export const ListContainer = styled(Box)`
  height: ${props => props.height || 153}px;
  padding: ${props => props.theme.space[3]}px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 4px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-inline-start: ${props => props.theme.space[3]}px;
`;
