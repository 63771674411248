import { Flex } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useDownloadTermsAttachment } from './useDownloadTermsAttachment';

export const TermsOfServiceAttachment = ({
  attachment,
  version,
  truncate = false,
  showTooltip,
}: {
  attachment: Attachment;
  version: number;
  truncate?: boolean;
  showTooltip?: boolean;
}) => {
  const filename = `DeepStream Terms of Service v${version}.html`;
  const [download, { isLoading }] = useDownloadTermsAttachment(filename);

  return (
    <Flex
      alignItems="center"
      sx={{ maxWidth: '100%' }}
    >
      <IconText
        isIconRegular
        gap={2}
        icon="file"
        iconColor="subtext"
        text={filename}
        truncate={truncate}
        showTooltip={showTooltip}
      />
      <IconButton
        fixedWidth
        icon="download"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          download(attachment);
        }}
        disabled={isLoading}
        ml={2}
      />
    </Flex>
  );
};
