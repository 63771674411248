import { assignWith, mapValues, mergeWith, sumBy } from 'lodash';
import Big from 'big.js';

export function hasOwnProperty<T, K extends PropertyKey>(obj: T, prop: K): obj is T & Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Assigns the sum of all values for each key
 *
 * @example
 * const objects = [{ a: 10, b: 5 }, { a: 3 }];
 * assignSums(objects);
 * //=> { a: 13, b: 5 }
 */
export const assignSums = (objects: Array<Record<string, number>>): Record<string, number> =>
  assignWith({}, ...objects, (a = 0, b = 0) => a + b);

/**
 * Assigns the average of all the values for each key
 *
 * @example
 * const objects = [{ a: 0, b: 2 }, { a: 10 }, { a: 5 }];
 * assignAverages(objects);
 * //=> { a: 5, b: 2 }
 */
export const assignAverages = (objects: Array<Record<string, number>>): Record<string, number> =>
  mapValues(assignSums(objects), value => value / objects.length);

/**
 * Takes the average across the values returned by the `iteratee`
 *
 * @example
 * averageBy([{ a: 10 }, { a: 0 }, { a: 5 }], item => item.a);
 * //=> { a: 5 };
 */
export const averageBy = <T>(array: T[], iteratee: (item: T) => number): number =>
  sumBy<T>(array, iteratee) / array.length;

const concatIfArray = (a = [], b = []) => Array.isArray(a) ? [...a, ...b] : undefined;

/**
 * Like `Object.assign` except when a `target` key's value is an array, the `source` key's
 * value is concatenated
 */
export const assignConcat = <T>(target: Record<string, T>, ...sources: Record<string, T>[]): Record<string, T> =>
  assignWith(
    target,
    ...sources,
    concatIfArray,
  );

/**
 * Like `_.merge` except when a `target` key's value is an array, the `source` key's
 * value is concatenated
 */
export const mergeConcat = <T>(target: Record<string, T>, ...sources: Record<string, T>[]): Record<string, T> =>
  mergeWith(
    target,
    ...sources,
    concatIfArray,
  );

export const roundToDecimals = (value: number, decimalPlaces: number) => {
  const num = new Big(value);

  return num.round(decimalPlaces).toNumber();
};

export const getFixedString = (value: number) => {
  const num = new Big(value);

  return num.toFixed(30).replace(/0+$/, '');
};

export const getDecimalPlaces = (value: number) => {
  const valueString = getFixedString(value);
  const decimalIndex = valueString.indexOf('.');

  return decimalIndex === -1
    ? 0
    : valueString.length - (decimalIndex + 1);
};
