import { forwardRef } from 'react';
import { ButtonProps } from 'rebass/styled-components';
import { transparentize, darken, lighten } from 'polished';
import { Icon, IconProps } from '../icon/Icon';
import { WrapperButton } from './WrapperButton';

type IconButtonProps = ButtonProps & {
  icon: IconProps['icon'];
  color?: string;
  fixedWidth?: boolean;
};

/**
 * Circular button
 *
 * - Tightly wraps icon with no padding
 * - Invisible bg until focus
 * - Darkens icon color on hover
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
  icon,
  color = 'gray',
  fixedWidth,
  onClick,
  sx,
  ...props
}, ref) => (
  <WrapperButton
    ref={ref}
    onClick={onClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      color,
      borderRadius: 'circle',
      transition: `
        color 300ms,
        background-color 300ms,
        border-color 300ms,
        box-shadow 300ms;
      `,
      ':focus:not(:disabled)': {
        backgroundColor: theme => transparentize(0.80, theme.colors[color]),
        boxShadow: theme => `0 0 0 ${theme.space[2]}px ${transparentize(0.80, theme.colors[color])}`,
        outline: 'none',
      },
      ':hover:not(:disabled):not(:focus)': {
        color: theme => darken(0.2, theme.colors[color]),
      },
      ':disabled': {
        color: theme => lighten(0.2, theme.colors[color]),
        cursor: 'inherit',
      },
      ...sx,
    }}
    {...props}
  >
    <Icon fixedWidth={fixedWidth} icon={icon} />
  </WrapperButton>
));
