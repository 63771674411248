import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '@deepstream/ui/form/TextField';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CompanyLogoWithName } from '@deepstream/ui/CompanyLogo';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { IntegrationDataType } from '@deepstream/ui/types';

type AddOrEditExternalSystemModalProps =
  { externalSystem?: any; company: CompanyMinimized; onCancel: any; onSave: any } &
  ModalProps;

export const AddOrEditExternalSystemModal = ({
  externalSystem,
  company,
  onCancel,
  onSave,
  ...props
}: AddOrEditExternalSystemModalProps) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();

  const isEditing = Boolean(externalSystem);

  const [addExternalSystem] = useMutation(
    adminApi.addExternalSystem,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('External system added'),
      ),
      onError: (error) => toaster.error(error.response.data),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['externalSystems', { companyId: company._id }]),
        () => queryClient.invalidateQueries(['integrationData', { companyId: company._id, type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY }]),
      ),
    },
  );

  const [updateExternalSystem] = useMutation(
    adminApi.updateExternalSystem,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('External system updated'),
      ),
      onError: () => toaster.error('External system could not be updated'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['externalSystem', { externalSystemId: externalSystem._id }]),
        () => queryClient.invalidateQueries(['integrationData', { companyId: company._id, type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY }]),
      ),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={
          isEditing ? externalSystem : {
            systemId: '',
            name: '',
            description: '',
            hasExternalToInternalCompanyMap: externalSystem?.hasExternalToInternalCompanyMap || false,
            allowManyToOneMapping: false,
            hasExternalSupplierStatuses: false,
          }
        }
        validationSchema={
          yup.object().shape({
            systemId: yup.string().required('Required'),
            name: yup.string().required('Required'),
            description: yup.string(),
            hasExternalToInternalCompanyMap: yup.boolean().required(),
            allowManyToOneMapping: yup.boolean(),
            hasExternalSupplierStatuses: yup.boolean(),
          })
        }
        onSubmit={async ({
          systemId,
          name,
          description,
          hasExternalToInternalCompanyMap,
          allowManyToOneMapping,
          hasExternalSupplierStatuses,
        }) => {
          if (isEditing) {
            return updateExternalSystem({
              _id: externalSystem._id,
              name,
              description,
              hasExternalToInternalCompanyMap,
              allowManyToOneMapping,
              hasExternalSupplierStatuses,
            });
          } else {
            return addExternalSystem({
              companyId: company._id,
              systemId,
              name,
              description,
              hasExternalToInternalCompanyMap,
              allowManyToOneMapping,
              hasExternalSupplierStatuses,
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {isEditing ? 'Update external system' : 'Add external system'}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <CompanyLogoWithName company={company} />
                <TextField
                  required
                  name="systemId"
                  label="System ID"
                  disabled={isEditing}
                />
                <TextField
                  required
                  name="name"
                  label="Name"
                />
                <TextField
                  isMultiLine
                  name="description"
                  label="Description"
                />
                <SwitchField
                  name="hasExternalToInternalCompanyMap"
                  label="Has supplier map?"
                  disabled={isEditing && externalSystem.hasExternalToInternalCompanyMap}
                />
                {values.hasExternalToInternalCompanyMap &&
                  (<SwitchField
                    name="allowManyToOneMapping"
                    label="Allow many-to-one mapping?"
                    infoTooltip="Allows many external supplier records to be mapped to a single DeepStream supplier"
                    tooltipIcon="info-circle"
                   />)
                }
                {values.hasExternalToInternalCompanyMap &&
                  (<SwitchField
                    name="hasExternalSupplierStatuses"
                    label="Has external supplier statuses?"
                    infoTooltip="Supplier records have an external status"
                    tooltipIcon="info-circle"
                   />)
                }
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {isEditing ? 'Update external system' : 'Add external system'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
