import * as React from 'react';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useInView } from 'react-intersection-observer';
import { FlexProps } from 'rebass/styled-components';
import { find } from 'lodash';
import { useQuery } from 'react-query';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelText, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { TeamUserModal } from '@deepstream/ui/modules/PreQualification/Questionnaire/Team/TeamUserModal';
import { QuestionnaireTeamMember } from '@deepstream/common/preQual';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { QuestionnaireProvider } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { useApi, wrap } from '@deepstream/ui/api';
import { PropertyList, Skip, PropertyListAction } from '@deepstream/ui/PropertyList';
import { CompanyLink } from '../../CompanyLink';
import { Page } from '../../Page';
import * as Title from '../../title';
import { UserLink } from '../../UserLink';
import { Id } from '../../Id';
import { questionnaireCompanyUserRoute, useAdminNavigation } from '../../AppRouting';
import { getUser, useAdminQuestionnaire } from './adminPreQual';
import { QuestionnaireLink } from './QuestionnaireLinkCell';
import { RemoveUserFromQuestionnaireButton } from './RemoveUserFromQuestionnaireButton';
import { AdminQuestionnairePermissionTable } from './AdminQuestionnairePermissionTable';

const textColumn: React.CSSProperties = {
  width: 'auto',
  minWidth: '10%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const PageTitle = ({ flexDirection, company, questionnaireId, user }: {
  flexDirection?: FlexProps['flexDirection'];
  questionnaireId: string;
  company: CompanyMinimized;
  user: QuestionnaireTeamMember;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <UserLink
        key="user"
        userId={user._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.User
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'large' : 'small'}
          user={user}
        />
      </UserLink>

      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          company={company}
        />
      </CompanyLink>

      <QuestionnaireLink
        questionnaireId={questionnaireId}
        style={flexDirection === 'column' ? undefined : { ...textColumn }}
      >
        <Title.Questionnaire
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
        />
      </QuestionnaireLink>
    </Title.Container>
  );
};

const AdminQuestionnaireCompanyUserPanels = ({
  questionnaireId,
  company,
  user,
}: {
  questionnaireId: string;
  company: CompanyMinimized;
  user: QuestionnaireTeamMember & { isOwner: boolean };
}) => {
  const editQuestionnairePermissionsModal = useModalState();
  const api = useApi();
  const { navigateToQuestionnaireCompany } = useAdminNavigation();

  const generalUserProperties = React.useMemo(
    () => [
      { name: 'ID', value: user._id, Component: Id },
      { name: 'Role', value: user.isOwner ? 'Owner' : 'Team member' },
    ],
    [user],
  );

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId: company._id }],
    wrap(api.getUsersForCompany),
  );

  const userForCompany = find(usersForCompany, { _id: user._id });

  return (
    <>
      <Panel heading="General" mb={4}>
        <PropertyList properties={generalUserProperties}>
          <Skip />
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={editQuestionnairePermissionsModal.open}
            disabled={(
              !userForCompany ||
              userForCompany.roleRestrictionProfiles?.[company._id] === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
            )}
          />
        </PropertyList>
      </Panel>
      <Panel mb={4}>
        <PanelHeader heading="Permissions">
          <Button
            small
            iconLeft="pencil-alt"
            onClick={editQuestionnairePermissionsModal.open}
            disabled={!userForCompany}
          >
            Edit
          </Button>
        </PanelHeader>
        <PanelDivider />
        <AdminQuestionnairePermissionTable user={user} />
        {userForCompany && editQuestionnairePermissionsModal.isOpen && (
          <TeamUserModal
            companyId={company._id}
            user={{
              ...user,
              companyId: company._id,
              email: userForCompany.email,
              roleRestrictionProfiles: userForCompany.roleRestrictionProfiles!,
            }}
            close={editQuestionnairePermissionsModal.close}
            onRemove={() => navigateToQuestionnaireCompany(questionnaireId, company._id, true)}
          />
        )}
      </Panel>
      <Panel heading="Remove user from questionnaire" mb={4}>
        <PanelText>
          <RemoveUserFromQuestionnaireButton
            questionnaireId={questionnaireId}
            company={company}
            user={{
              ...user,
              companyId: company._id,
              email: userForCompany?.email || '',
              roleRestrictionProfiles: userForCompany ? userForCompany.roleRestrictionProfiles! : {},
            }}
            disabled={!userForCompany}
          />
        </PanelText>
      </Panel>
    </>
  );
};

export const AdminQuestionnaireCompanyUserPage = () => {
  const { questionnaireId, companyId, userId } = questionnaireCompanyUserRoute.useParams();
  const [ref, inView, entry] = useInView();

  const { data: questionnaire, error, isLoading } = useAdminQuestionnaire();

  const company = find(questionnaire?.senders, { _id: companyId }) ?? questionnaire?.recipient;

  const user = questionnaire ? getUser(questionnaire, companyId, userId) : null;

  return (
    <Page
      subHeading={questionnaire && company && user && !inView && entry && (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <PageTitle
            questionnaireId={questionnaireId}
            company={company}
            user={user}
          />
        </QuestionnaireProvider>
      )}
    >
      {isLoading ? (
        <Panel>
          <PanelText>
            <Loading />
          </PanelText>
        </Panel>
      ) : error ? (
        <Panel heading="Error">
          <PanelText>Oh no</PanelText>
        </Panel>
      ) : questionnaire && company && user ? (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <div ref={ref}>
            <PageTitle
              flexDirection="column"
              questionnaireId={questionnaireId}
              company={company}
              user={user}
            />
          </div>
          <AdminQuestionnaireCompanyUserPanels
            questionnaireId={questionnaireId}
            company={company}
            user={user}
          />
        </QuestionnaireProvider>
      ) : null}
    </Page>
  );
};
