import * as React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { CompanyNameText } from './CompanyNameText';
import { UserNameText } from './UserNameText';

export const TooltipListWrapper = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const TooltipList = ({ title, items, ItemComponent } : { title?: string; items: string[]; ItemComponent: React.FC<{ item: string }> }) => {
  return (
    <>
      {title && <Text>{title}:</Text>}
      <TooltipListWrapper>
        {items.map((item) => (
          <li key={item}>
            <ItemComponent item={item} />
          </li>
        ))}
      </TooltipListWrapper>
    </>
  );
};

export const CompanyNamesTooltip = ({
  title,
  companyIds,
} : { title?: string; companyIds: string[] }) => {
  return (
    <TooltipList
      title={title}
      items={companyIds}
      ItemComponent={({ item }) => (<CompanyNameText id={item} />)}
    />
  );
};

export const UserNamesTooltip = ({
  title,
  userIds,
} : { title?: string; userIds: string[] }) => {
  return (
    <TooltipList
      title={title}
      items={userIds}
      ItemComponent={({ item }) => (<UserNameText id={item} />)}
    />
  );
};
