import { returnTrue } from '@deepstream/utils';
import { useRef, useEffect } from 'react';

type Filter <T> = (arg: T) => boolean;

// Hook to keep a reference to the previous value provided it passes the
// `filter`. The default `filter` allows all values.
export const usePrevious = <T,> (value: T, filter: Filter<T> = returnTrue) => {
  const ref = useRef<T>();

  useEffect(() => {
    if (filter(value)) {
      ref.current = value;
    }
  }, [value, filter]);

  return ref.current;
};
