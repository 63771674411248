import { get, isFinite } from 'lodash';
import type { TFunction } from 'i18next';
import { immutableSet } from '@deepstream/utils';
import type {
  EvaluationCriterionExchangeDefinition,
  FieldConfig,
  FieldSource,
  DefinitionFieldConfig,
  LineItemExchangeDefinition,
  LineItemFormulaFieldConfig,
  ReplyFieldConfig,
  LinkedFieldConfig,
  ExchangeDefinition,
  AuctionLineItemExchangeDefinition,
} from './types';

/**
 * Determines whether the field has a formula source type, which implies that
 * the field value is part of the field source config.
 */
export const isFormulaField = (field: FieldConfig<FieldSource>): field is LineItemFormulaFieldConfig => {
  return field.source.type === 'formula';
};

/**
 * Determines whether the field's value is provided while defining the field
 * and stored on the exchange definition.
 */
export const isDefinitionField = (field: FieldConfig<FieldSource>): field is DefinitionFieldConfig =>
  field.source.type === 'definition';

/**
 * Determines whether the field's value is provided by linking to another
 * exchange definition.
 */
export const isLinkedField = (field: FieldConfig<FieldSource>): field is LinkedFieldConfig =>
  field.source.type === 'linked-exchange-definition-field';

/**
 * Determines whether the field's value is provided by replying to an exchange
 * instance and stored on the exchange instance.
 */
export const isReplyField = (field: FieldConfig<FieldSource>): field is ReplyFieldConfig =>
  field.source.type === 'reply';

export const isSupplierReplyField = (field: FieldConfig<FieldSource>): field is ReplyFieldConfig =>
  isReplyField(field) && field.source.role === 'submitter';

export const isBuyerReplyField = (field: FieldConfig<FieldSource>): field is ReplyFieldConfig =>
  isReplyField(field) && field.source.role !== 'submitter';

export const isBuyerField = (field: FieldConfig<FieldSource>) =>
  isDefinitionField(field) || isBuyerReplyField(field);

export const isFieldWithDecimalPlaces = (field: FieldConfig<FieldSource>) =>
  field.type === 'price' && field.decimalPlaces !== undefined;

export const isFieldHiddenFromBuyerWhenLocked = (field: FieldConfig<FieldSource>) => (
  isSupplierReplyField(field) ||
  isFormulaField(field) ||
  field.source.type === 'parent-section-currency'
);

export const getExchangeDefFieldSourceKey = (
  exchangeDef: LineItemExchangeDefinition,
  fieldId: keyof LineItemExchangeDefinition['fields'],
) => {
  const field = exchangeDef.fields[fieldId];

  if (!field || !('key' in field.source)) {
    return;
  }

  return field.source.key;
};

export const getExchangeFieldValue = (
  exchange: {
    def: LineItemExchangeDefinition;
    latestReply: Record<keyof LineItemExchangeDefinition['fields'], unknown>;
    computedFormulas?: Record<keyof LineItemExchangeDefinition['fields'], number>;
    currency: string;
  } | {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
    computedFormulas?: Record<keyof LineItemExchangeDefinition['fields'], number>;
  } | {
    def: AuctionLineItemExchangeDefinition;
    latestReply: Record<string, unknown>;
    computedFormulas?: Record<keyof LineItemExchangeDefinition['fields'], number>;
  },
  fieldId: keyof LineItemExchangeDefinition['fields'],
) => {
  const field = exchange.def.fields[fieldId];

  if (!field) {
    return;
  }

  switch (field.source.type) {
    case 'definition':
      return get(exchange.def, field.source.key);
    case 'reply':
      return exchange.latestReply[field._id];
    case 'formula':
      return exchange.computedFormulas?.[field._id];
    case 'parent-section-currency':
      return (exchange as { currency: string }).currency;
    default:
      throw new Error(`Cannot get value of field '${fieldId}'`);
  }
};

export const getExchangeDefFieldValue = (
  exchangeDef: ExchangeDefinition,
  fieldId: keyof ExchangeDefinition['fields'],
) => {
  const field = exchangeDef.fields?.[fieldId];

  if (!field) {
    return;
  }

  if (!isDefinitionField(field)) {
    throw new Error(
      `Field ${fieldId} has source of type '${field.source.type}' instead of 'definition'`,
    );
  }

  // We use `_.get` because The field `key` can be a deep path
  return get(exchangeDef, field.source.key);
};

export const resolveExchangeDefFieldValue = (
  exchangeDefById: Record<string, ExchangeDefinition>,
  exchangeDef: Extract<ExchangeDefinition, { fields: unknown }>,
  fieldId: string,
) => {
  if (!exchangeDef) return;

  const field = exchangeDef.fields[fieldId];

  if (field && isLinkedField(field)) {
    const linkedExchangeDef = exchangeDefById[field.source.exchangeDefId];

    if (!linkedExchangeDef) throw new Error(`no exchange definition found for id: ${field.source.exchangeDefId}`);

    if (!('fields' in linkedExchangeDef)) {
      return linkedExchangeDef[field.source.fieldId];
    }

    return getExchangeDefFieldValue(linkedExchangeDef, field.source.fieldId as keyof ExchangeDefinition['fields']);
  }

  return getExchangeDefFieldValue(exchangeDef, fieldId as keyof ExchangeDefinition['fields']);
};

// TODO this functions is called from Evaluation/LineItems/Contracts
// but the exchangeDef type is always LineItemExchangeDefinition
export const setExchangeDefFieldValue = (
  exchangeDef: LineItemExchangeDefinition,
  fieldId: keyof LineItemExchangeDefinition['fields'],
  value: any,
) => {
  const field = exchangeDef.fields[fieldId];

  if (!field) {
    throw new Error(`Field ${fieldId} does not exist`);
  }

  if (!isDefinitionField(field)) {
    throw new Error(
      `Field ${fieldId} has source of type '${field.source.type}' instead of 'definition'`,
    );
  }

  // We use `immutableSet` because The field `key` can be a deep path
  return immutableSet(exchangeDef, field.source.key, value);
};

export const setExchangeReplyFieldValue = (
  exchange: any,
  fieldId: keyof LineItemExchangeDefinition['fields'],
  value: any,
) => {
  const field = exchange.def.fields[fieldId];

  if (!field) {
    throw new Error(`Field ${fieldId} does not exist`);
  }

  if (!isSupplierReplyField(field)) {
    throw new Error(
      `Field ${fieldId} has source of type is not a supplier reply field`,
    );
  }

  return immutableSet(exchange, ['latestReply', fieldId], value);
};

export const getFormattedFieldLabel = (field: FieldConfig, t: TFunction, hideRole = false) => {
  const [fieldsetId, role] = field._id.split(':');
  const label = (field as { label?: string }).label ||
    t(`request.fields.predefinedFieldLabel.${fieldsetId}`, { ns: 'translation' });

  if (role && !hideRole) {
    return t(`request.fields.appendedValueProvider.${role}`, { label, ns: 'translation' });
  }

  return label;
};

export const isFieldValueDefined = (fieldValue: unknown, fieldType: FieldConfig['type']) => {
  switch (fieldType) {
    case 'number':
    case 'price':
    case 'scorePoints':
      return isFinite(fieldValue);
    default:
      return Boolean(fieldValue);
  }
};

export const getEmptyFieldValue = (fieldType: FieldConfig['type']) => {
  switch (fieldType) {
    case 'string':
      return '';
    default:
      return null;
  }
};

export const isFieldOnlyVisibleToEvaluator = (field: FieldConfig<FieldSource>) => {
  return (
    field.visibility?.type === 'has-role' &&
    get(field.visibility, ['params', 'role']) === 'evaluator'
  );
};
