import { find } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { RfqEventChange } from '@deepstream/common/rfq-utils';
import { useMutation } from '@deepstream/ui/useMutation';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { RfxStructure } from '@deepstream/ui/types';
import { useRfqId } from '@deepstream/ui/useRfq';
import { useMemo } from 'react';
import { useWaitForRfqUnlock } from '@deepstream/ui/useWaitForUnlock';

export const getAdminRequestQueryKey = (requestId: string) =>
  ['admin-request-overview', { requestId }];

export const getAdminTemplateQueryKey = (templateId: string) =>
  ['admin-template-overview', { templateId }];

export const useAdminRequestQueryKey = (requestId: string) => {
  return useMemo(() => getAdminRequestQueryKey(requestId), [requestId]);
};

export const useAdminTemplateQueryKey = (templateId: string) => {
  return useMemo(() => getAdminTemplateQueryKey(templateId), [templateId]);
};

export const useAdminRequest = (requestId: string) => {
  const adminApi = useAdminApi();
  const queryKey = useAdminRequestQueryKey(requestId);

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminRequestOverview),
  );
};

export const useAdminTemplate = (templateId: string) => {
  const adminApi = useAdminApi();
  const queryKey = useAdminTemplateQueryKey(templateId);

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminTemplateOverview),
  );
};

export const useUpdateRfq = () => {
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  return useMutation(
    (payload: {
      companyId: string;
      requestId: string;
      changes: any[];
  }) => waitForRfqUnlock({
      command: () => adminApi.updateRfq(payload),
    }),
    {
      onSettled: (_, __, { requestId }) =>
        queryClient.invalidateQueries(getAdminRequestQueryKey(requestId)),
    },
  );
};

export const useSaveChanges = <TGetChanges extends (args: any) => RfqEventChange[]> ({
  companyId,
  getChanges,
  onError,
  onSuccess,
}: {
  companyId: string;
  getChanges: TGetChanges;
  onError: any;
  onSuccess: any;
}) => {
  const adminApi = useAdminApi();
  const requestId = useRfqId();
  const adminRequestQueryKey = getAdminRequestQueryKey(requestId);
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const mutation = useMutation(
    (params: Parameters<TGetChanges>[0]) => waitForRfqUnlock({
      command: () => adminApi.updateRfq({
        companyId,
        requestId,
        changes: getChanges(params),
      }),
    }),
    {
      mutationKey: adminRequestQueryKey,
      onError,
      onSuccess,
      onSettled: () => queryClient.invalidateQueries(adminRequestQueryKey),
    },
  );

  return mutation;
};

export const getUser = (structure: RfxStructure, companyId: string, userId: string) => {
  const team = structure.teamById[companyId];

  if (!team) {
    return null;
  }

  const user = find(team.users, { _id: userId });

  if (!user) {
    return null;
  }

  user.isOwner = team.owners.includes(userId);

  return user;
};
