import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { isNumber } from 'lodash';

import { localeFormatNumber } from '@deepstream/utils';
import { CircularChart } from '@deepstream/ui-kit/charts/CircularChart';
import { useRequestsReportingData } from './useRequestsReporting';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const RequestCompletionRateChart = () => {
  const { data } = useRequestsReportingData();
  const { t } = useTranslation('reporting');
  const locale = useCurrentUserLocale();

  // @ts-ignore ts(2339) FIXME: Property 'supplierCompletionRate' does not exist on type 'never'.
  const value = data?.supplierCompletionRate;
  const completed = Math.round(value * 100);

  return (
    <CircularChart
      tooltip={isNumber(value)
        ? (value) => t('supplierEngagement.requestCompletionRatePanel.tooltip', {
          progress: localeFormatNumber(value.progress, { locale }),
          label: value.label.toLowerCase(),
        })
        : false
      }
      values={[
        {
          progress: completed,
          variant: 'info',
          label: t('supplierEngagement.requestCompletionRatePanel.valueCompletedLabel'),
        },
        {
          progress: 100 - completed,
          variant: 'secondary',
          label: t('supplierEngagement.requestCompletionRatePanel.valueIncompleteLabel'),
        },
      ]}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100%',
          textAlign: 'center',
          gap: '4px',
        }}
      >
        {isNumber(value) ? (
          <>
            <Text color="gray" fontSize={1}>
              {t('supplierEngagement.requestCompletionRatePanel.chartDescription')}
            </Text>
            <Text fontSize={6} fontWeight={600}>
              {completed}%
            </Text>
          </>
        ) : (
          <Text fontSize={4} fontWeight={600}>
            {t('noData')}
          </Text>
        )}
      </Flex>
    </CircularChart>
  );
};
