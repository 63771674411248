import * as React from 'react';
import { isEmpty } from 'lodash';
import { getGridCellId, getGridContainerId } from '../core/utils';
import { useGridIdPrefix } from './gridIdPrefix';

export interface GridMenuStateContextType {
  menuReferenceId: string | null;
  setMenuReferenceId: React.Dispatch<string | null>;
  hideMenu: () => void;
  toggleMenu: (rowIndex: number, columnIndex: number) => void;
  dataIndex: number | null;
}

const GridMenuStateContext = React.createContext<GridMenuStateContextType | null>(null);

export const GridMenuStateProvider = ({ children }) => {
  const idPrefix = useGridIdPrefix();
  const [menuReferenceId, setMenuReferenceId] = React.useState<string | null>(null);
  const [dataIndex, setDataIndex] = React.useState<number | null>(null);

  const hideMenu = React.useCallback(() => setMenuReferenceId(null), [setMenuReferenceId]);

  const toggleMenu = React.useCallback((rowIndex: number, columnIndex: number) => {
    const cellId = getGridCellId(idPrefix, { rowIndex, columnIndex });
    const containerId = getGridContainerId(idPrefix);

    const hasInput = !isEmpty(
      document.getElementById(containerId)?.getElementsByTagName('input'),
    );

    if (hasInput) return;

    setMenuReferenceId(menuReferenceId === cellId ? null : cellId);
    // the grid rows include the header row so we need
    // to to subtract 1 to get the data index
    setDataIndex(rowIndex - 1);
  }, [setDataIndex, idPrefix, menuReferenceId]);

  const value = React.useMemo(() => {
    return {
      menuReferenceId,
      setMenuReferenceId,
      hideMenu,
      toggleMenu,
      dataIndex,
    };
  }, [
    menuReferenceId,
    setMenuReferenceId,
    hideMenu,
    toggleMenu,
    dataIndex,
  ]);

  return (
    <GridMenuStateContext.Provider value={value}>
      {children}
    </GridMenuStateContext.Provider>
  );
};

export const useGridMenuState = () => {
  const state = React.useContext(GridMenuStateContext);
  if (!state) throw new Error('No grid menu state found');
  return state;
};
