import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { UserLinkCell } from '../UserLinkCell';
import { CompanyLinkCell } from '../CompanyLinkCell';
import { useAdminNavigation } from '../AppRouting';

export const ChatbotChatsTable = ({ chats }) => {
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: chat => new Date(chat.createdAt),
        Cell: DatetimeCell,
        sortType: 'datetime',
      },
      {
        Header: 'Company',
        accessor: 'company.name',
        Cell: withProps(CompanyLinkCell, { getCompanyId: (row) => row.company._id }),
      },
      {
        Header: 'User ID',
        accessor: 'userId',
        Cell: withProps(UserLinkCell, { getUserId: (row) => row.userId }),
      },
      {
        Header: 'Messages',
        accessor: 'state.context.history.length',
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={chats}
        isSortable
        noFilteredDataPlaceholder="No chats found"
        smallPageControls
        isPaginated
        initialPageSize={20}
        onRowClick={chat => navigation.navigateToReportsChatbotChat(chat._id)}
      />
    </BasicTableStyles>
  );
};
