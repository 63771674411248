import { useMemo } from 'react';
import { flatMap } from 'lodash';

import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';

export const InternalToExternalLineItemDataTable = ({ internalToExternalLineItemData }) => {
  const lineItemData = flatMap(internalToExternalLineItemData, lineItemData => Object
    .entries(lineItemData.data as Record<string, { _id: string }>[])
    .map(([lineItemId, { _id }]) => ({
      lineItemId,
      externalId: _id,
      rfxId: lineItemData.meta.rfxId,
    })));

  const lineItemColumns = useMemo(
    () => [
      {
        Header: 'RFX ID',
        accessor: 'rfxId',
      },
      {
        Header: 'Line item ID',
        accessor: 'lineItemId',
      },
      {
        Header: 'External line item ID',
        accessor: 'externalId',
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={lineItemColumns}
        data={lineItemData}
      />
    </StaticTableStyles>
  );
};
