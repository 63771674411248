export const formatOptions = {
  'number': {
    thousandSeparator: true,
    decimalSeparator: '.',
  },
  'number.positive': {
    thousandSeparator: true,
    decimalSeparator: '.',
    allowNegative: false,
  },
  'number.negative': {
    thousandSeparator: true,
    decimalSeparator: '.',
    prefix: '-',
  },
  'integer': {
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 0,
  },
  'integer.positive': {
    allowNegative: false,
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 0,
  },
  'integer.negative': {
    prefix: '-',
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 0,
  },
  'money': {
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    decimalSeparator: '.',
  },
  'money.positive': {
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    decimalSeparator: '.',
  },
  'percentage.positive': {
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    decimalSeparator: '.',
  },
  'year': {
    format: '####',
    placeholder: 'YYYY',
    mask: ['Y', 'Y', 'Y', 'Y'],
  },
  'score': {
    decimalScale: 2,
    fixedDecimalScale: true,
    decimalSeparator: '.',
    suffix: '%',
  },
};
