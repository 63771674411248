import { useMemo } from 'react';

import { Flex } from 'rebass/styled-components';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { IntegrationsSelectedUsersTable } from './IntegrationsSelectedUsersTable';
import { renderPercentage } from '../reports/utils';

export const IntegrationsSelectedUsersModal = ({
  uniqueUsers,
  uniqueCompanies,
  ...props
}: Omit<DialogProps, 'heading' | 'body'> & {
  uniqueUsers;
  uniqueCompanies;
}) => {
  const proceededUsersCount = useMemo(() => uniqueUsers.filter(user => user.proceeded).length, [uniqueUsers]);
  const notProceededUsersCount = useMemo(() => uniqueUsers.filter(user => !user.proceeded).length, [uniqueUsers]);

  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading="Conversion funnel - 2. Integrations selected"
      body={(
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Yes"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="success" icon="arrow-right" regular mr={1} />
                    {proceededUsersCount}
                  </>
                )}
                mr={4}
              />
              <LabeledValue
                label="No"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="danger" icon="times" regular mr={1} />
                    {notProceededUsersCount}
                  </>
                )}
                mr={4}
              />
              <LabeledValue
                label="Rate proceeded"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: proceededUsersCount,
                  total: uniqueUsers.length,
                })}
              />
            </Flex>
            <Flex>
              <LabeledValue
                label="Unique companies"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueCompanies.total}
                mr={4}
              />
              <LabeledValue
                label="Yes"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="success" icon="arrow-right" regular mr={1} />
                    {uniqueCompanies.proceeded}
                  </>
                )}
                mr={4}
              />
              <LabeledValue
                label="No"
                align="left"
                labelProps={{ color: 'text' }}
                value={(
                  <>
                    <Icon color="danger" icon="times" regular mr={1} />
                    {uniqueCompanies.notProceeded}
                  </>
                )}
                mr={4}
              />
              <LabeledValue
                label="Rate proceeded"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: uniqueCompanies.proceeded,
                  total: uniqueCompanies.total,
                })}
              />
            </Flex>
          </Flex>
          <IntegrationsSelectedUsersTable uniqueUsers={uniqueUsers} />
        </Stack>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
