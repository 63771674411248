import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';

export const useIsAuthorizedStakeholder = () => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const roleRestrictionProfile = currentUser.roleRestrictionProfiles?.[currentCompanyId];

  return roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
};
