import { useState, useCallback } from 'react';
import { useInterval } from './useInterval';

export const useForceUpdate = () => {
  const [, forceUpdate] = useState(0);

  return useCallback(
    () => forceUpdate(count => count + 1),
    [],
  );
};

export const useForceUpdateInterval = (intervalMs: number) => {
  const forceUpdate = useForceUpdate();
  useInterval(forceUpdate, intervalMs);
};
