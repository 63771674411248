import { useMemo, useCallback, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { Box, BoxProps } from 'rebass/styled-components';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Input } from '../ui/Input';
import { DatePicker, DatePickerProps } from '../ui/DatePicker';
import { useDeviceSize } from '../ui/useDeviceSize';
import { ErrorMessage, HelperText } from './Field';
import { FieldContainer } from './FieldContainer';

type DatetimeFieldBaseProps = DatePickerProps & {
  name?: string;
  label?: string;
  description?: string;
  required?: boolean;
  hasTime?: boolean;
  value: Date;
  placeholder?: string;
  dateFormat?: string;
  min?: Date | string;
  max?: Date | string;
  fullWidth?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange?: any;
  error?: string;
  hideLabel?: boolean;
  inputContainerProps?: BoxProps;
  /**
   * If true, the end of the selected day (in the user's timezone) will be set as the value.
   * Can only be used if `hasTime` is false.
   * Example:
   * if the user selects 2020-01-01, and he's in the UTC+2 timezone the value will be 2020-01-01T21:59:59:999Z
   */
  useEndOfDay?: boolean;
  /**
   * If true, the start of the selected day (in the user's timezone) will be set as the value.
   * Can only be used if `hasTime` is false.
   * Example:
   * if the user selects 2020-01-01, and he's in the UTC+2 timezone the value will be 2020-12-31T22:00:00.000Z
   */
  useStartOfDay?: boolean;
};

export const DatetimeFieldBase = ({
  name,
  label,
  description,
  error,
  hasTime,
  value,
  placeholder,
  dateFormat = 'dd MMM yyyy',
  min,
  max,
  fullWidth,
  helperText,
  disabled,
  onChange,
  required,
  hideLabel,
  inputContainerProps = {},
  useEndOfDay,
  useStartOfDay,
  ...props
}: DatetimeFieldBaseProps) => {
  const id = useUniqueId();
  const { isExtraSmall } = useDeviceSize();
  const dateValue = value ? new Date(value) : value;

  // The native date input only accepts these formats
  const nativeInputFormat = useMemo(
    () => hasTime ? 'yyyy-MM-ddTHH:mm' : 'yyyy-MM-dd',
    [hasTime],
  );

  const nativeInputValue = useMemo(
    () => value
      ? format(new Date(value), nativeInputFormat)
      : '',
    [value, nativeInputFormat],
  );

  const nativeInputMinValue = min
    ? min === 'now'
      ? format(new Date(), nativeInputFormat)
      : format(new Date(min), nativeInputFormat)
    : undefined;

  const minDate = min
    ? min === 'now'
      ? new Date()
      : new Date(min)
    : undefined;

  const nativeInputMaxValue = max
    ? format(new Date(max), nativeInputFormat)
    : undefined;

  const maxDate = max
    ? new Date(max)
    : undefined;

  const onDateChange = useCallback(
    value => {
      if (!value) {
        onChange(value);
        return;
      }

      const newValue = useEndOfDay
        ? endOfDay(value).toISOString()
        : useStartOfDay
          ? startOfDay(value).toISOString()
          : (value as Date).toISOString();

      onChange(newValue);
    },
    [onChange, useEndOfDay, useStartOfDay],
  );

  const onInputChange = useCallback(
    event => {
      const { value } = event.target;

      const newValue = useEndOfDay
        ? endOfDay(new Date(value)).toISOString()
        : useStartOfDay
          ? startOfDay(new Date(value)).toISOString()
          : value;

      onChange(newValue);
    },
    [onChange, useEndOfDay, useStartOfDay],
  );

  if (hasTime && (useEndOfDay || useStartOfDay)) {
    throw new Error('`useEndOfDay` and `useStartOfDay` cannot be used together with `hasTime`');
  }

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
      description={description}
    >
      {isExtraSmall ? (
        <Box {...inputContainerProps}>
          <Input
            id={id}
            type={hasTime ? 'datetime-local' : 'date'}
            width={fullWidth ? '100%' : '250px'}
            disabled={disabled}
            min={nativeInputMinValue}
            max={nativeInputMaxValue}
            value={nativeInputValue as string}
            onChange={onInputChange}
            placeholder={placeholder}
          />
        </Box>
      ) : (
        <Box {...inputContainerProps}>
          <DatePicker
            value={dateValue}
            dateFormat={dateFormat}
            onChange={onDateChange}
            disabled={disabled}
            showTimeSelect={hasTime}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholder}
            {...props}
          />
        </Box>
      )}
      {error ? (
        <ErrorMessage error={error} />
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

interface DatetimeFieldProps extends DatetimeFieldBaseProps {
  /**
   * Use this prop if you want the value to be bound to the form (ie: for editing)
   */
  name?: string;
  /**
   * Use this prop if you want to read a value from the form, without the possibility
   * of editing (ie: for a synced read-only field)
   */
  fieldName?: string;
  validateOnMount?: boolean;
}

/**
 * Formik-aware datetime field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const DatetimeField = ({
  name,
  fieldName,
  onChange,
  validateOnMount,
  ...props
}: Omit<DatetimeFieldProps, 'value'>) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });
  const context = useFormikContext();
  // HACK: force the control to revalidate on untouched form, so we need to update formik
  useEffect(() => {
    if (validateOnMount) {
      context.validateField(field.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateOnMount]);

  return (
    <DatetimeFieldBase
      name={name}
      value={field.value}
      error={(meta.touched || validateOnMount) && meta.error ? meta.error : undefined}
      onChange={value => {
        formik.setTouched(true);
        formik.setValue(value);

        if (onChange) {
          onChange(value);
        }
      }}
      {...props}
    />
  );
};
