import * as React from 'react';
import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
  TabPanels,
  TabListProps,
  TabProps,
  TabsProps,
  TabsOrientation,
} from '@reach/tabs';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Panel, PanelPadding, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../Bold';
import { TabPanel } from './TabPanel';

// Value used to extend the bg of the Tab into the PanelPadding
// (ie: to maintain consistent left alignment of the tab's label
// with other panel content)
const TAB_BG_OVERLAP = 2;

export const TabList = (props: TabListProps) => (
  <ReachTabList
    style={{
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
    }}
    {...props}
  />
);

type TabListPanelProps = TabListProps &
  {
    heading?: React.ReactNode | string;
    panelStyle?: React.CSSProperties;
    headerButton?: React.ReactNode | null;
  };

export const TabListPanel = ({
  heading,
  panelStyle,
  headerButton = null,
  ...props
}: TabListPanelProps) => (
  <Panel sx={panelStyle}>
    <PanelPadding>
      <Box m={-TAB_BG_OVERLAP}>
        {heading && (
          <Box p={TAB_BG_OVERLAP}>
            <Flex justifyContent="space-between" alignItems="center">
              <SidebarPanelHeading>
                {heading}
              </SidebarPanelHeading>
              {headerButton}
            </Flex>
          </Box>
        )}
        <TabList {...props} />
      </Box>
    </PanelPadding>
  </Panel>
);

export const StyledReachTab = styled(ReachTab)`
  cursor: pointer;
  text-align: left;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.space[TAB_BG_OVERLAP]}px;
  border: none;
  border-radius: ${props => props.theme.radii.small}px;
  width: 100%;
  color: ${props => props.theme.colors[props.color || 'text']};

  &:focus {
    background-color: ${props => transparentize(0.85, props.theme.colors.primary)};
    outline: none;
  }

  &:hover:not(:focus):not(:disabled) {
    background-color: ${props => transparentize(0.95, props.theme.colors.primary)};
    outline: none;
  }

  &[aria-selected=true] {
    color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const Tab = React.forwardRef((
  {
    children,
    ...props
  }: TabProps & { color?: string; style?: React.CSSProperties },
  ref: React.Ref<HTMLButtonElement>,
) => (
  <StyledReachTab ref={ref} {...props}>
    <Bold sx={{ width: '100%' }}>
      {children}
    </Bold>
  </StyledReachTab>
));

export const Tabs = (props: Omit<TabsProps, 'orientation'>) => (
  <ReachTabs
    orientation={TabsOrientation.Vertical}
    {...props}
  />
);

export {
  TabPanel,
  TabPanels,
};
