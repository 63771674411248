import { useState, useMemo } from 'react';
import { keyBy, sum, values, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieChartPanel } from './PieChartPanel';
import { useRequestsReportingData } from './useRequestsReporting';
import * as dashboard from './dashboard';

export const TotalRequestsByProductOrService = () => {
  const { t } = useTranslation('reporting');
  const [activeSliceId, setActiveSliceId] = useState<string | null>(null);

  const {
    data: {
      numRequestsByProductAndServiceCode,
      numRequestsWithProductsAndServices,
      productsAndServices,
    },
  } = useRequestsReportingData();

  const numRequestsByProductCodeSlices = useMemo(
    () => Object
      .keys(numRequestsByProductAndServiceCode)
      .map(code => ({
        id: code,
        value: numRequestsByProductAndServiceCode[code],
        // @ts-ignore ts(2339) FIXME: Property 'title' does not exist on type 'never'.
        label: productsAndServices[code].title,
      })),
    [numRequestsByProductAndServiceCode, productsAndServices],
  );

  const sliceById = keyBy(numRequestsByProductCodeSlices, slice => slice.id);

  const value = activeSliceId
    ? sliceById[activeSliceId].value
    : !isEmpty(numRequestsByProductAndServiceCode)
      ? numRequestsWithProductsAndServices
      : null;

  return (
    <PieChartPanel
      sx={{ maxWidth: undefined }}
      heading={t('teamProductivity.totalRequestsByProduct')}
      headingTestAttribute="total-requests-by-product"
      slices={numRequestsByProductCodeSlices || []}
      chartDescription={t('teamProductivity.totalRequestsByProduct')}
      onActiveSliceChange={setActiveSliceId}
      // eslint-disable-next-line react/no-children-prop
      children={
        <dashboard.LabeledValue
          label={activeSliceId ? t('teamProductivity.categoryRequests') : t('teamProductivity.totalRequests')}
          mt="28px"
        >
          {value ?? t('noData')}
        </dashboard.LabeledValue>
      }
    />
  );
};
