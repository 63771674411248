import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { TextField } from '@deepstream/ui/form/TextField';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';

type EditNameProps =
  { user: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditEmailModal: React.FC<EditNameProps> = ({ user, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateUserEmail] = useMutation(
    adminApi.updateUserEmail,
    {
      onSuccess: () => toaster.success('Email updated successfully'),
      onError: () => toaster.error('An error occurred while updating the email'),
      onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          email: user.email,
        }}
        validationSchema={
          yup.object().shape({
            email: yup.string().email('Invalid email').required('Required'),
          })
        }
        onSubmit={({ email }, { setFieldError }) =>
          updateUserEmail({
            userId: user._id,
            email,
          }, {
            onSuccess: onSave,
            onError: (error: any) => {
              if (error?.response?.status === 400) {
                setFieldError('email', 'Email is already in use');
              }
            },
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Edit email</ModalHeader>
            <ModalBody>
              <TextField name="email" label="Email" inputType="email" required />
              <MessageBlock variant="info" mb={2}>
                User will be asked to verify their updated email address
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
