import { useState, useMemo } from 'react';
import { isEmpty, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieChartPanel } from './PieChartPanel';
import * as dashboard from './dashboard';
import { useContractsReportingData } from './useContractsReporting';
import { CurrencyAmount } from '../../ui/Currency';

export const ContractsValueByProductOrService = () => {
  const { t } = useTranslation('reporting');
  const [activeSliceId, setActiveSliceId] = useState<string | null>(null);

  const {
    data: {
      contractValueByProductAndServiceCode,
      productsAndServices,
      contractsValueWithProductsAndServices,
    },
  } = useContractsReportingData();

  const contractsValueByProductCodeSlices = useMemo(
    () => Object
      .keys(contractValueByProductAndServiceCode)
      .map(code => ({
        id: code,
        value: contractValueByProductAndServiceCode[code],
        label: productsAndServices[code].title,
      })),
    [contractValueByProductAndServiceCode, productsAndServices],
  );

  const sliceById = keyBy(contractsValueByProductCodeSlices, slice => slice.id);

  const value = activeSliceId
    ? sliceById[activeSliceId].value
    : !isEmpty(contractValueByProductAndServiceCode)
      ? contractsValueWithProductsAndServices
      : null;

  return (
    <PieChartPanel
      heading={t('contractValue.totalValueByProduct')}
      headingTestAttribute="total-value-by-product"
      slices={contractsValueByProductCodeSlices || []}
      chartDescription={t('contractValue.totalValueByProduct')}
      onActiveSliceChange={setActiveSliceId}
    >
      <dashboard.LabeledValue
        label={activeSliceId ? t('contractValue.categoryValue') : t('contractValue.totalValue')}
        mt="28px"
      >
        <CurrencyAmount value={value} placeholder={t('noData')} />
      </dashboard.LabeledValue>
    </PieChartPanel>
  );
};
