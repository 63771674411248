import { useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useQuestionnaireTemplateExchangeDefs } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useGetSupplierResponseCellValue, QuestionTypeCell } from '@deepstream/ui/draft/cell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';

export const AdminQuestionnaireTemplateQuestionsTable = () => {
  const exchangeDefs = useQuestionnaireTemplateExchangeDefs();

  const getSupplierResponseCellValue = useGetSupplierResponseCellValue();

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'description',
        Cell: TruncateCell,
      },
      {
        Header: 'Supplier response',
        accessor: getSupplierResponseCellValue,
        Cell: TruncateCell,
      },
      {
        Header: 'Type',
        accessor: 'questionType',
        Cell: QuestionTypeCell,
      },
    ],
    [getSupplierResponseCellValue],
  );

  return exchangeDefs.length ? (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={exchangeDefs}
      />
    </StaticTableStyles>
  ) : (
    <PanelPadding>
      <Text color="subtext">No questions</Text>
    </PanelPadding>
  );
};
