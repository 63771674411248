import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import * as rfx from '@deepstream/ui/rfx';

export const RequestSendersTable = ({
  onRowClick,
}: {
  onRowClick?: (sender: any) => void;
}) => {
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { senders } = rfx.useStructure();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'company.name',
        Cell: CompanyMinimizedNameAndLogoCell,
      },
      {
        id: 'role',
        Header: 'Collaborator role',
        accessor: 'company.role',
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={senders}
        onRowClick={onRowClick}
        hiddenColumns={!isLarge && !isExtraLarge ? ['role'] : []}
      />
    </BasicTableStyles>
  );
};
