import { useCallback } from 'react';
import { QueryKey, useQueryClient } from 'react-query';
import { useHandleWsMessage } from './WsProvider';

export const useInvalidateQueryOnMessage = (topics: string | string[], queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(
    () => {
      queryClient.invalidateQueries(queryKey);
    },
    [queryKey, queryClient],
  );

  useHandleWsMessage(topics, invalidateQueries, true, 'invalidate-queries');
};
