import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestRole } from '../../../types';

export const useRequestRoleSelectItems = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: RequestRole.OWNER,
        label: t('request.team.requestRole.owner'),
        description: t('request.team.requestRoleDescription.owner'),
      },
      {
        value: RequestRole.TEAM_MEMBER,
        label: t('request.team.requestRole.teamMember'),
        description: t('request.team.requestRoleDescription.teamMember'),
      },
    ],
    [t],
  );
};
