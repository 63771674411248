import * as React from 'react';
import { defaults, set } from 'lodash';
import { ThemeContext } from 'styled-components';
import { useToasts, ToastProvider as ToastProviderBase } from 'react-toast-notifications';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

const ToastContainer: React.FC = (props) => (
  <Box
    pb={30}
    style={{
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'fixed',
      left: '50%',
      bottom: 0,
      transform: 'translateX(-50%)',
      zIndex: 203,
    }}
    {...props}
  />
);

type TransitionStates = 'entering' | 'entered' | 'exiting' | 'exited';

const toastStates = {
  entering: { transform: 'translate3d(0, 120%, 0)' },
  entered: { transform: 'translate3d(0, 0, 0)' },
  exiting: { transform: 'scale(0.66)', opacity: 0 },
  exited: { transform: 'scale(0.66)', opacity: 0 },
};

type ToastProps = {
  appearance: string;
  transitionState: TransitionStates;
  transitionDuration: number;
  children: React.ReactNode;
};

const Toast = ({ appearance, transitionState, transitionDuration, children }: ToastProps) => {
  const theme = React.useContext(ThemeContext);

  return (
    <Flex
      alignItems="center"
      p={3}
      mt={2}
      style={{
        color: 'white',
        fontSize: '14px',
        backgroundColor: theme.colors.navy,
        borderRadius: 3,
        height: 50,
        transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`,
        boxShadow: theme.shadows.large,
        ...toastStates[transitionState],
      }}
    >
      {appearance === 'success' ? (
        <Icon icon="check" color="success" mr={2} fontSize="17px" />
      ) : appearance === 'error' ? (
        <Icon icon="exclamation-circle" color="danger" mr={2} fontSize="17px" />
      ) : appearance === 'warn' ? (
        <Icon icon="exclamation-triangle" color="warning" mr={2} fontSize="17px" />
      ) : (
        null
      )}
      {typeof children === 'string' ? (
        <Text fontFamily={theme.fonts.primary}>
          {children}
        </Text>
      ) : children}
    </Flex>
  );
};

export const ToastProvider = (props: { children: React.ReactNode }) => (
  <ToastProviderBase
    placement="bottom-center"
    autoDismissTimeout={3000}
    components={{ Toast, ToastContainer }}
    {...props}
  />
);

const TOAST_DEFAULTS = { autoDismiss: true };

export const useToaster = () => {
  const { addToast: addToastBase, removeToast } = useToasts();

  const addToast = React.useCallback(
    (message, props, cb?) => addToastBase(message, defaults(props, TOAST_DEFAULTS), cb),
    [addToastBase],
  );

  return React.useMemo(
    () => ({
      success: (message, props = {}, cb?) => addToast(message, set(props, 'appearance', 'success'), cb),
      error: (message, props = {}, cb?) => addToast(message, set(props, 'appearance', 'error'), cb),
      warn: (message, props = {}, cb?) => addToast(message, set(props, 'appearance', 'warn'), cb),
      remove: removeToast,
    }),
    [removeToast, addToast],
  );
};
