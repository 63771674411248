import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useExchange } from '../../useExchange';
import { useContractData } from './contract';
import { useToaster } from '../../toast';
import { MutationArgs } from '../../types';

export const useESign = () => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const exchange = useExchange();
  const contract = useContractData();
  const toaster = useToaster();

  const [getESignLink, mutationState] = useMutation(
    api.getContractESignatureLink,
    {
      onError: () => toaster.error(t('toaster.getESignLink.failed')),
    },
  );

  const eSign = useCallback(
    async (mutationOptions?: MutationArgs) => {
      const response = await getESignLink({
        companyId: currentCompanyId,
        exchangeId: exchange._id,
        contractId: contract._id,
      }, mutationOptions);

      if (response.url) {
        window.location.href = response.url;
      }
    },
    [getESignLink, currentCompanyId, exchange._id, contract._id],
  );

  return useMemo(
    () => [
      eSign,
      mutationState,
    ] as const,
    [eSign, mutationState],
  );
};
