import * as React from 'react';
import { CellProps } from 'react-table';
import { Flex, Box } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Avatar } from './Avatar';

/**
 * Cell component rendering avatar, name and job title of a user
 */
export const UserCell: React.FC<CellProps<{ _id: string; name: string; jobTitle: string }>> = ({
  cell,
  row,
}) => (
  <Flex alignItems="center">
    <Box mr={2} flex="0 0 auto">
      <Avatar userId={row.original._id} />
    </Box>
    <Flex flexDirection="column" flex="1 1 auto">
      <Truncate>{cell.value}</Truncate>
      <Truncate color="subtext">
        {row.original.jobTitle}
      </Truncate>
    </Flex>
  </Flex>
);
