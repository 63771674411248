import { get, isFunction } from 'lodash';
import { EditableGridColumn } from './utils';

export const getCellValue = <TRowOriginal = unknown>(row: TRowOriginal, column: EditableGridColumn<TRowOriginal>) => {
  if (isFunction(column.accessorFn)) {
    return column.accessorFn(row);
  } else if (column.accessorKey) {
    return get(row, column.accessorKey);
  } else {
    throw new Error(`Column ${column._id} has neither a value accessor function nor a value accessor key`);
  }
};
