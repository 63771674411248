import { useCallback, useState, useMemo } from 'react';
import * as React from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { User } from '@deepstream/ui/types';
import {
  FilterSelect,
  filterMultipleValues,
} from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { TeamMembershipRequestActionButton } from '@deepstream/ui/modules/Company/TeamManagement/TeamMembershipRequestActionButton';
import { TeamMembershipDecisionModal } from '@deepstream/ui/modules/Company/TeamManagement/TeamMembershipDecisionModal';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { RightAlignedCell } from '@deepstream/ui/RightAlignedCell';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getInviteMessage } from '@deepstream/common/user-utils';
import { UserLinkCell } from '../../UserLinkCell';
import { formatAdminDate } from '../../formatDate';

type TableProps = {
  companyId: string;
  membershipRequests: User[];
};

export const CompanyMembershipRequestsTable: React.FC<TableProps> = ({
  companyId,
  membershipRequests,
}) => {
  const { t } = useTranslation();
  const { isExtraLarge } = useDeviceSize();
  const queryClient = useQueryClient();

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const teamMembershipDecisionModal = useModalState();
  const openTeamMembershipDecisionModal = useCallback(
    async (user) => {
      setSelectedUser(user);
      teamMembershipDecisionModal.open();
    },
    [teamMembershipDecisionModal, setSelectedUser],
  );

  const closeTeamMembershipDecisionModal = () => {
    teamMembershipDecisionModal.close();
    queryClient.invalidateQueries(['company', { companyId }]);
    queryClient.invalidateQueries(['membershipRequests', { companyId }]);
    queryClient.invalidateQueries(['user']);
  };

  const columns = useMemo(
    () => [
      {
        id: 'date',
        Header: 'Date',
        accessor: `requestedRoles.${companyId}.date`,
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 222 : 162,
        Filter: withProps(FilterSelect, {
          itemToString: formatAdminDate,
          transformSelectedValues: formatAdminDate,
        }),
        filter: filterMultipleValues,
      },
      {
        Header: 'User',
        accessor: 'email',
        Cell: nestCells(
          TruncateCell,
          withProps(UserLinkCell, {
            getUserId: (membershipRequests: any) => membershipRequests._id,
          }),
        ),
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Message',
        accessor: (user) => getInviteMessage(user.requestedRoles[companyId], t),
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'respondAction',
        accessor: () => null,
        Cell: nestCells(
          RightAlignedCell,
          withProps(TeamMembershipRequestActionButton, {
            openTeamMembershipDecisionModal,
          }),
        ),
        disableSortBy: true,
        width: 120,
        paddingRight: 0,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        paddingRight: 0,
      },
    ],
    [companyId, isExtraLarge, openTeamMembershipDecisionModal, t],
  );

  return (
    <>
      <BasicTableStyles>
        <Table
          isPaginated={membershipRequests.length >= 5}
          columns={columns}
          data={membershipRequests}
          isSortable
          noFilteredDataPlaceholder="No requests match chosen filters"
        />
      </BasicTableStyles>
      <TeamMembershipDecisionModal
        user={selectedUser}
        companyId={companyId}
        isOpen={teamMembershipDecisionModal.isOpen}
        close={closeTeamMembershipDecisionModal}
      />
    </>
  );
};
