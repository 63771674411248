import * as yup from 'yup';
import * as React from 'react';
import { merge } from 'lodash';
import { Form, Formik } from 'formik';
import { Flex, Text } from 'rebass/styled-components';
import { useQueryClient, useQuery } from 'react-query';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { callAll } from '@deepstream/utils/callAll';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useMutation } from '@deepstream/ui/useMutation';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { LabelConfig, LabelConfigProvider } from '@deepstream/ui/LabelConfigProvider';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';

type AddLanguageProps =
  { onCancel: any; onSave: any } &
  ModalProps;

// this will change when implementing Lokalise
const languageOptions = [
  {
    value: 'en-GB',
    label: 'English [en-GB]',
  },
  {
    value: 'fr-FR',
    label: 'French [fr-FR]',
  },
  {
    value: 'es-ES',
    label: 'Spanish [es-ES]',
  },
  {
    value: 'pt-PT',
    label: 'Portuguese [pt-PT]',
  },
  {
    value: 'zh-CN',
    label: 'Simplified Chinese [zn-CN]',
  },
];

export const AddLanguageModal: React.FC<AddLanguageProps> = ({ onCancel, onSave, style = {}, ...props }) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();
  const [addLanguage] = useMutation(
    adminApi.addLanguage,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['config']),
        () => queryClient.invalidateQueries(['languages']),
      ),
      onSuccess: callAll(
        onSave,
        () => toaster.success('Language added successfully'),
      ),
      onError: () => toaster.error('Language could not be updated'),
    },
  );
  const { data: existingLanguages } = useQuery(
    ['languages'],
    wrap(adminApi.getLanguages),
  );

  const updatedLanguageOptions = languageOptions.map(option => {
    if (existingLanguages && existingLanguages.find(language => language.code === option.value)) {
      return {
        value: option.value,
        disabled: true,
        label: (
          <Flex justifyContent="space-between" alignItems="center" mr={1} width="100%">
            <Text ml="10px" flex="0 0 auto" opacity={0.5}>
              {option.label}
            </Text>
            <Text ml="10px" flex="0 0 auto" opacity={0.5}>
              Added
            </Text>
          </Flex>
        ),
      };
    }

    return option;
  });

  const modalStyle = merge({}, style, { content: { width: 498 } });

  return (
    <Modal onRequestClose={onCancel} style={modalStyle} {...props}>
      <Formik
        initialValues={{
          code: '',
          isEnabled: false,
        }}
        validationSchema={
          yup.object().shape({
            code: yup.string().required('Required'),
            isEnabled: yup.bool(),
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await addLanguage(values, {
              onSettled: () => setSubmitting(false),
            });
          } catch (error) {
            setSubmitting(false);
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty, errors }) => (
          <LabelConfigProvider
            variant={LabelConfig.LEFT}
            width="101px"
            style={{
              language: {
                fontWeight: 500,
              },
            }}
          >
            <Form>
              <ModalHeader onClose={onCancel}>
                Add language
              </ModalHeader>
              <ModalBody>
                <Stack gap={4}>
                  <SelectField
                    required
                    name="code"
                    label="Language"
                    buttonStyle={{ width: 361, height: '40px' }}
                    items={updatedLanguageOptions}
                    placeholder="Select a language"
                  />

                  <SwitchField
                    label="Status"
                    name="isEnabled"
                    switchHeight="18px"
                    checkedText="Enabled"
                    uncheckedText="Disabled"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={42}
                    useDefaultLabelConfig={false}
                    infoTooltip="This controls whether the language can be selected only. Any existing users of the language are unaffected."
                    tooltipIcon="info-circle"
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={onCancel} />
                <Button type="submit" disabled={isSubmitting || !dirty || !!Object.keys(errors).length}>
                  Add language
                </Button>
              </ModalFooter>
            </Form>
          </LabelConfigProvider>
        )}
      </Formik>
    </Modal>
  );
};
