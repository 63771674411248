import { useMemo } from 'react';
import { differenceBy, filter } from 'lodash';
import { ActionType, ExchangeType } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from '../types';
import { useBulletins } from '../modules/RequestMessages/useBulletins';
import { useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';
import { rejectObsolete } from './rejectObsolete';

export const useAvailableBulletins = (exchange: ExchangeSnapshot) => {
  const isClarification = exchange.def.type === ExchangeType.CLARIFICATION;

  const bulletins = useBulletins({
    rfqId: useRfqId(),
    currentCompanyId: useCurrentCompanyId({ required: true }),
    enabled: isClarification,
  });

  // Bulletins that have not been referred to yet
  return useMemo(
    () => {
      if (!isClarification) {
        return [];
      }

      const actions = filter(
        rejectObsolete(exchange.history),
        { value: ActionType.REFER_TO_BULLETIN },
      );

      const referredBulletins = actions.map(action => ({ _id: action.bulletinId }));

      return differenceBy(bulletins, referredBulletins, '_id');
    },
    [exchange, bulletins, isClarification],
  );
};
