import { forwardRef } from 'react';
import * as React from 'react';
import { Flex, FlexProps, Box, BoxProps } from 'rebass/styled-components';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

export const SidebarColumn: React.FC<BoxProps> = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    width={[1, null, 5 / 12, 4 / 12]}
    pr={[null, null, 32, 32]}
    mb={[3, null, 0]}
    {...props}
  >
    <Stack gap={20}>
      {children}
    </Stack>
  </Box>
));

export const MainColumn: React.FC<BoxProps> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    width={[1, null, 7 / 12, 8 / 12]}
    {...props}
  />
));

export const Row: React.FC<FlexProps> = forwardRef((props, ref) => (
  <Flex
    ref={ref}
    width={[1, null, null, null]}
    mx="auto"
    flexWrap="wrap"
    {...props}
  />
));

export const SidebarLayout = ({ sidebar, main, sidebarStyle = {}, mainStyle = {} }) => (
  <Row mx={0}>
    <SidebarColumn sx={sidebarStyle}>
      {sidebar}
    </SidebarColumn>
    <MainColumn sx={mainStyle}>
      {main}
    </MainColumn>
  </Row>
);
