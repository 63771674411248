import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { GlobalRole } from './types';

export const orderedPermissions = [
  'admin',
  'editor',
] as const;

export const orderedRequestsPermissions = [
  'sendRFQ',
  'receiveRFQ',
] as const;

export const orderedContractsPermissions = [
  'sendContracts',
  'receiveContracts',
] as const;

export const orderedPreQualPermissions = [
  'managePreQual',
  'sendQuestionnaires',
  'receiveQuestionnaires',
] as const;

export const orderedReportingPermissions = [
  'accessReportingForRequests',
  'accessReportingForContracts',
] as const;

export const STAKEHOLDER_EDITABLE_PERMISSIONS = [
  'receiveQuestionnaires',
  'receiveRFQ',
  'receiveContracts',
];

export const FULL_USER_EDITABLE_PERMISSIONS = [
  'editor',
  'managePreQual',
  'sendQuestionnaires',
  'receiveQuestionnaires',
  'sendRFQ',
  'receiveRFQ',
  'sendContracts',
  'receiveContracts',
  'accessReportingForRequests',
  'accessReportingForContracts',
];

export const SUPER_USER_EDITABLE_PERMISSIONS = [
  'editor',
  'managePreQual',
  'sendQuestionnaires',
  'receiveQuestionnaires',
  'sendRFQ',
  'receiveRFQ',
  'sendContracts',
  'receiveContracts',
  'accessReportingForRequests',
  'accessReportingForContracts',
];

export const globalRoleToRoleRestrictionProfile = (globalRole: GlobalRole) => {
  switch (globalRole) {
    case GlobalRole.FULL_USER:
      return RoleRestrictionProfile.FULL_USER;
    case GlobalRole.AUTHORIZED_STAKEHOLDER:
      return RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
    case GlobalRole.SUPER_USER:
      return RoleRestrictionProfile.SUPER_USER;
    default:
      return assertUnreachable(globalRole);
  }
};

export const roleRestrictionProfileToGlobalRole = (roleRestrictionProfile?: RoleRestrictionProfile | null) => {
  switch (roleRestrictionProfile) {
    case null:
    case undefined:
    case RoleRestrictionProfile.FULL_USER:
      return GlobalRole.FULL_USER;
    case RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER:
      return GlobalRole.AUTHORIZED_STAKEHOLDER;
    case RoleRestrictionProfile.SUPER_USER:
      return GlobalRole.SUPER_USER;
    default:
      return assertUnreachable(roleRestrictionProfile);
  }
};
