import * as React from 'react';
import { Text } from 'rebass/styled-components';

export const EmptyTableMessage = ({ header, body }: {
  header: string | React.ReactElement;
  body: string | React.ReactElement;
}) => (
  <>
    <Text fontSize={4} fontWeight={500} mb={2}>
      {header}
    </Text>
    <Text fontSize={2} lineHeight={1.5}>
      {body}
    </Text>
  </>
);
