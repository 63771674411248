import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { UserLinkCell } from './UserLinkCell';
import { useAdminNavigation } from './AppRouting';

const TeamOwnerCell = ({ cell: { value } }: any) => (
  <>
    {value ? 'Owner' : 'Team member'}
  </>
);

type RequestUserTableProps = {
  requestId: string;
  companyId: string;
  users: any;
};

// TODO: rename to RequestCompanyUsersTable
export const RequestUsersTable = ({ requestId, companyId, users }: RequestUserTableProps) => {
  const { isLarge, isExtraLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: UserLinkCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Role',
        accessor: 'isOwner',
        Cell: TeamOwnerCell,
        width: 180,
        Filter: withProps(FilterSelect, {
          itemToString: (value) => (`${value ? 'Owner' : 'Team member'}`),
          transformSelectedValues: (value) => (`${value ? 'Owner' : 'Team member'}`),
        }),
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={users}
        onRowClick={user => navigation.navigateToRequestCompanyUser(requestId, companyId, user._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['name'] : []}
        isSortable
        noFilteredDataPlaceholder="No teams match chosen filters"
      />
    </BasicTableStyles>
  );
};
