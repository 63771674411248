import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { merge } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { QuickFindForm } from './QuickFindForm';

export const QuickFind: React.FC = () => {
  const quickFindModal = useModalState();
  const { isExtraSmall } = useDeviceSize();

  useHotkeys('command+k', quickFindModal.toggle);

  const style = React.useMemo(
    () => {
      const defaultStyle: React.CSSProperties = { width: 440, minWidth: 440 };
      const extraSmallStyle: React.CSSProperties = {
        maxWidth: '100%',
        minWidth: 'auto',
        padding: 10,
        width: 420,
      };
      return isExtraSmall ? merge(defaultStyle, extraSmallStyle) : defaultStyle;
    },
    [isExtraSmall],
  );

  return (
    <>
      <Button variant="wrapper" onClick={quickFindModal.open} style={{ cursor: 'pointer' }}>
        <Icon icon="search" aria-hidden="true" mr={2} fontSize={18} />
        {!isExtraSmall && <span>Search</span>}
      </Button>
      <Modal
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={quickFindModal.isOpen}
        onRequestClose={quickFindModal.close}
        style={{ content: style }}
        panelStyle={{ overflow: 'hidden' }}
      >
        <Box p={0} margin="-1px">
          <QuickFindForm onRedirect={quickFindModal.close} />
        </Box>
      </Modal>
    </>
  );
};
