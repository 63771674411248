import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import { Text } from 'rebass/styled-components';
import { ProgressBar } from '../../ui/ProgressBar';

export const ProgressBarCell = ({ cell }) => {
  const { t } = useTranslation('reporting');

  return (
    isNumber(cell.value) ? (
      <ProgressBar variant="info" value={Math.round(cell.value * 100)} />
    ) : (
      <Text color="subtext">
        <em>{t('noDataToShow')}</em>
      </Text>
    )
  );
};
