import * as React from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ContractOverview } from '@deepstream/common/contract';
import { CapitalizeCell } from '../../CapitalizeCell';
import { CompanyLinkCell } from '../../CompanyLinkCell';
import { ContractLinkCell } from './ContractLinkCell';
import { useAdminNavigation } from '../../AppRouting';

type RecentActivityContractsTableProps = {
  contractOverviews: ContractOverview[];
};

const getRecipientCompanyId = (contractOverview: ContractOverview) => contractOverview.recipient?._id;
const getCreatorCompanyId = (contractOverview: ContractOverview) => contractOverview.senders[0]._id;

export const AdminRecentContractsTable: React.FC<RecentActivityContractsTableProps> = ({ contractOverviews }) => {
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Contract name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, ContractLinkCell),
      },
      {
        Header: 'Recipient counterparty',
        accessor: 'recipient.name',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId: getRecipientCompanyId })),
        width: 280,
      },
      {
        Header: 'Created by',
        accessor: 'senders[0].name',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId: getCreatorCompanyId })),
        width: 280,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: CapitalizeCell,
        width: 80,
      },
      {
        id: 'createdAt',
        Header: 'Created on',
        accessor: 'meta.createdAt',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
      },
    ],
    [isExtraLarge],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={contractOverviews}
        isPaginated
        smallPageControls
        onRowClick={contract => navigation.navigateToContract(contract._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['status', 'createdAt'] : []}
      />
    </BasicTableStyles>
  );
};
