import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { RequestLinkCell } from './RequestLinkCell';
import { CompanyLinkCell } from './CompanyLinkCell';
import { useAdminNavigation } from './AppRouting';

type RecentActivityRequestsTableProps = {
  requests: any[];
};

const getCompanyId = (request: any) => request.senderIds[0];

export const RecentActivityRequestsTable = ({ requests }: RecentActivityRequestsTableProps) => {
  const { t } = useTranslation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'receivedDashboard.subject',
        Cell: nestCells(TruncateCell, RequestLinkCell),
      },
      {
        Header: 'Creator',
        accessor: 'receivedDashboard.sendersNames[0]',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
        width: 280,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => t(`request.status.${get(item, 'meta.extendedStatus')}`),
        width: 140,
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: 'sentDashboard.bidDeadline',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
      },
    ],
    [isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={requests}
        isPaginated
        smallPageControls
        onRowClick={request => navigation.navigateToRequest(request._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['status', 'deadline'] : []}
      />
    </BasicTableStyles>
  );
};
