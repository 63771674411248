import { LinkProps } from '@tanstack/react-router';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { TableLink } from './TableLink';

type CompanyLinkProps =
  Omit<LinkProps, 'to'> &
  { companyId: string };

export const CompanyLink = ({ companyId, ...props }: CompanyLinkProps) => (
  <TableLink
    to={`/company/${companyId}`}
    onClickCapture={stopPropagation}
    {...props}
  />
);
