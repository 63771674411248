import * as React from 'react';
import { Text, TextProps } from 'rebass/styled-components';

export const Bold: React.FC<TextProps> = (props) => (
  <Text
    as="span"
    fontFamily="primary"
    fontWeight={500}
    {...props}
  />
);
