import { TFunction } from 'i18next';

export const getInviteMessage = ({
  msg,
  inviteeCompanyName,
  inviterName,
  inviterCompanyName,
}: {
  msg?: string;
  inviteeCompanyName?: string;
  inviterName?: string;
  inviterCompanyName?: string;
}, t: TFunction) => {
  if (
    inviteeCompanyName &&
    inviterName &&
    inviterCompanyName
  ) {
    return t('user.inviteMessage', {
      inviteeCompanyName,
      inviterName,
      inviterCompanyName,
    });
  } else {
    return msg;
  }
};
