import { useMemo } from 'react';
import { flatMap, size } from 'lodash';
import {
  INLINE_SUB_ROW_HEIGHT,
  SECTION_PADDING_TOP,
  SECTION_ROW_HEIGHT,
  SUB_HEADER_HEIGHT,
} from './constants';
import {
  TExpandableRowDataBase,
  TOriginalColumnDataBase,
  TOriginalSubRowDataBase,
  GridData,
} from './utils';

export const useGridData = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>(
    columnData: TOriginalColumnData[],
    rowData: TOriginalRowData[],
    collapsedRowIds: string[],
    subRowHeight: number,
    defaultColumnWidth: number,
    contentAreaSubcolumnData?: TOriginalColumnData[],
    frozenLeftSubcolumnData?: TOriginalColumnData[],
    subcolumnWidthsMap?: Record<string, number>,
  ): GridData<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData> => {
  const columns = useMemo(() => {
    let left = 0;

    return columnData.map((item, index, arr) => {
      const width = item.width ?? defaultColumnWidth;

      const column = {
        index,
        original: item,
        isLast: index === size(arr) - 1,
        width,
        left,
      };

      left += width;

      return column;
    });
  }, [columnData, defaultColumnWidth]);

  const contentAreaSubcolumns = useMemo(() => {
    if (!contentAreaSubcolumnData?.length) {
      return [];
    }

    let left = 0;

    return contentAreaSubcolumnData.map((item, index, arr) => {
      const width = subcolumnWidthsMap?.[item._id] ?? defaultColumnWidth;

      const subcolumn = {
        index,
        original: item,
        isLast: index === size(arr) - 1,
        width,
        left,
      };

      left += width;

      return subcolumn;
    });
  }, [contentAreaSubcolumnData, defaultColumnWidth, subcolumnWidthsMap]);

  const frozenLeftSubcolumns = useMemo(() => {
    if (!frozenLeftSubcolumnData?.length) {
      return [];
    }

    let left = 0;

    return frozenLeftSubcolumnData.map((item, index, arr) => {
      const width = subcolumnWidthsMap?.[item._id] ?? defaultColumnWidth;

      const subcolumn = {
        index,
        original: item,
        isLast: index === size(arr) - 1,
        width,
        left,
      };

      left += width;

      return subcolumn;
    });
  }, [frozenLeftSubcolumnData, subcolumnWidthsMap, defaultColumnWidth]);

  const rows: any[] = useMemo(() => {
    let top = 0;

    return [
      null,
      ...flatMap(
        rowData,
        (item, index) => {
          if (!item.subRows) {
            const height = subRowHeight;

            const row = {
              original: item as unknown as TOriginalSubRowData,
              height,
              top,
              isLast: index === size(rowData) - 1,
            };

            top += height;

            return row;
          }

          const isExpanded = !collapsedRowIds.includes(item._id);

          const height = item.inlineSubRowCount
            ? SECTION_PADDING_TOP + (INLINE_SUB_ROW_HEIGHT * item.inlineSubRowCount)
            : SECTION_PADDING_TOP + SECTION_ROW_HEIGHT;

          const row = {
            original: item,
            height,
            top,
            isExpanded,
          };

          top += height;

          if (!isExpanded) {
            return row;
          } else {
            return [
              row,
              ...item.subRows.map((item, index, arr) => {
                const height = item.isSubHeader ? SUB_HEADER_HEIGHT : subRowHeight;

                const row = {
                  original: item,
                  height,
                  top,
                  isLast: index === size(arr) - 1,
                };

                top += height;

                return row;
              }),
            ];
          }
        },
      ).map((row, index) => row ? { ...row, index } : row),
    ];
  }, [collapsedRowIds, rowData, subRowHeight]);

  return useMemo(() => ({
    columns,
    contentAreaSubcolumns,
    frozenLeftSubcolumns,
    rows,
  }), [columns, frozenLeftSubcolumns, contentAreaSubcolumns, rows]);
};
