import { useMemo } from 'react';

import { AdoptionRateTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { withProps } from '@deepstream/ui-utils/withProps';

import { YesNoCell } from '../YesNoCell';

export const ExcelClientAdoptionRateTable = ({
  companies,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'company.name',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { hasLink: false, minHeight: '40px' }),
      },
      {
        Header: 'Adopted?',
        accessor: 'hasAdoptedFeature',
        sortType: 'boolean',
        Cell: withProps(YesNoCell, { yesIcon: 'check-circle', noIcon: 'times-circle', isIconRegular: true, color: 'text' }),
      },
    ],
    [],
  );

  return (
    <AdoptionRateTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      maxHeight="356px"
      customRowHeight="40px"
    >
      <Table
        columns={columns}
        data={companies}
        isSortable
        initialSortBy={[{ id: 'date', desc: true }]}
      />
    </AdoptionRateTableStyle>
  );
};
