import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useContractsReportingData } from './useContractsReporting';
import { StaticTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { CurrencyAmountCell } from '../../ui/Currency';
import { VolumeValueFieldToggle } from './VolumeValueFieldToggle';
import { UserCell } from '../../UserCell';

export const UserVolumeTable = () => {
  const { t } = useTranslation('reporting');
  const { data: { usersStats } } = useContractsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('contracts.teamMember'),
        accessor: 'name',
        Cell: UserCell,
      },
      {
        id: 'numNegotiatingContracts',
        Header: t('contracts.negotiating'),
        accessor: 'numNegotiatingContracts',
      },
      {
        id: 'numAgreedContracts',
        Header: t('contracts.agreed'),
        accessor: 'numAgreedContracts',
      },
      {
        id: 'numActiveContracts',
        Header: t('contracts.active'),
        accessor: 'numActiveContracts',
      },
      {
        id: 'numTotalContracts',
        Header: t('contracts.total'),
        accessor: 'numTotalContracts',
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          isPaginated={usersStats.length > 5}
          smallPageControls
          columns={columns}
          data={usersStats}
        />
      </StaticTableStyles>
    </Panel>
  );
};

export const UserValueTable = () => {
  const { t } = useTranslation('reporting');
  const { data: { usersStats } } = useContractsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('contracts.teamMember'),
        accessor: 'name',
        Cell: UserCell,
      },
      {
        id: 'negotiatingContractsValue',
        Header: t('contracts.negotiating'),
        accessor: 'negotiatingContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'agreedContractsValue',
        Header: t('contracts.agreed'),
        accessor: 'agreedContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'activeContractsValue',
        Header: t('contracts.active'),
        accessor: 'activeContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'totalContractsValue',
        Header: t('contracts.total'),
        accessor: 'totalContractsValue',
        Cell: CurrencyAmountCell,
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          isPaginated={usersStats.length > 5}
          smallPageControls
          columns={columns}
          data={usersStats}
        />
      </StaticTableStyles>
    </Panel>
  );
};

export const UserStats = () => {
  const [displayField, setDisplayField] = useState<'volume' | 'value'>('volume');

  return (
    <Stack gap={3}>
      <VolumeValueFieldToggle displayField={displayField} setDisplayField={setDisplayField} />
      {displayField === 'volume' ? (
        <UserVolumeTable />
      ) : (
        <UserValueTable />
      )}
    </Stack>
  );
};
