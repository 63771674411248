import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { TextField } from '@deepstream/ui/form/TextField';
import { useMutation } from '@deepstream/ui/useMutation';

type EditJobRoleModalProps =
  { user: any; companyId: string; onCancel: any; onSave: any } &
  ModalProps;

export const EditJobRoleModal = ({
  user,
  companyId,
  onCancel,
  onSave,
  ...props
}: EditJobRoleModalProps) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateJobTitle] = useMutation(
    adminApi.updateJobTitle,
    {
      onSuccess: () => toaster.success('Job role updated successfully'),
      onError: () => toaster.error('An error occurred when trying to update the job role'),
      onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          jobTitle: user.jobTitles ? user.jobTitles[companyId] : '',
        }}
        validationSchema={
          yup.object().shape({
            jobTitle: yup.string(),
          })
        }
        onSubmit={({ jobTitle }) =>
          updateJobTitle({
            userId: user._id,
            companyId,
            jobTitle,
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Edit job role</ModalHeader>
            <ModalBody>
              <TextField name="jobTitle" label="Job role" />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
