import { get, isFinite, isNaN } from 'lodash';
import * as React from 'react';
import { CellProps } from 'react-table';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { CurrencyAmount, CurrencyCodeProvider } from './Currency';

export const CurrencyAmountCell = ({ cell: { value } }: CellProps<any>) =>
  !isNaN(value) ? (
    <CurrencyAmount value={value ?? NaN} />
  ) : (
    <EmDash />
  );

export const CurrencyAmountCellWithProvider = ({
  row: { original: exchange },
  cell: { value },
  column: { EmptyCell = EmDash as React.FC<any>, showTooltip, textAlign },
  decimalPlacesAccessor,
}: CellProps<any> & { column: { EmptyCell: React.FC<any>, showTooltip, textAlign } }) => {
  const decimalPlaces = decimalPlacesAccessor ? get(exchange, decimalPlacesAccessor) : undefined;

  if (isFinite(value)) {
    return (
      <Truncate sx={{ width: '100%', textAlign }}>
        <CurrencyCodeProvider code={exchange.currency}>
          <CurrencyAmount
            value={value ?? NaN}
            decimalPlaces={decimalPlaces}
            showTitle={showTooltip}
          />
        </CurrencyCodeProvider>
      </Truncate>
    );
  } else {
    return (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    );
  }
};
