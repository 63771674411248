import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Table } from '../../Table';
import { StaticTableStyles } from '../../TableStyles';
import { Bold } from '../../Bold';

export const CompanyCell = ({ cell }: CellProps<any>) => {
  return (
    <Bold>{cell.value}</Bold>
  );
};

export const WebsiteCell = ({ cell }: CellProps<any>) => {
  return cell.value ? (
    <a href={cell.value} target="_blank" rel="noopener noreferrer">
      {cell.value}
      <Icon icon="external-link" ml={2} />
    </a>
  ) : (
    <EmDash />
  );
};

export const ScoreCell = ({ cell }: CellProps<any>) => {
  const { t } = useTranslation('supplierDiscovery');
  const theme = useTheme();

  const score = cell.value;

  if (score && score >= 1) {
    return (
      <Tooltip content={t('results.table.score.cellTooltip.higherQuality')} placement="right-end">
        <Icon ml={1} fontSize={16} color={theme.colors.success} icon="circle-check" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={t('results.table.score.cellTooltip.lowerQuality')} placement="right-end">
        <Icon ml={1} fontSize={16} color={theme.colors.warning} icon="question-circle" />
      </Tooltip>
    );
  }
};

export const LocationHeaderWithTooltip = () => {
  const { t } = useTranslation('supplierDiscovery');

  return (
    <Flex display="inline-block" flexDirection="row">
      <Flex alignItems="center">
        <Bold>{t('results.table.location.header')}</Bold>
      </Flex>
      <Flex alignItems="center">
        <Tooltip
          content={t('results.table.location.headerTooltip')}
          placement="right-end"
        >
          <Icon icon="info-circle" ml={1} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export const ScoreHeaderWithTooltip = () => {
  const { t } = useTranslation('supplierDiscovery');

  return (
    <Flex flexDirection="row">
      <Flex alignItems="center">
        <Tooltip
          content={t('results.table.score.headerTooltip')}
          placement="right-end"
        >
          <Icon icon="info-circle" ml={1} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

type CompanyRow = {
  metadata: {
    websiteEvaluation?: boolean;
    validationScore: any;
  };
  company: string;
  location: string;
  website: string | null;
  info: string;
};

export const DiscoverySearchResultsTable = (props: { data: CompanyRow[]; }) => {
  const { t } = useTranslation(['supplierDiscovery', 'general']);

  const columns = useMemo(
    () => [
      {
        id: 'company',
        Header: t('company', { ns: 'general' }),
        accessor: 'company',
        Cell: CompanyCell,
        width: 210,
      },
      {
        id: 'location',
        Header: LocationHeaderWithTooltip,
        accessor: 'location',
        width: 220,
        disableSortBy: true,
      },
      {
        id: 'website',
        Header: t('results.table.website.header'),
        accessor: 'website',
        Cell: WebsiteCell,
        width: 310,
      },
      {
        id: 'info',
        Header: t('results.table.info.header'),
        accessor: 'info',
        width: 340,
      },
      {
        id: 'validationScore',
        Header: ScoreHeaderWithTooltip,
        accessor: 'metadata.validationScore',
        Cell: ScoreCell,
        disableSortBy: true,
      },
    ],
    [t],
  );

  return (
    <StaticTableStyles>
      <Table
        isSortable
        columns={columns}
        data={props.data}
      />
    </StaticTableStyles>
  );
};
