import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { LinkButton } from '@deepstream/ui-kit/elements/button/LinkButton';

type ToggleButtonProps = ButtonProps & {
  tagId: string;
  areAllChildrenSelected: (tagId: string) => boolean;
  selectAll: (tagId: string) => void;
  deselectAll: (tagId: string) => void;
};

export const ToggleAllButton: React.FC<ToggleButtonProps> = ({
  tagId,
  areAllChildrenSelected,
  selectAll,
  deselectAll,
  backgroundColor = 'transparent',
  fontSize = '14px',
  ...props
}) => {
  const { t } = useTranslation('general');

  return (
    <LinkButton
      onClick={() => areAllChildrenSelected(tagId)
        ? deselectAll(tagId)
        : selectAll(tagId)
      }
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      {...props}
    >
      {areAllChildrenSelected(tagId) ? t('deselectAll') : t('selectAll')}
    </LinkButton>
  );
};
