import { useField } from 'formik';
import { useCurrencySelectItems } from '../ui/currencies';
import { SelectFieldBase, SelectFieldBaseProps, getItemValue } from './SelectField';

type CurrencySelectFieldBaseProps = Omit<SelectFieldBaseProps, 'items'>;

export const CurrencySelectFieldBase = ({
  menuWidth = 300,
  ...props
}: CurrencySelectFieldBaseProps) => {
  const currencySelectItems = useCurrencySelectItems();

  return (
    <SelectFieldBase
      menuWidth={menuWidth}
      items={currencySelectItems}
      getButtonLabel={getItemValue}
      {...props}
    />
  );
};

export type CurrencySelectFieldProps = CurrencySelectFieldBaseProps & {
  name?: string;
  fieldName?: string;
  onChange?: any;
};

export const CurrencySelectField = ({
  name,
  fieldName,
  ...props
}: CurrencySelectFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });

  return (
    <CurrencySelectFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={value => {
        formik.setValue(value);
      }}
      value={field.value}
      {...props}
    />
  );
};
