import * as React from 'react';
import { CellProps } from 'react-table';
import { User } from '@deepstream/common/rfq-utils';
import { RespondButton } from '@deepstream/ui-kit/elements/button/Button';

type TeamMembershipRequestActionButtonProps = CellProps<any> & {
  openTeamMembershipDecisionModal: (user: User) => void;
};

export const TeamMembershipRequestActionButton: React.FC<TeamMembershipRequestActionButtonProps> = ({
  row,
  openTeamMembershipDecisionModal,
}) => {
  const user = row.original;

  return (
    <RespondButton small onClick={() => openTeamMembershipDecisionModal(user)} />
  );
};
