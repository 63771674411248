import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { contractRoute } from '../../AppRouting';
import { AdminTableLink } from '../../AdminTableLink';

export const ContractLink = ({
  contractId,
  style,
  children,
}: {
  contractId: string;
  style?: React.CSSProperties,
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={contractRoute.to}
    params={{ contractId }}
    onClick={stopPropagation}
    style={style}
  >
    {children}
  </AdminTableLink>
);
