/* eslint-disable react-hooks/exhaustive-deps */

export type QueryStatus = 'idle' | 'loading' | 'error' | 'success';

const isError = (status: string) => status === 'error';
const isLoading = (status: string) => status === 'loading';
const isIdle = (status: string) => status === 'idle';

export const useCombinedQueryStatus = (...statuses: QueryStatus[]): QueryStatus => {
  // separate loops to make sure more important states aren't covered by less important ones
  if (statuses.some(isError)) return 'error';
  if (statuses.some(isLoading)) return 'loading';
  if (statuses.some(isIdle)) return 'idle';

  return 'success';
};
