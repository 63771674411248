import * as React from 'react';
import { Text, Box, BoxProps, TextProps } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useCollapsibleContent } from './useCollapsibleContent';

type DisclosureProps = BoxProps & {
  initialIsOpen?: boolean;
  summary: string;
  width?: BoxProps['width'];
  maxWidth?: BoxProps['maxWidth'];
  lineHeight?: TextProps['lineHeight'];
  buttonProps?: ButtonProps;
};

export const Disclosure: React.FC<DisclosureProps> = ({
  initialIsOpen = false,
  summary,
  width = '400px',
  maxWidth = '100%',
  lineHeight = '1.5',
  children,
  ...props
}) => {
  const { isOpen, getButtonProps, getPanelProps } = useCollapsibleContent(initialIsOpen);

  return (
    <Box width={width} maxWidth={maxWidth} {...props}>
      <Box mb={isOpen ? 2 : 0}>
        <WrapperButton {...getButtonProps()}>
          <Text fontWeight={500} fontSize={2} color="text" textAlign="left">
            {summary}
            <Icon fixedWidth icon={isOpen ? 'chevron-down' : 'chevron-right'} />
          </Text>
        </WrapperButton>
      </Box>
      <Box>
        <Text fontSize={2} {...getPanelProps()} lineHeight={lineHeight}>
          {children}
        </Text>
      </Box>
    </Box>
  );
};

export const Disclosure2: React.FC<DisclosureProps> = ({
  initialIsOpen = false,
  summary,
  width = '400px',
  maxWidth = '100%',
  lineHeight = '1.5',
  buttonProps = {},
  children,
  ...props
}) => {
  const { isOpen, getButtonProps, getPanelProps } = useCollapsibleContent(initialIsOpen);
  const theme = useTheme();

  return (
    <Box width={width} maxWidth={maxWidth} {...props}>
      <Box mb={isOpen ? 2 : 0}>
        <WrapperButton {...getButtonProps(buttonProps)}>
          <Box as="span" sx={{ display: 'inline-block' }}>
            <Icon width="10px" icon={isOpen ? 'caret-down' : 'caret-right'} mr={1} color="primary" />
            <Text as="span" fontWeight={500} fontSize={2} textAlign="left" color="primary">
              {summary}
            </Text>
          </Box>
        </WrapperButton>
      </Box>
      <Box>
        <Text
          fontSize={2}
          pl="11px"
          color="lightNavy"
          sx={{ borderLeft: `3px solid ${theme.colors.lightGray2}` }}
          {...getPanelProps()}
          lineHeight={lineHeight}
        >
          {children}
        </Text>
      </Box>
    </Box>
  );
};
