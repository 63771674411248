import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { isEmpty } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTimeout } from '@deepstream/ui-kit/hooks/useTimeout';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ErrorMessage } from './ErrorMessage';

type SpinnerProps = Omit<IconProps, 'icon'>;

export const Spinner = (props: SpinnerProps) => (
  <Icon icon="spinner" spin {...props} />
);

interface LoadingProps extends Omit<IconProps, 'icon'> {
  loadingText?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  fontSize,
  fontWeight,
  lineHeight,
  loadingText,
  color = 'gray',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center">
      <Text color={color} fontSize={fontSize} fontWeight={fontWeight} lineHeight={lineHeight}>
        <Icon icon="spinner" spin fontSize={4} mr={2} {...props} />
        {loadingText || t('loading', { ns: 'general' })}
      </Text>
    </Flex>
  );
};

/**
 * Returns a boolean flag that is toggled based on the `delay`
 */
const useVisibilityTimer = (delay) => {
  const [isVisible, setVisibility] = React.useState(false);

  useTimeout(
    React.useCallback(() => setVisibility(true), [setVisibility]),
    delay,
  );

  return isVisible;
};

type LoadingPanelProps = {
  heading?: string;
  delay?: number;
  style?: any;
  loadingText?: string;
};

/**
 * The `delay` prop delays the visibility of the component. Useful for
 * avoiding component flashing for fast HTTP responses.
 */
export const LoadingPanel = ({ heading, delay = 400, style, loadingText }: LoadingPanelProps) => {
  const isVisible = useVisibilityTimer(delay);

  if (!isVisible) {
    return null;
  }

  return (
    <Panel heading={heading} padded style={style}>
      <Loading loadingText={loadingText} />
    </Panel>
  );
};

export const DelayedSpinner = ({ delay = 250, ...props }: SpinnerProps & { delay?: number }) => {
  const isVisible = useVisibilityTimer(delay);

  if (!isVisible) {
    return null;
  }

  return <Spinner {...props} />;
};

export const PanelLoadingWrapper: React.FC<{
  status: string;
  data: any;
  emptyDataMessage?: string;
  errorMessage?: string;
  children?: React.ReactNode;
}> = ({ status, data, emptyDataMessage, errorMessage, children }) =>
  status === 'loading' ? (
    <PanelPadding>
      <Loading fontSize={2} lineHeight={1.5} />
    </PanelPadding>
  ) : status === 'error' ? (
    <PanelPadding>
      <ErrorMessage
        error={errorMessage || 'Oh no'}
        fontSize={2}
        lineHeight={1.5}
      />
    </PanelPadding>
  ) : !isEmpty(data) ? (
    // This is required to avoid the following runtime error:
    // Objects are not valid as a React child (found: objec with keys {children}).
    // If you meant to render a collection of children, use an array instead.
    <>{children}</>
  ) : emptyDataMessage ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2} lineHeight={1.5}>
        {emptyDataMessage}
      </Text>
    </PanelPadding>
  ) : null;
