/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import * as React from 'react';
import clsx from 'clsx';
import { Box, Flex } from 'rebass/styled-components';
import { Icon } from '../../elements/icon/Icon';
import {
  TExpandableRowDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  DataCellProps,
  GridDataAndCommands,
} from '../core/utils';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from './utils';

const RenderTableDataCellBase = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  rowIndex,
  columnIndex,
  style,
}: {
  data: GridDataAndCommands<EditableGridColumn, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<
      EditableGridColumn,
      TOriginalSubRowData,
      TOriginalRowData
    >>;
    bodyPaddingLeft: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, activeCellIndices, selectedRange, onRowClick } = data;

  if (rowIndex === 0 || !row) {
    return null;
  }

  const isActive = Boolean(
    activeCellIndices &&
    activeCellIndices.rowIndex === rowIndex &&
    activeCellIndices.columnIndex === columnIndex,
  );

  const isSelected = Boolean(
    selectedRange &&
    rowIndex >= selectedRange.start.rowIndex &&
    rowIndex < selectedRange.end.rowIndex &&
    columnIndex >= selectedRange.start.columnIndex &&
    columnIndex < selectedRange.end.columnIndex,
  );

  const { toggleMenu } = column.original;

  return toggleMenu ? (
    <Flex sx={{ width: '100%', height: '100%' }}>
      {toggleMenu && (
        <div
          id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
          aria-rowindex={rowIndex + 1}
          aria-colindex={columnIndex + 1}
          aria-haspopup="true"
          role="gridcell"
          className={clsx(
            isActive && 'active',
            isSelected && 'selected',
          )}
          style={{
            height: '100%',
            width: `${ACTION_COLUMN_WIDTH + 2}px`,
          }}
          onClick={(event) => {
            data.activateCellAndEnsureVisibility({ rowIndex, columnIndex }, null, event);
            toggleMenu(rowIndex, columnIndex);
          }}
        >
          <div className="column-action">
            <Icon
              icon="ellipsis-h"
              color="text"
              fixedWidth
              fontSize={2}
              sx={{ top: '2px' }}
            />
          </div>
        </div>
      )}
      <Box
        role="gridcell"
        style={{
          width: `${ACTION_COLUMN_WIDTH}px`,
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <DataCell
          row={row}
          column={column}
          isActive={isActive}
          editedCell={data.editedCell}
        />
      </Box>
    </Flex>
  ) : (
    <div
      id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
      aria-rowindex={rowIndex + 1}
      aria-colindex={columnIndex + 1}
      role="gridcell"
      className={clsx(
        isActive && 'active',
        isSelected && 'selected',
      )}
      style={{
        height: '100%',
        position: 'relative',
        cursor: onRowClick ? 'pointer' : undefined,
      }}
      onClick={(event) => {
        if (onRowClick) {
          onRowClick?.(row.original);
        } else {
          data.activateCellAndEnsureVisibility({ rowIndex, columnIndex }, null, event);
        }
      }}
    >
      <DataCell
        row={row}
        column={column}
        isActive={isActive}
        editedCell={data.editedCell}
      />
    </div>
  );
};

export const RenderTableDataCell = React.memo(
  RenderTableDataCellBase,
) as typeof RenderTableDataCellBase;
