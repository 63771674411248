import { useRef, useMemo, useState } from 'react';
import { useOpenState } from '@deepstream/ui-kit/hooks/useOpenState';
import { DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';

export const useModalState = useOpenState;

export const useConfirmDialog = () => {
  const modal = useModalState();
  const funcRef = useRef<(...args: any) => void>();

  return useMemo(
    () => ({
      confirm: (func) => {
        funcRef.current = func;
        modal.open();
      },
      onOk: async (...args) => {
        if (funcRef.current) {
          await funcRef.current(...args);
        }

        modal.close();
      },
      onCancel: modal.close,
      isOpen: modal.isOpen,
    }),
    [modal],
  );
};

interface DialogConfig<TAdditionalProps extends Record<string, unknown>>{
  action: (payload?: any) => Promise<void> | void;
  props: Omit<DialogProps, 'onOk' | 'onCancel' | 'isOpen'> & TAdditionalProps;
}

export const useConfirmDialogWithConfig = <TAdditionalProps extends Record<string, unknown>>() => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig<TAdditionalProps> | null>(null);

  return useMemo(
    () => ({
      configureDialog: setDialogConfig,
      onOk: async (payload) => {
        if (dialogConfig) {
          await dialogConfig.action(payload);
        }

        setDialogConfig(null);
      },
      onCancel: () => setDialogConfig(null),
      isOpen: !!dialogConfig,
      ...dialogConfig?.props,
    }),
    [dialogConfig],
  );
};
