import { useMemo } from 'react';
import { compact, groupBy, isEmpty, isFinite } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestsSavingsCardsData, getCardsDateKey } from '@deepstream/common/reporting';
import { Flex } from 'rebass/styled-components';
import { ScatterChart } from '@deepstream/ui-kit/charts/ScatterChart';
import { useGetScatterChartTooltipConfig } from './useGetScatterChartTooltipConfig';
import { confirmStatusColorById } from './colors';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const useSavingsScatterChartData = (data: Pick<RequestsSavingsCardsData, 'config' | 'requests'>) => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    const dateKey = getCardsDateKey(data.config.countDate);
    const filteredItems = data.requests.filter(item => isFinite(item.savings));

    const groupedItems = groupBy(filteredItems, item => item.areSavingsConfirmed ? 'confirmed' : 'unconfirmed');

    const datasets = compact(
      [
        'confirmed',
        'unconfirmed',
      ].map(id => {
        const items = groupedItems[id];

        if (!items) {
          return null;
        }

        const color = confirmStatusColorById[id];

        return {
          // hack: add spaces to get more x padding between items while
          // keeping y padding sufficiently low
          label: id === 'confirmed'
            ? t('request.spendAndSavings.savingsConfirmed')
            : t('request.spendAndSavings.savingsUnconfirmed'),
          data: items,
          borderWidth: 2,
          borderColor: color,
          backgroundColor: color,
          pointHoverRadius: 6,
          parsing: {
            xAxisKey: dateKey,
            yAxisKey: 'savings',
          },
        };
      }),
    );

    return {
      datasets,
    };
  }, [data, t]);
};

export const SavingsScatterChart = ({ data }: { data: Pick<RequestsSavingsCardsData, 'config' | 'requests'> }) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const locale = useCurrentUserLocale();
  const chartData = useSavingsScatterChartData(data);
  const getTooltipConfig = useGetScatterChartTooltipConfig(data, 'savings');

  return isEmpty(chartData.datasets) ? (
    <Flex width="100%" height="100%" justifyContent="center" pt="20px">
      {t('noData', { ns: 'reporting' })}
    </Flex>
  ) : (
    <ScatterChart
      description={t(`request.spendAndSavings.savingsPer.${data.config.interval}`)}
      startDate={data.config.startDate}
      endDate={data.config.endDate}
      interval={data.config.interval}
      data={chartData as any}
      locale={locale}
      getTooltipConfig={getTooltipConfig}
    />
  );
};
