import { PageType, PageRole } from '@deepstream/common/rfq-utils';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { useFormikContext } from 'formik';
import { map, find } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Flex, Box } from 'rebass/styled-components';
import { REMINDERS_PAGE_ID, SUMMARY_PAGE_ID } from '@deepstream/common/contract';

import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import {
  getItemLabelWithIcon,
  getItemLabelWithIconAndDescription,
  SelectField,
} from '../../../form/SelectField';
import { Table } from '../../../Table';
import { PagePermissionTableStyles } from '../../../TableStyles';
import { RequestRole } from '../../../types';
import { Label } from '../../../ui/Label';
import { useContractData, useGeneralPages } from '../contract';

enum PermissionsPageType {
  SUMMARY = 'summary',
  REMINDERS = 'reminders',
  GENERIC = 'generic',
}

const pageRoleConfig = {
  [PermissionsPageType.GENERIC]: {
    labelKeyPrefix: 'teamManagement.pageRole',
    descriptionKeyPrefix: 'teamManagement.genericPageRoleDescription',
    items: [
      { pageRole: PageRole.NONE, icon: 'times' },
      { pageRole: PageRole.READER, icon: 'eye' },
      { pageRole: PageRole.COMMENTER, icon: 'comment-o' },
      { pageRole: PageRole.RESPONDER, icon: 'reply' },
      { pageRole: PageRole.EDITOR, icon: 'pencil' },
    ],
  },
  [PermissionsPageType.SUMMARY]: {
    labelKeyPrefix: 'teamManagement.pageRole',
    descriptionKeyPrefix: 'teamManagement.summaryPageRoleDescription',
    items: [
      { pageRole: PageRole.READER, icon: 'eye' },
      { pageRole: PageRole.EDITOR, icon: 'pencil' },
    ],
  },
  [PermissionsPageType.REMINDERS]: {
    labelKeyPrefix: 'teamManagement.pageRole',
    descriptionKeyPrefix: 'teamManagement.remindersPageRoleDescription',
    items: [
      { pageRole: PageRole.NONE, icon: 'times' },
      { pageRole: PageRole.READER, icon: 'eye' },
      { pageRole: PageRole.EDITOR, icon: 'pencil' },
    ],
  },
};

const PagePermissionSelectField = ({
  name,
  buttonStyle = {},
  permissionsPageType = PermissionsPageType.GENERIC,
}: {
  name: string;
  buttonStyle?: React.CSSProperties;
  permissionsPageType?: PermissionsPageType;
}) => {
  const { t } = useTranslation();
  const { items, labelKeyPrefix, descriptionKeyPrefix } = pageRoleConfig[permissionsPageType];
  const { values } = useFormikContext<any>();

  const options = useMemo(() => {
    const selectedUser = values?.selectedUser;
    const isAuthorizedStakeholder = selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;

    return map(items, (item) => ({
      value: item.pageRole,
      icon: item.icon,
      description: t(`${descriptionKeyPrefix}.${item.pageRole}`),
      label: t(`${labelKeyPrefix}.${item.pageRole}`),
      disabled: isAuthorizedStakeholder && item.pageRole === PageRole.EDITOR,
    }));
  }, [values?.selectedUser, items, t, descriptionKeyPrefix, labelKeyPrefix]);

  const isDisabled = !values.selectedUser;

  return (
    <SelectField
      name={name}
      items={options}
      buttonStyle={{
        borderRadius: 0,
        height: '40px',
        borderColor: 'transparent',
        pointerEvents: isDisabled ? 'none' : 'auto',
        opacity: isDisabled ? 0.5 : 1,
        ...buttonStyle,
      }}
      getButtonLabel={getItemLabelWithIcon}
      getItemLabel={getItemLabelWithIconAndDescription}
      placement="bottom-end"
      menuWidth={220}
    />
  );
};

export const ContractTeamAddUserPagesPermissions = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation('contracts');
  const { pages, senders } = useContractData();
  const { values } = useFormikContext<{ role: RequestRole; }>();
  const generalPages = useGeneralPages();
  const isSender = !!senders.find(sender => sender._id === companyId);

  const contractPage = find(pages, (page) => page.type === PageType.CONTRACT)!;

  const pagePermissionColumns = useMemo(() => {
    return [{
      id: 'pageName',
      Header: t('team.pagePermissionsTable.pageName'),
      accessor: 'name',
    }, {
      id: 'pagePermissions',
      Header: t('team.pagePermissionsTable.pagePermission'),
      width: 180,
      Cell: ({ cell }) => <PagePermissionSelectField name={`pagesPermissions[${cell.row.original._id}]`} />,
    }];
  }, [t]);

  return (
    <>
      <Label label={t('team.pagePermissionsTable.heading')} color="lightNavy" />
      {values.role === RequestRole.OWNER ? (
        <MessageBlock variant="info" mt={0}>
          <Text>{t('team.pagePermissionsTable.ownerPermissions')}</Text>
        </MessageBlock>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center" sx={{ borderBottom: 'lightGray', mb: 2, pb: 3 }}>
            <Text sx={{ fontWeight: 500 }}>
              {t('team.pagePermissionsTable.summaryPageLabel')}
            </Text>
            <PagePermissionSelectField
              name={`pagesPermissions.${SUMMARY_PAGE_ID}`}
              buttonStyle={{
                borderColor: 'lightGray2',
                borderWidth: 1,
                borderRadius: 'small',
                width: '180px',
              }}
              permissionsPageType={PermissionsPageType.SUMMARY}
            />
          </Flex>
          <Box>
            <Text sx={{ mb: 2, fontWeight: 500 }}>
              {t('team.pagePermissionsTable.detailsTableHeading')}
            </Text>
            <Panel maxWidth="600px">
              <PagePermissionTableStyles>
                <Table
                  columns={pagePermissionColumns}
                  data={generalPages}
                />
              </PagePermissionTableStyles>
            </Panel>
          </Box>
          <Flex justifyContent="space-between" alignItems="center" sx={{ borderTop: 'lightGray', mt: 2, pt: 3 }}>
            <Text sx={{ fontWeight: 500 }}>
              {t('team.pagePermissionsTable.contractPageLabel')}
            </Text>
            <PagePermissionSelectField
              name={`pagesPermissions[${contractPage._id}]`}
              buttonStyle={{
                borderColor: 'lightGray2',
                borderWidth: 1,
                borderRadius: 'small',
                width: '180px',
              }}
            />
          </Flex>
          {isSender && (
            <Flex justifyContent="space-between" alignItems="center" sx={{ borderBottom: 'lightGray', mb: 2, pb: 3 }}>
              <Text sx={{ fontWeight: 500 }}>
                {t('team.pagePermissionsTable.remindersPageLabel')}
              </Text>
              <PagePermissionSelectField
                name={`pagesPermissions.${REMINDERS_PAGE_ID}`}
                buttonStyle={{
                  borderColor: 'lightGray2',
                  borderWidth: 1,
                  borderRadius: 'small',
                  width: '180px',
                }}
                permissionsPageType={PermissionsPageType.REMINDERS}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};
