import { ApproximateTotal, Asset, Attachment, ExchangeDefinition, HirePeriod, Page, PageRole, Section, Sender, Stage, CompanyId, SectionId, ExchangeId, StageId, PageId, RequestUser, Draft, LotId, Lot } from './types';

type Entity = { _id: string };

/**
 * All keys are optional except for the `_id`
 */
export type Patch<TEntity extends Entity> =
  { _id: TEntity['_id'] } &
  Partial<Omit<TEntity, '_id'>>;

export enum ChangeType {
  EXCHANGE_ADDED = 'exchange-added',
  EXCHANGE_REMOVED = 'exchange-removed',
  EXCHANGE_UPDATED = 'exchange-updated',
  EXCHANGES_REORDERED = 'exchanges-reordered',
  HIRE_PERIOD_ADDED = 'hire-period-added',
  HIRE_PERIOD_REMOVED = 'hire-period-removed',
  HIRE_PERIOD_UPDATED = 'hire-period-updated',
  HIRE_PERIOD_ORDER_UPDATED = 'hire-period-order-updated',
  FIELD_UPDATED = 'field-updated',
  STAGE_ADDED = 'stage-added',
  STAGE_REMOVED = 'stage-removed',
  STAGE_UPDATED = 'stage-updated',
  STAGES_REORDERED = 'stages-reordered',
  PAGE_ADDED = 'page-added',
  PAGE_REMOVED = 'page-removed',
  PAGE_UPDATED = 'page-updated',
  PAGES_REORDERED = 'pages-reordered',
  SECTION_ADDED = 'section-added',
  SECTION_REMOVED = 'section-removed',
  SECTION_UPDATED = 'section-updated',
  SENDER_ADDED = 'sender-added',
  SENDER_UPDATED = 'sender-updated',
  SENDER_REMOVED = 'sender-removed',
  TEAM_MEMBER_ADDED = 'team-member-added',
  TEAM_MEMBER_REMOVED = 'team-member-removed',
  TEAM_MEMBER_ROLES_UPDATED = 'team-member-roles-updated',
  REQUEST_OWNER_UPDATED = 'request-owner-updated',
  COMPANY_MEMBERSHIP_REMOVED = 'company-membership-removed',
  PAGE_APPROVER_UPDATED = 'page-approver-updated',
  ASSET_ADDED = 'asset-added',
  ASSET_REMOVED = 'asset-removed',
  ASSET_FIELDS_UPDATED = 'asset-fields-updated',
  TEMPLATE_FIELD_UPDATED = 'template-field-updated',
  LOT_ADDED = 'lot-added',
  LOT_REMOVED = 'lot-removed',
  LOT_UPDATED = 'lot-updated',
  LOTS_REORDERED = 'lots-reordered',
}

export type RfqEventChange =
  | DetailsChange
  | SenderChange
  | AssetChange
  | TeamMembersChange
  | ExchangeChange;

export interface BasicChange {
  _id?: string;
}

export interface ExchangeAddedChange extends BasicChange {
  type: ChangeType.EXCHANGE_ADDED;
  companyId?: CompanyId;
  sectionName: SectionId;
  docXDef: ExchangeDefinition;
}

export interface ExchangeRemovedChange extends BasicChange {
  type: ChangeType.EXCHANGE_REMOVED;
  companyId?: CompanyId;
  sectionName: SectionId;
  docXDefId: ExchangeId;
}

export interface ExchangeUpdatedChange extends BasicChange {
  type: ChangeType.EXCHANGE_UPDATED;
  companyId?: CompanyId;
  sectionName: SectionId;
  docXDef: Patch<ExchangeDefinition>;
}

export interface ExchangesReorderedChange extends BasicChange {
  type: ChangeType.EXCHANGES_REORDERED;
  companyId?: CompanyId;
  sectionName: SectionId;
  docXDefIds: ExchangeId[];
}

export type ExchangeChange =
  | ExchangeAddedChange
  | ExchangeRemovedChange
  | ExchangeUpdatedChange
  | ExchangesReorderedChange;

export interface HirePeriodAddedChange extends BasicChange {
  type: ChangeType.HIRE_PERIOD_ADDED;
  companyId?: CompanyId;
  sectionName: SectionId;
  hirePeriod: HirePeriod;
}

export interface HirePeriodRemovedChange extends BasicChange {
  type: ChangeType.HIRE_PERIOD_REMOVED;
  companyId: CompanyId;
  sectionName: SectionId;
  hirePeriodId: string;
}

export interface HirePeriodUpdatedChange extends BasicChange {
  type: ChangeType.HIRE_PERIOD_UPDATED;
  companyId: CompanyId;
  sectionName: SectionId;
  hirePeriod: HirePeriod;
}

export interface HirePeriodOrderUpdatedChange extends BasicChange {
  type: ChangeType.HIRE_PERIOD_ORDER_UPDATED;
  companyId: CompanyId;
  sectionName: SectionId;
  hirePeriodIds: string[];
}

export type HirePeriodChange =
  | HirePeriodAddedChange
  | HirePeriodRemovedChange
  | HirePeriodUpdatedChange
  | HirePeriodOrderUpdatedChange;

export interface FieldUpdatedChange extends BasicChange {
  type: ChangeType.FIELD_UPDATED;
  sectionName?: SectionId;
  fieldName: string;
  value: string | number | ApproximateTotal | Attachment[];
}

export interface StageAddedChange extends BasicChange {
  type: ChangeType.STAGE_ADDED;
  stage: Stage<Draft>;
}

export interface StageRemovedChange extends BasicChange {
  type: ChangeType.STAGE_REMOVED;
  stageId: StageId;
}

export interface StageUpdatedChange extends BasicChange {
  type: ChangeType.STAGE_UPDATED;
  stage: Stage<Draft>;
}

export interface StagesReorderedChange extends BasicChange {
  type: ChangeType.STAGES_REORDERED;
  stageIds: StageId[];
}

export interface PageAddedChange extends BasicChange {
  type: ChangeType.PAGE_ADDED;
  page: Page;
}

export interface PageRemovedChange extends BasicChange {
  type: ChangeType.PAGE_REMOVED;
  pageId: PageId;
}

export interface PageUpdatedChange extends BasicChange {
  type: ChangeType.PAGE_UPDATED;
  page: Patch<Page>;
}

export interface PagesReorderedChange extends BasicChange {
  type: ChangeType.PAGES_REORDERED;
  pageIds: PageId[];
}

export interface SectionAddedChange extends BasicChange {
  type: ChangeType.SECTION_ADDED;
  section: Section;
}

export interface SectionRemovedChange extends BasicChange {
  type: ChangeType.SECTION_REMOVED;
  sectionId: SectionId;
}

export interface SectionUpdatedChange extends BasicChange {
  type: ChangeType.SECTION_UPDATED;
  section: Patch<Section>;
}

export interface LotAddedChange extends BasicChange {
  type: ChangeType.LOT_ADDED;
  lot: Lot<Draft>;
}

export interface LotRemovedChange extends BasicChange {
  type: ChangeType.LOT_REMOVED;
  lotId: LotId;
}

export interface LotUpdatedChange extends BasicChange {
  type: ChangeType.LOT_UPDATED;
  lot: Lot<Draft>;
}

export interface LotsReorderedChange extends BasicChange {
  type: ChangeType.LOTS_REORDERED;
  lotIds: LotId[];
}

export type SectionChange =
  | SectionAddedChange
  | SectionRemovedChange
  | SectionUpdatedChange;

export type LotChange =
  | LotAddedChange
  | LotRemovedChange
  | LotUpdatedChange
  | LotsReorderedChange;

export type DetailsChange =
  | ExchangeChange
  | HirePeriodChange
  | FieldUpdatedChange
  | StageAddedChange
  | StageRemovedChange
  | StageUpdatedChange
  | StagesReorderedChange
  | LotChange
  | PageAddedChange
  | PageRemovedChange
  | PageUpdatedChange
  | PagesReorderedChange
  | SectionChange;

export interface SenderAddedChange extends BasicChange {
  type: ChangeType.SENDER_ADDED;
  sender: Sender;
}

export interface SenderUpdatedChange extends BasicChange {
  type: ChangeType.SENDER_UPDATED;
  sender: Patch<Sender>;
}

export interface SenderRemovedChange extends BasicChange {
  type: ChangeType.SENDER_REMOVED;
  senderId: string;
}

export type SenderChange =
  | SenderAddedChange
  | SenderUpdatedChange
  | SenderRemovedChange;

export interface AssetAddedChange {
  type: ChangeType.ASSET_ADDED;
  companyId: CompanyId;
  sectionName: SectionId;
  asset: Asset;
}

export interface AssetRemovedChange {
  type: ChangeType.ASSET_REMOVED;
  companyId: CompanyId;
  sectionName: SectionId;
  assetId: string;
}

export interface AssetFieldsUpdatedChange {
  type: ChangeType.ASSET_FIELDS_UPDATED;
  companyId: CompanyId;
  sectionName: SectionId;
  assetId: string;
  assetName: string;
  fields: Partial<Asset>;
}

export type AssetChange = AssetAddedChange | AssetFieldsUpdatedChange | AssetRemovedChange;

export interface TeamMemberAddedChange extends BasicChange {
  type: ChangeType.TEAM_MEMBER_ADDED;
  user: RequestUser;
  companyId: CompanyId;
}

export interface TeamMemberRemovedChange extends BasicChange {
  type: ChangeType.TEAM_MEMBER_REMOVED;
  userId: string;
  name: string;
  companyId: CompanyId;
}

export interface TeamMemberRolesUpdatedChange extends BasicChange {
  type: ChangeType.TEAM_MEMBER_ROLES_UPDATED;
  userId: string;
  rfqRoles: {
    [key: string]: PageRole;
  };
  rfqRole?: string;
  companyId: CompanyId;
  /**
   * @deprecated
   * The `PageRole` with the most privileges allowed for the given user.
   */
  mostPrivilegedPageRole?: PageRole;
  /**
   * The `PageRole` with the most privileges allowed for the given user for each page, keyed by page ID
   */
   mostPrivilegedPageRoleById?: {
    [key: string]: PageRole;
   };
}

export interface RequestOwnerUpdatedChange extends BasicChange {
  type: ChangeType.REQUEST_OWNER_UPDATED;
  userId: string;
  isOwner: boolean;
  companyId: CompanyId;
  // `replacementUser` is required when the user is the only request owner
  // and gets downgraded to a non-owner
  replacementUser?: RequestUser;
}

export interface PageApproverUpdatedChange extends BasicChange {
  type: ChangeType.PAGE_APPROVER_UPDATED;
  userId: string;
  pageId: PageId;
}

export interface CompanyMembershipRemovedChange extends BasicChange {
  type: ChangeType.COMPANY_MEMBERSHIP_REMOVED;
  userId: string;
  companyId: CompanyId;
  // `replacementUser` is required when the removed user is the only request owner
  replacementUser?: RequestUser;
}

export type TeamMembersChange = TeamMemberAddedChange
  | TeamMemberRemovedChange
  | TeamMemberRolesUpdatedChange
  | RequestOwnerUpdatedChange
  | PageApproverUpdatedChange
  | CompanyMembershipRemovedChange;
