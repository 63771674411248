import { useMemo } from 'react';
import { Page, PageType, SectionType } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Text, Box, Flex } from 'rebass/styled-components';
import { partition } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@deepstream/ui/ui/Tabs';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ContractPageProvider, ContractSectionProvider, useContractData, useContractSection } from '@deepstream/ui/modules/Contracts/contract';
import { AdminDocumentSection } from '../../AdminDocumentSection';
import { AdminLineItemsSection } from '../../AdminLineItemsSection';
import { iconsBySectionType } from '../../RequestDetails';
import { AdminContractSection } from './AdminContractSection';

const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const SectionHeader = styled(Flex)`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
`;

const ContractSection = ({
  isLast,
}: {
  isLast?: boolean;
}) => {
  const section = useContractSection();

  const sectionIcon: { icon: IconProps['icon']; regular?: boolean } =
    iconsBySectionType[section.type as keyof typeof iconsBySectionType];

  return (
    <Box p={3} pb={isLast ? 3 : 4}>
      <SectionHeader pb={2}>
        <Flex alignItems="center">
          <Icon
            regular={sectionIcon?.regular}
            icon={sectionIcon?.icon}
            style={{ fontSize: '16px', marginRight: '5px', width: '20px', textAlign: 'center' }}
          />
          {section.name}
        </Flex>
      </SectionHeader>
      {section.type === SectionType.DOCUMENT ? (
        <AdminDocumentSection isLast={isLast} />
      ) : section.type === SectionType.LINE_ITEMS ? (
        <AdminLineItemsSection isLast={isLast} />
      ) : section.type === SectionType.CONTRACT ? (
        <AdminContractSection />
      ) : (
        <Subtext>See debug for more details</Subtext>
      )}
    </Box>
  );
};

const isContractPage = (page: Page) => page.type === PageType.CONTRACT;

export const AdminContractDetails = () => {
  const { pages, sectionById } = useContractData();

  const orderedPages = useMemo(() => {
    const [specialPages, regularPages] = partition(
      pages,
      page => isContractPage(page),
    );

    return [...regularPages, ...specialPages];
  }, [pages]);

  return orderedPages.length
    ? (
      <Tabs>
        <TabList>
          {orderedPages.map(page => (
            <Tab key={page._id}>{page.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {orderedPages.map(page => (
            <TabPanel key={page._id}>
              <ContractPageProvider page={page}>
                {page.sections.map((sectionId, index) => {
                  const section = sectionById[sectionId];

                  return section ? (
                    <ContractSectionProvider section={section} key={sectionId}>
                      <ContractSection isLast={index === page.sections.length - 1} />
                    </ContractSectionProvider>
                  ) : (
                    <Box p={3} key={sectionId}>
                      <ErrorMessage error={`No section found with id ${sectionId}`} />
                    </Box>
                  );
                })}
              </ContractPageProvider>
              {!page.sections.length && (
                <PanelPadding>
                  <Subtext>No sections yet</Subtext>
                </PanelPadding>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    )
    : (
      <Subtext p={16}>No pages yet</Subtext>
    );
};
