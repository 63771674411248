import { useMemo } from 'react';
import { useField } from 'formik';
import { Box, Text } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { getItemLabelWithDescription, SelectField } from '../../../form/SelectField';
import { HelpCenterInlineLink } from '../../../HelpCenterLink';
import { GlobalRole } from './types';

export const LearnMoreAboutUserRoles = () => {
  const theme = useTheme();

  return (
    <Trans i18nKey="user.globalRoleSelectFooter">
      {' '}
      <HelpCenterInlineLink
        path="/en/articles/6337681-understand-the-different-types-of-user-profiles-available"
        sx={{
          fontWeight: 500,
          // This is only needed for overwriting angular styles
          color: `${theme.colors.primary} !important`,
          textDecoration: 'none',
        }}
      >
        Learn more
      </HelpCenterInlineLink>
      {' '}
      about user roles.
    </Trans>
  );
};

const GlobalRoleSelectFooter = () => (
  <Box pt={1}>
    <DropdownMenuDivider />
    <Text fontSize={0} color="subtext" padding="10px 10px 7px">
      <LearnMoreAboutUserRoles />
    </Text>
  </Box>
);

export const useGlobalRoleOptions = (companyRoles: GlobalRole[]) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const options = [
      {
        label: t('user.globalRole.superUser'),
        description: (
          <>
            {t('user.globalRoleDescription.superUser.line1')}
            <br />
            {t('user.globalRoleDescription.superUser.line2')}
          </>
        ),
        value: GlobalRole.SUPER_USER,
      },
      {
        label: t('user.globalRole.fullUser'),
        description: (
          <>
            {t('user.globalRoleDescription.fullUser.line1')}
            <br />
            {t('user.globalRoleDescription.fullUser.line2')}
          </>
        ),
        value: GlobalRole.FULL_USER,
      },
      {
        label: t('user.globalRole.authorizedStakeholder'),
        description: (
          <>
            {t('user.globalRoleDescription.authorizedStakeholder.line1')}
            <br />
            {t('user.globalRoleDescription.authorizedStakeholder.line2')}
          </>
        ),
        value: GlobalRole.AUTHORIZED_STAKEHOLDER,
      },
    ];

    return options.filter(option => companyRoles.includes(option.value));
  }, [t, companyRoles]);
};

export const DEFAULT_COMPANY_ROLES = [
  GlobalRole.SUPER_USER,
  GlobalRole.FULL_USER,
  GlobalRole.AUTHORIZED_STAKEHOLDER,
];

export const APP_COMPANY_ROLES = [
  GlobalRole.SUPER_USER,
  GlobalRole.FULL_USER,
];

export const RoleField = ({
  companyRoles = DEFAULT_COMPANY_ROLES,
  disableGlobalRoleField = false,
  disableAdminField = false,
}: {
  companyRoles?: GlobalRole[];
  disableGlobalRoleField?: boolean;
  disableAdminField?: boolean;
}) => {
  const { t } = useTranslation();
  const [,, globalRoleFieldHelpers] = useField('globalRole');
  const [,, adminFieldHelpers] = useField('admin');
  const [,, editorFieldHelpers] = useField('editor');
  const [,, managePreQualFieldHelpers] = useField('managePreQual');
  const [,, sendQuestionnairesFieldHelpers] = useField('sendQuestionnaires');
  const [,, sendRFQFieldHelpers] = useField('sendRFQ');
  const [,, sendContractsFieldHelpers] = useField('sendContracts');
  const [,, accessReportingForRequestsFieldHelpers] = useField('accessReportingForRequests');
  const [,, accessReportingForContractsFieldHelpers] = useField('accessReportingForContracts');
  const globalRoleOptions = useGlobalRoleOptions(companyRoles);

  return (
    <Box mb={3}>
      <SelectField
        name="globalRole"
        label={t('general.userRole')}
        items={globalRoleOptions}
        onChange={(globalRole: GlobalRole) => {
          globalRoleFieldHelpers.setValue(globalRole);
          switch (globalRole) {
            case GlobalRole.AUTHORIZED_STAKEHOLDER:
              adminFieldHelpers.setValue(false);
              editorFieldHelpers.setValue(false);
              managePreQualFieldHelpers.setValue(false);
              sendQuestionnairesFieldHelpers.setValue(false);
              sendRFQFieldHelpers.setValue(false);
              sendContractsFieldHelpers.setValue(false);
              accessReportingForRequestsFieldHelpers.setValue(false);
              accessReportingForContractsFieldHelpers.setValue(false);
              break;
            case GlobalRole.FULL_USER:
              adminFieldHelpers.setValue(false);
              break;
            case GlobalRole.SUPER_USER:
              adminFieldHelpers.setValue(true);
              break;
            default:
              throw new Error(`Global role invalid value: ${globalRole}`);
          }
        }}
        disabled={disableGlobalRoleField}
        getButtonLabel={item => item?.label ?? ''}
        getItemLabel={getItemLabelWithDescription}
        footer={<GlobalRoleSelectFooter />}
      />
    </Box>
  );
};
