import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';

export const VolumeValueFieldToggle = ({
  displayField,
  setDisplayField,
}: {
  displayField: 'volume' | 'value';
  setDisplayField: (field: 'volume' | 'value') => void;
}) => {
  const { t } = useTranslation('reporting');

  return (
    <ButtonGroup marginBetween="-1">
      <Button
        type="button"
        variant={displayField === 'volume' ? 'secondary' : 'secondary-outline'}
        small
        onClick={() => { setDisplayField('volume'); }}
      >
        {t('contracts.volume')}
      </Button>

      <Button
        type="button"
        variant={displayField === 'value' ? 'secondary' : 'secondary-outline'}
        small
        onClick={() => { setDisplayField('value'); }}
      >
        {t('contracts.value')}
      </Button>
    </ButtonGroup>
  );
};
