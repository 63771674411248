import { format } from 'date-fns';
import { transparentize } from 'polished';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, Text, Flex, FlexProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { useApi, wrap } from '../../api';
import { Bold } from '../../Bold';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { Loading } from '../../ui/Loading';
import { Pagination } from '../../Pagination';

const PAGE_SIZE = 10;

const Row = styled(Flex)<{ isSelected?: boolean }>`
  font-size: ${props => props.theme.fontSizes[2]}px;
  height: 36px;
  outline: none;
  cursor: ${props => props.isSelected ? 'default' : 'pointer'};

  &:hover:not(:focus) {
    background-color: ${props => props.isSelected ? 'initial' : transparentize(0.95, props.theme.colors.primary)};
  }

  &:focus {
    background-color: ${props => props.isSelected ? 'initial' : transparentize(0.85, props.theme.colors.primary)};
  }
`;

const Heading = () => {
  const { t } = useTranslation('supplierDiscovery');

  return (
    <Box color="subtext" fontSize={1}>
      <Text as="span" mr={1} sx={{ textTransform: 'uppercase' }}>
        <Bold>
          {t('searchHistory.searchHistory')}
        </Bold>
      </Text>
      <Tooltip content={t('searchHistory.infoTooltip')}>
        <Icon icon="info-circle" regular />
      </Tooltip>
    </Box>
  );
};

type ListItemProps = FlexProps & {
  onSelect?: any;
  isSelected?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({ onSelect, isSelected, ...props }) => {
  const onKeyDown = event => {
    if (event.key === 'Enter') {
      onSelect();
    }
  };

  return (
    <Row
      as="li"
      tabIndex={isSelected ? -1 : 0}
      onClick={onSelect}
      onKeyDown={onKeyDown}
      fontWeight={isSelected ? 500 : 'normal'}
      isSelected={isSelected}
      alignItems="center"
      py={2}
      px={1}
      {...props}
    />
  );
};

export const DiscoverySearchHistory = ({
  selectedQueryId,
  navigateToDiscoveryQuery,
}: {
  selectedQueryId?: string;
  navigateToDiscoveryQuery: (queryId: string) => void;
}) => {
  const { t } = useTranslation('supplierDiscovery');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const [pageIndex, setPageIndex] = React.useState(0);

  const { data: searchHistory = [], status } = useQuery(
    ['discoveryQueryHistory', { companyId: currentCompanyId }],
    wrap(api.getDiscoveryQueryHistory),
  );

  const pageCount = searchHistory && Math.ceil(searchHistory.length / PAGE_SIZE);

  const visibleQueries = React.useMemo(() => {
    if (!searchHistory) return [];

    const sortedQueries = orderBy(searchHistory, 'timestamp', 'desc');

    return sortedQueries.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE);
  }, [pageIndex, searchHistory]);

  return (
    <>
      <Panel>
        <PanelHeader heading={<Heading />} />
        <PanelDivider />
        <PanelPadding>
          {status === 'loading' ? (
            <Loading />
          ) : status === 'error' ? (
            <ErrorMessage fontSize={1} error={t('error.getSearchHistory')} />
          ) : searchHistory.length ? (
            <Box as="ul" sx={{ listStyle: 'none', padding: 0 }}>
              {visibleQueries.map((query) => (
                <ListItem
                  key={query.queryId}
                  isSelected={selectedQueryId === query.queryId}
                  onSelect={() => navigateToDiscoveryQuery(query.queryId)}
                >
                  <Text flex="0 0 70px" fontSize={1} color="subtext" mr={2}>
                    {format(new Date(query.timestamp), DateFormat.DD_MM_YYYY_SLASH)}
                  </Text>
                  <Tooltip content={query.params.request}>
                    <Truncate flex="1 1 auto" display="inline-block">
                      {query.params.request}
                    </Truncate>
                  </Tooltip>
                </ListItem>
              ))}
            </Box>
          ) : (
            <Text fontSize={1} color="subtext">
              {t('searchHistory.noRecentSearches')}
            </Text>
          )}
        </PanelPadding>
      </Panel>
      {pageCount > 1 && (
        <Pagination
          small
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageSize={PAGE_SIZE}
          canPreviousPage={pageIndex > 0}
          canNextPage={pageIndex < pageCount - 1}
          nextPage={() => setPageIndex(pageIndex + 1)}
          previousPage={() => setPageIndex(pageIndex - 1)}
          sx={{
            borderTop: '',
            justifyContent: 'flex-end !important',
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          }}
        />
      )}
    </>
  );
};
