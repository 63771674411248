import * as React from 'react';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const PreWrap = styled(Box)`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const PreWrapCell: React.FC<CellProps<any>> = ({ cell, column, sx }) => (
  <PreWrap sx={sx}>
    {cell.value || (column as any).defaultValue}
  </PreWrap>
);

export const PreWrapCellLarge: React.FC<CellProps<any>> = ({ cell, column }) => (
  <PreWrap fontSize={3} lineHeight={1.25} mt={1}>
    {cell.value || (column as any).defaultValue}
  </PreWrap>
);

export const PreWrapCell2 = ({
  row: { original: exchange },
  cell,
  sx,
  column,
}) => {
  const { EmptyCell = EmDash as React.FC<any>, showTooltip } = column;

  if (!cell.value) {
    return EmptyCell ? (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    ) : (
      null
    );
  }
  return (
    <Tooltip content={showTooltip ? cell.value : null}>
      <PreWrap>
        {cell.value}
      </PreWrap>
    </Tooltip>
  );
};
