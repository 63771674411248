import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { callAll } from '@deepstream/utils/callAll';
import { useMutation } from '@deepstream/ui/useMutation';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useRouter } from '@tanstack/react-router';
import { ReactNode } from 'react';

export type FeatureFlagConfig = {
  accessor: string;
  label: string;
  modalHeading: string;
  modalBody?: ReactNode;
};

export const EditCompanyFeatureFlagModal = ({
  companyId,
  featureFlag,
  value,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  companyId: string;
  featureFlag: FeatureFlagConfig;
  value: boolean;
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const router = useRouter();

  const [updateCompanyFeatureFlags] = useMutation(
    adminApi.updateCompanyFeatureFlags,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Company feature flag updated successfully'),
      ),
      onError: () => toaster.error('Company feature flag could not be updated'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['company', { companyId }]),
        () => router.invalidate(),
      ),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{ value }}
        validationSchema={yup.object().shape({ value: yup.boolean() })}
        onSubmit={async ({ value }, { setSubmitting }) => {
          await updateCompanyFeatureFlags({
            companyId,
            featureFlags: {
              [featureFlag.accessor]: value,
            },
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {featureFlag.modalHeading}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <SwitchField
                  checkedIcon={false}
                  uncheckedIcon={false}
                  width={42}
                  name="value"
                  checkedText="Enabled"
                  uncheckedText="Disabled"
                />
                {featureFlag.modalBody}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
