import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Avatar } from './Avatar';
import { UserInvitedBadge } from './Badge';

type UserDetailsProps = {
  user: any;
  largeEmailLine?: boolean;
  emailLineMarginTop?: number;
};

export const UserDetails: React.FC<UserDetailsProps> = ({ user, largeEmailLine, emailLineMarginTop = 0 }) => (
  <Flex alignItems="center" style={{ overflow: 'hidden' }}>
    <Box flex="0 0 auto" width="30px" mr={2}>
      <Avatar userId={user._id} />
    </Box>
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      sx={largeEmailLine ? undefined : { lineHeight: 'normal' }}
    >
      {user.name?.trim() && (
        <Flex alignItems="center" maxWidth="100%">
          <Truncate
            as="span"
            style={{ fontWeight: 500, width: 'auto', maxWidth: '100%' }}
          >
            {user.name}
          </Truncate>
          {user.pendingCreation && (
            <UserInvitedBadge />
          )}
        </Flex>
      )}
      {largeEmailLine ? (
        <Truncate>{user.email}</Truncate>
      ) : (
        <Text fontSize={1} color="subtext" mt={emailLineMarginTop} mb="3px" maxWidth="100%">
          <Truncate>{user.email}</Truncate>
        </Text>
      )}
    </Flex>
  </Flex>
);
