export enum Environment {
  PROD = 'prod',
  TEST = 'test',
  DEV = 'dev',
  STAGING = 'staging',
  PILOT = 'pilot',
  DEMO = 'demo',
}

export enum NodeEnv {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}
