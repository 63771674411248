import { useCallback, useEffect } from 'react';

import { SidebarLayout } from '@deepstream/ui/ui/ProfileLayout';
import { Tab, TabListPanel, TabPanel, TabPanels, Tabs } from '@deepstream/ui/ui/TabsVertical';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useQuery } from 'react-query';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Datetime2 } from '@deepstream/ui/Datetime';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { addHours, startOfHour } from 'date-fns';
import { DateFormat } from '@deepstream/utils';
import * as Title from './title';
import { Page } from './Page';
import {
  productDashboardTabs,
  productDashboardTabsConfig,
  getDashboardTabIndex,
  getDashboardTab,
} from './dashboard/tabsConfig';
import { ProductDashboard } from './dashboard/ProductDashboard';
import * as reports from './reports/reports';
import { useAdminNavigation } from './AppRouting';

const UPDATE_INTERVAL_HOURS = 3;

const getNextScheduledUpdateDate = () => {
  const now = new Date();
  const startOfThisHour = startOfHour(now);
  const utcHour = startOfThisHour.getUTCHours();
  const hoursToAdd = UPDATE_INTERVAL_HOURS - (utcHour % UPDATE_INTERVAL_HOURS);

  return addHours(startOfThisHour, hoursToAdd);
};

const PageHeader = () => {
  const { generateReport, isGenerating } = reports.useGenerateReport({
    reportName: 'Product dashboard',
  });

  const adminApi = useAdminApi();

  const { data, refetch } = useQuery(
    ['productDashboard'],
    wrap(adminApi.getProductDashboard),
  );
  const nextScheduledUpdateData = getNextScheduledUpdateDate();

  useWatchValue(
    isGenerating,
    (isGenerating, wasGenerating) => {
      if (wasGenerating && !isGenerating) {
        refetch();
      }
    },
  );

  const onGenerateReport = useCallback(
    () => {
      generateReport({ reportType: 'productDashboard' });
    },
    [generateReport],
  );

  return (
    <Title.Container justifyContent="space-between" alignItems="center">
      <Title.Dashboard size="large" />

      {data && (
        <Flex alignItems="center">
          <Box color="subtext" fontSize={1} mr={3}>
            <Box>
              <Box sx={{ width: '95px', display: 'inline-block' }}>Last updated</Box>
              <Datetime2 value={data.meta.createdAt} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
            </Box>
            <Box>
              <Box sx={{ width: '95px', display: 'inline-block' }}>Next scheduled</Box>
              <Datetime2 value={nextScheduledUpdateData} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
            </Box>
          </Box>
          <Button
            variant="secondary-outline"
            iconLeft="refresh"
            onClick={onGenerateReport}
            // We don't disable the button when `data.meta?.isUpdating` is true
            // to allow retriggering report generation in the case of a report
            // being stuck in 'pending' / 'inProgress' state
            disabled={isGenerating}
          >
            {isGenerating || data.meta.isUpdating ? 'In progress' : 'Update now'}
          </Button>
        </Flex>
      )}
    </Title.Container>
  );
};

export const AdminDashboardPage = ({ tab }) => {
  const navigation = useAdminNavigation();

  useEffect(
    () => {
      if (!tab) {
        navigation.navigateToDashboard(productDashboardTabs.general, true);
      }
    },
    [tab, navigation],
  );

  const { isExtraSmall, isSmall } = useDeviceSize();

  const invalidateReports = reports.useInvalidateReports();

  return (
    <Page>
      <PageHeader />
      <Tabs
        index={getDashboardTabIndex(tab)}
        onChange={index => {
          invalidateReports();
          navigation.navigateToDashboard(getDashboardTab(index));
        }}
      >
        <SidebarLayout
          sidebar={[productDashboardTabsConfig].map(
            (sidebarConfig) => (
              <TabListPanel
                key={sidebarConfig.key}
              >
                {Object.entries(sidebarConfig.tabs).map(([tabKey, config]) => (
                  <Tab key={tabKey}>
                    <Flex alignItems="center">
                      <Icon icon={config.icon} mr={2} fixedWidth regular={config.isIconRegular} />
                      <Text flex={1}>{config.text}</Text>
                    </Flex>
                  </Tab>
                ))}
              </TabListPanel>
            ),
          )}
          main={
            <TabPanels>
              <TabPanel key={productDashboardTabs.general}>
                <ProductDashboard tab={productDashboardTabs.general} />
              </TabPanel>
              <TabPanel key={productDashboardTabs.preQualification}>
                <ProductDashboard tab={productDashboardTabs.preQualification} />
              </TabPanel>
              <TabPanel key={productDashboardTabs.requests}>
                <ProductDashboard tab={productDashboardTabs.requests} />
              </TabPanel>
              <TabPanel key={productDashboardTabs.contracts}>
                <ProductDashboard tab={productDashboardTabs.contracts} />
              </TabPanel>
              <TabPanel key={productDashboardTabs.integrations}>
                <ProductDashboard tab={productDashboardTabs.integrations} />
              </TabPanel>
            </TabPanels>
          }
          sidebarStyle={
            !isExtraSmall && !isSmall
              ? { maxWidth: '232px', flex: '0 0 auto' }
              : undefined
          }
          mainStyle={
            !isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined
          }
        />
      </Tabs>
    </Page>
  );
};
