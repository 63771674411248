import { useTranslation } from 'react-i18next';
import { ChangeType } from '@deepstream/common/rfq-utils';
import { TFunction } from 'i18next';
import { useQueryClient } from 'react-query';
import { isArray } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import * as bid from './bid';
import * as draft from './draft/draft';
import * as live from './live';
import { useToaster } from './toast';
import { useRfqId } from './useRfq';

export type UpdateMutationArgs = {
  onSuccess?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
  onSettled?: (...args: any[]) => void;
};

export const getSuccessMessage = (t: TFunction, changeType?: ChangeType) => {
  switch (changeType) {
    case ChangeType.TEAM_MEMBER_REMOVED:
      return t('teamManagement.toaster.removeUserSuccess');
    case ChangeType.SENDER_ADDED:
      return t('teamManagement.toaster.collaboratorAddedSuccess');
    default:
      return t('request.toaster.changesSavedSuccess');
  }
};

export const getErrorMessage = (t: TFunction, changeType?: ChangeType) => {
  switch (changeType) {
    case ChangeType.TEAM_MEMBER_REMOVED:
      return t('teamManagement.toaster.removeUserError');
    case ChangeType.SENDER_ADDED:
      return t('teamManagement.toaster.collaboratorAddedError');
    default:
      return t('request.toaster.changesSavedError');
  }
};

export const useSaveDraftTeamChanges = ({ onSuccess, onError }: UpdateMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();

  return draft.useSaveDraftChanges({
    getChanges: ({ changes }) => changes,
    onSuccess: callAll(
      onSuccess,
      (_, payload) => toaster.success(getSuccessMessage(t, payload?.changes?.[0]?.type)),
      () => queryClient.invalidateQueries({
        predicate: ({ queryKey }) => isArray(queryKey) && queryKey[1]?.rfqId === rfqId,
      }),
    ),
    onError: callAll(
      onError,
      (_, payload) => toaster.error(getErrorMessage(t, payload?.changes?.[0]?.type)),
    ),
  });
};

export const useSaveLiveTeamChanges = ({ onSuccess, onError }: UpdateMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();

  return live.useSaveLiveChanges({
    getChanges: ({ changes }) => changes,
    onSuccess: callAll(
      onSuccess,
      (_, payload) => toaster.success(getSuccessMessage(t, payload?.changes?.[0]?.type)),
      () => queryClient.invalidateQueries({
        predicate: ({ queryKey }) => isArray(queryKey) && queryKey[1]?.rfqId === rfqId,
      }),
    ),
    onError: callAll(
      onError,
      (_, payload) => toaster.error(getErrorMessage(t, payload?.changes?.[0]?.type)),
    ),
  });
};

export const useSaveBidTeamChanges = ({ onSuccess, onError, isSender }: UpdateMutationArgs & { isSender?: boolean; } = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();

  return bid.useSaveBidTeamChanges({
    getChanges: ({ changes }) => changes,
    onSuccess: callAll(
      onSuccess,
      (_, payload) => toaster.success(getSuccessMessage(t, payload?.changes?.[0]?.type)),
    ),
    onError: callAll(
      onError,
      (_, payload) => toaster.error(getErrorMessage(t, payload?.changes?.[0]?.type)),
    ),
    isSender,
  });
};
