import { difference, isEmpty, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import { MessageData, NotificationTransportType } from './shared-domain';
import { MessageDtoSerializer } from './message-dto-serializer';
import { MessageType } from './message';
import { CloseEventStatusCode, WebsocketsTransport, WS_LOGS_ENABLED } from './transport';
import { WsSubscription, WsSubscriptions } from './subscriptions';

export enum WsStatus {
  CONNECTING = 'connecting',
  READY = 'ready',
  CLOSED = 'closed',
  ERROR = 'error',
}

export type StatusChange = {
  status: WsStatus;
  code?: CloseEventStatusCode;
};

export class SubscriptionManager {
  private serializer = new MessageDtoSerializer();
  private transport = new WebsocketsTransport({
    keepAliveSignal: true,
    reconnectInterval: 3000,
  });
  private accessToken: string | null = null;
  private subscriberIdByTopic: Record<string, string> = {};
  private subscriptions = new WsSubscriptions();

  constructor(
    private endpoint: string,
    private getAccessTokenSilently: () => Promise<string>,
    private onStatusChange: (status: StatusChange) => void,
  ) {
    this.transport.onStartConnecting(() => {
      if (WS_LOGS_ENABLED) {
        console.log(new Date().toISOString(), 'WS connecting...');
      }
      this.onStatusChange({ status: WsStatus.CONNECTING });
    });

    this.transport.onOpen((event) => {
      if (WS_LOGS_ENABLED) {
        console.log(new Date().toISOString(), 'WS connection opened', event);
      }

      const topics = this.subscriptions.getAllTopics();

      for (const topic of topics) {
        const data = this.getSubscribePayload(topic);
        this.transport.send(data);
      }

      this.onStatusChange({ status: WsStatus.READY });

      this.subscriptions.notifyMatchingItems(
        subscription => subscription.notifyOnOpenConnection,
        { topic: 'connection-established', body: {} },
      );
    });

    this.transport.onClose((event) => {
      if (WS_LOGS_ENABLED) {
        console.log(new Date().toISOString(), 'WS connection closed', event);
      }
      this.onStatusChange({ status: WsStatus.CLOSED, code: event.code });
    });

    this.transport.onMessage((rawMessage: string) => {
      const parsedMessage = this.serializer.toInternalValue(rawMessage);

      if (parsedMessage.type !== MessageType.EVENT || !parsedMessage.data) {
        return;
      }

      const messageData = parsedMessage.data as MessageData;

      this.subscriptions.notifyMatchingItems(
        subscription => subscription.topics.includes(messageData.topic),
        messageData,
      );
    });

    this.transport.onError((error) => {
      console.error('WS connection error', error);
      this.onStatusChange({ status: WsStatus.ERROR });
    });
  }

  public getTransport() {
    return this.transport;
  }

  private async connect() {
    let newAccessToken: string | null = null;

    try {
      newAccessToken = await this.getAccessTokenSilently();
    } catch (err) {
      console.error('Could not get access token');
      this.onStatusChange({ status: WsStatus.ERROR });
      return;
    }

    this.accessToken = newAccessToken;
    this.transport.setUrl(`${this.endpoint}?token=${this.accessToken}`);

    try {
      await this.transport.reconnect();
    } catch (error) {
      console.error(error);
    }
  }

  public subscribe = (subscription: WsSubscription) => {
    const existingSubscriptionTopics = this.subscriptions.getAllTopics();
    const newSubscriptionTopics = difference(subscription.topics, existingSubscriptionTopics);

    this.subscriptions.add(subscription);

    if (isEmpty(newSubscriptionTopics)) {
      return;
    }

    if (this.transport.isReady()) {
      for (const topic of newSubscriptionTopics) {
        const data = this.getSubscribePayload(topic);
        this.transport.send(data);
      }
    } else if (!this.transport.isConnectingOrOpen()) {
      this.connect();
    }
  };

  public unsubscribe = (subscriptionId: string) => {
    const previousSubscriptionTopics = this.subscriptions.getAllTopics();

    this.subscriptions.remove(subscriptionId);

    const currentSubscriptionTopics = this.subscriptions.getAllTopics();

    if (isEmpty(currentSubscriptionTopics)) {
      this.transport.close(CloseEventStatusCode.PURPOSE_FULFILLED, 'No subscriptions');
      return;
    }

    const surplusSubscriptionTopics = difference(
      previousSubscriptionTopics,
      currentSubscriptionTopics,
    );

    if (
      !isEmpty(surplusSubscriptionTopics) &&
      this.transport.isReady()
    ) {
      for (const topic of surplusSubscriptionTopics) {
        const data = this.getUnsubscribePayload(topic);
        if (data) {
          this.transport.send(data);
        }
      }
    }
  };

  private getSubscribePayload(topic: string) {
    const subscriberId = uuid();

    this.subscriberIdByTopic[topic] = subscriberId;

    const message = {
      type: MessageType.SUBSCRIBE,
      subscriberId,
      topics: [topic] as string[],
      config: {
        type: NotificationTransportType.ApiGatewayCallback,
      },
    } as const;

    return this.serializer.toRepresentation(message);
  }

  private getUnsubscribePayload(topic: string) {
    const subscriberId = this.subscriberIdByTopic[topic];

    this.subscriberIdByTopic = omit(this.subscriberIdByTopic, topic);

    if (subscriberId) {
      return this.serializer.toRepresentation({
        type: MessageType.UNSUBSCRIBE,
        subscriberId,
      });
    } else {
      return null;
    }
  }
}
