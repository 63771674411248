export enum TransportType {
  Lambda,
  Websockets,
  Null,
}

export type BaseTransportConfig<T extends TransportType> = {
  type: T,
};

export interface PublishTransport {
  send(payload: string): Promise<unknown> | unknown
}

export interface SubscribeTransport {
  onMessage(callback: (message: string) => void): void
}
