import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestRole } from '../../../types';

export const useContractRoleSelectItems = () => {
  const { t } = useTranslation(['contracts', 'translation']);

  return React.useMemo(
    () => [
      {
        value: RequestRole.OWNER,
        label: t('request.team.requestRole.owner', { ns: 'translation' }),
        description: t('team.roleDescription.owner'),
      },
      {
        value: RequestRole.TEAM_MEMBER,
        label: t('request.team.requestRole.teamMember', { ns: 'translation' }),
        description: t('team.roleDescription.teamMember'),
      },
    ],
    [t],
  );
};
