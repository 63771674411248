import { TFunction } from 'i18next';
import { isNil } from 'lodash';

export const getRegionName = (code: string | undefined, locale: string, placeholder = '') => {
  let regionName;

  try {
    if (!isNil(code)) {
      const regionNames = new Intl.DisplayNames([locale], { type: 'region' });
      regionName = regionNames.of(code);
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return regionName || placeholder;
};

/**
 * Adds locale-dependent region names to the provided `regionTags`.
 */
export const addRegionNames = (regionTags: { _id: string; code?: string }[], locale: string, t: TFunction) => {
  const regionNames = new Intl.DisplayNames([locale], { type: 'region' });

  return regionTags.map(item => {
    if (item._id === 'AN2') {
      return { ...item, name: '' };
    } else if (item.code) {
      return { ...item, name: t(`geoRegion.${item.code}`, { namespace: 'translation' }) };
    } else {
      return {
        ...item,
        name: regionNames.of(item._id),
      };
    }
  });
};
