import * as React from 'react';
import { CellProps } from 'react-table';
import { User } from '@deepstream/common/rfq-utils';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';

type TeamUsersActionButtonsProps = CellProps<any> & {
  openEditPermissionsModal: (user: User) => void;
};

export const TeamUsersActionButtons: React.FC<TeamUsersActionButtonsProps> = ({
  row,
  openEditPermissionsModal,
}) => {
  const user = row.original;

  return (
    <ButtonGroup marginBetween="-1px">
      <EditButton small onClick={() => openEditPermissionsModal(user)} />
    </ButtonGroup>
  );
};
