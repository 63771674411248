import { useCallback, useEffect } from 'react';

export function AuthBridge() {
  const sendEvent = useCallback((message) => {
    window.parent.postMessage(
      { event: message },
      document.referrer ? document.referrer : window.parent.location.href,
    );
  }, []);

  const handleEvent = useCallback((payload) => {
    const { data: { event, token } } = payload;
    switch (event) {
      case 'token':
        localStorage.setItem('AUTH_TOKEN', token);
        sendEvent('tokenReceived');
        break;
      case 'removeToken':
        localStorage.removeItem('AUTH_TOKEN');
        sendEvent('tokenRemoved');
        break;
      case 'ping':
        sendEvent('iframeReady');
        break;
      default:
        break;
    }
  }, [sendEvent]);

  useEffect(() => {
    window.addEventListener('message', message => { handleEvent(message); });
    sendEvent('iframeReady');
  }, [handleEvent, sendEvent]);

  return null;
}
