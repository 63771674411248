import { TFunction } from 'i18next';
import { compact, isNil } from 'lodash';
import { AuctionStage, GeneralStage, AnyScope, Stage, StageType } from './types';

// TODO remove `!('type' in stage) ||` once type is a required prop in GeneralStage
export const isGeneralStage = <Scope extends AnyScope>(stage: Stage<Scope>): stage is GeneralStage<Scope> =>
  !('type' in stage) || stage.type === StageType.GENERAL;

export const isAuctionStage = <Scope extends AnyScope>(stage: Stage<Scope>): stage is AuctionStage<Scope> =>
  stage.type === StageType.AUCTION;

export const renderStageName = (stage: Stage | undefined, t: TFunction, index?: number, withPrefix?: boolean) => {
  if (!stage) {
    return;
  }

  const name = isAuctionStage(stage)
    ? t('general.auction', { ns: 'translation' })
    : stage.name;

  if (isNil(index)) {
    return name;
  } else {
    const nameSegments = compact([
      withPrefix
        ? `${t('general.stage', { count: 1, ns: 'translation' })} ${index + 1}`
        : `${index + 1}`,
      name,
    ]);
    return nameSegments.join(' – ');
  }
};
