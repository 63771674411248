import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { User } from '../../../types';
import { UserDetails } from '../../../UserDetails';

type ConfirmRemoveUserModalProps = {
  modal: any;
  user: User | null;
  currentUserId: string;
  removeUser: ({ user }: { user: User }) => any;
};

export const ConfirmRemoveUserModal = ({ modal, user, currentUserId, removeUser }: ConfirmRemoveUserModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('teamManagement.dialog.confirmRemoveUser.heading')}
      style={{ content: { width: '500px' } }}
      body={user ? (
        <>
          <UserDetails user={user} />
          {user._id === currentUserId ? (
            <MessageBlock variant="warn" mt={3}>
              {t('teamManagement.dialog.confirmRemoveUser.removeYourselfWarning')}
            </MessageBlock>
          ) : (
            <MessageBlock variant="info" mt={3}>
              {t('teamManagement.removeUserInfo')}
            </MessageBlock>
          )}
        </>
      ) : (
        null
      )}
      okButtonText={t('teamManagement.removeUser')}
      okButtonVariant="danger"
      isOpen={modal.isOpen}
      onOk={() => user ? removeUser({ user }) : Promise.resolve()}
      onCancel={modal.close}
    />
  );
};
