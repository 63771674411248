import { CompanyMinimized, User } from '@deepstream/common/rfq-utils';
import { size } from 'lodash';
import { Box } from 'rebass/styled-components';
import { Bold } from '@deepstream/ui/Bold';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import * as rfx from '@deepstream/ui/rfx';
import { useUpdateRfq } from './adminRequest';
import { useAdminNavigation } from './AppRouting';

export const RemoveUser = ({
  requestId,
  company,
  user,
}: {
  requestId: string;
  company: CompanyMinimized;
  user: User;
}) => {
  const { navigateToCompany } = useAdminNavigation();
  const toaster = useToaster();
  const { teamById, summary } = rfx.useStructure();
  const removeUserModal = useModalState();
  const [updateRfq] = useUpdateRfq();

  const userId = user._id;
  const companyId = company._id;
  const companyName = company.name;

  const team = teamById[company._id];

  const isOnlyUser = size(team.users) === 1;
  const isOnlyOwner = team.owners.includes(userId) && size(team.owners) === 1;

  const removeUser = async () => {
    const changes = [{
      type: 'team-member-removed',
      userId,
      name: user.name,
      companyId,
    }];

    await updateRfq(
      { companyId, requestId, changes },
      {
        onSuccess: () => {
          removeUserModal.close();
          navigateToCompany(companyId, true);
          toaster.success('User successfully removed from request');
        },
        onError: () => {
          removeUserModal.close();
          toaster.error('An error occurred when trying to remove the user');
        },
      },
    );
  };

  const warningText = isOnlyUser
    ? `${companyName} will have no users for this request`
    : isOnlyOwner
      ? `${companyName} will have no owners for this request`
      : '';

  return (
    <>
      <Box mb={3}>
        Remove <Bold>{user.name}</Bold> from the{' '}
        <Bold>{companyName}</Bold> team for{' '}
        <Bold>{summary.subject}</Bold>
      </Box>
      <Button onClick={removeUserModal.open} variant="danger">
        Remove
      </Button>
      <Dialog
        heading="Remove user from request?"
        body={(
          <>
            {warningText && (
              <MessageBlock variant="warn" mt={0}>
                {warningText}
              </MessageBlock>
            )}
            <MessageBlock variant="info" mb={2} mt={2}>
              User will be notified via email
            </MessageBlock>
          </>
        )}
        okButtonText="Remove"
        okButtonVariant="danger"
        isOpen={removeUserModal.isOpen}
        onOk={removeUser}
        onCancel={removeUserModal.close}
      />
    </>
  );
};
