import { transparentize } from 'polished';
import * as React from 'react';
import { Button } from 'rebass/styled-components';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconText } from '../text/IconText';

type IconTextButtonProps = any;

export const IconTextButton: React.FC<IconTextButtonProps> = ({
  fontSize = 1,
  icon,
  // color = 'primary',
  variant = 'wrapper',
  onClick,
  children,
  gap = 1,
  mr = 0,
  fixedWidth = false,
  fontWeight = 500,
  truncate,
  sx,
  ...props
}) => {
  const theme = React.useContext(ThemeContext);

  return (
    <Button
      type="button"
      variant={variant}
      onClick={onClick}
      fontSize={fontSize}
      p={0}
      mr={mr}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'primary',
        fontWeight,
        lineHeight: 'normal',
        borderRadius: '1px',
        transition: `
          color 300ms,
          background-color 300ms,
          border-color 300ms,
          box-shadow 300ms;
        `,
        ':disabled': {
          color: theme.colors.lightPrimary,
        },
        ':hover:not(:disabled)': {
          cursor: 'pointer',
        },
        ':focus:not(:disabled)': {
          boxShadow: `0 0 0 3px ${transparentize(0.82, theme.colors.primary)}`,
          outline: 'none',
        },
        ':hover:not(:disabled):not(:focus)': {
          color: theme.colors.darkPrimary,
        },
        ...sx,
      }}
      {...props}
    >
      <IconText
        icon={icon}
        text={children}
        gap={gap}
        fontSize={fontSize}
        fixedWidth={fixedWidth}
        truncate={truncate}
      />
    </Button>
  );
};

export const CancelButton: React.FC<any> = (props) => {
  const { t } = useTranslation('general');

  return (
    <IconTextButton icon="times" color="gray" {...props}>
      {t('cancel')}
    </IconTextButton>
  );
};

export const RemoveButton: React.FC<any> = (props) => {
  const { t } = useTranslation('general');

  return (
    <IconTextButton icon="times" color="gray" {...props}>
      {t('remove')}
    </IconTextButton>
  );
};

export const RetryButton: React.FC<any> = (props) => {
  const { t } = useTranslation('general');

  return (
    <IconTextButton icon="redo" color="primary" {...props}>
      {t('retry')}
    </IconTextButton>
  );
};

export const DownloadButton: React.FC<any> = (props) => {
  const { t } = useTranslation('general');

  return (
    <IconTextButton icon="download" color="primary" {...props}>
      {t('download')}
    </IconTextButton>
  );
};
