import { useTranslation } from 'react-i18next';
import { RequestsBudgetCardsData, RequestsReportingConfig } from '@deepstream/common/reporting';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Grid } from '@deepstream/ui-kit/elements/Grid';
import * as dashboard from './dashboard';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { useRequestsReportingCardsData } from './useRequestsReporting';
import { BudgetScatterChart } from './BudgetScatterChart';
import { BudgetDoughnutChart } from './BudgetDoughnutChart';

export const BudgetCards = ({
  config,
  useData = useRequestsReportingCardsData,
}: { config: RequestsReportingConfig; useData?: typeof useRequestsReportingCardsData }) => {
  const { t } = useTranslation('translation');
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { data, isLoading, isError, isSuccess } = useData<RequestsBudgetCardsData>(config, 'budget');

  const interval = data?.config.interval;

  return (
    <>
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr 1fr'
            : '1fr 1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '112px'
            : '112px 112px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.allTime')}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <dashboard.Price
                amount={data.totals.allTime.amount}
                currencyCode={data.config.currency}
              />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.this.${interval}`) : undefined}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <dashboard.Price
                amount={data.totals.currentInterval.amount}
                comparisonAmount={data.totals.previousInterval.amount}
                currencyCode={data.config.currency}
              />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.last.${interval}`) : undefined}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <dashboard.Price
                amount={data.totals.previousInterval.amount}
                currencyCode={data.config.currency}
              />
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr'
            : '1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '305px'
            : '305px 305px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.percentOfRequestsWithBudget')}
          infoTooltip={t('request.spendAndSavings.percentOfRequestsWithBudgetInfo')}
        >
          <PanelPadding>
            {isSuccess && data && (
              <BudgetDoughnutChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.budgetPer.${interval}`) : undefined}
        >
          <PanelPadding pt="10px" sx={{ height: '276px' }}>
            {isSuccess && data && (
              <BudgetScatterChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>
    </>
  );
};
