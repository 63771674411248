import { get, isEmpty, isNil, sum } from 'lodash';
import { CollationType, EvaluationCriterionExchangeDefinition, ScoringType } from './types';
import { FieldType } from '../exchangesConfig';

export const getCollationType = (scoringType: ScoringType) => {
  switch (scoringType) {
    case ScoringType.SINGLE_SCORE:
      return CollationType.COMBINED;
    case ScoringType.INDIVIDUAL_SCORES:
      return CollationType.SPLIT_BY_USER;
    default:
      throw new Error(`Cannot map scoring type ${scoringType} to collation type`);
  }
};

export const hasIndividualScores = (exchangeDef: EvaluationCriterionExchangeDefinition) =>
  exchangeDef.fields.score.collationType === CollationType.SPLIT_BY_USER;

/**
 * Returns the set of basic, immutable and non-optional evaluation field
 * configurations, which are part of every evaluation exchangeDef's `fields`.
 */
export const createUnlinkedEvaluationFields = (collationType: CollationType) => ({
  description: {
    _id: 'description',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'definition',
      key: 'description',
    },
  },
  maxPoints: {
    _id: 'maxPoints',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'definition',
      key: 'maxPoints',
    },
  },
  weight: {
    _id: 'weight',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'definition',
      key: 'weight',
    },
  },
  score: {
    _id: 'score',
    type: FieldType.SCORE_POINTS,
    required: true,
    collationType,
    source: {
      type: 'reply',
      role: 'submitter',
      key: 'points',
    },
  },
}) as const;

export const createLinkedEvaluationFields = (
  collationType: CollationType,
  exchangeDefId: string,
  fieldId = 'description',
) => {
  const fields = createUnlinkedEvaluationFields(collationType);

  return {
    ...fields,
    description: {
      ...fields.description,
      source: {
        type: 'linked-exchange-definition-field',
        fieldId,
        exchangeDefId,
      } as const,
    } as const,
  };
};

/**
 * When the exchange combines all users' scores into a single score,
 * returns that score.
 * When the exchange groups scores by user, returns the score
 * submitted by the provided evaluator.
 */
export const getScore = (
  exchange: {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
  },
  { companyId, userId }: { companyId: string; userId: string },
) => {
  return hasIndividualScores(exchange.def)
    ? get(exchange.latestReply.score, [companyId, userId])
    : exchange.latestReply.score;
};

/**
 * When the exchange combines all users' scores into a single score,
 * returns that score.
 * When the exchange groups scores by user, returns the average score
 * of all evaluators.
 */
export const getAverageScore = (
  exchange: {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
  },
): number | null => {
  if (hasIndividualScores(exchange.def)) {
    const scores = Object.values(exchange.latestReply.score as Record<string, number>)
      .flatMap(companyScores => Object.values(companyScores));

    return isEmpty(scores) || scores.some(isNil)
      ? null
      : sum(scores) / scores.length;
  } else {
    return exchange.latestReply.score as number | null;
  }
};

export const getScores = (
  exchange: {
    def: EvaluationCriterionExchangeDefinition;
    latestReply: Record<string, unknown>;
  },
): (number | null)[] => {
  if (hasIndividualScores(exchange.def)) {
    const scores = Object.values(exchange.latestReply.score as Record<string, number>)
      .flatMap(companyScores => Object.values(companyScores));

    return scores as (number | null)[];
  } else {
    return [exchange.latestReply.score as (number | null)];
  }
};
