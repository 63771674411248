import { Link, LinkProps } from '@tanstack/react-router';
import { Box, BoxProps, Flex, FlexProps, Heading, HeadingProps, SxStyleProp, Text, TextProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { IconValue } from '@deepstream/common';
import { Stack, StackProps } from '@deepstream/ui-kit/elements/Stack';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CSSProperties, ReactNode } from 'react';
import { stopEvent } from '@deepstream/ui-utils/domEvent';

export const DEFAULT_SECTION_WIDTH = '773px';
const HEADER_HEIGHT_AND_PADDING = 140 + 20;

export const navigationMenuCounterSx = {
  height: '19px',
  minWidth: '19px',
  position: 'relative',
  top: '-2px',
  fontWeight: 500,
};

export const H1 = (props: HeadingProps) => {
  return (
    <Heading as="h1" fontSize={6} fontWeight={500} lineHeight={1.25} {...props} />
  );
};

export const H2 = (props: HeadingProps) => {
  return (
    <Heading as="h2" fontSize={6} fontWeight={500} {...props} />
  );
};

export const H3 = (props: HeadingProps) => {
  return (
    <Heading as="h3" fontSize={4} fontWeight={500} {...props} />
  );
};

export const H2Success = ({ children, ...props }: HeadingProps) => {
  return (
    <Heading
      as="h2"
      fontSize={6}
      fontWeight={500}
      backgroundColor="success"
      color="white"
      p={4}
      sx={{ borderRadius: '4px' }}
    >
      <Icon icon="check" mr="12px" fontWeight={400} />
      {children}
    </Heading>
  );
};

/**
 * @deprecated use Section2 instead
 * TODO Replace all usage with Section2.
 * NB: the maxWidth prop is not used in Section2 because it is set higher up in the component tree,
 * in ContentWrapper2.
 */
export const Section = ({
  heading,
  children,
  id,
  ...props
}: {
  heading?: ReactNode | undefined;
  id?: string;
} & BoxProps) => {
  return (
    <Box as="section" maxWidth={DEFAULT_SECTION_WIDTH} {...props}>
      {heading && (
        <H2 id={id}>
          {heading}
        </H2>
      )}
      {children}
    </Box>
  );
};

// TODO Replace Section with Section2
export const Section2 = ({
  heading,
  children,
  id,
  ...props
}: {
  heading?: ReactNode | undefined;
  id?: string;
} & BoxProps) => {
  return (
    <Box as="section" {...props}>
      {heading && (
        <H2 id={id} mb="20px">
          {heading}
        </H2>
      )}
      {children}
    </Box>
  );
};

/**
 * @deprecated use Subsection instead
 * TODO Replace all usage with Subsection2.
 */
export const Subsection = ({
  heading,
  children,
  id,
  ...props
}: {
  heading?: React.ReactNode | undefined;
  id?: string;
} & BoxProps) => {
  return (
    <Box as="section" {...props}>
      <Heading as="h3" fontSize={4} fontWeight={500} id={id}>
        {heading}
      </Heading>
      {children}
    </Box>
  );
};

// TODO Replace Subsection with Subsection2
export const Subsection2 = ({
  heading,
  children,
  id,
  ...props
}: {
  heading?: React.ReactNode | undefined;
  id?: string;
} & BoxProps) => {
  return (
    <Box as="section" {...props}>
      <H3 id={id} mb="20px">
        {heading}
      </H3>
      {children}
    </Box>
  );
};

export const TitledListItem = ({
  icon,
  iconRight,
  isIconRegular,
  title,
  titleColor,
  titleProps,
  body,
  contentRight,
  ...props
}: Omit<FlexProps, 'title'> & {
  icon?: IconValue;
  iconRight?: IconValue;
  contentRight?: React.ReactNode | undefined;
  isIconRegular?: boolean;
  title: string | JSX.Element;
  titleColor?: string;
  titleProps?: BoxProps;
  body?: React.ReactNode;
  xs?: SxStyleProp;
}) => {
  return (
    <Flex
      pt="22px"
      pb="26px"
      sx={{
        width: '100%',
        borderBottom: 'lightGray',
      }}
      {...props}
    >
      <Box mr={3} flex={1}>
        <Box fontSize={4} fontWeight={500} color={titleColor} mb={1} {...titleProps}>
          {icon && (
            <Icon icon={icon} regular={isIconRegular} mr={2} />
          )}
          {title}
        </Box>
        {body}
      </Box>
      {contentRight}
      {iconRight && (
        <Icon icon={iconRight} fontSize={4} color={titleColor} mt={1} />
      )}
    </Flex>
  );
};

export const TanstackInlineLink = ({ style, ...props }: LinkProps & { style?: CSSProperties; href?: any; onClick?: any }) => {
  const theme = useTheme();

  return (
    // @ts-ignore ts(2322) FIXME: Type '{ to?: ToPathOption<Route<any, "/", "/", string, "__root__", RootSearchSchema, RootSearchSchema, RootSearchSchema, RootSearchSchema, ... 11 more ..., any>, string, ""> | undefined; ... 19 more ...; style: { ...; }; }' is not assignable to type 'Omit<{ to?: ToPathOption<Route<any, "/", "/", string, "__root__", RootSearchSchema, RootSearchSchema, RootSearchSchema, RootSearchSchema, ... 11 more ..., any>, string, "" | ... 206 more ... | "/$currentCompanyId/send-questionnaire"> | undefined; hash?: true | ... 1 more ... | undefined; state?: true | ... 1 more .....'.
    <Link
      style={{
        color: theme.colors.primary,
        textDecoration: 'underline',
        fontWeight: 500,
        ...style,
      }}
      {...props}
    />
  );
};

export const OrderedListContainer = ({ children }: { children?: React.ReactNode | undefined }) => {
  return (
    <Box
      as="ol"
      sx={{
        listStyleType: 'none',
        padding: 0,
      }}
    >
      {children}
    </Box>
  );
};

export const DescriptionListContainer = styled.dl`
  display: grid;
  grid-gap: 4px 0;
  grid-template-columns: max-content;
  width: 100%;
  margin-block-end: 0;

  > dt {
    font-weight: 500;
  }
`;

export const DescriptionListItem = ({
  label,
  description,
}: {
  label?: React.ReactNode | undefined;
  description?: React.ReactNode | undefined;
}) => {
  return (
    <>
      <Box
        as="dt"
        pt="10px"
        pb="14px"
        sx={{
          fontWeight: 500,
          borderBottom: 'lightGray',
        }}
      >
        {label}
      </Box>
      <Box
        as="dd"
        pl={5}
        pt="10px"
        pb="14px"
        sx={{
          gridColumnStart: 2,
          borderBottom: 'lightGray',
        }}
      >
        {description}
      </Box>
    </>
  );
};

/**
 * @deprecated use ContentWrapper2 instead
 * TODO Replace all usage with ContentWrapper2.
 */
export const ContentWrapper = ({ children, ...props }: StackProps) => {
  return (
    <Stack gap="40px" mt={3} mb="40px" {...props}>
      {children}
    </Stack>
  );
};

// TODO Replace ContentWrapper with ContentWrapper2
export const ContentWrapper2 = ({ children, ...props }: StackProps) => {
  return (
    <Stack gap="40px" mb="40px" maxWidth={DEFAULT_SECTION_WIDTH} overflow="hidden" {...props}>
      {children}
    </Stack>
  );
};

const StyledHeaderTr = styled.tr`
  border-bottom: ${props => props.theme.borders.lightGray};
`;

const StyledTh = styled.th`
  font-weight: 500;
  padding: 12px 8px 12px 0;
  vertical-align: top;
`;

const StyledTr = styled.tr`
  border-bottom: ${props => props.theme.borders.lightGray};
`;

const StyledTd = styled.td`
  padding: 12px 8px 12px 0;
  vertical-align: top;
`;

type Column = {
  id: string;
  width?: string | number;
  title: React.ReactNode;
};

type Row = {
  id: string;
};

export const Table = ({
  columns,
  rows,
  Th = StyledTh,
  Td = StyledTd,
  ...props
}: Omit<BoxProps, 'children' | 'rows' | 'columns'> & {
  columns: Column[];
  rows: Row[];
  Th?: any;
  Td?: any;
}) => {
  return (
    <Box as="table" {...props}>
      <colgroup>
        {columns.map(column => {
          return (
            <col
              key={column.id}
              style={{ width: column.width }}
            />
          );
        })}
      </colgroup>
      <thead>
        <StyledHeaderTr>
          {columns.map(column => {
            return (
              <Th
                key={column.id}
              >
                {column.title}
              </Th>
            );
          })}
        </StyledHeaderTr>
      </thead>
      <tbody>
        {rows.map(row => (
          <StyledTr
            key={row.id}
          >
            {columns.map(column => {
              return (
                <Td
                  key={column.id}
                >
                  {row[column.id]}
                </Td>
              );
            })}
          </StyledTr>
        ))}
      </tbody>
    </Box>
  );
};

/**
 * @deprecated use InfoText2 instead
 * TODO Replace all usage with InfoText2.
 */
export const InfoText = ({ children, ...props }: TextProps) => {
  return (
    <Text color="subtext" mt="12px" {...props}>
      <Icon icon="info-circle" regular mr={1} />
      {children}
    </Text>
  );
};

// TODO Replace InfoText with InfoText2
export const InfoText2 = ({ children, ...props }: TextProps) => {
  return (
    <Text color="subtext" {...props}>
      <Icon icon="info-circle" regular mr={1} />
      {children}
    </Text>
  );
};

export const Ul = styled.ul<{ listStyleType?: string }>`
  padding-inline-start: 24px;
  margin-bottom: 0;
  list-style-type: "${props => props.listStyleType || '•  '}";

  & > li {
    margin-bottom: 6px;
  }
`;

export const IndexListItem = ({ id, heading }: { id: string; heading: React.ReactNode }) => {
  return (
    <li key={id}>
      <TanstackInlineLink
        href={`#${id}`}
        onClick={(event) => {
          const element = document.getElementById(id);
          if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY;
            window.scroll({
              top: y - HEADER_HEIGHT_AND_PADDING,
              behavior: 'smooth',
            });
          }

          stopEvent(event);
        }}
      >
        {heading}
      </TanstackInlineLink>
    </li>
  );
};
