import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { QuestionTypeCell } from '@deepstream/ui/draft/cell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useExchanges } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { ExchangeSnapshot } from '@deepstream/ui/types';

export const AdminQuestionnaireQuestionsTable = () => {
  const { t } = useTranslation();
  const exchanges = useExchanges();

  const columns = useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'def.description',
        Cell: TruncateCell,
      },
      {
        Header: 'Status',
        accessor: (row: ExchangeSnapshot) => t(`request.exchange.exchangeStatus.${row.status}`),
      },
      {
        Header: 'Type',
        accessor: 'def.questionType',
        Cell: QuestionTypeCell,
      },
    ],
    [t],
  );

  return exchanges.length ? (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={exchanges}
      />
    </StaticTableStyles>
  ) : (
    <PanelPadding>
      <Text color="subtext">No questions</Text>
    </PanelPadding>
  );
};
