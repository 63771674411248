import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

// formerly, ui/src/Truncate.tsx
export const Truncate = styled(Box)`
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
