import { useMemo } from 'react';
import { flatten, isEmpty, range, size } from 'lodash';
import { parse, unparse } from 'papaparse';
import { CellIndices, ColumnData, RowData, SelectedRange, TExpandableRowDataBase, TOriginalSubRowDataBase } from '../core/utils';
import { EditableGridColumn, GridPasteMethod } from './utils';

const expandToSelection = (
  rawData: unknown[],
  selectedRange: SelectedRange | null,
) => {
  if (selectedRange) {
    const dataRowCount = rawData.length;
    const dataColumnCount = size(rawData[0] as unknown[]);

    const selectedRowCount = selectedRange.end.rowIndex - selectedRange.start.rowIndex;
    const selectedColumnCount = selectedRange.end.columnIndex - selectedRange.start.columnIndex;

    if (
      selectedRowCount % dataRowCount === 0 &&
      selectedColumnCount % dataColumnCount === 0
    ) {
      const rowReps = selectedRowCount / dataRowCount;
      const columnReps = selectedColumnCount / dataColumnCount;

      const rowsWithExpandedColumns = rawData.map((row) => {
        return flatten(range(columnReps).map(() => row));
      });

      const expandedData = flatten(range(rowReps).map(() => rowsWithExpandedColumns));

      return expandedData;
    }
  }

  return rawData as unknown[][];
};

export type EditableGridActions = {
  exportToCsv?: (
    rows: (RowData<TOriginalSubRowDataBase, TExpandableRowDataBase<TOriginalSubRowDataBase>>)[],
    columns: (ColumnData<EditableGridColumn>)[],
    selectedRange: SelectedRange,
  ) => { csv: string; cellCount: number };
  importFromCsv?: (
    csvString: string,
    rows: (RowData<TOriginalSubRowDataBase, TExpandableRowDataBase<TOriginalSubRowDataBase>>)[],
    columns: (ColumnData<EditableGridColumn>)[],
    topLeftCellIndices: CellIndices,
    selectedRange: SelectedRange | null,
    maxSelectableEndIndices: CellIndices,
  ) => Promise<{
    updatedRows: unknown[];
    affectedColumnCount: number;
    validCellCount: number;
    invalidCellCount: number;
  } | null>;
  createClearFieldsUpdater?: ({
    startRowIndex,
    endRowIndex,
    affectedColumns,
  }: { startRowIndex: number, endRowIndex: number, affectedColumns: ({ original: any } | null)[] }) =>
    (originalRowData: any, index: number) => any;
};

export const useEditableGridActions = <TData extends TOriginalSubRowDataBase>({
  selectPasteMethod,
  createRow,
  generateCsvCellValue,
  addCsvDataToRows,
  createClearFieldsUpdater,
}: {
  selectPasteMethod?: ((additionalRowCount: number) => Promise<GridPasteMethod>) | null;
  createRow: (modelExchangeDef: TData) => TData;
  generateCsvCellValue: (columnConfig, exchangeDef: TData) => unknown;
  addCsvDataToRows: ({ rows, data, affectedColumns }: {
    rows: any;
    data: unknown[][];
    affectedColumns: any;
  }) => Promise<{
    updatedRows: any;
    validCellCount: number;
    ignoreCroppedRows?: boolean;
  }>;
  createClearFieldsUpdater?: ({
    startRowIndex,
    endRowIndex,
    affectedColumns,
  }: { startRowIndex: number, endRowIndex: number, affectedColumns: ({ original: any } | null)[] }) =>
    (originalRowData: any, index: number) => unknown;
}): EditableGridActions => {
  return useMemo(() => ({
    exportToCsv: (
      rows: (RowData<TOriginalSubRowDataBase, TExpandableRowDataBase<TOriginalSubRowDataBase>>)[],
      columns: (ColumnData<EditableGridColumn>)[],
      selectedRange: SelectedRange,
    ) => {
      const selectedRows = rows
        .slice(selectedRange.start.rowIndex, selectedRange.end.rowIndex)
        .map(row => row.original);

      const affectedColumns = columns
        .slice(selectedRange.start.columnIndex, selectedRange.end.columnIndex);

      const data = selectedRows.map(selectedRow => {
        return affectedColumns.map(column => {
          return generateCsvCellValue(
            column.original,
            selectedRow as any,
          );
        });
      });

      return {
        csv: unparse(data, { delimiter: '\t' }),
        cellCount: selectedRows.length * affectedColumns.length,
      };
    },

    importFromCsv: async (
      csvString: string,
      rows: RowData<TOriginalSubRowDataBase, TExpandableRowDataBase<TOriginalSubRowDataBase>>[],
      columns: ColumnData<EditableGridColumn>[],
      topLeftCellIndices: CellIndices,
      selectedRange: SelectedRange | null,
      maxSelectableEndIndices: CellIndices,
    ) => {
      const { data: rawData, errors } = parse(csvString, {
        delimiter: '\t',
        transform(value: string) {
          return value.trim();
        },
      });

      // abort when there has been a parsing error
      if (!isEmpty(errors)) {
        return null;
      }

      const data = expandToSelection(rawData, selectedRange);

      const dataRowCount = data.length;
      const dataColumnCount = size(data[0]);

      const startRowIndex = topLeftCellIndices.rowIndex;
      const startColumnIndex = topLeftCellIndices.columnIndex;

      const endRowIndex = startRowIndex + dataRowCount;
      const endColumnIndex = startColumnIndex + dataColumnCount;

      const currentGridRowSize = rows.length;

      const additionalRowCount = endRowIndex - currentGridRowSize;

      const pasteMethod = additionalRowCount > 0 && selectPasteMethod
        ? await selectPasteMethod(additionalRowCount)
        : GridPasteMethod.CROP;

      // abort when no paste method has been selected
      if (!pasteMethod) {
        return null;
      }

      const affectedRows = rows
        .slice(startRowIndex, endRowIndex)
        .map(row => row.original);

      if (additionalRowCount > 0 && pasteMethod === GridPasteMethod.EXTEND) {
        affectedRows.push(
          ...range(additionalRowCount).map(() =>
            createRow(rows[1]?.original as TData),
          ),
        );
      }

      const affectedColumns = columns.slice(startColumnIndex, Math.min(endColumnIndex, maxSelectableEndIndices.columnIndex));

      const {
        updatedRows,
        validCellCount,
        ignoreCroppedRows,
      } = await addCsvDataToRows({ rows: affectedRows, data, affectedColumns });

      const invalidCellRowCount = ignoreCroppedRows && pasteMethod === GridPasteMethod.CROP
        ? affectedRows.length
        : dataRowCount;

      return {
        updatedRows,
        validCellCount,
        affectedColumnCount: affectedColumns.length,
        invalidCellCount: (invalidCellRowCount * dataColumnCount) - validCellCount,
      };
    },
    createClearFieldsUpdater,
  }), [createClearFieldsUpdater, generateCsvCellValue, selectPasteMethod, addCsvDataToRows, createRow]);
};
