import { isNil, isNumber, partition, size, sumBy } from 'lodash';
import * as React from 'react';

export type EditedCell = { rowId: string; columnId: string };

export enum NavigationTarget {
  LAST_ROW_NEXT_PAGE,
  FIRST_ROW_PREVIOUS_PAGE,
}

export type ElementSize = {
  height: number;
  width: number;
};

export type CellIndices = {
  rowIndex: number;
  columnIndex: number;
};

export type NavigableRange = {
  startRowIndex: number;
  startColumnIndex: number;
  endRowIndex?: number;
  endColumnIndex?: number;
};

export type SelectableRange = {
  startColumnIndex: number;
  endColumnIndex?: number;
};

export type SelectedRange = {
  /**
   * The indices of the top left cell of the range.
   */
  start: CellIndices;
  /**
   * The indices of the bottom right cell of the range, incremented
   * by 1 on each axis.
   */
  end: CellIndices;
  /**
   * The indices of the cell that was active when creating the range.
   */
  reference: CellIndices;
};

export type TOriginalColumnDataBase = {
  _id: string;
  width?: number;
};

export type TOriginalSubRowDataBase = {
  _id: string;
  isSubHeader?: boolean;
  noPadding?: boolean;
};

export type TExpandableRowDataBase<TOriginalSubRowData extends TOriginalSubRowDataBase> = {
  _id: string;
  rowType?: string;
  inlineSubRowCount?: number;
  subRows: TOriginalSubRowData[];
};

export type ColumnData<
  TOriginalColumnData extends TOriginalColumnDataBase
> = {
  index: number;
  original: TOriginalColumnData;
  isLast?: boolean;
  width: number;
  left: number;
};

type ExpandableRowData<
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  index: number;
  original: TOriginalRowData;
  height: number;
  top: number;
  isExpanded: boolean;
};

export type SubRowData<
  TOriginalSubRowData extends TOriginalSubRowDataBase,
> = {
  index: number;
  original: TOriginalSubRowData;
  height: number;
  top: number;
  isLast: boolean;
};

export type RowData<
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> =
  | ExpandableRowData<TOriginalSubRowData, TOriginalRowData>
  | SubRowData<TOriginalSubRowData>;

export const isExpandable = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>,
>(row: SubRowData<TOriginalSubRowData> | ExpandableRowData<TOriginalSubRowData, TOriginalRowData>):
  row is ExpandableRowData<TOriginalSubRowData, TOriginalRowData> => 'subRows' in row.original;

export type GridData<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  rows: (RowData<TOriginalSubRowData, TOriginalRowData> | null)[];
  columns: ColumnData<TOriginalColumnData>[];
  /**
   * Subcolumns rendered in the content area of the grid.
   */
  contentAreaSubcolumns?: ColumnData<TOriginalColumnData>[];
  /**
   * Subcolumns rendered in the frozen left panel.
   */
  frozenLeftSubcolumns?: ColumnData<TOriginalColumnData>[];
};

export type GridDataAndCommands<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  rows: (RowData<TOriginalSubRowData, TOriginalRowData> | null)[];
  columns: ColumnData<TOriginalColumnData>[];
  activeCellIndices: CellIndices | null;
  hoverCellIndices: CellIndices | null;
  setHoverCellIndices: React.Dispatch<React.SetStateAction<CellIndices | null>>;
  selectedRange: SelectedRange | null;
  activateCellAndEnsureVisibility: (
    cellIndices: Partial<CellIndices>,
    target?: NavigationTarget | null,
    event?: React.MouseEvent<unknown, MouseEvent> | React.KeyboardEvent<unknown> | null,
    keepSelectedRange?: boolean | null,
    bottomRightCellIndices?: CellIndices | null,
  ) => void;
  toggleCollapsedRowState: (rowId: string, rowIndex: number) => void;
  staticRowHeights?: boolean;
  idPrefix: string;
  editedCell: EditedCell | null;
  onRowClick?: (row: TOriginalSubRowData | TOriginalRowData) => void;
};

export type DataCellProps<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  row: RowData<TOriginalSubRowData, TOriginalRowData>;
  column: ColumnData<TOriginalColumnData>;
  isActive: boolean;
  editedCell?: EditedCell | null;
  truncate?: boolean;
  showErrorIfInvalidRow?: boolean;
};

export type FrozenHeaderCellProps<
  TOriginalColumnData extends TOriginalColumnDataBase,
> = {
  column: ColumnData<TOriginalColumnData>;
  info?: string;
  showFieldTypeIcon?: boolean;
  truncate?: boolean;
  clamp?: boolean;
};

export type InnerElementTypeProps = React.HTMLAttributes<HTMLDivElement> & {
  style: React.CSSProperties & {
    height: string;
    width: string;
  };
};

export const checkBounds = (
  cellIndices: Partial<CellIndices>,
  navigableRange: NavigableRange,
  data: GridData<any, any, any>,
) => (
  (
    isNil(cellIndices.rowIndex) ||
    (cellIndices.rowIndex >= navigableRange.startRowIndex && cellIndices.rowIndex < (
      isNumber(navigableRange.endRowIndex) ? navigableRange.endRowIndex : size(data.rows)
    ))
  ) &&
  (
    isNil(cellIndices.columnIndex) ||
    (cellIndices.columnIndex >= navigableRange.startColumnIndex && cellIndices.columnIndex < (
      isNumber(navigableRange.endColumnIndex) ? navigableRange.endColumnIndex : size(data.columns)
    ))
  )
);

export const getGridCellId = (idPrefix: string, cellIndices: CellIndices) =>
  `${idPrefix}-${cellIndices.rowIndex}-${cellIndices.columnIndex}`;

export const getGridContainerId = (idPrefix: string) => `${idPrefix}-container`;

export const focusGrid = (idPrefix: string) => {
  const gridContainerId = getGridContainerId(idPrefix);
  const gridContainerElement = document.getElementById(gridContainerId);

  if (gridContainerElement) {
    gridContainerElement.focus();
  }
};

export const getSelectedRange = (previousCellIndices: CellIndices | null, newCellIndices: CellIndices | null) => {
  if (!previousCellIndices || !newCellIndices) {
    return null;
  }

  const start = {
    rowIndex: Math.min(previousCellIndices.rowIndex, newCellIndices.rowIndex),
    columnIndex: Math.min(previousCellIndices.columnIndex, newCellIndices.columnIndex),
  };

  const end = {
    rowIndex: Math.max(previousCellIndices.rowIndex, newCellIndices.rowIndex) + 1,
    columnIndex: Math.max(previousCellIndices.columnIndex, newCellIndices.columnIndex) + 1,
  };

  if (start.rowIndex === start.rowIndex - 1 && start.columnIndex === start.columnIndex - 1) {
    return null;
  }

  return {
    start,
    end,
  };
};

export const getMinNavigableStartIndices = (
  navigableRange: NavigableRange,
) => {
  return {
    rowIndex: navigableRange.startRowIndex,
    columnIndex: navigableRange.startColumnIndex,
  };
};

export const getMaxNavigableEndIndices = (
  rowsLength: number,
  columnsLength: number,
  navigableRange: NavigableRange,
) => {
  return {
    rowIndex: isNumber(navigableRange.endRowIndex)
      ? navigableRange.endRowIndex
      : rowsLength,
    columnIndex: isNumber(navigableRange.endColumnIndex)
      ? navigableRange.endColumnIndex
      : columnsLength,
  };
};

export const getNavigationBoundary = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>(
  data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>,
  navigableRange: NavigableRange,
) => {
  if (data.selectedRange) {
    return {
      start: data.selectedRange.start,
      end: data.selectedRange.end,
    };
  } else {
    return {
      start: {
        columnIndex: navigableRange.startColumnIndex,
        rowIndex: navigableRange.startRowIndex,
      },
      end: getMaxNavigableEndIndices(data.rows.length, data.columns.length, navigableRange),
    };
  }
};

export const getMaxSelectableEndIndices = (
  rowsLength: number,
  columnsLength: number,
  navigableRange: NavigableRange,
  selectableRange: SelectableRange,
) => {
  return {
    rowIndex: isNumber(navigableRange.endRowIndex)
      ? navigableRange.endRowIndex
      : rowsLength,
    columnIndex: isNumber(selectableRange.endColumnIndex) ? (
      selectableRange.endColumnIndex
    ) : isNumber(navigableRange.endColumnIndex) ? (
      navigableRange.endColumnIndex
    ) : (
      columnsLength
    ),
  };
};

export const useSplitColumnData = <
  TOriginalColumnData extends TOriginalColumnDataBase,
>(
  columnData: TOriginalColumnData[],
  frozenLeftColumnIds: string[] | undefined,
  defaultDataCellWidth: number,
) => {
  return React.useMemo(() => {
    const [
      frozenLeftColumnData,
      otherColumnData,
    ] = partition(columnData, column => (frozenLeftColumnIds || []).includes(column._id));

    return {
      columnData: [...frozenLeftColumnData, ...otherColumnData],
      frozenLeftColumnData,
      frozenLeftColumnWidth: sumBy(frozenLeftColumnData, column => column.width || defaultDataCellWidth),
    };
  }, [columnData, frozenLeftColumnIds, defaultDataCellWidth]);
};
