import * as React from 'react';

/**
 * Renders `Component` when the `condition` function called with the
 * `Component` properties returns true. Otherwise returns null.
 */
export const renderIf = <P extends Record<string, unknown>>(condition: (props: P) => boolean, Component: React.FC<P>) => (props: P) =>
  condition(props)
    ? <Component {...props} />
    : null;
