import * as React from 'react';
import { isFinite } from 'lodash';
import { Box, Flex, Text, FlexProps, Heading as HeadingBase, HeadingProps, TextProps, BoxProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { localeFormatFactorAsPercent, localeFormatPrice } from '@deepstream/utils';
import { diffFactor } from '@deepstream/utils/math';
import { Icon, IconSquare } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Panel, PanelProps, PanelHeading as PanelPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Spinner } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const GridCell = ({ sx, ...props }: BoxProps) => <Box sx={{ ...sx }} {...props} />;

export const Row = (props: FlexProps) => <Flex flexDirection="row" sx={{ gap: 3 }} {...props} />;
export const Column = (props: FlexProps) => <Flex flexDirection="column" sx={{ gap: 3 }} {...props} />;

export const Heading = (props: HeadingProps) => (
  <HeadingBase as="h2" fontSize={7} fontWeight={500} {...props} />
);

export const Description = (props: TextProps) => (
  <Text fontSize={4} {...props} />
);

export const PanelHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <HeadingBase as="h3" fontSize={4} fontWeight={500} {...props}>
    <Truncate>
      {children}
    </Truncate>
  </HeadingBase>
);

export const SubHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    color="gray"
    sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
    fontSize="10px"
    fontWeight={400}
    {...props}
  >
    {children}
  </Text>
);

export const CardHeading = ({
  heading,
  infoTooltip,
  ...props
}: TextProps & { heading: true | React.ReactChild | React.ReactFragment | React.ReactPortal; infoTooltip?: string }) => {
  return (
    <PanelPanelHeading
      as="h3"
      fontSize={2}
      color="subtext"
      p="20px 20px 0"
      sx={{
        textTransform: 'uppercase',
        letterSpacing: '0.083333em',
      }}
      {...props}
    >
      {heading}
      {infoTooltip && (
        <Tooltip content={infoTooltip}>
          <Icon icon="question-circle" regular color="lightGray5" ml={1} />
        </Tooltip>
      )}
    </PanelPanelHeading>
  );
};

export const Card = ({
  children,
  heading,
  infoTooltip,
  isLoading,
  isError,
  as,
  ...props
}: PanelProps & { infoTooltip?: string; isLoading?: boolean; isError?: boolean }) => {
  const { t } = useTranslation('translation');

  return (
    <Panel as="section" sx={{ height: '100%', overflowWrap: 'normal' }} {...props}>
      {isLoading ? (
        <Flex width="100%" height="100%" justifyContent="center">
          <Flex alignItems="center">
            <Spinner />
          </Flex>
        </Flex>
      ) : isError ? (
        <Flex width="100%" height="100%" justifyContent="center">
          <ErrorMessage error={t('general.couldNotGetData')} fontSize={2} />
        </Flex>
      ) : (
        <>
          {heading && <CardHeading as={as} heading={heading} infoTooltip={infoTooltip} />}
          {children}
        </>
      )}
    </Panel>
  );
};

export const LabeledValue = ({ label, small = false, labelTestAttribute = undefined, children, ...props }) => (
  <Stack gap={1} {...props}>
    <SubHeading data-test={labelTestAttribute}>
      {label}
    </SubHeading>
    <Text fontSize={small ? 4 : 6} fontWeight={500}>
      {children}
    </Text>
  </Stack>
);

export const Section = ({
  heading,
  controls,
  description,
  children,
  headingSx,
  infoTooltip,
}: {
  heading: React.ReactNode;
  controls?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  headingSx?: React.CSSProperties;
  infoTooltip?: string;
}) => {
  const headingId = useUniqueId();
  const descriptionId = useUniqueId();

  return (
    <Stack
      as="section"
      gap={3}
      aria-labelledby={headingId}
      aria-describedby={descriptionId}
      sx={{ position: 'relative' }}
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Stack gap={2} sx={headingSx}>
          <Heading id={headingId}>
            {heading}
            {infoTooltip && (
              <Tooltip content={infoTooltip}>
                <Icon
                  icon="question-circle"
                  regular
                  color="lightGray5"
                  ml={2}
                  sx={{ top: '-2px' }}
                  fontWeight={400}
                  fontSize={4}
                />
              </Tooltip>
            )}
          </Heading>
          {description && (
            <Description id={descriptionId}>
              {description}
            </Description>
          )}
        </Stack>
        {controls}
      </Flex>
      {children}
    </Stack>
  );
};

export const Value = ({
  icon,
  heading,
  headingTestAttribute = undefined,
  value,
}) => {
  const theme = useTheme();

  return (
    <Panel p="20px">
      <Flex flexDirection="column" height="100%" justifyContent="space-between">
        <Box mb={3}>
          <IconSquare
            icon={icon}
            fontSize={2}
            color={theme.colors.gray}
            size="30px"
          />
        </Box>
        <LabeledValue label={heading} labelTestAttribute={headingTestAttribute}>
          {value}
        </LabeledValue>
      </Flex>
    </Panel>
  );
};

const PriceDiff = ({
  amount,
  comparisonAmount,
  currencyCode,
  isGreenOnIncrease,
  small,
}: {
  amount: number;
  comparisonAmount: number;
  currencyCode: string;
  /**
   * Indicates whether a green color is shown when the amount
   * is larger than the comparison amount (and red, when smaller).
   *
   * Should be `true` in scenarios where an increase of the amount
   * is considered something posivite (like with savings),
   * and falsy where an increase is considered negative (like with
   * spendings).
   */
  isGreenOnIncrease?: boolean;
  small?: boolean;
}) => {
  const locale = useCurrentUserLocale();
  const factor = diffFactor(comparisonAmount, amount);

  return (
    <Flex
      alignItems="center"
      color={factor > 0 ? (
        isGreenOnIncrease ? 'success' : 'danger'
      ) : factor < 0 ? (
        isGreenOnIncrease ? 'danger' : 'success'
      ) : (
        'subtext'
      )}
      ml={small ? '20px' : 4}
      mt="3px"
    >
      <Icon
        fontSize={small ? 3 : 6}
        fontWeight={400}
        icon={factor > 0 ? (
          'arrow-up'
        ) : factor < 0 ? (
          'arrow-down'
        ) : (
          'minus'
        )}
        mr={small ? 1 : 2}
      />
      <Text lineHeight={1.1} fontSize={small ? '10px' : undefined}>
        {localeFormatFactorAsPercent(Math.abs(factor), { locale, decimalPlaces: 1 })}
        <br />
        {localeFormatPrice(Math.abs(amount - comparisonAmount), currencyCode, { locale, notation: 'compact' })}
      </Text>
    </Flex>
  );
};

export const Price = ({
  amount,
  comparisonAmount,
  currencyCode,
  isGreenOnIncrease,
  small,
}: {
  amount?: number;
  comparisonAmount?: number;
  currencyCode: string;
  /**
   * Indicates whether a green color is shown when the amount
   * is larger than the comparison amount (and red, when smaller).
   *
   * Should be `true` in scenarios where a decrease of the amount
   * is considered something posivite (like with spend amounts),
   * and falsy where a decrease is considered negative (like with
   * savings).
   */
  isGreenOnIncrease?: boolean;
  small?: boolean;
}) => {
  const locale = useCurrentUserLocale();
  const { t } = useTranslation('translation');

  return (
    <Flex>
      <Text fontWeight={500} fontSize={small ? '28px' : 8}>
        {isFinite(amount) ? (
          // @ts-ignore ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
          localeFormatPrice(amount, currencyCode, { locale, notation: 'compact' })
        ) : (
          t('general.notApplicableShort')
        )}
      </Text>
      {isFinite(amount) && isFinite(comparisonAmount) && (
        <PriceDiff
          // @ts-ignore ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          amount={amount}
          // @ts-ignore ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          comparisonAmount={comparisonAmount}
          currencyCode={currencyCode}
          isGreenOnIncrease={isGreenOnIncrease}
          small={small}
        />
      )}
    </Flex>
  );
};
