import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ProgressCircle } from '@deepstream/ui-kit/elements/ProgressCircle';

export const StepProgress = ({
  totalStepCount,
  currentStepIndex,
  currentStepName,
}: {
  totalStepCount: number;
  currentStepIndex: number;
  currentStepName?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center">
      {totalStepCount && (
        <ProgressCircle
          width={24}
          borderWidth={3}
          progress={(currentStepIndex + 1) / totalStepCount}
        />
      )}
      <Box ml={2}>
        <Text fontSize={2} fontWeight={500}>
          {currentStepName}
        </Text>
        <Text fontSize={1} color="subtext">
          {t('general.countOfTotal', { count: currentStepIndex + 1, total: totalStepCount })}
        </Text>
      </Box>
    </Flex>
  );
};
