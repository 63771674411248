import { ExchangeRateDocument } from './rfq-utils';

export const convertCurrencyAmount = (
  amount: number,
  sourceCurrency: string,
  targetCurrency: string,
  exchangeRates: ExchangeRateDocument['rates'],
) => {
  const amountInDollars = amount / exchangeRates[sourceCurrency];
  const amountInTarget = amountInDollars * exchangeRates[targetCurrency];
  return amountInTarget;
};

export type CurrencyConverter = ({ value, currencyCode }: { value: number; currencyCode: string }) =>
  ({ value: number; currencyCode: string });

export const createCurrencyConverter = (
  targetCurrency: string | undefined,
  exchangeRates: ExchangeRateDocument | undefined,
): CurrencyConverter =>
  ({ value, currencyCode }) => {
    const canConvert = (exchangeRates && targetCurrency && (targetCurrency !== currencyCode));

    if (canConvert) {
      const convertedValue = convertCurrencyAmount(value, currencyCode, targetCurrency, exchangeRates.rates);

      return {
        value: convertedValue,
        currencyCode: targetCurrency,
      };
    } else {
      return { value, currencyCode };
    }
  };
