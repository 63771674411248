import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonVariant } from '@deepstream/ui-kit/theme/types';

export const ExpandViewButton = ({
  disabled,
  isExpandedView,
  setIsExpandedView,
  shrinkedVariant = 'secondary-outline',
  ...props
}: ButtonProps & {
  disabled?: boolean;
  isExpandedView: boolean;
  shrinkedVariant?: ButtonVariant;
  setIsExpandedView: (isExpandedView: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      small
      variant={isExpandedView ? 'primary' : shrinkedVariant}
      iconLeft={isExpandedView ? 'compress' : 'expand'}
      disabled={disabled}
      onClick={() => setIsExpandedView(!isExpandedView)}
      {...props}
    >
      {isExpandedView ? (
        t('request.comparison.shrinkView')
      ) : (
        t('request.comparison.expandView')
      )}
    </Button>
  );
};
