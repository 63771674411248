import * as React from 'react';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { PanelDivider, PanelPadding, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useTranslation } from 'react-i18next';

const BooleanIcon: React.FC<{ value: boolean }> = ({ value }) => (
  <>
    {value ? (
      <IconText gap={2} icon="check" text="Enabled" />
    ) : (
      <IconText gap={2} icon="times" text="Disabled" />
    )}
  </>
);

const GlobalRole = ({ value }) => {
  const { t } = useTranslation();

  return value ? (
    <>
      {t(`user.globalRole.${value}`)}
    </>
  ) : (
    <EmDash />
  );
};

export const PermissionsPropertyList: React.FC<{ company: any; user: any }> = ({ company, user }) => {
  const globalRole = user.roleRestrictionProfiles?.[company._id];
  const {
    admin,
    editor,
    managePreQual,
    sendQuestionnaires,
    receiveQuestionnaires,
    sendRFQ,
    receiveRFQ,
    receiveContracts,
    sendContracts,
    accessReportingForRequests,
    accessReportingForContracts,
  } = user.roles[company._id];

  return (
    <>
      <PropertyList
        properties={[
          { name: 'User role', value: globalRole, Component: GlobalRole },
        ]}
      />
      <PanelDivider />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>General</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList
        properties={[
          { name: 'Edit team members', value: admin, Component: BooleanIcon },
          { name: 'Edit company profile', value: editor, Component: BooleanIcon },
        ]}
      />
      <PanelDivider />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>Pre-qualification</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList
        properties={[
          { name: 'Create & manage questions and questionnaires', value: managePreQual, Component: BooleanIcon },
          { name: 'Send questionnaires', value: sendQuestionnaires, Component: BooleanIcon },
          { name: 'Receive questionnaires', value: receiveQuestionnaires, Component: BooleanIcon },
        ]}
      />
      <PanelDivider />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>Requests</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList
        properties={[
          { name: 'Send requests', value: sendRFQ, Component: BooleanIcon },
          { name: 'Receive requests', value: receiveRFQ, Component: BooleanIcon },
        ]}
      />
      <PanelDivider />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>Contracts</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList
        properties={[
          { name: 'Send contracts', value: sendContracts, Component: BooleanIcon },
          { name: 'Receive contracts', value: receiveContracts, Component: BooleanIcon },
        ]}
      />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>Reporting</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList
        properties={[
          { name: 'Access reporting for Requests', value: accessReportingForRequests, Component: BooleanIcon },
          { name: 'Access reporting for Contracts', value: accessReportingForContracts, Component: BooleanIcon },
        ]}
      />
    </>
  );
};
