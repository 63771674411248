import { useCallback, useMemo } from 'react';
import {
  clone,
  cloneDeep,
  flatMap,
  map,
  omit,
  pick,
  reject,
  without,
  values,
  find,
  compact,
  dropWhile,
  isEmpty,
  last,
  isFunction,
  filter,
  mapValues,
  groupBy,
  every,
  omitBy,
  isEqual,
  isUndefined,
  intersection,
  difference,
} from 'lodash';
import { useQueryClient, useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import {
  Section,
  ChangeType,
  RfqEventChange,
  ExchangeDefinition,
  SectionType,
  ExchangeChange,
  SectionAddedChange,
  ExchangeType,
  ExchangeProvider,
  PageType,
  TeamMemberRolesUpdatedChange,
  ExchangeAddedChange,
  FieldUpdatedChange,
  HirePeriod,
  HirePeriodChange,
  Draft,
  StageType,
  LineItemExchangeFields,
  isLineItemExchangeDef,
  LineItemExchangeDefinition,
  Page,
  FieldConfig,
  isLinkedEvaluationPage,
  isDefinitionField,
  EvaluationPage,
  isLinkedEvaluationSection,
  SectionChange,
  RfxSection,
  SectionRemovedChange,
  PublishableSettings,
  LotChange,
} from '@deepstream/common/rfq-utils';
import { diffArrayBy } from '@deepstream/utils';
import { swap } from '@deepstream/utils/swap';
import { useRfqId, useDraftRfqStructureQueryKey } from '../useRfq';
import { useApi } from '../api';
import { mapDiffToChanges, mappings } from '../ui/diff';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useToaster } from '../toast';
import { sanitizePublishableEntity } from '../utils';
import * as rfx from '../rfx';
import { useMutation } from '../useMutation';
import { DEFAULT_CURRENCY } from '../ui/currencies';
import { RfxStructure, StructureStage } from '../types';
import { getStageAddedRelatedChanges } from './stageUtils';
import { createEmptyDocument, createLineItem, createQuestion } from './exchangeDefs';
import { useWaitForRfqUnlock } from '../useWaitForUnlock';

/*
 * Hooks
 */

export const useIsMutationLoading = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate } = rfx.useState();
  const rfqId = useRfqId();
  const queryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const mutationCount = useIsMutating({ mutationKey: queryKey });

  return mutationCount !== 0;
};

export const useIsEditing = () => {
  const section = rfx.useSection();
  const { editingPanelId } = rfx.useState();
  return section._id === editingPanelId;
};

/*
 * Mutations
 */

/**
 * Base mutation for updating draft request
 */
export const useSaveDraftChanges = <TGetChanges extends (args: any) => RfqEventChange[]> ({
  getChanges,
  onError,
  onSuccess,
}: {
  getChanges: TGetChanges;
  onError: any;
  onSuccess: any;
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate, isRevising } = rfx.useState();
  const rfqId = useRfqId();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const mutation = useMutation(
    (payload: Parameters<TGetChanges>[0]) => waitForRfqUnlock({
      isTemplate,
      command: () => api.updateDraftRequest({
        currentCompanyId,
        rfqId,
        changes: getChanges(payload),
        isTemplate,
        isRevising,
      }),
    }),
    {
      mutationKey: draftStructureQueryKey,
      onError,
      onSuccess,
      onSettled: () => queryClient.invalidateQueries(draftStructureQueryKey),
    },
  );

  return mutation;
};

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

export const getChanges = <TChange extends RfqEventChange> ({ next, previous, keys, mapping }): TChange[] =>
  mapDiffToChanges(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    mappings[mapping],
  ) as any;

type SectionState = {
  section: RfxSection;
  exchangeDefs: ExchangeDefinition[];
  hirePeriods?: HirePeriod[];
  extraChanges?: RfqEventChange[];
};

type SaveSectionConfig = {
  sectionKeys: string[] | ((next: SectionState) => string[]);
  exchangeDefKeys: string[] | ((next: SectionState, previous: SectionState) => string[]);
  hirePeriodKeys?: string[];
};

const getSaveSectionChanges = (
  {
    sectionKeys,
    exchangeDefKeys,
    hirePeriodKeys,
  }: SaveSectionConfig,
  previous: SectionState,
  next: SectionState,
) => {
  return [
    ...getChanges<SectionChange>({
      mapping: 'section',
      next: [next.section],
      previous: [previous.section],
      keys: isFunction(sectionKeys) ? sectionKeys(next) : sectionKeys,
    }),
    ...(
      hirePeriodKeys && next.hirePeriods ? (
        getChanges<HirePeriodChange>({
          mapping: 'hirePeriod',
          next: next.hirePeriods,
          previous: previous.hirePeriods,
          keys: hirePeriodKeys,
        }).map(change => {
          change.sectionName = next.section._id;
          return change;
        })
      ) : (
        []
      )
    ),
    ...getChanges<ExchangeChange>({
      mapping: 'exchangeDef',
      next: next.exchangeDefs,
      previous: previous.exchangeDefs,
      keys: isFunction(exchangeDefKeys) ? exchangeDefKeys(next, previous) : exchangeDefKeys,
    }).map(change => {
      change.sectionName = next.section._id;
      return change;
    }),
    ...(next.extraChanges || []),
  ];
};

const createUseSaveSection = (saveSectionConfig: SaveSectionConfig) => () => {
  const { t } = useTranslation();
  const section = rfx.useSection();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const { hirePeriodById } = rfx.useStructure();
  const toaster = useToaster();

  const getChanges = useCallback((next: SectionState) => {
    const previous = {
      section,
      exchangeDefs,
      hirePeriods: values(hirePeriodById),
    } as SectionState;

    return getSaveSectionChanges(saveSectionConfig, previous, next);
  }, [exchangeDefs, hirePeriodById, section]);

  return useSaveDraftChanges({
    getChanges,
    onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
    onError: () => toaster.error(t('request.toaster.changesSavedError')),
  });
};

export const auctionLineItemSectionKeys = ['_id', 'type', 'stages', 'auctionRules', 'lotIds'];

export const auctionTermExchangeDefKeys = ['_id', 'type', 'description', 'text', 'stages'];

export const lineItemSectionKeys =
  ['_id', 'type', 'name', 'description', 'stages', 'locking', 'providedBy', 'isObsolete', 'responseTagConfig', 'lotIds'];

export const lineItemExchangeBaseDefKeys = ['_id', 'type', 'isObsolete', 'currencies', 'stages', 'totalCost', 'fields', 'isFixed'];

export const auctionLineItemExchangeDefKeys =
  ['_id', 'type', 'description', 'quantity', 'fields', 'unit', 'isObsolete', 'currencies', 'stages', 'linkedExchangeDefId'];

export const lotKeys = ['_id', 'name', 'description', 'isObsolete'];

export const getLineItemFieldValueKeys = (exchangeDefs) => {
  const lineItemFields: FieldConfig[] = find(
    exchangeDefs,
    (exchangeDef) => exchangeDef.type === ExchangeType.LINE_ITEM,
  )?.fields || {};

  const definitionLineItemFields = filter(
    lineItemFields,
    isDefinitionField,
  );

  return map(
    definitionLineItemFields,
    (field) => field.source.key,
  );
};

const saveSectionConfigByType: Record<string, SaveSectionConfig> = {
  [SectionType.LINE_ITEMS]: {
    sectionKeys: lineItemSectionKeys,
    exchangeDefKeys: (next: SectionState, previous: SectionState) => compact([
      ...lineItemExchangeBaseDefKeys,
      // We're including both next and previous field value keys in order to
      // forward `null` values that correspond to deleted fields. Without these
      // `null` value, the rfx machine would preserve the value of the deleted
      // field.
      ...getLineItemFieldValueKeys(next.exchangeDefs),
      ...getLineItemFieldValueKeys(previous.exchangeDefs),
    ]),
  },
  [SectionType.EVALUATION]: {
    sectionKeys: ['_id', 'type', 'name', 'description', 'weight', 'isObsolete', 'lotIds'],
    exchangeDefKeys: [
      '_id',
      'type',
      'description',
      'maxPoints',
      'weight',
      'isObsolete',
      'fields',
    ],
  },
  [SectionType.DOCUMENT]: {
    sectionKeys: ['_id', 'type', 'name', 'description', 'stages', 'locking', 'providedBy', 'isObsolete', 'lotIds'],
    exchangeDefKeys: ['_id', 'type', 'supertype', 'category', 'attachments', 'stages', 'isObsolete', 'locking'],
  },
  [SectionType.VESSEL_PRICING]: {
    sectionKeys: [
      '_id', 'type', 'name', 'description', 'stages', 'locking', 'hirePeriodIds', 'providedBy', 'isObsolete',
      'allowSuppliersToAddAdditionalTerms', 'allowSuppliersToAddFees', 'allowSuppliersToAddInclusionsAndExclusions',
      'lotIds',
    ],
    exchangeDefKeys: [
      '_id', 'type', 'description', 'option', 'isObsolete', 'currencies', 'stages', 'feeType',
      'intervalType', 'amount', 'unit', 'quantity', 'hirePeriodId',
    ],
    hirePeriodKeys: ['_id', 'name', 'fromDate', 'toDate', 'isObsolete'],
  },
  [SectionType.QUESTION]: {
    sectionKeys: ['_id', 'type', 'name', 'description', 'stages', 'locking', 'isObsolete', 'lotIds'],
    exchangeDefKeys: [
      '_id', 'type', 'description', 'questionType', 'options', 'isRequired', 'allowCustomOption',
      'isObsolete', 'stages', 'visibleFields', 'schema', 'format', 'currencies',
    ],
  },
};

export const useSaveLineItemsSection = createUseSaveSection(saveSectionConfigByType[SectionType.LINE_ITEMS]);

export const useSaveEvaluationSection = createUseSaveSection(saveSectionConfigByType[SectionType.EVALUATION]);

export const useSaveDocumentsSection = createUseSaveSection(saveSectionConfigByType[SectionType.DOCUMENT]);

export const useSaveVesselPricingSection = createUseSaveSection(saveSectionConfigByType[SectionType.VESSEL_PRICING]);

export const useSaveQuestionSection = createUseSaveSection(saveSectionConfigByType[SectionType.QUESTION]);

const getNewSectionStageData = (rfxStructure: RfxStructure<Draft>): {
  stageChanges: RfqEventChange[];
  stageIds: string[];
} => {
  const { stages } = rfxStructure;

  const filteredStages = dropWhile(
    stages,
    stage => stage.type === StageType.AUCTION,
  );

  if (isEmpty(filteredStages)) {
    // there's always at least one stage, but if the only existing stage is
    // an auction stage, we need to add an exchange stage for the new section
    const newStageId = uuid();

    const newStage: StructureStage<Draft> = {
      _id: newStageId,
      type: StageType.GENERAL,
      name: '',
      intentionDeadline: null,
      completionDeadline: null,
      isPrivate: last(stages)?.isPrivate,
    };

    const relatedChanges = getStageAddedRelatedChanges(newStage._id, rfxStructure);

    return {
      stageChanges: [
        {
          type: ChangeType.STAGE_ADDED,
          stage: newStage,
        },
        ...relatedChanges,
      ],
      stageIds: [newStageId],
    };
  } else {
    // when there are exchange stages, we add the
    // new section to the first exchange stage
    return {
      stageChanges: [],
      stageIds: filteredStages.map(stage => stage._id),
    };
  }
};

export const useAddLineItemsSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();
  const structure = rfx.useStructure<Draft>();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid() }: { _id?: string }) => {
      const { stageIds, stageChanges } = getNewSectionStageData(structure);

      return [...stageChanges, {
        type: ChangeType.PAGE_UPDATED,
        page: {
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          _id: page._id,
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          sections: [...page.sections, _id],
        },
      }, {
        type: ChangeType.SECTION_ADDED,
        section: {
          type: SectionType.LINE_ITEMS,
          _id,
          name: '',
          providedBy: ExchangeProvider.BUYER,
          stages: stageIds,
          docXDefs: [],
        },
      }, {
        // NB This change is redundant, but we are keeping it for feature parity
        // (see https://github.com/deepstreamtech/admin-client/pull/452#discussion_r690388251)
        type: ChangeType.FIELD_UPDATED,
        sectionName: _id,
        fieldName: 'providedBy',
        value: ExchangeProvider.BUYER,
      }, {
        type: ChangeType.EXCHANGE_ADDED,
        sectionName: _id,
        docXDef: {
          _id: uuid(),
          type: ExchangeType.CURRENCY,
          currencies: [DEFAULT_CURRENCY],
          isFixed: true,
          stages: stageIds,
        },
      }, {
        type: ChangeType.EXCHANGE_ADDED,
        sectionName: _id,
        docXDef: {
          ...createLineItem(),
          stages: stageIds,
        },
      }];
    },
    onSuccess: () => toaster.success(t('request.toaster.sectionAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionAddedError')),
  });
};

export const useAddDocumentsSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();
  const structure = rfx.useStructure<Draft>();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid() }: { _id?: string }) => {
      const { stageIds, stageChanges } = getNewSectionStageData(structure);

      return [...stageChanges, {
        type: ChangeType.PAGE_UPDATED,
        page: {
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          _id: page._id,
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          sections: [...page.sections, _id],
        },
      }, {
        type: ChangeType.SECTION_ADDED,
        section: {
          type: SectionType.DOCUMENT,
          _id,
          name: '',
          providedBy: ExchangeProvider.BUYER,
          stages: stageIds,
          docXDefs: [],
        },
      }, {
        // NB This change is redundant, but we are keeping it for feature parity
        // (see https://github.com/deepstreamtech/admin-client/pull/452#discussion_r690388251)
        type: ChangeType.FIELD_UPDATED,
        sectionName: _id,
        fieldName: 'providedBy',
        value: ExchangeProvider.BUYER,
      }, {
        type: ChangeType.EXCHANGE_ADDED,
        sectionName: _id,
        docXDef: createEmptyDocument(stageIds),
      } as ExchangeAddedChange];
    },
    onSuccess: () => toaster.success(t('request.toaster.sectionAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionAddedError')),
  });
};

export const useAddVesselPricingSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();
  const structure = rfx.useStructure<Draft>();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid() }: { _id?: string }) => {
      const { stageIds, stageChanges } = getNewSectionStageData(structure);

      return [...stageChanges, {
        type: ChangeType.PAGE_UPDATED,
        page: {
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          _id: page._id,
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          sections: [...page.sections, _id],
        },
      }, {
        type: ChangeType.SECTION_ADDED,
        section: {
          type: SectionType.VESSEL_PRICING,
          _id,
          name: '',
          providedBy: ExchangeProvider.BUYER,
          stages: stageIds,
          docXDefs: [],
          allowSuppliersToAddAdditionalTerms: false,
          allowSuppliersToAddFees: false,
          allowSuppliersToAddInclusionsAndExclusions: false,
        },
      }, {
        // NB This change is redundant, but we are keeping it for feature parity
        // (see https://github.com/deepstreamtech/admin-client/pull/452#discussion_r690388251)
        type: ChangeType.FIELD_UPDATED,
        sectionName: _id,
        fieldName: 'providedBy',
        value: ExchangeProvider.BUYER,
      }, {
        type: ChangeType.EXCHANGE_ADDED,
        sectionName: _id,
        docXDef: {
          _id: uuid(),
          type: ExchangeType.CURRENCY,
          currencies: [DEFAULT_CURRENCY],
          isFixed: true,
          stages: stageIds,
        },
      }, {
        type: ChangeType.HIRE_PERIOD_ADDED,
        sectionName: _id,
        hirePeriod: {
          _id: uuid(),
          name: t('request.vesselPricing.hirePeriods.periodOne'),
          fromDate: '',
          toDate: '',
          isObsolete: false,
        },
      }];
    },
    onSuccess: () => toaster.success(t('request.toaster.sectionAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionAddedError')),
  });
};

export const useAddEvaluationSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();

  return useSaveDraftChanges({
    getChanges: ({
      _id = uuid(),
      name,
      weight,
      linkedSectionId,
    }: { _id?: string; name: string; weight: number; linkedSectionId?: string; }) => [{
      type: ChangeType.SECTION_ADDED,
      section: {
        type: SectionType.EVALUATION,
        _id,
        name,
        weight,
        linkedSectionId,
        docXDefs: [],
      },
    }, {
      type: ChangeType.PAGE_UPDATED,
      page: {
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        _id: page._id,
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        sections: [...page.sections, _id],
      },
    }],
    onSuccess: () => toaster.success(t('request.toaster.sectionAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionAddedError')),
  });
};

export const useAddEvaluationSections = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();

  return useSaveDraftChanges({
    getChanges: (
      (sections: { _id: string; name: string; weight: number; linkedSectionId?: string; }[]) =>
        [
          ...sections.map(
            ({ _id, name, weight, linkedSectionId }) => ({
              type: ChangeType.SECTION_ADDED,
              section: {
                type: SectionType.EVALUATION,
                _id,
                name,
                weight,
                linkedSectionId,
                docXDefs: [],
              },
            }),
          ) as SectionAddedChange[],
          {
            type: ChangeType.PAGE_UPDATED,
            page: {
              // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
              _id: page._id,
              // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
              sections: [...page.sections, ...sections.map(({ _id }) => _id)],
            },
          },
      ]
    ),
    onSuccess: () => toaster.success(t('request.toaster.linkedSectionsAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.linkedSectionsAddedError')),
  });
};

export const useAddQuestionSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();
  const structure = rfx.useStructure<Draft>();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid() }: { _id?: string }) => {
      const { stageIds, stageChanges } = getNewSectionStageData(structure);

      return [...stageChanges, {
        type: ChangeType.PAGE_UPDATED,
        page: {
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          _id: page._id,
          // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
          sections: [...page.sections, _id],
        },
      }, {
        type: ChangeType.SECTION_ADDED,
        section: {
          type: SectionType.QUESTION,
          _id,
          name: '',
          providedBy: ExchangeProvider.BUYER,
          stages: stageIds,
          docXDefs: [],
        },
      }, {
        type: ChangeType.EXCHANGE_ADDED,
        sectionName: _id,
        docXDef: {
          ...createQuestion(),
          stages: stageIds,
        },
      }];
    },
    onSuccess: () => toaster.success(t('request.toaster.sectionAddedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionAddedError')),
  });
};

export const useAddPage = () => {
  const { t } = useTranslation();
  const toaster = useToaster();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid(), name, type }: { _id?: string; name: string; type?: PageType }) => [{
      type: ChangeType.PAGE_ADDED,
      page: {
        _id,
        name,
        type,
        sections: [],
      },
    }],
    onSuccess: () => toaster.success(t('request.toaster.pageAddedSuccess', { count: 1 })),
    onError: () => toaster.error(t('request.toaster.pageAddedError', { count: 1 })),
  });
};

export const useAddLinkedEvaluationPages = () => {
  const { t } = useTranslation();
  const toaster = useToaster();

  return useSaveDraftChanges({
    getChanges: ({ pages }: { pages: { _id: string; linkedPageId: string }[] }) => {
      // @ts-ignore ts(2352) FIXME: Conversion of type '{ type: ChangeType.PAGE_ADDED; page: { _id: string; type: PageType.EVALUATION; linkedPageId: string; weight: number; sections: never[]; }; }[]' to type 'RfqEventChange[]' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
      return pages.flatMap(({ _id, linkedPageId }) => {
        return [{
          type: ChangeType.PAGE_ADDED,
          page: {
            _id,
            type: PageType.EVALUATION,
            linkedPageId,
            weight: 1,
            sections: [],
          },
        }];
      }) as RfqEventChange[];
    },
    onSuccess: () => toaster.success(t('request.toaster.pageAddedSuccess_other')),
    onError: () => toaster.error(t('request.toaster.pageAddedError_other')),
  });
};

export const useRenamePage = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { pageById } = rfx.useStructure();

  return useSaveDraftChanges({
    getChanges: ({ _id, name }: { _id: string; name: string }) => [{
      type: ChangeType.PAGE_UPDATED,
      page: {
        ...sanitizePublishableEntity(pageById[_id]),
        name,
      } as Page,
    }],
    onSuccess: () => toaster.success(t('request.toaster.pageRenamedSuccess')),
    onError: () => toaster.error(t('request.toaster.pageRenamedError')),
  });
};

export const useUpdatePagesWeight = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { pageById } = rfx.useStructure();

  return useSaveDraftChanges({
    getChanges: (pages: EvaluationPage[]) => pages.map(page => ({
      type: ChangeType.PAGE_UPDATED,
      page: {
        ...sanitizePublishableEntity(pageById[page._id]),
        weight: page.weight,
      } as Page,
    })),
    onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
    onError: () => toaster.error(t('request.toaster.changesSavedError')),
  });
};

export const useRemovePage = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { pageById } = rfx.useStructure();

  return useSaveDraftChanges({
    getChanges: ({ pageId }: { pageId: string }) => [{
      type: ChangeType.PAGE_REMOVED,
      pageId,
    },
    ...pageById[pageId].sections.map(sectionId => ({
      type: ChangeType.SECTION_REMOVED as ChangeType.SECTION_REMOVED,
      sectionId,
    }))],
    onSuccess: () => toaster.success(t('request.toaster.pageRemovedSuccess')),
    onError: () => toaster.error(t('request.toaster.pageRemovedError')),
  });
};

export const useReorderPages = () => {
  const { t } = useTranslation();
  const toaster = useToaster();

  return useSaveDraftChanges({
    getChanges: (pageIds: string[]) => [{
      type: ChangeType.PAGES_REORDERED,
      pageIds,
    }],
    onSuccess: () => toaster.success(t('request.toaster.pagesReorderedSuccess')),
    onError: () => toaster.error(t('request.toaster.pagesReorderedError')),
  });
};

/**
 * Only availably in drafting and templates, not in live requests.
 */
export const useDuplicatePage = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { senders, pages, pageById, teamById, sectionById, exchangeDefById } = rfx.useStructure();

  return useSaveDraftChanges({
    getChanges: ({ _id = uuid(), name, sourcePageId }: { _id?: string; name: string; sourcePageId: string }) => {
      const pageIds = map(pages, '_id');
      const sourcePage = pageById[sourcePageId];

      const sourcePageIndex = pageIds.indexOf(sourcePageId);
      const newPageIds = clone(pageIds);

      // Reorder pages so that the duplicated page is next to the source page
      newPageIds.splice(sourcePageIndex + 1, 0, _id);

      const sourcePageSections = reject(
        map(
          sourcePage.sections,
          sectionId => sectionById[sectionId],
        ),
        { type: SectionType.VESSEL_PRICING },
      );

      const newSections = map(
        sourcePageSections,
        section => ({
          ...omit(section, ['isLive', 'liveVersion', 'exchangeDefIds', 'creatorId']),
          _id: uuid(),
        } as Section),
      );

      return [
        {
          type: ChangeType.PAGE_ADDED,
          page: {
            _id,
            name,
            sections: map(newSections, '_id'),
          },
        }, {
          type: ChangeType.PAGES_REORDERED,
          pageIds: newPageIds,
        },
        ...flatMap(
          senders,
          sender => map(
            teamById[sender._id].users,
            user => ({
              type: ChangeType.TEAM_MEMBER_ROLES_UPDATED,
              userId: user._id,
              companyId: sender._id,
              rfqRoles: {
                ...user.rfqRoles,
                [_id]: user.rfqRoles![sourcePageId],
              },
            } as TeamMemberRolesUpdatedChange)),
        ),
        ...flatMap(
          newSections,
          section => [
            {
              type: ChangeType.SECTION_ADDED,
              section,
            } as SectionAddedChange,
            {
              // NB This change is redundant, but we are keeping it for feature parity
              // (see https://github.com/deepstreamtech/admin-client/pull/452#discussion_r690388251)
              type: ChangeType.FIELD_UPDATED,
              sectionName: section._id,
              fieldName: 'providedBy',
              value: section.providedBy,
            } as FieldUpdatedChange,
          ],
        ),
        ...flatMap(
          sourcePageSections,
          (section, index) => map(
            section.exchangeDefIds,
            exchangeDefId => ({
              type: ChangeType.EXCHANGE_ADDED,
              sectionName: newSections[index]._id,
              docXDef: {
                ...omit(exchangeDefById[exchangeDefId], ['isLive', 'liveVersion', 'creatorId']),
                sectionId: newSections[index]._id,
                _id: uuid(),
              },
            } as ExchangeAddedChange),
          ),
        ),
      ];
    },
    onSuccess: () => toaster.success(t('request.toaster.pageAddedSuccess', { count: 1 })),
    onError: () => toaster.error(t('request.toaster.pageAddedError', { count: 1 })),
  });
};

export const useDuplicateSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { pages, sectionById, exchangeDefById } = rfx.useStructure();

  return useSaveDraftChanges({
    getChanges: ({ sourceSectionId }: { _id?: string; sourceSectionId: string }) => {
      const page = find(pages, page => page.sections.includes(sourceSectionId));
      const sourceSection = sectionById[sourceSectionId];

      if (!page) {
        throw new Error('Cannot duplicate an orphan section');
      }

      const newSection = {
        ...omit(sourceSection, ['isLive', 'liveVersion', 'exchangeDefIds', 'creatorId', 'lotIds', 'isObsolete']),
        name: `${t('general.copyOf')} ${sourceSection.name}`,
        _id: uuid(),
      } as Section;

      const sectionIds = page.sections;
      const sourceSectionIndex = sectionIds.indexOf(sourceSectionId);
      const newSectionIds = clone(sectionIds);

      // Reorder sections so that the duplicated section is next to the source section
      newSectionIds.splice(sourceSectionIndex + 1, 0, newSection._id);

      return [
        {
          type: ChangeType.SECTION_ADDED,
          section: newSection,
        } as SectionAddedChange,
        {
          // NB This change is redundant, but we are keeping it for feature parity
          // (see https://github.com/deepstreamtech/admin-client/pull/452#discussion_r690388251)
          type: ChangeType.FIELD_UPDATED,
          sectionName: newSection._id,
          fieldName: 'providedBy',
          value: newSection.providedBy,
        } as FieldUpdatedChange,
        ...compact(map(
          sourceSection.exchangeDefIds,
          exchangeDefId => (
            exchangeDefById[exchangeDefId]?.type === ExchangeType.CURRENCY ||
            // include obsolete exchangeDefs when the section is obsolete
            sourceSection.isObsolete ||
            !exchangeDefById[exchangeDefId].isObsolete
          )
            ? {
              type: ChangeType.EXCHANGE_ADDED,
              sectionName: newSection._id,
              docXDef: {
                ...omit(exchangeDefById[exchangeDefId], ['isLive', 'liveVersion', 'creatorId', 'isObsolete']),
                sectionId: newSection._id,
                _id: uuid(),
              },
            } as ExchangeAddedChange
            : null,
        )),
        {
          type: ChangeType.PAGE_UPDATED,
          page: {
            _id: page._id,
            sections: newSectionIds,
          },
        },
      ];
    },
    onSuccess: () => toaster.success(t('request.toaster.sectionDuplicatedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionDuplicatedError')),
  });
};

export const useUpdateSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();

  return useSaveDraftChanges({
    getChanges: (section: Section) => [{ type: ChangeType.SECTION_UPDATED, section }],
    onSuccess: () => toaster.success(t('request.toaster.sectionUpdatedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionUpdatedError')),
  });
};

export const useRemoveSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();

  return useSaveDraftChanges({
    getChanges: (sectionId: Section['_id']) => [{
      type: ChangeType.PAGE_UPDATED,
      page: {
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        _id: page._id,
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        sections: without(page.sections, sectionId),
      },
    }, {
      type: ChangeType.SECTION_REMOVED,
      sectionId,
    }],
    onSuccess: () => toaster.success(t('request.toaster.sectionRemovedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionRemovedError')),
  });
};

export const useMoveSection = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const page = rfx.usePage();
  const section = rfx.useSectionWithPosition();

  return useSaveDraftChanges({
    getChanges: (delta: number): RfqEventChange[] => [{
      type: ChangeType.PAGE_UPDATED,
      page: {
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        _id: page._id,
        // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
        sections: swap(page.sections, section.index, section.index + delta),
      },
    }],
    onSuccess: () => toaster.success(t('request.toaster.sectionMovedSuccess')),
    onError: () => toaster.error(t('request.toaster.sectionMovedError')),
  });
};

export const useGeneralDraftPages = () => {
  const rfxStructure = rfx.useStructure();

  return useMemo(
    () => reject(
      rfxStructure.pages,
      page => (
        page.isHiddenWhileEditing ||
        page.type === PageType.EVALUATION ||
        page.type === PageType.AUCTION
      ),
    ),
    [rfxStructure],
  );
};

export const useLinkedSection = () => {
  const rfxStructure = rfx.useStructure();
  const section = rfx.useSection<Section>();

  return useMemo(
    () => section && isLinkedEvaluationSection(section)
      ? rfxStructure.sectionById[section.linkedSectionId]
      : null,
    [rfxStructure, section],
  );
};

export const useLinkedSectionTarget = () => {
  const rfxStructure = rfx.useStructure();
  const section = rfx.useSection<Section>();
  const isLinkedSection = isLinkedEvaluationSection(section);
  const targetSectionId = isLinkedSection ? section.linkedSectionId : find(
    Object.values(rfxStructure.sectionById),
    ['linkedSectionId', section._id],
  )?._id;
  const page = rfx.usePage();
  // @ts-ignore ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type '{ type?: PageType | undefined; linkedPageId?: string | undefined; }'.
  const isLinkedPage = isLinkedEvaluationPage(page);
  const linkedSectionTab = isLinkedSection ? 'details' : 'evaluation';
  const targetPageId = isLinkedPage
    // @ts-ignore ts(2339) FIXME: Property 'linkedPageId' does not exist on type 'never'.
    ? page.linkedPageId
    // @ts-ignore ts(18047) FIXME: 'page' is possibly 'null'.
    : find(rfxStructure.pages, ['linkedPageId', page._id])?._id;
  const linkedPage = rfxStructure.pageById[targetPageId];

  // @ts-ignore ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  if (!targetPageId || !linkedPage?.sections.includes(targetSectionId)) {
    return null;
  }

  return {
    tab: linkedSectionTab,
    sectionId: targetSectionId,
    pageId: targetPageId,
  };
};

export const useIsReview = () => {
  const { isReview } = rfx.useState();

  return isReview;
};

export const useShowValidationErrors = () => {
  const { isReview, isTemplate, isEditingSupplierExchangeDefs } = rfx.useState();
  const isPendingCollaborator = rfx.useIsPendingCollaborator();

  return isEditingSupplierExchangeDefs || (isReview && !isTemplate && !isPendingCollaborator);
};

export const otherSectionFieldsetIdsToIgnore = [
  'evaluatorFieldCurrency',
  'targetPrice',
  'unspscCode',
  'deliveryDate',
  'leadTime',
  'manufacturer',
  'partNumber',
  'price',
  'totalCost',
  'currency',
  'description',
  'quantity',
  'unit',
];

export const checkAreFieldLabelsEqual = (
  exchangeDef: LineItemExchangeDefinition,
  fieldLabelByFieldsetId: Record<string, string>,
) => {
  const entries = Object.entries(exchangeDef.fields);

  return every(
    entries,
    ([fieldId, field]) => {
      const fieldsetId = fieldId.split(':')[0];

      const newFieldLabel = fieldLabelByFieldsetId[fieldsetId];

      return !newFieldLabel || newFieldLabel === (field as { label: string }).label;
    },
  );
};

export const getUpdatedSectionFields = (
  exchangeDef: LineItemExchangeDefinition,
  fieldLabelByFieldsetId: Record<string, string>,
) => {
  const fields = cloneDeep(exchangeDef.fields);

  return mapValues(
    fields,
    (field, fieldId) => {
      const fieldsetId = fieldId.split(':')[0];

      const newFieldLabel = fieldLabelByFieldsetId[fieldsetId];

      return newFieldLabel
        ? {
          ...field,
          label: newFieldLabel,
        }
        : field;
    },
  );
};

export const useGetOtherSectionFieldLabelChanges = () => {
  const structure = rfx.useStructure();

  return useCallback((sectionId: string, fields?: LineItemExchangeFields) => {
    if (!fields) {
      return [];
    }

    const fieldLabelByFieldsetId = omitBy(
      mapValues(
        // @ts-ignore ts(18048) FIXME: 'field' is possibly 'undefined'.
        groupBy(fields, field => field._id.split(':')[0]),
        (fields) => (fields[0] as unknown as { label: string }).label,
      ),
      (field, fieldsetId) => otherSectionFieldsetIdsToIgnore.includes(fieldsetId),
    );

    const otherLineItemSections = filter(
      structure.sectionById,
      section => section.type === SectionType.LINE_ITEMS && section._id !== sectionId,
    );

    const changes = [];

    for (const section of otherLineItemSections) {
      const sectionExchangeDefs = rfx.getSectionExchangeDefs(section, structure);
      const firstLineItemExchangeDef = sectionExchangeDefs.find(isLineItemExchangeDef);

      if (!firstLineItemExchangeDef) {
        continue;
      }

      const areFieldLabelsEqual = checkAreFieldLabelsEqual(
        firstLineItemExchangeDef,
        fieldLabelByFieldsetId,
      );

      if (!areFieldLabelsEqual) {
        const updatedSectionFields = getUpdatedSectionFields(
          firstLineItemExchangeDef,
          fieldLabelByFieldsetId,
        );

        const exchangeDefs = sectionExchangeDefs.filter(isLineItemExchangeDef);

        const updatedExchangeDefs = exchangeDefs.map(exchangeDef => ({
          ...exchangeDef,
          fields: updatedSectionFields,
        }));

        const exchangeDefKeys = compact([
          ...lineItemExchangeBaseDefKeys,
          ...getLineItemFieldValueKeys(exchangeDefs),
        ]);

        const sectionChanges = getChanges<ExchangeChange>({
          mapping: 'exchangeDef',
          next: updatedExchangeDefs,
          previous: exchangeDefs,
          keys: exchangeDefKeys,
        }).map(change => {
          change.sectionName = section._id;
          return change;
        });

        // @ts-ignore ts(2345) FIXME: Argument of type 'ExchangeChange' is not assignable to parameter of type 'never'.
        changes.push(...sectionChanges);
      }
    }

    return changes;
  }, [structure]);
};

export const useSaveLotsAndAwardScenarios = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Draft>();
  const toaster = useToaster();

  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate, isRevising } = rfx.useState();
  const rfqId = useRfqId();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const mutation = useMutation(
    ({
      settings,
      lotChanges,
      sectionChanges,
    }: {
      settings: Partial<PublishableSettings>;
      lotChanges: LotChange[];
      sectionChanges?: RfqEventChange[];
    }) => waitForRfqUnlock({
      isTemplate,
      command: () => {
        const settingsUpdates = omitBy({
          areLotsEnabled: settings.areLotsEnabled === structure.settings.areLotsEnabled
            ? undefined
            : settings.areLotsEnabled,
          awardScenarios: isEqual(settings.awardScenarios, structure.settings.awardScenarios)
            ? undefined
            : settings.awardScenarios,
          canSplitAwards: isEqual(settings.canSplitAwards, structure.settings.canSplitAwards)
            ? undefined
            : settings.canSplitAwards,
        }, isUndefined);

        return api.updateDraftLotsAndAwardScenarios({
          currentCompanyId,
          rfqId,
          lotChanges,
          settingsUpdates,
          sectionChanges,
          isTemplate,
          isRevising,
        });
      },
    }),
    {
      mutationKey: draftStructureQueryKey,
      onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
      onError: () => toaster.error(t('request.toaster.changesSavedError')),
      onSettled: () => queryClient.invalidateQueries(draftStructureQueryKey),
    },
  );

  return mutation;
};

export const getObsoleteSectionChanges = (sectionIds: string[], structure: RfxStructure<Draft>) => {
  const { sectionById, hirePeriodById } = structure;

  const changes = sectionIds.flatMap(sectionId => {
    const section = sectionById[sectionId];

    // remove sections that haven't been published yet
    if (!section.liveVersion) {
      return [
        {
          type: ChangeType.SECTION_REMOVED,
          sectionId,
        } as const,
      ];
    }

    const saveSectionConfig = saveSectionConfigByType[section.type];

    if (!saveSectionConfig) {
      throw new Error(`could not find saveSectionConfig for type ${section.type}`);
    }

    const exchangeDefs = rfx.getSectionExchangeDefs(section, structure);
    const hirePeriods = values(hirePeriodById);

    const previous = {
      section,
      exchangeDefs,
      hirePeriods,
    } as SectionState;

    const next = {
      section: {
        ...section.liveVersion,
        isLive: section.isLive,
        liveVersion: section.liveVersion,
        isObsolete: true,
      },
      exchangeDefs: exchangeDefs
        .filter(exchangeDef => exchangeDef.isLive)
        .map(exchangeDef => ({
          ...exchangeDef.liveVersion,
          isLive: exchangeDef.isLive,
          liveVersion: exchangeDef.liveVersion,
          isObsolete: true,
        })),
      hirePeriods: hirePeriods
        .filter(hirePeriod => hirePeriod.isLive)
        .map(hirePeriod => ({
          ...hirePeriod.liveVersion,
          isLive: hirePeriod.isLive,
          liveVersion: hirePeriod.liveVersion,
          isObsolete: true,
        })),
    } as SectionState;

    return getSaveSectionChanges(saveSectionConfig, previous, next);
  });

  const removedSectionIds = changes
    .filter((change): change is SectionRemovedChange => change.type === ChangeType.SECTION_REMOVED)
    .map(change => change.sectionId);

  const pageChanges = compact(structure.pages.map(page => {
    const sectionIdsToRemove = intersection(page.sections, removedSectionIds);

    if (!isEmpty(sectionIdsToRemove)) {
      return {
        type: ChangeType.PAGE_UPDATED,
        page: {
          _id: page._id,
          sections: difference(page.sections, sectionIdsToRemove),
        },
      };
    } else {
      return null;
    }
  }));

  return [
    ...pageChanges,
    ...changes,
  ];
};

export const getUnobsoleteSectionChanges = (sectionIds: string[], structure: RfxStructure<Draft>) => {
  const { sectionById, hirePeriodById } = structure;

  return sectionIds.flatMap(sectionId => {
    const section = sectionById[sectionId];

    const saveSectionConfig = saveSectionConfigByType[section.type];

    if (!saveSectionConfig) {
      throw new Error(`could not find saveSectionConfig for type ${section.type}`);
    }

    const exchangeDefs = rfx.getSectionExchangeDefs(section, structure);
    const hirePeriods = values(hirePeriodById);

    const previous = {
      section,
      exchangeDefs,
      hirePeriods,
    } as SectionState;

    const next = {
      section: {
        ...section,
        isObsolete: false,
      },
      exchangeDefs: exchangeDefs.map(exchangeDef => {
        // when the exchangeDef is obsolete in the live version,
        // we need to keep it obsolete
        return exchangeDef.liveVersion?.isObsolete
          ? exchangeDef
          : ({
            ...exchangeDef,
            isObsolete: false,
          });
      }),
      hirePeriods: hirePeriods.map(hirePeriod => {
        // when the hire period is obsolete in the live version,
        // we need to keep it obsolete
        return hirePeriod.liveVersion?.isObsolete
          ? hirePeriod
          : {
            ...hirePeriod,
            isObsolete: false,
          };
      }),
    } as SectionState;

    return getSaveSectionChanges(saveSectionConfig, previous, next);
  });
};
