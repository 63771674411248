import * as React from 'react';
import { CellProps } from 'react-table';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

export const TruncateCell: React.FC<CellProps<any>> = ({ cell, column, sx }) => (
  <Tooltip content={(column as any).showTooltip ? cell.value : null}>
    <Truncate sx={sx}>
      {cell.value}
    </Truncate>
  </Tooltip>
);
