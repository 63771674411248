import { uniqueId } from 'lodash';

export const openFilePicker = ({ multiple, accept }: { multiple: boolean, accept?: string }): Promise<FileList> =>
  new Promise(resolve => {
    const input = document.createElement('input');
    input.id = uniqueId('file-upload-input');
    input.type = 'file';
    // @ts-ignore ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    input.accept = accept;
    input.style.display = 'none';
    input.multiple = multiple;
    input.setAttribute('data-test', 'file-picker');

    input.addEventListener('change', (event: any) => {
      const files = event.target.files as FileList;
      resolve(files);
      input.remove();
    });

    document.body.appendChild(input);

    input.click();
  });
