import { SupplierListType } from '@deepstream/common';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';

export enum SupplierListTab {
  SUPPLIERS = 'suppliers',
  SETTINGS = 'settings',
}

export const listTypeIconProps: Record<SupplierListType, IconProps> = {
  [SupplierListType.MANUAL]: {
    icon: 'user',
    regular: true,
  },
  [SupplierListType.AUTOMATIC]: {
    icon: 'bolt-auto',
    regular: true,
  },
};
