import { useMemo } from 'react';
import { Flex } from 'rebass/styled-components';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { exchangesConfig } from '@deepstream/common/exchangesConfig';
import { omitBy, mapValues } from 'lodash';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { withProps } from '@deepstream/ui-utils/withProps';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { ExchangeSnapshot } from '@deepstream/ui/types';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';
import { YesNoCell } from '../../YesNoCell';

const DocumentTypeCell = ({ cell }: { cell: { value: ExchangeType } }) => {
  const { value } = cell;
  const exchangeConfig = value ? exchangesConfig[value] : null;

  return exchangeConfig ? (
    <Flex alignItems="center">
      {exchangeConfig.label}
    </Flex>
  ) : (
    <EmDash />
  );
};

export const AdminContractDocumentsTable = ({
  exchangeDefIds,
}: {
  exchangeDefIds: string[];
}) => {
  const contract = useContractData();
  const exchangeTypeFilters = useMemo(
    () => mapValues(omitBy(exchangesConfig, 'isDeprecated'), item => item.label),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'category',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Resolved',
        accessor: exchangeDef => {
          const exchange = contract.exchangeById[exchangeDef._id] as ExchangeSnapshot;
          return exchange ? exchange.isResolved : false;
        },
        Cell: YesNoCell,
        Filter: withProps(FilterSelect, {
          itemToString: value => value ? 'Yes' : 'No',
          transformSelectedValues: value => value ? 'Yes' : 'No',
        }),
        filter: filterMultipleValues,
        sortType: 'boolean',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: DocumentTypeCell,
        Filter: withProps(FilterSelect, {
          itemToString: value => exchangeTypeFilters[value],
          transformSelectedValues: value => exchangeTypeFilters[value],
        }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [exchangeTypeFilters, contract],
  );

  const data = useMemo(
    () => exchangeDefIds.map(exchangeDefId => contract.exchangeDefById[exchangeDefId]),
    [exchangeDefIds, contract],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
        isSortable
        noFilteredDataPlaceholder="No documents match chosen filters"
      />
    </StaticTableStyles>
  );
};
