import { useField, useFormikContext } from 'formik';
import { compact, map, values } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { FormTableStyles } from './TableStyles';
import { SelectField } from './form/SelectField';
import { nestCells } from './nestCells';
import { Table } from './Table';
import { TruncateCell } from './TruncateCell';
import { LockPermission, LockStatus } from './types';
import { ValueOrDashCell } from './ValueOrDashCell';
import { isTeamMemberLockType } from './LockTypeField';

const iconByPermission = {
  [LockPermission.CANNOT_UNLOCK]: 'times',
  [LockPermission.CAN_UNLOCK]: 'check',
} as const;

const iconByStatus = {
  [LockStatus.LOCKED]: 'lock',
  [LockStatus.UNLOCKED]: 'unlock',
} as const;

export const SelectFieldCell = ({ row, column }) => (
  <SelectField
    name={`sendersLockConfig[${row.index}].${column.id}`}
    items={column.options}
    required={column.required}
    disabled={column.disabled}
  />
);

export const LockTableField = ({
  name,
  lockTypeName,
  disabled: isReadOnly,
}: {
  name: string;
  lockTypeName: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [{ value: sendersLockConfig }] = useField(name);
  const [{ value: lockType }] = useField(lockTypeName);
  const { errors } = useFormikContext<{ status: any; permission: any }>();

  const columns = useMemo(
    () => compact([
      {
        id: 'name',
        Header: t('general.company'),
        accessor: 'name',
        Cell: TruncateCell,
        verticalAlign: 'middle',
      }, {
        id: 'role',
        Header: t('general.role'),
        accessor: 'role',
        Cell: nestCells(
          TruncateCell,
          ValueOrDashCell,
        ),
        verticalAlign: 'middle',
      },
      {
        id: 'status',
        Header: t('request.lock.status'),
        accessor: 'status',
        Cell: SelectFieldCell,
        options: map(
          values(LockStatus),
          status => ({
            value: status,
            label: (
              <IconText
                icon={iconByStatus[status]}
                iconFontSize={1}
                gap={2}
                text={t(`request.lock.${status}`)}
              />
            ),
          }),
        ),
        disabled: isReadOnly,
        error: errors.status,
      },
      isTeamMemberLockType(lockType) ? {
        id: 'permission',
        Header: t('request.lock.permission'),
        accessor: 'permission',
        Cell: SelectFieldCell,
        options: map(
          values(LockPermission),
          permission => ({
            value: permission,
            label: (
              <IconText
                icon={iconByPermission[permission]}
                iconFontSize={1}
                gap={2}
                text={t(`request.lock.${permission}`)}
              />
            ),
          }),
        ),
        disabled: isReadOnly,
        error: errors.permission,
      } : {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
      },
    ]),
    [t, isReadOnly, lockType, errors],
  );

  return (
    <FormTableStyles>
      <Table columns={columns} data={sendersLockConfig} />
    </FormTableStyles>
  );
};
