import { Flex, Text } from 'rebass/styled-components';
import { localeFormatNumber } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';

type RequestLimits = {
  current: number;
  limit: number;
  limitOverride?: number;
};
export const RequestLimitsPropertyValue = ({
  value,
}: {
  value: RequestLimits,
}) => {
  const { current, limit, limitOverride } = value;
  const formattedCurrent = localeFormatNumber(current, { locale: APP_ADMIN_LOCALE });
  const formattedLimit = localeFormatNumber(limit, { locale: APP_ADMIN_LOCALE });
  const formattedLimitOverride = limitOverride
    ? localeFormatNumber(limitOverride, { locale: APP_ADMIN_LOCALE })
    : '—';

  return (
    <Flex sx={{ columnGap: 2 }}>
      <Text sx={{ minWidth: 150 }}>
        {formattedCurrent} / {formattedLimit}
      </Text>
      <Text>Override: {formattedLimitOverride}</Text>
    </Flex>
  );
};
