import * as React from 'react';
import { merge, pick } from 'lodash';
import { Form, Formik, useField } from 'formik';
import { useQueryClient, useQuery } from 'react-query';
import { z } from 'zod';
import { Flex } from 'rebass/styled-components';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { callAll } from '@deepstream/utils/callAll';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TermsOfService } from '@deepstream/common';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { FileField } from '@deepstream/ui/form/FilesField';
import { Attachment } from '@deepstream/common/rfq-utils';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@deepstream/ui/ui/Tabs';
import { TermsOfServicePreview } from '@deepstream/ui/TermsOfServicePreview';
import { useDownloadTermsAttachment } from './useDownloadTermsAttachment';

const Schema = z.object({
  attachments: z.array(z.object({
    _id: z.string({ message: 'Required' }),
  })).min(1, { message: 'Required' }),
  changesDescription: z.string({ message: 'Required' }).trim().min(1, { message: 'Required' }),
});

type FormValues = {
  attachments: Attachment[];
  changesDescription: string;
};

const PreviewTab = () => {
  const [{ value: attachments }] = useField('attachments');
  const adminApi = useAdminApi();

  const attachmentId = attachments[0]?._id;

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['termsAttachmentHtml', { attachmentId }],
    wrap(adminApi.getTermsAttachmentPreview),
    {
      enabled: Boolean(attachmentId),
    },
  );

  return attachmentId && data ? (
    <TermsOfServicePreview
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      html={data.html}
      sx={{ border: 'secondary', borderRadius: '4px' }}
      errorMessage="Could not load the preview"
      height="381px"
    />
  ) : (
    <Flex alignItems="center" justifyContent="center" height="355px">
      <MessageBlock variant="info" mt={0} width="305px">
        Upload a terms HTML file on the details tab to see a preview here.
      </MessageBlock>
    </Flex>
  );
};

type TermsOfServiceProps =
  {
    initialTermsOfService?: TermsOfService;
    version: number;
    onCancel: () => void;
    onSave: () => void;
  } &
  ModalProps;

export const TermsOfServiceModal = ({
  initialTermsOfService,
  version,
  onCancel,
  onSave,
  style = {},
  ...props
}: TermsOfServiceProps) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();
  const [download] = useDownloadTermsAttachment();
  const [createTermsOfService] = useMutation(
    adminApi.createTermsOfService,
    {
      onSettled: () => queryClient.invalidateQueries(['termsOfService']),
      onSuccess: callAll(
        onSave,
        () => toaster.success(`Version ${version} added successfully`),
      ),
      onError: () => toaster.error(`Version ${version} could not be added`),
    },
  );

  const [updateTermsOfService] = useMutation(
    adminApi.updateTermsOfService,
    {
      onSettled: () => queryClient.invalidateQueries(['termsOfService']),
      onSuccess: callAll(
        onSave,
        () => toaster.success(`Version ${version} updated successfully`),
      ),
      onError: () => toaster.error(`Version ${version} could not be updated`),
    },
  );

  const modalStyle = merge({}, style, { content: { width: 498 } });

  const initialValues = React.useMemo(
    () => initialTermsOfService
      ? pick(initialTermsOfService, ['attachments', 'changesDescription'])
      : { attachments: [], changesDescription: '' },
    [initialTermsOfService],
  );

  return (
    <Modal onRequestClose={onCancel} style={modalStyle} {...props}>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={toFormikValidationSchema(Schema)}
        onSubmit={async (values) => {
          if (!initialTermsOfService) {
            await createTermsOfService(values);
          } else {
            await updateTermsOfService({
              termsId: initialTermsOfService._id,
              updates: values,
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel} divider={false}>
              {initialTermsOfService ? `Edit version ${version}` : `Add version ${version}`}
            </ModalHeader>
            <Tabs>
              <TabList style={{ backgroundColor: 'inherit' }}>
                <Tab>Details</Tab>
                <Tab>HTML preview</Tab>
              </TabList>
              <ModalBody height="413px">
                <TabPanels>
                  <TabPanel>
                    <Stack gap={3}>
                      <FileField
                        name="attachments"
                        label="Terms HTML file"
                        required
                        purpose="admin"
                        truncateFileName
                        accept=".html"
                        helperText="Upload an HTML file containing only the terms of service exactly as they should appear in the platform."
                        download={download}
                      />
                      <TextField
                        name="changesDescription"
                        required
                        label="What’s changed?"
                        isMultiLine
                        rows={5}
                        helperText="Write a short paragraph describing what has changed from the previous version to help existing users understand the changes."
                      />
                      <MessageBlock variant="info" mt={0}>
                        You can edit these fields until you make this version live.
                      </MessageBlock>
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <PreviewTab />
                  </TabPanel>
                </TabPanels>
              </ModalBody>
            </Tabs>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {initialTermsOfService ? 'Save changes' : 'Add version'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
