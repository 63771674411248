import * as React from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { merge } from 'lodash';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Bold } from '@deepstream/ui/Bold';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { TextField } from '@deepstream/ui/form/TextField';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useAdminNavigation } from './AppRouting';

type CreateCompanyProps =
  { onCancel: any; onSave: any } &
  ModalProps;

const companyTypeOptions = [
  { label: 'Buyer', value: 'buyer' },
  { label: 'Supplier', value: 'supplier' },
  { label: 'Both', value: 'both' },
];

const DuplicateNameWarning = () => {
  const adminApi = useAdminApi();
  const { values: { name = '' } } = useFormikContext<{ name: string }>();
  const [debouncedName] = useDebounce(name.trim().toLowerCase(), 500);

  const { data: company } = useQuery(
    ['duplicateCompany', { name: debouncedName }],
    wrap(adminApi.companyNameExists),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  return company ? (
    <MessageBlock variant="warn" mt={0}>
      <div>This name matches an existing company</div>
      <div><Bold>{company.name} ({company._id})</Bold></div>
    </MessageBlock>
  ) : (
    null
  );
};

export const CreateCompanyModal: React.FC<CreateCompanyProps> = ({ onCancel, onSave, style = {}, ...props }) => {
  const toaster = useToaster();
  const adminApi = useAdminApi();
  const { navigateToCompany } = useAdminNavigation();
  const modalStyle = merge({}, style, { content: { width: 499 } });

  return (
    <Modal onRequestClose={onCancel} style={modalStyle} {...props}>
      <Formik
        initialValues={{
          name: '',
          type: '',
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().trim().required('Required'),
            type: yup.string().trim().required('Required'),
          })
        }
        onSubmit={async ({ name, type }, { setSubmitting }) => {
          try {
            const company = await adminApi.createCompany({ name, type });
            setSubmitting(false);
            toaster.success('Company created successfully');
            onSave();
            navigateToCompany(company._id);
          } catch (error) {
            setSubmitting(false);
            toaster.error('An error occurred when trying to create company');
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty, errors }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Create new company
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <MessageBlock variant="info" mt={0}>
                  No users will be added – consider the invite flow instead
                </MessageBlock>
                <TextField
                  required
                  name="name"
                  label="Name"
                />
                <DuplicateNameWarning />
                <SelectField
                  required
                  name="type"
                  label="Type"
                  items={companyTypeOptions}
                  placeholder="Choose a company type"
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !!Object.keys(errors).length}>
                Create company
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
