import * as React from 'react';
import { LockType, Lock, Company } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Placement } from '@floating-ui/react';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

const Content = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

type LockedTooltipProps = {
  senders: Company[];
  lock?: Lock;
  placement?: Placement;
  children: React.ReactElement;
};

const LockInfo = ({ lock, senders }) => {
  const { t } = useTranslation();
  const lockedSenders = senders.filter(sender => !lock?.bypass?.includes(sender._id));
  const sendersWithKey = senders.filter(sender => lock?.keys?.includes(sender._id));

  return lock && lock.type ? (
    <>
      {t('request.lock.lockedFor')}:
      {lockedSenders.map(sender => <div key={sender._id}>• {sender.company.name}</div>)}
      <br />
      {lock.type === LockType.BID_DEADLINE ? (
        <div>{t('request.lock.willUnlockAfterFinalDeadline')}</div>
      ) : lock.type === LockType.STAGE_DEADLINE ? (
        <div>{t('request.lock.willUnlockAfterStageDeadline')}</div>
      ) : (
        <>
          {lock.type === LockType.TEAM_MEMBER ? (
            <div>{t('request.lock.canBeUnlockedAtAnyTime')}</div>
          ) : lock.type === LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE ? (
            <div>{t('request.lock.canBeUnlockedAfterStageDeadline')}</div>
          ) : lock.type === LockType.TEAM_MEMBER_AFTER_BID_DEADLINE ? (
            <div>{t('request.lock.canBeUnlockedAfterFinalDeadline')}</div>
          ) : (
            null
          )}
          <br />
          {t('request.lock.canUnlock')}:
          {sendersWithKey.map(sender => <div key={sender._id}>• {sender.company.name}</div>)}
        </>
      )}
    </>
  ) : (
    <>{t('request.lock.noLockCriteriaSet')}</>
  );
};

export const LockedTooltip: React.FC<LockedTooltipProps> = ({ lock, senders, ...props }) => {
  return (
    <Tooltip
      content={
        <Content>
          <LockInfo lock={lock} senders={senders} />
        </Content>
      }
      {...props}
    />
  );
};

type GridLockedTooltipProps = {
  senders: Company[];
  lock?: Lock;
  textAlign?: string;
  children: React.ReactNode;
};

export const GridLockedTooltip = ({ lock, senders, children, textAlign }: GridLockedTooltipProps) => {
  const { t } = useTranslation();
  const lockedSenders = senders.filter(sender => !lock?.bypass?.includes(sender._id));
  const sendersWithKey = senders.filter(sender => lock?.keys?.includes(sender._id));

  const content = lock && lock.type ? (
    [
      `${t('request.lock.lockedFor')}:`,
      ...lockedSenders.map(sender => `• ${sender.company.name}`),
      '',
      ...(
        lock.type === LockType.BID_DEADLINE ? ([
          t('request.lock.willUnlockAfterFinalDeadline'),
        ]) : lock.type === LockType.STAGE_DEADLINE ? ([
          t('request.lock.willUnlockAfterStageDeadline'),
        ]) : ([
          ...(
            lock.type === LockType.TEAM_MEMBER ? ([
              t('request.lock.canBeUnlockedAtAnyTime'),
            ]) : lock.type === LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE ? ([
              t('request.lock.canBeUnlockedAfterStageDeadline'),
            ]) : lock.type === LockType.TEAM_MEMBER_AFTER_BID_DEADLINE ? ([
              t('request.lock.canBeUnlockedAfterFinalDeadline'),
            ]) : ([])
          ),
          '',
          `${t('request.lock.canUnlock')}:`,
          ...sendersWithKey.map(sender => `• ${sender.company.name}`),
        ])
      ),
    ].join('\n')
  ) : (
   t('request.lock.noLockCriteriaSet')
  );

  return (
    <Flex
      sx={{ width: '100%', height: '100%' }}
      alignItems="center"
      justifyContent={textAlign === 'right' ? 'space-between' : 'flex-start'}
    >
      <Tooltip content={content}>
        <>{children}</>
      </Tooltip>
    </Flex>
  );
};
