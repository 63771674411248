import * as React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { isEqual, values } from 'lodash';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { useProducts } from './useProducts';
import { FilteredProductsPanel } from './FilteredProductsList';
import { Operator } from './types';
import { useSearchState } from './useCompanySearch';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { SelectField } from './form/SelectField';

const Footer = ({
  isInternalNetwork,
  selectedProductIds,
  onCancel,
}: {
  isInternalNetwork?: boolean;
  selectedProductIds: string[];
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const searchState = useSearchState();
  const [{ value: operator }] = useField<Operator>('operator');

  const { data: searchResults, isSuccess } = useQuery(
    [
      isInternalNetwork ? 'searchInternalCompanies' : 'searchExternalCompanies',
      {
        currentCompanyId,
        searchState: {
          ...searchState,
          products: selectedProductIds,
          productsOperator: operator,
        },
      },
    ],
    wrap(isInternalNetwork ? api.searchInternalCompaniesWithAggregations : api.searchExternalCompaniesWithAggregations),
  );

  const saveDisabled = React.useMemo(
    () => {
      const selectedProductsChanged = !isEqual(selectedProductIds, searchState.products);
      const operatorChanged = operator !== searchState.productsOperator;

      return !selectedProductsChanged && (!operatorChanged || !searchState.productsOperator);
    },
    [selectedProductIds, operator, searchState],
  );

  const operatorOptions = React.useMemo(
    () => ([
      { value: Operator.AND, label: t('general.matchAllSelected') },
      { value: Operator.OR, label: t('general.matchAnySelected') },
    ]),
    [t],
  );

  return (
    <ModalFooter justifyContent="space-between">
      <Flex alignItems="center">
        <Box width="165px">
          <SelectField
            name="operator"
            items={operatorOptions}
          />
        </Box>
        {isSuccess && (
          <Text ml={2}>
            {t('general.companyCount', { count: searchResults.total })}
          </Text>
        )}
      </Flex>
      <Box>
        <CancelButton onClick={onCancel} mr={2} />
        <Button
          variant="primary"
          type="submit"
          disabled={saveDisabled}
        >
          {t('general.applyFilter')}
        </Button>
      </Box>
    </ModalFooter>
  );
};

type ProductsFilterModalProps = ModalProps &
  {
    isInternalNetwork?: boolean;
    onClose: any;
    updateProducts: (products: string[], operator: Operator | undefined) => void;
  };

export const ProductsFilterModal: React.FC<ProductsFilterModalProps> = ({
  isInternalNetwork,
  onClose,
  updateProducts,
  ...props
}) => {
  const { t } = useTranslation();
  const searchState = useSearchState();
  const {
    state,
    actions,
    showSearchResults,
    products,
    isProductsLoading,
    isProductsError,
    isSearching,
    isSearchError,
    refetchProducts,
    refetchSearch,
  } = useProducts({ selectedProductIds: searchState.products });

  const { selectedProductIds, searchResultIds } = state;

  return (
    <Modal
      onRequestClose={onClose}
      style={{ content: { width: '900px' } }}
      {...props}
    >
      <Formik
        initialValues={{
          operator: searchState.productsOperator ?? Operator.AND,
        }}
        validationSchema={
          yup.object().shape({
            operator: yup.string().oneOf(values(Operator)),
          })
        }
        onSubmit={({ operator }) => {
          updateProducts(selectedProductIds, selectedProductIds.length ? operator : undefined);
          onClose();
        }}
      >
        <Form>
          <ModalHeader onClose={onClose}>
            {t('productsAndServices.productsAndServices')}
          </ModalHeader>
          <ModalBody>
            <Flex alignItems="center" justifyContent="space-between" height="40px" mb="12px">
              <Text>
                {!isProductsLoading && (
                  t('general.tagCount', { count: showSearchResults ? searchResultIds.length : products.length })
                )}
              </Text>
              {Boolean(selectedProductIds.length) && (
                <Button
                  small
                  type="button"
                  variant="secondary-outline"
                  iconLeft="times"
                  onClick={actions.deselectAll}
                >
                  {t('general.deselectAll')}
                </Button>
              )}
            </Flex>
            <FilteredProductsPanel
              productsState={state}
              productsActions={actions}
              showSearchResults={showSearchResults}
              products={products}
              isProductsLoading={isProductsLoading}
              isProductsError={isProductsError}
              isSearching={isSearching}
              isSearchError={isSearchError}
              refetchProducts={refetchProducts}
              refetchSearch={refetchSearch}
            />
          </ModalBody>
          <Footer
            isInternalNetwork={isInternalNetwork}
            selectedProductIds={selectedProductIds}
            onCancel={onClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};
