import { useState, useMemo } from 'react';

import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { IntegrationDataModal } from './IntegrationDataModal';

export const IntegrationDataTable = ({ integrationData }) => {
  const [selectedRow, setSelectedRow] = useState({ data: [{}] });
  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Company ID',
        accessor: 'meta.companyId',
      },
      {
        Header: 'System ID',
        accessor: 'meta.systemId',
      },
    ],
    [],
  );
  const integrationDataModal = useModalState();

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        onRowClick={row => {
          setSelectedRow(row);
          integrationDataModal.open();
        }}
        data={integrationData}
      />
      <IntegrationDataModal
        isOpen={integrationDataModal.isOpen}
        onClose={integrationDataModal.close}
        data={selectedRow.data}
      />
    </BasicTableStyles>
  );
};
