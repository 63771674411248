import { hasOwnProperty } from '@deepstream/utils';
import { isNotificationTransportConfig, NotificationTransportConfig, MessageData } from './shared-domain';

export enum MessageType {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  POST = 'POST',
  EVENT = 'EVENT',
}

export type MessageDto = {
  type: MessageType.SUBSCRIBE,
  subscriberId: string,
  topics: string[],
  config: NotificationTransportConfig
} | {
  type: MessageType.UNSUBSCRIBE,
  subscriberId: string,
} | {
  type: MessageType.POST,
  data: MessageData,
} | {
  type: MessageType.EVENT,
  data: MessageData,
};

export function isMessageDto(message: unknown): message is MessageDto {
  if (
    !hasOwnProperty(message, 'type') ||
    !(typeof message.type === 'string') ||
    !(Object.values(MessageType).includes(message.type as MessageType))
  ) {
    return false;
  }

  if (
    message.type === MessageType.SUBSCRIBE &&
    (
      !hasOwnProperty(message, 'subscriberId') ||
      !hasOwnProperty(message, 'topics') ||
      !hasOwnProperty(message, 'config') ||
      !isNotificationTransportConfig(message.config)
    )
  ) {
    return false;
  }

  if (
    message.type === MessageType.UNSUBSCRIBE &&
    !hasOwnProperty(message, 'subscriberId')
  ) {
    return false;
  }

  if (
    message.type === MessageType.POST &&
    !hasOwnProperty(message, 'data')
  ) {
    return false;
  }

  if (
    message.type === MessageType.EVENT &&
    !hasOwnProperty(message, 'data')
  ) {
    return false;
  }

  return true;
}
