import { useMemo } from 'react';
import { get } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTranslation } from 'react-i18next';
import { RequestLinkCell } from './RequestLinkCell';
import { formatAdminDate } from './formatDate';
import { useAdminNavigation } from './AppRouting';

const getCompanyRole = (companyId: string) => ({ senderIds, recipientIds }: any) => {
  if (senderIds.includes(companyId)) {
    return 'Sender';
  } else if (recipientIds.includes(companyId)) {
    return 'Recipient';
  } else {
    return 'Unknown';
  }
};

const getUserRole = (companyId: string, userId: string) => ({ ownerIdsBySenderId }: any) => {
  if (
    ownerIdsBySenderId &&
    ownerIdsBySenderId[companyId] &&
    ownerIdsBySenderId[companyId].includes(userId)
  ) {
    return 'Owner';
  } else {
    return 'Team member';
  }
};

type RequestsTableProps = {
  companyId: string;
  userId: string;
  requests: any[];
};

export const CompanyUserRequestsTable = ({ companyId, userId, requests }: RequestsTableProps) => {
  const { t } = useTranslation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'receivedDashboard.subject',
        Cell: nestCells(TruncateCell, RequestLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Company role',
        accessor: getCompanyRole(companyId),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'userRole',
        Header: 'User role',
        accessor: getUserRole(companyId, userId),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        Header: 'Status',
        accessor: (item) => t(`request.status.${get(item, 'meta.extendedStatus')}`),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: 'sentDashboard.bidDeadline',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [companyId, userId, isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={requests}
        onRowClick={request => navigation.navigateToRequestCompanyUser(request._id, companyId, userId)}
        isSortable
        noFilteredDataPlaceholder="No requests match chosen filters"
        hiddenColumns={!isExtraLarge && !isLarge ? ['userRole', 'deadline'] : []}
      />
    </BasicTableStyles>
  );
};
