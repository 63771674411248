import { useQuery } from 'react-query';
import { useApi, wrap } from './api';

export const useExchangeRates = () => {
  const api = useApi();

  const { data: exchangeRates } = useQuery(
    'exchangeRates',
    wrap(api.getExchangeRates),
    {
      staleTime: 60 * 60 * 1000,
    },
  );

  return exchangeRates;
};
