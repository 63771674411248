import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { merge } from 'lodash';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useAdminNavigation } from './AppRouting';

type CreateUserProps =
  { onCancel: any; onSave: any } &
  ModalProps;

export const CreateUserModal: React.FC<CreateUserProps> = ({ onCancel, onSave, style = {}, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const { navigateToUser } = useAdminNavigation();

  const modalStyle = merge({}, style, { content: { width: 600 } });

  return (
    <Modal onRequestClose={onCancel} style={modalStyle} {...props}>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
        }}
        validationSchema={
          yup.object().shape({
            email: yup.string().email('Invalid email').required('Required'),
            firstName: yup.string(),
            lastName: yup.string(),
          })
        }
        onSubmit={async ({ email, firstName, lastName }, { setErrors }) => {
          try {
            const isExistingUser = await adminApi.userExists({ email });
            if (isExistingUser) {
              setErrors({ email: 'User already exists' });
            } else {
              const user = await adminApi.createUser({ email, firstName, lastName });
              toaster.success('User created successfully');
              onSave();
              navigateToUser(user._id);
            }
          } catch (error) {
            toaster.error('An error occurred when trying to create user');
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty, errors }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Create new user</ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <MessageBlock variant="info" mt={0}>
                  No invite email will be sent – use the invite flow if required
                </MessageBlock>
                <TextField name="email" label="Email" inputType="email" required />
                <TextField name="firstName" label="First Name" />
                <TextField name="lastName" label="Last Name" />
              </Stack>
            </ModalBody>
            <PanelDivider />
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !!Object.keys(errors).length}>
                Create user
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
