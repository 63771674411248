import { localeFormatFactorAsPercent, localeFormatNumber } from '@deepstream/utils';
import { useCurrentUserLocale } from '../useCurrentUser';

export const Number = ({
  value,
  suffix,
  options,
}: {
  value?: number;
  suffix?: string;
  options?: Intl.NumberFormatOptions;
}) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {/*
       // @ts-ignore ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'. */}
      {localeFormatNumber(value, { locale, ...options })}{suffix}
    </>
  );
};

export const FactorAsPercent = ({ value }: { value?: number }) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {localeFormatFactorAsPercent(value, { locale })}
    </>
  );
};
