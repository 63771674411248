import * as React from 'react';
import { SxStyleProp } from 'rebass/styled-components';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { DateFormat } from '@deepstream/utils';
import { ColumnData, DataCellProps, FrozenHeaderCellProps, RowData } from '../core/utils';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT } from '../core/constants';

export const ACTION_COLUMN_WIDTH = 40;
export const DEFAULT_ROW_HEIGHT = 40;

export const DEFAULT_EDITABLE_DATA_CELL_WIDTH = 190;

export type GridDataCell = (props: DataCellProps<EditableGridColumn, any, any>) => React.ReactElement;

export type EditableGridColumn<TRowOriginal = unknown> = {
  _id: string;
  Header: (props: FrozenHeaderCellProps<EditableGridColumn>) => React.ReactNode | null;
  required?: boolean;
  label: string;
  descriptionIcon?: React.ReactNode;
  description?: React.ReactNode | string;
  descriptionSx?: SxStyleProp;
  accessorKey: string;
  accessorFn?: (rowOriginal: TRowOriginal) => unknown;
  fieldType?: FieldType;
  disabled?: boolean;
  isDisabled?: (rowIndex: number) => boolean;
  startEditingCell?: (
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  format?: DateFormat | string;
  toggleMenu?: (rowIndex: number, columnIndex: number) => void;
  InputCell?: GridDataCell;
  ValueCell: GridDataCell;
  staticValue?: string;
  prefix?: string | ((row: any) => any);
  suffix?: string;
  minValue?: number;
  exclusiveMinValue?: number;
  decimalPlaces?: number;
  hideError?: boolean;
  warnings?: number;
  errors?: number;
  width?: number;
  disableSortBy?: boolean;
  sortAccessorFn?: (rowOriginal: TRowOriginal) => unknown;
  sortType?: string;
  infoTooltipKey?: string;
  /**
   * Indicates whether the cell should render an obsolete icon
   * when the row has a truthy `isObsolete` property.
   */
  showIconWhenObsolete?: boolean;
};

export enum GridPasteMethod {
  CROP = 'crop',
  EXTEND = 'extend',
}

export enum GridClipboardEventType {
  COPY = 'copy',
  PASTE = 'paste',
  PASTE_ERROR = 'pasteError',
}

export type GridClipboardEvent =
  | {
    type: GridClipboardEventType.COPY;
    cellCount: number;
  }
  | {
    type: GridClipboardEventType.PASTE;
    validCellCount: number;
    invalidCellCount: number;
  }
  | {
    type: GridClipboardEventType.PASTE_ERROR;
    reason: 'accessBlocked' | 'unknown';
  };

export const calculateMaxGridHeight = (numRows: number, bodyPaddingBottom = 0) : number => {
  return (
    DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT +
    BORDER_ADJUSTMENT +
    bodyPaddingBottom +
    DEFAULT_ROW_HEIGHT * numRows +
    getScrollbarSize()
  );
};
