import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CompanyLinkCell } from './CompanyLinkCell';
import { TemplateLinkCell } from './TemplateLinkCell';
import { useAdminNavigation } from './AppRouting';

type RecentActivityTemplatesTableProps = {
  templates: any[];
};

const getCompanyId = (template: any) => template.companyId;

export const RecentActivityTemplatesTable = ({ templates }: RecentActivityTemplatesTableProps) => {
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'meta.name',
        Cell: nestCells(TruncateCell, TemplateLinkCell),
      },
      {
        Header: 'Creator',
        accessor: 'companyId',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
      },
      {
        id: 'lastUpdated',
        Header: 'Last updated',
        accessor: 'meta.lastEditedAt',
        Cell: DatetimeCell,
        sort: 'datetime',
      },
      {
        id: 'requestsCreated',
        Header: 'Requests created',
        accessor: (template) => template.requestsCount === 1 ? `${template.requestsCount} request` : `${template.requestsCount ?? 0} requests`,
        Cell: TruncateCell,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={templates}
        isPaginated
        smallPageControls
        onRowClick={template => navigation.navigateToTemplate(template._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['lastUpdated', 'requestsCreated'] : []}
      />
    </BasicTableStyles>
  );
};
