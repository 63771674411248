import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  ChartOptions,
  ChartEvent,
  TooltipModel,
  ChartTypeRegistry,
  Point,
  BubbleDataPoint,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { first, isNumber, range, toUpper } from 'lodash';
import { useTranslation } from 'react-i18next';
import { localeFormatNumber } from '@deepstream/utils';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useCurrentUserLocale } from '../../useCurrentUser';

ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
);

const TOOLTIP_WIDTH = 250;
const CARET_HEIGHT = 10;

const HAIRSPACE = '\u200A';

// ChartJS doesn't support text transformation or
// letter spacing out of the box so we emulate it.
const formatAxisTitle = (label: string) =>
  toUpper(label).split('').join(HAIRSPACE);

export const updateActiveBar = (dataset, activeBarIndex: number) => {
  const backgroundColors = activeBarIndex === -1
    ? dataset.data.map(() => dataset.activeColor)
    : dataset.data.map(((_, index: number) => (
      index === activeBarIndex
        ? dataset.activeColor
        : dataset.inactiveColor
    )));

  dataset.backgroundColor = backgroundColors;
  dataset.hoverBackgroundColor = backgroundColors;
};

type TooltipContext = {
  chart: ChartJS<keyof ChartTypeRegistry, number[], unknown>;
  tooltip: TooltipModel<'bar'>;
};

const useTooltipCallback = () => {
  const theme = useTheme();
  const { t } = useTranslation('reporting');
  const tooltipId = useUniqueId();
  const locale = useCurrentUserLocale();

  return useCallback((context: TooltipContext) => {
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = tooltipId;
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    const desiredLeft = position.left + window.scrollX + tooltipModel.caretX - TOOLTIP_WIDTH / 2;

    const actualLeft = Math.max(
      Math.min(desiredLeft, window.innerWidth - TOOLTIP_WIDTH - 20),
      20,
    );

    if (tooltipModel.body) {
      const duration = first(tooltipModel.title);
      const completionRate = localeFormatNumber(Number(first(first(tooltipModel.body)?.lines)), { locale, maximumFractionDigits: 2 });

      tooltipEl.innerHTML = [
        `<div>${t('supplierEngagement.averageCompletionShort')}: ${completionRate}%</div>`,
        `<div>${t('supplierEngagement.duration')}: ${duration} ${t('supplierEngagement.day_other')}</div>`,
        '<div style="',
        'position: absolute;',
        `left: ${(TOOLTIP_WIDTH / 2) - (CARET_HEIGHT / 2) + (desiredLeft - actualLeft)}px;`,
        `bottom: -${CARET_HEIGHT / 2}px;`,
        `width: ${CARET_HEIGHT}px;`,
        `height: ${CARET_HEIGHT}px;`,
        'transform: rotate(-45deg);',
        `background: ${theme.colors.navy}`,
        '" />',
      ].join('');
    }

    tooltipEl.style.backgroundColor = theme.colors.navy;
    tooltipEl.style.borderRadius = '4px';
    tooltipEl.style.width = `${TOOLTIP_WIDTH}px`;
    tooltipEl.style.color = '#FFFFFF';
    tooltipEl.style.fontSize = `${theme.fontSizes[2]}px`;
    tooltipEl.style.lineHeight = '20px';
    tooltipEl.style.padding = '7px 12px';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${actualLeft}px`;
    tooltipEl.style.top = `${position.top + window.scrollY + tooltipModel.caretY - CARET_HEIGHT - 2}px`;
    tooltipEl.style.font = theme.fonts.primary;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.transition = 'opacity 300ms ease-in';
    tooltipEl.style.transform = 'translate(0, -100%)';
  }, [locale, t, theme.colors.navy, theme.fontSizes, theme.fonts.primary, tooltipId]);
};

const useChartOptions = (onHover: (event: ChartEvent) => void) => {
  const theme = useTheme();
  const { t } = useTranslation('reporting');
  const tooltipCallback = useTooltipCallback();

  return useMemo(
    () =>
      ({
        animation: {
          duration: 300,
        },
        responsive: true,
        maintainAspectRatio: false,
        font: {
          size: 12,
          family: 'aktiv-grotesk, sans-serif',
        },
        layout: {
          padding: {
            top: 20,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: tooltipCallback,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            position: 'bottom',
            title: {
              display: true,
              text: formatAxisTitle(t('supplierEngagement.requestDurationDays')),
              color: theme.colors.subtext,
              font: {
                size: 10,
                weight: 'bold',
              },
            },
            ticks: {
              color: theme.colors.subtext,
              padding: 10,
            },
            grid: {
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
              color: theme.colors.secondary,
              borderColor: theme.colors.secondary,
            },
          },
          y: {
            min: 0,
            max: 100,
            title: {
              display: true,
              text: formatAxisTitle(
                t('supplierEngagement.supplierCompletionRatePercent'),
              ),
              color: theme.colors.subtext,
              font: {
                size: 10,
                weight: 'bold',
              },
            },
            ticks: {
              color: theme.colors.subtext,
              padding: 10,
            },
            grid: {
              drawBorder: false,
              drawTicks: false,
              color: theme.colors.secondary,
              borderColor: theme.colors.secondary,
            },
          },
        },
        onHover,
      } as ChartOptions<'bar'>),
    [onHover, t, theme, tooltipCallback],
  );
};

type BarChart = ChartJS<'bar', (number | [number, number] | Point | BubbleDataPoint)[], unknown>;

export const DurationCompletionRateChart = ({
  completionRateByDuration,
}: {
  completionRateByDuration: Record<string, number>;
}) => {
  const chartRef = useRef<BarChart>(null);
  const theme = useTheme();
  const { t } = useTranslation('reporting');
  const previousActiveIndex = useRef(-1);
  const [activeBarIndex, setActiveBarIndex] = useState<number>(-1);

  // unset active slice when mouse leaves chart
  const handleMouseLeave = useCallback(() => {
    previousActiveIndex.current = -1;
    setActiveBarIndex(-1);
  }, [setActiveBarIndex]);

  const handleHover = useCallback((event) => {
    if (event.type === 'mousemove') {
      const activeElement = event.chart.getActiveElements()?.[0];

      if (!activeElement || !(activeElement.element instanceof BarElement)) {
        if (isNumber(previousActiveIndex.current)) {
          previousActiveIndex.current = -1;
          setActiveBarIndex(-1);
        }
      } else if (
        activeElement.element instanceof BarElement &&
        activeElement.index !== previousActiveIndex.current
      ) {
        previousActiveIndex.current = activeElement.index;

        setActiveBarIndex(activeElement.index);
      }
    }
  }, [setActiveBarIndex]);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        updateActiveBar(dataset, activeBarIndex);
      });

      chart.update();
    }
  }, [activeBarIndex]);

  const options = useChartOptions(handleHover);

  const data = useMemo(() => {
    const allDurations = range(2, 32, 2).map(String).concat('> 30');

    return {
      labels: allDurations,
      datasets: [
        {
          data: allDurations.map(duration =>
            (completionRateByDuration[duration] ?? 0) * 100,
          ),
          backgroundColor: theme.colors.primary,
          hoverBackgroundColor: theme.colors.primary,
          borderRadius: 4,

          activeColor: theme.colors.primary,
          inactiveColor: 'rgba(52, 152, 219, 0.4)',
        },
      ],
    };
  }, [completionRateByDuration, theme]);

  return (
    <div
      style={{
        position: 'relative',
        height: '434px',
        width: '100%',
      }}
    >
      <Chart
        ref={chartRef}
        onMouseLeave={handleMouseLeave}
        type="bar"
        options={options}
        data={data}
        fallbackContent={
          <p>{t('supplierEngagement.requestCompletionRateByDuration')}</p>
        }
      />
    </div>
  );
};
