import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Company } from '@deepstream/ui/ui/types';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { PageHeaderTabs } from '@deepstream/ui/page-headers/PageHeaderTabs';
import { useNavigate } from '@tanstack/react-router';
import { Box } from 'rebass/styled-components';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as Title from '../../title';
import { Page } from '../../Page';
import { companyDetailsRoute, companyReportingRoute, companyRoute } from '../../AppRouting';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyPage = ({
  company,
  selectedTabId,
  children,
}: { company?: Company, selectedTabId: 'details' | 'reporting', children?: React.ReactElement }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { companyId } = companyRoute.useParams();
  const [ref, inView, entry] = useInView();

  const tabs = React.useMemo(
    () => [{
      id: 'details',
      name: 'Details',
      iconLeft: 'grid-2' as const,
      iconProps: { regular: true },
      navigate: () => navigate({ to: companyDetailsRoute.to, params: { companyId } }),
    }, {
      id: 'reporting',
      name: 'Reporting',
      iconLeft: 'chart-bar' as const,
      navigate: () => navigate({ to: companyReportingRoute.to, params: { companyId } }),
    }],
    [navigate, companyId],
  );

  return (
    <Page subHeading={company && !inView && entry && <PageTitle company={company} />}>
      {company ? (
        <>
          <div ref={ref}>
            <PageTitle flexDirection="column" company={company} />
          </div>
          <Box mb="40px">
            <PageHeaderTabs
              tabs={tabs}
              selectedTabId={selectedTabId}
              tabListStyle={{ backgroundColor: 'inherit', borderBottom: theme.borders.lightGray2 }}
            />
          </Box>
          {children}
        </>
      ) : (
        <Panel heading="Error">
          <PanelPadding>
            <ErrorMessage error="Oh no" fontSize={2} lineHeight={1.5} />
          </PanelPadding>
        </Panel>
      )}
    </Page>
  );
};
