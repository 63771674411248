import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { AuctionOverview } from '@deepstream/ui/types';
import { CapitalizeCell } from './CapitalizeCell';
import { CompanyLinkCell } from './CompanyLinkCell';
import { RequestLink } from './RequestLink';
import { useAdminNavigation } from './AppRouting';

const getCompanyId = (auctionOverview: AuctionOverview) => auctionOverview.senderIds[0];

const AuctionRequestLinkCell = ({ row, cell }: CellProps<AuctionOverview>) => (
  <RequestLink requestId={row.original.rfxId}>
    {cell.value || '(Not set)'}
  </RequestLink>
);

export const RecentActivityAuctionsTable = ({ auctions }: { auctions: AuctionOverview[] }) => {
  const { isExtraLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Request name',
        accessor: 'rfx.subject',
        Cell: nestCells(TruncateCell, AuctionRequestLinkCell),
      },
      {
        Header: 'Creator',
        accessor: 'rfx.senderNames[0]',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId })),
        width: 280,
      },
      {
        id: 'status',
        Header: 'Auction status',
        accessor: 'status',
        Cell: CapitalizeCell,
        width: 160,
      },
      {
        id: 'startDate',
        Header: 'Start',
        accessor: 'startDate',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
      },
    ],
    [isExtraLarge],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={auctions}
        isPaginated
        smallPageControls
        onRowClick={({ rfxId, auctionId }) => navigation.navigateToRequestAuction(rfxId, auctionId)}
      />
    </BasicTableStyles>
  );
};
