import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import { getDate } from 'date-fns';
import { Form, Formik } from 'formik';
import { consecutiveGrouper } from '@deepstream/utils';
import * as yup from 'yup';
import { lighten } from 'polished';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useOpenState } from '@deepstream/ui-kit/hooks/useOpenState';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Panel, PanelDivider, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { TextField } from '../../../form/TextField';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as chatbot from './types';
import { RadioField } from '../../../form/RadioField';
import { Loading } from '../../../ui/Loading';
import { Datetime } from '../../../Datetime';
import { ScrollToBottom, ChatbotScrollToBottomStyles } from '../../../ScrollToBottomStyles';
import { Disclosure } from '../../../ui/Disclosure';
import { BasicTargetById, useChatbotChat, useChatbotState, useCreateChatbotChat, useDispatchChatbotAction } from './ChatbotSubjectContext';
import { HiddenField } from '../../../form/HiddenField';
import { Bold } from '../../../Bold';
import { MessageBase } from '../../Request/Live/AuctionChat';

const ChatGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: min-content 1fr min-content;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "header header"
    "sidebar content"
    "sidebar footer";
`;

const ChatLayout = ({ header, sidebar, footer, content }) => {
  return (
    <ChatGrid>
      <Box sx={{ gridArea: 'header' }}>
        {header}
      </Box>
      <Box sx={{ gridArea: 'sidebar', borderRight: 'lightGray' }}>
        {sidebar}
      </Box>
      <Box sx={{ gridArea: 'content' }}>
        {content}
      </Box>
      <Box sx={{ gridArea: 'footer', borderTop: 'lightGray' }}>
        {footer}
      </Box>
    </ChatGrid>
  );
};

const SendMessageForm = ({
  control,
  onSubmit,
}: {
  control: chatbot.Control;
  onSubmit: (action: chatbot.ChatAction) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Formik<chatbot.ChatFormAction>
      validateOnBlur
      validateOnMount
      initialValues={control.options[0].value as chatbot.ChatFormAction}
      validationSchema={
        yup.object().shape({
          type: yup.string(),
          strategy: yup.string(),
          message: yup.string().when('type', {
            is: 'retrieve-information',
            then: yup.string().required(),
          }),
        })
      }
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);

        resetForm();
      }}
    >
      {({ values, isSubmitting, isValid, submitForm }) => (
        <Form style={{ width: '100%' }}>
          <Flex alignItems="flex-end">
            <Box flex={1}>
              {control.type === 'radio' ? (
                <Stack gap={2}>
                  <RadioField
                    variant="inline"
                    hideLabel
                    name="type"
                    disabled={isSubmitting}
                    options={control.options.map(option => ({
                      ...option,
                      value: option.value.type,
                      tooltip: option.value.type === 'condense'
                        ? <>Use <Bold>Summarise</Bold> mode to extract key insights based on a single file and your text prompt</>
                        : option.value.type === 'retrieve-information'
                          ? <>Use <Bold>Retrieve</Bold> mode to ask a specific question and get an answer from your selected files</>
                          : null,
                    }))}
                  />
                  {values.type === 'condense' && (
                    <HiddenField
                      name="strategy"
                      value="summarize-parallel"
                    />
                  )}
                  <TextField
                    hideLabel
                    hideError
                    isMultiLine
                    hasDynamicHeight
                    name="message"
                    maxHeight={112}
                    label={t('general.message')}
                    placeholder={values.type === 'retrieve-information' ? (
                      'Ask a question about your selected files'
                    ) : (
                      'Optionally include any directions for your summary'
                    )}
                    disabled={isSubmitting}
                    onKeyDown={event => {
                      if (event.key === 'Enter' && !event.shiftKey) {
                        stopEvent(event);
                        submitForm();
                      }
                    }}
                  />
                </Stack>
              ) : (
                null
              )}
            </Box>
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              iconLeft="paper-plane"
              ml={2}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

const NumberedList = (props: BoxProps) => (
  <Box
    as="ol"
    sx={{
      display: 'grid',
      gap: 2,
      listStylePosition: 'outside',
      pl: 3,
      '> li::marker': {
        fontWeight: 500, // This makes the numbering bold
      },
    }}
    {...props}
  />
);

const DateHeading = ({ date }: { date: Date }) => (
  <Text fontSize={2} mx="auto" color="gray">
    <Datetime value={date} onlyDate isCondensed />
  </Text>
);

/**
 * Takes a string that has citations in the form of [1] and transforms them into superscript
 */
const transformCitationsToSuperScript = (text: string) => {
  const citationRegex = /\[\d+\]/g;
  let match;
  let lastIndex = 0;
  const superscriptText = [] as React.ReactNode[];

  while ((match = citationRegex.exec(text)) !== null) {
    const citation = match[0];
    const citationIndex = match.index;
    const citationLength = citation.length;

    superscriptText.push(
      text.slice(lastIndex, citationIndex),
    );

    superscriptText.push(
      <sup key={citationIndex}>
        {citation}
      </sup>,
    );

    lastIndex = citationIndex + citationLength;
  }

  superscriptText.push(text.slice(lastIndex));

  return superscriptText;
};

const Message = React.memo(({
  event,
  showErrorDisclosures,
}: {
  event: chatbot.ChatHistoryEvent;
  showErrorDisclosures: boolean;
}) => {
  return (
    <Stack gap={2}>
      <Box>
        {event.role === 'user' ? (
          event.message
        ) : (
          transformCitationsToSuperScript(event.message)
        )}
      </Box>
      {event.role === 'assistant' && !isEmpty(event.data?.fragments) ? (
        <Disclosure summary="Excerpts">
          <NumberedList>
            {/*
             // @ts-ignore ts(18048) FIXME: 'event.data' is possibly 'undefined'. */}
            {event.data.fragments.map((fragment, index) => (
              <li key={index}>
                “{fragment.text.trim()}” (Source: {fragment.metadata.source.name})
              </li>
            ))}
          </NumberedList>
        </Disclosure>
      ) : (
        null
      )}
      {event.role === 'assistant' && !isEmpty(event.data?.error) && showErrorDisclosures ? (
        <Disclosure summary="Error">
          <Text style={{ whiteSpace: 'pre-wrap', fontFamily: 'courier' }}>
            {/*
             // @ts-ignore ts(18048) FIXME: 'event.data' is possibly 'undefined'. */}
            {event.data.error}
          </Text>
        </Disclosure>
      ) : (
        null
      )}
    </Stack>
  );
});

export const ChatbotChatHistory = React.memo(({
  history,
  userName,
  showErrorDisclosures,
}: {
  history: chatbot.ChatHistoryEvent[];
  userName?: string;
  showErrorDisclosures: boolean;
}) => {
  const eventGroupsByDateByRole = history
    .reduce<chatbot.ChatHistoryEvent[][]>(
      consecutiveGrouper((previous, next) =>
        // @ts-ignore ts(2769) FIXME: No overload matches this call.
        getDate(new Date(previous?.date)) === getDate(new Date(next.date)),
      ),
      [],
    )
    .map(group =>
      group.reduce<chatbot.ChatHistoryEvent[][]>(
        consecutiveGrouper((previous, next) =>
          previous?.role === next.role,
        ),
        [],
      ),
    );

  return (
    <Stack gap={3}>
      {eventGroupsByDateByRole.map((eventsByUser, index) => (
        <React.Fragment key={index}>
          <DateHeading date={new Date(eventsByUser[0][0].date)} />
          {eventsByUser.map((events, index) => (
            <Stack key={index} gap={1}>
              {events.map(event => (
                <MessageBase
                  key={event._id}
                  maxWidth={450}
                  date={new Date(event.date)}
                  placement={event.role === 'user' ? 'right' : 'left'}
                  variant={event.role === 'user' ? 'blue' : 'gray'}
                  name={event.role === 'user' ? userName ?? 'You' : 'Quest'}
                  body={<Message event={event} showErrorDisclosures={showErrorDisclosures} />}
                />
              ))}
            </Stack>
          ))}
        </React.Fragment>
      ))}
    </Stack>
  );
});

const ChatbotTargetList = React.memo(({
  chatbotId,
  target,
  targetById,
  dispatchChatbotAction,
}: {
  chatbotId: string;
  target: chatbot.Target;
  targetById: BasicTargetById;
  dispatchChatbotAction: any;
}) => {
  const currentCompanyId = useCurrentCompanyId();
  const theme = useTheme();

  const handleToggle = React.useCallback(
    (availableTarget: chatbot.BasicTarget) => {
      let nextTarget: chatbot.Target;
      if (!target) {
        // If there is no target, set the clicked target as the current target
        nextTarget = availableTarget;
      } else if (target.type === 'group') {
        // If the current target is a group check if the clicked target is not in the group
        if (!target.targets.find(target => target._id === availableTarget._id)) {
          // If it's not in the group, add the clicked target
          nextTarget = {
            type: 'group',
            targets: [...target.targets, availableTarget],
          };
        } else if (target.targets.length > 2) {
          // If removing the target leaves at least two targets in the group, then preserve the
          // group but remove the clicked target
          nextTarget = {
            type: 'group',
            targets: target.targets.filter(target =>
              target._id !== availableTarget._id,
            ),
          };
        } else if (target.targets.length === 2) {
          // If removing the clicked target leaves only one target in the group, then replace
          // the group with the remaining target
          // @ts-ignore ts(2322) FIXME: Type 'BasicTarget | undefined' is not assignable to type 'Target'.
          nextTarget = target.targets.find(target =>
            target._id !== availableTarget._id,
          );
        }
      } else if (target._id === availableTarget._id) {
        // If the current target is not a group and the current target is the clicked target,
        // clear the current target
        // @ts-ignore ts(2322) FIXME: Type 'null' is not assignable to type 'Target'.
        nextTarget = null;
      } else if (target._id !== availableTarget._id) {
        // If the current target is not a group, create a group with the current target and
        // the clicked target
        nextTarget = {
          type: 'group',
          targets: [target, availableTarget],
        };
      }

      dispatchChatbotAction({
        companyId: currentCompanyId,
        chatbotId,
        action: {
          type: 'select-target',
          // @ts-ignore ts(2454) FIXME: Variable 'nextTarget' is used before being assigned.
          target: nextTarget,
        },
      });
    },
    [chatbotId, currentCompanyId, dispatchChatbotAction, target],
  );

  return (
    <Box
      as="ul"
      p={0}
      sx={{
        flex: 1,
        listStyle: 'none',
        whiteSpace: 'nowrap',
        overflowY: 'auto',
        '> :hover': {
          backgroundColor: lighten(0.4, theme.colors.primary),
        },
      }}
    >
      {Object.values(targetById).map(availableTarget => (
        <Box key={availableTarget._id} as="li" px={3} py={1} sx={{ borderBottom: 'lightGray' }}>
          <Checkbox
            label={availableTarget.name}
            onClick={() => handleToggle(availableTarget)}
            checked={
              target?.type === 'group' ? (
                target?.targets.some(childTarget => childTarget._id === availableTarget._id)
              ) : (
                target?._id === availableTarget._id
              )
            }
          />
        </Box>
      ))}
    </Box>
  );
});

const ChatbotChatFooter = React.memo(({ chat }: { chat: any }) => {
  const currentCompanyId = useCurrentCompanyId();
  const [dispatchChatbotAction] = useDispatchChatbotAction();

  return (
    <Flex p={3}>
      {chat.isAwaitingUserInput && chat.control ? (
        <SendMessageForm
          control={chat.control}
          onSubmit={async action =>
            // @ts-ignore ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            dispatchChatbotAction({ companyId: currentCompanyId, chatbotId: chat._id, action })
          }
        />
      ) : chat.isAwaitingUserInput && !chat.control ? (
        <>Select 1 or more files from the sidebar</>
      ) : (
        <Loading />
      )}
    </Flex>
  );
});

export const ChatbotChat = React.memo(({ showErrorDisclosures }: { showErrorDisclosures: boolean }) => {
  const { t } = useTranslation('chatbot');
  const currentCompanyId = useCurrentCompanyId();
  const chatPopOver = useOpenState();
  const { chatbotId } = useChatbotState();

  const [createChatbotConversation, { isLoading: isCreatingChatbot }] = useCreateChatbotChat();
  const [dispatchChatbotAction] = useDispatchChatbotAction();
  const { data: chat } = useChatbotChat();
  const { targetById: pageTargetById } = useChatbotState();

  useEffect(
    () => {
      if (chatPopOver.isOpen && !chatbotId && !isCreatingChatbot) {
        createChatbotConversation({
          // @ts-ignore ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          companyId: currentCompanyId,
          target: null,
        });
      }
    },
    [chatPopOver.isOpen, chatbotId, createChatbotConversation, currentCompanyId, isCreatingChatbot],
  );

  const numFilesSelected = chat?.target?.type === 'group'
    ? chat.target.targets.length
    : chat?.target
      ? 1
      : 0;

  return (
    <Flex flexDirection="column" style={{ position: 'fixed', bottom: 30, right: 30, zIndex: 420 }}>
      {chatPopOver.isOpen && (
        <Panel width={738} height="min(800px, 80vh)">
          {!chatbotId || !chat ? (
            <Loading />
          ) : (
            <ChatLayout
              header={
                <ModalHeader onClose={chatPopOver.close}>
                  Chat with Quest <Text fontSize={0} display="inline" color="subtext" ml={1}>Powered by <Icon icon="brain-circuit" /> <Bold>DeepAI</Bold></Text>
                </ModalHeader>
              }
              sidebar={
                <Flex flexDirection="column" height="100%">
                  <SidebarPanelHeading px={3} py={2} bg="lightGray3">
                    {t('chat.filesSelected', { count: numFilesSelected })}
                  </SidebarPanelHeading>
                  <PanelDivider />
                  <ChatbotTargetList
                    chatbotId={chatbotId}
                    target={chat?.target}
                    targetById={{
                      ...pageTargetById,
                      /* ...chat.chatTargetById */
                    }}
                    dispatchChatbotAction={dispatchChatbotAction}
                  />
                </Flex>
              }
              content={
                <ScrollToBottom>
                  <Flex flexDirection="column" minHeight="100%" p={3}>
                    <Box flex={1} />
                    <ChatbotChatHistory
                      history={chat.history}
                      showErrorDisclosures={showErrorDisclosures}
                    />
                  </Flex>
                </ScrollToBottom>
              }
              footer={
                <ChatbotChatFooter chat={chat} />
              }
            />
          )}
        </Panel>
      )}
      <Icon
        alignSelf="flex-end"
        icon="brain-circuit"
        style={{ cursor: 'pointer' }}
        onClick={chatPopOver.toggle}
        fontSize={30}
        mt={3}
        mr={5}
      />
      <ChatbotScrollToBottomStyles />
    </Flex>
  );
});
