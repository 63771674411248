import * as React from 'react';
import { upperFirst } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CapitalizeCell } from './CapitalizeCell';
import { formatAdminDate } from './formatDate';

type UserEmailsTableProps = {
  emails: any[];
};

export const UserEmailsTable: React.FC<UserEmailsTableProps> = ({ emails }) => {
  const { isLarge, isExtraLarge } = useDeviceSize();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Subject',
        accessor: 'subject',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'sent',
        Header: 'Sent',
        accessor: 'date',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: CapitalizeCell,
        width: 120,
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [isExtraLarge],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={emails}
        hiddenColumns={!isLarge && !isExtraLarge ? ['sent'] : []}
        isSortable
        noFilteredDataPlaceholder="No emails match chosen filters"
      />
    </BasicTableStyles>
  );
};
