import * as React from 'react';
import { Box, Flex, FlexProps, SxStyleProp } from 'rebass/styled-components';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { get } from 'lodash';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Checkbox, CheckboxProps } from '@deepstream/ui-kit/elements/input/Checkbox';
import { ErrorMessage, HelperText } from './Field';
import { FieldContainer } from './FieldContainer';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

interface CheckboxFieldBaseProps {
  name?: string;
  /**
   * Label used by `FieldContainer`
   */
  label?: string;
  value?: boolean;
  /**
   * Label for the checkbox input
   */
  fieldLabel?: string | React.ReactNode;
  error?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: CheckboxProps['onChange'];
  checkboxStyle?: React.CSSProperties;
  helperText?: string | React.ReactNode;
  inputTooltip?: string;
  errorMessageStyle?: React.CSSProperties;
}

export const CheckboxFieldBase = ({
  name,
  label,
  hideLabel,
  required,
  value,
  fieldLabel,
  error,
  hideError,
  disabled,
  onChange,
  checkboxStyle,
  helperText,
  inputTooltip,
  errorMessageStyle,
}: CheckboxFieldBaseProps) => {
  const id = useUniqueId();

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
    >
      <Checkbox
        id={id}
        checked={value}
        name={name}
        onChange={onChange}
        label={fieldLabel}
        disabled={disabled}
        style={checkboxStyle}
        inputTooltip={inputTooltip}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }} ml="24px">
          <ErrorMessage error={error} fontWeight="normal" style={errorMessageStyle} />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

interface CheckboxFieldProps extends CheckboxFieldBaseProps {
  /**
   * Form field name
   */
  name: string;
}

/**
 * Formik-aware checkbox field component.
 */
export const CheckboxField = ({
  name,
  onChange: onChangeProp,
  ...props
}: CheckboxFieldProps) => {
  const [field, meta] = useField({ name });

  const onChange = React.useCallback(
    (event) => {
      if (onChangeProp) {
        onChangeProp(event);
      }

      field.onChange(event);
    },
    [field, onChangeProp],
  );

  return (
    <CheckboxFieldBase
      name={name}
      value={field.value}
      error={meta.touched && meta.error ? meta.error : undefined}
      onChange={onChange}
      {...props}
    />
  );
};

/**
 * Formik-aware checkbox field component that updates the formik
 * data on change.
 */
export const CheckboxField2 = ({
  name,
  ...props
}: CheckboxFieldProps) => {
  const [field, meta, formik] = useField({ name });

  return (
    <CheckboxFieldBase
      name={name}
      value={field.value}
      error={meta.touched && meta.error ? meta.error : undefined}
      onChange={(event: any) => { formik.setTouched(true); formik.setValue(Boolean(event.target.checked)); }}
      {...props}
    />
  );
};

export type CheckboxFieldArrayProps = FlexProps & {
  name: string;
  value?: string;
  label?: string;
  keyProperty?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  options: any[];
  required?: boolean;
  onChange?: any;
  disabled?: boolean;
  errorOverride?: string;
  autoFocus?: boolean;
  description?: string;
  errorMessageStyle?: React.CSSProperties;
  checkboxStyle?: React.CSSProperties;
  styledCheckboxSx?: SxStyleProp;
  CheckboxLabel?: React.FunctionComponent<any>;
};

export const CheckboxFieldArray: React.FC<CheckboxFieldArrayProps> = ({
  name,
  label,
  options,
  keyProperty,
  hideLabel,
  width,
  disabled,
  required,
  hideError,
  errorOverride,
  onChange,
  description,
  errorMessageStyle,
  checkboxStyle,
  styledCheckboxSx,
  CheckboxLabel,
  ...props
}) => {
  const [, meta, helper] = useField<string[]>(name);

  return (
    <FieldContainer
      name={name}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      description={description}
    >
      <FieldArray name={name}>
        {({ form: { errors, values }, push, remove }: FieldArrayRenderProps) => {
          const toggleCheckbox = (value: any) =>
            (event: any) => {
              if (event.target.checked) {
                push(value);
              } else {
                const index = keyProperty
                  ? get(values, name).findIndex((element: any) => element[keyProperty] === value[keyProperty])
                  : get(values, name).indexOf(value);
                remove(index);
              }

              if (onChange) {
                onChange(event);
              }
            };

          const isChecked = (optionValue: any) => keyProperty
            ? get(values, name).some((selectedValue: any) => optionValue[keyProperty] === selectedValue[keyProperty])
            : get(values, name).includes(optionValue);

          const error = errorOverride || get(errors, name) as string;

          return (
            <Box width={width}>
              <Flex
                onBlur={hideError ? (
                  undefined
                ) : (
                  (event: React.FocusEvent<HTMLDivElement, any>) => {
                    if (event.relatedTarget?.name !== name) {
                      helper.setTouched(true);
                    }
                  }
                )}
                {...props}
              >
                {options.map((opt) => (
                  <Box
                    key={keyProperty ? opt.value[keyProperty] : opt.value}
                    mr={props.flexDirection === 'row' ? 3 : 0}
                  >
                    <Checkbox
                      data-value={opt.value}
                      label={opt.label}
                      checked={isChecked(opt.value)}
                      name={name}
                      onChange={toggleCheckbox(opt.value)}
                      disabled={disabled || opt.isDisabled}
                      autoFocus={opt.autoFocus}
                      style={checkboxStyle}
                      styledCheckboxSx={styledCheckboxSx}
                      CheckboxLabel={CheckboxLabel}
                    />
                  </Box>
                ))}
              </Flex>
              {error && meta.touched && !hideError ? (
                <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
                  <ErrorMessage error={error} fontWeight="normal" style={errorMessageStyle} />
                </Box>
              ) : (
                null
              )}
            </Box>
          );
        }}
      </FieldArray>
    </FieldContainer>
  );
};
