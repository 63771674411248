import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { addMinutes, subMinutes } from 'date-fns';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { darken, transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { DateFormat, adjustDateFnsFormatToLocale, getDateFnsLocale, getDatePickerTimeFormat, localeFormatDate } from '@deepstream/utils';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Input } from './Input';
import { Bold } from '../Bold';
import { Datetime2 } from '../Datetime';
import { useCurrentUserLocale } from '../useCurrentUser';

const TIME_ITEM_HEIGHT = 40;

interface InputSuffixProps {
  icon: IconProps['icon'];
  disabled?: boolean;
  onClick?: React.EventHandler<any>;
}

const InputSuffix = ({ icon, disabled, onClick }: InputSuffixProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    sx={{
      position: 'absolute',
      right: 0,
      top: '1px',
      bottom: '1px',
      width: 40,
      borderLeft: 'secondary',
      cursor: disabled ? 'default' : 'pointer',
    }}
    onClick={onClick}
  >
    <Icon icon={icon} color="subtext" />
  </Flex>
);

export type DateInputProps = {
  value?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  onClick?: React.EventHandler<any>;
  disabled?: boolean
};

const DefaultDateInput = React.forwardRef<any, DateInputProps>(({ onClick, showTimeSelect, ...props }, ref) => {
  const locale = useCurrentUserLocale();
  const timeZone = localeFormatDate(new Date(), DateFormat.ZZZ, { locale });

  return (
    <Flex>
      <Box flex={1} sx={{ position: 'relative' }}>
        <Input
          ref={ref}
          style={{ paddingRight: '40px' }}
          onClick={onClick}
          {...props}
        />
        <InputSuffix icon="calendar-alt" onClick={onClick} disabled={props.disabled} />
      </Box>
      {showTimeSelect && (
        <Box ml={2} sx={{ position: 'relative' }}>
          <Input value={timeZone} disabled width="90px" />
          <InputSuffix icon="globe" disabled />
        </Box>
      )}
    </Flex>
  );
});

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <Flex alignItems="center" p={3} sx={{ borderBottom: 'lightGray', color: 'text', height: '55px' }} >
    <Bold flex={1} fontSize={4} textAlign="left">
      <Datetime2 value={date} format={DateFormat.MMMM_YYYY} />
    </Bold>
    <IconButton
      icon="chevron-left"
      disabled={prevMonthButtonDisabled}
      onClick={decreaseMonth}
      fontSize={5}
      mr="24px"
    />
    <IconButton
      icon="chevron-right"
      disabled={nextMonthButtonDisabled}
      onClick={increaseMonth}
      fontSize={5}
    />
  </Flex>
);

const containerCss = css<{ zIndex?: number }>`
  .react-datepicker {
    background-color: white;

    border: none;
    box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
    border-radius: 4px;
    font-family: ${props => props.theme.fonts.primary};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    margin: 0;
    z-index: ${props => props.zIndex || 151};
  }

  .react-datepicker__header {
    background-color: ${props => props.theme.colors.white};
    border: none;
    padding: 0;
  }

  .react-datepicker-time__header {
    padding: ${props => props.theme.space[3]}px 12px;
    text-align: left;
    font-size: ${props => props.theme.fontSizes[4]}px;
    border-bottom: ${props => props.theme.borders.lightGray};
    color: ${props => props.theme.colors.text};
    font-weight: 500;
    height: 55px;
  }

  .react-datepicker__day-names {
    margin: 0px 10px;
  }

  .react-datepicker__day-name {
    color: ${props => props.theme.colors.subtext};
  }

  .react-datepicker__day-name, .react-datepicker__day {
    font-size: ${props => props.theme.fontSizes[2]}px;
    font-weight: normal;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 2px ${props => props.theme.space[1]}px;
  }

  .react-datepicker__month {
    margin: 0 10px 10px;
  }

  .react-datepicker__day {
    outline: none;

    &.react-datepicker__day--disabled {
      cursor: default;
    }

    &:not(.react-datepicker__day--disabled) {
      color: ${props => props.theme.colors.text};
      border-radius: 50%;
    }

    &.react-datepicker__day--selected {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.primary};
      font-weight: 500;

      &:hover {
        background-color: ${props => darken(0.1, props.theme.colors.primary)};
      }
    }

    &.react-datepicker__day--today {
      font-weight: 500;
    }

    &.react-datepicker__day--outside-month:not(.react-datepicker__day--disabled):not(.react-datepicker__day--today):not(.react-datepicker__day--selected) {
      color: ${props => props.theme.colors.subtext};
    }

    &:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected) {
      &.react-datepicker__day--today {
        border: 2px solid ${props => props.theme.colors.primary};
        line-height: 25px;
        color: ${props => props.theme.colors.primary};
      }

      &.react-datepicker__day--keyboard-selected, &:hover {
        background-color: ${props => transparentize(0.9, props.theme.colors.primary)};
        outline: none;
      }
    }

    &.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }
  }

  .react-datepicker__time-container {
    border-left: ${props => props.theme.borders.lightGray};
    width: 120px;
  }

  .react-datepicker__time-box {
    width: 100% !important;
  }

  .react-datepicker__time-list-item {
    color: ${props => props.theme.colors.text};
    height: ${TIME_ITEM_HEIGHT}px !important;
    line-height: 30px;
    font-size: ${props => props.theme.fontSizes[2]}px;
    text-align: left;
    padding: 5px 12px;

    &.react-datepicker__time-list-item--selected {
      background-color: ${props => props.theme.colors.primary} !important;
      font-weight: 500 !important;
    }
  }
`;

export const GlobalDatePickerStyles = createGlobalStyle<any>`
  ${containerCss}
`;

const Container = styled(Box)`
  ${containerCss}
`;

export type DatePickerProps = {
  value?: ReactDatePickerProps['selected'];
  placeholder?: string;
  // dateFormat is not the same as moment.js format: https://date-fns.org/v2.16.1/docs/format
  dateFormat?: ReactDatePickerProps['dateFormat'];
  disabled?: ReactDatePickerProps['disabled'];
  placeholderText?: ReactDatePickerProps['placeholderText'];
  readOnly?: ReactDatePickerProps['readOnly'];
  minDate?: ReactDatePickerProps['minDate'];
  maxDate?: ReactDatePickerProps['maxDate'];
  popperPlacement?: ReactDatePickerProps['popperPlacement'];
  popperModifiers?: ReactDatePickerProps['popperModifiers'];
  showTimeSelect?: ReactDatePickerProps['showTimeSelect'];
  showTimeSelectOnly?: ReactDatePickerProps['showTimeSelectOnly'];
  timeIntervals?: ReactDatePickerProps['timeIntervals'];
  timeCaption?: ReactDatePickerProps['timeCaption'];
  onChange?: ReactDatePickerProps['onChange'];
  onBlur?: ReactDatePickerProps['onBlur'];
  checkMinDateOnOpen?: boolean;
  portalId?: ReactDatePickerProps['portailId'];
  autoFocus?: ReactDatePickerProps['autoFocus'];
  onCalendarClose?: ReactDatePickerProps['onCalendarClose'];
  onClickOutside?: ReactDatePickerProps['onClickOutside'];
  DateInput?: any,
  checkMaxDateOnOpen?: boolean;
  shouldCloseOnSelect?: boolean;
  onSelect?: ReactDatePickerProps['onSelect'];
};

export const DatePicker = ({
  value,
  checkMinDateOnOpen = true,
  placeholder,
  DateInput = DefaultDateInput,
  checkMaxDateOnOpen = true,
  dateFormat,
  ...props
}: DatePickerProps) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();
  const { minDate, maxDate, showTimeSelect, onChange } = props;

  const filterTime = React.useCallback(
    (time: Date) => {
      if (!minDate && !maxDate) {
        return true;
      }

      const selectedTime = new Date(time);

      // This is needed because of a bug in react-datepicker: https://github.com/Hacker0x01/react-datepicker/issues/2533
      if (value) {
        selectedTime.setFullYear(value.getFullYear());
        selectedTime.setMonth(value.getMonth());
        selectedTime.setDate(value.getDate());
      }

      return (
        (minDate && minDate.getTime() < selectedTime.getTime()) ||
        (maxDate && maxDate.getTime() > selectedTime.getTime())
      );
    },
    [value, minDate, maxDate],
  );

  const calculateTimeListHeight = React.useCallback(
    () => {
      if (showTimeSelect) {
        const calendarElement = document.getElementsByClassName('react-datepicker__month')[0] as HTMLElement;
        const timeListElement = document.getElementsByClassName('react-datepicker__time-list')[0] as HTMLElement;

        if (calendarElement && timeListElement) {
          timeListElement.style.height = `${calendarElement.clientHeight + 10 + 34}px`;
        }
      }
    },
    [showTimeSelect],
  );

  const calculateTimeListHeightDelayed = React.useCallback(
    () => setTimeout(calculateTimeListHeight, 0),
    [calculateTimeListHeight],
  );

  const checkMinAllowedDate = React.useCallback(
    () => {
      // When setting a date + time first and then setting the date to minDate, we need to check
      // if the previously selected time is allowed. If not, set the time to the minimum allowed value
      if (value && minDate && value.getTime() < minDate.getTime()) {
        const minutesDiff = 15 - (minDate.getMinutes() % 15);
        const newDate = addMinutes(minDate, minutesDiff);
        onChange(newDate, undefined);
        calculateTimeListHeightDelayed();
      }
    },
    [value, minDate, onChange, calculateTimeListHeightDelayed],
  );

  const checkMaxAllowedDate = React.useCallback(
    () => {
      // When setting a date + time first and then setting the date to maxDate, we need to check
      // if the previously selected time is allowed. If not, set the time to the maximum allowed value
      if (value && maxDate && value.getTime() > maxDate.getTime()) {
        const minutesDiff = 15 - (maxDate.getMinutes() % 15);
        const newDate = subMinutes(maxDate, minutesDiff);
        onChange(newDate, undefined);
        calculateTimeListHeightDelayed();
      }
    },
    [value, maxDate, onChange, calculateTimeListHeightDelayed],
  );

  return (
    <Container>
      <ReactDatePicker
        selected={value}
        customInput={<DateInput showTimeSelect={showTimeSelect} placeholder={placeholder} />}
        locale={getDateFnsLocale(locale)}
        renderCustomHeader={Header}
        showPopperArrow={false}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '0px,4px',
          },
        }}
        popperPlacement="top"
        dateFormat={dateFormat ? adjustDateFnsFormatToLocale(dateFormat, locale) : undefined}
        timeFormat={getDatePickerTimeFormat(locale)}
        timeIntervals={15}
        timeCaption={t('general.time')}
        filterTime={filterTime}
        onCalendarOpen={() => {
          calculateTimeListHeightDelayed();

          if (checkMinDateOnOpen) {
            checkMinAllowedDate();
          }

          if (checkMaxDateOnOpen) {
            checkMaxAllowedDate();
          }
        }}
        onMonthChange={calculateTimeListHeightDelayed}
        onSelect={calculateTimeListHeightDelayed}
        onKeyDown={calculateTimeListHeightDelayed}
        {...props}
      />
    </Container>
  );
};
