import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { transparentize, darken } from 'polished';
import styled from 'styled-components';
import { Icon } from './icon/Icon';

const Container = styled(Flex)`
  display: inline-flex;
  height: 24px;
  padding: 3px 12px;
  background-color: ${props => transparentize(0.8, props.theme.colors.primary)};
  border-radius: 12px;
  max-width: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

const CloseButton = styled.div`
  font-size: ${props => props.theme.fontSizes[2]}px;
  width: 10px;
  height: 10px;
  flex: 0 0 auto;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${props => props.theme.colors.gray};
  border-radius: 50%;
  transition: color 300ms, background-color 300ms, border-color 300ms, box-shadow 300ms;

  &:focus:not(:disabled) {
    background-color: ${props => transparentize(0.80, props.theme.colors.gray)};
    box-shadow: ${props => `0 0 0 5px ${transparentize(0.80, props.theme.colors.gray)}`};
    outline: none;
  }

  &:hover:not(:disabled):not(:focus) {
    color: ${props => darken(0.2, props.theme.colors.gray)};
    cursor: pointer;
  }
`;

export const Chip = ({ children, removeButtonProps }: { removeButtonProps: any; children: React.ReactNode }) => (
  <Container alignItems="center">
    <StyledText flex={1}>{children}</StyledText>
    <CloseButton
      role="button"
      tabIndex={0}
      className="chip-close-button" // The className is only used for faster querying for programmatically setting the focus
      {...removeButtonProps}
    >
      <Icon icon="times" />
    </CloseButton>
  </Container>
);
