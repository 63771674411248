import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { CapitalizeCell } from './CapitalizeCell';
import { CompanyLinkCell } from './CompanyLinkCell';
import { useAdminNavigation } from './AppRouting';

type CompaniesTableProps = {
  companies: any[];
};

export const CompaniesTable = ({ companies }: CompaniesTableProps) => {
  const { isExtraLarge, isSmall, isExtraSmall } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, CompanyLinkCell),
      },
      {
        Header: 'Type',
        accessor: 'group',
        Cell: CapitalizeCell,
        width: isExtraLarge ? 180 : 90,
      },
      {
        id: 'firstClaim',
        Header: 'First claim',
        accessor: 'claims[0].date',
        Cell: DatetimeCell,
        width: isExtraLarge ? 224 : 164,
      },
    ],
    [isExtraLarge],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={companies}
        isPaginated
        smallPageControls
        onRowClick={company => navigation.navigateToCompany(company._id)}
        hiddenColumns={isSmall || isExtraSmall ? ['firstClaim'] : []}
      />
    </BasicTableStyles>
  );
};
