import { uniqueId } from 'lodash';
import { Attachment } from '@deepstream/common/rfq-utils';
import { UploadFn } from './types';

export const fakeUploadFn: UploadFn = ({ file, onProgress }) => {
  const total = 10000;
  let loaded = 0;

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      loaded += 1000;

      onProgress({ total, loaded });

      if (Math.random() < 0.05) {
        clearInterval(intervalId);
        reject();
      }

      if (loaded === total) {
        clearInterval(intervalId);
        resolve({
          _id: uniqueId('attachment'),
          name: file.name,
          notSaved: false,
          title: file.name,
        } as Attachment);
      }
    }, 250);
  });
};

export const fastFakeUploadFn: UploadFn = ({ file, onProgress }) => {
  const total = 10000;
  let loaded = 0;

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      loaded += 1000;

      onProgress({ total, loaded });

      if (Math.random() < 0.05) {
        clearInterval(intervalId);
        reject();
      }

      if (loaded === total) {
        clearInterval(intervalId);
        resolve({
          _id: uniqueId('attachment'),
          name: file.name,
          notSaved: false,
          title: file.name,
        } as Attachment);
      }
    }, 10);
  });
};
