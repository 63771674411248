import { InclusionOption } from './types';

export const keyByInclusionOptionType = {
  [InclusionOption.EXCLUDED]: 'request.vesselPricing.inclusions.notIncluded',
  [InclusionOption.INCLUDED]: 'request.vesselPricing.inclusions.included',
  [InclusionOption.FLEXIBLE]: 'request.vesselPricing.inclusions.flexible',
};

export const timePeriods = [
  'year',
  'month',
  'week',
  'day',
];
