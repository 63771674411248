import { get } from 'lodash';
import { CellProps } from 'react-table';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Badge } from './Badge';
import { CompanyLink } from './CompanyLink';
import { CompanyLogo } from './CompanyLogo';
import { useDeviceSize } from './ui/useDeviceSize';

const CompanyText = styled.span`
  inline-flex
`;

/**
 * Cell component rendering name and logo of a `CompanyMinimized`.
 */
export const CompanyMinimizedNameAndLogoCell = ({
  cell,
  row,
  hasLink = true,
  minHeight = '51px',
  height,
  companyIdField = '_id',
}: CellProps<CompanyMinimized> & {
  minHeight?: string;
  height?: string;
  companyIdField?: string;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isExtraLarge } = useDeviceSize();
  const CompanyName = hasLink ? CompanyLink : CompanyText;

  const style = {
    verticalAlign: isExtraLarge ? 'middle' : undefined,
    color: row.original.isPending ? theme.colors.subtext : theme.colors.text,
  };

  return (
    <Flex alignItems="center" minHeight={minHeight} height={height}>
      <Truncate>
        {isExtraLarge && (
          <CompanyLogo companyId={get(row.original, companyIdField)} size="xs" />
        )}
        <CompanyName companyId={get(row.original, companyIdField)} style={style} >
          {cell.value || `(${t('general.notSet')})`}
        </CompanyName>
      </Truncate>
      {row.original.isPending && (
        <Badge ml={2} tooltip={t('general.thisCompanyHasntSignedUpYet')}>
          {t('invite.status.invited')}
        </Badge>
      )}
    </Flex>
  );
};
