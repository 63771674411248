import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { callAll } from '@deepstream/utils/callAll';
import { useRouter } from '@tanstack/react-router';

type EditPayingStatusProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditPayingStatusModal: React.FC<EditPayingStatusProps> = ({ company, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const router = useRouter();

  const [updateCompanySubscription] = useMutation(
    adminApi.updateCompanySubscription,
    {
      onSuccess: () => toaster.success('Paying company status successfully updated'),
      onError: () => toaster.error('An error occurred when trying to update paying company status'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['company', { companyId: company._id }]),
        () => router.invalidate(),
      ),
    },
  );

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          isPaying: !!company.subscription,
        }}
        validationSchema={
          yup.object().shape({
            isPaying: yup.boolean().required('Required'),
          })
        }
        onSubmit={async ({ isPaying }, { setSubmitting }) => {
          await updateCompanySubscription({
            companyId: company._id,
            isPaying,
          }, {
            onSettled: () => setSubmitting(false),
            onSuccess: onSave,
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Paying company?
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <MessageBlock variant="info" mt={0}>
                  Paying companies can create new requests
                </MessageBlock>
                <SwitchField name="isPaying" aria-label="Edit company paying status" />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
