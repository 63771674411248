import * as React from 'react';
import { identity, isFunction } from 'lodash';

/**
 * Keeps track of component-level state just like {@link React.useState} while
 * syncing the state with `localStorage`.
 *
 * Sharing the same `localStorage` entry by using the same {@link key} in
 * multiple instances of this hook is not supported since the state persisted
 * in `localStorage` only gets read on mount, so `localStorage` updates
 * triggered by one instance wouldn't update the states of other instances.)
 */
export const useLocalStorageState = <T>({
  key,
  defaultValue,
  mapInitialValue = identity,
}: {
  /**
   * The `localStorage` key at which the state gets persisted.
   */
  key: string;
  /**
   * The value to which {@link value} gets set when the component is mounted
   * but couldn't get restored from `localStorage` or a function to return
   * that value.
   */
  defaultValue: T | (() => T);
  /**
    * Maps the value parsed on mount from `localStorage` to another value of
    * the same type; the return value gets set as the initial state's value.
    * This allows to maintain integrity in cases where `value` must fulfill
    * strict identity constraints that the value parsed from `localStorage`
    * wouldn't fulfill.
    */
  mapInitialValue?: (value: T) => T;
}): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = React.useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item
        ? mapInitialValue(JSON.parse(item))
        : isFunction(defaultValue) ? defaultValue() : defaultValue;
    } catch (err) {
      return isFunction(defaultValue) ? defaultValue() : defaultValue;
    }
  });

  React.useEffect(() => {
    try {
      // `undefined` can't get stringified so we convert it to `null`
      const item = JSON.stringify(value ?? null);
      localStorage.setItem(key, item);
    } catch (err) {} // eslint-disable-line no-empty
  }, [key, value]);

  return [value, setValue];
};
