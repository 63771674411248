import { flatten, flatMap, remove, cloneDeep, last, sortBy } from 'lodash';

// Transforms an array of tags to a tree of nodes with
// `parent` and `children` keys
export const toTagTree = (tagById: { [key: string]: any }) => {
  const root = [] as any[];

  for (const tag of Object.values(tagById)) {
    const parent = tag.parent && tagById[tag.parent.toString()];

    tag.parent = parent;

    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(tag);
    } else {
      root.push(tag);
    }
  }

  // Sort level 3 tags by name to ensure they appear alphabetically under
  // their corresponding level 2 tag
  for (const tag of Object.values(tagById)) {
    if (tag.level === 1 || tag.level === 2) {
      tag.children = sortBy(tag.children, 'name');
    }
  }

  return root;
};

export const getAncestors = (tag: any, ancestors: any[] = []): any =>
  tag.parent
    ? getAncestors(tag.parent, [...ancestors, tag.parent])
    : ancestors;

export const getAncestorsForBasicTag = (tagsById: any, tag: any, ancestors: any[] = []): any => {
  const parentTag = tag.parent && tagsById[tag.parent];
  return parentTag
    ? getAncestorsForBasicTag(tagsById, parentTag, [...ancestors, parentTag])
    : ancestors;
};

export const getChildren = (tag: any): any =>
  tag.children
    ? flatten([tag, ...tag.children.map(getChildren)])
    : tag;

export const getLevel1Ancestor = (tag: any) => {
  const lastAncestor: any = last(getAncestors(tag));

  if (lastAncestor.level !== 1) {
    // The last ancestor should always be a level 1 tag (otherwise we have
    // an orphaned tag subtree and something is very wrong)
    throw new Error(`
      Tag ${tag._id} (level ${tag.level}) does not have a level 1 ancestor. This
      means we have an orphaned tag subtree (which should never happen)
    `);
  }

  return lastAncestor;
};

export const getVisibleTags = (tree: any, tagFilter: string, textFilter: string) => {
  const level1 = cloneDeep(tree);

  // Based on the the selected level 1 filter, we find the corresponding subtree
  const visibleTree = tagFilter
    ? level1.filter((tag: any) => tag._id === tagFilter)
    : level1;

  // Omit any level 2 tags that have no children
  // TODO: figure out why there are tags without children
  const level2 = flatMap(visibleTree, tag => tag.children);

  // Given a text filterRemove level 3 tags that don't match the text filter
  if (textFilter) {
    const isNameMatch = (tag: any) => !tag.name.match(new RegExp(textFilter, 'i'));

    for (const tag of level2) {
      // Remove all level 3 tags that don't match text filter
      remove(tag.children, isNameMatch);
    }
  }

  // Remove empty level 2 tags
  remove(level2, tag => !tag.children || !tag.children.length);

  return {
    level1,
    level2,
  };
};
