import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { sumBy } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { Panel, PanelHeader, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { LoadingPanel } from '@deepstream/ui/ui/Loading';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { Bold } from '@deepstream/ui/Bold';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { UserLinkCell } from './UserLinkCell';
import { SubscriptionPropertyList } from './SubscriptionPropertyList';
import { Page } from './Page';
import { subscriptionRoute, useAdminNavigation } from './AppRouting';

const LabeledValue = ({ label, value }) => (
  <Box>
    <Text fontSize={1} fontWeight={500} mb={1}>
      {label}
    </Text>
    <Flex alignItems="center" fontSize={2}>
      <Bold>{value}</Bold>
    </Flex>
  </Box>
);

type CompaniesTableProps = {
  companies: any[];
  getRoute: (user: any) => string;
};

export const CompaniesTable = ({ companies }: CompaniesTableProps) => {
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [{ Header: 'Name', accessor: 'name', Cell: CompanyMinimizedNameAndLogoCell }],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={companies}
        onRowClick={row => navigation.navigateToCompany(row._id)}
      />
    </BasicTableStyles>
  );
};

export const AddOnUsageTable = ({ usage }) => {
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'dateRecorded',
        Header: 'Date recorded',
        accessor: 'recordingTimestamp',
        Cell: DatetimeCell,
        sortType: 'datetime',
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'user.name',
        Cell: nestCells(TruncateCell, UserLinkCell),
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        Cell: TruncateCell,
        sortType: 'caseInsensitive',
      },
      {
        id: 'lastSeen',
        Header: 'Last active',
        accessor: 'user.lastSeen',
        Cell: DatetimeCell,
        sortType: 'datetime',
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        isSortable
        columns={columns}
        data={usage}
        onRowClick={row => navigation.navigateToUser(row.user._id)}
      />
    </BasicTableStyles>
  );
};

export const SubscriptionPage = () => {
  const toaster = useToaster();
  const { subscriptionUuid } = subscriptionRoute.useParams();
  const adminApi = useAdminApi();

  const { data, isLoading } = useQuery(
    ['subscription', { subscriptionUuid }],
    wrap(adminApi.getSubscription),
  );

  const [requestSubscriptionUsageUpdate, { isLoading: isLoadingUsageRequest }] = useMutation(
    adminApi.requestSubscriptionUsageUpdate,
    {
      onSuccess: () => toaster.success('Usage update requested'),
      onError: () => toaster.error('Could not request usage update'),
    },
  );

  return (
    <Page>
      {isLoading ? (
        <LoadingPanel />
      ) : (
        <Stack gap={3}>
          <Panel>
            <PanelHeader heading="Subscription">
              <Button
                small
                iconLeft="sync"
                disabled={isLoadingUsageRequest}
                onClick={() => requestSubscriptionUsageUpdate({ subscriptionUuid })}
              >
                Request usage update
              </Button>
            </PanelHeader>
            <PanelDivider />
            <SubscriptionPropertyList subscription={data.subscription} />
          </Panel>

          <Panel heading="Companies">
            <CompaniesTable
              companies={data.companies}
              getRoute={company => `/company/${company._id}`}
            />
          </Panel>

          {data.subscription.addOns.map(({ id, addOn, usage }) => (
            <Panel key={id}>
              <PanelHeader heading={`Add-on: ${addOn.name}`}>
                <LabeledValue label="Amount" value={sumBy(usage, 'amount')} />
              </PanelHeader>
              <PanelDivider />
              <AddOnUsageTable usage={usage} />
            </Panel>
          ))}
        </Stack>
      )}
    </Page>
  );
};
