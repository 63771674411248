import * as React from 'react';
import {
  Field as FormikField,
  FieldProps as FormikFieldProps,
  FieldArray,
  FieldArrayRenderProps,
  useField,
} from 'formik';
import styled from 'styled-components';
import { Text, Box, BoxProps, Flex, FlexProps, TextProps, SxStyleProp } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Radio, RadioProps } from '../ui/Radio';
import { Input } from '../ui/Input';
import { Avatar } from '../Avatar';
import { ImageUploadControls } from '../ui/ImageUploadControls';
import { UploadFn } from '../ui/types';
import { Required } from '../Required';

export const Fieldset = ({ sx = {}, ...props }) => (
  <Box as="fieldset" sx={{ border: 0, margin: 0, padding: 0, ...sx }} {...props} />
);

export const Legend = (props) => (
  <Text as="legend" fontWeight={500} fontSize={2} p={0} color="darkGray" {...props} />
);

type ErrorMessageProps = {
  error: React.ReactNode | string | null | undefined;
  fontWeight?: React.CSSProperties['fontWeight'];
  hide?: boolean;
  style?: any;
  sx?: SxStyleProp;
};

export const ErrorMessage = ({ hide, error, fontWeight = 500, style, sx = {} }: ErrorMessageProps) => (
  (!hide && error) ? (
    <Text color="danger" fontSize={1} mt={1} fontWeight={fontWeight} style={style} sx={sx}>
      <Icon icon="exclamation-circle" mr="5px" />
      {error}
    </Text>
  ) : (
    null
  )
);

export const HelperText = ({ text, ...props }: TextProps & { text: string | React.ReactNode }) => (
  <Text color="subtext" fontSize={1} mt={1} {...props}>
    {text}
  </Text>
);

type FieldProps = {
  name: string;
  hideError?: boolean;
  errorOverride?: React.ReactNode | string;
  handleChange?: any;
};

export type CheckboxFieldProps =
  React.HTMLProps<HTMLInputElement> &
  BoxProps &
  FieldProps;

/**
 * @deprecated
 */
export const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, hideError = false, errorOverride, width, ...props }) => (
  <FormikField name={name}>
    {({ field, form: { errors } }: FormikFieldProps) => (
      <Box width={width}>
        <Checkbox checked={field.value} name={name} onChange={field.onChange} {...props} />
        <ErrorMessage hide={hideError} error={errorOverride || errors[field.name] as string} />
      </Box>
    )}
  </FormikField>
);

export type CheckboxFieldArrayProps =
  {
    options: any[];
    keyProperty?: any;
  } &
  React.HTMLProps<HTMLInputElement> &
  FlexProps &
  FieldProps;

/**
 * @deprecated
 */
export const CheckboxFieldArray: React.FC<CheckboxFieldArrayProps> = ({
  name,
  options,
  keyProperty,
  hideError = false,
  errorOverride,
  width,
  disabled,
  ...props
}) => (
  <FieldArray name={name}>
    {({ form: { errors, values }, push, remove }: FieldArrayRenderProps) => {
      const toggleCheckbox = (value: any) =>
        (event: any) => {
          if (event.target.checked) {
            push(value);
          } else {
            const index = keyProperty
              ? values[name].findIndex((element: any) => element[keyProperty] === value[keyProperty])
              : values[name].indexOf(value);
            remove(index);
          }
        };

      const isChecked = (optionValue: any) => keyProperty
        ? values[name].some((selectedValue: any) => optionValue[keyProperty] === selectedValue[keyProperty])
        : values[name].includes(optionValue);

      return (
        <Box width={width}>
          <Flex {...props}>
            {options.map((opt) => (
              <Box key={opt.value[keyProperty] || opt.value} mr={props.flexDirection === 'row' ? 3 : 0}>
                <Checkbox
                  label={opt.label}
                  checked={isChecked(opt.value)}
                  name={name}
                  onChange={toggleCheckbox(opt.value)}
                  disabled={disabled}
                />
              </Box>
            ))}
          </Flex>
          <ErrorMessage
            hide={hideError}
            error={errorOverride || errors[name] as string}
            fontWeight="normal"
          />
        </Box>
      );
    }}
  </FieldArray>
);

type RadioFieldProps =
  React.HTMLProps<HTMLInputElement> &
  BoxProps &
  FieldProps &
  RadioProps;

/**
 * @deprecated
 */
export const RadioField = ({
  name,
  hideError = false,
  errorOverride,
  width,
  value,
  mr,
  ...props
}: RadioFieldProps) => (
  <FormikField name={name}>
    {({ field, form: { errors } }: FormikFieldProps) => (
      <Box width={width} mr={mr}>
        <Radio checked={field.value === value} name={name} onChange={field.onChange} value={value} {...props} />
        <ErrorMessage hide={hideError} error={errorOverride || errors[field.name] as string} />
      </Box>
    )}
  </FormikField>
);

export type TextFieldProps =
  React.HTMLProps<HTMLInputElement> &
  BoxProps &
  FieldProps &
  { noFocus?: boolean; errorStyle?: any };

/**
 * Use `form/TextField.ts` instead
 * @deprecated
 */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({
  name,
  hideError = false,
  errorOverride,
  type,
  width,
  handleChange,
  errorStyle,
  ...props
}, ref) => (
  <FormikField name={name}>
    {({ field, form: { errors, touched } }: FormikFieldProps) => {
      const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        if (handleChange) {
          handleChange(event);
        }
      };
      return (
        <Box width={width}>
          <Input type={type} ref={ref} {...props} {...field} onChange={onChange} />
          {touched[field.name] && (
            <ErrorMessage
              hide={hideError}
              error={errorOverride || errors[field.name] as string}
              style={errorStyle}
            />
          )}
        </Box>
      );
    }}
  </FormikField>
));

TextField.displayName = 'TextField';

type LabelProps = BoxProps & {
  label: string;
  required?: boolean;
};

const LabelBase: React.FC<LabelProps> = ({ label, required, children, ...props }) => (
  <Box as="label" {...props}>
    <Text mb={1} sx={{ fontWeight: 500, fontSize: 1 }}>
      {label}
      {required && <Required />}
    </Text>
    {children}
  </Box>
);

/**
 * @deprecated
 */
export const Label = styled(LabelBase)`
  display: block;

  & + & {
    margin-top: ${props => props.theme.space[3]}px;
  }
`;

export type AvatarFieldProps =
  { userId: string; uploadFn: UploadFn } &
  BoxProps &
  FieldProps;

/**
 * @deprecated
 */
export const AvatarField: React.FC<AvatarFieldProps> = ({
  userId,
  name,
  hideError = false,
  errorOverride,
  width,
  uploadFn,
}) => {
  const [field, meta, helpers] = useField({ name });

  return (
    <Box width={width}>
      <ImageUploadControls
        initialAttachment={field.value ? { _id: field.value } as any : null}
        onChange={attachment => helpers.setValue(attachment?._id ?? null)}
        uploadFn={uploadFn}
      >
        <Avatar
          userId={userId}
          avatarId={field.value}
          width="xl"
          sx={{ margin: 'auto' }}
        />
      </ImageUploadControls>
      <ErrorMessage
        hide={hideError}
        error={errorOverride || meta.error}
      />
    </Box>
  );
};
