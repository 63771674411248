import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { UserMinimized } from '@deepstream/common/rfq-utils';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';

export const AdminContractRecipientTable = ({
  onRowClick,
}: {
  onRowClick: (sender: UserMinimized) => void;
}) => {
  const { recipients } = useContractData();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: CompanyMinimizedNameAndLogoCell,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={recipients}
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
