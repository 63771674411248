import { useTranslation } from 'react-i18next';
import { QuestionnaireRenewalConfig, timeUnitToRenewalFrequencyTranslationKey, timeUnitToRenewalTranslationKey } from '@deepstream/common/preQual';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const RenewalFrequency = ({ value, onlyFrequency = false }: { value: QuestionnaireRenewalConfig, onlyFrequency?: boolean }) => {
  const { t } = useTranslation('preQualification');
  const { isRecurring, frequency } = value;
  const { unit, amount } = frequency;

  return isRecurring && unit && amount ? (
    <>
      {onlyFrequency ? (
        t(timeUnitToRenewalFrequencyTranslationKey[unit], { count: amount })
      ) : (
        t(timeUnitToRenewalTranslationKey[unit], { count: amount })
      )}
    </>
  ) : (
    <EmDash />
  );
};
