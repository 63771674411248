import { useQuery } from 'react-query';
import { find } from 'lodash';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Contract } from '@deepstream/common/contract';
import { useAdminContractQueryKey } from '@deepstream/ui/modules/Contracts/useContract';

export const useAdminContract = (contractId: string) => {
  const adminApi = useAdminApi();
  const queryKey = useAdminContractQueryKey(contractId);

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminContract),
  );
};

export const getUser = (contract: Contract, companyId: string, userId: string) => {
  const team = contract.teamById[companyId];

  if (!team) {
    return null;
  }

  const user = find(team.users, { _id: userId });

  if (!user) {
    return null;
  }

  return { ...user, isOwner: team.owners.includes(userId) };
};
