import { useQuery } from 'react-query';
import { useApi, useAdminApi, wrap } from '../../../api';
import { useIsAppAdmin } from '../../../useIsAppAdmin';

export const useCompanyUsers = (companyId: string) => {
  const api = useApi();
  const adminApi = useAdminApi();
  const isAppAdmin = useIsAppAdmin();

  return useQuery(
    ['users', { companyId }],
    isAppAdmin ? wrap(adminApi.getCompanyUsers) : wrap(api.getCompanyUsers),
  );
};
