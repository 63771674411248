import { useMemo } from 'react';
import { ModifierPhases } from '@popperjs/core';

export const useSameWidthModifier = ({ enabled }: { enabled: boolean }) => {
  return useMemo(
    () => ({
      name: 'sameWidth',
      enabled,
      phase: 'beforeWrite' as ModifierPhases,
      requires: ['computeStyles'],
      fn({ state }) {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect({ state }) {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
      },
    }),
    [enabled],
  );
};
