import { Link as RouterLink } from '@tanstack/react-router';
import styled from 'styled-components';

export const TableLink: any = styled(RouterLink)`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
