import { IconValue } from '../icons';
import { ContractStatus } from './contract';

type Icon = {
  value: IconValue;
  color: string;
  isRegular?: boolean;
};

interface StatusConfig {
  status: ContractStatus;
  label: string;
  icon: Icon;
}

export interface StatusesConfig {
  [key: string]: StatusConfig;
}

// TODO stop using label directly, and use const { t } = useTranslation(['contracts']); t(`status.${status}` instead
export const contractStatusConfig: StatusesConfig = {
  [ContractStatus.DRAFT]: {
    status: ContractStatus.DRAFT,
    label: 'Drafting',
    icon: {
      value: 'pencil',
      color: 'subtext',
    },
  },
  [ContractStatus.NEGOTIATION]: {
    status: ContractStatus.NEGOTIATION,
    label: 'Negotiating',
    icon: {
      value: 'arrow-right-arrow-left',
      color: 'warning',
    },
  },
  [ContractStatus.AGREED]: {
    status: ContractStatus.AGREED,
    label: 'Agreed pending start',
    icon: {
      value: 'circle-check',
      color: 'success',
      isRegular: true,
    },
  },
  [ContractStatus.ACTIVE]: {
    status: ContractStatus.ACTIVE,
    label: 'Active',
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [ContractStatus.EXPIRED]: {
    status: ContractStatus.EXPIRED,
    label: 'Expired',
    icon: {
      value: 'calendar-xmark',
      color: 'subtext',
    },
  },
  [ContractStatus.FAILED]: {
    status: ContractStatus.FAILED,
    label: 'Negotiation failed',
    icon: {
      value: 'circle-xmark',
      color: 'danger',
      isRegular: true,
    },
  },
  [ContractStatus.TERMINATED]: {
    status: ContractStatus.TERMINATED,
    label: 'Terminated',
    icon: {
      value: 'circle',
      color: 'danger',
    },
  },
  [ContractStatus.DELETED]: {
    status: ContractStatus.DELETED,
    label: 'Deleted',
    icon: {
      value: 'trash',
      color: 'subtext',
    },
  },
};
