import { useCallback } from 'react';
import { ChangeType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { compact, keyBy, mapValues } from 'lodash';
import * as yup from 'yup';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ModalForm } from '../../../ModalForm';
import { RequestRole, RequestTeamUser } from '../../../types';
import { useConfirmDialog } from '../../../ui/useModalState';
import { UserDetails } from '../../../UserDetails';
import * as rfx from '../../../rfx';
import { useIsAppAdmin } from '../../../useIsAppAdmin';
import { ConfirmRemoveUserDialog } from './ConfirmRemoveUserDialog';
import { RequestTeamRolePermissionFields } from './RequestTeamRolePermissionFields';
import { PermissionRow, usePermissionRows } from './usePermissionRows';
import { getPageRoleChanges } from './utils';
import { useCurrentUser } from '../../../useCurrentUser';
import { useCurrentCompanyId } from '../../../currentCompanyId';

const RemoveUserButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      variant="danger-outline"
      {...props}
    >
      {t('teamManagement.removeUser')}
    </Button>
  );
};

const getRequestRoleChanges = (
  initialRole: RequestRole,
  submittedRole: RequestRole,
  user: RequestTeamUser,
) =>
  initialRole === submittedRole
    ? []
    : [{
      type: ChangeType.REQUEST_OWNER_UPDATED,
      userId: user._id,
      companyId: user.companyId,
      isOwner: submittedRole === RequestRole.OWNER,
    }];

export const getRfqRoles = (permissionRows: PermissionRow[]) => {
  const pages = compact([
    ...permissionRows.map(permissionRow => permissionRow.page),
    ...permissionRows.map(permissionRow => permissionRow.linkedEvaluationPage),
  ]);

  return mapValues(keyBy(pages, 'pageId'), 'role');
};

export const RequestTeamEditUserModal = ({
  isOpen,
  close,
  user,
}: {
  isOpen: boolean;
  close: any;
  user: RequestTeamUser;
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { confirm, ...dialogProps } = useConfirmDialog();
  const { pages, teamById } = rfx.useStructure();
  const saveChanges = rfx.useSaveChanges();
  const isAdmin = useIsAppAdmin();

  const team = teamById[user.companyId];

  const rfqRoles = team?.users[user._id]?.rfqRoles;

  // @ts-ignore ts(2345) FIXME: Argument of type '{ [key: string]: PageRole; } | undefined' is not assignable to parameter of type 'Record<string, PageRole>'.
  const permissionRows = usePermissionRows(pages, rfqRoles, user.requestRole === RequestRole.OWNER);

  const openRemoveUserModal = useCallback(
    (user: RequestTeamUser) => {
      confirm(async () => saveChanges({
        changes: [{
          type: ChangeType.TEAM_MEMBER_REMOVED,
          userId: user._id,
          name: user.name || user.email,
          companyId: user.companyId,
        }],
      }, {
        onSettled: close,
      }));
    }, [close, confirm, saveChanges]);

  const initialValues = {
    requestRole: user.requestRole,
    permissions: permissionRows,
    selectedUser: user,
  };

  const isCurrentUser = user._id === currentUser._id && user.companyId === currentCompanyId;
  const isSoleRequestOwner = team?.owners.length === 1 && team?.owners[0] === user._id;

  return (
    <ModalForm
      heading={t('teamManagement.editUser')}
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          requestRole: yup.string().oneOf(Object.values(RequestRole)),
        })
      }
      disableSubmitIfNotDirty
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async (values) => {
        const pageRoleChanges = getPageRoleChanges(
          getRfqRoles(initialValues.permissions),
          getRfqRoles(values.permissions),
          user.companyId,
          user._id,
        );
        const requestRoleChanges = getRequestRoleChanges(initialValues.requestRole, values.requestRole, user);

        await saveChanges({
          changes: [
            ...pageRoleChanges,
            ...requestRoleChanges,
          ],
        }, {
          onSettled: close,
        });
      }}
      submitLabel={t('general.saveChanges')}
      footerLeftButton={isAdmin ? (
        null
      ) : (
        <RemoveUserButton
          onClick={() => openRemoveUserModal(user)}
          disabled={isCurrentUser || isSoleRequestOwner}
        />
      )}
    >
      <UserDetails user={user} />

      <RequestTeamRolePermissionFields
        initialPermissionRows={permissionRows}
        initialRequestRole={initialValues.requestRole}
        companyId={user.companyId}
      />

      <ConfirmRemoveUserDialog user={user} {...dialogProps} />
    </ModalForm>
  );
};
