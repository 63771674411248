import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { filterMultipleValues, FilterSelect } from '@deepstream/ui/FilterSelect';
import { TemplateLinkCell } from '../../TemplateLinkCell';
import { UserLinkCell } from '../../UserLinkCell';
import { formatAdminDate } from '../../formatDate';
import { useAdminNavigation } from '../../AppRouting';

type CompanyTemplatesTableProps = {
  templates: any[];
};

export const CompanyTemplatesTable = ({ templates }: CompanyTemplatesTableProps) => {
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'meta.name',
        Cell: nestCells(TruncateCell, TemplateLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Creator',
        accessor: 'meta.createdBy.name',
        Cell: nestCells(TruncateCell, withProps(UserLinkCell, {
          getUserId: (template) => template.meta.createdBy._id,
        })),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'lastUpdated',
        Header: 'Last updated',
        accessor: 'meta.lastEditedAt',
        Cell: DatetimeCell,
        sort: 'datetime',
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'requestsCreated',
        Header: 'Requests created',
        accessor: (template) => `${template.requestsCount ?? 0} requests`,
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        smallPageControls
        columns={columns}
        data={templates}
        isPaginated
        onRowClick={template => navigation.navigateToTemplate(template._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['lastUpdated', 'requestsCreated'] : []}
      />
    </BasicTableStyles>
  );
};
