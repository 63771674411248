import { formatDistanceToNowStrict } from 'date-fns';
import { getDateFnsLocale } from '@deepstream/utils';
import { useForceUpdateInterval } from '@deepstream/ui-kit/hooks/useForceUpdate';
import { useCurrentUserLocale } from './useCurrentUser';

export const DistanceToNow = ({ date }: { date: Date }) => {
  const locale = useCurrentUserLocale();

  useForceUpdateInterval(5000);

  const formattedDistance = formatDistanceToNowStrict(date, { locale: getDateFnsLocale(locale), addSuffix: true });

  return <>{formattedDistance}</>;
};
