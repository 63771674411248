import { useMemo } from 'react';
import * as yup from 'yup';
import { Flex, Text, TextProps, Box } from 'rebass/styled-components';
import { useField } from 'formik';
import { isNumber } from 'lodash';
import { useQueryClient } from 'react-query';
import { ModalForm } from '@deepstream/ui/ModalForm';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { useRfqId } from '@deepstream/ui/useRfq';
import { useToaster } from '@deepstream/ui/toast';
import { callAll } from '@deepstream/utils/callAll';
import { useStructure } from '@deepstream/ui/rfx';
import { HiddenField } from '@deepstream/ui/form/HiddenField';
import { useAdminRequestQueryKey } from './adminRequest';

const Label = (props: TextProps) => (
  <Text
    flex="0 0 auto"
    width="96px"
    mr={3}
    fontWeight={500}
    {...props}
  />
);

const convertToSeconds = ({ minutes = 0, seconds = 0 }) => {
  return minutes * 60 + seconds;
};

const TotalSecondsField = () => {
  const [{ value: minutes }] = useField('minutes');
  const [{ value: seconds }] = useField('seconds');

  const totalSeconds = useMemo(
    () => convertToSeconds({ minutes, seconds }),
    [minutes, seconds],
  );

  return (
    <HiddenField name="totalSeconds" value={totalSeconds} showError />
  );
};

type ExtendAuctionTimerModalProps = ModalProps & {
  initialMinutesCount: number;
  initialSecondsCount: number;
  onCancel: () => void;
};

export const ExtendAuctionTimerModal = ({
  initialMinutesCount,
  initialSecondsCount,
  isOpen,
  onCancel,
}: ExtendAuctionTimerModalProps) => {
  const adminApi = useAdminApi();
  const rfqId = useRfqId({ required: true });
  const queryKey = useAdminRequestQueryKey(rfqId);
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const { auction } = useStructure();

  const auctionId = auction._id;

  const [extendAuctionBidding] = useMutation(
    adminApi.extendAuctionBidding,
    {
      onSuccess: callAll(
        () => toaster.success('Auction timer extended successfully'),
        onCancel,
      ),
      onError: () => toaster.error('Auction timer could not be extended'),
      onSettled: () => queryClient.invalidateQueries(queryKey),
    },
  );

  const initialTotalSeconds = useMemo(
    () => convertToSeconds({ minutes: initialMinutesCount, seconds: initialSecondsCount }),
    [initialMinutesCount, initialSecondsCount],
  );

  return (
    <ModalForm
      heading="Extend auction timer"
      initialValues={{
        minutes: initialMinutesCount,
        seconds: initialSecondsCount,
        totalSeconds: initialTotalSeconds,
      }}
      validationSchema={
        yup.object().shape({
          minutes: yup.number().required('Required'),
          seconds: yup.number().required('Required').max(59, 'Max. 59'),
          totalSeconds: yup.number().when(
            ['minutes', 'seconds'],
            {
              is: (minutes, seconds) => isNumber(minutes) && isNumber(seconds),
              then: schema => schema.min(initialTotalSeconds, 'Must be longer than current time'),
              otherwise: schema => schema.nullable(),
            },
          ),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ totalSeconds }) => {
        const extensionSec = totalSeconds - initialTotalSeconds;

        await extendAuctionBidding({ rfqId, auctionId, extensionSec });
      }}
      submitLabel="Extend auction timer"
      style={{ content: { width: '500px' } }}
    >
      <Flex>
        <Label>Current time</Label>
        <Text>{initialMinutesCount} mins {initialSecondsCount} secs</Text>
      </Flex>
      <Flex>
        <Label sx={{ position: 'relative', top: '10px' }}>
          Extended time
        </Label>
        <Box>
          <Flex>
            <Box mr={2} flex="0 0 130px">
              <TextField name="minutes" format="integer.positive" suffix="mins" />
            </Box>
            <Box flex="0 0 130px">
              <TextField name="seconds" format="integer.positive" suffix="secs" maxValue={59} />
            </Box>
          </Flex>
          <TotalSecondsField />
        </Box>
      </Flex>
      <MessageBlock variant="warn" mt={0}>
        This will extend the time left until the end of the auction
      </MessageBlock>
    </ModalForm>
  );
};
