import * as React from 'react';
import { isEmpty } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useIsMounting } from '@deepstream/ui-kit/hooks/useIsMounting';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { SupplierListOverview, SupplierListType } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { MultiSelect } from './ui/MultiSelect';
import { useToaster } from './toast';
import { useApi } from './api';
import { useSupplierLists } from './modules/SupplierLists/useSupplierLists';
import { useMutation } from './useMutation';
import { useCurrentCompanyId } from './currentCompanyId';
import { listTypeIconProps } from './modules/SupplierLists/utils';

const renderListItem = (list: SupplierListOverview) => (
  <Flex alignItems="center" justifyContent="space-between" sx={{ gap: 2 }}>
    <Truncate>
      {list.name}
    </Truncate>
    <Icon {...listTypeIconProps[list.listType]} fixedWidth flex="0 0 auto" />
  </Flex>
);

const MenuHeader = () => {
  const { t } = useTranslation();

  return (
    <Box fontSize={1} color="subtext" p="2px 8px 10px" sx={{ position: 'relative' }}>
      <Icon icon="info-circle" regular display="inline" mr={1} />
      {t('network.selectListInfo')}
    </Box>
  );
};

type SelectSupplierListsProps = {
  // Company being added/removed from lists
  targetCompanyId: string;
  onChange: any;
};

export const SelectSupplierLists: React.FC<SelectSupplierListsProps> = React.memo(({
  targetCompanyId,
  onChange,
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const isMounting = useIsMounting();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { supplierLists, supplierListsStatus, refetchSupplierLists } = useSupplierLists();

  const selectedSupplierLists = React.useMemo(
    () => supplierLists.filter((supplierList) => supplierList.supplierIds.includes(targetCompanyId)),
    [supplierLists, targetCompanyId],
  );

  // We don't refetch in the onSettled handler because many mutations might happen
  // within a short period of time. We have to manage the refetch manually.
  const [updateSupplierListCompanies, { status: updateListStatus }] = useMutation(api.updateSupplierListCompanies);

  // We don't want to excessively refetch so we need to debounce the call
  const [debouncedRefetchSupplierLists] = useDebouncedCallback(refetchSupplierLists, 500);

  // Effect to refetch when updates are complete
  React.useEffect(
    () => {
      // Skip the refetch if it's the first render
      if (updateListStatus !== 'loading' && !isMounting) {
        debouncedRefetchSupplierLists();
      }
    },
    // Intentionally leave isMounting out of the dependency array so we don't refetch when it changes
    [updateListStatus, debouncedRefetchSupplierLists], // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    onChange();
  }, [supplierLists, onChange]);

  const handleDeselect = React.useCallback(
    async supplierList => {
      try {
        await updateSupplierListCompanies({
          companyId: currentCompanyId,
          supplierListId: supplierList._id,
          removedCompanyIds: [targetCompanyId],
        });

        toaster.success(t('network.toaster.removeCompanyFromListSuccess', { listName: supplierList.name }));
      } catch (error) {
        toaster.error(t('network.toaster.removeCompanyFromListError'));
      }
    },
    [currentCompanyId, targetCompanyId, toaster, updateSupplierListCompanies, t],
  );

  const handleSelect = React.useCallback(
    async supplierList => {
      try {
        await updateSupplierListCompanies({
          companyId: currentCompanyId,
          supplierListId: supplierList._id,
          addedCompanyIds: [targetCompanyId],
        });

        toaster.success(t('network.toaster.addCompanyToListSuccess', { listName: supplierList.name }));
      } catch (error) {
        toaster.error(t('network.toaster.addCompanyToListError'));
      }
    },
    [currentCompanyId, targetCompanyId, toaster, updateSupplierListCompanies, t],
  );

  return (
    <Tooltip content={isEmpty(supplierLists) ? t('network.createOneOrMoreLists') : ''}>
      <span style={{ display: 'inline-block' }}>
        <MultiSelect<SupplierListOverview>
          variant="secondary"
          disabled={supplierListsStatus === 'loading' || supplierListsStatus === 'error' || isEmpty(supplierLists)}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          items={supplierLists}
          itemToString={(item) => item ? item.name : ''}
          // @ts-ignore ts(2322) FIXME: Type '(list: SupplierListOverview) => JSX.Element' is not assignable to type '(item: SupplierListOverview | null) => string | ComponentElement<SupplierListOverview, any>'.
          renderItem={renderListItem}
          buttonWidth={120}
          menuZIndex={2}
          selectedItems={selectedSupplierLists}
          getButtonIcon={() => updateListStatus === 'loading' ? 'spinner' : 'list-ul'}
          // @ts-ignore ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          placeholder={supplierListsStatus === 'loading' ? (
            t('general.loading')
          ) : supplierListsStatus === 'error' ? (
            t('general.error')
          ) : null}
          getButtonText={() => <Truncate>{t('network.editLists')}</Truncate>}
          menuWidth={250}
          withRegularButton
          isItemDisabled={item => item.listType === SupplierListType.AUTOMATIC}
          header={<MenuHeader />}
          alwaysShowCaret
        />
      </span>
    </Tooltip>
  );
});

SelectSupplierLists.displayName = 'SelectSupplierLists';
