import { Dictionary, flatMap, get, keys, pickBy } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import { CellProps } from 'react-table';
import { Text } from 'rebass/styled-components';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { nestCells } from '@deepstream/ui/nestCells';
import { Table } from '@deepstream/ui/Table';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTranslation } from 'react-i18next';
import { RequestLinkCell } from './RequestLinkCell';
import { formatAdminDate } from './formatDate';
import { useAdminNavigation } from './AppRouting';
import { expandByCompanies } from './utils';

const includes = <T,> (item: T) => (array: T[]) => array.includes(item);

// Returns array of duplicated requests based on the number of companies teams the `userId` belongs to
const expandRequest = (userId: string) => (request: any) => {
  const senderIds = keys(pickBy(request.userIdsBySenderId, includes(userId)));
  const recipientIds = keys(pickBy(request.userIdsByRecipientId, includes(userId)));

  return [
    ...expandByCompanies(request, senderIds, 'Sender'),
    ...expandByCompanies(request, recipientIds, 'Recipient'),
  ];
};

const GetCompanyNameCell: React.FC<CellProps<any>> = ({ cell: { value: companyId } }) => {
  const adminApi = useAdminApi();

  const { data: name, error } = useQuery(
    ['companyName', { companyId }],
    wrap(adminApi.getCompanyName),
  );

  return (
    <Truncate>
      {error ? (
        <Text color="danger">
          <Icon icon="exclamation-circle" mr={1} />Error
        </Text>
      ) : name ? (
        <span>{name}</span>
      ) : (
        null
      )}
    </Truncate>
  );
};

type RequestsTableProps = {
  userId: string;
  requests: {
    companies: Dictionary<string>;
    rfqDashboardOverviews: any[];
  };
};

export const UserRequestsTable = ({ userId, requests }: RequestsTableProps) => {
  const { t } = useTranslation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const expandedRequests = React.useMemo(
    () => flatMap(requests.rfqDashboardOverviews, expandRequest(userId)),
    [userId, requests.rfqDashboardOverviews],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'receivedDashboard.subject',
        Cell: nestCells(TruncateCell, RequestLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Company',
        accessor: 'company._id',
        Cell: GetCompanyNameCell,
        width: isExtraLarge ? undefined : 250,
        Filter: withProps(FilterSelect, {
          itemToString: value => requests.companies[value], transformSelectedValues: value => requests.companies[value],
        }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Company role',
        accessor: 'company.role',
        width: 140,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => t(`request.status.${get(item, 'meta.extendedStatus')}`),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: 'sentDashboard.bidDeadline',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [isExtraLarge, requests.companies, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={expandedRequests}
        onRowClick={request => navigation.navigateToRequestCompanyUser(request._id, request.company._id, userId)}
        isSortable
        noFilteredDataPlaceholder="No requests match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['status', 'deadline'] : []}
      />
    </BasicTableStyles>
  );
};
