import { isEmpty } from 'lodash';

export const getScrollLeftForCellVisibility = ({
  currentScrollLeft,
  visibleWidth,
  targetLeft,
  targetWidth,
}) => {
  const viewboxLeft = currentScrollLeft;
  const viewboxRight = currentScrollLeft + visibleWidth;

  const targetRight = targetLeft + targetWidth;

  if (targetLeft < viewboxLeft) {
    return targetLeft;
  } else if (targetRight > viewboxRight) {
    return currentScrollLeft + (targetRight - viewboxRight);
  } else {
    return null;
  }
};

export const getScrollTopForCellVisibility = ({
  currentScrollTop,
  visibleHeight,
  targetTop,
  targetHeight,
}: {
  currentScrollTop: number;
  visibleHeight: number;
  targetTop: number;
  targetHeight: number;
}) => {
  const viewboxTop = currentScrollTop;
  const limit = currentScrollTop + visibleHeight;

  const targetBottom = targetTop + targetHeight;

  if (targetTop < viewboxTop) {
    return targetTop;
  } else if (targetBottom > limit) {
    return currentScrollTop + (targetBottom - limit);
  } else {
    return null;
  }
};

const getFirstRowExceedingLimit = ({
  limit,
  rows,
  index: initialIndex,
  offset: initialOffset,
}) => {
  let offset = initialOffset;
  for (let index = initialIndex; index < rows.length; index += 1) {
    const row = rows[index];
    offset += row.height;

    if (offset > limit) {
      return {
        index,
        top: offset - row.height,
      };
    }
  }

  return {
    index: rows.length,
    top: offset,
  };
};

export const getLastRowNextPage = ({
  currentScrollTop,
  visibleHeight,
  rows,
}) => {
  if (isEmpty(rows)) {
    return null;
  }

  const lastRowCurrentPage = getFirstRowExceedingLimit({
    limit: currentScrollTop + visibleHeight,
    rows,
    index: 1,
    offset: 0,
  });

  if (lastRowCurrentPage.index === rows.length) {
    return {
      scrollTop: lastRowCurrentPage.top - visibleHeight,
      rowIndex: lastRowCurrentPage.index - 1,
    };
  }

  const lastRowNextPage = getFirstRowExceedingLimit({
    limit: lastRowCurrentPage.top + visibleHeight,
    rows,
    index: lastRowCurrentPage.index,
    offset: lastRowCurrentPage.top,
  });

  return {
    scrollTop: lastRowNextPage.top - visibleHeight,
    rowIndex: lastRowNextPage.index - 1,
  };
};

export const getFirstRowPreviousPage = ({
  currentScrollTop,
  visibleHeight,
  rows,
}) => {
  if (isEmpty(rows)) {
    return null;
  }

  const limit = currentScrollTop - visibleHeight;

  if (limit <= 0) {
    return {
      rowIndex: 1,
      scrollTop: 0,
    };
  }

  const firstRowPreviousPage = getFirstRowExceedingLimit({
    limit,
    rows,
    index: 1,
    offset: 0,
  });

  return {
    rowIndex: firstRowPreviousPage.index + 1,
    scrollTop: firstRowPreviousPage.top +
      rows[firstRowPreviousPage.index].height,
  };
};

export const getLastRowNextPageFromDom = ({
  currentScrollTop,
  visibleHeight,
  frozenHeaderHeight,
  tBodyElement,
  containerTop,
}) => {
  const el = tBodyElement as HTMLElement;

  let currentElement = el.lastElementChild;
  let firstNotFullyVisibleFollowingElement: Element | null = null;
  let rowIndex = el.children.length - 1;

  if (!currentElement) {
    return null;
  }

  while (currentElement) {
    const rect = currentElement.getBoundingClientRect();

    const { top, height } = rect;

    const isBottomVisible = top + height <= containerTop + frozenHeaderHeight + visibleHeight;

    if (isBottomVisible) {
      firstNotFullyVisibleFollowingElement = currentElement.nextElementSibling;
      break;
    }

    rowIndex -= 1;
    currentElement = currentElement.previousElementSibling;
  }

  if (!firstNotFullyVisibleFollowingElement) {
    return {
      rowIndex: el.children.length,
      scrollTop: currentScrollTop,
    };
  }

  const rect = firstNotFullyVisibleFollowingElement.getBoundingClientRect();

  if (rect.top + rect.height > containerTop + frozenHeaderHeight + 2 * visibleHeight) {
    const newScrollTop = currentScrollTop + rect.top - containerTop - frozenHeaderHeight;

    return {
      rowIndex: rowIndex + 2,
      scrollTop: newScrollTop,
    };
  }

  const firstNotFullyVisibleTop = rect.top;

  let element = firstNotFullyVisibleFollowingElement;
  let elementRect = rect;

  while (element) {
    const nextElement = element.nextElementSibling;
    const nextRect = nextElement?.getBoundingClientRect();

    if (!nextRect || nextRect.top + nextRect.height > firstNotFullyVisibleTop + visibleHeight) {
      const newScrollTop = currentScrollTop - containerTop - frozenHeaderHeight + elementRect.top + elementRect.height - visibleHeight;

      return {
        rowIndex: rowIndex + 2,
        scrollTop: newScrollTop,
      };
    }

    element = nextElement!;
    elementRect = nextRect!;
    rowIndex += 1;
  }

  return null;
};

export const getFirstRowPreviousPageFromDom = ({
  currentScrollTop,
  visibleHeight,
  frozenHeaderHeight,
  tBodyElement,
  containerTop,
}) => {
  const el = tBodyElement as HTMLElement;

  let currentElement = el.firstElementChild;
  let lastNotFullyVisiblePrecedingElement: Element | null = null;
  let rowIndex = 0;

  if (!currentElement) {
    return null;
  }

  while (currentElement) {
    const rect = currentElement.getBoundingClientRect();

    const { top } = rect;

    const isTopVisible = top >= containerTop + frozenHeaderHeight;

    if (isTopVisible) {
      lastNotFullyVisiblePrecedingElement = currentElement.previousElementSibling;
      break;
    }

    rowIndex += 1;
    currentElement = currentElement.nextElementSibling;
  }

  if (!lastNotFullyVisiblePrecedingElement) {
    return {
      rowIndex: 1,
      scrollTop: 0,
    };
  }

  const rect = lastNotFullyVisiblePrecedingElement.getBoundingClientRect();

  if (rect.top < containerTop + frozenHeaderHeight - visibleHeight) {
    const newScrollTop = currentScrollTop + rect.top - containerTop - frozenHeaderHeight;

    return {
      rowIndex,
      scrollTop: newScrollTop,
    };
  }

  const lastNotFullyVisibleBottom = rect.top + rect.height;

  let element = lastNotFullyVisiblePrecedingElement;
  let elementRect = rect;

  while (element) {
    const precedingElement = element.previousElementSibling;
    const precedingRect = precedingElement?.getBoundingClientRect();

    if (!precedingRect || precedingRect.top + visibleHeight < lastNotFullyVisibleBottom) {
      const newScrollTop = currentScrollTop + elementRect.top - containerTop - frozenHeaderHeight;

      return {
        rowIndex,
        scrollTop: newScrollTop,
      };
    }

    element = precedingElement!;
    elementRect = precedingRect!;
    rowIndex -= 1;
  }

  return null;
};
