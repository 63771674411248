import { debounce, capitalize, sum, clone, noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass/styled-components';
import useStructuredTagPickerState from './useStructuredTagPickerState';
import Level1Tag from './Level1Tag';
import Level2Tag from './Level2Tag';
import Tag from '../Tag';
import { ToggleAllButton } from './ToggleAllButton';
import { Label } from '../Label';
import { Select } from '../Select';
import { Input } from '../Input';

/* eslint-disable no-param-reassign */

const Level1TagsList = styled.div`
  overflow-y: auto;
  min-width: 260px;
  max-width: 260px;
  border-right: 1px solid #D4D9E2;
`;

const Level2TagsList = styled.div`
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 22px;
  padding-bottom: 22px;
`;

const Category = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;

type NoResultsProps = {
  textFilter?: string;
  tagFilter?: any;
  noResultsText: string;
  isCaseSensitive?: boolean;
};

const NoResults: React.FC<NoResultsProps> = ({ textFilter, tagFilter, noResultsText, isCaseSensitive }) => (
  <div>
    <span>{noResultsText} for</span>
    <span style={{ fontWeight: 500 }}>{' '}{textFilter}{' '}</span>
    {tagFilter && (
      <>
        <span>in{' '}</span>
        <span style={{ fontWeight: 500 }}>
          {isCaseSensitive ? tagFilter.name : capitalize(tagFilter.name)}
        </span>
      </>
    )}
  </div>
);

/*
 * TODO:
 * Extract a lower level component that is semantically decoupled from
 * the concept of our tags
 */

type TagPickerProps = {
  tags: any[];
  getTags: () => Promise<any[]>;
  onChange?: (tags: any[]) => void;
  allLevel1CategoryLabel?: string;
  isCaseSensitive?: boolean;
  searchLabel: string;
  filterLabel: string;
  placeholder: string;
  noResultsText: string;
};

export const StructuredTagPicker: React.FC<TagPickerProps> = React.memo(({
  tags,
  getTags,
  onChange = noop,
  allLevel1CategoryLabel,
  isCaseSensitive,
  searchLabel,
  filterLabel,
  placeholder,
  noResultsText,
}) => {
  const {
    isReady,
    visibleTags,
    selectedIds,
    tagFilter,
    textFilter,
    countsById,
    selectedTags,
    setTagFilter,
    setTextFilter,
    toggleTagSelection,
    selectAll,
    deselectAll,
    areAllChildrenSelected,
  } = useStructuredTagPickerState(tags, getTags);

  const setTextFilterDebounced = debounce(setTextFilter, 100);

  // Call `onChange` whenever there is a change to the selected tags
  React.useEffect(
    () => {
      // We need to transform the selected tags into expected tag schema.
      //
      // This requires 2 things:
      //   1. Resolve reference to parent tag (ie: convert object -> id)
      //   2. Remove references to child tags
      //
      // Note that we must *clone* the tag to avoid mutating structure
      // that is required by the internals the useStructuredTagPickerState
      let tags = selectedTags
        .map(tag => {
          tag = clone(tag);

          // Resolve reference to parent tag (an empty string is expected if the
          // tag has no parent)
          tag.parent = tag.parent ? tag.parent._id : '';

          // Remove children
          delete tag.children;

          return tag;
        });

      // Remove all level 2 tags that have no children
      tags = tags.filter(tag => tag.level !== 2 || tags.some((child: any) => child.parent === tag._id));
      // Remove all level 1 tags that have no children
      tags = tags.filter(tag => tag.level !== 1 || tags.some((child: any) => child.parent === tag._id));
      onChange(tags);
    },
    [selectedTags], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!isReady) {
    return <Box width="778px">{placeholder}</Box>;
  }

  const selectedCategoryId = tagFilter ? tagFilter._id : 'all';
  const selectedCategoryLabel = tagFilter ? tagFilter.name : allLevel1CategoryLabel;
  const visibleLevel1Tags = visibleTags && visibleTags.level1;

  return (
    <Flex flexDirection="column">
      <Flex mb={16}>
        <Label flex={1} label={searchLabel} mr={24}>
          <Box>
            <Input
              type="text"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setTextFilterDebounced(ev.target.value)}
              onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                }
              }}
            />
          </Box>
        </Label>
        <Label flex={1} label={filterLabel} marginTop="0px !important">
          <Box>
            <Select
              onChange={(changes: any) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { _id } = changes.selectedItem;
                // For some reason, React complains about updating the state during rendering in this case, so the timeout is a workaround.
                setTimeout(() => {
                  setTagFilter(_id === 'all' ? null : _id);
                }, 0);
              }}
              itemToString={(item: any) => item ? item._id : ''}
              getItemLabel={(item: any) => item ? capitalize(item.name) : ''}
              items={[{ _id: 'all', name: allLevel1CategoryLabel }, ...visibleLevel1Tags]}
              selectedItem={tagFilter && tagFilter._id}
            />
          </Box>
        </Label>
      </Flex>
      <Flex style={{ maxHeight: 432, border: '1px solid #D4D9E2' }}>
        <Level1TagsList>
          {allLevel1CategoryLabel && (
            <Level1Tag
              key="all"
              name={allLevel1CategoryLabel}
              selected={!tagFilter}
              count={sum(Object.values(countsById))}
              onClick={() => setTagFilter(null)}
              isCaseSensitive={isCaseSensitive}
            />
          )}
          {visibleTags && visibleTags.level1.map(({ _id, name }: any) => (
            <Level1Tag
              key={_id}
              name={name}
              selected={tagFilter && tagFilter._id === _id}
              count={countsById[_id]}
              onClick={() => setTagFilter(_id)}
              isCaseSensitive={isCaseSensitive}
            />
          ))}
        </Level1TagsList>
        <Level2TagsList>
          <Flex marginBottom={24}>
            <Category>
              {isCaseSensitive ? selectedCategoryLabel : capitalize(selectedCategoryLabel)}
            </Category>
            {!textFilter && (
              <ToggleAllButton
                tagId={selectedCategoryId}
                areAllChildrenSelected={areAllChildrenSelected}
                selectAll={selectAll}
                deselectAll={deselectAll}
                ml={2}
                style={{ flexShrink: 0, marginBottom: '2px', marginTop: '6px' }}
              />
            )}
          </Flex>
          {visibleTags && visibleTags.level2.length ? (
            visibleTags.level2.map(({ _id, name, children: level3 = [] }: any) => (
              <Level2Tag
                key={_id}
                tagId={_id}
                name={name}
                isCaseSensitive={isCaseSensitive}
                textFilter={textFilter}
                selectAll={selectAll}
                deselectAll={deselectAll}
                areAllChildrenSelected={areAllChildrenSelected}
              >
                {level3.map(({ _id, name }: any) => (
                  <Tag
                    key={_id}
                    id={_id}
                    name={name}
                    selected={selectedIds[_id]}
                    onClick={() => toggleTagSelection(_id)}
                    isCaseSensitive={isCaseSensitive}
                  />
                ))}
              </Level2Tag>
            ))
          ) : (
            <NoResults
              tagFilter={tagFilter}
              textFilter={textFilter}
              noResultsText={noResultsText}
              isCaseSensitive={isCaseSensitive}
            />
          )}
        </Level2TagsList>
      </Flex>
    </Flex>
  );
});

StructuredTagPicker.displayName = 'StructuredTagPicker';
