import * as React from 'react';
import { Counter, BadgeProps } from '../../ui/Badge';
import { useNotifications } from './NotificationsProvider';

type NotificationCounterProps = BadgeProps & {
  filter?: any;
};

export const NotificationCounter: React.FC<NotificationCounterProps> = ({
  filter,
  ...props
}) => {
  const notifications = useNotifications(filter);

  return (
    <Counter count={notifications.length} {...props} />
  );
};
