import * as React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Box, Flex } from 'rebass/styled-components';
import { fromPairs, isNumber, sumBy } from 'lodash';
import { IconText } from '../elements/text/IconText';

ChartJS.register(
  ArcElement,
  PieController,
);

export type DoughnutChartSlice = {
  id: string;
  value: number;
  label: string;
  activeColor: string;
  inactiveColor: string;
};

export const updateActiveSlice = (dataset, activeSliceId: string | null) => {
  if (!dataset.ids.includes(activeSliceId)) {
    dataset.backgroundColor = dataset.activeColors;
    dataset.hoverBackgroundColor = dataset.activeColors;
  } else {
    const backgroundColors = dataset.ids.map((id: string, index: number) => (
      id === activeSliceId
        ? dataset.activeColors[index]
        : dataset.inactiveColors[index]
    ));
    dataset.backgroundColor = backgroundColors;
    dataset.hoverBackgroundColor = backgroundColors;
  }
};

export const DoughnutChart = ({
  slices,
  excludeFromCountIds,
  legendPosition = 'right',
  legendOrientation,
  description,
  cutout = '80%',
  width = '203px',
  height = '203px',
  Overlay,
}: {
  slices: DoughnutChartSlice[];
  excludeFromCountIds?: string[];
  legendPosition?: 'right' | 'bottom';
  legendOrientation?: 'horizontal' | 'vertical';
  description: string;
  cutout?: string;
  width?: string;
  height?: string;
  Overlay?: (props: {
    activeSliceId: string | null;
    selectedValue: number;
    totalValue: number;
  }) => React.ReactElement;
}) => {
  const previousActiveIndex = React.useRef(null);
  const chartRef = React.useRef<ChartJS>(null);
  const [activeSliceId, setActiveSliceId] = React.useState<string | null>(null);

  const {
    counts,
    totalValue,
    includedValue,
  } = React.useMemo(() => {
    const counts = fromPairs(slices.map(slice => [slice.id, slice.value]));
    const totalValue = sumBy(slices, slice => slice.value);
    const excludedSlices = excludeFromCountIds
      ? slices.filter(slice => excludeFromCountIds.includes(slice.id))
      : [];
    const excludedValue = sumBy(excludedSlices, slice => slice.value);

    return {
      counts,
      totalValue,
      includedValue: totalValue - excludedValue,
    };
  }, [slices, excludeFromCountIds]);

  React.useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        updateActiveSlice(dataset, activeSliceId);
      });

      chart.update();

      setActiveSliceId(activeSliceId);
    }
  }, [activeSliceId, setActiveSliceId]);

  // unset active slice when mouse leaves chart
  const handleMouseLeave = React.useCallback(() => {
    previousActiveIndex.current = null;
    setActiveSliceId(null);
  }, [setActiveSliceId]);

  const handleHover = React.useCallback((event) => {
    if (event.type === 'mousemove') {
      const activeElement = event.chart.getActiveElements()?.[0];

      if (!activeElement || !(activeElement.element instanceof ArcElement)) {
        if (isNumber(previousActiveIndex.current)) {
          previousActiveIndex.current = null;
          setActiveSliceId(null);
        }
      } else if (
        activeElement.element instanceof ArcElement &&
        activeElement.index !== previousActiveIndex.current
      ) {
        previousActiveIndex.current = activeElement.index;

        const activeSliceId = event.chart.data
          .datasets[activeElement.datasetIndex]
          ?.ids
          ?.[activeElement.index];

        if (activeSliceId) {
          setActiveSliceId(activeSliceId);
        }
      }
    }
  }, [setActiveSliceId]);

  const options = React.useMemo(() => ({
    animation: {
      duration: 300,
    },
    animations: {
      x: {
        duration: 0,
      },
      y: {
        duration: 0,
      },
    },
    cutout,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    onHover: handleHover,
  }), [handleHover, cutout]);

  const data = React.useMemo(() => {
    const activeColors = slices.map(slice => slice.activeColor);
    const inactiveColors = slices.map(slice => slice.inactiveColor);

    return {
      labels: slices.map(slice => slice.label),
      datasets: [
        {
          data: slices.map(slice => slice.value),
          backgroundColor: activeColors,
          hoverBackgroundColor: activeColors,
          borderWidth: 0,

          ids: slices.map(slice => slice.id),
          activeColors,
          inactiveColors,
        },
      ],
    };
  }, [slices]);

  const selectedValue = activeSliceId ? counts[activeSliceId] : includedValue;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection={legendPosition === 'right' ? 'row' : 'column'}
    >
      <Box width={width} height={height} sx={{ position: 'relative' }}>
        <Chart
          onMouseLeave={handleMouseLeave}
          ref={chartRef}
          type="doughnut"
          options={options}
          data={data}
          width="100%"
          height="100%"
          fallbackContent={<p>{description}</p>}
        />
        {Overlay && (
          <Overlay
            activeSliceId={activeSliceId}
            selectedValue={selectedValue}
            totalValue={totalValue}
          />
        )}
      </Box>
      <Flex
        flexDirection={legendOrientation === 'vertical' || (!legendOrientation && legendPosition === 'right') ? (
          'column'
        ) : (
          'row'
        )}
        sx={{
          marginTop: legendPosition === 'right' ? null : '12px',
          marginLeft: legendPosition === 'right' ? '20px' : null,
          width: legendPosition === 'right' ? '120px' : null,
        }}
      >
        {slices.map(slice => {
          const isInactive = activeSliceId && activeSliceId !== slice.id;

          return (
            <IconText
              key={slice.id}
              py="2px"
              icon="circle"
              iconColor={isInactive ? slice.inactiveColor : slice.activeColor}
              iconFontSize="9px"
              text={slice.label}
              fontWeight={activeSliceId === slice.id ? 500 : 400}
              color={isInactive ? 'disabledText' : 'text'}
              fontSize={1}
              onMouseEnter={() => setActiveSliceId(slice.id)}
              onMouseLeave={() => setActiveSliceId(null)}
              sx={{ cursor: 'default' }}
              mx={legendPosition === 'right' ? null : 1}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
