import { isNil } from 'lodash';
import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { LayoutProps, SpaceProps } from 'styled-system';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { RequiredAsterisk } from '@deepstream/ui-kit/elements/text/RequiredAsterisk';
import { formatOptions } from '../ui/formatOptions';
import { NumberFormat } from '../NumberFormat';
import { CurrencyAmount } from '../ui/Currency';
import { FactorAsPercent } from '../ui/Number';

const smallLabelProps = {
  fontSize: '9px',
  color: 'subtext',
};

const smallValueProps = {
  fontSize: 1,
};

export const LabeledValue = ({
  label,
  value,
  align = 'right',
  small,
  labelProps = {},
  valueProps = { fontWeight: 500 },
  showAsterisk,
  infoTooltip,
  ...props
}: LayoutProps & SpaceProps & {
  label: React.ReactNode;
  value: any;
  align?: 'left' | 'right';
  small?: boolean;
  labelProps?: Partial<React.ComponentProps<typeof Text>>;
  valueProps?: Partial<React.ComponentProps<typeof Text>>;
  showAsterisk?: boolean;
  infoTooltip?: React.ReactNode;
  gap?: string | number;
}) => {
  const justifyContent = align === 'left' ? 'flex-start' : 'flex-end';

  const effectiveLabelProps = small ? { ...smallLabelProps, ...labelProps } : labelProps;
  const effectiveValueProps = small ? { ...smallValueProps, ...valueProps } : valueProps;

  return (
    <Stack gap={1} {...props}>
      <Flex justifyContent={justifyContent}>
        <Text color="lightNavy" fontSize={1} fontWeight={500} lineHeight="normal" {...effectiveLabelProps}>
          {label}
          {showAsterisk ? <RequiredAsterisk /> : null}
          {infoTooltip && (
            <Tooltip content={infoTooltip}>
              <Icon icon="question-circle" regular color="lightGray5" ml={1} />
            </Tooltip>
          )}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent={justifyContent}>
        <Text fontSize={2} lineHeight="normal" {...effectiveValueProps}>
          {value}
        </Text>
      </Flex>
    </Stack>
  );
};

type LabeledNumberProps =
  & Omit<React.ComponentProps<typeof LabeledValue>, 'value'>
  & {
    number: number | null | undefined | '';
    format?: keyof typeof formatOptions | 'percent' | 'money';
  };

export const LabeledNumber = ({ number, format, ...props }: LabeledNumberProps) => (
  <LabeledValue
    value={isNil(number) || number === '' || Number.isNaN(number) ? (
      <EmDash />
    ) : format === 'percent' ? (
      <FactorAsPercent value={number} />
    ) : format === 'money' ? (
      <CurrencyAmount value={number} />
    ) : (
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={number}
        {...(format ? formatOptions[format] : {})}
      />
    )}
    {...props}
  />
);
