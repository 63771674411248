import * as React from 'react';
import { Box, BoxProps, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Required } from '../Required';

type LabelProps = BoxProps & {
  label: string;
  required?: boolean;
  color?: string;
};

const LabelBase: React.FC<LabelProps> = ({ label, required, color, children, ...props }) => (
  <Box as="label" {...props}>
    <Text mb={1} color={color} sx={{ fontWeight: 500, fontSize: '12px' }}>
      {label}
      {required && <Required />}
    </Text>
    {children}
  </Box>
);

export const Label = styled(LabelBase)`
  display: block;

  & + & {
    margin-top: ${props => props.theme.space[3]}px;
  }
`;
