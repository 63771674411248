import React from 'react';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { companyRoute } from './AppRouting';
import { AdminTableLink } from './AdminTableLink';

export const CompanyLink = ({
  companyId,
  style,
  children,
}: {
  companyId: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={companyRoute.to}
    params={{ companyId }}
    onClick={stopPropagation}
    style={style}
  >
    {children}
  </AdminTableLink>
);
