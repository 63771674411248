import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyMinimized, PageType, User } from '@deepstream/common/rfq-utils';
import { useInView } from 'react-intersection-observer';
import { FlexProps } from 'rebass/styled-components';
import { compact, find } from 'lodash';
import { useQuery } from 'react-query';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { ContractProvider, useContractData, useGeneralPages } from '@deepstream/ui/modules/Contracts/contract';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelText, PanelDivider, PanelHeader, PanelPadding, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { ContractTeamUserModal } from '@deepstream/ui/modules/Contracts/Team/ContractTeamUserModal';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useApi, wrap } from '@deepstream/ui/api';
import { REMINDERS_PAGE_ID, SUMMARY_PAGE_ID } from '@deepstream/common/contract';
import { PropertyList, Skip, PropertyListAction } from '@deepstream/ui/PropertyList';
import { CompanyLink } from '../../CompanyLink';
import { Page } from '../../Page';
import * as Title from '../../title';
import { UserLink } from '../../UserLink';
import { Id } from '../../Id';
import { contractCompanyUserRoute, useAdminNavigation } from '../../AppRouting';
import { getUser, useAdminContract } from './adminContract';
import { ContractLink } from './ContractLink';
import { RemoveUserFromContractButton } from './RemoveUserFromContractButton';

const textColumn: React.CSSProperties = {
  width: 'auto',
  minWidth: '10%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const PageTitle = ({ flexDirection, company, contractId, user }: {
  flexDirection?: FlexProps['flexDirection'];
  contractId: string;
  company: CompanyMinimized;
  user: User;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <UserLink
        key="user"
        userId={user._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.User
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'large' : 'small'}
          user={user}
        />
      </UserLink>

      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          company={company}
        />
      </CompanyLink>

      <ContractLink
        contractId={contractId}
        style={flexDirection === 'column' ? undefined : { ...textColumn }}
      >
        <Title.Contract
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
        />
      </ContractLink>
    </Title.Container>
  );
};

const PermissionsPropertyList = ({ user, companyId }: { user: User, companyId: string }) => {
  const { t } = useTranslation();
  const { pages, senders } = useContractData();
  const generalPages = useGeneralPages();

  const contractPage = find(pages, (page) => page.type === PageType.CONTRACT)!;

  const generalUserProperties = useMemo(
    () => [
      { name: 'User role', value: user.isOwner ? 'Owner' : 'Team member' },
    ],
    [user],
  );

  const isSender = !!senders.find(sender => sender._id === companyId);

  const permissionsProperties = useMemo(
    () => compact([
      { name: 'Summary', value: t(`teamManagement.pageRole.${user.contractRoles![SUMMARY_PAGE_ID]}`) },
      ...generalPages.map(page => ({ name: `Page – ${page.name}`, value: t(`teamManagement.pageRole.${user.contractRoles![page._id]}`) })),
      { name: 'Contract', value: t(`teamManagement.pageRole.${user.contractRoles![contractPage._id]}`) },
      isSender && { name: 'Reminders', value: t(`teamManagement.pageRole.${user.contractRoles![REMINDERS_PAGE_ID]}`) },
    ]),
    [contractPage._id, generalPages, isSender, t, user.contractRoles],
  );

  return (
    <>
      <PropertyList properties={generalUserProperties} />
      <PanelDivider />
      <PanelPadding bg="lightGray3" py="12px">
        <SidebarPanelHeading>Permissions</SidebarPanelHeading>
      </PanelPadding>
      <PanelDivider />
      <PropertyList properties={permissionsProperties} />
    </>
  );
};

const AdminContractCompanyUserPanels = ({
  contractId,
  company,
  user,
}: {
  contractId: string;
  company: CompanyMinimized;
  user: User;
}) => {
  const editContractPermissionsModal = useModalState();
  const api = useApi();
  const { navigateToContractCompany } = useAdminNavigation();

  const generalUserProperties = useMemo(
    () => [
      { name: 'ID', value: user._id, Component: Id },
    ],
    [user],
  );

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId: company._id }],
    wrap(api.getUsersForCompany),
  );

  const userForCompany = find(usersForCompany, { _id: user._id });

  return (
    <>
      <Panel heading="General" mb={4}>
        <PropertyList properties={generalUserProperties}>
          <Skip />
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={editContractPermissionsModal.open}
            disabled={(
              !userForCompany ||
              userForCompany.roleRestrictionProfiles?.[company._id] === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
            )}
          />
        </PropertyList>
      </Panel>
      <Panel mb={4}>
        <PanelHeader heading="Permissions">
          <Button
            small
            iconLeft="pencil-alt"
            onClick={editContractPermissionsModal.open}
            disabled={!userForCompany}
          >
            Edit
          </Button>
        </PanelHeader>
        <PanelDivider />
        <PermissionsPropertyList user={user} companyId={company._id} />
        {userForCompany && editContractPermissionsModal.isOpen && (
          <ContractTeamUserModal
            companyId={company._id}
            user={{
              ...user,
              companyId: company._id,
              email: userForCompany.email,
              roleRestrictionProfiles: userForCompany.roleRestrictionProfiles!,
            }}
            close={editContractPermissionsModal.close}
            onRemove={() => navigateToContractCompany(contractId, company._id, true)}
          />
        )}
      </Panel>
      <Panel heading="Remove user from contract" mb={4}>
        <PanelText>
          <RemoveUserFromContractButton
            contractId={contractId}
            company={company}
            user={{
              ...user,
              companyId: company._id,
              email: userForCompany?.email || '',
              roleRestrictionProfiles: userForCompany ? userForCompany.roleRestrictionProfiles! : {},
            }}
            disabled={!userForCompany}
          />
        </PanelText>
      </Panel>
    </>
  );
};

export const AdminContractCompanyUserPage = () => {
  const { contractId, companyId, userId } = contractCompanyUserRoute.useParams();
  const [ref, inView, entry] = useInView();

  const { data: contract, error, isLoading } = useAdminContract(contractId);

  const company = find(contract?.senders, { _id: companyId }) ?? find(contract?.recipients, { _id: companyId });

  const user = (contract && company) ? getUser(contract, companyId, userId) : null;

  return (
    <Page
      subHeading={contract && company && user && !inView && entry && (
        <ContractProvider contract={contract}>
          <PageTitle
            contractId={contractId}
            company={company}
            user={user}
          />
        </ContractProvider>
      )}
    >
      {isLoading ? (
        <Panel>
          <PanelText>
            <Loading />
          </PanelText>
        </Panel>
      ) : error ? (
        <Panel heading="Error">
          <PanelText>Oh no</PanelText>
        </Panel>
      ) : contract && company && user ? (
        <ContractProvider contract={contract}>
          <div ref={ref}>
            <PageTitle
              flexDirection="column"
              contractId={contractId}
              company={company}
              user={user}
            />
          </div>
          <AdminContractCompanyUserPanels
            contractId={contractId}
            company={company}
            user={user}
          />
        </ContractProvider>
      ) : null}
    </Page>
  );
};
