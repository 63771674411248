import * as React from 'react';
import { Action, ActionType, RfxOtherSection, RfxSection } from '@deepstream/common/rfq-utils';
import { conforms, filter, find, last, matches, overEvery, partition, reverse, some, sortBy, toLower } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import { SpaceProps } from 'styled-system';
import { getDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { consecutiveGrouper } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { NotificationDomain } from '@deepstream/common/notification-utils';
import { ScrollToBottom, AuctionChatScrollToBottomStyles } from '../../../ScrollToBottomStyles';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { DelayedSpinner } from '../../../ui/Loading';
import { useRfqExchange, useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { ExchangeSnapshot } from '../../../types';
import { useAllExchanges } from '../../../useAllExchanges';
import { useSendExchangeReply } from '../../../ExchangeModal/useSendExchangeReply';
import {
  ActionNotificationSubject,
  useActionNotificationSubject,
  useRfxActionNotificationSubject,
} from '../../../ExchangeModal/useActionNotificationSubject';
import { Datetime } from '../../../Datetime';
import { CompanyLogo } from '../../../CompanyLogo';
import { Counter } from '../../../ui/Badge';
import { useExchangeCommentNotifications } from '../../Exchange/ChatCell';
import { SearchInput } from '../../../ui/Input';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';
import { useNotifications } from '../../Notifications/NotificationsProvider';
import { useBroadcastChatMessage } from '../../../ExchangeModal/useBroadcastChatMessage';
import { SendMessageForm } from '../../../SendMessageForm';
import { Notification } from '../../Notifications/types';

export const AuctionChatListItem = ({
  companyId,
  companyName,
  latestMessageText,
  latestMessageDate,
  onClick,
  notificationsCount,
}) => {
  return (
    <WrapperButton width="100%">
      <Flex
        onClick={onClick}
        py="12px"
        px={3}
        width="100%"
        alignItems="center"
        sx={{
          ':hover': {
            bg: 'lightGray3',
          },
        }}
      >
        <CompanyLogo companyId={companyId} size="md" />
        <Box flex={1}>
          <Flex width="100%">
            <Truncate textAlign="left" color="text" flex={1}>
              {companyName}
            </Truncate>
            <Text color={notificationsCount > 0 ? 'danger' : 'subtext'} fontSize={1}>
              <Datetime value={latestMessageDate} onlyTime isCondensed />
            </Text>
          </Flex>
          <Flex>
            <Truncate color="subtext" flex={1} textAlign="left">
              {latestMessageText}
            </Truncate>
            <Counter count={notificationsCount} ml={1} />
          </Flex>
        </Box>
      </Flex>
    </WrapperButton>
  );
};

const AuctionChatExchangePreview = ({ exchange, onClick }: { exchange: ExchangeSnapshot; onClick: () => void }) => {
  const notifications = useExchangeCommentNotifications(exchange);
  const latestAction = last(exchange.history.filter(action => action.comment));

  return (
    <AuctionChatListItem
      companyId={exchange.recipientId}
      // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      companyName={exchange.companies[exchange.recipientId].name}
      onClick={onClick}
      latestMessageText={latestAction?.comment}
      latestMessageDate={latestAction?.date}
      notificationsCount={notifications.length}
    />
  );
};

export const AuctionChatList = ({
  searchValue,
  setSearchValue,
  onExchangeClick,
  onBroadcastMessageClick,
  onClose,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  onExchangeClick: (exchange: ExchangeSnapshot) => void;
  onBroadcastMessageClick: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const section = rfx.useSection<RfxOtherSection>();
  const pagePermissions = rfx.usePagePermissions();

  // TODO we filter allExchanges below -- instead of requesting all
  // exchanges from the server, just request the ones for this section!
  // Currently, `useAllExchanges` is also called in the ancestor component
  // `RfxAuctionContainer` -- when we refactor the query for chats, we
  // should also get rid of `useAllExchanges` in the `RfxAuctionContainer`.
  const { data: allExchanges, isLoading, queryKey } = useAllExchanges({
    rfqId,
    currentCompanyId,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  const exchanges: ExchangeSnapshot[] = filter(
    allExchanges,
    exchange => section.exchangeDefIds.includes(exchange._id),
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value),
    [setSearchValue],
  );

  const clearSearch = React.useCallback(
    () => setSearchValue(''),
    [setSearchValue],
  );

  const filteredExchanges = React.useMemo(
    () => filter(
      exchanges,
      exchange => {
        // @ts-ignore ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        const recipientName = exchange.companies[exchange.recipientId].name;
        return toLower(recipientName).includes(toLower(searchValue));
      },
    ),
    [searchValue, exchanges],
  );

  const chatMessageNotificationFilters = React.useMemo(
    () => overEvery([
      matches({
        domain: NotificationDomain.RFQ_SENT,
        to: {
          companyId: currentCompanyId,
        },
        meta: {
          rfqId,
          actionType: ActionType.NONE,
        },
      }),
      conforms({
        meta: (meta: Notification['meta']) => section.exchangeDefIds.includes(meta.exchangeId),
      }) as any,
    ]),
    [section, currentCompanyId, rfqId],
  );

  const chatMessageNotifications = useNotifications(chatMessageNotificationFilters);

  const [
    exchangesWithNotifications,
    exchangesWithoutNotifications,
  ] = React.useMemo(
    () => partition(
      filteredExchanges,
      exchange => some(
        chatMessageNotifications,
        notification => notification.meta.exchangeId === exchange.def._id,
      ),
    ),
    [filteredExchanges, chatMessageNotifications],
  );

  const sortedExchangesWithNotifications = React.useMemo(
    () => reverse(sortBy(
      exchangesWithNotifications,
      exchange => {
        const latestAction = last(exchange.history.filter(action => action.comment));

        return latestAction?.date;
      },
    )),
    [exchangesWithNotifications],
  );

  const sortedExchangesWithoutNotifications = React.useMemo(
    () => sortBy(
      exchangesWithoutNotifications,
      exchange => {
        const recipient = find(exchange.companies, company => company._id === exchange.recipientId);

        // @ts-ignore ts(18048) FIXME: 'recipient' is possibly 'undefined'.
        return toLower(recipient.name);
      },
    ),
    [exchangesWithoutNotifications],
  );

  return isLoading ? (
    <DelayedSpinner />
  ) : exchanges.length ? (
    <Panel height="100%">
      <Flex flexDirection="column" height="100%">
        <ModalHeader onClose={onClose}>
          {t('request.auction.chat.chatListHeader')}
        </ModalHeader>
        <Box flex={0} p="12px 16px">
          <SearchInput
            initialValue={searchValue}
            canClear={Boolean(searchValue.length)}
            placeholder={t('request.auction.chat.supplierSearchPlaceholder')}
            onChange={onChange}
            clearSearch={clearSearch}
          />
        </Box>
        {pagePermissions.canComment && (
          <Box flex={0} px={3} pt={0} pb="12px">
            <Button
              small
              variant="secondary-outline"
              onClick={onBroadcastMessageClick}
              sx={{ justifyContent: 'center', width: '100%' }}
            >
              <IconText
                text={t('request.auction.chat.broadcastButtonText')}
                icon="bullhorn"
              />
            </Button>
          </Box>
        )}
        <PanelDivider />
        <Box flex={1} overflow="auto">
          {[
            ...sortedExchangesWithNotifications,
            ...sortedExchangesWithoutNotifications,
          ].map(exchange => (
            <React.Fragment key={exchange._id}>
              <AuctionChatExchangePreview
                exchange={exchange}
                onClick={() => onExchangeClick(exchange)}
              />
              <PanelDivider />
            </React.Fragment>
          ))}
        </Box>
      </Flex>
    </Panel>
  ) : (
    <>{t('request.auction.chat.noSuppliers')}</>
  );
};

const DateHeading = ({ date }: { date: Date }) => (
  <Text fontSize={2} mx="auto" color="gray">
    <Datetime value={date} onlyDate isCondensed />
  </Text>
);

export const MessageBase = React.forwardRef(({
  variant,
  placement,
  name,
  body,
  date,
  maxWidth,
}: {
  variant: 'blue' | 'gray';
  placement: 'left' | 'right';
  name: string;
  body: React.ReactNode;
  date: Date | number;
  maxWidth: React.CSSProperties['maxWidth'];
}, ref) => {
  return (
    <Box
      ml={placement === 'right' ? 'auto' : 0}
      mr={placement === 'right' ? 0 : 'auto'}
      bg={variant === 'blue' ? 'primary' : 'lightGray2'}
      maxWidth={maxWidth}
      p={2}
      sx={{ borderRadius: 'small' }}
      ref={ref}
    >
      <Stack gap="2px">
        <Flex justifyContent="space-between">
          <Text color={variant === 'blue' ? 'white' : 'primary'} fontSize={1} fontWeight={500} mr={2}>
            {name}
          </Text>
          <Text color={variant === 'blue' ? 'lightGray2' : 'gray'} fontSize={1}>
            <Datetime value={date} onlyTime isCondensed />
          </Text>
        </Flex>
        <Text color={variant === 'blue' ? 'white' : 'text'} sx={{ whiteSpace: 'pre-wrap' }} fontSize={2} lineHeight={1.5}>
          {body}
        </Text>
      </Stack>
    </Box>
  );
});

const Message = ({ exchange, action }: { exchange: ExchangeSnapshot; action: Action }) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const actionNotificationSubject = useActionNotificationSubject();

  const chatMessageRef = actionNotificationSubject({
    exchange,
    action,
  });

  return (
    <MessageBase
      ref={chatMessageRef}
      variant={currentCompanyId === action.companyId ? 'blue' : 'gray'}
      placement={currentCompanyId === action.companyId ? 'right' : 'left'}
      name={action.user.name}
      body={action.comment}
      date={action.date}
      maxWidth={300}
    />
  );
};

const AuctionChatHistory = ({ exchange }: { exchange: ExchangeSnapshot }) => {
  // Group the messages into arrays by date, and group those arrays by user+company combination
  const actionGroupsByDateByUser: Action[][][] = exchange.history
    .filter(event => event.comment)
    .reduce(
      consecutiveGrouper<Action>((previous, next) =>
        // @ts-ignore ts(18048) FIXME: 'previous' is possibly 'undefined'.
        getDate(new Date(previous.date)) === getDate(new Date(next.date)),
      ),
      [] as Action[][],
    )
    .map(group =>
      group.reduce(
        consecutiveGrouper<Action>((previous, next) =>
          previous?.user._id === next.user._id && previous?.companyId === next.companyId,
        ),
        [] as Action[][],
      ),
    );

  return (
    <Stack gap={3}>
      {actionGroupsByDateByUser.map((actionsByUser, index) => (
        <React.Fragment key={index}>
          <DateHeading date={actionsByUser[0][0].date} />
          {actionsByUser.map((actions, index) => (
            <Stack key={index} gap={1}>
              {actions.map((action, index) => (
                <Message key={index} exchange={exchange} action={action} />
              ))}
            </Stack>
          ))}
        </React.Fragment>
      ))}
    </Stack>
  );
};

const BackButton = ({
  onClick,
  mr,
  ml,
}: {
  onClick: () => void,
  mr: SpaceProps['mr'],
  ml?: SpaceProps['ml'],
 }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="wrapper"
      type="button"
      onClick={onClick}
      mr={mr}
      ml={ml}
      sx={{
        height: '30px',
        width: 'auto',
        padding: '4px !important',
        color: 'primary',
        transition: 'color 300ms',
        ':hover:not(:disabled)': {
          opacity: 0.8,
          cursor: 'pointer',
        },
      }}
    >
      <Flex justifyContent="center" alignItems="center">
        <Icon
          fontSize={18}
          icon="chevron-left"
          aria-label={t('request.auction.chat.backToList')}
        />
      </Flex>
    </Button>
  );
};

export const BroadcastChatExchange = ({
  onBack,
}: {
  onBack: () => void;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();

  const [sendBroadcastMessage] = useBroadcastChatMessage({ rfqId });

  return (
    <Panel height="100%">
      <Flex flexDirection="column" height="100%">
        <ModalHeader>
          <Flex alignItems="center">
            <BackButton onClick={onBack} mr={2} />
            <Icon icon="bullhorn" mr={2} />
            {t('request.auction.chat.chatWithBroadcast')}
          </Flex>
        </ModalHeader>
        <Flex flexDirection="column" p={3} flex={1}>
          <Box flex={1} />
          <MessageBlock variant="info" mt={0}>
            {t('request.auction.chat.broadcastInfo')}
          </MessageBlock>
        </Flex>
        <PanelDivider />
        <Box p={3}>
          <SendMessageForm
            // @ts-ignore ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            onSubmit={action => sendBroadcastMessage(action.comment)}
            placeholder={t('request.auction.chat.inputPlaceholderBroadcast')}
          />
        </Box>
      </Flex>
    </Panel>
  );
};

export const AuctionChatExchange = ({
  exchangeId,
  recipientId,
  onClose,
  onBack,
}: {
  exchangeId: string;
  recipientId: string;
  onClose: () => void;
  onBack: () => void;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const pagePermissions = rfx.usePagePermissions();
  const senders = rfx.useSenders();
  const isSender = rfx.useIsSender();
  const counterPartyId = isSender ? recipientId : senders[0]._id;

  const { data: exchange, isLoading, queryKey } = useRfqExchange({
    exchangeId,
    recipientId,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  const [sendExchangeReply] = useSendExchangeReply({
    rfqId,
    exchangeId,
    recipientId,
  });

  return isLoading ? (
    <DelayedSpinner />
  ) : (
    <>
      <Panel height="100%">
        <Flex flexDirection="column" height="100%">
          <ModalHeader onClose={isSender ? undefined : onClose}>
            <Flex alignItems="center">
              {isSender && (
                <BackButton onClick={onBack} mr={2} ml={-1} />
              )}
              <CompanyLogo companyId={counterPartyId} />
              {/*
               // @ts-ignore ts(18048) FIXME: 'exchange' is possibly 'undefined'. */}
              {t('request.auction.chat.chatWith', { companyName: exchange.companies[counterPartyId].name })}
            </Flex>
          </ModalHeader>
          <ScrollToBottom>
            <Flex flexDirection="column" p={3}>
              {/* this box pushed the history down when it's less than the height of the screen */}
              <Box flex={1} />
              <ActionNotificationSubject.Provider value={useRfxActionNotificationSubject}>
                {/*
                 // @ts-ignore ts(2322) FIXME: Type '{ history: any[]; _id: string; currentCompanyId: string; companies: Record<string, CompanyMinimized & { group?: string | undefined; }>; hasLock: boolean; isLocked: boolean; ... 29 more ...; isCountersigningDisabled?: boolean | undefined; } | undefined' is not assignable to type 'ExchangeSnapshot'. */}
                <AuctionChatHistory exchange={exchange} />
              </ActionNotificationSubject.Provider>
            </Flex>
          </ScrollToBottom>
          <PanelDivider />
          <Box p={3}>
            {pagePermissions.canComment ? (
              <SendMessageForm
                onSubmit={sendExchangeReply}
                placeholder={t('request.auction.chat.inputPlaceholder')}
              />
            ) : (
              <Text color="subtext">
                {t('request.auction.chat.readOnlyPermission')}
              </Text>
            )}
          </Box>
        </Flex>
      </Panel>
      <AuctionChatScrollToBottomStyles />
    </>
  );
};

export const AuctionChat = ({ isSender, recipientId, onClose }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const section = rfx.useSection<RfxSection>();

  const initialExchangeKey = isSender
    ? null
    : { recipientId, exchangeId: section.exchangeDefIds[0] };

  // This should be set when we're looking at a specific exchange
  const [exchangeKey, setExchangeKey] = React.useState<{ exchangeId: string; recipientId: string } | null>(initialExchangeKey);
  const [isBroadcastMessage, setIsBroadcastMessage] = React.useState(false);

  return exchangeKey ? (
    <AuctionChatExchange
      onBack={() => setExchangeKey(null)}
      onClose={onClose}
      exchangeId={exchangeKey.exchangeId}
      recipientId={exchangeKey.recipientId}
    />
  ) : isBroadcastMessage ? (
    <BroadcastChatExchange onBack={() => setIsBroadcastMessage(false)} />
  ) : (
    <AuctionChatList
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      onClose={onClose}
      onBroadcastMessageClick={() => setIsBroadcastMessage(true)}
      onExchangeClick={exchange =>
        setExchangeKey({
          exchangeId: exchange._id,
          // @ts-ignore ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          recipientId: exchange.recipientId,
        })
      }
    />
  );
};
