import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useContractsReportingData } from './useContractsReporting';
import { StaticTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { CompanyMinimizedNameAndLogoCell } from '../../CompanyMinimizedNameAndLogoCell';
import { CurrencyAmountCell } from '../../ui/Currency';
import { VolumeValueFieldToggle } from './VolumeValueFieldToggle';

export const CounterpartyVolumeTable = () => {
  const { t } = useTranslation('reporting');
  const { data: { counterpartiesStats } } = useContractsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('contracts.counterparty'),
        accessor: 'name',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { minHeight: '47px', height: '47px', hasLink: false }),
      },
      {
        id: 'numNegotiatingContracts',
        Header: t('contracts.negotiating'),
        accessor: 'numNegotiatingContracts',
      },
      {
        id: 'numAgreedContracts',
        Header: t('contracts.agreed'),
        accessor: 'numAgreedContracts',
      },
      {
        id: 'numActiveContracts',
        Header: t('contracts.active'),
        accessor: 'numActiveContracts',
      },
      {
        id: 'numTotalContracts',
        Header: t('contracts.total'),
        accessor: 'numTotalContracts',
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          isPaginated={counterpartiesStats.length > 5}
          smallPageControls
          columns={columns}
          data={counterpartiesStats}
          noDataPlaceholder={t('contracts.noContracts')}
        />
      </StaticTableStyles>
    </Panel>
  );
};

export const CounterpartyValueTable = () => {
  const { t } = useTranslation('reporting');
  const { data: { counterpartiesStats } } = useContractsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('contracts.counterparty'),
        accessor: 'name',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { minHeight: '47px', height: '47px', hasLink: false }),
      },
      {
        id: 'negotiatingContractsValue',
        Header: t('contracts.negotiating'),
        accessor: 'negotiatingContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'agreedContractsValue',
        Header: t('contracts.agreed'),
        accessor: 'agreedContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'activeContractsValue',
        Header: t('contracts.active'),
        accessor: 'activeContractsValue',
        Cell: CurrencyAmountCell,
      },
      {
        id: 'totalContractsValue',
        Header: t('contracts.total'),
        accessor: 'totalContractsValue',
        Cell: CurrencyAmountCell,
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          isPaginated={counterpartiesStats.length > 5}
          smallPageControls
          columns={columns}
          data={counterpartiesStats}
          noDataPlaceholder={t('contracts.noContracts')}
        />
      </StaticTableStyles>
    </Panel>
  );
};

export const CounterpartyStats = () => {
  const [displayField, setDisplayField] = useState<'volume' | 'value'>('volume');

  return (
    <Stack gap={3}>
      <VolumeValueFieldToggle displayField={displayField} setDisplayField={setDisplayField} />
      {displayField === 'volume' ? (
        <CounterpartyVolumeTable />
      ) : (
        <CounterpartyValueTable />
      )}
    </Stack>
  );
};
