import * as React from 'react';
import { Formik, Form } from 'formik';
import { noop } from 'lodash';
import * as yup from 'yup';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { useAdminApi } from '@deepstream/ui/api';
import { TextField } from '@deepstream/ui/form/TextField';
import { useAdminNavigation } from './AppRouting';

type QuickFindFormProps = {
  onRedirect?: () => void;
};

const SearchIcon = styled(Text)`
  width: 18px;
  position: absolute;
  top: 30%;
  left: 20px;
  color: ${(props) => props.theme.colors.subtext};
`;

export const QuickFindForm: React.FC<QuickFindFormProps> = ({ onRedirect = noop }) => {
  const adminApi = useAdminApi();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [globalError, setGlobalError] = React.useState('');
  const navigation = useAdminNavigation();

  React.useEffect(
    () => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    },
    [inputRef],
  );

  return (
    <Formik
      initialValues={{
        query: '',
      }}
      validationSchema={
        yup.object().shape({
          query: yup.string().trim().required('Required'),
        })
      }
      onSubmit={async ({ query }, { setSubmitting }) => {
        try {
          const resource = await adminApi.getResourceById({ resourceId: query });
          navigation.navigateToResource(resource.type, resource._id);
          onRedirect();
        } catch (error: any) {
          const { response } = error;
          switch (response?.status) {
            case 404:
              setGlobalError('No match found');
              setSubmitting(false);
              break;
            case 400:
              onRedirect();
              navigation.navigateToSearch({ text: query });
              break;
            default:
              setGlobalError('Unexpected error');
              setSubmitting(false);
              break;
          }
        }
      }}
    >
      {() => (
        <Form style={{ position: 'relative' }}>
          <SearchIcon as="i" className="fa fa-search" />
          <TextField
            name="query"
            inputType="search"
            inputStyle={{ backgroundColor: 'white', paddingLeft: '50px', maxWidth: 440, height: 50 }}
            placeholder="Enter an ID, email address, or company name"
            ref={inputRef}
            error={globalError}
          />
        </Form>
      )}
    </Formik>
  );
};
