import { CellProps } from 'react-table';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { DateFormat } from '@deepstream/utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Datetime2 } from '../../../Datetime';

export const QuestionnaireExpiryDateCell = ({ value }: CellProps<QuestionnaireOverview, Date>) => {
  return value ? (
    <Datetime2
      value={value}
      format={DateFormat.DD_MMM_YYYY}
    />
  ) : (
    <EmDash />
  );
};
