import { useMemo } from 'react';
import { useField } from 'formik';
import { noop } from 'lodash';
import { Box } from 'rebass/styled-components';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { HelperText, ErrorMessage } from './Field';
import { FieldContainer } from './FieldContainer';
import { CompanySelect } from '../CompanySelect';
import { Company } from '../ui/types';
import { Placement } from '../ui/FilterSelectCombobox';

type CompanyFinderFieldBaseProps = {
  value?: Company | null;
  name?: string;
  required?: boolean;
  hideLabel?: boolean;
  onSelect?: (company?: Company) => void;
  onCreateCompany?: (company) => void;
  onBlur?: any;
  onFocus?: any;
  label?: string;
  searchCompanies: any;
  currentCompanyId: string;
  disabledCompanies?: { _id: string }[];
  helperText?: string;
  error?: string | null;
  hideError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  popoverWidth?: number;
  popoverPlacement?: Placement;
  disabledCompanyLabel?: string;
  withResultListSubheader?: boolean;
};

const CompanyFinderFieldBase = ({
  name,
  value,
  onSelect,
  onBlur,
  label,
  hideLabel,
  required,
  searchCompanies,
  disabledCompanies = [],
  error,
  hideError,
  helperText,
  ...props
}: CompanyFinderFieldBaseProps) => {
  const id = useUniqueId();

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
    >
      <CompanySelect
        controlled
        filterItems={searchCompanies}
        selected={value ?? undefined}
        onChange={onSelect}
        onTouched={onBlur}
        labelId={id}
        isDisabled={(company) => !!disabledCompanies?.find(disabled => disabled?._id === company?._id)}
        {...props}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export type CompanyFinderFieldProps = Omit<CompanyFinderFieldBaseProps, 'inputValue'> & {
  /**
   * The formik field name for the selected company
   */
  fieldName?: string;
  /**
   * Custom error message for the "required" error
   */
  requiredErrorMessage?: string;
};

/**
 * Formik-aware field for select a company.
 *
 * If you want formik to also manage the input text, use the `inputFieldName` prop
 * to bind the input text to formik.
 */
export const CompanyFinderField = ({
  name,
  fieldName,
  required,
  requiredErrorMessage,
  onSelect = noop,
  onCreateCompany,
  ...props
}: CompanyFinderFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [companyField, meta, companyFormik] = useField({ name: resolvedFieldName! });

  const handleCreateCompany = useMemo(
    () => {
      if (!onCreateCompany) return;

      return (company) => {
        companyFormik.setValue(company);
        onCreateCompany(company);
      };
    },
    [companyFormik, onCreateCompany],
  );

  return (
    <CompanyFinderFieldBase
      name={name}
      required={required}
      value={companyField.value}
      error={(meta.touched && meta.error) ? (meta.error as any)._id || meta.error : undefined}
      onSelect={company => {
        onSelect(company);
        companyFormik.setValue(company);
      }}
      onCreateCompany={handleCreateCompany}
      onBlur={() => {
        companyFormik.setTouched(true);
      }}
      onFocus={() => {
        // When the input is focused and the selected item is cleared, the `inputError`
        // will be set. To avoid this, we mark the field as untouched.
        if (companyField.value) {
          companyFormik.setTouched(false);
        }
      }}
      {...props}
    />
  );
};
