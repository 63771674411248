import * as React from 'react';
import { useQuery } from 'react-query';
import { map, filter, reject, noop, ValueIteratee } from 'lodash';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';

type Task = {
  _id: string;
  type: string;
  isComplete: boolean;
  data: Record<string, any>;
};

type TasksContextType = {
  tasks: Array<Task>;
  removeTasks: (iteratee: ValueIteratee<Task>) => void;
};

export const TasksContext = React.createContext<TasksContextType>({
  tasks: [],
  removeTasks: noop,
});

export const TasksProvider = (props: { children: React.ReactNode }) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();

  // We optimistically remove tasks from the UI
  const [removedTaskIds, setRemovedTaskIds] = React.useState<string[]>([]);

  const { data: tasks } = useQuery<Task[]>({
    queryKey: ['tasks', { companyId: currentCompanyId, state: 'incomplete' }],
    queryFn: wrap(api.getIncompleteTasks),
    refetchInterval: 30 * 1000,
    initialData: [],
    enabled: Boolean(currentCompanyId),
  });

  const value = React.useMemo(
    () => ({
      tasks: reject(tasks, task => removedTaskIds.includes(task._id)),
      removeTasks: (iteratee) => {
        setRemovedTaskIds(taskIds => [
          ...taskIds,
          ...map(filter(tasks, iteratee), task => task._id),
        ]);
      },
    }),
    [removedTaskIds, tasks],
  );

  return <TasksContext.Provider value={value} {...props} />;
};

export const useTasks = () => React.useContext(TasksContext);
