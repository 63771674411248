import { useMemo } from 'react';
import { compact, countBy, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RfqStatus } from '@deepstream/common/rfq-utils';
import { RequestsBudgetCardsData } from '@deepstream/common/reporting';
import { Box, Flex } from 'rebass/styled-components';
import { DoughnutChart } from '@deepstream/ui-kit/charts/DoughnutChart';
import { budgetColorById, inactiveBudgetColorById } from './colors';
import { DoughnutChartRequestCountOverlay } from './DoughnutChartRequestCountOverlay';

const excludeFromCountIds = ['noBudget'];

export const BudgetDoughnutChart = ({
  data,
}: {
  data: RequestsBudgetCardsData;
}) => {
  const { t } = useTranslation(['translation', 'reporting']);

  const slices = useMemo(() => {
    const counts = {
      ...countBy(data.requests, 'extendedStatus'),
      noBudget: data.noDataRequestCount,
    };

    return compact(
      [
        // order is relevant
        RfqStatus.DRAFT,
        RfqStatus.LIVE,
        RfqStatus.DEADLINES_PASSED,
        RfqStatus.AWARDED,
        'noBudget',
      ].map(id => {
        const count = counts[id];

        if (!count) {
          return null;
        }

        return {
          value: count,
          id,
          label: id === 'noBudget'
            ? t('request.spendAndSavings.noBudget')
            : t(`request.status.${id}`),
          activeColor: budgetColorById[id],
          inactiveColor: inactiveBudgetColorById[id],
        };
      }),
    );
  }, [data, t]);

  return isEmpty(slices) ? (
    <Flex width="100%" height="100%" justifyContent="center">
      {t('noData', { ns: 'reporting' })}
    </Flex>
  ) : (
    <DoughnutChart
      description={t('request.spendAndSavings.percentOfRequestsWithBudget')}
      slices={slices}
      excludeFromCountIds={excludeFromCountIds}
      legendPosition="right"
      Overlay={DoughnutChartRequestCountOverlay}
    />
  );
};
