import { useTranslation } from 'react-i18next';
import { EntityTeamMembership } from '@deepstream/common/user-utils';
import { LabeledValue } from '../../../draft/LabeledValue';
import { EntityTeamMembershipList } from './EntityTeamMembershipList';

export const UserQuestionnairesList = ({
  items,
}: {
  items: EntityTeamMembership[];
}) => {
  const { t } = useTranslation(['translation', 'preQualification']);

  return (
    <LabeledValue
      label={`${t('teamManagement.teamMemberFor')} ${t('requests.questionnaireCount', { count: items.length })}`}
      infoTooltip={t('teamManagement.eachQuestionnaire')}
      value={(
        <EntityTeamMembershipList
          items={items}
          noDataPlaceholder={t('noQuestionnaires', { ns: 'preQualification' })}
        />
      )}
      align="left"
      labelProps={{ mt: 3, mb: 1 }}
      valueProps={{ width: '100%' }}
    />
  );
};
