import { InitializationError } from '@deepstream/errors';
import { DateFormat, DateFormatOptions, localeFormatDate, localeFormatDateTz } from '@deepstream/utils';
import { LiveQuestionFormat, QuestionFormat } from './types';

export interface SerializedDate {
  isoStringDate: string,
  questionFormat: LiveQuestionFormat,
}

export abstract class ExtendedDateTimeBase {
  public date: Date;

  public static getDateFormat = (questionFormat: LiveQuestionFormat, withTimezone = true): DateFormat => {
    return {
      [QuestionFormat.DATE]: DateFormat.DD_MMM_YYYY,
      [QuestionFormat.DATETIME]: withTimezone ? DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ : DateFormat.DD_MMM_YYYY_HH_MM_A,
      [QuestionFormat.TIME]: withTimezone ? DateFormat.HH_MM_A_ZZZ : DateFormat.HH_MM_A,
    }[questionFormat];
  };

  // https://date-fns.org/v2.0.0-beta.2/docs/format, https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  protected abstract questionFormat: LiveQuestionFormat;

  public constructor(date: Date) {
    this.date = new Date(date);
  }

  protected get dateFormat(): DateFormat {
    return ExtendedDateTimeBase.getDateFormat(this.questionFormat);
  }

  /**
   * The serialized value to be stored in db
   */
  public serialize(): SerializedDate {
    return {
      isoStringDate: this.date.toISOString(),
      questionFormat: this.questionFormat,
    };
  }

  /**
   * The localized representation of this date
   * The timezone depends on the Intl API, which changes with versions of node/browser. E.g.
   * exDate.toString({ locale: 'en-GB', timeZone: 'Europe/London' }) in node12 is 06:29 GMT+1
   * exDate.toString({ locale: 'en-GB', timeZone: 'Europe/London' }) in node14 is 06:29 BST
   * So, in the browser we can get results different from a CSV report... And we will, most likely, get drift all the time.
   */
  public toString(options: DateFormatOptions = {}): string {
    return localeFormatDateTz(this.date, this.dateFormat, options);
  }
}

/**
 * By convention, extended dates are not TZ aware. The roundtrip:
 * In GMT-12:00 timezone we have this date        Mon Apr 11 2022 06:43:00 GMT-1200
 * In GMT+0 this gets written as                  Mon Apr 11 2022 00:00:00 GMT+0000
 * In GMT+14:00 timezone we have this date        Tue Apr 12 2022 08:43:00 GMT+1400 (Line Islands Time)
 * When reading we alter the above date and set
 * its year, month and day to the GMT+0 one's     Tue Apr 11 2022 08:43:00 GMT+1400 (Line Islands Time)
 *
 *
 */
export class ExtendedDate extends ExtendedDateTimeBase {
  protected questionFormat = QuestionFormat.DATE as const;

  /**
   * Since we are using a fixed date format, for now locale doesn't matter regarding the output of this date.
   */
  public override toString(options: DateFormatOptions = {}): string {
    return localeFormatDate(this.date, this.dateFormat, options);
  }

  /**
   * Save the current year, month and day in an iso date. This is just dumb storage.
   */
  public override serialize(): SerializedDate {
    const baseSerialization = super.serialize();
    const utcDate = new Date();
    utcDate.setUTCFullYear(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    utcDate.setUTCHours(0, 0, 0, 0);

    return {
      ...baseSerialization,
      isoStringDate: utcDate.toISOString(),
    };
  }
}

export class ExtendedTime extends ExtendedDateTimeBase {
  protected questionFormat = QuestionFormat.TIME as const;
}

export class ExtendedDateTime extends ExtendedDateTimeBase {
  protected questionFormat = QuestionFormat.DATETIME as const;
}

export abstract class ExtendedDateTimeBaseDeserializer {
  /**
   * From JS Date
   */
  public fromDate(date: Date): ExtendedTime | ExtendedDate | ExtendedDateTime {
    this.validateDate(date);
    return this.create(date);
  }

  /**
   * From stored db value
   */
  public fromRepresentation(isoStringDate: string) {
    const date = new Date(isoStringDate);
    this.validateDate(date);
    return this.create(date);
  }

  public abstract create(date: Date): ExtendedTime | ExtendedDate | ExtendedDateTime;

  protected validateDate(date: Date): void {
    // https://262.ecma-international.org/12.0/#sec-date.prototype.gettime
    if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
      throw new InitializationError('Malformed extended date representation', { date });
    }
  }
}

export class ExtendedDateDeserializer extends ExtendedDateTimeBaseDeserializer {
  public override fromRepresentation(isoStringDate: string): ExtendedDate {
    const utcDate = new Date(isoStringDate);
    this.validateDate(utcDate);
    const date = new Date();
    date.setFullYear(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    date.setHours(0, 0, 0, 0);
    return this.create(date);
  }

  public create(date: Date): ExtendedDate {
    return new ExtendedDate(date);
  }
}

export class ExtendedTimeDeserializer extends ExtendedDateTimeBaseDeserializer {
  public create(date: Date): ExtendedTime {
    return new ExtendedTime(date);
  }
}

export class ExtendedDateTimeDeserializer extends ExtendedDateTimeBaseDeserializer {
  public create(date: Date): ExtendedDateTime {
    return new ExtendedDateTime(date);
  }
}

export class ExtendedDateTimeFactory {
  private map = {
    [QuestionFormat.DATE]: ExtendedDateDeserializer,
    [QuestionFormat.DATETIME]: ExtendedDateTimeDeserializer,
    [QuestionFormat.TIME]: ExtendedTimeDeserializer,
  };

  public fromDate(date: Date, format: QuestionFormat.DATE): ExtendedDate;
  public fromDate(date: Date, format: QuestionFormat.DATETIME): ExtendedDateTime;
  public fromDate(date: Date, format: QuestionFormat.TIME): ExtendedTime;
  public fromDate(date: Date, format: LiveQuestionFormat): ExtendedTime | ExtendedDate | ExtendedDateTime {
    this.validateFormat(format);
    const serializer = new this.map[format]();

    return serializer.fromDate(date);
  }

  public fromRepresentation(extendedDate: SerializedDate) {
    const { isoStringDate, questionFormat } = extendedDate;
    this.validateFormat(questionFormat);
    const serializer = new this.map[questionFormat]();

    return serializer.fromRepresentation(isoStringDate);
  }

  private validateFormat(format: LiveQuestionFormat) {
    if (!this.isLiveQuestionFormat(format)) {
      throw new InitializationError('Invalid extended date format', { format });
    }
  }

  private isLiveQuestionFormat(format: string): format is LiveQuestionFormat {
    return ([QuestionFormat.DATETIME, QuestionFormat.DATE, QuestionFormat.TIME] as string[]).includes(format);
  }
}
