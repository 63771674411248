import { CellProps } from 'react-table';
import { CompanyLink } from './CompanyLink';

type CompanyLinkCellProps = CellProps<any> & { getCompanyId?: any };

export const CompanyLinkCell = ({ row, cell, getCompanyId }: CompanyLinkCellProps) => {
  const companyId = getCompanyId ? getCompanyId(row.original) : row.original._id;

  if (!companyId) {
    return cell.value || '(Not set)';
  }

  return (
    <CompanyLink companyId={companyId}>
      {cell.value || '(Not set)'}
    </CompanyLink>
  );
};
