export const stringify = (queryParams: Record<string, any>) => {
  const sanitizedQueryParams = Object
    .entries(queryParams)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => [key, item]);
      }

      return value
        ? [[key, value]]
        : [];
    });

  const searchParams = new URLSearchParams(sanitizedQueryParams);

  return searchParams.toString();
};

export const getArrayQueryParam = <T>(rawValue?: unknown): T[] => {
  return rawValue
    ? Array.isArray(rawValue)
      ? (rawValue as T[])
      : [rawValue as T]
    : [];
};
