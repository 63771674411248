import * as React from 'react';
import styled from 'styled-components';
import { useTheme } from '../theme/ThemeProvider';

export type ProgressCircleProps = {
  progress: number;
  width: number;
  borderWidth?: number;
  colorPrimary?: string;
  colorSecondary?: string;
};

// This can be removed once we ugprade to a version of React that supports transform-origin
const StrokeCircle = styled.circle`
  transform-origin: center;
`;

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  width,
  borderWidth = 2,
  colorPrimary: colorPrimaryProp,
  colorSecondary: colorSecondaryProp,
}) => {
  const theme = useTheme();

  const colorPrimary = colorPrimaryProp || theme.colors.primary;
  const colorSecondary = colorSecondaryProp || theme.colors.secondary;

  const x = width / 2;
  const y = width / 2;

  const radius = x - (borderWidth / 2);

  const progressCircumference = 2 * Math.PI * radius;

  return (
    <svg height={width} width={width} viewBox={`0 0 ${width} ${width}`}>
      <circle
        r={radius}
        cx={x}
        cy={y}
        fillOpacity={0}
        stroke={colorSecondary}
        strokeWidth={borderWidth}
      />
      <StrokeCircle
        r={radius}
        cx={x}
        cy={y}
        fillOpacity={0}
        stroke={colorPrimary}
        strokeWidth={borderWidth}
        strokeDasharray={`${progress * progressCircumference} ${progressCircumference}`}
        transform="rotate(-90)"
      />
    </svg>
  );
};
