import { useTranslation } from 'react-i18next';
import { first, isEmpty, size } from 'lodash';
import { Text } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const NameArray = ({
  entities,
  visibleEntities = 1,
}: {
  entities: string[];
  visibleEntities?: number;
}) => {
  const { t } = useTranslation();

  return isEmpty(entities) ? (
    <EmDash />
  ) : size(entities) === 1 ? (
    <>{first(entities)}</>
  ) : size(entities) <= visibleEntities ? (
    <>
      {entities.slice(0, visibleEntities - 1).join(', ')} {t('general.and')} {entities[visibleEntities - 1]}
    </>
  ) : (
    <span>
      {`${entities.slice(0, visibleEntities).join(', ')} ${t('general.and')} `}
      <Tooltip content={entities.slice(visibleEntities).join(', ')}>
        <Text as="span">
          {t('requests.otherCount', { count: size(entities) - visibleEntities })}
        </Text>
      </Tooltip>
    </span>
  );
};
