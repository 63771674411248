import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';

export const Required = () => {
  const { t } = useTranslation('general');

  return (
    <Text as="span" color="danger" ml="5px" aria-label={t('required')}>*</Text>
  );
};
