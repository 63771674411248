import { useMemo } from 'react';
import { PropertyList, TopAlignedRow } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import * as rfx from '@deepstream/ui/rfx';
import { Id } from './Id';
import { EnabledDisabledPropertyValue } from './EnabledDisabledPropertyValue';

export const TemplatePropertyList = ({ requestsCount }) => {
  const template = rfx.useTemplate();
  const { newFeaturesDisabled } = rfx.useStructure();

  const generalProperties = useMemo(
    () => {
      return [
        { name: 'Status', value: 'Template' },
        { name: 'Request name', value: template.meta.name, Component: Id, valueLabel: 'Request Name' },
        { name: 'ID', value: template._id, Component: Id },
        { name: 'Creator', value: template.meta.createdBy.name },
        { name: 'Created', value: template.meta.createdAt, Component: Datetime },
        { name: 'Last updated by', value: template.meta.lastEditedBy.name },
        { name: 'Last updated', value: template.meta.lastEditedAt, Component: Datetime },
        { name: 'Requests created', value: requestsCount === 1 ? `${requestsCount} request` : `${requestsCount ?? 0} requests` },
        {
          name: 'New request features – Lots; Award scenarios; New UX (Supplier and Buyer); New award flow.',
          value: !newFeaturesDisabled,
          Component: EnabledDisabledPropertyValue,
        },
      ].map(props => ({ ...props, heightAuto: true }));
    },
    [template, newFeaturesDisabled, requestsCount],
  );

  return (
    <PropertyList properties={generalProperties} Row={TopAlignedRow} />
  );
};
