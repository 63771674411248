import { useSessionStatusHandler } from './auth';
import { useRedirectToOnboarding } from './useAppInitRedirects';

export const RedirectIfSessionRejected = ({ children }: { children: React.ReactNode }) => {
  useSessionStatusHandler({
    status: 'rejected',
    handler: useRedirectToOnboarding({ setLoginRedirect: true }),
  });

  return <>{children}</>;
};
