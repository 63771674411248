import { useMemo } from 'react';
import { find } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { ContractOverview } from '@deepstream/common/contract';
import { useTranslation } from 'react-i18next';
import { useAdminNavigation } from './AppRouting';
import { ContractLinkCell } from './modules/Contracts/ContractLinkCell';

const getCompanyRole = (companyId: string) => ({ senders }: ContractOverview) => {
  const senderIds = senders.map(sender => sender._id);
  return senderIds.includes(companyId) ? 'Sender' : 'Recipient';
};

const getUserRole = (companyId: string, userId: string) => ({ senders, recipient }: ContractOverview) => {
  const company = find(senders, { _id: companyId }) ?? recipient;

  if (company.ownerIds.includes(userId)) {
    return 'Owner';
  } else {
    return 'Team member';
  }
};

type ContractsTableProps = {
  companyId: string;
  userId: string;
  contracts: any[];
};

export const CompanyUserContractsTable = ({ companyId, userId, contracts }: ContractsTableProps) => {
  const { t } = useTranslation('contracts');
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, ContractLinkCell),
        sortType: 'caseInsensitive',
      },
      {
        id: 'companyRole',
        Header: 'Company role',
        accessor: getCompanyRole(companyId),
        sortType: 'caseInsensitive',
        width: 170,
      },
      {
        id: 'userRole',
        Header: 'User role',
        accessor: getUserRole(companyId, userId),
        sortType: 'caseInsensitive',
        width: 170,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => t(`status.${item.status}`),
        sortType: 'caseInsensitive',
        width: 160,
      },
    ],
    [companyId, userId, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={contracts}
        onRowClick={contract => navigation.navigateToContractCompanyUser(contract._id, companyId, userId)}
        isSortable
        noFilteredDataPlaceholder="No contracts match chosen filters"
        hiddenColumns={!isExtraLarge && !isLarge ? ['userRole', 'status'] : []}
      />
    </BasicTableStyles>
  );
};
