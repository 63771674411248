import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { ModalForm } from '@deepstream/ui/ModalForm';
import { callAll } from '@deepstream/utils/callAll';
import { useMutation } from '@deepstream/ui/useMutation';
import { TextField } from '@deepstream/ui/form/TextField';

const useUpdateCompanySubscription = ({
  company,
  onSuccess,
  onError,
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  return useMutation(
    adminApi.updateCompanySubscription,
    {
      onSuccess: callAll(
        onSuccess,
        () => toaster.success('Recurly subscription ID successfully updated'),
      ),
      onError: callAll(
        onError,
        () => toaster.error('Could not update Recurly subscription ID'),
      ),
      onSettled: () => queryClient.invalidateQueries(['company', { companyId: company._id }]),
    },
  );
};

interface EditSubscriptionModalProps extends ModalProps {
  company: {
    _id: string;
    subscription?: {
      recurlySubscriptionUuid: string;
    };
  };
  onCancel: any;
  onSave?: any;
  onError?: any;
}

export const EditSubscriptionModal = ({
  company,
  onCancel,
  onSave,
  onError,
  isOpen,
}: EditSubscriptionModalProps) => {
  const [updateCompanySubscription] = useUpdateCompanySubscription({
    onSuccess: onSave,
    onError,
    company,
  });

  return (
    <ModalForm
      heading="Edit subscription"
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ recurlySubscriptionUuid }, { setSubmitting }) => {
        await updateCompanySubscription({
          companyId: company._id,
          isPaying: true,
          recurlySubscriptionUuid,
        }, {
          onSettled: () => setSubmitting(false),
        });
      }}
      initialValues={{
        recurlySubscriptionUuid: company.subscription?.recurlySubscriptionUuid,
      }}
      validationSchema={
        yup.object().shape({
          recurlySubscriptionUuid: yup.string().required('Required'),
        })
      }
    >
      <TextField name="recurlySubscriptionUuid" label="Recurly subscription ID" required />
    </ModalForm>
  );
};
