import * as React from 'react';

interface Urls {
  signup?: string; // url for the onboarding sign up page
  api: string; // base url for the api
  publicPages: string;
}

export const UrlContext = React.createContext<Urls>({} as Urls);

type UrlProviderProps = Urls & { children: React.ReactNode };

export const UrlProvider = ({ signup, api, publicPages, children }: UrlProviderProps) => {
  const contextValue = React.useMemo(
    () => ({ signup, api, publicPages }),
    [signup, api, publicPages],
  );

  return (
    <UrlContext.Provider value={contextValue}>
      {children}
    </UrlContext.Provider>
  );
};
