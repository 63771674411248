import { createLink } from '@tanstack/react-router';
import { Link as LinkBase } from 'rebass/styled-components';
import styled from 'styled-components';

const Link = createLink(LinkBase);

export const AdminTableLink = styled(Link)`
  color: ${props => props.theme.colors.text} !important;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
