import { isUndefined, omitBy } from 'lodash';

// TODO the default locale is set in the app admin preferences -- we should
// obtain that value from the server instead of hard-coding it here
export const DEFAULT_LOCALE = 'en-GB';

export const getUserLocale = (user?: { preferences?: { locale?: string } }) =>
  user?.preferences?.locale || DEFAULT_LOCALE;

export const getSignUpQueryParams = user =>
  omitBy({
    userId: user._id,
    secret: user.secret,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    locale: user.preferences?.locale,
  }, isUndefined);
