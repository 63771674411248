import React, { useMemo } from 'react';
import { isAuctionStage, Stage } from '@deepstream/common/rfq-utils';
import { CellProps } from 'react-table';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { nestCells } from '@deepstream/ui/nestCells';
import * as rfx from '@deepstream/ui/rfx';
import { useRfqId } from '@deepstream/ui/useRfq';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink } from './AdminTableLink';
import { requestAuctionRoute } from './AppRouting';

const IntentionDeadlineCell = ({ cell: { value: intentionDeadline } }: CellProps<Stage>) => (
  <>
    {intentionDeadline ? (
      `${intentionDeadline.number} ${intentionDeadline.timeUnit}`
    ) : (
      <EmDash />
    )}
  </>
);

const VisibilityCell = ({ cell: { value: isPrivate } }: CellProps<Stage>) => (
  <>
    {isPrivate ? 'Only suppliers in this stage' : 'All suppliers'}
  </>
);

export const AuctionLink = ({
  rfqId,
  auctionId,
  ...props
}: {
  rfqId: string;
  auctionId: string;
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={requestAuctionRoute.to}
    params={{ requestId: rfqId, auctionId }}
    onClick={stopPropagation}
    {...props}
  />
);

const StageLinkCell = ({ cell: { value }, row }: CellProps<Stage>) => {
  const rfqId = useRfqId({ required: true });

  return isAuctionStage(row.original) ? (
    <AuctionLink rfqId={rfqId} auctionId={row.original._id}>
      {value}
    </AuctionLink>
  ) : (
    value
  );
};

export const StagesTable = () => {
  const { stages } = rfx.useStructure();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(
          nestCells(TruncateCell, ValueOrDashCell),
          StageLinkCell,
        ),
      },
      {
        Header: 'Intention to proceed',
        accessor: 'intentionDeadline',
        Cell: IntentionDeadlineCell,
      },
      {
        Header: 'Deadline',
        accessor: 'completionDeadline',
        Cell: DatetimeCell,
      },
      {
        Header: 'Visibility',
        accessor: 'isPrivate',
        Cell: VisibilityCell,
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table columns={columns} data={stages} />
    </StaticTableStyles>
  );
};
