import { transparentize } from 'polished';
import * as React from 'react';
import { ColumnInstance, Row, useFilters, usePagination, useRowSelect, useSortBy, useTable, useExpanded } from 'react-table';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { isEqual, isFinite } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { usePrevious } from '@deepstream/ui-kit/hooks/usePrevious';
import { Pagination } from './Pagination';
import { useTableData } from './TableDataContext';
import { TableHoveredRowProvider, useHoveredTableRow } from './TableHoveredRowContext';

const Tr = styled.tr`
  &.selected,
  &.selected:hover {
    background-color: ${props => transparentize(0.95, props.theme.colors.primary)}
  }

  &:focus {
    background-color: ${props => props.theme.colors.lightGray3};
    outline: 0;
  }
`;

export const ROW_HEIGHT = 50;
export const SMALL_ROW_HEIGHT = 35;

type TableHeaderCellProps = {
  column: ColumnInstance & { textAlign: React.CSSProperties['textAlign']; paddingRight: React.CSSProperties['paddingRight']; };
  isFilterRowVisible?: boolean;
};

type SortButtonProps = {
  isSorted: ColumnInstance['isSorted'];
  isSortedDesc: ColumnInstance['isSortedDesc'];
};

const SortIndicator: React.FC<SortButtonProps> = ({ isSorted, isSortedDesc }) => (
  <Icon
    icon={isSorted ? isSortedDesc ? 'sort-desc' : 'sort-asc' : 'sort'}
    ml={1}
    color="gray"
  />
);

const TableHeaderCell = ({ column, isFilterRowVisible = false }: TableHeaderCellProps) => {
  const { t } = useTranslation();

  const { key, ...headerProps } = column.getHeaderProps(
    column.getSortByToggleProps({
      title: column.canSort ? t('table.toggleSort') : '',
      style: {
        width: column.width,
        paddingRight: column.paddingRight,
        borderBottom: isFilterRowVisible ? 0 : undefined,
        textAlign: column.textAlign,
        ...(column.canSort ? { userSelect: 'none', cursor: 'pointer' } : {}),
      },
    }),
  );

  return (
    <th
      key={key}
      {...headerProps}
    >
      {column.Header && column.render('Header')}
      {column.canSort && column.Header && (
        <SortIndicator
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
        />
      )}
    </th>
  );
};

const TableHeaderCheckboxCell: React.FC<any> = ({ getToggleAllRowsSelectedProps }) => (
  <Checkbox
    {...getToggleAllRowsSelectedProps({
      onClick: stopPropagation,
    })}
  />
);

const TableCheckboxCell: React.FC<any> = ({ row }) => (
  <Checkbox
    {...row.getToggleRowSelectedProps({
      onClick: stopPropagation,
    })}
  />
);

type TableRowProps = {
  row: Row;
  onRowClick?: ((row: any) => void) | null;
  onRowPointerEnter?: ((row: any) => void) | null;
  onRowPointerLeave?: ((row: any) => void) | null;
  ExpandedRow?: React.FC<any>;
  CustomRowCells?: React.FC<any>;
  isPageStart: boolean;
  isPageEnd: boolean;
  isPaginated: boolean;
};

const ENTER = 13;
const SPACE = 32;

const TableRow: React.FC<TableRowProps> = ({
  row,
  onRowClick,
  ExpandedRow,
  CustomRowCells,
  isPageStart,
  isPageEnd,
  isPaginated,
}) => {
  const isLink = Boolean(onRowClick);

  const navigateToItem = React.useCallback(
    () => onRowClick && onRowClick(row.original),
    [row, onRowClick],
  );

  const { setHoveredRowIndex } = useHoveredTableRow();
  const onPointerEnter = React.useCallback(
    () => setHoveredRowIndex?.(row.index),
    [row, setHoveredRowIndex],
  );
  const onPointerLeave = React.useCallback(
    // @ts-ignore ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<number>'.
    () => setHoveredRowIndex?.(null),
    [setHoveredRowIndex],
  );

  const handleKeyPress = React.useCallback(
    (event) => {
      if (isLink && (event.which === ENTER || event.which === SPACE)) {
        navigateToItem();
      }
    },
    [navigateToItem, isLink],
  );

  const { key, ...rowProps } = row.getRowProps({
    'data-paginated': isPaginated,
    'data-page-start': isPageStart,
    'data-page-end': isPageEnd,
  } as any);

  return (
    <>
      <Tr
        key={key}
        className={clsx('Table__row', row.isSelected && 'selected', !navigateToItem && 'unhoverable')}
        role={isLink ? 'link' : undefined}
        tabIndex={isLink ? 0 : undefined}
        onClick={isLink ? navigateToItem : undefined}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onKeyPress={handleKeyPress}
        {...rowProps}
      >
        {CustomRowCells ? (
          <CustomRowCells row={row} />
        ) : (
          row.cells.map(cell => {
            const { key, ...cellProps } = cell.getCellProps({
              style: {
                width: cell.column.width,
                paddingRight: (cell.column as any).paddingRight,
                textAlign: (cell.column as any).textAlign,
                verticalAlign: (cell.column as any).verticalAlign,
              },
            });

            return (
              <td
                key={key}
                {...cellProps}
              >
                {cell.render('Cell')}
              </td>
            );
          })
        )}
      </Tr>
      {ExpandedRow && row.isExpanded && (
        <ExpandedRow row={row} />
      )}
    </>
  );
};

const ErrorsRow = ({ headers }: { headers: ColumnInstance<object>[] }) => (
  <Tr className="Table__row">
    {headers.map(column => (
      // eslint-disable-next-line react/jsx-key
      <td style={{ width: column.width }}>
        {(column as any).error && (
          <Flex fontSize={1} color="danger">
            <Icon icon="exclamation-circle" mr={1} />
            <Text>{(column as any).error}</Text>
          </Flex>
        )}
      </td>
    ))}
  </Tr>
);

export type TableProps = {
  isSortable?: boolean;
  isPaginated?: boolean;
  hasStaticHeight?: boolean;
  setSelectedRows?: (rows: any[]) => void;
  columns: any[];
  data?: any[];
  onRowClick?: ((row: any) => void) | null;
  ExpandedRow?: React.FC<any>;
  CustomRowCells?: React.FC<any>;
  noFilteredDataPlaceholder?: string;
  noDataPlaceholder?: string;
  autoResetPage?: boolean;
  autoResetSortBy?: boolean;
  hiddenColumns?: string[];
  bodyCellVerticalAlign?: string;
  initialPageSize?: number;
  pageSizes?: number[];
  onPageSizeChange?: (pageSize: number) => void;
  initialSortBy?: { id: string; desc?: boolean }[];
  hideHeader?: boolean;
  enforcePagination?: boolean;
  smallPageControls?: boolean;
  minCellHeight?: number;
  selectionColumnWidth?: number;
  tableWrapperStyle?: React.CSSProperties;
};

// TODO: remove this when upgrading to tanstack/table v8
// see: https://github.com/TanStack/table/issues/2321#issuecomment-1999023028
const calculatePageCount = (dataLength: number, pageSize: number) => {
  return Math.ceil((dataLength + pageSize - 1) / pageSize);
};

export const Table: React.FC<TableProps> = React.memo(({
  isSortable = false,
  isPaginated = false,
  hasStaticHeight = true,
  setSelectedRows = undefined,
  columns,
  onRowClick,
  ExpandedRow,
  CustomRowCells,
  // required unless there's a TableDataContext
  data,
  noDataPlaceholder,
  noFilteredDataPlaceholder,
  autoResetPage: propsAutoResetPage,
  autoResetSortBy,
  initialPageSize: initialPageSizeParam,
  pageSizes,
  onPageSizeChange,
  hiddenColumns = [],
  // This is used for vertically aligning the cells in a row
  bodyCellVerticalAlign = 'middle',
  initialSortBy = [],
  hideHeader,
  enforcePagination,
  smallPageControls,
  minCellHeight,
  selectionColumnWidth = 55,
  tableWrapperStyle,
}) => {
  const { t } = useTranslation();
  const tableDataContext = useTableData();
  const [autoResetPage, setAutoResetPage] = React.useState(true);

  if (!tableDataContext && !data) {
    throw new Error('No table data. Table data must be provided by a TableDataContext or a data prop.');
  }

  const initialPageSize = isFinite(initialPageSizeParam) ? initialPageSizeParam! : 5;

  // TODO: remove this when upgrading to tanstack/table v8
  const pageCountRef = React.useRef(calculatePageCount(data?.length || 0, initialPageSize));

  const useTableDataContext = tableDataContext && !data;

  const sortTypes = React.useMemo(
    () => ({
      caseInsensitive: (a, b, id) => {
        const valueA = (a.values[id] ?? '').toLowerCase();
        const valueB = (b.values[id] ?? '').toLowerCase();

        return valueA > valueB ? 1 : -1;
      },
      number: (a, b, id) => {
        const valueA = a.values[id] ?? Infinity;
        const bValue = b.values[id] ?? Infinity;

        if (valueA > bValue) {
          return 1;
        }

        if (bValue > valueA) {
          return -1;
        }

        return 0;
      },
      boolean: (a, b, id) => {
        const valueA = a.values[id] ?? false;
        const bValue = b.values[id] ?? false;

        if (valueA > bValue) {
          return 1;
        }

        if (bValue > valueA) {
          return -1;
        }

        return 0;
      },
    }),
    [],
  );

  const tableData = React.useMemo(() => useTableDataContext ? tableDataContext.data : data!, [useTableDataContext, tableDataContext, data]);
  const previousTableDataLength = usePrevious(tableData.length);

  React.useEffect(() => {
    if (tableData.length !== previousTableDataLength) {
      setAutoResetPage(true);
    } else {
      setAutoResetPage(false);
    }
  }, [tableData.length, previousTableDataLength]);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    page,
    prepareRow,
    state,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data: tableData,
      sortTypes,
      disableSortBy: !isSortable,
      initialState: {
        pageIndex: useTableDataContext ? tableDataContext.pageIndex : 0,
        pageSize: useTableDataContext ? tableDataContext.pageSize : initialPageSize,
        hiddenColumns,
        sortBy: initialSortBy,
      },
      defaultColumn: { width: undefined },
      autoResetPage: typeof propsAutoResetPage === 'boolean' ? propsAutoResetPage : autoResetPage,
      autoResetSortBy,
      manualPagination: useTableDataContext,
      pageCount: useTableDataContext ? tableDataContext.pageCount : pageCountRef.current,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      if (setSelectedRows) {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: TableHeaderCheckboxCell,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: TableCheckboxCell,
            width: selectionColumnWidth,
          },
          ...columns,
        ]);
      }
    },
  );
  pageCountRef.current = calculatePageCount(tableData.length, pageSize);

  useWatchValue(hiddenColumns, setHiddenColumns, isEqual);

  const [isFilterRowVisible, setIsFilterRowVisible] = React.useState(false);
  const visibleRows = isPaginated ? page : rows;

  React.useEffect(() => {
    if (tableDataContext && (
      pageIndex !== tableDataContext.pageIndex ||
      pageSize !== tableDataContext.pageSize
    )) {
      tableDataContext.setPageControls({ pageIndex, pageSize });
    }
  }, [tableDataContext, pageIndex, pageSize]);

  React.useEffect(() => {
    if (onPageSizeChange) {
      onPageSizeChange(pageSize);
    }
  }, [onPageSizeChange, pageSize]);

  const height = isPaginated && hasStaticHeight
    ? (ROW_HEIGHT * pageSize) + (isFilterRowVisible ? ROW_HEIGHT * 2 : ROW_HEIGHT)
    : undefined;

  // Column instance doesn't officially have `Filter`
  const hasFilter = headers.some(column => column.canFilter && (column as any).Filter);
  const hasAppliedFilter = state.filters.length > 0;

  React.useEffect(
    () => setSelectedRows && setSelectedRows(selectedFlatRows.map(row => row.original)),
    [selectedFlatRows, setSelectedRows],
  );

  const hasErrors = React.useMemo(
    () => headers.some(column => (column as any).error),
    [headers],
  );

  return (
    <Text fontFamily="primary" fontSize={2}>
      <div style={{ height, ...tableWrapperStyle }}>
        <TableHoveredRowProvider>
          <table {...getTableProps()} className="Table">
            {!hideHeader && (
              <thead>
                <tr className="Table__row">
                  {headers.map(column => (
                    column.isVisible && column.Header ? (
                      <TableHeaderCell key={column.id} column={column as any} isFilterRowVisible={isFilterRowVisible} />
                    ) : (
                      null
                    )
                  ))}
                  {/* Filters */}
                  {hasFilter && (
                    <th style={{ width: 50, borderBottom: isFilterRowVisible ? 0 : undefined }}>
                      <Icon icon="filter" fontSize={4} onClick={() => setIsFilterRowVisible(!isFilterRowVisible)} style={{ cursor: 'pointer' }} />
                    </th>
                  )}
                </tr>
                {isFilterRowVisible && (
                  <tr className="Table__row">
                    {headers.map(column => (
                      column.isVisible ? (
                        <th key={column.id} style={{ fontWeight: 400 }}>
                          {/* TODO: ColumnInstance does not have Filter - is this a mistake? */}
                          {column.canFilter && (column as any).Filter ? column.render('Filter') : null}
                        </th>
                      ) : (
                        null
                      )
                    ))}
                  </tr>
                )}
              </thead>
            )}
            <tbody {...getTableBodyProps({ style: { verticalAlign: bodyCellVerticalAlign } })}>
              {visibleRows.map((row, index) => {
                  prepareRow(row);

                  const value = row.original as { _rowId?: string; _id: string };

                  return (
                    <TableRow
                      // eslint-disable-next-line no-underscore-dangle
                      key={value._rowId || value._id || index}
                      row={row}
                      onRowClick={onRowClick}
                      ExpandedRow={ExpandedRow}
                      CustomRowCells={CustomRowCells}
                      isPageStart={index % pageSize === 0}
                      isPageEnd={index % pageSize === pageSize - 1}
                      isPaginated={isPaginated}
                    />
                  );
                })}
              {visibleRows.length && hasErrors ? (
                <ErrorsRow headers={headers} />
                ) : (
                  null
                )}
              {!visibleRows.length && (
                <tr>
                  <td colSpan={headers.length}>
                    <Text p={3}>
                      {hasAppliedFilter ? (
                          noFilteredDataPlaceholder || t('table.noDataMatchChosenFilters')
                        ) : (
                          noDataPlaceholder || t('table.noDataToDisplay')
                        )}
                    </Text>
                  </td>
                </tr>
                )}
            </tbody>
          </table>
        </TableHoveredRowProvider>
      </div>
      {isPaginated && (pageCount > 1 || hasStaticHeight || enforcePagination) && (
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={pageSizes}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          small={smallPageControls}
          mt={enforcePagination && minCellHeight && pageSize !== visibleRows.length ? (
            (pageSize - visibleRows.length) * minCellHeight
          ) : (
            0
          )}
        />
      )}
    </Text>
  );
});
