import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { compact, first, intersection, isEmpty, map, size } from 'lodash';

import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { Text } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { FieldsCell, FieldsCellField, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { UserAvatar } from '../../../UserAvatar';
import { User } from '../../../types';
import { isAppAdminCompany } from '../../../useIsAppAdmin';
import { TeamUsersActionButtons } from '../../../TeamUsersActionButtons';
import { TeamUserCellTitle } from '../../../TeamUserCellTitle';
import { orderedPermissions, roleRestrictionProfileToGlobalRole } from './utils';
import { useCurrentUser } from '../../../useCurrentUser';

const GlobalRoleFieldValue = ({ value }: { value?: RoleRestrictionProfile }) => {
  const { t } = useTranslation();

  const globalRole = roleRestrictionProfileToGlobalRole(value);

  return (
    <>{t(`user.globalRole.${globalRole}`)}</>
  );
};

type Permissions = {
  [Key in typeof orderedPermissions[number]]: boolean;
};

const PermissionsFieldValue = ({ value }: { value: Permissions }) => {
  const { t } = useTranslation();

  const permissionLabels = React.useMemo(() => {
    const permissions = map(value, (hasPermission, role) =>
      hasPermission ? role : null,
    );

    return map(
      intersection(orderedPermissions, permissions),
      permission => t(`teamManagement.permissions.${permission}`),
    );
  }, [t, value]);

  return isEmpty(permissionLabels) ? (
    <>{t('general.none')}</>
  ) : size(permissionLabels) === 1 ? (
    <>{first(permissionLabels)}</>
  ) : (
    <span>
      {`${first(permissionLabels)} ${t('general.and')} `}
      <Tooltip content={permissionLabels.slice(1).join(', ')}>
        <Text as="span">
          {t('teamManagement.moreCount', { count: size(permissionLabels) - 1 })}
        </Text>
      </Tooltip>
    </span>
  );
};

type TeamUsersTableProps = {
  users: User[];
  entitiesCount: {
    contractsCount: Record<string, number>;
    requestsCount: Record<string, number>;
  };
  canEditPermissions: boolean;
  openEditPermissionsModal: (user: User) => void;
};

export const TeamUsersTable: React.FC<TeamUsersTableProps> = ({
  users,
  entitiesCount,
  canEditPermissions,
  openEditPermissionsModal,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const getEntitiesCount = React.useCallback(
    (user) => {
      const requestsCount = entitiesCount.requestsCount[user._id] ?? 0;
      const contractsCount = entitiesCount.contractsCount[user._id] ?? 0;

      return `${t('teamManagement.requestsAccessCount', { count: requestsCount })}, ${t('requests.contractCount', { count: contractsCount })} `;
    },
    [entitiesCount, t],
  );

  const columns = React.useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = React.useMemo(
    () => {
      const fields = compact<FieldsCellField>([
        {
          label: t('general.email'),
          accessor: 'email',
          // when there's no user name, the email is displayed in the cell's
          // title and thus shouldn't be repeated here
          condition: (user) => !!user.name,
        },
        {
          label: t('general.role'),
          accessor: `roleRestrictionProfiles.${currentCompanyId}`,
          FieldValueComponent: GlobalRoleFieldValue,
        },
        {
          label: t('teamManagement.permission_other'),
          accessor: `roles.${currentCompanyId}`,
          FieldValueComponent: PermissionsFieldValue,
        },
      ]);

      if (!isAppAdminCompany(currentCompanyId)) {
        fields.push({
          id: 'canAccess',
          label: t('teamManagement.teamMemberFor'),
          accessor: getEntitiesCount,
        });
      }

      return withProps(
        FieldsCell,
        {
          fields,
          TitleComponent: TeamUserCellTitle,
          RowIconComponent: UserAvatar,
          actionColumnWidth: 100,
          ActionsComponent: canEditPermissions
            ? withProps(
              TeamUsersActionButtons,
              { openEditPermissionsModal },
            )
            : () => null,
        },
      );
    },
    [t, currentCompanyId, canEditPermissions, openEditPermissionsModal, getEntitiesCount],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.teamUsers.pageSize`,
    defaultValue: 10,
  });

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={users}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        initialPageSize={initialPageSize}
        onPageSizeChange={setInitialPageSize}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
      />
    </BasicTableStyles>
  );
};
