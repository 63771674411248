import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { User } from '../../../types';
import { UserDetails } from '../../../UserDetails';

export const OnlyAdminInfoModal = ({
  modal,
  user,
}: {
  modal: any;
  user: User | null;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('teamManagement.dialog.onlyAdminInfo.heading')}
      style={{ content: { width: '500px' } }}
      body={user ? (
        <>
          <UserDetails user={user} />
          <MessageBlock variant="info" mt={3}>
            {t('teamManagement.dialog.onlyAdminInfo.body')}
          </MessageBlock>
        </>
      ) : (
        null
      )}
      okButtonText={t('general.ok')}
      okButtonVariant="primary"
      isOpen={modal.isOpen}
      onOk={modal.close}
    />
  );
};
