import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import { DocumentExchangeDefinition } from '@deepstream/common/rfq-utils';
import { exchangesConfig } from '@deepstream/common/exchangesConfig';
import { omitBy, mapValues } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { LockedTooltip } from '@deepstream/ui/LockedTooltip';
import { RfxStructure } from '@deepstream/ui/types';
import * as rfx from '@deepstream/ui/rfx';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';

type DocumentTypeProps = {
  cell: { value: DocumentExchangeDefinition };
  structure: RfxStructure;
};

const DocumentTypeCell: React.FC<DocumentTypeProps> = ({ cell, structure }) => {
  const { value } = cell;
  const exchangeConfig = value.type ? exchangesConfig[value.type] : null;

  if (!exchangeConfig) {
    return <EmDash />;
  }

  return (
    <Flex alignItems="center">
      {exchangeConfig.label}
      {Boolean(exchangeConfig.hasLock) && (
        <>
          {' '}(Locked)
          <LockedTooltip lock={value.locking} senders={structure.senders}>
            <div style={{ display: 'inline-block' }}>
              <Icon icon="lock" style={{ marginLeft: '10px' }} />
            </div>
          </LockedTooltip>
        </>
      )}
    </Flex>
  );
};

export const AdminDocumentsTable = ({
  exchangeDefIds,
}: {
  exchangeDefIds: string[];
}) => {
  const structure = rfx.useStructure();
  const bidStatusFilters = React.useMemo(() => mapValues(omitBy(exchangesConfig, 'isDeprecated'), item => item.label), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        Cell: ValueOrDashCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Type',
        accessor: 'exchangeDef',
        Cell: withProps(DocumentTypeCell, { structure }),
        Filter: withProps(FilterSelect, {
          itemToString: value => bidStatusFilters[value.type],
          transformSelectedValues: value => bidStatusFilters[value.type],
        }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [bidStatusFilters, structure],
  );

  const data = React.useMemo(
    () => exchangeDefIds.map(exchangeDefId => {
      const exchangeDef = structure.exchangeDefById[exchangeDefId] as DocumentExchangeDefinition;
      return {
        _id: exchangeDef._id,
        name: exchangeDef.category,
        stage: exchangeDef.stages ? structure.stageById[exchangeDef.stages[0]].name : null,
        exchangeDef,
      };
    }),
    [exchangeDefIds, structure],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
        isSortable
        noFilteredDataPlaceholder="No documents match chosen filters"
      />
    </StaticTableStyles>
  );
};
