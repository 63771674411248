import { PageRole, PageType } from '@deepstream/common/rfq-utils';

export const HIDDEN_ITEM_VALUE = '__hidden__';

const selectGenericPageRoleConfig = {
  labelKeyPrefix: 'teamManagement.pageRole',
  descriptionKeyPrefix: 'teamManagement.genericPageRoleDescription',
  items: [
    { pageRole: PageRole.NONE, icon: 'times' },
    { pageRole: PageRole.READER, icon: 'eye' },
    { pageRole: PageRole.COMMENTER, icon: 'comment-o' },
    { pageRole: PageRole.RESPONDER, icon: 'reply' },
    { pageRole: PageRole.EDITOR, icon: 'pencil' },
  ],
};

const selectAuctionSenderPageRoleConfig = {
  labelKeyPrefix: 'teamManagement.pageRole',
  descriptionKeyPrefix: 'teamManagement.auctionPageRoleDescription.sender',
  items: [
    { pageRole: PageRole.NONE, icon: 'times' },
    { pageRole: PageRole.READER, icon: 'eye' },
    { pageRole: PageRole.COMMENTER, icon: 'comment-o' },
    { pageRole: PageRole.EDITOR, icon: 'pencil' },
  ],
};

const selectAuctionRecipientPageRoleConfig = {
  labelKeyPrefix: 'teamManagement.pageRole',
  descriptionKeyPrefix: 'teamManagement.auctionPageRoleDescription.recipient',
  items: [
    { pageRole: PageRole.NONE, icon: 'times' },
    { pageRole: PageRole.READER, icon: 'eye' },
    { pageRole: PageRole.COMMENTER, icon: 'comment-o' },
    { pageRole: PageRole.RESPONDER, icon: 'reply' },
  ],
};

const selectOwnerEvaluationPageRoleConfig = {
  labelKeyPrefix: 'teamManagement.evaluationPageRole.owner',
  descriptionKeyPrefix: 'teamManagement.evaluationPageRoleDescription.owner',
  items: [
    { pageRole: PageRole.COMMENTER, icon: 'times' },
    { pageRole: PageRole.EDITOR, icon: 'balance-scale' },
  ],
};

export const selectTeamMemberEvaluationPageRoleConfig = {
  labelKeyPrefix: 'teamManagement.evaluationPageRole.teamMember',
  descriptionKeyPrefix: 'teamManagement.evaluationPageRoleDescription.teamMember',
  items: [
    { pageRole: PageRole.NONE, icon: 'times' },
    { pageRole: PageRole.RESPONDER, icon: 'balance-scale' },
    { pageRole: PageRole.EDITOR, icon: 'pencil' },
  ],
};

export const getSelectPageRoleConfig = (
  pageType: PageType | undefined,
  isSender: boolean,
  isOwner: boolean,
) => {
  switch (pageType) {
    case PageType.AUCTION:
      return isSender
        ? selectAuctionSenderPageRoleConfig
        : selectAuctionRecipientPageRoleConfig;
    case PageType.EVALUATION:
      return isOwner
        ? selectOwnerEvaluationPageRoleConfig
        : selectTeamMemberEvaluationPageRoleConfig;
    default:
      return selectGenericPageRoleConfig;
  }
};
