import { Box, Flex } from 'rebass/styled-components';

import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { ExcelFlowTrackingEventType } from '@deepstream/common';
import { useModalState } from '@deepstream/ui/ui/useModalState';

import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useMemo } from 'react';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { NumberCell } from '@deepstream/ui/draft/cell';
import { AdoptionRateTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { round } from 'lodash';
import {
  ProductDashboardFunnelMetric,
  ProductDashboardMetric,
  ProductDashboardSectionContainer,
  ProductDashboardSectionHeader,
} from './ProductDashboardUtils';
import { ExcelConversionFunnelModal } from './ExcelConversionFunnelModal';
import { ExcelClientAdoptionRateModal } from './ExcelClientAdoptionRateModal';
import { renderPercentage } from '../reports/utils';

const ConversionFunnel = ({ data }) => {
  const flowOpenedModal = useModalState();
  const excelDownloadedModal = useModalState();
  const lineItemsSubmittedModal = useModalState();

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Conversion funnel"
          icon="bars-filter"
          tooltip="The funnel counts unique users on unique requests."
        />

        <ProductDashboardSectionContainer>
          <ProductDashboardFunnelMetric
            label="1. Flow opened"
            mainValue={data[ExcelFlowTrackingEventType.FLOW_OPENED].total}
            proceededValues={{
              proceeded: data[ExcelFlowTrackingEventType.FLOW_OPENED].proceeded,
              notProceeded: data[ExcelFlowTrackingEventType.FLOW_OPENED].notProceeded,
            }}
            onViewMoreClick={flowOpenedModal.open}
            tooltip="Counted when a user opens the “Submit line items with Excel” flow"
          />
          <ProductDashboardFunnelMetric
            label="2. Excel downloaded"
            mainValue={data[ExcelFlowTrackingEventType.EXCEL_DOWNLOADED].total}
            proceededValues={{
              proceeded: data[ExcelFlowTrackingEventType.EXCEL_DOWNLOADED].proceeded,
              notProceeded: data[ExcelFlowTrackingEventType.EXCEL_DOWNLOADED].notProceeded,
            }}
            onViewMoreClick={excelDownloadedModal.open}
            tooltip="Counted when a user downloads the Excel file within the flow."
          />
          <ProductDashboardFunnelMetric
            label="3. Line items submitted"
            mainValue={data[ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED].users.length}
            totalValue={data[ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED].total}
            onViewMoreClick={lineItemsSubmittedModal.open}
            tooltip="Counted when a user submits line items and finishes the flow."
          />
        </ProductDashboardSectionContainer>
      </Flex>
      {data?.[ExcelFlowTrackingEventType.FLOW_OPENED]?.users && flowOpenedModal.isOpen && (
        <ExcelConversionFunnelModal
          heading="Conversion funnel - 1. Flow opened"
          isOpen={flowOpenedModal.isOpen}
          onOk={flowOpenedModal.close}
          onCancel={flowOpenedModal.close}
          users={data[ExcelFlowTrackingEventType.FLOW_OPENED].users}
        />
      )}
      {data?.[ExcelFlowTrackingEventType.EXCEL_DOWNLOADED]?.users && excelDownloadedModal.isOpen && (
        <ExcelConversionFunnelModal
          heading="Conversion funnel - 2. Excel downloaded"
          isOpen={excelDownloadedModal.isOpen}
          onOk={excelDownloadedModal.close}
          onCancel={excelDownloadedModal.close}
          users={data[ExcelFlowTrackingEventType.EXCEL_DOWNLOADED].users}
        />
      )}
      {data?.[ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED]?.users && lineItemsSubmittedModal.isOpen && (
        <ExcelConversionFunnelModal
          heading="Conversion funnel - 3. Line items submitted"
          isOpen={lineItemsSubmittedModal.isOpen}
          onOk={lineItemsSubmittedModal.close}
          onCancel={lineItemsSubmittedModal.close}
          users={data[ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED].users}
          step1Users={data[ExcelFlowTrackingEventType.FLOW_OPENED].users}
          showConversionFunnel
        />
      )}
    </PanelPadding>
  );
};

const AdoptionAndUsage = ({ data }) => {
  const companyAdoptionModal = useModalState();

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Adoption and usage"
          icon="users"
          isIconRegular
          tooltip={(
            <div>
              <b>Client adoption rate</b>
              <br />
              % of “paying companies” that have issued 1 or more requests on which 1 or more suppliers have submitted line items with Excel.
              <br />
              <br />
              <b>Request usage rate</b>
              <br />
              % of requests with 1 or more line items and an active deadline during the period where this feature has been used during the
              period by 1 or more supplier on the request.
            </div>
          )}
        />
        <Box sx={{ display: 'grid', gridTemplate: 'auto / 1fr 1fr 1fr', gap: 3 }}>
          <ProductDashboardMetric
            label="Client adoption rate (now)"
            value={renderPercentage({
              count: data.clientAdoptionRate.adoptedCount,
              total: data.clientAdoptionRate.totalCount,
            })}
            subValue={`${data.clientAdoptionRate.adoptedCount} of ${data.clientAdoptionRate.totalCount} clients`}
            onViewMoreClick={companyAdoptionModal.open}
          />
          <ProductDashboardMetric
            label="Request usage rate (this week)"
            value={renderPercentage({
              count: data.requestUsageRate.thisWeek.submitted,
              total: data.requestUsageRate.total,
            })}
            subValue={`${data.requestUsageRate.thisWeek.submitted} of ${data.requestUsageRate.thisWeek.total} requests`}
          />
          <ProductDashboardMetric
            label="Request usage rate (last week)"
            value={renderPercentage({
              count: data.requestUsageRate.lastWeek.submitted,
              total: data.requestUsageRate.lastWeek.total,
            })}
            subValue={`${data.requestUsageRate.lastWeek.submitted} of ${data.requestUsageRate.lastWeek.total} requests`}
          />
          <ProductDashboardMetric
            label="Submissions (all time)"
            value={data.submissions.allTime}
          />
          <ProductDashboardMetric
            label="Submissions (this week)"
            value={data.submissions.thisWeek}
          />
          <ProductDashboardMetric
            label="Submissions (last week)"
            value={data.submissions.lastWeek}
          />
        </Box>
      </Flex>
      {data?.clientAdoptionRate?.companiesWithAdoption && companyAdoptionModal.isOpen && (
        <ExcelClientAdoptionRateModal
          heading="Client adoption rate"
          isOpen={companyAdoptionModal.isOpen}
          onOk={companyAdoptionModal.close}
          onCancel={companyAdoptionModal.close}
          data={data.clientAdoptionRate}
        />
      )}
    </PanelPadding>
  );
};

export const SubmissionOutcomesTable = ({
  submissionOutcomes,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        id: 'outcome',
        Header: 'Outcome',
        accessor: 'name',
        Cell: TruncateCell,
      },
      {
        Header: 'Count of line items',
        accessor: 'count',
        Cell: NumberCell,
        width: 200,
      },
    ],
    [],
  );

  return (
    <AdoptionRateTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
    >
      <Table
        columns={columns}
        data={submissionOutcomes}
        isSortable
        initialSortBy={[{ id: 'count', desc: true }]}
      />
    </AdoptionRateTableStyle>
  );
};

const SubmissionOutcomes = ({ data }) => {
  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Submission outcomes"
          icon="check-circle"
          isIconRegular
        />
      </Flex>
      <SubmissionOutcomesTable submissionOutcomes={data} />
    </PanelPadding>
  );
};

const LineItemsPerSubmission = ({ data }) => {
  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Line items submitted successfully"
          icon="list-ul"
          isIconRegular
        />
      </Flex>
      <Box sx={{ display: 'grid', gridTemplate: 'auto / 1fr 1fr 1fr', gap: 3 }}>
        <ProductDashboardMetric
          label="Average"
          value={round(data.average, 2)}
          subValue={`${data.totalSubmissionsCount} submissions`}
        />
        <ProductDashboardMetric
          label="Minimum"
          value={data.minimum}
        />
        <ProductDashboardMetric
          label="Maximum"
          value={data.maximum}
        />
      </Box>
    </PanelPadding>
  );
};

export const ProductDashboardExcelSubmitTabContent = ({ status, data }) => {
  return (
    <Panel>
      <PanelHeader heading="Submit line items with Excel" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data?.excelSubmitActivity ? (
        <>
          <ConversionFunnel data={data.data.excelSubmitActivity.conversionFunnel} />
          <AdoptionAndUsage data={data.data.excelSubmitActivity.adoptionAndUsage} />
          <SubmissionOutcomes data={data.data.excelSubmitActivity.submissionOutcomes} />
          <LineItemsPerSubmission data={data.data.excelSubmitActivity.lineItemsPerSubmission} />
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : null}
    </Panel>
  );
};
