import * as React from 'react';
import { AdminConfig } from '@deepstream/ui/types';

export const ConfigContext = React.createContext<AdminConfig>({} as AdminConfig);

export const ConfigProvider = ({
  config,
  children,
}: {
  config: AdminConfig;
  children: React.ReactNode;
}) => (
  <ConfigContext.Provider value={config}>
    {children}
  </ConfigContext.Provider>
);

export const useConfig = () => {
  const config = React.useContext<AdminConfig>(ConfigContext);
  if (!config) throw new Error('No admin config found');
  return config;
};
