import { Box, BoxProps, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';

const TermsContainer = styled(Box)`
  > div {
    font-size: 14px;
    counter-reset: h2counter;
  }

  h2 {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 400;
  }

  h2:before {
    counter-increment: h2counter;
    content: counter(h2counter) '. ';
  }

  h2:nth-of-type(-n+9):before {
    content: '0'counter(h2counter) '. ';
  }

  p {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  > div > ol {
    padding-left: 0;
  }

  ol ol {
    padding-left: 16px;
  }

  ol {
    counter-reset: item;
  }

  li {
    display: block;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }

`;

export const TermsOfServicePreview = ({
  html,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  ...props
}: BoxProps & {
  html: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: string;
}) => {
  return (
    <TermsContainer
      overflowY="auto"
      p="8px 24px"
      {...props}
    >
      {isLoading ? (
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Loading />
        </Flex>
      ) : isError ? (
        <Flex height="100%" alignItems="center" justifyContent="center">
          <ErrorMessage error={errorMessage} />
        </Flex>
      ) : isSuccess ? (
        // This is fine because the terms HTML can only be set by DS admins
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        null
      )}
    </TermsContainer>
  );
};
