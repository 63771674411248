export enum KeyCode {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  BACKSPACE = 'Backspace',
  END = 'End',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  HOME = 'Home',
  PAGE_DOWN = 'PageDown',
  PAGE_UP = 'PageUp',
  SPACE = 'Space',
  TAB = 'Tab',
  A = 'KeyA',
  C = 'KeyC',
  V = 'KeyV',
  X = 'KeyX',
}
