import { useMutation as useMutationBase, MutationFunction, UseMutationOptions } from 'react-query';

/**
 * A wrapper around `useMutation` (from react-query@3) for backwards
 * compatibility with the signature from react-query@2
 */
export const useMutation = <
  TData,
  TVariables,
  TError,
  TContext,
> (fn: MutationFunction<TData, TVariables>, options?: UseMutationOptions<TData, TError, TVariables, TContext>) => {
  const { mutateAsync, ...remainingProps } = useMutationBase(fn, options);

  return [mutateAsync, remainingProps] as const;
};
