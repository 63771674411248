import { useTranslation } from 'react-i18next';
import { AnyScope, Stage, renderStageName } from '@deepstream/common/rfq-utils';

export const StageName = ({
  index,
  stage,
  withPrefix,
}: {
  index?: number;
  stage: Stage<AnyScope>;
  withPrefix?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {renderStageName(stage, t, index, withPrefix)}
    </>
  );
};
