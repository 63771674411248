import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

export const UserCount = ({ count }) => {
  const { t } = useTranslation();

  return (
    <Box fontSize={2}>
      {t('general.userCount', { count })}
    </Box>
  );
};
