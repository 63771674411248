import { useMemo } from 'react';
import { compact, countBy, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestsSavingsCardsData } from '@deepstream/common/reporting';
import { Flex } from 'rebass/styled-components';
import { DoughnutChart } from '@deepstream/ui-kit/charts/DoughnutChart';
import { inactiveConfirmStatusColorById, confirmStatusColorById } from './colors';
import { DoughnutChartRequestCountOverlay } from './DoughnutChartRequestCountOverlay';

const excludeFromCountIds = ['noValue', 'unconfirmed'];

export const SavingsDoughnutChart = ({
  data,
}: {
  data: RequestsSavingsCardsData;
}) => {
  const { t } = useTranslation(['translation', 'reporting']);

  const slices = useMemo(() => {
    const counts = {
      ...countBy(data.requests, item => item.areSavingsConfirmed ? 'confirmed' : 'unconfirmed'),
      noValue: data.noDataRequestCount,
    };

    return compact(
      [
        // order is relevant
        'confirmed',
        'unconfirmed',
        'noValue',
      ].map(id => {
        const count = counts[id];

        if (!count) {
          return null;
        }

        return {
          id,
          value: count,
          label: id === 'noValue' ? (
            t('request.spendAndSavings.noSavings')
          ) : id === 'confirmed' ? (
            t('request.spendAndSavings.savingsConfirmed')
          ) : (
            t('request.spendAndSavings.savingsUnconfirmed')
          ),
          activeColor: confirmStatusColorById[id],
          inactiveColor: inactiveConfirmStatusColorById[id],
        };
      }),
    );
  }, [data, t]);

  return isEmpty(slices) ? (
    <Flex width="100%" height="100%" justifyContent="center">
      {t('noData', { ns: 'reporting' })}
    </Flex>
  ) : (
    <DoughnutChart
      description={t('request.spendAndSavings.percentOfRequestsWithSavings')}
      slices={slices}
      excludeFromCountIds={excludeFromCountIds}
      legendPosition="bottom"
      width="182px"
      height="182px"
      Overlay={DoughnutChartRequestCountOverlay}
    />
  );
};
