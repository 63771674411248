export const copyToClipboard = (text = '') => navigator.clipboard.writeText(text);

export const readFromClipboard = async () => {
  let readPermissionState;

  try {
    const queryResult = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName });
    readPermissionState = queryResult.state;
  } catch (err) {
    // ignore error in browsers that don't support querying for 'clipboard-read' (Firefox)
  }

  if (readPermissionState === 'denied') {
    return null;
  } else {
    return navigator.clipboard.readText();
  }
};
