import { useMemo } from 'react';
import { sumBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { RequestsSavingsCardsData } from '@deepstream/common/reporting';
import { TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { RenderTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderTableDataCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import {
  CurrencyAmountOrDashCell,
  NumberOrDashCell,
  PercentOrDashCell,
  TextWithInfoTooltipCell,
} from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { SimpleHeader } from '../../ui/ExchangeDefsGrid/header';

const P = styled.p`
  margin-bottom: 6px;
`;

const Ol = styled.p`
  margin-bottom: 6px;
  list-style-type: decimal;
  list-style-position: inside;
  text-indent: -16px;
  padding-left: 16px;
`;

export const SavingsByMethodTable = ({
  data,
}: {
  data: RequestsSavingsCardsData;
}) => {
  const { t } = useTranslation('translation');

  const {
    rows,
    totalAmount,
  } = useMemo(() => {
    const filteredRequests = data.requests.filter(request => request.areSavingsConfirmed);

    const rowData = filteredRequests.reduce((accum, current) => {
      switch (current.savingsCalculationMethod) {
        case TotalSavingsCalculationMethod.MANUAL:
          accum.manualSavings.amount += current.manualSavings || 0;
          accum.manualSavings.requestCount += 1;
          break;
        case TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF:
          accum.budgetFinalValueSavings.amount += current.budgetFinalValueSavings || 0;
          accum.budgetFinalValueSavings.requestCount += 1;
          break;
        case TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS:
          accum.singleResponseLineItemSavings.amount += current.singleResponseLineItemSavings || 0;
          accum.singleResponseLineItemSavings.requestCount += 1;
          accum.multiResponseLineItemSavings.amount += current.multiResponseLineItemSavings || 0;
          accum.multiResponseLineItemSavings.requestCount += 1;
          accum.auctionLineItemSavings.amount += current.auctionLineItemSavings || 0;
          accum.auctionLineItemSavings.requestCount += 1;
          break;
        default:
          break;
      }

      return accum;
    }, {
      singleResponseLineItemSavings: {
        _id: 'singleResponseLineItemSavings',
        label: `${t('request.lineItems.lineItem_other')}: ${t('request.multiStageBehavior.single.label')}`,
        infoTooltip: (
          <Trans
            i18nKey="request.spendAndSavings.singleResponseLineItemSavingsInfo"
            ns="translation"
            components={{
              p: <P />,
              ol: <Ol />,
              li: <li />,
            }}
          />
        ),
        amount: 0,
        requestCount: 0,
        currencyCode: data.config.currency,
      },
      multiResponseLineItemSavings: {
        _id: 'multiResponseLineItemSavings',
        label: `${t('request.lineItems.lineItem_other')}: ${t('request.multiStageBehavior.perStage.label')}`,
        infoTooltip: (
          <Trans
            i18nKey="request.spendAndSavings.multiResponseLineItemSavingsInfo"
            ns="translation"
            components={{
              p: <P />,
              ol: <Ol />,
              li: <li />,
            }}
          />
        ),
        amount: 0,
        requestCount: 0,
        currencyCode: data.config.currency,
      },
      manualSavings: {
        _id: 'manualSavings',
        label: t('request.spendAndSavings.totalSavingsCalculationMethods.manual'),
        infoTooltip: (
          <Trans
            i18nKey="request.spendAndSavings.manualSavingsInfo"
            ns="translation"
            components={{
              p: <P />,
              ol: <Ol />,
              li: <li />,
            }}
          />
        ),
        amount: 0,
        requestCount: 0,
        currencyCode: data.config.currency,
      },
      auctionLineItemSavings: {
        _id: 'auctionLineItemSavings',
        label: t('general.auction'),
        infoTooltip: (
          <Trans
            i18nKey="request.spendAndSavings.auctionLineItemSavingsInfo"
            ns="translation"
            components={{
              p: <P />,
              ol: <Ol />,
              li: <li />,
            }}
          />
        ),
        amount: 0,
        requestCount: 0,
        currencyCode: data.config.currency,
      },
      budgetFinalValueSavings: {
        _id: 'budgetFinalValueSavings',
        label: t('request.spendAndSavings.totalSavingsCalculationMethods.budgetFinalValueDiff'),
        infoTooltip: (
          <Trans
            i18nKey="request.spendAndSavings.budgetFinalValueSavingsInfo"
            ns="translation"
            components={{
              p: <P />,
              ol: <Ol />,
              li: <li />,
            }}
          />
        ),
        amount: 0,
        requestCount: 0,
        currencyCode: data.config.currency,
      },
    });

    const rows = Object.values(rowData);
    const totalAmount = sumBy(rows, item => item.amount);

    return {
      rows,
      totalAmount,
    };
  }, [data, t]);

  const columns: EditableGridColumn[] = useMemo(() => {
    return [
      {
        _id: 'label',
        accessorKey: 'label',
        Header: SimpleHeader,
        infoTooltipKey: 'infoTooltip',
        label: t('general.type'),
        ValueCell: TextWithInfoTooltipCell,
        width: 250,
      },
      {
        _id: 'amount',
        accessorKey: 'amount',
        Header: SimpleHeader,
        label: t('general.savings'),
        description: `(${t('general.amount')})`,
        descriptionSx: { fontSize: 2, fontWeight: 500, color: 'inherit', mt: 0 },
        ValueCell: CurrencyAmountOrDashCell,
        width: '25%' as any,
      },
      {
        _id: 'amountPercent',
        accessorKey: 'amountPercent',
        accessorFn: ((row: { amount: number }) => row.amount / totalAmount) as any,
        Header: SimpleHeader,
        label: t('general.savings'),
        description: '(%)',
        descriptionSx: { fontSize: 2, fontWeight: 500, color: 'inherit', mt: 0 },
        ValueCell: PercentOrDashCell,
        width: '15%' as any,
      },
      {
        _id: 'requestCount',
        accessorKey: 'requestCount',
        Header: withProps(SimpleHeader, { truncate: false }),
        label: t('request.spendAndSavings.noOfRequests'),
        ValueCell: NumberOrDashCell,
        width: '15%' as any,
      },
    ];
  }, [t, totalAmount]);

  return (
    <DefaultEditableGridStyles
      isReadOnly
      cellContentCss="align-items: flex-start; padding: 10px"
      omitLastRowBorderBottom
    >
      <GridIdPrefixProvider>
        <ReadOnlyGrid
          columns={columns}
          rowData={rows as any}
          GridComponent={NonVirtualGrid}
          RenderDataCell={RenderTableDataCell}
        />
      </GridIdPrefixProvider>
    </DefaultEditableGridStyles>
  );
};
