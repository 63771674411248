import { memo, forwardRef } from 'react';
import {
  DEFAULT_FROZEN_FOOTER_HEIGHT,
} from './constants';
import { FrozenPanelsContext, FrozenPanelsContextValue } from './FrozenPanels';
import { InnerElementTypeProps, TExpandableRowDataBase, TOriginalColumnDataBase, TOriginalSubRowDataBase } from './utils';

export const GridInnerElementType = memo(forwardRef<
  HTMLDivElement,
  InnerElementTypeProps & { leftColumnItems: any }
>(({ style, children, leftColumnItems, ...rest }, ref) => (
  <FrozenPanelsContext.Consumer>
    {<
      TOriginalColumnData extends TOriginalColumnDataBase,
      TOriginalSubRowData extends TOriginalSubRowDataBase,
      TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
    >(frozenPanelsContextValue: FrozenPanelsContextValue<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
      const {
        FrozenHeader,
        FrozenFooter,
        idPrefix,
        bodyPaddingRight,
        bodyPaddingBottom,
        AdditionalHeaderContent,
        FooterContent,
        frozenLeftColumnWidth,
      } = frozenPanelsContextValue;

      const footerHeight = FrozenFooter ? DEFAULT_FROZEN_FOOTER_HEIGHT : 0;

      return (
        <div
          ref={ref}
          role="row"
          style={{
            ...style,
            height: `${parseFloat(style.height) + bodyPaddingBottom + footerHeight}px`,
            minHeight: '100%',
            width: `${parseFloat(style.width) + bodyPaddingRight}px`,
            minWidth: '100%',
          }}
          {...rest}
        >
          <FrozenHeader>
            {AdditionalHeaderContent && (
              <AdditionalHeaderContent
                height={parseFloat(style.height)}
                width={parseFloat(style.width)}
              />
          )}
          </FrozenHeader>
          <div className="frozen-left-column-wrapper" style={{ width: `${frozenLeftColumnWidth}px` }}>
            {leftColumnItems}
            {FooterContent && (
              <FooterContent
                height={parseFloat(style.height)}
                width={parseFloat(style.width)}
                idPrefix={idPrefix}
              />
            )}
          </div>
          {FrozenFooter ? (
            <FrozenFooter />
          ) : (
            null
          )}
          {children}
        </div>
      );
    }}
  </FrozenPanelsContext.Consumer>
)));
