import { useCallback, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { downloadUrl } from '@deepstream/ui/useDownload';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useMutation } from '@deepstream/ui/useMutation';
import { reportConfigByType } from './reportConfig';

type ReportId = string;

const REPORTS_QUERY_KEY = 'reports';

export const useInvalidateReports = () => {
  const queryClient = useQueryClient();

  return useCallback(
    () => queryClient.invalidateQueries(REPORTS_QUERY_KEY),
    [queryClient],
  );
};

/**
 * Fetches the last 100 generated reports
 */
export const useReportHistory = () => {
  const api = useAdminApi();

  return useQuery(
    REPORTS_QUERY_KEY,
    api.getReports,
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: true,
    },
  );
};

/**
 * Mutation to request the generation of a specific report type
 */
export const useRequestReport = () => {
  const toaster = useToaster();
  const api = useAdminApi();

  return useMutation(api.requestReport, {
    onSuccess: () => toaster.success('Report requested. You will receive an email when it is ready for download.'),
    onError: () => toaster.error('Report could not be requested'),
  });
};

/**
 * Mutation to download a report
 */
export const useDownloadReport = () => {
  const api = useAdminApi();
  const toaster = useToaster();

  return useMutation(
    async ({ reportId }: { reportId: ReportId }) => {
      const { url } = await api.getReportDownloadUrl({ reportId });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success('Report downloaded successfully'),
      onError: () => toaster.error('Report could not be downloaded'),
    },
  );
};

/**
 * Hook to poll for the status of a specific report. Stops polling when `status` is `'success'`.
 */
export const useReportPolling = ({ onSuccess, onError, onStatusChange }: { onSuccess: any; onError: any; onStatusChange: any }) => {
  const api = useAdminApi();
  const [reportId, setReportId] = useState(null);

  const { data: report } = useQuery(
    ['report', { reportId }] as any,
    wrap(api.getReport),
    {
      refetchInterval: 5 * 1000,
      enabled: Boolean(reportId),
    },
  );

  useWatchValue(
    report,
    useCallback(
      report => {
        if (report?.status === 'success') {
          setReportId(null);
          onSuccess(report);
        } else if (report?.status === 'error') {
          setReportId(null);
          onError(report);
        }

        onStatusChange(report);
      },
      [onSuccess, onError, onStatusChange],
    ),
  );

  return useMemo(
    () => ({
      startPolling: setReportId,
      isPollingReport: Boolean(reportId),
    }),
    [reportId],
  );
};

/**
 * The process of report generation includes requesting the report and polling
 * the report till it finishes generating (either by success or failure).
 */
export const useGenerateReport = ({ reportName }: { reportName?: string } = {}) => {
  const toaster = useToaster();
  const [requestReport, { isLoading: isRequestingReport }] = useRequestReport();
  const invalidateReports = useInvalidateReports();
  const { startPolling, isPollingReport } = useReportPolling({
    onSuccess: (report) => toaster.success(`'${reportName || reportConfigByType[report.type].name}' report has just been generated successfully`),
    onError: (report) => toaster.error(`'${reportName || reportConfigByType[report.type].name}' report has failed to generate`),
    onStatusChange: invalidateReports,
  });

  return useMemo(
    () => ({
      isGenerating: isRequestingReport || isPollingReport,
      generateReport: (params: Parameters<typeof requestReport>[0]) =>
        requestReport(params, {
          onSuccess: startPolling,
          onSettled: invalidateReports,
        }),
    }),
    [invalidateReports, isPollingReport, isRequestingReport, requestReport, startPolling],
  );
};
