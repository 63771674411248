import { useCallback } from 'react';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { values, includes, isEmpty } from 'lodash';
import { defaultRoles, TeamMembershipsByEntity, isOnlyEntityOwner } from '@deepstream/common/user-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../../ui/Tabs';
import { useApi, useAdminApi } from '../../../api';
import { User } from '../../../types';
import { useToaster } from '../../../toast';
import { UserDetails } from '../../../UserDetails';
import { isAppAdminCompany, useIsAppAdmin } from '../../../useIsAppAdmin';
import { useIsOnlyAdmin } from './useIsOnlyAdmin';
import { useMutation } from '../../../useMutation';
import { useModalState } from '../../../ui/useModalState';
import { ConfirmRemoveUserModal } from './ConfirmRemoveUserModal';
import { OnlyAdminInfoModal } from './OnlyAdminInfoModal';
import { RemoveSoleOwnerModal } from './RemoveSoleOwnerModal';
import { useCurrentUser } from '../../../useCurrentUser';
import { LabeledValue } from '../../../draft/LabeledValue';
import { EditPermissionList, EditRequestsPermissionList, EditContractsPermissionList, EditReportingPermissionList, EditPreQualPermissionList } from './EditPermissionList';
import {
  FULL_USER_EDITABLE_PERMISSIONS,
  SUPER_USER_EDITABLE_PERMISSIONS,
  STAKEHOLDER_EDITABLE_PERMISSIONS,
  roleRestrictionProfileToGlobalRole,
  globalRoleToRoleRestrictionProfile,
} from './utils';
import { GlobalRole } from './types';
import { ChangeGlobalRoleModal } from './ChangeGlobalRoleModal';
import { UserRequestsList } from './UserRequestsList';
import { UserContractsList } from './UserContractsList';
import { useWaitForProcessingTask } from '../../../useWaitForProcessingTask';
import { UserQuestionnairesList } from './UserQuestionnairesList';

type EditPermissionsModalProps = ModalProps &
  {
    user: any;
    users: any;
    companyId: string;
    isPayingCompany: boolean;
    teamMembershipsByEntity: TeamMembershipsByEntity;
    onCancel: any;
    onSave: any;
  };

export const EditPermissionsModal = ({
  user,
  users,
  companyId,
  isPayingCompany,
  teamMembershipsByEntity,
  onCancel,
  onSave,
  ...props
}: EditPermissionsModalProps) => {
  const { t } = useTranslation(['translation', 'general']);
  const api = useApi();
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const isAppAdmin = useIsAppAdmin();
  const isOnlyAdmin = useIsOnlyAdmin({ userId: user._id, companyId });
  const deleteConfirmationModal = useModalState();
  const onlyAdminInfoModal = useModalState();
  const soleOwnerModal = useModalState();
  const changeGlobalRoleModal = useModalState();
  const infoModal = useModalState();
  const [infoModalText, setInfoModalText] = React.useState({
    heading: t('teamManagement.dialog.cannotRemoveUser.heading'),
    body: t('teamManagement.dialog.cannotRemoveUser.body'),
  });
  const currentUser = useCurrentUser();
  const waitForProcessingTask = useWaitForProcessingTask();

  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const {
    contractMemberships,
    requestMemberships,
    questionnaireMemberships,
    soleOwnerSentQuestionnaireMemberships,
    soleOwnerSentRequestMemberships,
    soleOwnerSentContractMemberships,
  } = React.useMemo(() => {
    const {
      request,
      contract,
      questionnaire,
    } = teamMembershipsByEntity;

    return {
      contractMemberships: request.memberships,
      requestMemberships: contract.memberships,
      questionnaireMemberships: questionnaire.memberships,

      // Downgrading a user to an authorized stakeholder affects only sent entities
      soleOwnerSentQuestionnaireMemberships: questionnaire.memberships.filter(membership => (
        isOnlyEntityOwner(membership) && membership.isSender
      )),
      soleOwnerSentRequestMemberships: request.memberships.filter(membership => (
        isOnlyEntityOwner(membership) && membership.isSender
      )),
      soleOwnerSentContractMemberships: contract.memberships.filter(membership => (
        isOnlyEntityOwner(membership) && membership.isSender
      )),
    };
  }, [teamMembershipsByEntity]);

  const [updateCompanyRoles] = useMutation(
    (payload) => waitForProcessingTask({
      command: () => isAppAdmin
        ? adminApi.updateCompanyRoles(payload)
        : api.updateCompanyRoles(payload),
    }),
    {
      onSuccess: callAll(
        (data, values) => values.roleRestrictionProfile === user.roleRestrictionProfiles?.[companyId]
          ? toaster.success(t('teamManagement.toaster.updatePermissionsSuccess'))
          : toaster.success(t('teamManagement.toaster.updateRoleSuccess')),
        onSave,
      ),
      onError: callAll(
        (data, values) => values.roleRestrictionProfile === user.roleRestrictionProfiles?.[companyId]
          ? toaster.error(t('teamManagement.toaster.updatePermissionsError'))
          : toaster.error(t('teamManagement.toaster.updateRoleError')),
        onCancel,
      ),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['user', { userId: user._id }]),
        () => queryClient.invalidateQueries(['users', { companyId }]),
      ),
    },
  );

  const initialGlobalRole = roleRestrictionProfileToGlobalRole(user.roleRestrictionProfiles?.[companyId]);

  const handlePermissionsSubmit = useCallback((values) => {
    const userId = user._id;
    const roleRestrictionProfile = globalRoleToRoleRestrictionProfile(values.globalRole);
    const questionnaireReplacementUserId = values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER
      ? values.questionnaireReplacementUserId
      : undefined;
    const rfxReplacementUserId = values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER
      ? values.rfxReplacementUserId
      : undefined;
    const contractReplacementUserId = values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER
      ? values.contractReplacementUserId
      : undefined;

    const roles = {
      admin: values.admin,
      editor: values.editor,
      managePreQual: values.managePreQual,
      sendQuestionnaires: values.sendQuestionnaires,
      receiveQuestionnaires: values.receiveQuestionnaires,
      sendRFQ: values.sendRFQ,
      receiveRFQ: values.receiveRFQ,
      sendContracts: values.sendContracts,
      receiveContracts: values.receiveContracts,
      accessReportingForRequests: values.accessReportingForRequests,
      accessReportingForContracts: values.accessReportingForContracts,
    };

    return updateCompanyRoles({
      userId,
      companyId,
      roleRestrictionProfile,
      roles,
      questionnaireReplacementUserId,
      rfxReplacementUserId,
      contractReplacementUserId,
    });
  }, [updateCompanyRoles, user, companyId]);

  const handleRemoveUserClick = React.useCallback(
    async (user: User) => {
      if (!users) {
        return;
      }

      setSelectedUser(user);

      const {
        request,
        contract,
        questionnaire,
      } = teamMembershipsByEntity;

      if (user._id === currentUser._id) {
        setInfoModalText({
          heading: t('teamManagement.dialog.cannotRemoveUser.heading'),
          body: t('teamManagement.dialog.cannotRemoveUser.body'),
        });
        infoModal.open();
      } else if (isOnlyAdmin) {
        onlyAdminInfoModal.open();
      } else if (request.isOnlyOwner || contract.isOnlyOwner || questionnaire.isOnlyOwner) {
        soleOwnerModal.open();
      } else {
        deleteConfirmationModal.open();
      }
    },
    [
      t,
      users,
      onlyAdminInfoModal,
      isOnlyAdmin,
      soleOwnerModal,
      teamMembershipsByEntity,
      deleteConfirmationModal,
      infoModal,
      currentUser,
    ],
  );

  const handleChangeGlobalRoleClick = React.useCallback(() => {
    setSelectedUser(user);

    changeGlobalRoleModal.open();
  }, [changeGlobalRoleModal, user]);

  const [removeUserFromCompany] = useMutation(
    (payload) => waitForProcessingTask({
      command: () => api.removeUserFromCompany(payload),
    }),
    {
      onSuccess: callAll(
        onSave,
        deleteConfirmationModal.close,
        soleOwnerModal.close,
        () => toaster.success(t('teamManagement.toaster.removeUserSuccess')),
      ),
      onError: () => toaster.error(t('teamManagement.toaster.removeUserError')),
      onSettled: () => queryClient.invalidateQueries(['users', { companyId }]),
    },
  );

  const removeUser = React.useCallback(
    async ({
      user,
      questionnaireReplacementUserId,
      rfxReplacementUserId,
      contractReplacementUserId,
    }: {
      user: User;
      questionnaireReplacementUserId?: string;
      rfxReplacementUserId?: string;
      contractReplacementUserId?: string;
    }) => {
      await removeUserFromCompany({
        userId: user._id,
        companyId,
        questionnaireReplacementUserId,
        rfxReplacementUserId,
        contractReplacementUserId,
      });
    },
    [companyId, removeUserFromCompany],
  );

  const changeGlobalRole = React.useCallback(
    async (values) => {
      await handlePermissionsSubmit(values);

      onSave();
    },
    [handlePermissionsSubmit, onSave],
  );

  const isPermissionDisabled = React.useCallback((itemId, globalRole) => {
    if (isOnlyAdmin && itemId === 'admin') {
      return true;
    }

    if (globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER) {
      return !includes(STAKEHOLDER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.FULL_USER) {
      return !includes(FULL_USER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.SUPER_USER) {
      return !includes(SUPER_USER_EDITABLE_PERMISSIONS, itemId);
    }
  }, [isOnlyAdmin]);

  return (
    <Modal style={{ content: { width: '600px' } }} {...props}>
      {user && (
        <Formik
          initialValues={{
            globalRole: initialGlobalRole,
            ...defaultRoles[initialGlobalRole],
            ...user.roles[companyId],
            rfxReplacementUserId: undefined,
            contractReplacementUserId: undefined,
          }}
          validationSchema={
            yup.object().shape({
              globalRole: isAppAdminCompany(companyId)
                ? yup.mixed()
                : yup.string().oneOf(values(GlobalRole)).required(),
              admin: yup.boolean(),
              editor: yup.boolean(),
              managePreQual: yup.boolean(),
              sendQuestionnaires: yup.boolean(),
              receiveQuestionnaires: yup.boolean(),
              sendRFQ: yup.boolean(),
              receiveRFQ: yup.boolean(),
              receiveContracts: yup.boolean(),
              sendContracts: yup.boolean(),
              accessReportingForRequests: yup.boolean(),
              accessReportingForContracts: yup.boolean(),
              questionnaireReplacementUserId: yup.string()
                .when('globalRole', {
                  is: globalRole => (
                    globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    initialGlobalRole !== GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    !isEmpty(soleOwnerSentQuestionnaireMemberships)
                  ),
                  then: schema => schema.required(t('general.required')),
                  otherwise: schema => schema.nullable(),
                }),
              rfxReplacementUserId: yup.string()
                .when('globalRole', {
                  is: globalRole => (
                    globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    initialGlobalRole !== GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    !isEmpty(soleOwnerSentRequestMemberships)
                  ),
                  then: schema => schema.required(t('general.required')),
                  otherwise: schema => schema.nullable(),
                }),
              contractReplacementUserId: yup.string()
                .when('globalRole', {
                  is: globalRole => (
                    globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    initialGlobalRole !== GlobalRole.AUTHORIZED_STAKEHOLDER &&
                    !isEmpty(soleOwnerSentContractMemberships)
                  ),
                  then: schema => schema.required(t('general.required')),
                  otherwise: schema => schema.nullable(),
                }),
            })
          }
          onSubmit={handlePermissionsSubmit}
        >
          {({ isSubmitting, dirty, values, isValid }) => (
            <Form>
              <ModalHeader onClose={onCancel}>
                {t('teamManagement.dialog.editPermissions.heading')}
              </ModalHeader>
              <Tabs>
                <PanelPadding p={3}>
                  <UserDetails user={user} />
                </PanelPadding>
                <PanelPadding pr={3} pl={3} pb={2} pt={1}>
                  <Flex justifyContent="space-between" alignItems="flex-end" mb={3}>
                    <Box>
                      <LabeledValue
                        align="left"
                        label={t('general.userRole')}
                        value={values.globalRole ? (
                          t(`user.globalRole.${values.globalRole}`)
                        ) : (
                          <EmDash />
                        )}
                        valueProps={{}}
                      />
                    </Box>
                    <Box>
                      <Button
                        type="button"
                        iconLeft="refresh"
                        variant="secondary-outline"
                        small
                        disabled={isOnlyAdmin}
                        onClick={handleChangeGlobalRoleClick}
                      >
                        {t('teamManagement.changeRole')}
                      </Button>
                    </Box>
                  </Flex>
                </PanelPadding>
                <TabList style={{ backgroundColor: 'white' }}>
                  <Tab>
                    {t('general', { ns: 'general' })}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-check"
                      regular
                    />
                    {t('preQualification', { ns: 'general' })}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-text-o"
                    />
                    {t('general.request_other')}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-contract"
                      regular
                    />
                    {t('general.contract_other')}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="chart-bar"
                      regular
                    />
                    {t('general.reporting')}
                  </Tab>
                </TabList>
                <ModalBody>
                  <TabPanels>
                    <TabPanel>
                      <EditPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                    </TabPanel>
                    <TabPanel>
                      <EditPreQualPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                      <UserQuestionnairesList items={questionnaireMemberships} />
                    </TabPanel>
                    <TabPanel>
                      <EditRequestsPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                      <UserRequestsList items={requestMemberships} />
                    </TabPanel>
                    <TabPanel>
                      <EditContractsPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                      <UserContractsList items={contractMemberships} />
                    </TabPanel>
                    <TabPanel>
                      <EditReportingPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                    </TabPanel>
                  </TabPanels>
                </ModalBody>
              </Tabs>
              <ModalFooter justifyContent="space-between">
                <Button
                  variant="danger-outline"
                  onClick={() => handleRemoveUserClick(user)}
                  type="button"
                  iconLeft="close"
                >
                  {t('teamManagement.removeUser')}
                </Button>
                <Box>
                  <CancelButton onClick={onCancel} />
                  <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                    {t('saveChanges', { ns: 'general' })}
                  </Button>
                </Box>
              </ModalFooter>
              {selectedUser && teamMembershipsByEntity && (
                <RemoveSoleOwnerModal
                  isOpen={soleOwnerModal.isOpen}
                  teamMembershipsByEntity={teamMembershipsByEntity}
                  user={selectedUser}
                  companyId={companyId}
                  removeUser={removeUser}
                  close={soleOwnerModal.close}
                />
              )}
              <OnlyAdminInfoModal
                modal={onlyAdminInfoModal}
                user={selectedUser}
              />
              <ConfirmRemoveUserModal
                modal={deleteConfirmationModal}
                user={selectedUser}
                currentUserId={currentUser._id}
                removeUser={removeUser}
              />
              <Dialog
                heading={infoModalText.heading}
                body={(
                  <>
                    <UserDetails user={selectedUser} />
                    <MessageBlock variant="error" mt={3}>
                      {infoModalText.body}
                    </MessageBlock>
                  </>
                )}
                okButtonText={t('general.ok')}
                okButtonVariant="primary"
                isOpen={infoModal.isOpen}
                onOk={infoModal.close}
              />
              {selectedUser && (
                <ChangeGlobalRoleModal
                  isOpen={changeGlobalRoleModal.isOpen}
                  companyId={companyId}
                  isPayingCompany={isPayingCompany}
                  teamMembershipsByEntity={teamMembershipsByEntity}
                  soleOwnerSentQuestionnaireMemberships={soleOwnerSentQuestionnaireMemberships}
                  soleOwnerSentRequestMemberships={soleOwnerSentRequestMemberships}
                  soleOwnerSentContractMemberships={soleOwnerSentContractMemberships}
                  user={selectedUser}
                  changeGlobalRole={changeGlobalRole}
                  close={changeGlobalRoleModal.close}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
