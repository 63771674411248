import * as React from 'react';
import { CellProps } from 'react-table';
import { Box, Flex } from 'rebass/styled-components';
import { Avatar } from './Avatar';

export const UserAvatar: React.FC<CellProps<any>> = ({ row }) => (
  <Flex alignItems="center" style={{ overflow: 'hidden' }} flex="0 0 38px">
    <Box flex="0 0 auto" width="30px">
      <Avatar userId={row.original._id} />
    </Box>
  </Flex>
);
