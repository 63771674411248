import * as React from 'react';
import { FetchQueryOptions, QueryClient, QueryKey } from 'react-query';
import {
  Outlet,
  Navigate,
  useNavigate,
  createRoute,
  createRouter,
  createRootRouteWithContext,
  useScrollRestoration,
} from '@tanstack/react-router';
import { noop } from 'lodash';

import { AdminApiClient } from '@deepstream/ui/adminApiClient';
import { omitNil } from '@deepstream/utils';
import { Operator } from '@deepstream/ui/types';
import { wrap } from '@deepstream/ui/api';
import { getArrayQueryParam } from '@deepstream/ui-utils/queryParams';
import { QuestionnaireIdProvider } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';

import { LatestVersionChecker } from '@deepstream/ui/hooks/useIsLatestVersion';
import { GlobalProcessingModal } from '@deepstream/ui/GlobalProcessingProvider';
import { NetworkStatusChecker } from '@deepstream/ui/NetworkStatusChecker';
import { DeviceContextProvider } from '@deepstream/ui/ui/useDeviceSize';
import { NodeEnv } from '@deepstream/environment';
import { ContractIdProvider, ContractStateProvider } from '@deepstream/ui/modules/Contracts/contract';
import { AdminDashboardPage } from './AdminDashboardPage';
import { AdminRecentActivityPage } from './AdminRecentActivityPage';
import { AdminUserPage } from './AdminUserPage';
import { CompanyPage } from './modules/Company/CompanyPage';
import { CompanyUserPage } from './CompanyUserPage';
import { AdminRequestPage } from './AdminRequestPage';
import { RequestCompanyPage } from './RequestCompanyPage';
import { RequestCompanyUserPage } from './RequestCompanyUserPage';
import { AuctionPage } from './AuctionPage';
import { AdminTemplatesPage } from './AdminTemplatesPage';
import { AdminContractPage } from './modules/Contracts/AdminContractPage';
import { SearchPage } from './SearchPage';
import { ReportsPage } from './reports/ReportsPage';
import { AdminChatbotChatPage } from './reports/AdminChatbotChatPage';
import { DiscoveryTransactionPage } from './reports/DiscoveryTransactionPage';
import { CompanyExternalSystemPage } from './CompanyExternalSystemPage';
import { SubscriptionPage } from './SubscriptionPage';
import { TeamUsersPage } from './TeamUsersPage';
import { ConfigPage } from './modules/Config/ConfigPage';
import { ToolsPage } from './ToolsPage';
import { CompanyDetails } from './modules/CompanyDetails/CompanyDetails';
import { CompanyReporting } from './modules/CompanyReporting/CompanyReporting';
import { AdminQuestionnaireTemplatePage } from './modules/PreQualification/AdminQuestionnaireTemplatePage';
import { AdminTasksProvider } from './AdminTasksProvider';
import { AdminCurrentCompanyIdProvider } from './CurrentCompanyIdProvider';
import { useAppInitRedirects } from './useAppInitRedirects';
import { AdminQuestionnairePage } from './modules/PreQualification/AdminQuestionnairePage';
import { AdminQuestionnaireCompanyPage } from './modules/PreQualification/AdminQuestionnaireCompanyPage';
import { AdminQuestionnaireCompanyUserPage } from './modules/PreQualification/AdminQuestionnaireCompanyUserPage';
import { AdminContractCompanyPage } from './modules/Contracts/AdminContractCompanyPage';
import { AdminContractCompanyUserPage } from './modules/Contracts/AdminContractCompanyUserPage';

const TanStackRouterDevtools =
  process.env.NODE_ENV === NodeEnv.PRODUCTION
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        })),
      );

type RouterContext = {
  queryClient: QueryClient;
  api: AdminApiClient;
};

const createRootRoute = createRootRouteWithContext<RouterContext>();

const rootRoute = createRootRoute({
  component: function Root() {
    useScrollRestoration();

    useAppInitRedirects();

    return (
      <DeviceContextProvider>
        <AdminCurrentCompanyIdProvider>
          <AdminTasksProvider>
            <AdminNavigationProvider>
              <Outlet />
              <GlobalProcessingModal />
              <NetworkStatusChecker />
              <LatestVersionChecker appName="admin" />
              <TanStackRouterDevtools />
            </AdminNavigationProvider>
          </AdminTasksProvider>
        </AdminCurrentCompanyIdProvider>
      </DeviceContextProvider>
    );
  },

  errorComponent: () => {
    return <h1>Error</h1>;
  },

  notFoundComponent: () => {
    return (
      <h1>Not Found</h1>
    );
  },
});

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <Navigate from={indexRoute.to} to={dashboardRoute.to} />,
});

export const dashboardRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'dashboard',
  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab as string | undefined,
  }),
  component: function DashboardRoute() {
    const search = dashboardRoute.useSearch();

    return <AdminDashboardPage tab={search.tab} />;
  },
});

export const recentActivityRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'recent-activity',
  component: AdminRecentActivityPage,
});

export const userRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'user/$userId',
  component: AdminUserPage,
});

const ensureQueryData = async <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { queryKey: TQueryKey },
): Promise<TData> => {
  const state = queryClient.getQueryState<TData>(options.queryKey);

  if (state && state.data && !state.isInvalidated) {
    return state.data;
  }

  const data = await queryClient.fetchQuery(options);

  return data;
};

const companyLoader = async ({
  params: { companyId },
  context: { api, queryClient },
}: {
  params: { companyId: string }
  context: RouterContext,
}) => {
  const company = await ensureQueryData(queryClient, {
    queryKey: ['company', { companyId }],
    queryFn: wrap(api.getCompany),
  });

  return {
    company,
  };
};

export const companyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'company/$companyId',

  component: function CompanyRoute() {
    return (
      <Outlet />
    );
  },
});

export const companyIndexRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/',
  component: () => (
    <Navigate
      from={companyIndexRoute.to}
      to={companyDetailsRoute.to}
      replace={true}
    />
  ),
});

export const companyDetailsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'details',

  loader: options => companyLoader(options),

  component: () => {
    const { company } = companyDetailsRoute.useLoaderData();

    return (
      <CompanyPage company={company} selectedTabId="details">
        {company && <CompanyDetails company={company} />}
      </CompanyPage>
    );
  },
});

export const companyReportingRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'reporting',
  loader: options => companyLoader(options),
  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab ? String(search.tab) : undefined,
  }),

  component: function CompanyReportingRoute() {
    const { company } = companyReportingRoute.useLoaderData();
    const search = companyReportingRoute.useSearch();
    const navigation = useAdminNavigation();

    return (
      <CompanyPage company={company} selectedTabId="reporting">
        {company && (
          <CompanyReporting
            selectedTabId={search.tab}
            company={company}
            navigateToContract={contract => navigation.navigateToContract(contract._id)}
          />
        )}
      </CompanyPage>
    );
  },
});

export const companyUserRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'user/$userId',
  component: CompanyUserPage,
});

export const companyExternalSystemRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'external-system/$externalSystemId',
  component: CompanyExternalSystemPage,
});

export const requestRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'request/$requestId',
  component: AdminRequestPage,
});

export const requestCompanyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'request/$requestId/company/$companyId',
  component: RequestCompanyPage,
});

export const requestCompanyUserRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'request/$requestId/company/$companyId/user/$userId',
  component: RequestCompanyUserPage,
});

export const requestAuctionRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'request/$requestId/auction/$auctionId',
  component: AuctionPage,
});

export const templateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'template/$templateId',
  component: AdminTemplatesPage,
});

export const questionnaireTemplateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'questionnaireTemplate/$templateId',
  component: AdminQuestionnaireTemplatePage,
});

export const questionnaireRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'questionnaire/$questionnaireId',
  component: function QuestionnaireRoute() {
    const { questionnaireId } = questionnaireRoute.useParams();

    return (
      <QuestionnaireIdProvider questionnaireId={questionnaireId}>
        <Outlet />
      </QuestionnaireIdProvider>
    );
  },
});

export const questionnaireIndexRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: '/',
  component: AdminQuestionnairePage,
});

export const questionnaireCompanyRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'company/$companyId',
  component: AdminQuestionnaireCompanyPage,
});

export const questionnaireCompanyUserRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'company/$companyId/user/$userId',
  component: AdminQuestionnaireCompanyUserPage,
});

export const contractRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'contract/$contractId',
  component: function ContractRoute() {
    const { contractId } = contractRoute.useParams();

    return (
      <ContractIdProvider contractId={contractId}>
        <ContractStateProvider>
          <Outlet />
        </ContractStateProvider>
      </ContractIdProvider>
    );
  },
});

export const contractIndexRoute = createRoute({
  getParentRoute: () => contractRoute,
  path: '/',
  component: AdminContractPage,
});

export const contractCompanyRoute = createRoute({
  getParentRoute: () => contractRoute,
  path: 'company/$companyId',
  component: AdminContractCompanyPage,
});

export const contractCompanyUserRoute = createRoute({
  getParentRoute: () => contractRoute,
  path: 'company/$companyId/user/$userId',
  component: function X() {
    return <AdminContractCompanyUserPage />;
  },
});

export type CompanySearchParams = {
  text: string;
  pageIndex: number;
  pageSize: number;
  supplierListIds: string[];
  deliveryCountries: string[];
  products: string[];
  productsOperator: Operator;
  // deprecated product tags
  productTags: string[];
};

export const searchRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'search',
  validateSearch: (search: Record<string, unknown>): Partial<CompanySearchParams> => ({
    text: search.text as string ?? '',
    pageIndex: Number(search.pageIndex as number ?? 0),
    pageSize: Number(search.pageSize as number ?? 20),
    supplierListIds: getArrayQueryParam<string>(search.supplierListIds),
    deliveryCountries: getArrayQueryParam<string>(search.deliveryCountries),
    products: getArrayQueryParam<string>(search.products),
    productsOperator: (search.productsOperator ?? Operator.AND) as Operator,
    // deprecated product tags
    productTags: getArrayQueryParam<string>(search.productTags),
  }),
  component: SearchPage,
});

export const reportsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'reports',
  validateSearch: (search: Record<string, unknown>): { tab?: string; download?: string } => ({
    tab: search.tab as string | undefined,
    download: search.download as string | undefined,
  }),
  component: ReportsPage,
});

export const reportsChatbotChatRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'reports/chatbot-chat/$chatId',
  component: AdminChatbotChatPage,
});

export const reportsDiscoveryTransactionRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'reports/discovery-logs/transaction/$transactionId',
  component: DiscoveryTransactionPage,
});

export const subscriptionRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'subscription/$subscriptionUuid',
  component: SubscriptionPage,
});

export const adminTeamRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'team',
  component: TeamUsersPage,
});

export const configRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'config',
  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab as string | undefined,
  }),
  component: ConfigPage,
});

export const toolsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'tools',
  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab as string | undefined,
  }),
  component: ToolsPage,
});

export const routeTree = rootRoute.addChildren([
  indexRoute,
  dashboardRoute,
  recentActivityRoute,
  userRoute,

  companyRoute.addChildren([
    companyIndexRoute,
    companyDetailsRoute,
    companyReportingRoute,
    companyUserRoute,
    companyExternalSystemRoute,
  ]),

  requestRoute,
  requestCompanyRoute,
  requestCompanyUserRoute,
  requestAuctionRoute,
  templateRoute,

  questionnaireTemplateRoute,
  questionnaireRoute.addChildren([
    questionnaireIndexRoute,
    questionnaireCompanyRoute,
    questionnaireCompanyUserRoute,
  ]),

  contractRoute.addChildren([
    contractIndexRoute,
    contractCompanyRoute,
    contractCompanyUserRoute,
  ]),

  searchRoute,

  reportsRoute,
  reportsChatbotChatRoute,
  reportsDiscoveryTransactionRoute,

  subscriptionRoute,
  adminTeamRoute,
  configRoute,
  toolsRoute,
]);

type ResourceType = 'user' | 'request' | 'template' | 'company';

const AdminNavigationContext = React.createContext<{
  navigateToDashboard:(tab?: string, replace?: boolean) => void;
  navigateToRecentActivity: () => void;
  navigateToUser: (userId: string) => void;
  navigateToCompany: (companyId: string, replace?: boolean) => void;
  navigateToCompanyUser: (companyId: string, userId: string) => void;
  navigateToCompanyExternalSystem: (companyId: string, externalSystemId: string) => void;
  navigateToRequest: (requestId: string) => void;
  navigateToRequestCompany: (requestId: string, companyId: string) => void;
  navigateToRequestCompanyUser: (requestId: string, companyId: string, userId: string) => void;
  navigateToRequestAuction: (requestId: string, auctionId: string) => void;
  navigateToTemplate: (templateId: string) => void;
  navigateToQuestionnaireTemplate: (templateId: string) => void;
  navigateToQuestionnaire: (questionnaireId: string) => void;
  navigateToQuestionnaireCompany: (questionnaireId: string, companyId: string, replace?: boolean) => void;
  navigateToQuestionnaireCompanyUser: (questionnaireId: string, companyId: string, userId: string) => void;
  navigateToContract: (contractId: string) => void;
  navigateToContractCompany: (contractId: string, companyId: string, replace?: boolean) => void;
  navigateToContractCompanyUser: (contractId: string, companyId: string, userId: string) => void;
  navigateToSearch: (search: any) => void;
  navigateToReports: (search?: { tab?: string; download?: string }, replace?: boolean) => void;
  navigateToReportsChatbotChat: (chatId: string) => void;
  navigateToReportsDiscoveryTransaction: (transactionId: string) => void;
  navigateToSubscription: (subscriptionUuid: string) => void;
  navigateToAdminTeam: () => void;
  navigateToConfig: (tab?: string, replace?: boolean) => void;
  navigateToTools: (tab?: string, replace?: boolean) => void;
  navigateToResource: (resourceType: ResourceType, resourceId: string) => void;
}>({
  navigateToDashboard: noop,
  navigateToRecentActivity: noop,
  navigateToUser: noop,
  navigateToCompany: noop,
  navigateToCompanyUser: noop,
  navigateToCompanyExternalSystem: noop,
  navigateToRequest: noop,
  navigateToRequestCompany: noop,
  navigateToRequestCompanyUser: noop,
  navigateToRequestAuction: noop,
  navigateToTemplate: noop,
  navigateToQuestionnaireTemplate: noop,
  navigateToQuestionnaire: noop,
  navigateToQuestionnaireCompany: noop,
  navigateToQuestionnaireCompanyUser: noop,
  navigateToContract: noop,
  navigateToContractCompany: noop,
  navigateToContractCompanyUser: noop,
  navigateToSearch: noop,
  navigateToReports: noop,
  navigateToReportsChatbotChat: noop,
  navigateToReportsDiscoveryTransaction: noop,
  navigateToSubscription: noop,
  navigateToAdminTeam: noop,
  navigateToConfig: noop,
  navigateToTools: noop,
  navigateToResource: noop,
});

export const AdminNavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const navigateToRequest = React.useCallback(
    (requestId: string) => navigate({ to: requestRoute.to, params: { requestId } }),
    [navigate],
  );

  const navigateToCompany = React.useCallback(
    (companyId: string, replace?: boolean) => navigate({ to: companyRoute.to, params: { companyId }, replace }),
    [navigate],
  );

  const navigateToUser = React.useCallback(
    (userId: string) => navigate({ to: userRoute.to, params: { userId } }),
    [navigate],
  );

  const navigateToTemplate = React.useCallback(
    (templateId: string) => navigate({ to: templateRoute.to, params: { templateId } }),
    [navigate],
  );

  const value = React.useMemo(
    () => ({
      navigateToDashboard: (tab: string | undefined, replace = false) =>
        navigate({
          to: dashboardRoute.to,
          search: tab ? { tab } : undefined,
          replace,
        }),
      navigateToRecentActivity: () =>
        navigate({
          to: recentActivityRoute.to,
        }),
      navigateToUser,
      navigateToCompany,
      navigateToCompanyUser: (companyId: string, userId: string) =>
        navigate({
          to: companyUserRoute.to,
          params: { companyId, userId },
        }),
      navigateToCompanyExternalSystem: (companyId: string, externalSystemId: string) =>
        navigate({
          to: companyExternalSystemRoute.to,
          params: { companyId, externalSystemId },
        }),
      navigateToRequest,
      navigateToRequestCompany: (requestId: string, companyId: string) =>
        navigate({
          to: requestCompanyRoute.to,
          params: { requestId, companyId },
        }),
      navigateToRequestCompanyUser: (requestId: string, companyId: string, userId: string) =>
        navigate({
          to: requestCompanyUserRoute.to,
          params: { requestId, companyId, userId },
        }),
      navigateToRequestAuction: (requestId: string, auctionId: string) =>
        navigate({
          to: requestAuctionRoute.to,
          params: { requestId, auctionId },
        }),
      navigateToTemplate,
      navigateToQuestionnaireTemplate: (templateId: string) =>
        navigate({
          to: questionnaireTemplateRoute.to,
          params: { templateId },
        }),
      navigateToQuestionnaire: (questionnaireId: string) =>
        navigate({
          to: questionnaireRoute.to,
          params: { questionnaireId },
        }),
      navigateToQuestionnaireCompany: (questionnaireId: string, companyId: string, replace?: boolean) =>
        navigate({
          to: questionnaireCompanyRoute.to,
          params: { questionnaireId, companyId },
          replace,
        }),
      navigateToQuestionnaireCompanyUser: (questionnaireId: string, companyId: string, userId: string) =>
        navigate({
          to: questionnaireCompanyUserRoute.to,
          params: { questionnaireId, companyId, userId },
        }),
      navigateToContract: (contractId: string) =>
        navigate({
          to: contractRoute.to,
          params: { contractId },
        }),
      navigateToContractCompany: (contractId: string, companyId: string, replace?: boolean) =>
        navigate({
          to: contractCompanyRoute.to,
          params: { contractId, companyId },
          replace,
        }),
      navigateToContractCompanyUser: (contractId: string, companyId: string, userId: string) =>
        navigate({
          to: contractCompanyUserRoute.to,
          params: { contractId, companyId, userId },
        }),
      navigateToSearch: (search: any = {}) =>
        navigate({
          to: searchRoute.to,
          search,
        }),
      navigateToReports: ({ tab, download }: { tab?: string, download?: string } = {}, replace?: boolean) =>
        navigate({
          to: reportsRoute.to,
          search: tab || download ? omitNil({ tab, download }) : undefined,
          replace,
        }),
      navigateToReportsChatbotChat: (chatId: string) =>
        navigate({
          to: reportsChatbotChatRoute.to,
          params: { chatId },
        }),
      navigateToReportsDiscoveryTransaction: (transactionId: string) =>
        navigate({
          to: reportsDiscoveryTransactionRoute.to,
          params: { transactionId },
        }),
      navigateToSubscription: (subscriptionUuid: string) =>
        navigate({
          to: subscriptionRoute.to,
          params: { subscriptionUuid },
        }),
      navigateToAdminTeam: () =>
        navigate({
          to: adminTeamRoute.to,
        }),
      navigateToConfig: (tab?: string, replace?: boolean) =>
        navigate({
          to: configRoute.to,
          search: tab ? { tab } : undefined,
          replace,
        }),
      navigateToTools: (tab?: string, replace?: boolean) =>
        navigate({
          to: toolsRoute.to,
          search: tab ? { tab } : undefined,
          replace,
        }),
      navigateToResource: (resourceType: ResourceType, resourceId: string) => {
        switch (resourceType) {
          case 'request': return navigateToRequest(resourceId);
          case 'company': return navigateToCompany(resourceId);
          case 'template': return navigateToTemplate(resourceId);
          case 'user': return navigateToUser(resourceId);
        }
      },
    }),
    [navigate, navigateToCompany, navigateToRequest, navigateToTemplate, navigateToUser],
  );

  return (
    <AdminNavigationContext.Provider value={value}>
      {children}
    </AdminNavigationContext.Provider>
  );
};

export const useAdminNavigation = () => React.useContext(AdminNavigationContext);

export const router = createRouter({
  routeTree,
  context: {
    queryClient: {} as QueryClient,
    api: {} as AdminApiClient,
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
