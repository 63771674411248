import { TFunction } from 'i18next';
import { difference, findLast, get, isEmpty, isNil, isUndefined, propertyOf } from 'lodash';

import { areFieldValuesEqual } from '../areFieldValuesEqual';
import { exchangesConfig, Field, FieldType } from '../exchangesConfig';
import { getFormattedFieldLabel, isDefinitionField, isFormulaField } from './fields';
import { getFormattedFormula } from './lineItemFields';
import { getOrderedAddressFields } from './question-exchange';
import { Action, ActionType, DefinitionFieldConfig, ExchangeDefinition, ExchangeType, FieldConfig, InclusionOption, QuestionAddressField, QuestionExchangeDefinition, QuestionType } from './types';
import { keyByInclusionOptionType } from './vesselPricing';
import {
  getChatActionDisplayProps,
  getDocumentActionDisplayProps,
  getClarificationActionDisplayProps,
  getLineItemActionDisplayProps,
  getCurrencyActionDisplayProps,
  getEvaluationCriterionActionDisplayProps,
  getContractActionDisplayProps,
  getLegacyContractActionDisplayProps,
  getTermsActionDisplayProps,
  getQuestionActionDisplayProps,
  getInclusionsActionDisplayProps,
  getInternalDocumentActionDisplayProps,
  getAuctionTermsActionDisplayProps,
  getBulletinActionDisplayProps,
} from './actionDisplayProps';
import { isLegacyContractExchangeDef } from '../contract';

const {
  CLARIFICATION,
  CHAT_NO_RECEIVER_UPLOAD,
  CHAT_ONLY_RECEIVER_UPLOAD,
  CHAT,
  BULLETIN,
  ACCEPT_CLOSED,
  ACCEPT,
  COMPLETE_OR_SIGN,
  COMPLETE_OR_SIGN_CLOSED,
  COMPLETE_OR_SIGN_LOCKED,
  DOCUMENT_REQUEST,
  DOCUMENT_REQUEST_CLOSED,
  DOCUMENT_REQUEST_LOCKED,
  LINE_ITEM,
  QUESTION,
  EVALUATION_CRITERION,
  CURRENCY,
  CONTRACT,
  LEGACY_CONTRACT,
  INCLUSIONS,
  TERMS,
  INFORMATION,
  INTERNAL_DOCUMENT,
  AUCTION_TERMS,
  HIRE_PERIOD,
  FEES,
  STAGE_APPROVAL,
} = ExchangeType;

const getFieldsFromHistory = (historySlice): ExchangeDefinition['fields'] | null => {
  for (const action of historySlice.reverse()) {
    const field = (
      action.currentValue?.fields ||
      action.def?.fields
    );

    if (field) {
      return field;
    }
  }

  return null;
};

const hasFieldValueChanged = (
  exchangeType: ExchangeType,
  field: Field | DefinitionFieldConfig,
  reviseAction: Action,
) => {
  const { previousValue, currentValue } = reviseAction;

  if (field.isHidden) {
    return false;
  }

  // important to use `get` since `field.key` might be a nested path
  if (isUndefined(get(currentValue, (field as Field).key ?? (field as DefinitionFieldConfig).source.key))) {
    return false;
  }

  if (areFieldValuesEqual(previousValue, currentValue, field)) {
    return false;
  }

  // we don't display detail information when switching from currency-specified
  // to not specified and vice versa.
  if (
    exchangeType === QUESTION &&
    (field as Field).key === 'currencies' && (
      (previousValue && 'currencies' in previousValue && isEmpty(previousValue.currencies)) ||
      (currentValue && 'currencies' in currentValue && isEmpty(currentValue.currencies))
    )
  ) {
    return false;
  }

  return true;
};

/**
 * Returns the translated description for the provided exchange action and
 * additional adjustments for sender-provided exchange actions
 */
export const getActionDisplayProps = (
  action,
  exchangeDef: ExchangeDefinition,
  t: TFunction,
  colors,
  recipientId?: string,
) => {
  const { type, subtype, companyId } = action;
  const senderActionOverrides = (recipientId && companyId !== recipientId) || isLegacyContractExchangeDef(exchangeDef)
    ? {
      user: null,
      textColor: null,
      iconColor: null,
      iconBackgroundColor: colors.darkGray2,
    }
    : null;

  let actionDisplayProps: Record<string, unknown> | null = null;

  switch (exchangeDef.type) {
    case CLARIFICATION:
      actionDisplayProps = getClarificationActionDisplayProps(action, t);
      break;
    case CHAT:
    case CHAT_NO_RECEIVER_UPLOAD:
    case CHAT_ONLY_RECEIVER_UPLOAD:
      actionDisplayProps = getChatActionDisplayProps(action, t);
      break;
    case BULLETIN:
      actionDisplayProps = getBulletinActionDisplayProps(action, t);
      break;
    case ACCEPT_CLOSED:
    case ACCEPT:
    case COMPLETE_OR_SIGN:
    case COMPLETE_OR_SIGN_CLOSED:
    case COMPLETE_OR_SIGN_LOCKED:
    case DOCUMENT_REQUEST:
    case DOCUMENT_REQUEST_CLOSED:
    case DOCUMENT_REQUEST_LOCKED:
    case INFORMATION:
      actionDisplayProps = getDocumentActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case LINE_ITEM:
      actionDisplayProps = getLineItemActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case INCLUSIONS:
      actionDisplayProps = getInclusionsActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case QUESTION:
      actionDisplayProps = getQuestionActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case CURRENCY:
      actionDisplayProps = getCurrencyActionDisplayProps(action, t);
      break;
    case EVALUATION_CRITERION:
      actionDisplayProps = getEvaluationCriterionActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case CONTRACT:
      actionDisplayProps = getContractActionDisplayProps(action, t, exchangeDef, senderActionOverrides);
      break;
    case LEGACY_CONTRACT:
      actionDisplayProps = getLegacyContractActionDisplayProps(action, t, senderActionOverrides);
      break;
    case TERMS:
      actionDisplayProps = getTermsActionDisplayProps(action, t, exchangeDef.type, senderActionOverrides);
      break;
    case INTERNAL_DOCUMENT:
      actionDisplayProps = getInternalDocumentActionDisplayProps(action, t);
      break;
    case AUCTION_TERMS:
      actionDisplayProps = getAuctionTermsActionDisplayProps(action, t);
      break;
    case HIRE_PERIOD:
      actionDisplayProps = {};
      break;
    case FEES:
      actionDisplayProps = {};
      break;
    case STAGE_APPROVAL:
      actionDisplayProps = {};
      break;
    default:
      break;
  }

  if (!actionDisplayProps) {
    throw new Error(
      `Could not get display properties for exchange type ${exchangeDef.type}, action type ${type}, action subtype ${subtype}`,
    );
  }

  return actionDisplayProps;
};

export const getChangedRevisionFields = (exchange, action, historySlice) => {
  if (action.type !== ActionType.REVISE) {
    return null;
  }

  if (exchange.def.fields) {
    const currentFields = getFieldsFromHistory(historySlice);

    if (!currentFields) {
      throw new Error(`Could not get fields for exchange ${exchange.def._id}`);
    }

    const removedFieldIds = difference(
      Object.keys(action.previousValue.fields || {}),
      Object.keys(action.currentValue.fields || {}),
    );

    const changedCurrentFields = Object
      .values(currentFields)
      .filter(field => {
        // Check if the field config has changed
        if (action.changedFieldConfigIds?.includes(field._id)) {
          return true;
        }

        // We only need to check if values of definition fields have changed
        // because these are the only field values outside of the field config
        // that can be changed as part of a revision.
        return (
          isDefinitionField(field) &&
          !areFieldValuesEqual(action.previousValue, action.currentValue, field)
        );
      });

    return [
      ...removedFieldIds.map(propertyOf(action.previousValue.fields || {})),
      ...changedCurrentFields,
    ];
  } else {
    const { fields } = exchangesConfig[exchange.def.type];

    return fields.filter((field: Field) =>
      hasFieldValueChanged(exchange.def.type, field, action),
    );
  }
};

export type FieldData = Field & {
  label: any;
  previousValue: any;
  newValue: any;
  isFirst?: boolean;
};

export type DynamicFieldData = FieldConfig & {
  label: any;
  hasAddedField?: boolean;
  hasRemovedField?: boolean;
  previousLabel?: string;
  newLabel?: string;
  previousValue: any;
  newValue: any;
  previousDecimalPlaces?: number,
  newDecimalPlaces?: number,
  isFirst?: boolean;
};

const transformValue = ({
  t,
  exchange,
  field,
  value,
}: {
  t: any;
  exchange: any;
  field: Omit<Field, 'label'> | Omit<FieldConfig, 'label'>;
  value: unknown;
}) => {
  const fieldKey = (field as Field).key ?? (field as DefinitionFieldConfig).source.key;

  if (isNil(value)) {
    return value;
  }

  if (
    exchange.def.type === INCLUSIONS &&
    fieldKey === 'option'
  ) {
    return t(keyByInclusionOptionType[value as InclusionOption]);
  }

  if (
    exchange.def.type === QUESTION &&
    fieldKey === 'questionType'
  ) {
    return t(`request.question.questionType.${value}`);
  }

  if (
    exchange.def.type === QUESTION &&
    exchange.def.questionType === QuestionType.ADDRESS &&
    fieldKey === 'visibleFields'
  ) {
    return getOrderedAddressFields(value as QuestionAddressField[])
      .map(field => t(`request.question.addressField.${field}`));
  }

  if (field.type === FieldType.BOOLEAN) {
    return value ? t('general.yes') : t('general.no');
  }

  return value;
};

export const getRevisionChangesTableData = (
  action,
  exchange,
  changedFields,
  t,
): FieldData[] => {
  return changedFields.map(({ label, ...field }: Field) => {
    const fieldKey = field.key;

    const isInclusionOptionField = exchange.def.type === INCLUSIONS && fieldKey === 'option';

    return {
      _id: fieldKey,
      previousValue: transformValue({
        t,
        exchange,
        field,
        value: get(action.previousValue, fieldKey),
      }),
      newValue: transformValue({
        t,
        exchange,
        field,
        value: get(action.currentValue, fieldKey),
      }),
      label: isInclusionOptionField
        ? t('request.vesselPricing.inclusions.includedInRates')
        : t(`request.fields.predefinedFieldLabel.${field.labelId}`, { namespace: 'translation' }),
      ...field,
    };
  });
};

export const getDynamicFieldsRevisionChangesTableData = (
  action,
  exchange,
  changedFields: FieldConfig[],
  t,
): DynamicFieldData[] => {
  return changedFields.map((field) => {
    const label = getFormattedFieldLabel(field, t);

    const fieldId = field._id;

    const previousValueField = action.previousValue.fields?.[fieldId];
    const currentValueField = action.currentValue.fields?.[fieldId];

    // handle removed field
    if (previousValueField && !currentValueField) {
      const field = previousValueField;

      return {
        ...field,
        hasRemovedField: true,
        label,
      };
    }

    // handle added field
    if (!previousValueField && currentValueField) {
      const field = currentValueField;
      if (isDefinitionField(field)) {
        return {
          ...field,
          hasAddedField: true,
          newValue: transformValue({
            t,
            exchange,
            field,
            value: get(action.currentValue, field.source.key),
          }),
          label,
        };
      } else {
        return {
          ...field,
          hasAddedField: true,
          label,
        };
      }
    }

    const previousAndNewLabel = (
      previousValueField && currentValueField &&
      previousValueField.label !== currentValueField.label
    ) ? {
          previousLabel: getFormattedFieldLabel(previousValueField, t),
          newLabel: getFormattedFieldLabel(currentValueField, t),
        }
      : {};

    const previousAndNewDecimalPlaces = (
      previousValueField && currentValueField &&
      previousValueField.decimalPlaces !== currentValueField.decimalPlaces
    ) ? {
      previousDecimalPlaces: get(previousValueField, 'decimalPlaces') ?? 2,
      newDecimalPlaces: get(currentValueField, 'decimalPlaces'),
    } : {};

    const fieldIsDefinitionField = isDefinitionField(field);

    const previousValue = fieldIsDefinitionField ? get(action.previousValue, field.source.key) : null;
    const newValue = fieldIsDefinitionField ? get(action.currentValue, field.source.key) : null;

    let previousAndNewValue = previousValue !== newValue
      ? {
          previousValue: transformValue({ t, exchange, field, value: previousValue }),
          newValue: transformValue({ t, exchange, field, value: newValue }),
        }
      : {};

    if (isFormulaField(field)) {
      const previousFormula = action.previousValue.fields[fieldId].source.formula;
      const newFormula = action.currentValue.fields[fieldId].source.formula;

      previousAndNewValue = previousFormula !== newFormula ? {
        previousValue: getFormattedFormula(action.previousValue.fields, fieldId, t),
        newValue: getFormattedFormula(action.currentValue.fields, fieldId, t),
      } : {};
    }

    return {
      ...field,
      ...previousAndNewLabel,
      ...previousAndNewValue,
      ...previousAndNewDecimalPlaces,
      label,
    };
  });
};

/**
 * Amends the question type in a question exchange definition to
 * be the latest question type according to the provided `historySlice`,
 * which holds the exchange actions up to (and including) the current action.
 */
export const amendQuestionType = (
  exchangeDef: QuestionExchangeDefinition,
  historySlice: any[],
) => {
  const action = findLast(
    historySlice,
    action => action.def?.questionType || action.currentValue?.questionType,
  );
  const questionType = action?.def?.questionType || action?.currentValue?.questionType;

  return questionType
    ? { ...exchangeDef, questionType }
    : exchangeDef;
};
