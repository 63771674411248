import { getUserLocale } from '@deepstream/common/user-utils';
import { useSession } from './auth';

export function useCurrentUser() {
  const { user } = useSession();

  if (!user) {
    throw new Error('The session does not contain the current user');
  }

  return user;
}

export const useCurrentUserLocale = () => {
  const currentUser = useCurrentUser();

  return getUserLocale(currentUser);
};
