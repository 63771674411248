/**
 * Throws an exception if the provided value is either null or undefined,
 * allowing TypeScript to correctly infer the non-nullable type.
*/
export function assertDefined<T>(value: T | null | undefined, name?: string): asserts value is T {
  if (value === null) {
    throw new Error(`Expected ${name ?? 'Value'} not to be null.`);
  }
  if (value === undefined) {
    throw new Error(`Expected ${name ?? 'Value'} not to be undefined.`);
  }
}
