export const currencyCodeToSymbol = {
  DKK: 'kr',
  GBP: '£',
  USD: '$',
  EUR: '€',
  MAD: 'MAD',
  NOK: 'kr',
  SEK: 'kr',
} as const;

export type CurrencySymbol = typeof currencyCodeToSymbol[keyof typeof currencyCodeToSymbol];

/**
 * Returns the symbol for the provided currency code. For currencies without a
 * symbol, returns the code. Returns an empty string if `currencyCode` is nil.
 */
export const getCurrencySymbol = (currencyCode?: string): CurrencySymbol | string | '' => {
  if (currencyCode) {
    return currencyCodeToSymbol[currencyCode] ?? currencyCode;
  } else {
    return '';
  }
};
