import { trim } from 'lodash';
import { PageRole } from '../rfq-utils';
import { RoleRestrictionProfile, Roles } from './types';

export * from './getUserLocale';
export * from './getInviteMessage';
export * from './isUserSuspended';
export * from './types';

export const getNameOrEmail = (user?: { name?: string; email: string }) => trim(user?.name)
  ? `${user!.name} (${user!.email})`
  : user?.email;

export const translateToEvaluationPageOwnerRole = (evaluationPageRole: PageRole) => {
  switch (evaluationPageRole) {
    case PageRole.RESPONDER:
    case PageRole.EDITOR:
      return PageRole.EDITOR;
    default:
      return PageRole.COMMENTER;
  }
};

export const translateToEvaluationPageTeamMemberRole = (evaluationPageRole: PageRole) => {
  switch (evaluationPageRole) {
    case PageRole.EDITOR:
      return PageRole.EDITOR;
    case PageRole.RESPONDER:
      return PageRole.RESPONDER;
    default:
      return PageRole.NONE;
  }
};

export const isCompanySuperUser = (
  user: { roleRestrictionProfiles?: Record<string, RoleRestrictionProfile> },
  companyId: string,
) => user?.roleRestrictionProfiles?.[companyId] === RoleRestrictionProfile.SUPER_USER;

export const defaultRoles = {
  [RoleRestrictionProfile.SUPER_USER]: {
    admin: true,
    editor: true,
    managePreQual: true,
    sendQuestionnaires: true,
    receiveQuestionnaires: true,
    sendRFQ: true,
    receiveRFQ: true,
    sendContracts: true,
    receiveContracts: true,
    accessReportingForRequests: true,
    accessReportingForContracts: true,
  } as const satisfies Roles,
  [RoleRestrictionProfile.FULL_USER]: {
    admin: false,
    editor: true,
    managePreQual: true,
    sendQuestionnaires: true,
    receiveQuestionnaires: true,
    sendRFQ: true,
    receiveRFQ: true,
    sendContracts: true,
    receiveContracts: true,
    accessReportingForRequests: true,
    accessReportingForContracts: true,
  } as const satisfies Roles,
  [RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER]: {
    admin: false,
    editor: false,
    managePreQual: false,
    sendQuestionnaires: false,
    receiveQuestionnaires: true,
    sendRFQ: false,
    receiveRFQ: true,
    sendContracts: false,
    receiveContracts: true,
    accessReportingForRequests: false,
    accessReportingForContracts: false,
  } as const satisfies Roles,
};

export const emptyRoles = {
  admin: false,
  editor: false,
  managePreQual: false,
  sendQuestionnaires: false,
  receiveQuestionnaires: false,
  sendRFQ: false,
  receiveRFQ: false,
  sendContracts: false,
  receiveContracts: false,
  accessReportingForRequests: false,
  accessReportingForContracts: false,
} as const satisfies Roles;
