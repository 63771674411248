import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button as RebassButton, ButtonProps as RebassButtonProps, Text } from 'rebass/styled-components';
import clsx from 'clsx';
import { Icon, IconProps } from '../icon/Icon';
import { centeredTextAdjustmentSx } from '../text/textAdjustment';
import { ButtonVariant } from '../../theme/types';

export type ButtonProps =
  Omit<RebassButtonProps, 'variant'> &
  {
    // type: 'button' | 'submit';
    small?: boolean;
    iconLeft?: IconProps['icon'];
    iconLeftRegular?: IconProps['regular'];
    iconRight?: IconProps['icon'];
    style?: React.CSSProperties;
    borderRadius?: number | string;
    variant?: ButtonVariant;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  variant = 'primary',
  iconLeft,
  iconLeftRegular,
  iconRight,
  small = false,
  borderRadius = 4,
  fontWeight = 500,
  fontSize = small ? 1 : 2,
  children,
  sx,
  ...props
}, ref) => (
  <RebassButton
    ref={ref}
    variant={variant}
    px={small ? 2 : '12px'}
    fontSize={fontSize}
    sx={{
      ':active:not(:disabled)': {
        // In DropdownMenu the Button component is rendered as a div,
        // so the "disabled" pseudo-class is not applied in this case
        transform: !props.disabled ? 'translateY(1px)' : undefined,
      },
      borderRadius,
      height: small ? 28 : 40,
      maxWidth: '100%',
      padding: 0,
      lineHeight: 1.5,
      ...sx,
    }}
    className={clsx(props.disabled && 'disabled')}
    {...props}
  >
    <Text
      fontFamily="primary"
      fontSize={fontSize}
      fontWeight={fontWeight}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={centeredTextAdjustmentSx}
    >
      {iconLeft && (
        <Icon
          icon={iconLeft}
          fixedWidth={!children}
          mr={children ? (small ? 1 : 2) : 0}
          flex={0}
          regular={iconLeftRegular}
        />
      )}
      {children}
      {iconRight && (
        <Icon
          icon={iconRight}
          fixedWidth={!children}
          ml={children ? (small ? 1 : 2) : 0}
          flex={0}
        />
      )}
    </Text>
  </RebassButton>
));

Button.displayName = 'Button';

export const EditButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary-outline" iconLeft="pencil" {...props}>
      {t('general.edit')}
    </Button>
  );
};

export const CancelUploadButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="times" variant="secondary" data-test="cancel-upload" {...props}>
      {t('general.cancelUpload')}
    </Button>
  );
};

export const RemoveButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="times" variant="secondary" {...props}>
      {t('general.remove')}
    </Button>
  );
};

export const RespondButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="share" variant="primary-outline" {...props}>
      {t('general.respond')}
    </Button>
  );
};

export const RetryUploadButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="redo" variant="primary" {...props}>
      {t('general.retryUpload')}
    </Button>
  );
};

export const RetryButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="redo" variant="primary-outline" {...props}>
      {t('general.retry')}
    </Button>
  );
};

export const DownloadButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="download" variant="primary-outline" {...props}>
      {t('general.download')}
    </Button>
  );
};

export const DownloadCsvButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button iconLeft="download" variant="secondary-outline" {...props}>
      {t('general.downloadCSV')}
    </Button>
  );
};

export const LearnMoreButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary-outline" iconLeft="info-circle" {...props}>
      {t('general.learnMore')}
    </Button>
  );
};

export const OpenButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary-outline" iconLeft="folder-open" {...props}>
      {t('general.open')}
    </Button>
  );
};

export const ButtonLink = ({ href, sx, ...props }: ButtonProps) => (
  <Button
    as="a"
    href={href}
    target="_blank"
    display="flex"
    sx={{
      alignItems: 'center',
      textDecoration: 'none',
      ...sx,
    }}
    {...props}
  />
);

export const SaveButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="submit" variant="primary" {...props}>
      {children ?? t('general.save')}
    </Button>
  );
};

export const SaveChangesButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="submit" variant="primary" {...props}>
      {children ?? t('general.saveChanges')}
    </Button>
  );
};

export const OkButton = ({ children, variant = 'primary', ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="button" variant={variant} {...props}>
      {children ?? t('general.ok')}
    </Button>
  );
};

export const ConfirmButton = ({ children, variant = 'primary', ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="button" variant={variant} {...props}>
      {children ?? t('general.confirm')}
    </Button>
  );
};

export const ContactToUpgradeButton = ({ variant = 'primary', ...props }: ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button type="button" variant={variant} {...props}>
      {t('contactToUpgrade')}
    </Button>
  );
};

export const CancelButton = ({ children, variant = 'secondary', ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="button" variant={variant} {...props}>
      {children ?? t('general.cancel')}
    </Button>
  );
};
