import { useMediaQuery } from './useMediaQuery';

interface DeviceSizeQueries {
  extraSmall: string;
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
}

// TODO read breakpoints from the theme
const defaultDeviceSizeQueries: DeviceSizeQueries = {
  extraSmall: '(min-width: 0px) and (max-width: 575px)',
  small: '(min-width: 576px) and (max-width: 767px',
  medium: '(min-width: 768px) and (max-width: 1099px)',
  large: '(min-width: 1100px) and (max-width: 1199px)',
  extraLarge: '(min-width: 1200px)',
};

const canHoverQuery = '(any-hover: hover)';

export interface MediaQueries {
  isExtraSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
  canHover: boolean;
}

export const useMediaQueries = (deviceSizeQueries = defaultDeviceSizeQueries): MediaQueries => {
  const isExtraSmall = useMediaQuery(deviceSizeQueries.extraSmall);
  const isSmall = useMediaQuery(deviceSizeQueries.small);
  const isMedium = useMediaQuery(deviceSizeQueries.medium);
  const isLarge = useMediaQuery(deviceSizeQueries.large);
  const isExtraLarge = useMediaQuery(deviceSizeQueries.extraLarge);
  const canHover = useMediaQuery(canHoverQuery);

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    canHover,
  };
};
