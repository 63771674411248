// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgDeepstreamLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={30}
    fill="none"
    {...props}
  >
    <g clipPath="url(#deepstream-logo_svg__a)">
      <g fill="#fff" clipPath="url(#deepstream-logo_svg__b)">
        <path d="M27.552 9.014H16.214V1.01h-4.001v12.004h15.339zM.875 19.685h11.338v8.003h4.001V15.683H.875z" />
      </g>
    </g>
    <defs>
      <clipPath id="deepstream-logo_svg__a">
        <path fill="#fff" d="M.875.417h26.25v29.166H.875z" />
      </clipPath>
      <clipPath id="deepstream-logo_svg__b">
        <path fill="#fff" d="M.875.417h234.498v34.146H.875z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDeepstreamLogo;
