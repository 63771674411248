import { TextField, TextFieldProps } from './TextField';
import { CurrencyCode, CurrencyCodeProps } from '../ui/Currency';

type MoneyFieldProps = TextFieldProps & {
  symbol?: CurrencyCodeProps['symbol'];
  inputPrefixMinWidth?: number;
};

export const MoneyField = ({
  symbol,
  inputStyle,
  inputPrefixMinWidth,
  ...props
}: MoneyFieldProps) => (
  <TextField
    convertNonNumbersToNull
    format="money.positive"
    prefix={<CurrencyCode symbol={symbol} />}
    inputStyle={{
      textAlign: 'right',
      ...inputStyle,
    }}
    inputPrefixMinWidth={inputPrefixMinWidth}
    {...props}
  />
);
