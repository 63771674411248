import { useMemo } from 'react';
import { filter } from 'lodash';
import { User } from '../../../types';
import { useCompanyUsers } from './useCompanyUsers';

export const isOnlyAdmin = (
  userId: string,
  companyId: string,
  users: User[] = [],
) => {
  const admins = filter(
    users,
    user => !!user.roles[companyId].admin,
  );

  return admins.length === 1 && admins[0]._id === userId;
};

export const useIsOnlyAdmin = ({ userId, companyId }) => {
  const { data: users } = useCompanyUsers(companyId);

  return useMemo(
    () => isOnlyAdmin(userId, companyId, users),
    [users, companyId, userId],
  );
};
