import * as React from 'react';
import type { Environment } from '@deepstream/environment';

export type Env = {
  ENV: Environment;
  API_URL: string;
  PUBLIC_PAGES_URL: string;
  HELP_CENTER_URL?: string;
  ONBOARDING_URL?: string;
  AUTH0_DOMAIN?: string;
  AUTH0_CLIENT_ID?: string;
  AUTH0_AUDIENCE?: string;
  AUTH0_REDIRECT_URL?: string;
  AUTH?: string;
  API_GATEWAY_ENDPOINT?: string;
  HUBSPOT_URL?: string;
  FULLVIEW_ORGANIZATION_ID?: string;
  INTERCOM_APP_ID?: string;
  INTERCOM_BASE_URL?: string;
  PRISMATIC_URL?: string
};

const EnvContext = React.createContext<Env>({} as Env);

export const EnvProvider = ({
  env,
  ...props
}: {
  env: Env;
  children: React.ReactNode;
}) => {
  const envMemo = React.useMemo(() => env, [env]);

  return (
    <EnvContext.Provider value={envMemo} {...props} />
  );
};

export const useEnv = () => React.useContext(EnvContext);
