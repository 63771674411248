import * as React from 'react';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { EditJobRoleModal } from './EditJobRoleModal';

export const CompanyUserProfilePropertyList: React.FC<{ user: any; company: any }> = ({ user, company }) => {
  const editJobRoleModal = useModalState();

  const properties = React.useMemo(
    () => [{ name: 'Job role', value: user.jobTitles && user.jobTitles[company._id] }],
    [user, company],
  );

  return (
    <>
      <PropertyList properties={properties}>
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editJobRoleModal.open} />
      </PropertyList>
      <EditJobRoleModal
        user={user}
        companyId={company._id}
        isOpen={editJobRoleModal.isOpen}
        onCancel={editJobRoleModal.close}
        onSave={editJobRoleModal.close}
      />
    </>
  );
};
