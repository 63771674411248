import * as React from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';

/**
 * Only to be imported in Angular context.
 *
 * In React context, access `useQueryClient()` instead.
 */
export const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30000),
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      suspense: false,
    },
  },
});

export const ReactQueryProvider = React.memo(({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
));
