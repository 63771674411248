import * as React from 'react';

export type SortConfig = {
  columnId: string | null,
  direction: 'asc' | 'desc' | null,
};

export type TSortConfigContext = {
  sortConfig: SortConfig,
  setSortConfig: React.Dispatch<React.SetStateAction<{
    columnId: any;
    direction: any;
  }>>
};

const SortConfigContext = React.createContext<TSortConfigContext | null>(null);

export const SortConfigProvider = React.memo<{ children: React.ReactNode } & TSortConfigContext>(({ children, ...props }) => (
  <SortConfigContext.Provider value={props}>
    {children}
  </SortConfigContext.Provider>
));

export const useSortConfig = () => {
  const sortConfig = React.useContext<TSortConfigContext | null>(SortConfigContext);
  if (!sortConfig) throw new Error('No sort config found');
  return sortConfig;
};
