import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { reducer } from './reducer';
import { SimpleAuthContext } from './SimpleAuthContext';

export const initialAuthState = {
  isAuthenticated: false,
  isLoading: true,
  token: null,
  error: '',
};

export function SimpleAuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(
    () => {
      try {
        const token = localStorage.getItem('AUTH_TOKEN');
        dispatch({ type: 'INITIALISED', token });
      } catch (error: any) {
        dispatch({ type: 'ERROR', error });
      }
    },
    [],
  );

  const getAccessTokenSilently = useCallback(
    () => Promise.resolve(state.token),
    [state.token],
  );

  const logout = useCallback(
    ({ logoutParams: { returnTo } }: { logoutParams: { returnTo: string } }) => {
      window.location.href = returnTo;
    },
    [],
  );

  const localAuth = useMemo(
    () => ({
      ...state,
      logout,
      getAccessTokenSilently,
    }),
    [state, logout, getAccessTokenSilently],
  );

  return (
    <SimpleAuthContext.Provider value={localAuth}>
      {children}
    </SimpleAuthContext.Provider>
  );
}
