import { trim, uniqueId } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Flex, Box } from 'rebass/styled-components';
import { transparentize } from 'polished';
import { ComboboxState, SelectState } from 'ariakit';
import { getRegionName } from '@deepstream/common';
import { BorderedIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { FilterSelectCombobox, FilterSelectComboboxProps } from './ui/FilterSelectCombobox';
import { CompanyLogo } from './CompanyLogo';
import { useCurrentCompanyId } from './currentCompanyId';
import { Company } from './ui/types';
import { useCurrentUserLocale } from './useCurrentUser';

const getId = (item: Company) => item._id;

const TEMP_COMPANY_ID_PREFIX = 'temp-id-';

const CompanyIcon = ({ company }: { company: Company }) => {
  const isNewCompany = company._id.startsWith(TEMP_COMPANY_ID_PREFIX);

  return (
    <Box alignItems="center" height="34px" flexShrink={0} mr="2px">
      {isNewCompany ? (
        <BorderedIcon icon="plus" size={24} height="34px" width="34px" mr={2} />
      ) : (
        <CompanyLogo size="sm" companyId={company._id} />
      )}
    </Box>
  );
};

const CompanyDetails = ({ company }: { company: Company }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();
  const isNewCompany = company._id.startsWith(TEMP_COMPANY_ID_PREFIX);

  return (
    <Flex alignItems="center">
      <CompanyIcon company={company} />
      <Stack gap={0} flex={1}>
        <Text>
          <Truncate>
            {company.name}
          </Truncate>
        </Text>
        <Text color="subtext">
          {isNewCompany ? (
            // This company has just been created
            t('general.new')
          ) : (
            <Truncate>
              {getRegionName(company?.address?.country, locale, t('general.unknown'))}
            </Truncate>
          )}
        </Text>
      </Stack>
    </Flex>
  );
};

const CompanyItem = ({
  item,
  disabled,
  currentCompanyId,
  disabledCompanyLabel,
}: {
  item: Company;
  disabled: boolean;
  currentCompanyId?: string | null;
  disabledCompanyLabel?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="space-between" alignItems="center" mr={1} width="100%">
      <CompanyDetails company={item} />
      {disabled ? (
        <Text color="subtext" minWidth="auto" ml={2}>
          {currentCompanyId === item._id ? (
            t('companySelect.yourCompany')
          ) : disabledCompanyLabel || (
            t('companySelect.added')
          )}
        </Text>
      ) : (
        null
      )}
    </Flex>
  );
};

const CompanySelectedItem = ({ item }: { item: Company; disabled: boolean }) => (
  <Flex alignItems="center" mr={1} width="100%">
    <CompanyIcon company={item} />
    <Text textAlign="start">
      <Truncate>
        {item.name}
      </Truncate>
    </Text>
  </Flex>
);

type CompanySelectProps = {
  onCreateCompany?: (company: Partial<Company>) => void;
  filterItems: (value: string) => Promise<Company[]>;
  placeholder?: string;
  currentCompanyId?: string;
  disabledCompanyLabel?: string;
  withResultListSubheader?: boolean;
} & Omit<
  FilterSelectComboboxProps<Company>,
  'selectedPlaceholder' |
  'filterPlaceholder' |
  'emptyFilteredItemsMessage' |
  'getId' |
  'Item' |
  'filterItems' |
  'items'
  >;

const MenuFooterButton = ({ onClick, children }) => (
  <WrapperButton width="100%" onClick={onClick}>
    <Box
      color="text"
      py={2}
      px="10px"
      height="57px"
      textAlign="left"
      alignItems="center"
      display="flex"
      sx={{
        ':hover': {
          backgroundColor: theme => transparentize(0.90, theme.colors.primary),
        },
      }}
    >
      {children}
    </Box>
  </WrapperButton>
);

type CreateCompanyProps = {
  inputValue?: string;
  onCreateCompany: (company: Partial<Company>) => void;
};

const CreateCompanyButton = ({ onCreateCompany, inputValue }: CreateCompanyProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={2} mt="6px" width="100%">
      <Text
        color="subtextLight"
        fontSize={1}
        letterSpacing="1px"
        px="10px"
        style={{ textTransform: 'uppercase' }}
      >
        {t('companySelect.createNewCompany')}
      </Text>
      <Flex width="100%">
        <MenuFooterButton
          onClick={() => {
            onCreateCompany({ _id: uniqueId(TEMP_COMPANY_ID_PREFIX), name: inputValue });
          }}
        >
          <BorderedIcon icon="plus" mr={2} />
          {inputValue}
        </MenuFooterButton>
      </Flex>
    </Stack>
  );
};

const CompaniesListWrapper = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={0} width="100%">
      <Text
        color="subtextLight"
        fontSize={1}
        marginLeft="10px"
        paddingY={2}
        letterSpacing="1px"
        style={{ textTransform: 'uppercase' }}
      >
        {t('companySelect.selectExistingCompany')}
      </Text>
      {children}
    </Stack>
  );
};

export const CompanySelect = ({
  onCreateCompany,
  filterItems,
  placeholder,
  currentCompanyId,
  disabledCompanyLabel,
  withResultListSubheader = true,
  ...props
}: CompanySelectProps) => {
  const { t } = useTranslation();
  const contextCurrentCompany = useCurrentCompanyId({ required: false });

  const renderCreateCompany = useCallback((comboboxState: ComboboxState, selectState: SelectState) => {
    const trimmedValue = trim(comboboxState.value);

    if (!trimmedValue || !onCreateCompany) {
      return null;
    }

    const handleCreateCompany = (company: Partial<Company>) => {
      onCreateCompany(company);
      selectState.hide();
    };

    return <CreateCompanyButton onCreateCompany={handleCreateCompany} inputValue={trimmedValue} />;
  }, [onCreateCompany]);

  return (
    <FilterSelectCombobox<Company>
      selectedPlaceholderText={t('companySelect.selectACompany')}
      filterPlaceholder={placeholder ?? t('companySelect.enterCompanyName')}
      emptyFilteredItemsMessage={t('companySelect.noCompaniesFound')}
      filterItems={filterItems}
      getId={getId}
      Item={(props) => (
        <CompanyItem
          {...props}
          currentCompanyId={currentCompanyId ?? contextCurrentCompany}
          disabledCompanyLabel={disabledCompanyLabel}
        />
      )}
      SelectedItem={CompanySelectedItem}
      ListWrapper={withResultListSubheader ? CompaniesListWrapper : undefined}
      renderMenuFooter={renderCreateCompany}
      maxItems={4}
      {...props}
    />
  );
};
