import { Attachment } from '@deepstream/common/rfq-utils';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { downloadUrl } from '@deepstream/ui/useDownload';
import { useMutation } from '@deepstream/ui/useMutation';

export const useDownloadTermsAttachment = (filename = '') => {
  const api = useAdminApi();
  const toaster = useToaster();

  return useMutation(
    async (attachment: Attachment) => {
      const { url } = await api.getTermsAttachmentDownloadUrl(attachment);
      downloadUrl(url, filename);
    },
    {
      onSuccess: () => toaster.success('Document downloaded successfully'),
      onError: () => toaster.error('Document could not be downloaded'),
    },
  );
};
