import { useMemo } from 'react';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { withProps } from '@deepstream/ui-utils/withProps';
import * as rfx from '@deepstream/ui/rfx';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { ObsoleteCell, ExchangeDefDescriptionCell, QuestionTypeCell, SwitchCell, useGetSupplierResponseCellValue } from '@deepstream/ui/draft/cell';
import { nestCells } from '@deepstream/ui/nestCells';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';

type EvalDef = QuestionExchangeDefinition;

export const AdminQuestionsTable = ({
  exchangeDefIds,
}: {
  exchangeDefIds: string[];
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();

  const exchangeDefs = useMemo(() => exchangeDefIds.map(
    exchangeDefId => structure.exchangeDefById[exchangeDefId] as EvalDef,
  ), [exchangeDefIds, structure]);

  const getSupplierResponseCellValue = useGetSupplierResponseCellValue();

  const columns = useMemo(
    () => [
      {
        id: 'description',
        Header: 'Question',
        accessor: 'description',
        Cell: nestCells(ObsoleteCell, ExchangeDefDescriptionCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'options',
        accessor: getSupplierResponseCellValue,
        Header: 'Supplier response',
        Cell: nestCells(ObsoleteCell, nestCells(TruncateCell, ValueOrDashCell)),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'questionType',
        Header: 'Type',
        accessor: 'questionType',
        Cell: nestCells(ObsoleteCell, QuestionTypeCell),
        Filter: withProps(FilterSelect, {
          itemToString: value => t(`request.question.questionType.${value}`),
        }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'isRequired',
        Header: 'Required',
        accessor: 'isRequired',
        Cell: nestCells(ObsoleteCell, SwitchCell),
        width: 110,
        Filter: withProps(FilterSelect, {
          itemToString: value => `${value ? 'Yes' : 'No'}`,
          transformSelectedValues: value => `${value ? 'Yes' : 'No'}`,
        }),
        filter: filterMultipleValues,
        sortType: 'boolean',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [getSupplierResponseCellValue, t],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={exchangeDefs}
        isSortable
        noFilteredDataPlaceholder="No questions match chosen filters"
      />
    </StaticTableStyles>
  );
};
