import * as React from 'react';
import { GridData, TExpandableRowDataBase, TOriginalColumnDataBase, TOriginalSubRowDataBase } from './utils';

export type FrozenPanelsContextValue<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  data: GridData<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>;
  idPrefix: string;
  FrozenHeader: React.FC<any>;
  FrozenFooter: React.FC | null;
  bodyPaddingRight: number;
  bodyPaddingBottom: number;
  AdditionalHeaderContent?: React.FC<{ height: number; width: number }> | null;
  FooterContent?: React.FC<{ height: number; width: number; idPrefix: string }> | null;
  frozenLeftColumnWidth: number;
};

export const FrozenPanelsContext = React.createContext<FrozenPanelsContextValue<any, any, any>>({
  data: {} as any,
  idPrefix: '',
  FrozenHeader: () => null,
  FrozenFooter: null,
  bodyPaddingRight: 0,
  bodyPaddingBottom: 0,
  AdditionalHeaderContent: null,
  FooterContent: null,
  frozenLeftColumnWidth: 0,
});
