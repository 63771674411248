import { useOpenState } from '@deepstream/ui-kit/hooks/useOpenState';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { useCallback, useMemo } from 'react';

export const useCollapsibleContent = (initialIsOpen = false) => {
  const id = useUniqueId('disclosure');
  const openState = useOpenState(initialIsOpen);

  const getButtonProps = useCallback(
    (props = {}) => ({
      'aria-expanded': openState.isOpen,
      'aria-controls': id,
      onClick: openState.toggle,
      ...props,
    }),
    [openState, id],
  );

  const getPanelProps = useCallback(
    (props = {}) => ({
      id,
      hidden: !openState.isOpen,
      ...props,
    }),
    [openState, id],
  );

  return useMemo(
    () => ({ getButtonProps, getPanelProps, ...openState }),
    [getButtonProps, getPanelProps, openState],
  );
};
