import { useMemo } from 'react';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { useQuestionnaireTemplateData } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { RenewalFrequency } from '@deepstream/ui/modules/PreQualification/RenewalFrequency';

export const AdminQuestionnaireTemplatePropertyList = ({ companyName, numSuppliers }: { companyName: string; numSuppliers: number }) => {
  const template = useQuestionnaireTemplateData();

  const generalProperties = useMemo(
    () => [
      { name: 'Company', value: companyName },
      { name: 'Created by', value: template.meta.creatorUsername },
      { name: 'Created on', value: template.meta.creationTimestamp, Component: Datetime },
      { name: 'Last updated by', value: template.meta.lastUpdatedBy?.name },
      { name: 'Last updated on', value: template.meta.lastUpdatedTimestamp, Component: Datetime },
      { name: 'Version', value: template.version },
      { name: 'Renewal', value: template.renewalConfig, Component: RenewalFrequency },
      { name: 'No. of questions', value: template.exchangeDefSequence.length },
      { name: 'No. of suppliers', value: numSuppliers },
    ],
    [template, companyName, numSuppliers],
  );

  return (
    <PropertyList properties={generalProperties} />
  );
};
