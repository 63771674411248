import * as yup from 'yup';
import { useQueryClient, useQuery } from 'react-query';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalForm, ModalFormProps } from '@deepstream/ui/ModalForm';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { getPrimarySubtag } from '@deepstream/utils';

type EditUserLocaleModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { user, languageNames, onSave };

export const EditUserLocaleModal = ({ user, languageNames, onSave, ...props }: EditUserLocaleModalProps) => {
    const adminApi = useAdminApi();
    const toaster = useToaster();
    const queryClient = useQueryClient();

    const [updateUserLocale] = useMutation(
      adminApi.updateUserLocale,
      {
        onSuccess: () => toaster.success('Language preference updated successfully'),
        onError: () => toaster.error('Language preference could not be updated'),
        onSettled: () => queryClient.invalidateQueries(['user', { userId: user._id }]),
      },
    );

    const { data: enabledLanguages } = useQuery(
      ['languages'],
      wrap(adminApi.getEnabledLanguages),
    );

    return (
      <ModalForm
        heading="Edit language"
        initialValues={{
          locale: user.preferences?.locale,
        }}
        validationSchema={yup.object({
          locale: yup.string().required(),
        })}
        onSubmit={async ({ locale }, { setSubmitting }) => {
          try {
            updateUserLocale({
              userId: user._id,
              locale,
            }, {
              onSuccess: onSave,
            });
          } catch (error) {
            console.error(error);
          } finally {
            setSubmitting(false);
          }
        }}
        {...props}
      >
        <SelectField
          required
          name="locale"
          label="Language"
          items={enabledLanguages ? enabledLanguages.map(language => {
            return {
              label: languageNames.of(getPrimarySubtag(language.code)),
              value: language.code,
            };
          }) : []}
        />
      </ModalForm>
    );
  };
