import { useMemo } from 'react';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useContractData, useContractSection } from '@deepstream/ui/modules/Contracts/contract';
import { ExchangeSnapshot } from '@deepstream/ui/types';
import { ContractExchangeDefinition } from '@deepstream/common/contract';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { YesNoCell } from '../../YesNoCell';

export const AdminContractsTable = () => {
  const contract = useContractData();
  const { exchangeDefIds } = useContractSection();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'description',
        Cell: ValueOrDashCell,
      },
      {
        Header: 'Recipient counterparty signed?',
        accessor: (exchangeDef: ContractExchangeDefinition) => {
          const exchange = contract.exchangeById[exchangeDef._id] as ExchangeSnapshot;
          return exchange
            ? exchange.isResolved || exchange.isAwaitingCounterSignature || exchange.isAwaitingCounterESignature
            : false;
        },
        Cell: YesNoCell,
      },
      {
        Header: 'Sender counterparty signed?',
        accessor: (exchangeDef: ContractExchangeDefinition) => {
          const exchange = contract.exchangeById[exchangeDef._id] as ExchangeSnapshot;
          return exchange
            ? exchange.isResolved
            : false;
        },
        Cell: YesNoCell,
      },
    ],
    [contract],
  );

  const data = useMemo(
    () => exchangeDefIds.map(exchangeDefId => contract.exchangeDefById[exchangeDefId]),
    [exchangeDefIds, contract],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
      />
    </StaticTableStyles>
  );
};
