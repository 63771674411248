import * as React from 'react';
import { Form, Formik, FormikConfig } from 'formik';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

export type ModalFormProps =
  ModalProps &
  {
    heading: string;
    submitLabel?: string;
    submitVariant?: ButtonProps['variant'];
    cancelLabel?: string;
    footerLeftButton?: React.ReactNode | null;
    onCancel: any;
    onSubmit: FormikConfig<any>['onSubmit'];
    validationSchema: FormikConfig<any>['validationSchema'];
    initialValues: FormikConfig<Record<string, any>>['initialValues'];
    disableSubmitIfInvalid?: boolean;
    disableSubmitIfNotDirty?: boolean;
    disableCancelWhenSubmitting?: boolean;
    validate?: FormikConfig<any>['validate'];
  };

export const ModalForm: React.FC<ModalFormProps> = ({
  heading,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  submitLabel,
  submitVariant,
  cancelLabel,
  footerLeftButton = null,
  disableSubmitIfInvalid = true,
  disableSubmitIfNotDirty = true,
  disableCancelWhenSubmitting = false,
  validate,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Modal {...props}>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isSubmitting, dirty, isValid, errors }) => (
          <Form>
            <ModalHeader onClose={disableCancelWhenSubmitting && isSubmitting ? undefined : onCancel}>
              {heading}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                {children}
              </Stack>
            </ModalBody>

            <ModalFooter justifyContent="space-between" sx={{ gap: 3 }}>
              <Flex>
                {footerLeftButton}
              </Flex>
              <Flex>
                <CancelButton
                  label={cancelLabel}
                  onClick={onCancel}
                  disabled={disableCancelWhenSubmitting && isSubmitting}
                />
                <SaveButton
                  label={submitLabel || t('general.save')}
                  variant={submitVariant}
                  disabled={isSubmitting || (disableSubmitIfNotDirty && !dirty) || (disableSubmitIfInvalid && !isValid)}
                />
              </Flex>
            </ModalFooter>

          </Form>
        )}
      </Formik>
    </Modal>
  );
};
