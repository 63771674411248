import { useState } from 'react';
import { useField } from 'formik';
import { useQuery } from 'react-query';
import { filter, identity } from 'lodash';
import { Box } from 'rebass/styled-components';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { UserSelect } from './UserSelect';
import { useApi, wrap } from '../../../api';
import { ErrorMessage } from '../../../form/Field';
import { User } from '../../../types';

export const UserSelectField = ({
  companyId,
  existingUserIds,
  filterIteratee = identity,
}: {
  companyId: string;
  existingUserIds: string[];
  filterIteratee?: (user: User) => boolean;
}) => {
  const api = useApi();

  const [userValue, setUserValue] = useState(undefined);
  const labelId = useUniqueId();
  const [, meta, formik] = useField<any>('selectedUser');

  const { data: usersForCompany = [], status } = useQuery(
    ['usersForCompany', { companyId }],
    wrap(api.getUsersForCompany),
  );

  const filteredUsers = filter(usersForCompany, filterIteratee);

  const items = filteredUsers.map(({ _id, name, email, roleRestrictionProfiles }) => ({
    _id,
    name: name ?? '',
    email: email ?? '',
    roleRestrictionProfile: roleRestrictionProfiles?.[companyId] ?? null,
  }));

  const error = meta.touched && meta.error ? meta.error : undefined;

  return (
    <Box width="100%">
      {status === 'success' && usersForCompany && (
        <>
          <UserSelect
            initialItem={userValue}
            items={items}
            onTouched={() => formik.setTouched(true)}
            isDisabled={item => existingUserIds.includes(item._id)}
            labelId={labelId}
            onChange={(user: any) => {
              setUserValue(user);
              formik.setValue(user);
            }}
          />
          {error && (
            <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
              <ErrorMessage error={error} fontWeight="normal" />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
