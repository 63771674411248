import * as React from 'react';
import { Placement } from '@floating-ui/react';
import { useToaster } from '@deepstream/ui/toast';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import { PopoverMenu } from './PopoverMenu';

type CopyIdMenuProps = {
  value: string;
  valueLabel: string;
  children: React.ReactElement;
  placement?: Placement;
};

export const CopyValueMenu: React.FC<CopyIdMenuProps> = ({ value, valueLabel, placement, children }) => {
  const toaster = useToaster();

  const buttons = React.useMemo(
    () => [
      {
        label: `Copy ${valueLabel}`,
        onClick: () => {
          copyToClipboard(value);
          toaster.success(`${valueLabel} copied to clipboard`);
        },
      },
    ],
    [toaster, value, valueLabel],
  );

  return (
    <PopoverMenu buttons={buttons} placement={placement}>
      {children}
    </PopoverMenu>
  );
};
