import { useMemo, useState, useEffect } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';
import { castArray, compact, identity } from 'lodash';

import { useQueryClient } from 'react-query';
import { useApi } from '../../api';
import { useNotifications } from './NotificationsProvider';

const defaultThreshold = [0.5];

/**
 * Mark notification as read when the subject is in view
 */
export const useNotificationSubject = ({
  filter,
  transform = identity,
  threshold = defaultThreshold,
}: {
  filter: Parameters<typeof useNotifications>[0];
  transform?: any;
  threshold?: IntersectionOptions['threshold'];
}) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const notifications = useNotifications(filter);

  const unreadNotifications: any[] = useMemo(
    () => compact(castArray(transform(notifications))),
    [notifications, transform],
  );

  const [hasBeenRead, setHasBeenRead] = useState(false);
  const [ref, inView] = useInView({ threshold });

  useEffect(
    () => {
      if (inView && unreadNotifications && !hasBeenRead) {
        const notificationIds = unreadNotifications.map(notification => notification._id);

        if (notificationIds.length) {
          setHasBeenRead(true);

          api
            .markNotificationsAsRead({ notificationIds })
            .then(() => queryClient.invalidateQueries('notifications'));
        }
      }
    },
    [api, inView, unreadNotifications, hasBeenRead, filter, queryClient],
  );

  return ref;
};
