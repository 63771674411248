import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { groupBy } from 'lodash';

import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useToaster } from '@deepstream/ui/toast';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useMutation } from '@deepstream/ui/useMutation';
import { useConfirmDialog, useModalState } from '@deepstream/ui/ui/useModalState';
import { DeleteMenuItem, DropdownMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Button, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';

import { IntegrationDataType } from '@deepstream/ui/types';
import { CompanyLink } from './CompanyLink';
import { Page } from './Page';
import * as Title from './title';
import { ExternalSystemSubscriptionsTable } from './ExternalSystemSubscriptionsTable';
import { ExternalSystemPropertyList } from './ExternalSystemPropertyList';
import { AddOrEditExternalSystemModal } from './AddOrEditExternalSystemModal';
import { AddOrEditSubscriptionModal } from './AddOrEditSubscriptionModal';
import { ExternalToInternalCompanyDataTable } from './ExternalToInternalCompanyDataTable';
import { RfxToExternalEntityDataTable } from './RfxToExternalEntityDataTable';
import { InternalToExternalLineItemDataTable } from './InternalToExternalLineItemDataTable';
import { companyExternalSystemRoute, useAdminNavigation } from './AppRouting';

export const BorderWrap = styled(Flex)`
  border: #E2E8EF 1px solid;
`;

const ExternalSystemActions = ({ company, externalSystem }) => {
  const toaster = useToaster();
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const navigation = useAdminNavigation();

  const editExternalSystemModal = useModalState();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const [deleteExternalSystem] = useMutation(
    adminApi.deleteExternalSystem,
    {
      onSuccess: () => toaster.success('External system deleted'),
      onError: () => toaster.error('External system could not be deleted'),
      onSettled: () => queryClient.invalidateQueries(['externalSystems', { companyId: company._id }]),
    },
  );

  return (
    <>
      <Box display="inline-block">
        <ButtonGroup marginBetween="-1px">
          <EditButton small onClick={() => editExternalSystemModal.open()} />
          <DropdownMenu
            small
            rightAligned
            variant="primary-outline"
            iconLeft="ellipsis-h"
            menuZIndex={10}
          >
            <DeleteMenuItem
              onSelect={() => {
                confirm(() => {
                  deleteExternalSystem({ externalSystemId: externalSystem._id });
                  navigation.navigateToCompany(company._id);
                });
              }}
            />
          </DropdownMenu>
        </ButtonGroup>
      </Box>
      <AddOrEditExternalSystemModal
        company={company}
        externalSystem={externalSystem}
        isOpen={editExternalSystemModal.isOpen}
        onCancel={editExternalSystemModal.close}
        onSave={editExternalSystemModal.close}
        onRequestClose={editExternalSystemModal.close}
      />
      <ConfirmDeleteDialog
        heading={`Delete external system "${externalSystem.name}"?`}
        message="This cannot be undone"
        {...dialogProps}
      />
    </>
  );
};

const PageTitle: React.FC<any> = ({ flexDirection, company, externalSystem }) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <Title.IconText icon="solar-system" size="large">
        {externalSystem.name}
      </Title.IconText>

      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          company={company}
        />
      </CompanyLink>
    </Title.Container>
  );
};

export const CompanyExternalSystemPage = () => {
  const adminApi = useAdminApi();
  const addSubscriptionModal = useModalState();

  const { companyId, externalSystemId } = companyExternalSystemRoute.useParams();
  const [ref, inView, entry] = useInView();

  const { data: company, status: companyStatus } = useQuery(
    ['company', { companyId }],
    wrap(adminApi.getCompany),
  );

  const { data: externalSystem, status: externalSystemStatus } = useQuery(
    ['externalSystem', { externalSystemId }],
    wrap(adminApi.getExternalSystem),
  );

  const { data: integrationData, status: integrationDataStatus } = useQuery(
    ['integrationData', { systemId: externalSystem && externalSystem.systemId, companyId }],
    wrap(adminApi.getIntegrationDataBySystemAndCompany),
  );

  const integrationDataByType = groupBy(integrationData, 'type');
  const internalToExternalLineItemData = integrationDataByType[IntegrationDataType.INTERNAL_LINE_ITEM_TO_EXTERNAL_LINE_ITEM] || [];
  const externalToInternalCompanyData = integrationDataByType[IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY] || [];
  const rfxToExternalEntityCompanyData = integrationDataByType[IntegrationDataType.RFX_TO_EXTERNAL_ENTITY] || [];

  return (
    <Page
      subHeading={
        company &&
        externalSystem &&
        entry &&
        !inView && (
          <PageTitle company={company} externalSystem={externalSystem} />
        )
      }
    >
      {companyStatus === 'loading' || externalSystemStatus === 'loading' || integrationDataStatus === 'loading' ? (
        <Panel>
          <PanelText>
            <Loading />
          </PanelText>
        </Panel>
        ) : companyStatus === 'error' || externalSystemStatus === 'error' || integrationDataStatus === 'error' ? (
          <Panel heading="Error">
            <PanelText>Oh no</PanelText>
          </Panel>
        ) : company && externalSystem ? (
          <>
            <div ref={ref}>
              <PageTitle flexDirection="column" company={company} externalSystem={externalSystem} />
            </div>

            <Stack gap={4}>
              <Panel>
                <PanelHeader heading="General">
                  <ExternalSystemActions externalSystem={externalSystem} company={company} />
                </PanelHeader>
                <PanelDivider />
                <ExternalSystemPropertyList externalSystem={externalSystem} />
              </Panel>

              <Panel mb={4}>
                <PanelHeader heading="Subscriptions">
                  <Button small iconLeft="plus" onClick={addSubscriptionModal.open} >
                    Add subscription
                  </Button>
                </PanelHeader>
                <PanelDivider />
                {externalSystem.subscriptions?.length ? (
                  <ExternalSystemSubscriptionsTable
                    externalSystemId={externalSystem._id}
                    subscriptions={externalSystem.subscriptions}
                  />
                ) : (
                  <PanelText color="gray">No subscriptions yet</PanelText>
                )}
              </Panel>
              <Panel mb={4}>
                <PanelHeader heading="Internal to external company data" />
                <PanelDivider />
                {externalToInternalCompanyData.length ? (
                  <ExternalToInternalCompanyDataTable
                    externalToInternalCompanyData={externalToInternalCompanyData}
                    hasExternalSupplierStatuses={externalSystem.hasExternalSupplierStatuses}
                  />
                ) : (
                  <PanelText color="gray">No company integration data yet</PanelText>
                )}
              </Panel>
              <Panel mb={4}>
                <PanelHeader heading="RFX to external entity data" />
                <PanelDivider />
                {rfxToExternalEntityCompanyData.length ? (
                  <RfxToExternalEntityDataTable rfxToExternalEntityCompanyData={rfxToExternalEntityCompanyData} />
                ) : (
                  <PanelText color="gray">No RFX to external entity data yet</PanelText>
                )}
              </Panel>
              <Panel mb={4}>
                <PanelHeader heading="Internal to external line item data" />
                <PanelDivider />
                {internalToExternalLineItemData.length ? (
                  <InternalToExternalLineItemDataTable internalToExternalLineItemData={internalToExternalLineItemData} />
                ) : (
                  <PanelText color="gray">No line item data yet</PanelText>
                )}
              </Panel>
              <AddOrEditSubscriptionModal
                externalSystemId={externalSystem._id}
                isOpen={addSubscriptionModal.isOpen}
                onCancel={addSubscriptionModal.close}
                onSave={addSubscriptionModal.close}
                onRequestClose={addSubscriptionModal.close}
              />
            </Stack>
          </>
        ) : null}
    </Page>
  );
};
