import * as React from 'react';
import { ActiveStageFilter, ActiveStageFilterOperator, FinalDeadlineFilter, FinalDeadlineFilterOperator } from '@deepstream/common';
import { compact, constant, find, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { endOfDay, startOfDay } from 'date-fns';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useLocalStorageState } from './useLocalStorageState';
import { renderStatusIconText } from './RequestsTable';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { CompanyItem, renderCompanyItemLabel } from './modules/Contracts/CompanySelectField';
import { DashboardRole, RecipientFilterItem } from './types';

export const ALL_DASHBOARD_ROLES: DashboardRole[] = [
  'owner',
  'teamMember',
  'none',
  'requestCreator',
];

export const ACTIVE_STAGE_DEFAULT_STAGE_NUMBER = 1;
export const ACTIVE_STAGE_DEFAULT_OPERATOR = ActiveStageFilterOperator.EQ;
export const FINAL_DEADLINE_DEFAULT_OPERATOR = FinalDeadlineFilterOperator.EQ;

type StatusFilter = {
  status: string;
  label: string;
  icon: {
    value: IconProps['icon'];
    color: string;
    isRegular?: boolean;
  };
};

export type DashboardRoleFilter = {
  name: string;
  _id: string;
};

export type ActiveStageLabeledFilter = ActiveStageFilter & {
  label: string;
};

export type FinalDeadlineLabeledFilter = FinalDeadlineFilter & {
  label: string;
};

export const useStatusFilterProps = (context: string, statusFilterItems: StatusFilter[]) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  return useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${context}.statusFilter`,
    items: statusFilterItems,
    idProp: 'status',
    renderItem: renderStatusIconText,
    getQueryParam: (selectedItems: StatusFilter[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      statuses: map(selectedItems, 'status').sort(),
    }),
  });
};

export const useRecipientFilterProps = (context: string, recipients: RecipientFilterItem[]) => {
  const { t } = useTranslation('general');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();

  const items = React.useMemo(
    () => map(
      recipients,
      recipient => ({
        label: recipient.name,
        value: recipient._id,
        address: recipient.address,
      }) as CompanyItem,
    ),
    [recipients],
  );

  return useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${context}.recipientFilter`,
    items,
    idProp: 'value',
    // @ts-ignore ts(2345) FIXME: Argument of type 'CompanyItem | null' is not assignable to parameter of type 'CompanyItem'.
    renderItem: (item) => renderCompanyItemLabel(item, locale, t),
    getQueryParam: (selectedItems: CompanyItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      recipients: map(selectedItems, 'value').sort(),
    }),
  });
};

export const useDashboardRoleFilterItems = (roles: string[] | undefined) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    if (!roles) {
      return;
    }

    return roles.map(role => ({
      _id: role,
      name: t(`requests.dashboardRole.${role}`),
    }));
  }, [t, roles]);
};

/**
 * Provides properties to control filter selection components.
 * The selection gets persisted in localStorage.
 */
export const useLocalStorageFilterProps = <
  TItem extends Record<string, unknown>,
  TRenderItem extends TItem
>({
  storageKey,
  items,
  idProp,
  renderItem,
  getQueryParam = constant({}),
  defaultValue = [],
}: {
  storageKey: string;
  items?: TItem[];
  idProp: keyof TItem;
  renderItem?: (item: TRenderItem | null) => JSX.Element | string;
  getQueryParam?: (selectedItems: TItem[]) => Record<string, unknown>;
  defaultValue?: TItem[];
}) => {
  const previousItems = React.useRef<TItem[] | undefined>(items);

  const [selectedItems, setSelectedItems] =
    useLocalStorageState<TItem[]>({
      key: storageKey,
      defaultValue,
      mapInitialValue: (initialItems) =>
        // When there are `items`, map the `initialItems` from localStorage
        // to the corrsponding `items` to support strict equality checks
        // in the filter select component.
        // When there are no `items` yet (they might get loaded from the
        // server), just forward the `initialItems` from localStorage
        // so the `initialItems` can get passed immediately to consumers
        // like filter queries.
        items
          ? compact(
            initialItems.map(initialItem =>
              find(items, item => item[idProp] === initialItem[idProp]),
            ),
          )
          : initialItems,
    });

  // The first time `items` is defined after initially having been undefined,
  // map the `selectedItems` to the corresponding `items` to support strict
  // equality checks in the filter select component.
  React.useEffect(() => {
    if (items && previousItems.current !== items) {
      previousItems.current = items;
      setSelectedItems(selectedItems => compact(
        selectedItems.map(selectedItem =>
          find(items, item => item[idProp] === selectedItem[idProp]),
        ),
      ));
    }
  }, [idProp, items, setSelectedItems]);

  return {
    itemToString: (item: TItem | null) => item ? item[idProp] : '',
    renderItem,
    items: items ?? [],
    selectedItems,
    onChange: setSelectedItems,
    getQueryParam,
    idProp,
  };
};

/**
 * Provides properties to control filter selection components.
 * The selection does not get persisted in localStorage.
 */
export const useFilterProps = <
  TItem extends Record<string, unknown>,
  TRenderItem extends TItem
>({
  items,
  idProp,
  renderItem,
  getQueryParam = constant({}),
  initialValue = [],
}: {
  items?: TItem[];
  idProp: keyof TItem;
  renderItem?: (item: TRenderItem | null) => JSX.Element | string;
  getQueryParam?: (selectedItems: TItem[]) => Record<string, unknown>;
  initialValue?: TItem[];
}) => {
  const [selectedItems, setSelectedItems] = React.useState<TItem[]>(initialValue);

  return {
    itemToString: (item: TItem | null) => item ? item[idProp] : '',
    renderItem,
    items: items ?? [],
    selectedItems,
    onChange: setSelectedItems,
    getQueryParam,
  };
};

export type FilterProps<
  TItem extends object,
  TRenderItem extends TItem = TItem,
  TQueryParam extends Record<string, unknown> = Record<string, unknown>
> = {
  itemToString: (item: TItem | null) => any;
  renderItem: ((item: TRenderItem | null) => JSX.Element | string) | undefined;
  items: TItem[];
  selectedItems: TItem[];
  onChange: React.Dispatch<React.SetStateAction<TItem[]>>;
  getQueryParam: (selectedItems: TItem[]) => TQueryParam;
  idProp?: keyof TItem;
};

export const ClearFiltersButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      small
      variant="secondary-outline"
      iconLeft="times"
      {...props}
    >
      {t('requests.filtering.clearFilters')}
    </Button>
  );
};

const useActiveStageFilterItems = (): ActiveStageLabeledFilter[] => {
  const { t } = useTranslation();

  return React.useMemo(() => [
    { label: t('requests.filtering.isAtStage'), operator: ActiveStageFilterOperator.EQ },
    { label: t('requests.filtering.isBeforeStage'), operator: ActiveStageFilterOperator.LT },
    { label: t('requests.filtering.isAfterStage'), operator: ActiveStageFilterOperator.GT },
    { label: t('requests.filtering.isAtOrBeforeStage'), operator: ActiveStageFilterOperator.LTE },
    { label: t('requests.filtering.isAtOrAfterStage'), operator: ActiveStageFilterOperator.GTE },
    { label: t('requests.filtering.isAtFinalStage'), operator: ActiveStageFilterOperator.FINAL_STAGE },
  ], [t]);
};

export const useActiveStageFilterProps = (context: string): FilterProps<ActiveStageLabeledFilter> => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const items = useActiveStageFilterItems();
  const [activeStageFilter, setActiveStageFilter] =
    useLocalStorageState<ActiveStageLabeledFilter | undefined>({
      key: `${currentCompanyId}.${user._id}.${context}.activeStageFilter`,
      defaultValue: undefined,
    });

  // @ts-ignore ts(2322) FIXME: Type '{ items: ActiveStageLabeledFilter[]; selectedItems: { stageNumber?: number | undefined; operator: "lt" | "lte" | "eq" | "gte" | "gt" | "neq" | "final_stage"; label: string; }[]; ... 4 more ...; getQueryParam: (selectedItems: ActiveStageLabeledFilter[]) => false | { ...; }; }' is not assignable to type 'FilterProps<ActiveStageLabeledFilter, ActiveStageLabeledFilter, Record<string, unknown>>'.
  return React.useMemo(() => {
    return {
      items,
      selectedItems: activeStageFilter ? [{ ...activeStageFilter }] : [],
      onChange: (newSelectedItems) => {
        if (newSelectedItems.length > 0) {
          setActiveStageFilter(newSelectedItems[0]);
        } else {
          setActiveStageFilter(undefined);
        }
      },
      idProp: 'operator',
      itemToString: (item: ActiveStageLabeledFilter) => item ? item.operator : '',
      renderItem: (item: ActiveStageLabeledFilter | null) => item ? item.label : '',
      getQueryParam: (selectedItems: ActiveStageLabeledFilter[]) =>
        selectedItems.length > 0 && {
          activeStage: {
            operator: selectedItems[0].operator,
            stageNumber: selectedItems[0].stageNumber,
          },
        },
    };
  }, [items, activeStageFilter, setActiveStageFilter]);
};

const useFinalDeadlineFilterItems = (): FinalDeadlineLabeledFilter[] => {
  const { t } = useTranslation();

  return React.useMemo(() => [
    { label: t('requests.filtering.isOn'), operator: FinalDeadlineFilterOperator.EQ },
    { label: t('requests.filtering.isBefore'), operator: FinalDeadlineFilterOperator.LT },
    { label: t('requests.filtering.isOnOrBefore'), operator: FinalDeadlineFilterOperator.LTE },
    { label: t('requests.filtering.isAfter'), operator: FinalDeadlineFilterOperator.GT },
    { label: t('requests.filtering.isOnOrAfter'), operator: FinalDeadlineFilterOperator.GTE },
    { label: t('requests.filtering.hasPassed'), operator: FinalDeadlineFilterOperator.HAS_PASSED },
    { label: t('requests.filtering.hasNotPassed'), operator: FinalDeadlineFilterOperator.HAS_NOT_PASSED },
  ], [t]);
};

export const useFinalDeadlineFilterProps = (context: string): FilterProps<FinalDeadlineLabeledFilter> => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const items = useFinalDeadlineFilterItems();
  const [finalDeadlineFilter, setFinalDeadlineFilter] =
    useLocalStorageState<FinalDeadlineLabeledFilter | undefined>({
      key: `${currentCompanyId}.${user._id}.${context}.finalDeadlineFilter`,
      defaultValue: undefined,
    });

  // @ts-ignore ts(2322) FIXME: Type '{ items: FinalDeadlineLabeledFilter[]; selectedItems: { deadline?: Date | undefined; operator: "lt" | "lte" | "eq" | "gte" | "gt" | "neq" | "has_passed" | "has_not_passed"; label: string; }[]; ... 4 more ...; getQueryParam: (selectedItems: FinalDeadlineLabeledFilter[]) => { ...; } | undefined; }' is not assignable to type 'FilterProps<FinalDeadlineLabeledFilter, FinalDeadlineLabeledFilter, Record<string, unknown>>'.
  return React.useMemo(() => {
    return {
      items,
      selectedItems: finalDeadlineFilter ? [{ ...finalDeadlineFilter }] : [],
      onChange: (newSelectedItems) => {
        if (newSelectedItems.length > 0) {
          setFinalDeadlineFilter(newSelectedItems[0]);
        } else {
          setFinalDeadlineFilter(undefined);
        }
      },
      idProp: 'operator',
      itemToString: (item: FinalDeadlineLabeledFilter) => item ? item.operator : '',
      renderItem: (item: FinalDeadlineLabeledFilter | null) => item ? item.label : '',
      getQueryParam: (selectedItems: FinalDeadlineLabeledFilter[]) => {
        const selected = selectedItems[0];
        if (selected) {
          const { operator, deadline } = selected;
          // @ts-ignore ts(2769) FIXME: No overload matches this call.
          const deadlineDayStart = startOfDay(new Date(deadline)).getTime();
          // @ts-ignore ts(2769) FIXME: No overload matches this call.
          const deadlineDayEnd = endOfDay(new Date(deadline)).getTime();
          return {
            finalDeadline: {
              operator,
              deadlineDayStart,
              deadlineDayEnd,
            },
          };
        }
      },
    };
  }, [items, finalDeadlineFilter, setFinalDeadlineFilter]);
};
