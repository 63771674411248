import * as React from 'react';
import { Text } from 'rebass/styled-components';
import { getRegionName } from '@deepstream/common';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useCurrentUserLocale } from '../useCurrentUser';

const orderedFields = ['lineOne', 'lineTwo', 'city', 'state', 'postcode', 'country'];

export const CompanyAddress: React.FC<{ value: any }> = ({ value: address }) => {
  const locale = useCurrentUserLocale();

  if (!address || !Object.values(address).some(value => !!value)) {
    return <EmDash />;
  }

  return (
    <Text>
      {orderedFields.map(field => {
        if (!address[field]) return null;

        const value = field === 'country'
          ? getRegionName(address[field], locale)
          : address[field];

        return <div key={field}>{value}</div>;
      })}
    </Text>
  );
};
