import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { DataShown, Interval, CountDate, RequestsReportingConfig } from '@deepstream/common/reporting';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { CurrencySelectFieldBase } from '../../form/CurrencySelectField';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { SelectFieldBase, getItemLabel, getItemLabelWithDescription } from '../../form/SelectField';

const useDataShownSelectItems = () => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  return React.useMemo(() => {
    return Object.values(DataShown).map(value => ({
      value,
      label: t(`request.spendAndSavings.dataShown.${value}.label`),
      description: t(`request.spendAndSavings.dataShown.${value}.description`),
      disabled: value === DataShown.ALL_REQUESTS && !isSuperUser,
    }));
  }, [t, isSuperUser]);
};

const useCountDateSelectItems = () => {
  const { t } = useTranslation('translation');
  return React.useMemo(() => {
    return Object.values(CountDate).map(value => ({
      value,
      label: t(`request.spendAndSavings.countDate.${value}`),
    }));
  }, [t]);
};

const useIntervalSelectItems = () => {
  const { t } = useTranslation('translation');
  return React.useMemo(() => {
    return Object.values(Interval).map(value => ({
      value,
      label: t(`general.${value}`),
    }));
  }, [t]);
};

export const useRequestsReportingConfig = (options: { enforceAllRequests?: boolean } = {}) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  const [dataShown, setDataShown] = useLocalStorageState<DataShown>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.dataShown`,
    defaultValue: DataShown.ALL_REQUESTS,
  });

  const [countDate, setCountDate] = useLocalStorageState<CountDate>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.countDate`,
    defaultValue: CountDate.REQUEST_CREATION,
  });

  const [interval, setInterval] = useLocalStorageState<Interval>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.interval`,
    defaultValue: Interval.MONTH,
  });

  const [currency, setCurrency] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.currency`,
    defaultValue: 'USD',
  });

  return React.useMemo(() => ({
    config: {
      // only super users are allowed to see data of all requests
      dataShown: isSuperUser ? (
        dataShown
       ) : options.enforceAllRequests ? (
        DataShown.ALL_REQUESTS
       ) : (
        DataShown.OWN_REQUESTS
      ),
      countDate,
      interval,
      currency,
    },
    setters: {
      setDataShown,
      setCountDate,
      setInterval,
      setCurrency,
    },
  }), [
    options.enforceAllRequests,
    dataShown,
    setDataShown,
    countDate,
    setCountDate,
    interval,
    setInterval,
    currency,
    setCurrency,
    isSuperUser,
  ]);
};

export const RequestsReportingConfigControls = ({
  config: {
    dataShown,
    countDate,
    interval,
    currency,
  },
  setters: {
    setDataShown,
    setCountDate,
    setInterval,
    setCurrency,
  },
  enforceAllRequests,
}: {
  config: RequestsReportingConfig;
  setters: {
    setDataShown: React.Dispatch<React.SetStateAction<DataShown>>;
    setCountDate: React.Dispatch<React.SetStateAction<CountDate>>;
    setInterval: React.Dispatch<React.SetStateAction<Interval>>;
    setCurrency: React.Dispatch<React.SetStateAction<string>>;
  };
  enforceAllRequests?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const dataShownSelectItems = useDataShownSelectItems();
  const countDateSelectItems = useCountDateSelectItems();
  const intervalSelectItems = useIntervalSelectItems();

  return (
    <Flex
      ml={4}
      sx={{ gap: 3 }}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      {!enforceAllRequests && (
        <SelectFieldBase
          data-test="data-shown-dropdown"
          label={t('request.spendAndSavings.dataShown.dataShown')}
          items={dataShownSelectItems}
          getItemLabel={getItemLabelWithDescription}
          getButtonLabel={getItemLabel}
          value={dataShown}
          onChange={setDataShown}
          menuWidth={300}
        />
      )}
      <SelectFieldBase
        data-test="count-date-dropdown"
        label={t('request.spendAndSavings.countDate.countDate')}
        items={countDateSelectItems}
        getButtonLabel={getItemLabel}
        value={countDate}
        onChange={setCountDate}
        menuWidth={200}
      />
      <SelectFieldBase
        data-test="interval-dropdown"
        label={t('request.spendAndSavings.interval')}
        items={intervalSelectItems}
        getButtonLabel={getItemLabel}
        value={interval}
        onChange={setInterval}
        menuWidth={150}
      />
      <CurrencySelectFieldBase
        data-test="currency-dropdown"
        label={t('general.currency')}
        value={currency}
        onChange={setCurrency}
      />
    </Flex>
  );
};
