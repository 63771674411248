import * as React from 'react';
import { isNil } from 'lodash';
import {
  TabPanel as ReachTabPanel,
  TabPanelProps as ReachTabPanelProps,
  useTabsContext,
} from '@reach/tabs';

type TabPanelProps = ReachTabPanelProps & {
  // As an optimization, including a `tabIndex` will result in unselected
  // tabs not rendering their children to the DOM at all (as opposed to
  // simply being hidden via the `hidden` attribute)
  tabIndex?: number | null;
  style?: React.CSSProperties;
};

export const TabPanel = ({
  tabIndex = null,
  style,
  children,
  ...props
}: TabPanelProps) => {
  const { selectedIndex } = useTabsContext();

  // This component's children are rendered if no `tabIndex` is provided (ie: we
  // don't have enough information to determine if the tab has been selected or
  // not). Otherwise, we render the children if the tab is selected.
  const shouldRenderChildren = isNil(tabIndex) || tabIndex === selectedIndex;

  return (
    <ReachTabPanel style={{ ...style, outline: 'none' }} {...props}>
      {shouldRenderChildren ? children : null}
    </ReachTabPanel>
  );
};
