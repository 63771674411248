import useOnClickOutside from 'use-onclickoutside';

const OUTSIDE_CLICK_EXCEPTED_ROLES = ['listbox', 'dialog'];

export const useOnClickOutsidePopper = (
  popperRef: React.RefObject<HTMLElement>,
  referenceElement: HTMLElement,
  onClickOutside: () => void,
) => {
  useOnClickOutside(popperRef, event => {
    const composedPath = event.composedPath();

    if (
      // We don't want to consider an event on the reference element (ie: what is
      // triggering the popover to be open) to be "clicking outside." Otherwise,
      // the popover will close on mousedown and immediately re-open on click.
      !composedPath.includes(referenceElement) &&
      // We don't want to consider an event on a select menu popup (rendered in
      // a React portal and thus 'outside' of the reference element in the DOM
      // hierarchy) to be "clicking outside".
      !composedPath.some(eventTarget => {
        if (
          !(eventTarget instanceof Element) ||
          !eventTarget.getAttribute('data-popper-placement')
        ) {
          return false;
        }

        const role = eventTarget.getAttribute('role');

        return role && OUTSIDE_CLICK_EXCEPTED_ROLES.includes(role);
      })
    ) {
      onClickOutside();
    }
  });
};
