import { BidIntentionStatus } from '@deepstream/common/rfq-utils';

const iconProps = {
  [BidIntentionStatus.DECLINED_TO_BID]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
  [BidIntentionStatus.BID_WITHDRAWN]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
  [BidIntentionStatus.BIDDING]: {
    icon: 'circle-check',
    regular: true,
    color: 'primary',
  },
} as const;

export const iconPropsByBidIntentionStatus = {
  buyer: {
    ...iconProps,
    [BidIntentionStatus.NO_RESPONSE]: {
      icon: 'circle-dashed',
      color: 'subtext',
    },
  },
  supplier: {
    ...iconProps,
    [BidIntentionStatus.NO_RESPONSE]: {
      icon: 'exclamation-circle',
      color: 'danger',
    },
  },
} as const;
