import { CellProps } from 'react-table';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import React from 'react';
import { AdminTableLink } from '../../AdminTableLink';
import { questionnaireTemplateRoute } from '../../AppRouting';

const QuestionnaireTemplateLink = ({ templateId, ...props }: { templateId: string; children: React.ReactNode }) => (
  <AdminTableLink
    to={questionnaireTemplateRoute.to}
    params={{ templateId }}
    onClick={stopPropagation}
    {...props}
  />
);

export const QuestionnaireTemplateLinkCell = ({ row, cell }: CellProps<QuestionnaireTemplateOverview>) => (
  <QuestionnaireTemplateLink templateId={row.original._id}>
    {cell.value || '(Not set)'}
  </QuestionnaireTemplateLink>
);
