import { omit, uniq } from 'lodash';
import { MessageData } from './shared-domain';

export type WsSubscription = {
  topics: string[],
  handleMessage: (message: MessageData) => void,
  notifyOnOpenConnection: boolean;
  subscriptionId: string,
};

export class WsSubscriptions {
  private items: Record<string, WsSubscription> = {};

  public add(item: WsSubscription) {
    this.items[item.subscriptionId] = item;
  }

  public remove(subscriptionId: string) {
    this.items = omit(this.items, subscriptionId);
  }

  public getAllTopics() {
    return uniq(Object.values(this.items).flatMap(item => item.topics));
  }

  public notifyMatchingItems(
    predicate: (item: WsSubscription) => boolean,
    messageData: MessageData,
  ) {
    const matchingItems = Object.values(this.items)
      .filter(predicate);

    for (const item of matchingItems) {
      item.handleMessage(messageData);
    }
  }
}
