import { clone, PropertyPath, setWith, updateWith } from 'lodash';

/**
 * Returns a new object based on `obj` in which `path` is set to `value`.
 *
 * This does not mutate `obj` or any nested objects: In the new object, nested
 * objects of `obj` affected by the update are replaced with clones;
 * references to nested objects not affected by the update are maintained.
 */
export const immutableSet = <TObject extends object>(
  obj: TObject,
  path: PropertyPath,
  value: any,
) => {
  const newObj = clone(obj);

  setWith(newObj, path, value, clone);

  return newObj;
};

/**
 * Returns a new object based on `obj` in which `path` is updated with `updater`.
 *
 * This does not mutate `obj` or any nested objects: In the new object, nested
 * objects of `obj` affected by the update are replaced with clones;
 * references to nested objects not affected by the update are maintained.
 *
 * It's the responsibility of the updater function to make sure that the property
 * at `path` doesn't get mutated.
 */
export const immutableUpdate = <TObject extends object>(
  obj: TObject,
  path: PropertyPath,
  updater: any,
) => {
  const newObj = clone(obj);

  updateWith(newObj, path, updater, clone);

  return newObj;
};
