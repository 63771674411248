import * as React from 'react';
import { isNumber, trimEnd } from 'lodash';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { isMac } from '@deepstream/ui-utils/isMac';
import { copyToClipboard, readFromClipboard } from '@deepstream/ui-utils/clipboard';
import { producesPrintableCharacter, stopEvent } from '@deepstream/ui-utils/domEvent';
import {
  DataCellProps,
  GridDataAndCommands,
  CellIndices,
  SelectedRange,
  getNavigationBoundary,
  SelectableRange,
  NavigableRange,
  getMaxSelectableEndIndices,
} from '../core/utils';
import { ComparisonGrid, ComparisonGridProps } from '../core/ComparisonGrid';
import { RenderGridDataCell } from './RenderGridDataCell';
import { useEditableGridData } from './editableGridData';
import { useGridMenuState } from './gridMenuState';
import { useGridIdPrefix } from './gridIdPrefix';
import { DEFAULT_EDITABLE_DATA_CELL_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn, GridClipboardEvent, GridClipboardEventType } from './utils';
import { EditableGridActions } from './useEditableGridActions';
import { FrozenHeaderCell } from './ReadOnlyGrid';

export const useSubmitEditedCell = () => {
  const {
    cellInputState,
    setEditedCell,
    setCellValue,
  } = useEditableGridData();

  return React.useCallback(() => {
    let updatedRowData = null;

    if (cellInputState.current) {
      const { rowId, columnId, value } = cellInputState.current;

      updatedRowData = setCellValue(rowId, columnId, value);

      cellInputState.current = null;
    }

    setEditedCell(null);

    return updatedRowData;
  }, [cellInputState, setEditedCell, setCellValue]);
};

const SubmitEditedCell = () => {
  const submitEditedCell = useSubmitEditedCell();

  React.useEffect(() => {
    submitEditedCell();
  }, [submitEditedCell]);

  return null;
};

const EditableDataCell = React.memo((props: DataCellProps<EditableGridColumn, any, any>) => {
  const {
    row,
    column,
    isActive,
    editedCell,
  } = props;

  if (!column) {
    return null;
  }

  const { InputCell, ValueCell } = column.original;

  return (
    InputCell &&
    editedCell &&
    editedCell.rowId === row.original._id &&
    editedCell.columnId === column.original._id
  ) ? isActive ? (
    <InputCell {...props} />
  ) : (
    <>
      <SubmitEditedCell />
      <ValueCell {...props} />
    </>
  ) : (
    <ValueCell {...props} />
  );
});

export type EditableGridProps = Omit<
  React.ComponentProps<typeof ComparisonGrid>,
  | 'columnData'
  | 'subRowHeight'
  | 'FirstColumnCell'
  | 'RenderDataCell'
  | 'FrozenHeaderCell'
  | 'DataCell'
  | 'staticRowHeights'
  | 'onDataCellKeyboardAction'
  | 'onActiveCellChange'
  | 'idPrefix'
  | 'onScroll'
  | 'editedCell'
  | 'contentAreaSubcolumnData'
  | 'frozenLeftSubcolumnData'
  | 'SecondRowFrozenHeaderCell'
  | 'FrozenFooterCell'
  | 'navigableRange'
> & {
  columns: EditableGridColumn[];
  gridActions?: EditableGridActions;
  onGridClipboardEvent: (clipboardEvent: GridClipboardEvent) => void;
  GridComponent?: (props: ComparisonGridProps<any, any, any>) => React.ReactElement;
  RenderDataCell?: any;
  /**
   * Should be enabled when using virtual grids.
   *
   * Should be disabled in editable grids with dynamic row heights
   * because it can cause cells to get submitted immediately when
   * trying to activate it and the height of the cell in edit mode
   * doesn't match the height in read-only mode.
   *
   * @default true
   */
  submitEditedCellOnScroll?: boolean;
  navigableRange: NavigableRange;
  subRowHeight?: number;
};

/**
 * Creates a selected range for a single cell
 */
const createSelectedRange = (
  cellIndices: CellIndices | undefined,
  selectableRange: SelectableRange,
  navigableRange: NavigableRange,
): SelectedRange | null => {
  const endColumnIndex = isNumber(selectableRange.endColumnIndex)
    ? selectableRange.endColumnIndex
    : navigableRange.endColumnIndex;

  if (
    !cellIndices ||
    cellIndices.columnIndex < selectableRange.startColumnIndex ||
    (isNumber(endColumnIndex) && cellIndices.columnIndex >= endColumnIndex)
  ) {
    return null;
  }

  return {
    start: cellIndices,
    end: {
      rowIndex: cellIndices.rowIndex + 1,
      columnIndex: cellIndices.columnIndex + 1,
    },
    reference: cellIndices,
  };
};

export const EditableGrid = ({
  columns,
  gridActions,
  onGridClipboardEvent,
  GridComponent = ComparisonGrid,
  RenderDataCell = RenderGridDataCell,
  submitEditedCellOnScroll = true,
  navigableRange,
  defaultColumnWidth = DEFAULT_EDITABLE_DATA_CELL_WIDTH,
  subRowHeight = DEFAULT_ROW_HEIGHT,
  ...props
}: EditableGridProps) => {
  const idPrefix = useGridIdPrefix();
  const { editedCell, updateEachRowWith, updateDataWith } = useEditableGridData();
  const { hideMenu } = useGridMenuState();
  const submitEditedCell = useSubmitEditedCell();

  const { rowData, selectableRange } = props;

  const handleDataCellKeyboardAction = React.useCallback((
    data: GridDataAndCommands<
      (typeof columns)[number],
      (typeof rowData)[number]['subRows'][number],
      (typeof rowData)[number]
    >,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!data.activeCellIndices) {
      return;
    }

    const column = data.columns[data.activeCellIndices.columnIndex];

    if (column?.original.toggleMenu) {
      if ([KeyCode.ENTER, KeyCode.SPACE].includes(event.code as KeyCode)) {
        stopEvent(event);

        column?.original.toggleMenu(data.activeCellIndices.rowIndex, data.activeCellIndices.columnIndex);
      }

      return;
    }

    const row = data.rows[data.activeCellIndices.rowIndex];

    if (row && column) {
      if (
        !data.editedCell &&
        selectableRange &&
        gridActions?.exportToCsv &&
        event.code === KeyCode.C &&
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey))
      ) {
        // Copy to clipboard
        const selectedRange = (
          data.selectedRange ||
          createSelectedRange(data.activeCellIndices, selectableRange, navigableRange)
        );

        if (selectedRange) {
          const { csv, cellCount } = gridActions.exportToCsv(
            data.rows as any,
            data.columns as any,
            selectedRange,
          );

          copyToClipboard(csv)
            .then(() => {
              onGridClipboardEvent({ type: GridClipboardEventType.COPY, cellCount });
            });
        }
      } else if (
        !data.editedCell &&
        selectableRange &&
        gridActions?.exportToCsv &&
        gridActions?.createClearFieldsUpdater &&
        event.code === KeyCode.X &&
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey))
      ) {
        // Copy to clipboard
        const selectedRange = (
          data.selectedRange ||
          createSelectedRange(data.activeCellIndices, selectableRange, navigableRange)
        );

        if (selectedRange) {
          const { csv, cellCount } = gridActions.exportToCsv(
            data.rows as any,
            data.columns as any,
            selectedRange,
          );

          copyToClipboard(csv)
            .then(() => {
              const affectedColumns = data.columns
                .slice(selectedRange.start.columnIndex, selectedRange.end.columnIndex);

              // subtracting -1 to convert from grid rows (which include the header row)
              // to data rows
              const startRowIndex = selectedRange.start.rowIndex - 1;
              const endRowIndex = selectedRange.end.rowIndex - 1;

              const updater = gridActions.createClearFieldsUpdater?.({
                affectedColumns,
                startRowIndex,
                endRowIndex,
              });

              if (updater) {
                updateEachRowWith(updater);
              }

              onGridClipboardEvent({ type: GridClipboardEventType.COPY, cellCount });
            });
        }
      } else if (
        !data.editedCell &&
        selectableRange &&
        gridActions?.importFromCsv &&
        event.code === KeyCode.V &&
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey))
      ) {
        const topLeftCellIndices = data.selectedRange?.start ?? data.activeCellIndices;
        // Paste from clipboard
        if (topLeftCellIndices) {
          readFromClipboard()
            .then(async (clipboardString) => {
              if (clipboardString === null) {
                onGridClipboardEvent({ type: GridClipboardEventType.PASTE_ERROR, reason: 'accessBlocked' });
              } else {
                const clipboardStringWithoutTrailingNewline = trimEnd(clipboardString, '\r\n');

                const maxSelectableEndIndices = getMaxSelectableEndIndices(
                  data.rows.length,
                  data.columns.length,
                  navigableRange,
                  selectableRange,
                );

                const importData = await gridActions.importFromCsv?.(
                  clipboardStringWithoutTrailingNewline,
                  data.rows as any,
                  data.columns as any,
                  topLeftCellIndices,
                  data.selectedRange,
                  maxSelectableEndIndices,
                );

                if (importData) {
                  const {
                    updatedRows,
                    affectedColumnCount,
                    validCellCount,
                    invalidCellCount,
                  } = importData;

                  updateDataWith(rows => {
                    const clonedRows = [...rows];
                    clonedRows.splice(
                      topLeftCellIndices.rowIndex - 1,
                      updatedRows.length,
                      ...updatedRows,
                    );

                    return clonedRows;
                  });

                  const bottomRightCellIndices = updatedRows.length > 1 || affectedColumnCount > 1
                    ? {
                      rowIndex: topLeftCellIndices.rowIndex + updatedRows.length,
                      columnIndex: topLeftCellIndices.columnIndex + affectedColumnCount,
                    }
                    : null;

                  data.activateCellAndEnsureVisibility(topLeftCellIndices, null, null, null, bottomRightCellIndices);

                  onGridClipboardEvent({ type: GridClipboardEventType.PASTE, validCellCount, invalidCellCount });
                }
              }
            })
            .catch(() => {
              onGridClipboardEvent({ type: GridClipboardEventType.PASTE_ERROR, reason: 'unknown' });
            });
        }
      } else if (
        !data.editedCell &&
        data.selectedRange &&
        gridActions?.createClearFieldsUpdater &&
        event.code === KeyCode.BACKSPACE
      ) {
        // remove selected range
        const affectedColumns = data.columns
          .slice(data.selectedRange.start.columnIndex, data.selectedRange.end.columnIndex);

        // subtracting -1 to convert from grid rows (which include the header row)
        // to data rows
        const startRowIndex = data.selectedRange.start.rowIndex - 1;
        const endRowIndex = data.selectedRange.end.rowIndex - 1;

        const updater = gridActions.createClearFieldsUpdater({
          affectedColumns,
          startRowIndex,
          endRowIndex,
        });

        updateEachRowWith(updater);
      } else if (producesPrintableCharacter(event) || event.code === KeyCode.BACKSPACE) {
        // start editing cell while sending printable characters to cell or
        // clearing the cell content
        stopEvent(event);

        column.original.startEditingCell?.(row, column, event);
      } else if (event.code === KeyCode.ENTER) {
        stopEvent(event);

        if (
          !(
            data.editedCell &&
            data.editedCell.rowId === row.original._id &&
            data.editedCell.columnId === column.original._id
          ) &&
          !data.selectedRange &&
          !event.shiftKey
        ) {
          // start editing cell
          column.original.startEditingCell?.(row, column);
        } else {
          // navigate away from cell
          const navigationBoundary = getNavigationBoundary(data, navigableRange);

          if (event.shiftKey) {
            // when in first row
            if (data.activeCellIndices.rowIndex === navigationBoundary.start.rowIndex) {
              const hasPrecedingColumn = data.activeCellIndices.columnIndex > navigationBoundary.start.columnIndex;
              // when there's a preceding column, highlight cell in last row of preceding column
              if (hasPrecedingColumn || data.selectedRange) {
                data.activateCellAndEnsureVisibility({
                  rowIndex: navigationBoundary.end.rowIndex - 1,
                  columnIndex: hasPrecedingColumn
                    ? data.activeCellIndices.columnIndex - 1
                    : navigationBoundary.end.columnIndex - 1,
                }, null, null, Boolean(data.selectedRange));
              }
            } else {
              // when not in first row, highlight cell in preceding row
              data.activateCellAndEnsureVisibility({
                rowIndex: data.activeCellIndices.rowIndex - 1,
                columnIndex: data.activeCellIndices.columnIndex,
              }, null, null, Boolean(data.selectedRange));
            }
          } else {
            // when in last row
            // eslint-disable-next-line no-lonely-if
            if (data.activeCellIndices.rowIndex === navigationBoundary.end.rowIndex - 1) {
              const hasNextColumn = data.activeCellIndices.columnIndex < navigationBoundary.end.columnIndex - 1;
              // when there's a next column, highlight cell in first row of next column
              if (hasNextColumn || data.selectedRange) {
                data.activateCellAndEnsureVisibility({
                  rowIndex: navigationBoundary.start.rowIndex,
                  columnIndex: hasNextColumn
                    ? data.activeCellIndices.columnIndex + 1
                    : navigationBoundary.start.columnIndex,
                }, null, null, Boolean(data.selectedRange));
              }
            } else {
              // when not in last row, highlight cell in next row
              data.activateCellAndEnsureVisibility({
                rowIndex: data.activeCellIndices.rowIndex + 1,
                columnIndex: data.activeCellIndices.columnIndex,
              }, null, null, Boolean(data.selectedRange));
            }
          }
        }
      } else if (event.code === KeyCode.TAB) {
        // don't stop propagation here -- we still want to move the focus
        if (
          data.editedCell &&
          data.editedCell.rowId === row.original._id &&
          data.editedCell.columnId === column.original._id
        ) {
          submitEditedCell();
        }
      }
    }
  }, [
    selectableRange,
    gridActions,
    onGridClipboardEvent,
    updateEachRowWith,
    updateDataWith,
    navigableRange,
    submitEditedCell,
  ]);

  const handleScroll = React.useCallback(() => {
    if (submitEditedCellOnScroll) {
      submitEditedCell();
    }
    hideMenu();
  }, [hideMenu, submitEditedCell, submitEditedCellOnScroll]);

  return (
    <GridComponent
      columnData={columns}
      subRowHeight={subRowHeight}
      defaultColumnWidth={defaultColumnWidth}
      FirstColumnCell={EditableDataCell}
      RenderDataCell={RenderDataCell}
      FrozenHeaderCell={FrozenHeaderCell}
      DataCell={EditableDataCell}
      staticRowHeights
      bodyPaddingLeft={0}
      bodyPaddingRight={0}
      bodyPaddingBottom={0}
      focusedCellBottomOffset={0}
      onDataCellKeyboardAction={handleDataCellKeyboardAction}
      navigableRange={navigableRange}
      onActiveCellChange={hideMenu}
      idPrefix={idPrefix}
      onScroll={handleScroll}
      editedCell={editedCell}
      {...props}
    />
  );
};
