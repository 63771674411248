/**
 * Calculates the diff factor of 2 numbers. The result * 100 is
 * the percentage difference.
 */
export const diffFactor = (initialValue: number, finalValue: number) => {
  const diff = Math.abs((finalValue - initialValue) / initialValue);

  return finalValue >= initialValue
    ? diff
    : -diff;
};
