import { Suspense } from 'react';
import { ClientThemeProvider } from '@deepstream/ui/ClientThemeProvider';
import { ReactQueryProvider } from '@deepstream/ui/ReactQueryProvider';
import { ApiProvider, AdminApiProvider } from '@deepstream/ui/api';
import { ToastProvider } from '@deepstream/ui/toast';
import { GlobalProcessingProvider } from '@deepstream/ui/GlobalProcessingProvider';
import { AdminSessionProvider } from '@deepstream/ui/auth';
import { DirectionProvider } from '@deepstream/ui/direction';
import { EnvProvider, Env } from '@deepstream/ui/env';
import { CountriesProviderWrapper } from '@deepstream/ui/CountriesProviderWrapper';
import { CurrenciesProvider } from '@deepstream/ui/ui/currencies';
import { SystemFeatureFlagsProvider } from '@deepstream/ui/systemFeatureFlags';
import { UrlProvider } from '@deepstream/ui/ui/UrlProvider';
import { getAuthProvider } from '@deepstream/ui/auth/getAuthProvider';
import { AuthBridge } from '@deepstream/ui/local-auth/AuthBridge';
import { WebSocketProvider } from '@deepstream/ui/WsProvider';
import { RedirectIfSessionRejected } from '@deepstream/ui/RedirectIfSessionRejected';
import { IntercomProvider } from 'react-use-intercom';
import { Router } from './Router';

export const App = () => {
  const env: Env = {
    ENV: process.env.NX_ENV,
    API_URL: process.env.NX_API_URL,
    HELP_CENTER_URL: process.env.NX_HELP_CENTER_URL,
    ONBOARDING_URL: process.env.NX_ONBOARDING_URL,
    AUTH0_DOMAIN: process.env.NX_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.NX_AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.NX_AUTH0_AUDIENCE,
    API_GATEWAY_ENDPOINT: process.env.NX_API_GATEWAY_ENDPOINT,
    AUTH: process.env.NX_AUTH,
    PUBLIC_PAGES_URL: process.env.NX_PUBLIC_PAGES_URL,
  };

  const AuthProvider = getAuthProvider(env.AUTH);

  // Special case route for the auth bridge -- this is used by the onboarding app to save
  // a JWT into localstorage via an iframe
  if (window.location.pathname.match('auth-bridge')) {
    return <AuthBridge />;
  }

  return (
    <EnvProvider env={env}>
      <IntercomProvider appId="N/A" shouldInitialize={false}>
        <AuthProvider>
          <ApiProvider baseUrl={env.API_URL}>
            <AdminApiProvider baseUrl={env.API_URL}>
              <UrlProvider api={env.API_URL} publicPages={env.PUBLIC_PAGES_URL}>
                <ReactQueryProvider>
                  <ClientThemeProvider>
                    <AdminSessionProvider>
                      <RedirectIfSessionRejected>
                        <SystemFeatureFlagsProvider>
                          <CountriesProviderWrapper>
                            <CurrenciesProvider>
                              <WebSocketProvider>
                                <GlobalProcessingProvider>
                                  <DirectionProvider>
                                    <ToastProvider>
                                      <Suspense fallback={null}>
                                        <Router />
                                      </Suspense>
                                    </ToastProvider>
                                  </DirectionProvider>
                                </GlobalProcessingProvider>
                              </WebSocketProvider>
                            </CurrenciesProvider>
                          </CountriesProviderWrapper>
                        </SystemFeatureFlagsProvider>
                      </RedirectIfSessionRejected>
                    </AdminSessionProvider>
                  </ClientThemeProvider>
                </ReactQueryProvider>
              </UrlProvider>
            </AdminApiProvider>
          </ApiProvider>
        </AuthProvider>
      </IntercomProvider>
    </EnvProvider>
  );
};
