import * as React from 'react';
import { transparentize } from 'polished';
import { Button, ButtonProps } from './Button';
import { useTheme } from '../../theme/ThemeProvider';

export const LinkButton: React.FC<ButtonProps> = ({ height = 24, sx = {}, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      variant="wrapper"
      type="button"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height,
        color: theme.colors.primary,
        padding: '2px 8px !important',
        cursor: 'pointer',
        transition: `
          color 300ms,
          background-color 300ms,
          border-color 300ms,
          box-shadow 300ms;
        `,
        ':focus:not(:disabled)': {
          backgroundColor: theme => transparentize(0.85, theme.colors.primary),
          boxShadow: theme => `0 0 0 ${theme.space[1]}px ${transparentize(0.85, theme.colors.primary)}`,
          outline: 'none',
        },
        ':hover:not(:disabled):not(:focus)': {
          backgroundColor: theme => transparentize(0.95, theme.colors.primary),
          boxShadow: theme => `0 0 0 ${theme.space[1]}px ${transparentize(0.95, theme.colors.primary)}`,
        },
        ...sx,
      }}
      {...props}
    />
  );
};
