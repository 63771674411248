import * as React from 'react';
import { TOriginalColumnDataBase, FrozenHeaderCellProps, ColumnData } from './utils';

const RenderFrozenFooterCellBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
>({
    data,
    index: columnIndex,
    offsetX,
  }: {
  data: {
    columns: ColumnData<TOriginalColumnData>[];
    FrozenFooterCell: React.FunctionComponent<FrozenHeaderCellProps<any>>;
  };
  index: number;
  offsetX: number;
}) => {
  const column = data.columns[columnIndex];

  const { FrozenFooterCell } = data;

  if (columnIndex === 0) {
    return null;
  }

  return (
    <div
      className="footer-cell"
      style={{
        position: 'absolute',
        top: 'calc(-1 * (var(--frozen-footer-height) + 1px))',
        left: `${column.left}px`,
        height: 'calc(var(--frozen-footer-height) + 1px)',
        width: `${column.width}px`,
      }}
    >
      <FrozenFooterCell column={column} />
    </div>
  );
};

export const RenderFrozenFooterCell =
  React.memo(RenderFrozenFooterCellBase) as typeof RenderFrozenFooterCellBase;
