import { isFunction } from 'lodash';
import * as draft from './draft';
import * as validation from './validation';

export const useCellValidationError = ({
  fieldName,
  customFieldKey,
  row,
  column,
  useShowValidationErrors = draft.useShowValidationErrors,
} : {
  fieldName: string;
  customFieldKey?: string | ((originalRow: any) => string);
  row: any;
  column: any;
  useShowValidationErrors?: () => boolean;
}) => {
  const showValidationErrors = useShowValidationErrors();

  const fieldKey = isFunction(customFieldKey)
    ? customFieldKey(row.original)
    : customFieldKey;

  const { error } = validation.useError(`${fieldName}[${row.index}].${fieldKey || column.id}`);

  return showValidationErrors ? error : undefined;
};
