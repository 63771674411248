import { Attachment } from '@deepstream/common/rfq-utils';
import { noop } from 'lodash';
import { transparentize } from 'polished';
import { useCallback } from 'react';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Card, Flex, Text, FlexProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { IconTextButton, RetryButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ErrorMessage } from './ErrorMessage';
import { ProgressBarObsolete } from './ProgressBar';
import { DownloadFn, UploadFn } from './types';
import { fakeUploadFn } from './fakeUploadFn';
import { openFilePicker } from '../uploads/openFilePicker';
import { Upload, useUploads } from '../uploads/useUploads';

type BaseFileProps = {
  downloadFn?: DownloadFn;
  isReadOnly?: boolean;
  disabled?: boolean;
  details?: React.ReactNode;
  truncateFileName?: boolean;
  hideFileNameWhileUploading?: boolean;
  small?: boolean;
  iconColor?: string;
};

type FileProps = Upload & BaseFileProps;

type FilenameProps = {
  name: string;
  color: string;
  iconColor?: string;
  truncate?: boolean;
  fontSize?: number | string;
};

const Filename = ({ name, color, iconColor, truncate, fontSize }: FilenameProps) => (
  <IconText
    icon="file-o"
    text={name}
    color={color}
    iconColor={iconColor || color}
    fontWeight="normal"
    lineHeight="normal"
    gap={2}
    ml="1px"
    fontSize={fontSize}
    iconFontSize={fontSize}
    textStyle={truncate
      ? truncateStyle
      : { overflow: 'hidden', overflowWrap: 'break-word' }
    }
  />
);

const Header = ({ small, bg = 'lightGray3', ...props }: FlexProps & { small?: boolean }) => (
  <Flex
    bg={bg}
    px={small ? '8px' : '10px'}
    py={small ? '4px' : '10px'}
    minHeight={!small ? 38 : undefined}
    sx={{ borderRadius: 'small' }}
    alignItems="center"
    justifyContent="space-between"
    {...props}
  />
);

const Content = (props: { children: React.ReactNode }) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    px="10px"
    height={30}
    {...props}
  />
);

export const File = ({
  status,
  attachment,
  file,
  progress,
  error,
  canRetry,
  retry,
  cancel,
  remove,
  downloadFn,
  isReadOnly,
  disabled,
  details,
  truncateFileName,
  hideFileNameWhileUploading,
  small,
  iconColor = 'text',
}: FileProps) => {
  const { t } = useTranslation('general');
  const fontSize = small ? 1 : 2;

  switch (status) {
    case 'uploading':
      return (
        <Panel>
          {!hideFileNameWhileUploading && (
            <>
              <Header>
                <Box flex={1}>
                  <Filename
                    name={file.name}
                    color="disabledText"
                    truncate={truncateFileName}
                  />
                </Box>
                {!isReadOnly && (
                  <Box ml={2}>
                    <IconButton icon="times" onClick={cancel} fixedWidth />
                  </Box>
                )}
              </Header>
              <PanelDivider />
            </>
          )}
          <Content>
            <Text fontSize={1} mr={2}>
              {t('uploading')}
            </Text>
            <Box flex={1}>
              <ProgressBarObsolete
                percentage={progress * 100}
                width="100%"
                height="8px"
              />
            </Box>
          </Content>
        </Panel>
      );
    case 'failed':
      return (
        <Panel>
          {!hideFileNameWhileUploading && (
            <Header>
              <Box flex={1}>
                <Filename name={file.name} color="disabledText" truncate={truncateFileName} />
              </Box>
              {!isReadOnly && (
                <Box ml={2}>
                  <IconButton icon="times" onClick={remove} fixedWidth />
                </Box>
              )}
            </Header>
          )}
          {error || (!isReadOnly && canRetry) ? (
            <>
              <PanelDivider />
              <Content>
                <ErrorMessage error={error} fontSize={1} />
                {!isReadOnly && canRetry && (
                  <RetryButton key="retry" onClick={retry} />
                )}
              </Content>
            </>
          ) : (
            null
          )}
        </Panel>
      );
    case 'completed':
      return (
        <Panel>
          <Header small={small} bg={disabled ? 'disabledBackground' : undefined}>
            <Box flex={1}>
              <Filename
                name={attachment.name}
                color={disabled ? 'disabledText' : 'text'}
                iconColor={iconColor}
                truncate={truncateFileName}
                fontSize={fontSize}
              />
            </Box>
            <Flex alignItems="center" ml={2}>
              {downloadFn && (
                <IconButton
                  key="download"
                  icon="download"
                  color="primary"
                  onClick={() => downloadFn(attachment)}
                  fontSize={fontSize}
                  fixedWidth
                />
              )}
              {!isReadOnly && (
                <IconButton key="remove" icon="times" onClick={remove} ml="12px" fixedWidth />
              )}
            </Flex>
          </Header>
          {details && (
            <>
              <PanelDivider />
              <Content>{details}</Content>
            </>
          )}
        </Panel>
      );
    case 'deleted':
      return <>{t('deleted')}: {attachment.name}</>;
    case 'unknown':
      return <>{t('unknown')}</>;
    default:
      throw new Error('Impossible state');
  }
};

export type FileListBaseProps = BaseFileProps & {
  addUploads: ReturnType<typeof useUploads>['addUploads'];
  uploads: ReturnType<typeof useUploads>['uploads'];
  limit: number;
  accept?: string;
  iconColor?: string;
  confirmRemoveFn?: (attachment: Attachment, removeFn: () => void) => void;
};

export const FileListBase = ({
  uploads,
  addUploads,
  isReadOnly,
  disabled,
  downloadFn,
  limit,
  details,
  truncateFileName,
  hideFileNameWhileUploading,
  small,
  accept,
  iconColor,
  confirmRemoveFn,
}: FileListBaseProps) => {
  const { t } = useTranslation('general');
  const { getRootProps, isDragActive } = useDropzone({ onDrop: addUploads });

  const selectFilesToUpload = useCallback(
    async ({ multiple }) => addUploads(await openFilePicker({ multiple, accept })),
    [addUploads, accept],
  );

  return (
    <Stack data-document-list-wrapper gap={2}>
      {Boolean(uploads.length) && (
        <Stack as="ul" gap={2} style={{ listStyle: 'none', padding: 0 }}>
          {uploads.map(upload => (
            <Box as="li" key={upload._id}>
              <File
                small={small}
                iconColor={iconColor}
                isReadOnly={isReadOnly}
                disabled={disabled}
                downloadFn={downloadFn}
                details={details}
                truncateFileName={truncateFileName}
                hideFileNameWhileUploading={hideFileNameWhileUploading}
                {...upload}
                remove={() => confirmRemoveFn ? confirmRemoveFn(upload.attachment, upload.remove) : upload.remove()}
              />
            </Box>
          ))}
        </Stack>
      )}
      {!isReadOnly && uploads.length < limit && (
        <Card
          sx={{
            border: isDragActive ? 'primary' : 'placeholder',
            borderRadius: 'small',
            backgroundColor: isDragActive
              ? theme => transparentize(0.9, theme.colors.primary)
              : 'inherit',
          }}
          {...getRootProps()}
        >
          <Flex alignItems="center" justifyContent="space-between" px="10px" height={38}>
            <IconText
              icon="plus"
              text={t('dropFileToUpload', { count: limit })}
              color={isDragActive ? 'primary' : 'gray'}
              gap={2}
              fontWeight={500}
            />
            {!isDragActive && (
              <Text display="flex" alignItems="center" fontSize={1} color="gray">
                {t('or')}
                <IconTextButton
                  icon="folder-open"
                  fontSize={1}
                  ml={1}
                  onClick={() => selectFilesToUpload({ multiple: limit > 1 })}
                  sx={{ display: 'inline-block', lineHeight: 'normal' }}
                >
                  {t('browse')}
                </IconTextButton>
              </Text>
            )}
          </Flex>
        </Card>
      )}
    </Stack>
  );
};

export type FileListProps = {
  /**
   * These attachments will be initialized in a completed upload state
   */
  initialAttachments?: Attachment[];
  /**
   * These files will immediately start uploading when the component mounts
   */
  initialFiles?: File[];
  /**
   * Called whenever the uploaded attachments change
   */
  onChange?: (attachments: Attachment[]) => void;
  /**
   * Called for each file when the upload has started
   */
  onUploadStart?: (file: File) => void;
  /**
   * Called each time the upload status changes (ie: when a file is uploaded or the upload finished).
   */
  onUploadStatusChange?: (isUploading: boolean) => void;
  /**
   * When `true` the list is not editable (ie: can't add/remove new files)
   */
  isReadOnly?: BaseFileProps['isReadOnly'];
  /**
   * When `true` the list items will be rendered in a disabled state (grayed out)
   */
  disabled?: BaseFileProps['disabled'];
  /**
   * Function to upload a File
   */
  uploadFn?: UploadFn;
  /**
   * Function to download an Attachment
   */
  downloadFn?: BaseFileProps['downloadFn'];
  /**
   * Max number of files in the list
   */
  limit?: number;
  /**
   * For displaying arbitrary content under each file
   */
  details?: BaseFileProps['details'];
  /**
   * Truncate the file name with ellipsis
   */
  truncateFileName?: BaseFileProps['truncateFileName'];
  /**
   * Hide the file name and show only the upload progress
   */
  hideFileNameWhileUploading?: BaseFileProps['hideFileNameWhileUploading']
  /**
   * For displaying a smaller variant of the FileList (only implemented for read only version)
   */
  small?: BaseFileProps['small'];
  /**
   * Optional comma-separated list of one or more file types (or unique file type specifiers) describing which file types to allow.
   */
  accept?: string;
  /**
   * Icon color of the file name
   */
  iconColor?: string;
  /**
   * Function to confirm the removal of an attachment.
   * @param attachment The attachment to be removed.
   */
  confirmRemoveFn?: (attachment: Attachment, removeFn: () => void) => void;
};

export const FileList = ({
  initialAttachments,
  initialFiles,
  uploadFn = fakeUploadFn,
  onChange = noop,
  onUploadStart = noop,
  onUploadStatusChange,
  isReadOnly = false,
  disabled = false,
  limit = Infinity,
  iconColor,
  ...props
}: FileListProps) => {
  const [isUploading, setIsUploading] = React.useState<boolean>();

  const { uploads, addUploads } = useUploads({
    initialAttachments,
    initialFiles,
    onUploadStart,
    onChange,
    uploadFn,
    limit,
  });

  React.useEffect(() => {
    if (onUploadStatusChange) {
      const isCurrentlyUploading = uploads.some((upload) => upload.status === 'uploading');
      if (isUploading !== isCurrentlyUploading) {
        setIsUploading(isCurrentlyUploading);
        onUploadStatusChange(isCurrentlyUploading);
      }
    }
  }, [uploads, onUploadStatusChange, isUploading]);

  return (
    <FileListBase
      uploads={uploads}
      addUploads={addUploads}
      isReadOnly={isReadOnly}
      disabled={disabled}
      limit={limit}
      iconColor={iconColor}
      {...props}
    />
  );
};
