import { useEffect, useCallback, useMemo, forwardRef } from 'react';
import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  ChartOptions,
  TimeScale,
  ChartTypeRegistry,
  TooltipModel,
  Point,
  ChartData,
  ScatterController,
  BubbleDataPoint,
} from 'chart.js';
import './chartDateFnsAdapters';
import { Chart } from 'react-chartjs-2';
import { compact } from 'lodash';
import { DateFormat, localeFormatDate, localeFormatNumber } from '@deepstream/utils';
import { Interval } from '@deepstream/common/reporting';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useUniqueId } from '../hooks/useUniqueId';
import { useTheme } from '../theme/ThemeProvider';

ChartJS.register(
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
);

const TOOLTIP_WIDTH = 310;
const CARET_HEIGHT = 10;

type TooltipContext = {
  chart: ChartJS<keyof ChartTypeRegistry, number[], unknown>;
  tooltip: TooltipModel<'scatter'>;
};

export type GetTooltipConfig<TItem> = (item: TItem) => {
  onClick: (item: TItem) => void;
  buttonText: string;
  items: {
    label: string;
    value: string;
  }[];
};

const useTooltipCallback = (getTooltipConfig) => {
  const theme = useTheme();
  const tooltipId = useUniqueId();

  useEffect(() => {
    // on unmount, remove the tooltip element
    return () => {
      const tooltipEl = document.getElementById(tooltipId);
      if (tooltipEl) {
        document.body.removeChild(tooltipEl);
      }
    };
  }, [tooltipId]);

  return useCallback((context: TooltipContext) => {
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = tooltipId;
      tooltipEl.innerHTML = '<div></div>';
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      // We can't just set the opacity to 0 because
      // pointer events on the hidden tooltip would
      // prevent us from opening the next tooltip.
      tooltipEl.style.left = '-5000px';
      return;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    const desiredLeft = position.left + window.scrollX + tooltipModel.caretX - TOOLTIP_WIDTH / 2;

    const actualLeft = Math.max(
      Math.min(desiredLeft, window.innerWidth - TOOLTIP_WIDTH - 20),
      20,
    );

    // Set tooltip content
    if (tooltipModel.body) {
      const contentWrapperEl = document.createElement('div');
      Object.assign(
        contentWrapperEl.style,
        {
          display: 'flex',
          'flex-direction': 'column',
          gap: '16px',
        },
      );

      // canvas.js returns information about multiple
      // items but we currently only render the closest
      // match
      for (const dataPoint of tooltipModel.dataPoints.slice(0, 1)) {
        const { onClick, buttonText, items } = getTooltipConfig(dataPoint.raw);

        const itemsWrapperEl = document.createElement('div');
        Object.assign(
          itemsWrapperEl.style,
          {
            display: 'flex',
            'flex-direction': 'column',
            gap: '8px',
          },
        );

        for (const item of items) {
          const itemWrapperEl = document.createElement('div');
          itemWrapperEl.style.display = 'flex';

          const labelEl = document.createElement('div');
          labelEl.style.width = '100px';
          labelEl.innerText = item.label;

          const valueEl = document.createElement('div');
          valueEl.innerText = item.value;

          itemWrapperEl.append(labelEl, valueEl);

          itemsWrapperEl.appendChild(itemWrapperEl);
        }

        const buttonEl = document.createElement('button');
        buttonEl.onclick = onClick;
        buttonEl.innerText = buttonText;
        Object.assign(
          buttonEl.style,
          {
            width: '100%',
            height: '28px',
            color: 'white',
            background: theme.colors.primary,
            'font-size': `${theme.fontSizes[1]}px`,
            cursor: 'pointer',
            border: `1px solid ${theme.colors.primary}`,
            'border-radius': '4px',
            'font-weight': 'bold',
            font: theme.fonts.primary,
          },
        );
        itemsWrapperEl.appendChild(buttonEl);

        contentWrapperEl.appendChild(itemsWrapperEl);
      }

      const caretEl = document.createElement('div');
      caretEl.id = 'caret';
      Object.assign(
        caretEl.style,
        {
          position: 'absolute',
          left: `${(TOOLTIP_WIDTH / 2) - (CARET_HEIGHT / 2) + (desiredLeft - actualLeft)}px`,
          bottom: `-${CARET_HEIGHT / 2}px`,
          width: `${CARET_HEIGHT}px`,
          height: `${CARET_HEIGHT}px`,
          transform: 'rotate(-45deg)',
          background: `${theme.colors.navy}`,
        },
      );

      const root = tooltipEl.querySelector('div');

      root!.replaceChildren();
      root!.appendChild(contentWrapperEl);
      root!.appendChild(caretEl);
    }

    // Display, position, and set styles for font
    tooltipEl.style.backgroundColor = theme.colors.navy;
    tooltipEl.style.borderRadius = '4px';
    tooltipEl.style.color = '#FFFFFF';
    tooltipEl.style.fontSize = `${theme.fontSizes[2]}px`;
    tooltipEl.style.lineHeight = '20px';
    tooltipEl.style.padding = '12px';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${actualLeft}px`;
    tooltipEl.style.width = `${TOOLTIP_WIDTH}px`;
    tooltipEl.style.top = `${position.top + window.scrollY + tooltipModel.caretY - CARET_HEIGHT - 2}px`;
    tooltipEl.style.font = theme.fonts.primary;
    tooltipEl.style.transform = 'translate(0, -100%)';
  }, [getTooltipConfig, theme.colors.navy, theme.colors.primary, theme.fontSizes, theme.fonts.primary, tooltipId]);
};

// hack: below, we add spaces characters to avoid clashing of date strings
const getXAxisTick = {
  [Interval.DAY]: (date: Date, index: number, ticks: { major: boolean }[], locale: string) => {
    const formattedDayMonth = localeFormatDate(date, DateFormat.D_MMM, { locale });
    const formattedYear = localeFormatDate(date, DateFormat.YY, { locale });

    return compact([
      formattedDayMonth,
      index === 0 || ticks[index]?.major ? formattedYear : null,
    ]);
  },
  [Interval.WEEK]: (date: Date, index: number, ticks: { major: boolean }[], locale: string) => {
    const formattedDayMonth = localeFormatDate(date, DateFormat.D_MMM, { locale });
    const formattedYear = localeFormatDate(date, DateFormat.YY, { locale });

    return compact([
      formattedDayMonth,
      index === 0 || ticks[index]?.major ? formattedYear : null,
    ]);
  },
  [Interval.MONTH]: (date: Date, index: number, ticks: { major: boolean }[], locale: string) => {
    const formattedMonth = localeFormatDate(date, DateFormat.MMM, { locale });
    const formattedYear = localeFormatDate(date, DateFormat.YY, { locale });

    return compact([
      formattedMonth,
      index === 0 || ticks[index]?.major ? formattedYear : null,
    ]);
  },
  [Interval.QUARTER_YEAR]: (date: Date, index: number, ticks: { major: boolean }[], locale: string) => {
    const formattedQuarter = localeFormatDate(date, DateFormat.QQQ, { locale });
    const formattedYear = localeFormatDate(date, DateFormat.YY, { locale });

    return compact([
      formattedQuarter,
      index === 0 || ticks[index]?.major ? formattedYear : null,
    ]);
  },
  [Interval.YEAR]: (date: Date, _index: number, _ticks: { major: boolean }[], locale: string) => {
    return localeFormatDate(date, DateFormat.YY, { locale });
  },
};

const useScatterChartOptions = ({
  startDate,
  endDate,
  interval,
  getTooltipConfig,
  locale,
}: {
  startDate: Date | string;
  endDate: Date | string;
  interval: Interval;
  getTooltipConfig: any;
  locale: string;
}) => {
  const theme = useTheme();
  const tooltipCallback = useTooltipCallback(getTooltipConfig);

  return useMemo(() => {
    // When the tick value is not an integer, return `null` to hide
    // the tick. Otherwise return a formatted string.
    const transformYAxisTick = (value: number | undefined) => Number.isInteger(value)
      ? localeFormatNumber(value!, { locale, notation: 'compact' })
      : null;

    const transformXAxisTick = (value: number | Date, index: number, ticks) => {
      return getXAxisTick[interval](new Date(value), index, ticks, locale);
    };

    return {
      // show pointer cursor when hovering a point
      onHover: (event, chartElement) => {
        (event?.native?.target as HTMLElement).style.cursor = chartElement[0]?.element instanceof PointElement
          ? 'pointer'
          : 'default';
      },
      animation: {
        duration: 300,
      },
      responsive: true,
      maintainAspectRatio: false,
      // don't cut off points at the borders of the grid
      clip: false as const,
      font: {
        size: 12,
        family: 'aktiv-grotesk, sans-serif',
      },
      interaction: {
        mode: 'point',
      },
      plugins: {
        tooltip: {
          events: ['click'],
          enabled: false,
          external: tooltipCallback,
        },
        legend: {
          display: true,
          align: 'end',
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 5,
            boxHeight: 5,
            color: theme.colors.text,
            pointStyle: 'circle',
            padding: 10,
          },
          onHover: (event) => {
            // hack: ideally, we'd only set this once, not on every hover event
            (event?.native?.target as HTMLElement).style.cursor = 'pointer';
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          type: 'time',
          min: startDate,
          max: endDate,
          time: {
            unit: interval,
          },
          position: 'bottom',
          ticks: {
            color: theme.colors.text,
            // we need rotation with years to avoid clashing of tick labels
            maxRotation: interval === Interval.YEAR ? undefined : 0,
            major: {
              enabled: true,
            },

            callback: transformXAxisTick,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: theme.colors.secondary,
            borderColor: theme.colors.secondary,
          },
          ticks: {
            color: theme.colors.text,
            callback: transformYAxisTick,
          },
        },
      },
    } as ChartOptions<'scatter'>;
  }, [tooltipCallback, theme.colors.text, theme.colors.secondary, startDate, endDate, interval, locale]);
};

export interface ScatterChartProps<TItem> {
  description: string;
  startDate: Date | string;
  endDate: Date | string;
  interval: Interval;
  data: ChartData<'scatter', (number | Point)[], unknown>;
  getTooltipConfig: GetTooltipConfig<TItem>;
  locale: string;
}

type Ref = ChartJSOrUndefined<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>;

export const ScatterChart = forwardRef<Ref, ScatterChartProps<any>>(({
  description,
  startDate,
  endDate,
  interval,
  data,
  getTooltipConfig,
  locale,
}, ref) => {
  const options = useScatterChartOptions({
    startDate,
    endDate,
    interval,
    getTooltipConfig,
    locale,
  });

  return (
    <Chart
      ref={ref}
      type="scatter"
      options={options}
      data={data}
      fallbackContent={<p>{description}</p>}
    />
  );
});
