import { isEqual, map, omit } from 'lodash';
import { diffArrayBy, hasBeenReordered } from '@deepstream/utils';
import { QuestionnaireExchangeDefinition, QuestionnaireExpiryConfig, QuestionnaireInternalData, QuestionnaireRenewalConfig, QuestionnaireRevisionPayload, QuestionnaireTemplateVersion } from './preQual';
import { getOffsetDate } from '../contract/contractDates';
import { Attachment } from '../rfq-utils';

export enum QuestionnaireTemplateChangeType {
  QUESTIONS_ADDED = 'questions-added',
  QUESTIONS_UPDATED = 'questions-updated',
  QUESTIONS_REMOVED = 'questions-removed',
  QUESTIONS_REORDERED = 'questions-reordered',
  EXPIRY_AND_RENEWAL_CONFIG_UPDATED = 'expiry-and-renewal-config-updated',
  INTERNAL_DATA_UPDATED = 'internal-data-updated',
  INSTRUCTIONS_UPDATED = 'instructions-updated',
}

type QuestionnaireTemplateChange =
  | {
    type: QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED;
    nextRenewalConfig: QuestionnaireRenewalConfig;
    nextExpiryConfig: QuestionnaireExpiryConfig;
    previousRenewalConfig: QuestionnaireRenewalConfig;
    previousExpiryConfig: QuestionnaireExpiryConfig;
  }
  | {
    type: QuestionnaireTemplateChangeType.INTERNAL_DATA_UPDATED,
    nextInternalData: QuestionnaireInternalData;
    previousInternalData: QuestionnaireInternalData;
  }
  | {
    type: QuestionnaireTemplateChangeType.INSTRUCTIONS_UPDATED,
    nextInstructions?: string;
    previousInstructions?: string;
    nextInstructionsAttachments: Attachment[];
    previousInstructionsAttachments: Attachment[];
  }
  | {
    type: QuestionnaireTemplateChangeType.QUESTIONS_ADDED,
    questions: QuestionnaireExchangeDefinition[];
  }
  | {
    type: QuestionnaireTemplateChangeType.QUESTIONS_UPDATED,
    updates: {
      question: QuestionnaireExchangeDefinition;
      /**
       * Question updates where only the version has changed are a special case and should be treated differently
       * (eg these changes by themselves don't trigger a status change or a notification).
       * This happens if the question has been updated multiple times, but the latest version is the same as the initial version.
       */
      hasOnlyQuestionVersionChanged: boolean;
    }[];
  }
  | {
    type: QuestionnaireTemplateChangeType.QUESTIONS_REMOVED,
    questions: QuestionnaireExchangeDefinition[];
  }
  | {
    type: QuestionnaireTemplateChangeType.QUESTIONS_REORDERED,
    nextQuestions: QuestionnaireExchangeDefinition[];
    previousQuestions: QuestionnaireExchangeDefinition[];
  };

const ignoredFields = ['creatorId', 'user'];

export const getQuestionnaireTemplateChanges = (
  nextVersion: QuestionnaireTemplateVersion,
  previousVersion: QuestionnaireTemplateVersion,
) => {
  const changes: QuestionnaireTemplateChange[] = [];

  if (
    !isEqual(nextVersion.renewalConfig, previousVersion.renewalConfig) ||
    !isEqual(nextVersion.expiryConfig, previousVersion.expiryConfig)
  ) {
    changes.push({
      type: QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED,
      nextRenewalConfig: nextVersion.renewalConfig,
      nextExpiryConfig: nextVersion.expiryConfig,
      previousRenewalConfig: previousVersion.renewalConfig,
      previousExpiryConfig: previousVersion.expiryConfig,
    });
  }

  if (!isEqual(nextVersion.internal, previousVersion.internal)) {
    changes.push({
      type: QuestionnaireTemplateChangeType.INTERNAL_DATA_UPDATED,
      nextInternalData: nextVersion.internal,
      previousInternalData: previousVersion.internal,
    });
  }

  if (
    !isEqual(nextVersion.instructions, previousVersion.instructions) ||
    !isEqual(nextVersion.instructionsAttachments, previousVersion.instructionsAttachments)
  ) {
    changes.push({
      type: QuestionnaireTemplateChangeType.INSTRUCTIONS_UPDATED,
      nextInstructions: nextVersion.instructions,
      nextInstructionsAttachments: nextVersion.instructionsAttachments,
      previousInstructions: previousVersion.instructions,
      previousInstructionsAttachments: previousVersion.instructionsAttachments,
    });
  }

  const nextQuestions = map(
    nextVersion.exchangeDefSequence,
    exchangeDefId => omit(nextVersion.exchangeDefById[exchangeDefId], ignoredFields),
  );
  const previousQuestions = map(
    previousVersion.exchangeDefSequence,
    exchangeDefId => omit(previousVersion.exchangeDefById[exchangeDefId], ignoredFields),
  );

  const questionsDiff = diffArrayBy(nextQuestions, previousQuestions, '_id');

  if (questionsDiff.added.length > 0) {
    changes.push({
      type: QuestionnaireTemplateChangeType.QUESTIONS_ADDED,
      questions: questionsDiff.added,
    });
  }

  if (questionsDiff.updated.length > 0) {
    changes.push({
      type: QuestionnaireTemplateChangeType.QUESTIONS_UPDATED,
      updates: questionsDiff.updated.map(question => ({
        question,
        hasOnlyQuestionVersionChanged: hasOnlyQuestionVersionChanged(question, previousVersion.exchangeDefById[question._id]),
      })),
    });
  }

  if (questionsDiff.removed.length > 0) {
    changes.push({
      type: QuestionnaireTemplateChangeType.QUESTIONS_REMOVED,
      questions: questionsDiff.removed,
    });
  }

  if (hasBeenReordered(questionsDiff)) {
    changes.push({
      type: QuestionnaireTemplateChangeType.QUESTIONS_REORDERED,
      nextQuestions: questionsDiff.next,
      previousQuestions: questionsDiff.previous,
    });
  }

  return changes;
};

export const hasOnlyQuestionVersionChanged = (
  next: QuestionnaireExchangeDefinition,
  previous: QuestionnaireExchangeDefinition,
) => (
  next.originalExchangeVersion !== previous.originalExchangeVersion &&
  isEqual(
    omit(next, ['originalExchangeVersion', 'creatorId', 'user']),
    omit(previous, ['originalExchangeVersion', 'creatorId', 'user']),
  )
);

export const getRenewalDate = (approvalDate: Date, renewalConfig: QuestionnaireRenewalConfig) => {
  if (!approvalDate || !renewalConfig?.isRecurring) {
    return null;
  }

  return getOffsetDate(approvalDate, renewalConfig.frequency);
};

export const getExpiryDate = (approvalDate: Date, expiryConfig: QuestionnaireExpiryConfig) => {
  if (!approvalDate || !expiryConfig?.doesExpire) {
    return null;
  }

  return getOffsetDate(approvalDate, expiryConfig.offset);
};

export const getQuestionnaireRevisionPayload = (
  nextTemplateVersion: QuestionnaireTemplateVersion,
  previousTemplateVersion: QuestionnaireTemplateVersion,
  newVersionNumber: number,
) => {
  const payload: QuestionnaireRevisionPayload = {
    questionnaireTemplateVersion: newVersionNumber,
    renewalConfig: undefined,
    expiryConfig: undefined,
    internal: undefined,
    instructions: undefined,
    instructionsAttachments: undefined,
    addedExchangeDefs: [],
    removedExchangeDefIds: [],
    updatedExchangeDefs: [],
    exchangeDefSequence: nextTemplateVersion.exchangeDefSequence,
    /**
     * See comment in `QuestionnaireTemplateChange` for why we need this.
     */
    updatedExchangeDefIdsWithOnlyVersionChange: [],
  };

  const changes = getQuestionnaireTemplateChanges(nextTemplateVersion, previousTemplateVersion);

  for (const change of changes) {
    switch (change.type) {
      case QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED: {
        payload.renewalConfig = change.nextRenewalConfig;
        payload.expiryConfig = change.nextExpiryConfig;
        break;
      }
      case QuestionnaireTemplateChangeType.INTERNAL_DATA_UPDATED: {
        payload.internal = change.nextInternalData;
        break;
      }
      case QuestionnaireTemplateChangeType.INSTRUCTIONS_UPDATED: {
        if (change.nextInstructions !== change.previousInstructions) {
          payload.instructions = change.nextInstructions;
        }
        if (!isEqual(change.nextInstructionsAttachments, change.previousInstructionsAttachments)) {
          payload.instructionsAttachments = change.nextInstructionsAttachments;
        }
        break;
      }
      case QuestionnaireTemplateChangeType.QUESTIONS_ADDED: {
        payload.addedExchangeDefs.push(...change.questions);
        break;
      }
      case QuestionnaireTemplateChangeType.QUESTIONS_UPDATED: {
        payload.updatedExchangeDefs.push(...change.updates.map(update => update.question));
        payload.updatedExchangeDefIdsWithOnlyVersionChange.push(
          ...change.updates
            .filter(update => update.hasOnlyQuestionVersionChanged)
            .map(update => update.question._id),
        );
        break;
      }
      case QuestionnaireTemplateChangeType.QUESTIONS_REMOVED: {
        payload.removedExchangeDefIds.push(...map(change.questions, question => question._id));
        break;
      }
      case QuestionnaireTemplateChangeType.QUESTIONS_REORDERED: {
        payload.exchangeDefSequence = map(change.nextQuestions, question => question._id);
        break;
      }
    }
  }

  return payload;
};
