export const APP_ADMIN_COMPANY_ID = '_app';
export const APP_ADMIN_LOCALE = 'en-GB';
export const APP_ADMIN_ANONYMIZED_USER_NAME = 'DeepStream admin';

export const DEEPSTREAM_CUSTOMER_SUPPORT_LOCALE = 'en-GB';

export const PUBLIC_PAGES_LOCALE = 'en-GB';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = ONE_DAY * 7;

export const AUCTION_CHAT_EXCHANGE_NAME = 'Auction chat';

export const DEFAULT_CURRENCY = 'USD';

export const DEFAULT_FEEDBACK_EMAIL_FREQUENCY = 30;
export const DEFAULT_REQUEST_MAX_COMPLEXITY = 5000;
export const DEFAULT_REQUEST_MAX_EXCHANGE_DEF_COUNT = 2500;
export const DEFAULT_CONTRACT_MAX_COMPLEXITY = 500;
export const REQUEST_MAX_SUPPLIER_COUNT = 100;

export const DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT = 50;

export const OBJECT_ID_REGEX = /^[0-9a-fA-F]{24}$/;

export const BLANK = '___blank___';
export const UNKNOWN = '___unknown___';
export const LOCKED = '___locked___';
