import * as React from 'react';
import { Box, Flex, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';

export type BadgeProps =
  BoxProps &
  { color?: string; light?: boolean; truncate?: boolean; disabled?: boolean };

export const Badge: React.FC<BadgeProps> = ({
  color = 'primary',
  light = false,
  sx,
  px = 12,
  children,
  truncate,
  disabled,
  ...props
}) => {
  const isSingleChar = String(children).length === 1;

  return (
    <Box
      sx={{
        height: 24,
        // Single characters don't have padding to ensure bg looks like a circle
        px: isSingleChar ? 0 : px as string,
        backgroundColor: disabled
          ? 'lightGray2'
          : theme => lighten(light ? 0.35 : 0, theme.colors[color]),
        borderRadius: isSingleChar ? 'circle' : 'badge',
        color: disabled ? 'gray' : light ? 'text' : 'white',
        overflowWrap: 'normal',
        ...sx,
      }}
      {...props}
    >
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Box sx={truncate ? truncateStyle : undefined}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

const NumberBadge: React.FC<BadgeProps> = ({ color = 'danger', sx = {}, ...props }) => (
  <Badge
    color={color}
    px={1}
    sx={{
      display: 'inline-block',
      fontSize: '9px',
      fontWeight: 'normal',
      minWidth: 16,
      height: 16,
      textAlign: 'center',
      ...sx,
    }}
    {...props}
  />
);

export type CounterProps = Omit<BadgeProps & { count?: number }, 'children'>;

// Renders nothing if count is 0
export const Counter: React.FC<CounterProps> = ({ count = 0, ...props }) =>
  count ? (
    <NumberBadge {...props}>
      {count}
    </NumberBadge>
  ) : (
    null
  );

export const StageBadgeBase = styled.div`
  display: inline-block;
  border: ${props => props.theme.borders.lightGray2};
  font-size: 10px;
  line-height: 0.9em;
  padding: 4px;
  border-radius: 3px;
  vertical-align: bottom;
  min-width: auto;
  margin-right: 10px;
`;

export const StageBadge = ({ index, disabled }: { index: number, disabled?: boolean }) => {
  const { t } = useTranslation();

  return (
    <StageBadgeBase color={disabled ? 'disabledText' : 'text'}>
      {t('general.stage', { count: 1 })} {index + 1}
    </StageBadgeBase>
  );
};
