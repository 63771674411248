import { css } from 'styled-components';

/**
 * Adjustments to visually center text in buttons and input components.
 */
export const centeredTextAdjustmentCss = css`
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    padding-top: 0.2em;
    padding-bottom: 3px;
  }
  @supports (-moz-appearance: none) {
    padding-top: 0;
    padding-bottom: 0.1em;
  }
`;

/**
 * Adjustments to visually center text in buttons and input components.
 */
export const centeredTextAdjustmentSx = {
  '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
    paddingTop: '0.2em',
    paddingBottom: '3px',
  },
  '@supports (-moz-appearance: none)': {
    paddingTop: 0,
    paddingBottom: '0.1em',
  },
};

/**
 * Adjustments to align text that is not centered with components
 * that got visually centered by using `centeredTextAdjustmentCss`
 * or `centeredTextAdjustmentSx`.
 */
export const textAreaAlignmentCss = css`
  padding-top: 8.5px;
  padding-bottom: 8.5px;

  @supports (-moz-appearance: none) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
