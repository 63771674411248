import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { Company } from '@deepstream/common/rfq-utils';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { useWaitForRfqUnlock } from '@deepstream/ui/useWaitForUnlock';
import * as rfx from '@deepstream/ui/rfx';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { TextField } from '@deepstream/ui/form/TextField';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { AdminApiClient } from '@deepstream/ui/adminApiClient';
import { useAdminRequestQueryKey } from './adminRequest';

const getRecipientIds = (recipients: Company[]) => recipients.map(recipient => recipient._id);

const validateCompanyId = async (api: AdminApiClient, companyId: string, recipients: Company[]) => {
  const ids = getRecipientIds(recipients);

  if (ids.includes(companyId)) {
    return 'This company is already selected.';
  }

  try {
    const exists = await api.companyIdExists({ companyId });
    if (exists) {
      return null;
    }
  } catch (error) { } // eslint-disable-line

  return 'There is no company with this ID';
};

const useUpdateRequestRecipients = (requestId) => {
  const adminApi = useAdminApi();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const adminRequestOverviewQueryKey = useAdminRequestQueryKey(requestId);

  return useMutation(
    (payload: { buyerId: string; rfqId: string; recipientIds: string[]; }) => waitForRfqUnlock({
      command: () => adminApi.updateRequestRecipients(payload),
    }),
    {
      onSuccess: () => toaster.success('Recipient was added successfully'),
      onError: () => toaster.error('An error occurred when trying to add recipient'),
      onSettled: () => queryClient.invalidateQueries(adminRequestOverviewQueryKey),
    },
  );
};

export const AddCompanyToRequestModal = ({
  onCancel,
  onSave,
  requestId,
  ...props
}: {
  onCancel: () => void;
  onSave: () => void;
  requestId: string;
} & ModalProps) => {
  const adminApi = useAdminApi();
  const { senders, recipients } = rfx.useStructure();
  const [updateRequestRecipients] = useUpdateRequestRecipients(requestId);

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          senderId: senders.length === 1 ? senders[0].company._id : '',
          recipientId: '',
        }}
        validateOnBlur
        validationSchema={
          yup.object().shape({
            senderId: yup.string().required('Required'),
            recipientId: yup.string().required('Required'),
          })
        }
        onSubmit={async ({ senderId, recipientId }, { setErrors, setSubmitting }) => {
          const error = await validateCompanyId(adminApi, recipientId, recipients);

          if (error) {
            setErrors({ recipientId: error });
            setSubmitting(false);
          } else {
            const recipientIds = [...getRecipientIds(recipients), recipientId];

            await updateRequestRecipients({
              buyerId: senderId,
              rfqId: requestId,
              recipientIds,
            }, {
              onSuccess: onSave,
              onError: onCancel,
              onSettled: () => setSubmitting(false),
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => {
          const items = senders.map(({ company }) => ({ value: company._id, label: company.name }));
          return (
            <Form>
              <ModalHeader onClose={onCancel}>
                Add company
              </ModalHeader>
              <ModalBody>
                <Stack gap={3}>
                  <SelectField
                    name="senderId"
                    label="Sender"
                    items={items}
                    placeholder="Select a company"
                    disabled={senders.length === 1}
                  />
                  <TextField
                    name="recipientId"
                    label="Recipient"
                    placeholder="Enter company ID"
                  />
                  <MessageBlock variant="info" mt={0}>
                    Added companies will receive a request invite email
                  </MessageBlock>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={onCancel} />
                <SaveButton disabled={isSubmitting || !dirty || !isValid} />
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
