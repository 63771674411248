import { diffObject } from '@deepstream/utils';
import { ChangeType, PageRole } from '@deepstream/common/rfq-utils';
import { isEmpty, keyBy, mapValues } from 'lodash';

export const getPageRoleChanges = (
  initialRoles: Record<string, PageRole>,
  submittedRoles: Record<string, PageRole>,
  companyId: string,
  userId: string,
) => {
  const permissionChanges = diffObject(submittedRoles, initialRoles);

  const rfqRoles = mapValues(keyBy(permissionChanges.updated, 'key'), 'value');

  return isEmpty(rfqRoles)
    ? []
    : [{
      type: ChangeType.TEAM_MEMBER_ROLES_UPDATED,
      userId,
      companyId,
      rfqRoles,
    }];
};
