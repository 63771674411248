import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useAdminApi } from '@deepstream/ui/api';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { cacheTypes } from '@deepstream/ui/adminApiClient';
import { values } from 'lodash';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalForm } from '@deepstream/ui/ModalForm';
import { SwitchField } from '@deepstream/ui/form/SwitchField';

const Schema = z.object({
  dryRun: z.boolean(),
});

export const ConfirmRepopulateCachesModal = ({
  isOpen,
  onCancel,
}: {
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();

  const [repopulateCaches] = useMutation(adminApi.repopulateCaches, {
    onError: () => toaster.error('Could not repopulate caches'),
    onSuccess: () => toaster.success('Repopulating caches...'),
  });

  return (
    <ModalForm
      heading="Repopulate all caches?"
      initialValues={{
        dryRun: false,
      }}
      validationSchema={toFormikValidationSchema(Schema)}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ dryRun }) => {
        await repopulateCaches({
          cacheTypes: values(cacheTypes),
          dryRun,
        });

        onCancel();
      }}
      submitLabel="Confirm"
      style={{ content: { width: '380px' } }}
      disableSubmitIfNotDirty={false}
    >
      <Stack gap={3}>
        <MessageBlock variant="warn" mt={0}>
          This will process all event-sourced models from scratch. If “Dry run” is enabled, the caches will not be updated.
        </MessageBlock>
        <SwitchField
          name="dryRun"
          text="Dry run?"
        />
      </Stack>
    </ModalForm>
  );
};
