import { useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { RoleRestrictionProfile, EntityTeamMembership, getNameOrEmail } from '@deepstream/common/user-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { isEmpty } from 'lodash';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { User } from '../../../types';
import { SelectField } from '../../../form/SelectField';
import { EntityTeamMembershipList } from './EntityTeamMembershipList';

const entityTypeToFieldName = {
  request: 'rfxReplacementUserId',
  contract: 'contractReplacementUserId',
  questionnaire: 'questionnaireReplacementUserId',
};

export const SelectSoleOwnerStep = ({
  type,
  newOwnerDescription,
  user,
  companyUsers,
  items,
}: {
  type: 'request' | 'contract' | 'questionnaire';
  newOwnerDescription: string;
  user: any;
  companyUsers: User[];
  items: EntityTeamMembership[];
}) => {
  const { t } = useTranslation(['translation', 'general', 'contracts']);
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const userItems = useMemo(
    () => companyUsers
      .filter(companyUser => (
        companyUser._id !== user._id &&
        companyUser.roleRestrictionProfiles?.[currentCompanyId] !== RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
      ))
      .map((user) => ({
        label: getNameOrEmail(user),
        value: user._id,
      })),
    [companyUsers, user._id, currentCompanyId],
  );

  return (
    <Stack gap={3}>
      <Text>
        {type === 'questionnaire' ? (
          isEmpty(items) ? t('teamManagement.soleOwnerOfSomeQuestionnaires') : t('teamManagement.soleOwnerOfQuestionnaires')
        ) : type === 'request' ? (
          isEmpty(items) ? t('teamManagement.soleOwnerOfSomeRequests') : t('teamManagement.soleOwnerOfRequests')
        ) : (
          isEmpty(items) ? t('teamManagement.soleOwnerOfSomeContracts') : t('teamManagement.soleOwnerOfContracts')
        )}
      </Text>
      {!isEmpty(items) && (
        <EntityTeamMembershipList items={items} height="212px" />
      )}
      <Text>
        {newOwnerDescription}
      </Text>
      <SelectField
        key={type} // remount component on type change
        required
        name={entityTypeToFieldName[type]}
        label={t('teamManagement.newOwner')}
        items={userItems}
        disabled={isEmpty(userItems)}
        maxHeight="215px"
      />
    </Stack>
  );
};
