import * as React from 'react';
import { map } from 'lodash';
import { Questionnaire, QUESTIONS_PAGE_ID } from '@deepstream/common/preQual';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { getPagePermissions, PageRole } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from '../../../types';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useCurrentUser } from '../../../useCurrentUser';

const QuestionnaireIdContext = React.createContext<string | null>(null);
const QuestionnaireContext = React.createContext<Questionnaire | null>(null);

export const QuestionnaireIdProvider = ({ questionnaireId, ...props }: { questionnaireId: string; children: React.ReactNode }) => (
  <QuestionnaireIdContext.Provider value={questionnaireId} {...props} />
);

export const QuestionnaireProvider = React.memo<{ questionnaire: Questionnaire; children: React.ReactNode }>(({
  questionnaire,
  ...props
}) => (
  <QuestionnaireContext.Provider value={questionnaire} {...props} />
));

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useQuestionnaireId(a: { required: true }): string;
export function useQuestionnaireId(): string;
export function useQuestionnaireId(a: { required: false }): string | null;
export function useQuestionnaireId({ required = true } = {}) {
  const questionnaireId = React.useContext(QuestionnaireIdContext);

  if (required && !questionnaireId) {
    throw new Error('A `questionnaireId` has not been set via the `QuestionnaireIdProvider` component');
  }

  return questionnaireId;
}

export const useQuestionnaireData = () => {
  const questionnaire = React.useContext<Questionnaire | null>(QuestionnaireContext);
  if (!questionnaire) throw new Error('No questionnaire context found');
  return questionnaire;
};

/*
 * Contexts and hooks for managing a questionnaire's state.
 */

type QuestionnaireStateContextType = {
  editingPanelId: string | null;
};

const QuestionnaireStateContext = React.createContext<QuestionnaireStateContextType | null>(null);

type QuestionnaireActionsContextType = {
  startEditing: (panelId: string) => void;
  stopEditing: () => void;
};

const QuestionnaireActionsContext = React.createContext<QuestionnaireActionsContextType | null>(null);

export const QuestionnaireStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [editingPanelId, setEditingPanelId] = React.useState<string | null>(null);

  const state = React.useMemo(
    () => ({
      editingPanelId,
    }),
    [editingPanelId],
  );

  const actions = React.useMemo(
    () => ({
      startEditing: (panelId: string) => {
        setEditingPanelId(panelId);
      },
      stopEditing: () => {
        setEditingPanelId(null);
      },
    }),
    [],
  );

  return (
    <QuestionnaireStateContext.Provider value={state}>
      <QuestionnaireActionsContext.Provider value={actions}>
        {children}
      </QuestionnaireActionsContext.Provider>
    </QuestionnaireStateContext.Provider>
  );
};

// @ts-ignore ts(2394) FIXME: This overload signature is not compatible with its implementation signature.
export function useQuestionnaireState(): QuestionnaireStateContextType | undefined;
export function useQuestionnaireState(a: { required: true }): QuestionnaireStateContextType;
export function useQuestionnaireState(a: { required: false }): QuestionnaireStateContextType | undefined;
export function useQuestionnaireState({ required = true } = {}) {
  const state = React.useContext(QuestionnaireStateContext);

  if (required && !state) throw new Error('No questionnaire state found');

  return state || {};
}

export const useQuestionnaireActions = () => {
  const actions = React.useContext(QuestionnaireActionsContext);

  if (!actions) throw new Error('No questionnaire actions found');

  return actions;
};

export const useQuestionnaireExchanges = () => {
  const { exchangeById, exchangeDefSequence } = useQuestionnaireData();
  return map(exchangeDefSequence, id => exchangeById[id]);
};

export const useSummary = () => {
  const { summary } = useQuestionnaireData();

  return summary;
};

export const useExchangeDefById = () => {
  const { exchangeDefById } = useQuestionnaireData();

  return exchangeDefById;
};

export const useExchanges = () => {
  const questionnaire = useQuestionnaireData();

  return React.useMemo(() => {
    return map(
      questionnaire.exchangeDefSequence,
      exchangeDefId => questionnaire.exchangeById[exchangeDefId] as ExchangeSnapshot,
    );
  }, [questionnaire]);
};

export const useTeam = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { teamById } = useQuestionnaireData();

  return React.useMemo(
    () => teamById[currentCompanyId],
    [teamById, currentCompanyId],
  );
};

export const useQuestionnairePermissions = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const team = useTeam();
  const isRecipient = useIsRecipient();
  const isSender = useIsSender();

  return React.useMemo(() => {
    const isSuperUserOrOwner = (
      isSuperUser ||
      team.owners.includes(currentUser._id)
    );

    return {
      canAcceptOrDecline: isRecipient && isSuperUserOrOwner,
      canSubmitDecision: isSender && isSuperUserOrOwner,
      canEditTeam: isSuperUserOrOwner,
      canEditSupplierUsers: isSender && isSuperUserOrOwner,
      canSubmitQuestionnaire: isRecipient && isSuperUserOrOwner,
      canUpdateQuestionnaire: isRecipient && isSuperUserOrOwner,
      canReopenQuestionnaire: isSender && isSuperUserOrOwner,
      canMarkAsExpired: isSender && isSuperUserOrOwner,
      canMarkAsObsolete: isSender && isSuperUserOrOwner,
    };
  }, [currentUser, team.owners, isSuperUser, isRecipient, isSender]);
};

export const usePagePermissions = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const team = useTeam();

  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const user = team.users[currentUser._id];
  const isTeamMember = Boolean(user);

  return React.useMemo(
    () => {
      if (isSuperUser) {
        return getPagePermissions(PageRole.RESPONDER);
      } else if (!isTeamMember) {
        return getPagePermissions(PageRole.NONE);
      } else {
        const pageRole = user.roles[QUESTIONS_PAGE_ID];
        return getPagePermissions(pageRole);
      }
    },
    [isTeamMember, isSuperUser, user],
  );
};

export const useIsRecipient = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const questionnaire = useQuestionnaireData();

  return questionnaire.recipient._id === currentCompanyId;
};

export const useIsSender = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const questionnaire = useQuestionnaireData();

  return questionnaire.senderIds.includes(currentCompanyId);
};

export const useStatus = () => {
  const { status } = useQuestionnaireData();

  return status;
};

export const useIsSuperUserOrOwner = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const { teamById } = useQuestionnaireData();

  return isSuperUser || teamById[currentCompanyId].owners.includes(currentUser._id);
};
