import { useCurrentCompanyId } from './currentCompanyId';
import { CompanyFeatureFlags } from './ui/types';
import { useCurrentUser } from './useCurrentUser';

export const useCompanyFeatureFlags = (): CompanyFeatureFlags => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const companyFeatureFlags = currentUser?.featureFlags[currentCompanyId];

  return companyFeatureFlags ?? {};
};
