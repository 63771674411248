import { useState, useMemo, useCallback } from 'react';
import { last } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import * as rfx from '@deepstream/ui/rfx';
import { AuctionBid, Company, getRecipientsWithAuctionAccess } from '@deepstream/common/rfq-utils';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { CurrencyAmount, CurrencyCodeProvider } from '@deepstream/ui/ui/Currency';
import { CurrencyAmountCell } from '@deepstream/ui/ui/CurrencyAmountCell';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CellProps } from 'react-table';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useRfqId } from '@deepstream/ui/useRfq';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { callAll } from '@deepstream/utils/callAll';
import { useQueryClient } from 'react-query';
import { useMutation } from '@deepstream/ui/useMutation';
import { DescriptionList, InlineDescriptionItem } from '@deepstream/ui/ui/DescriptionList';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useTranslation } from 'react-i18next';
import { assertDefined } from '@deepstream/utils';
import { useAdminRequestQueryKey } from './adminRequest';
import { useAdminNavigation } from './AppRouting';

type SelectedBid = {
  bidderId: string;
  currentBid: AuctionBid | undefined;
  previousBid: AuctionBid | undefined;
};

const BidDescriptionItem = withProps(
  InlineDescriptionItem,
  {
    alignItems: 'center',
    height: '21px',
    termProps: {
      style: { marginTop: '5px' },
      width: '90px',
      color: 'text',
    },
  },
);

export const AuctionRecipientsTable = () => {
  const { t } = useTranslation();
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const rfqId = useRfqId();
  const rfxStructure = rfx.useStructure();
  const toaster = useToaster();
  const navigation = useAdminNavigation();
  const queryKey = useAdminRequestQueryKey(rfqId);
  const [selectedBids, setSelectedBids] = useState<SelectedBid | null>(null);

  const confirmationDialog = useModalState();

  const recipientsWithAuctionAccess = useMemo(
    () => rfxStructure ? getRecipientsWithAuctionAccess(rfxStructure) : [],
    [rfxStructure],
  );

  const { auction } = rfxStructure;
  const firstLot = auction.lots[0];
  const { bidsByBidderId } = firstLot;

  const [requestAuctionLotBidRetraction, { isLoading }] = useMutation(
    adminApi.requestAuctionLotBidRetraction,
    {
      onSuccess: () => toaster.success('Current bid retracted successfully'),
      onError: () => toaster.error('Current bid could not be retracted'),
      onSettled: callAll(
        confirmationDialog.close,
        () => queryClient.invalidateQueries(queryKey),
      ),
    },
  );

  const handleConfirmation = useCallback(() => {
    if (selectedBids?.currentBid) {
      return requestAuctionLotBidRetraction({
        rfqId,
        auctionId: auction._id,
        lotId: firstLot._id,
        bidderId: selectedBids.currentBid.bidderId,
        price: selectedBids.currentBid.price,
      });
    }
  }, [selectedBids, rfqId, auction, firstLot, requestAuctionLotBidRetraction]);

  const canRetractBids = (
    !isLoading &&
    ['pending', 'paused'].includes(auction.status)
  );

  const openRetractBidDialog = useCallback((bidderId: string) => {
    const bids = bidsByBidderId[bidderId];

    setSelectedBids({
      bidderId,
      currentBid: bids?.[bids.length - 1],
      previousBid: bids?.[bids.length - 2],
    });

    confirmationDialog.open();
  }, [setSelectedBids, bidsByBidderId, confirmationDialog]);

  const columns = useMemo(
    () => {
      const { bidderIds } = auction;
      assertDefined(bidderIds, 'bidderIds');

      return [
        {
          Header: 'Name',
          accessor: 'company.name',
          Cell: CompanyMinimizedNameAndLogoCell,
        },
        {
          id: 'agreementStatus',
          Header: t('request.auction.bidderAgreementStatus.header'),
          accessor: ({ _id }: Company) => bidderIds.includes(_id)
            ? t('request.auction.bidderAgreementStatus.agreed')
            : t('request.auction.bidderAgreementStatus.pending'),
          Cell: ValueOrDashCell,
        },
        {
          id: 'bid',
          Header: 'Current bid total',
          accessor: ({ _id }: Company) => {
            const latestBid = last(bidsByBidderId[_id]);

            return latestBid ? latestBid.price : undefined;
          },
          Cell: CurrencyAmountCell,
        },
        {
          id: 'datetime',
          Header: 'Current bid submitted on',
          accessor: ({ _id }: Company) => {
            const latestBid = last(bidsByBidderId[_id]);

            return latestBid ? latestBid.date : undefined;
          },
          Cell: nestCells(ValueOrDashCell, DatetimeCell),
        },
        {
          id: 'actions',
          accessor: '_id',
          Cell: ({ cell: { value: bidderId } }: CellProps<Company, string>) => {
            const latestBid = last(bidsByBidderId[bidderId]);

            return canRetractBids && latestBid
              ? (
                <Button
                  small
                  variant="primary-outline"
                  iconLeft="close"
                  onClick={(event) => {
                    event.stopPropagation();
                    openRetractBidDialog(bidderId);
                  }}
                >
                  Retract current bid
                </Button>
              ) : null;
          },
          textAlign: 'right',
          width: 200,
        },
      ];
    },
    [auction, bidsByBidderId, canRetractBids, openRetractBidDialog, t],
  );

  return (
    <CurrencyCodeProvider code={firstLot.rules.currency}>
      <BasicTableStyles>
        <Table
          hasStaticHeight={false}
          columns={columns}
          data={recipientsWithAuctionAccess}
          onRowClick={recipient => navigation.navigateToRequestCompany(rfqId, recipient._id)}
        />
      </BasicTableStyles>
      <Dialog
        heading="Retract current bid"
        body={(
          <Stack gap={3} maxWidth="470px">
            {selectedBids && (
              <DescriptionList gap="1px">
                <BidDescriptionItem term="Supplier">
                  {recipientsWithAuctionAccess.find(
                    recipient => recipient._id === selectedBids.bidderId,
                  )?.company.name}
                </BidDescriptionItem>
                <BidDescriptionItem term="Current bid">
                  <CurrencyAmount value={selectedBids.currentBid?.price} />
                </BidDescriptionItem>
                <BidDescriptionItem term="Previous bid">
                  <CurrencyAmount value={selectedBids.previousBid?.price} />
                </BidDescriptionItem>
              </DescriptionList>
            )}
            <MessageBlock variant="warn" mt={0}>
              This will retract the supplier’s current bid and their previous
              bid will become their current bid. This cannot be undone.
            </MessageBlock>
          </Stack>
        )}
        okButtonText="Retract current bid"
        okButtonVariant="primary"
        cancelButtonText="Cancel"
        cancelButtonVariant="secondary"
        isOpen={confirmationDialog.isOpen}
        onOk={handleConfirmation}
        onCancel={confirmationDialog.close}
      />
    </CurrencyCodeProvider>
  );
};
