import * as React from 'react';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const ValueOrDashCell: React.FC<
  CellProps<any>
> = ({ cell: { value }, row: { original: exchange }, column: { EmptyCell = EmDash } }: any) => (
  <>
    {value || <EmptyCell exchange={exchange} />}
  </>
);

export const ValueOrDashCellWithTooltip: React.FC<
  CellProps<any> & { column: { showTooltip?: boolean; truncate?: boolean; EmptyCell } }
> = ({ cell: { value }, row, column: { EmptyCell = EmDash, truncate, showTooltip }, sx }) => {
  const Wrapper = truncate ? Truncate : Box;

  return value ? (
    <Tooltip content={showTooltip ? value : null}>
      <Wrapper sx={sx}>
        {value}
      </Wrapper>
    </Tooltip>
  ) : (
    <EmptyCell exchange={row.original} truncate={truncate} showTooltip={showTooltip} />
  );
};
