import { useMemo } from 'react';
import { Box } from 'rebass/styled-components';

import { Table } from '@deepstream/ui/Table';
import { useToaster } from '@deepstream/ui/toast';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { useQueryClient } from 'react-query';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { DeleteMenuItem, DropdownMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { useConfirmDialog, useModalState } from '@deepstream/ui/ui/useModalState';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';

import { AddOrEditSubscriptionModal } from './AddOrEditSubscriptionModal';
import { YesNoCell } from './YesNoCell';

const SubscriptionActions = ({ subscription, externalSystemId }) => {
  const toaster = useToaster();
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const editSubscriptionModal = useModalState();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const [deleteSubscription] = useMutation(
    adminApi.deleteSubscription,
    {
      onSuccess: () => toaster.success('Subscription deleted'),
      onError: () => toaster.error('Subscription could not be deleted'),
      onSettled: () => queryClient.invalidateQueries(['externalSystem', { externalSystemId }]),
    },
  );

  return (
    <>
      <Box display="inline-block">
        <ButtonGroup marginBetween="-1px">
          <EditButton small onClick={() => editSubscriptionModal.open()} />
          <DropdownMenu
            small
            rightAligned
            variant="primary-outline"
            iconLeft="ellipsis-h"
            menuZIndex={10}
          >
            <DeleteMenuItem
              onSelect={() => {
                confirm(() => {
                  deleteSubscription({ _id: subscription._id, externalSystemId });
                });
              }}
            />
          </DropdownMenu>
        </ButtonGroup>
      </Box>
      <AddOrEditSubscriptionModal
        externalSystemId={externalSystemId}
        subscription={subscription}
        isOpen={editSubscriptionModal.isOpen}
        onCancel={editSubscriptionModal.close}
        onSave={editSubscriptionModal.close}
        onRequestClose={editSubscriptionModal.close}
      />
      <ConfirmDeleteDialog
        heading="Delete subscription?"
        message="This cannot be undone"
        {...dialogProps}
      />
    </>
  );
};

export const ExternalSystemSubscriptionsTable = ({ externalSystemId, subscriptions }: { externalSystemId: string, subscriptions: [] }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TruncateCell,
      },
      {
        Header: 'System Event Type',
        accessor: 'systemEventType',
        Cell: TruncateCell,
      },
      {
        Header: 'Webhook URL',
        accessor: 'webhook.url',
        Cell: TruncateCell,
      },
      {
        Header: 'Webhook API Key',
        accessor: 'webhook.apiKey',
        Cell: TruncateCell,
      },
      {
        Header: 'Can receive notifications?',
        accessor: 'receiveNotifications',
        Cell: YesNoCell,
      },
      {
        id: 'actions',
        accessor: '_id',
        Cell: ({ row }) => (
          <SubscriptionActions subscription={row.original} externalSystemId={externalSystemId} />
        ),
        textAlign: 'right',
        width: 200,
        disableSortBy: true,
      },
    ],
    [externalSystemId],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={subscriptions}
        smallPageControls
      />
    </StaticTableStyles>
  );
};
