import * as React from 'react';
import { compact } from 'lodash';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction, Skip } from '@deepstream/ui/PropertyList';
import { useApi } from '@deepstream/ui/api';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useMutation } from '@deepstream/ui/useMutation';
import { CopyUserMenu } from './CopyUserMenu';
import { EditEmailModal } from './EditEmailModal';
import { EditPhoneNumberModal } from './EditPhoneNumberModal';
import { EditNameModal } from './EditNameModal';

export const UserProfilePropertyList: React.FC<{ user: any }> = ({ user }) => {
  const api = useApi();
  const toaster = useToaster();
  const editEmailModal = useModalState();
  const editNameModal = useModalState();
  const editPhoneNumberModal = useModalState();
  const passwordResetModal = useModalState();

  const profileProperties = React.useMemo(
    () => {
      if (!user) return [];

      return compact([
        { name: 'First name', value: user.firstName },
        { name: 'Last name', value: user.lastName },
        { name: 'Phone number', value: user.phoneNumber },
        { name: 'Email', value: user.email, Component: withProps(CopyUserMenu, { user }) },
        user.emailPending ? { name: 'Pending email', value: user.emailPending } : null,
        { name: 'Password', value: '●●●●●●●●●' },
      ]);
    },
    [user],
  );

  const [sendResetPasswordEmail] = useMutation(api.sendResetPasswordEmail);

  const requestPasswordReset = React.useCallback(
    async () => {
      try {
        await sendResetPasswordEmail({ email: user.email });
        toaster.success('Successfully sent password reset email');
        passwordResetModal.close();
      } catch (error) {
        toaster.error('An error occurred while sending the password reset email');
        passwordResetModal.close();
        throw error;
      }
    },
    [passwordResetModal, toaster, sendResetPasswordEmail, user],
  );

  return (
    <>
      <PropertyList properties={profileProperties}>
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editNameModal.open} rows={2} />
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editPhoneNumberModal.open} />
        <PropertyListAction
          label="Edit"
          icon="pencil-alt"
          onClick={editEmailModal.open}
          disabled={user.auth0IsFederated}
        />
        {user.emailPending ? <Skip rows={1} /> : <Skip rows={0} />}
        <PropertyListAction
          label="Reset"
          icon="paper-plane"
          onClick={passwordResetModal.open}
          disabled={user.auth0IsFederated}
        />
      </PropertyList>
      <Dialog
        heading="Send password reset email"
        body={
          <MessageBlock variant="info" m={0}>
            Only send this email when the user is expecting it
          </MessageBlock>
        }
        okButtonText="Send"
        isOpen={passwordResetModal.isOpen}
        onOk={requestPasswordReset}
        onCancel={passwordResetModal.close}
      />
      <EditNameModal
        onSave={editNameModal.close}
        onCancel={editNameModal.close}
        isOpen={editNameModal.isOpen}
        user={user}
      />
      <EditPhoneNumberModal
        onSave={editPhoneNumberModal.close}
        onCancel={editPhoneNumberModal.close}
        isOpen={editPhoneNumberModal.isOpen}
        user={user}
      />
      <EditEmailModal
        onSave={editEmailModal.close}
        onCancel={editEmailModal.close}
        isOpen={editEmailModal.isOpen}
        user={user}
      />
    </>
  );
};
