import * as React from 'react';

import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';

type IntegrationDataModalProps = {
  data: Record<string, { externalId: string }>[];
  onClose: () => void;
} & Omit<ModalProps, 'data'>;

export const IntegrationDataModal: React.FC<IntegrationDataModalProps> = ({
  data,
  onClose,
  ...props
}) => {
  const lineItemData = Object.entries(data)
    .map(([lineItemId, { _id }]) => ({ lineItemId, externalId: _id }));

  const columns = React.useMemo(
    () => [
      {
        Header: 'Line item ID',
        accessor: 'lineItemId',
      },
      {
        Header: 'External line item ID',
        accessor: 'externalId',
      },
    ],
    [],
  );

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{ content: { maxWidth: '700px' } }}
      {...props}
    >
      <ModalHeader onClose={onClose}>
        Integration Data
      </ModalHeader>
      <ModalBody>
        <StaticTableStyles>
          <Table
            columns={columns}
            data={lineItemData}
          />
        </StaticTableStyles>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Dismiss</Button>
      </ModalFooter>
    </Modal>
  );
};
