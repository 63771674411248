import * as React from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';

type CollapsibleHeaderContextValue = {
  isCollapsed: boolean;
  translatePx: number;
  toggleCollapsed: () => void;
  setTranslatePx?: (px: number) => void;
};

export const CollapsibleHeaderContext = createContext<CollapsibleHeaderContextValue>({
  isCollapsed: false,
  translatePx: 0,
  toggleCollapsed: noop,
  setTranslatePx: noop,
});

export const CollapsibleHeaderContextProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [translatePx, setTranslatePx] = useState(0);

  const toggleCollapsed = useCallback(
    () => setIsCollapsed((prev) => !prev),
    [],
  );

  const value = useMemo(
    () => ({
      isCollapsed,
      translatePx,
      toggleCollapsed,
      setTranslatePx,
    }),
    [isCollapsed, translatePx, toggleCollapsed],
  );

  return (
    <CollapsibleHeaderContext.Provider value={value}>
      {children}
    </CollapsibleHeaderContext.Provider>
  );
};

export const useCollapsibleHeader = () => {
  return useContext(CollapsibleHeaderContext);
};

export const CollapsibleHeaderContent = ({ children }: { children: React.ReactNode }) => {
  const {
    isCollapsed,
    translatePx,
  } = useContext(CollapsibleHeaderContext);

  return (
    <div
      style={{
        transform: isCollapsed ? `translateY(${translatePx}px)` : undefined,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {children}
    </div>
  );
};
