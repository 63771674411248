const DeepStreamLogo = ({ variant }: { variant: 'white' | 'black' }) => {
  const color = variant === 'black' ? '#111010' : '#ffffff';

  return (
    <svg width="134" height="25" viewBox="0 0 134 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7967 3.83868C29.1371 3.83868 32.0348 6.41493 32.0348 11.4456C32.0348 16.4762 29.1366 19.053 24.7967 19.053H19.0178V3.83868H24.7967ZM24.5025 17.0843C27.7765 17.0843 29.6475 15.135 29.6475 11.4488C29.6475 7.76262 27.7765 5.8079 24.5025 5.8079H21.3617V17.0843H24.5025Z" fill={color} />
      <path d="M43.5155 15.7801C43.0024 17.3999 41.637 19.3085 38.3308 19.3085C35.1669 19.3085 33.0388 16.9469 33.0388 13.4491C33.0388 9.91111 35.0853 7.55063 38.3099 7.55063C41.5758 7.55063 43.8714 9.93151 43.5816 13.9531H35.2383C35.3022 16.0028 36.4658 17.5491 38.3308 17.5491C40.277 17.5491 41.0359 16.3147 41.3171 15.5096L43.5155 15.7801ZM38.3094 9.3116C36.4244 9.3116 35.4487 10.7334 35.2791 12.2947H41.381C41.2114 10.7334 40.2362 9.3116 38.3099 9.3116H38.3094Z" fill={color} />
      <path d="M55.1139 15.7801C54.6008 17.3999 53.2354 19.3085 49.9287 19.3085C46.7653 19.3085 44.6372 16.9469 44.6372 13.4491C44.6372 9.91111 46.6837 7.55063 49.9083 7.55063C53.1742 7.55063 55.4698 9.93151 55.1799 13.9531H46.8367C46.9005 16.0028 48.0641 17.5491 49.9287 17.5491C51.8754 17.5491 52.6343 16.3147 52.9155 15.5096L55.1139 15.7801ZM49.9078 9.3116C48.0228 9.3116 47.0471 10.7334 46.8775 12.2947H52.9794C52.8098 10.7334 51.8346 9.3116 49.9083 9.3116H49.9078Z" fill={color} />
      <path d="M62.0983 19.3085C60.5381 19.3085 59.3283 18.7224 58.7111 17.744H58.6279V22.9737H56.4504V7.80611H58.5232V9.25954H58.6097C59.2274 8.17859 60.4984 7.55063 62.1015 7.55063C65.0819 7.55063 67.2095 9.89072 67.2095 13.4293C67.2095 16.9679 65.0792 19.3085 62.0983 19.3085ZM58.4819 13.4293C58.4819 15.9825 59.7539 17.547 61.7226 17.547C63.7181 17.547 64.9875 15.9825 64.9875 13.4293C64.9875 10.8761 63.7181 9.31106 61.7226 9.31106C59.7539 9.3116 58.4819 10.8761 58.4819 13.4293Z" fill={color} />
      <path d="M77.9313 8.22311C77.5272 6.30918 76.1263 5.38549 74.2049 5.38549C72.2668 5.38549 71.0646 6.3768 71.0646 7.75241C71.0646 9.05717 72.1096 9.49406 73.4852 9.81662L76.1049 10.4177C78.4176 10.9883 80.6369 11.5985 80.6369 14.9203C80.6369 17.898 77.985 19.39 74.733 19.39C71.042 19.39 68.6853 17.6253 68.147 14.3664L70.4736 14.1345C70.8021 16.6861 72.6785 17.5024 74.7744 17.5024C76.8316 17.5024 78.2517 16.5551 78.2517 14.9831C78.2517 13.3767 77.0549 12.9784 75.4522 12.5941L72.6715 11.9136C70.6856 11.4236 68.6746 10.6314 68.6746 7.81521C68.6746 5.18529 71.0694 3.5 74.2232 3.5C77.5701 3.5 79.5334 5.16382 80.2725 8.03687L77.9313 8.22311Z" fill={color} />
      <path d="M90.0708 9.51124H90.154C90.7101 8.42331 91.893 7.55115 93.4967 7.55115C93.8318 7.55223 94.1658 7.58821 94.4934 7.65849V9.75169C94.1283 9.6979 93.76 9.66974 93.391 9.66742C91.5479 9.66742 90.175 10.5659 90.175 13.0826V19.053H87.998V7.80609H90.0703L90.0708 9.51124Z" fill={color} />
      <path d="M104.948 15.7801C104.435 17.3999 103.07 19.3085 99.763 19.3085C96.5963 19.3085 94.4709 16.9469 94.4709 13.4491C94.4709 9.91111 96.5174 7.55063 99.7426 7.55063C103.008 7.55063 105.304 9.93151 105.014 13.9531H96.6704C96.7343 16.0028 97.8979 17.5491 99.763 17.5491C101.71 17.5491 102.468 16.3147 102.75 15.5096L104.948 15.7801ZM99.742 9.3116C97.8571 9.3116 96.8813 10.7334 96.7123 12.2947H102.814C102.644 10.7334 101.668 9.3116 99.7426 9.3116H99.742Z" fill={color} />
      <path d="M115.795 17.3554C115.971 17.3551 116.145 17.3261 116.311 17.2695V19.0525C116.122 19.1163 115.596 19.1598 115.173 19.1598C114.121 19.1598 113.406 18.7889 113.182 17.8035H113.098C112.326 18.8845 110.781 19.309 109.502 19.309C107.548 19.309 105.893 18.0305 105.893 15.9481C105.893 13.5141 107.575 12.6301 110.512 12.3301C112.551 12.1116 113.081 11.7354 113.081 11.0259C113.081 10.027 112.476 9.33251 110.864 9.33251C109.751 9.33251 108.676 9.90304 108.288 11.2271L106.299 10.7655C106.771 8.75178 108.58 7.55115 110.929 7.55115C114.128 7.55115 115.259 9.10065 115.259 10.9835V16.6893C115.259 17.2475 115.515 17.3554 115.795 17.3554V17.3554ZM113.081 13.2763C112.74 13.6617 111.477 13.8522 110.283 14.003C109.15 14.1544 108.113 14.7072 108.113 15.9443C108.113 16.9802 108.803 17.5856 109.921 17.5856C111.33 17.5856 113.081 16.7204 113.081 14.6149V13.2763Z" fill={color} />
      <path d="M129.969 7.55008C131.853 7.55008 133.735 8.75072 133.735 11.5932V19.0535H131.558V12.2485C131.558 10.1312 130.73 9.37116 129.265 9.37116C127.959 9.37116 126.708 10.5519 126.708 12.8598V19.053H124.531V12.2485C124.531 10.1306 123.703 9.37116 122.238 9.37116C120.929 9.37116 119.681 10.5595 119.681 12.8673V19.053H117.504V7.80609H119.576V9.3089H119.66C120.519 8.06103 121.693 7.55169 122.942 7.55169C124.23 7.55169 125.527 8.11202 126.204 9.39424C127.209 8.18502 128.321 7.55169 129.969 7.55169L129.969 7.55008Z" fill={color} />
      <path d="M86.5188 9.44202V7.80611H84.0564V5.70325H81.8816V15.1833C81.8827 16.2093 82.2908 17.1929 83.0163 17.9183C83.7417 18.6438 84.7254 19.0519 85.7513 19.053H86.4228V16.8756H85.7513C85.3026 16.8751 84.8723 16.6967 84.555 16.3795C84.2376 16.0622 84.0591 15.632 84.0585 15.1833V9.44041L86.5188 9.44202Z" fill={color} />
      <path d="M15.2138 8.40293H8.74794V3.83868H6.46582V10.685H15.2138V8.40293Z" fill={color} />
      <path d="M0 14.4888H6.46583V19.053H8.74795V12.2066H0V14.4888Z" fill={color} />
    </svg>
  );
};

export default DeepStreamLogo;
