import { Draft, ExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { EditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';

export const QuestionResponseGridMenu = ({
  columns,
  gridActions,
}: {
  columns: EditableGridColumn[];
  gridActions: EditableGridActions;
}) => {
  const { t } = useTranslation('translation');
  const { dataIndex } = useGridMenuState();
  const {
    rowData: draftRows,
    minRows,
    updateEachRowWith,
    moveRow,
    removeRow,
  } = useEditableGridData<ExchangeDefinition<Draft>>();

  // @ts-ignore ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const draftRow = draftRows[dataIndex];

  return draftRow ? (
    <GridMenu
      popoverProps={{
        'data-test': 'more-actions-menu',
      }}
    >
      <GridMenuItem
        icon="arrow-up"
        // @ts-ignore ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, -1)}
        disabled={dataIndex === 0}
      >
        {t('general.moveUp')}
      </GridMenuItem>

      <GridMenuItem
        icon="arrow-down"
        // @ts-ignore ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, +1)}
        disabled={dataIndex === draftRows.length - 1}
      >
        {t('general.moveDown')}
      </GridMenuItem>

      <GridMenuItem
        icon="undo"
        onSelect={() => {
          const updater = gridActions.createClearFieldsUpdater?.({
            affectedColumns: columns
              .filter(column => column.fieldType)
              .map(column => ({ original: column })),
            // @ts-ignore ts(2322) FIXME: Type 'number | null' is not assignable to type 'number'.
            startRowIndex: dataIndex,
            // @ts-ignore ts(18047) FIXME: 'dataIndex' is possibly 'null'.
            endRowIndex: dataIndex + 1,
          });

          if (updater) {
            updateEachRowWith(updater);
          }
        }}
      >
        {t('request.question.grid.clearRow')}
      </GridMenuItem>

      <GridMenuItem
        icon="trash-o"
        // @ts-ignore ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => removeRow(dataIndex)}
        // @ts-ignore ts(18048) FIXME: 'minRows' is possibly 'undefined'.
        disabled={draftRows.length <= minRows}
      >
        {t('request.question.grid.removeRow')}
      </GridMenuItem>
    </GridMenu>
  ) : (
    null
  );
};
