import * as React from 'react';
import { TOriginalColumnDataBase, FrozenHeaderCellProps, ColumnData } from './utils';

const RenderFrozenHeaderSecondRowCellBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
>({
    SecondRowFrozenHeaderCell,
    subcolumns,
    index: subcolumnIndex,
    offsetX,
  }: {
  SecondRowFrozenHeaderCell?: React.FunctionComponent<FrozenHeaderCellProps<any>>;
  subcolumns: ColumnData<TOriginalColumnData>[];
  index: number;
  offsetX: number;
}) => {
  const subcolumn = subcolumns[subcolumnIndex];

  return (
    <div
      className="header-cell"
      style={{
        position: 'absolute',
        top: 0,
        left: `${offsetX + subcolumn.left}px`,
        height: 'var(--frozen-header-second-line-height)',
        width: `${subcolumn.width}px`,
      }}
    >
      {SecondRowFrozenHeaderCell && (
        <SecondRowFrozenHeaderCell column={subcolumn} />
      )}
    </div>
  );
};

export const RenderFrozenHeaderSecondRowCell =
  React.memo(RenderFrozenHeaderSecondRowCellBase) as typeof RenderFrozenHeaderSecondRowCellBase;
