import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { LatestIntegrationExecutionsTable } from './LatestIntegrationExecutionsTable';

export const LatestIntegrationExecutionsModal = ({
  integrationExecutions,
  ...props
}: Omit<DialogProps, 'heading' | 'body'> & {
  integrationExecutions;
}) => {
  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading="Latest Executions"
      body={(
        <Stack maxWidth="900px" gap={0}>
          <LatestIntegrationExecutionsTable integrationExecutions={integrationExecutions} />
        </Stack>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
