import * as React from 'react';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink } from './AdminTableLink';
import { userRoute } from './AppRouting';

export const UserLink = ({
  userId,
  style,
  children,
}: {
  userId: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={userRoute.to}
    params={{ userId }}
    onClick={stopPropagation}
    style={style}
  >
    {children}
  </AdminTableLink>
);
