/* eslint-disable @typescript-eslint/no-unused-vars,no-underscore-dangle,no-plusplus,react/no-children-prop */

import * as React from 'react';
import { VariableSizeGrid } from 'react-window';

// literal copy of https://github.com/bvaughn/react-window/blob/master/src/createGridComponent.js#L145-L146
const defaultItemKey = ({ columnIndex, data, rowIndex }) =>
  `${rowIndex}:${columnIndex}`;

// literal copy of https://github.com/bvaughn/react-window/blob/master/src/VariableSizeGrid.js#L32-L53
const getEstimatedTotalHeight = (
  { rowCount },
  { rowMetadataMap, estimatedRowHeight, lastMeasuredRowIndex },
) => {
  let totalSizeOfMeasuredRows = 0;

  // Edge case check for when the number of items decreases while a scroll is in progress.
  // https://github.com/bvaughn/react-window/pull/138
  if (lastMeasuredRowIndex >= rowCount) {
    lastMeasuredRowIndex = rowCount - 1;
  }

  if (lastMeasuredRowIndex >= 0) {
    const itemMetadata = rowMetadataMap[lastMeasuredRowIndex];
    totalSizeOfMeasuredRows = itemMetadata.offset + itemMetadata.size;
  }

  const numUnmeasuredItems = rowCount - lastMeasuredRowIndex - 1;
  const totalSizeOfUnmeasuredItems = numUnmeasuredItems * estimatedRowHeight;

  return totalSizeOfMeasuredRows + totalSizeOfUnmeasuredItems;
};

// literal copy of https://github.com/bvaughn/react-window/blob/master/src/VariableSizeGrid.js#L32-L53
const getEstimatedTotalWidth = (
  { columnCount },
  {
    columnMetadataMap,
    estimatedColumnWidth,
    lastMeasuredColumnIndex,
  },
) => {
  let totalSizeOfMeasuredRows = 0;

  // Edge case check for when the number of items decreases while a scroll is in progress.
  // https://github.com/bvaughn/react-window/pull/138
  if (lastMeasuredColumnIndex >= columnCount) {
    lastMeasuredColumnIndex = columnCount - 1;
  }

  if (lastMeasuredColumnIndex >= 0) {
    const itemMetadata = columnMetadataMap[lastMeasuredColumnIndex];
    totalSizeOfMeasuredRows = itemMetadata.offset + itemMetadata.size;
  }

  const numUnmeasuredItems = columnCount - lastMeasuredColumnIndex - 1;
  const totalSizeOfUnmeasuredItems = numUnmeasuredItems * estimatedColumnWidth;

  return totalSizeOfMeasuredRows + totalSizeOfUnmeasuredItems;
};

// extension of https://github.com/bvaughn/react-window/blob/master/src/createGridComponent.js#L395-L486
// that passes the items of the first column (leftColumnItems) to the `innerElementType`
function render(this: any) {
  const {
    children,
    className,
    columnCount,
    direction,
    height,
    innerRef,
    innerElementType,
    innerTagName,
    itemData,
    itemKey = defaultItemKey,
    outerElementType,
    outerTagName,
    rowCount,
    style,
    useIsScrolling,
    width,
  } = this.props;
  const { isScrolling } = this.state;

  const [
    columnStartIndex,
    columnStopIndex,
  ] = this._getHorizontalRangeToRender();
  const [rowStartIndex, rowStopIndex] = this._getVerticalRangeToRender();

  const firstContentColumnIndex = itemData.frozenLeftColumnCount;

  const contentColumnStartIndex = Math.max(columnStartIndex, firstContentColumnIndex);

  const leftColumnItems: React.ReactElement[] = [];
  const items: React.ReactElement[] = [];
  if (columnCount > 0 && rowCount) {
    // render frozen left cells
    for (
      let rowIndex = rowStartIndex;
      rowIndex <= rowStopIndex;
      rowIndex++
    ) {
      for (
        let columnIndex = 0;
        columnIndex < firstContentColumnIndex;
        columnIndex++
      ) {
        leftColumnItems.push(
          React.createElement(children, {
            columnIndex,
            data: itemData,
            isScrolling: useIsScrolling ? isScrolling : undefined,
            key: itemKey({ columnIndex, data: itemData, rowIndex }),
            rowIndex,
            style: this._getItemStyle(rowIndex, columnIndex),
          }),
        );
      }

      // render content cells
      for (
        let columnIndex = contentColumnStartIndex;
        columnIndex <= columnStopIndex;
        columnIndex++
      ) {
        items.push(
          React.createElement(children, {
            columnIndex,
            data: itemData,
            isScrolling: useIsScrolling ? isScrolling : undefined,
            key: itemKey({ columnIndex, data: itemData, rowIndex }),
            rowIndex,
            style: this._getItemStyle(rowIndex, columnIndex),
          }),
        );
      }
    }
  }

  // Read this value AFTER items have been created,
  // So their actual sizes (if variable) are taken into consideration.
  const estimatedTotalHeight = getEstimatedTotalHeight(
    this.props,
    this._instanceProps,
  );
  const estimatedTotalWidth = getEstimatedTotalWidth(
    this.props,
    this._instanceProps,
  );

  return React.createElement(
    outerElementType || outerTagName || 'div',
    {
      className,
      onScroll: this._onScroll,
      ref: this._outerRefSetter,
      style: {
        position: 'relative',
        height,
        width,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        willChange: 'transform',
        direction,
        ...style,
      },
    },
    React.createElement(innerElementType || innerTagName || 'div', {
      children: items,
      leftColumnItems,
      ref: innerRef,
      style: {
        height: estimatedTotalHeight,
        pointerEvents: isScrolling ? 'none' : undefined,
        width: estimatedTotalWidth,
      },
    }),
  );
}

VariableSizeGrid.prototype.render = render;

export const PatchedVariableSizeGrid = VariableSizeGrid;
