import { isNumber } from 'lodash';
import { assign, createMachine, sendParent } from 'xstate';

/*
type UploadMachineContext = {
  uploadFn: any;
  _id: string | null;
  file: File | null;
  attachment: Attachment | null;
  attempts: number;
  progress: number;
  error: string | null;
};

type Event =
  { type: 'START'; file: File } |
  { type: 'REPLACE'; file: File } |
  { type: 'UPLOAD'; progress: number } |
  { type: 'COMPLETE'; attachment: Attachment } |
  { type: 'CLEAR' } |
  { type: 'ERROR' } |
  { type: 'CANCEL' } |
  { type: 'RETRY' } |
  { type: 'DELETE' };
*/

export const uploadMachine = createMachine<any>({
  id: 'upload',
  initial: 'unknown',
  context: {
    // Function in charge of uploading
    uploadFn: () => Promise.resolve({}),

    // Arbitrary id, used to maintain bi-directional link with parent machines
    _id: null,

    // Input: File object to be passed to `uploadFn`
    file: null,

    // Output: Attachment generated by the `uploadFn`
    attachment: null,

    // Number of times the upload has been attempted
    attempts: 0,

    // Progress of the current attempt
    progress: 0,

    // Error message displayed
    error: null,

    // Flag to display the retry button
    canRetry: true,

    // the translation function
    t: null,
  },
  states: {
    // Transient state to determine whether this is a new upload or a completed
    // upload (ie: a completed upload has a corresponding attachment).
    unknown: {
      always: [
        { cond: 'hasAttachment', target: 'completed' },
        { cond: 'hasFile', target: 'validating' },
        { target: 'idle' },
      ],
    },
    idle: {
      on: {
        START: {
          target: 'validating',
          actions: 'setFile',
        },
      },
    },
    validating: {
      always: [
        { cond: 'hasZeroBytes', target: 'failed', actions: 'setZeroByteError' },
        { target: 'uploading' },
      ],
    },
    uploading: {
      entry: ['incrementAttempts', 'resetProgress'],
      invoke: {
        id: 'uploader',
        src: 'uploader',
      },
      on: {
        UPLOAD: {
          actions: 'setProgress',
        },
        CANCEL: [
          { cond: 'hasAttachment', target: 'completed', actions: 'sendCancel' },
          { target: 'idle', actions: 'sendCancel' },
        ],
        COMPLETE: {
          target: 'completed',
          actions: ['completeProgress', 'setAttachment', 'sendComplete'],
        },
        ERROR: {
          target: 'failed',
          actions: 'setError',
        },
      },
    },
    completed: {
      on: {
        DELETE: {
          target: 'deleted',
          actions: 'sendDelete',
        },
        REPLACE: {
          target: 'uploading',
          actions: 'setFile',
        },
        CLEAR: {
          target: 'idle',
          actions: ['removeFile', 'removeAttachment'],
        },
      },
    },
    failed: {
      on: {
        RETRY: {
          target: 'uploading',
        },
        DELETE: {
          target: 'deleted',
          actions: 'sendDelete',
        },
        CANCEL: [
          { cond: 'hasAttachment', target: 'completed', actions: 'sendCancel' },
          { target: 'idle', actions: 'sendCancel' },
        ],
      },
    },
    deleted: {},
  },
}, {
  actions: {
    resetProgress: assign({
      progress: 0,
    }),
    setProgress: assign({
      progress: (context, event) => event.progress,
    }),
    completeProgress: assign({
      progress: 1,
    }),
    incrementAttempts: assign({
      attempts: context => isNumber(context.attempts) ? context.attempts + 1 : 1,
    }),
    setFile: assign({
      file: (context, event) => event.file,
    }),
    removeFile: assign({
      file: null,
    }),
    setUploadError: assign({
      error: (context: any) => context.t?.('upload.uploadError'),
    }),
    setZeroByteError: assign({
      error: (context: any) => context.t?.('upload.uploadEmpty'),
      canRetry: false,
    }),
    setAttachment: assign({
      attachment: (context, event) => event.attachment,
    }),
    removeAttachment: assign({
      attachment: null,
    }),
    setError: assign({
      error: (_, event) => event.error?.response?.data ?? event.error?.message,
    }),
    sendComplete: sendParent((context: any) => ({
      type: 'COMPLETE_UPLOAD',
      _id: context._id,
      attachment: context.attachment,
    })),
    sendCancel: sendParent((context: any) => ({
      type: 'CANCEL_UPLOAD',
      _id: context._id,
    })),
    sendDelete: sendParent((context: any) => ({
      type: 'REMOVE_UPLOAD',
      _id: context._id,
      attachmentId: context.attachment?._id,
    })),
  },
  guards: {
    hasFile: context => Boolean(context.file),
    hasZeroBytes: context => Boolean(context.file) && context.file.size === 0,
    hasAttachment: context => Boolean(context.attachment),
  },
  services: {
    uploader: (context) => (callback) => {
      context
        .uploadFn({
          file: context.file,
          onProgress: ({ total, loaded }: { total: number; loaded: number }) => {
            callback({ type: 'UPLOAD', progress: loaded / total });
          },
        })
        .then((attachment: any) =>
          callback({ type: 'COMPLETE', attachment }),
        )
        .catch(error =>
          callback({ type: 'ERROR', error }),
        );
    },
  },
});
