import { useQuery } from 'react-query';
import { filter, sortBy } from 'lodash';
import { useApi, wrap } from '../../api';
import { useBulletinSection } from './useBulletinSection';

const useDeprecatedBulletinsQuery = ({ rfqId, currentCompanyId, enabled }) => {
  const api = useApi();

  return useQuery(
    ['bulletins', { rfqId, currentCompanyId }],
    wrap(api.getDeprecatedBulletins),
    {
      enabled,
      staleTime: 10 * 1000,
      select: bulletins => sortBy(filter(bulletins, { _type: 'clarification' }), 'createdAt'),
      placeholderData: [],
    },
  );
};

export const useBulletinSectionExchangeDefs = ({ rfqId, currentCompanyId, sectionId }) => {
  const api = useApi();

  return useQuery(
    ['bulletinExchangeDefs', { currentCompanyId, rfqId, sectionId }],
    wrap(api.getBulletinExchangeDefs),
    {
      enabled: Boolean(sectionId),
      staleTime: 10 * 1000,
      placeholderData: [],
    },
  );
};

export const useBulletins = ({
  rfqId,
  currentCompanyId,
  enabled = true,
}: {
  rfqId: string;
  currentCompanyId: string;
  enabled?: boolean;
}) => {
  const bulletinSection = useBulletinSection();

  // Based on the presence of the `bulletinSection`, we use the "new" version of bulletins
  // that are implemented as exchanges
  const { data: newBulletins } = useBulletinSectionExchangeDefs({
    rfqId,
    currentCompanyId,
    sectionId: enabled ? bulletinSection?._id : null,
  });

  // If the rfq has no bulletin section (ie: it's an older request), then we fallback to
  // using the older, deprecated version of bulletins.
  const { data: deprecatedBulletins } = useDeprecatedBulletinsQuery({
    rfqId,
    currentCompanyId,
    enabled: enabled && !bulletinSection,
  });

  // Resolve the correct bulletins based on the existence of a bulletin section
  return (bulletinSection ? newBulletins : deprecatedBulletins) as any[];
};
