import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { values, includes } from 'lodash';
import { RoleRestrictionProfile, defaultRoles } from '@deepstream/common/user-utils';
import { callAll } from '@deepstream/utils/callAll';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelText } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { TextField } from '../../../form/TextField';
import { useAdminApi, useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { useCompanyUsers } from './useCompanyUsers';
import { useMutation } from '../../../useMutation';
import { isAppAdminCompany, useIsAppAdmin } from '../../../useIsAppAdmin';
import {
  FULL_USER_EDITABLE_PERMISSIONS,
  SUPER_USER_EDITABLE_PERMISSIONS,
  STAKEHOLDER_EDITABLE_PERMISSIONS,
  globalRoleToRoleRestrictionProfile,
} from './utils';
import { APP_COMPANY_ROLES, DEFAULT_COMPANY_ROLES, RoleField } from './RoleField';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../../ui/Tabs';
import { GlobalRole } from './types';
import { EditPermissionList, EditRequestsPermissionList, EditContractsPermissionList, EditReportingPermissionList, EditPreQualPermissionList } from './EditPermissionList';

type AddUserToCompanyModalProps =
  { companyId: string; isPayingCompany: boolean; onCancel: any; onSave: any } &
  ModalProps;

export const AddUserToCompanyModal = ({
  companyId,
  isPayingCompany,
  onCancel,
  onSave,
  ...props
}: AddUserToCompanyModalProps) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const { data: users } = useCompanyUsers(companyId);
  const isAppAdmin = useIsAppAdmin();

  const [addTeamMember] = useMutation(
    isAppAdmin ? adminApi.addTeamMember : api.addTeamMember,
    {
      onSuccess: callAll(
        () => toaster.success(t('teamManagement.toaster.addUserSuccess')),
        onSave,
      ),
      onError: () => toaster.error(t('teamManagement.toaster.addUserError')),
      onSettled: () => queryClient.invalidateQueries(['users', { companyId }]),
    },
  );

  const isAlreadyMember = (value: string) => !!value && !users?.find(user => user.email === value.trim());

  const handleAddUserSubmit = useCallback((values) => {
    const roles = {
      admin: values.admin,
      editor: values.editor,
      managePreQual: values.managePreQual,
      sendQuestionnaires: values.sendQuestionnaires,
      receiveQuestionnaires: values.receiveQuestionnaires,
      sendRFQ: values.sendRFQ,
      receiveRFQ: values.receiveRFQ,
      sendContracts: values.sendContracts,
      receiveContracts: values.receiveContracts,
      accessReportingForRequests: values.accessReportingForRequests,
      accessReportingForContracts: values.accessReportingForContracts,
    };
    const roleRestrictionProfile = globalRoleToRoleRestrictionProfile(values.globalRole);

    return addTeamMember({
      companyId,
      user: {
        email: values.email.trim(),
        roles,
        roleRestrictionProfile,
      },
    });
  }, [companyId, addTeamMember]);

  const isPermissionDisabled = useCallback((itemId, globalRole) => {
    if (globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER) {
      return !includes(STAKEHOLDER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.FULL_USER) {
      return !includes(FULL_USER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.SUPER_USER) {
      return !includes(SUPER_USER_EDITABLE_PERMISSIONS, itemId);
    }
  }, []);

  return (
    <Modal style={{ content: { width: '600px' } }} {...props}>
      {users && (
        <Formik
          initialValues={{
            email: '',
            globalRole: GlobalRole.FULL_USER,
            ...defaultRoles[RoleRestrictionProfile.FULL_USER],
          }}
          validationSchema={
            yup.object().shape({
              email: yup
                .string()
                .email(t('teamManagement.errors.invalidEmail'))
                .required(t('general.required'))
                .test('alreadyExists', t('teamManagement.errors.userAlreadyExists'), isAlreadyMember),
              globalRole: isAppAdminCompany(companyId)
                ? yup.mixed()
                : yup.string().oneOf(values(GlobalRole)).required(),
              admin: yup.string().oneOf(['true', 'false']).required(),
              editor: yup.string().oneOf(['true', 'false']).required(),
              managePreQual: yup.string().oneOf(['true', 'false']).required(),
              sendQuestionnaires: yup.string().oneOf(['true', 'false']).required(),
              receiveQuestionnaires: yup.string().oneOf(['true', 'false']).required(),
              sendRFQ: yup.string().oneOf(['true', 'false']).required(),
              receiveRFQ: yup.string().oneOf(['true', 'false']).required(),
              sendContracts: yup.string().oneOf(['true', 'false']).required(),
              receiveContracts: yup.string().oneOf(['true', 'false']).required(),
              accessReportingForRequests: yup.string().oneOf(['true', 'false']).required(),
              accessReportingForContracts: yup.string().oneOf(['true', 'false']).required(),
            })
          }
          onSubmit={handleAddUserSubmit}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form>
              <ModalHeader onClose={onCancel}>
                {t('teamManagement.dialog.addUser.heading')}
              </ModalHeader>
              <Tabs>
                <PanelText p={3}>
                  <TextField name="email" label={t('general.email')} inputType="email" required />
                </PanelText>
                <PanelText p={3}>
                  <RoleField
                    companyRoles={isAppAdminCompany(companyId) ? APP_COMPANY_ROLES : DEFAULT_COMPANY_ROLES}
                  />
                </PanelText>
                <TabList style={{ backgroundColor: 'white' }}>
                  <Tab>
                    {t('general', { ns: 'general' })}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-check"
                      regular
                    />
                    {t('preQualification', { ns: 'general' })}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-text-o"
                    />
                    {t('general.request_other')}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="file-contract"
                      regular
                    />
                    {t('general.contract_other')}
                  </Tab>
                  <Tab disabled={isAppAdminCompany(companyId)}>
                    <Icon
                      style={{ fontSize: '14px', marginRight: 4 }}
                      icon="chart-bar"
                      regular
                    />
                    {t('general.reporting')}
                  </Tab>
                </TabList>
                <ModalBody>
                  <TabPanels>
                    <TabPanel>
                      <EditPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                      {isPayingCompany && values.globalRole === GlobalRole.FULL_USER ? (
                        <MessageBlock variant="warn">
                          {isAppAdmin
                            ? t('teamManagement.dialog.addUser.fullUserWarningAdmin')
                            : t('teamManagement.dialog.addUser.fullUserWarning')
                          }
                        </MessageBlock>
                      ) : isPayingCompany && values.globalRole === GlobalRole.SUPER_USER ? (
                        <MessageBlock variant="warn">
                          {isAppAdmin
                            ? t('teamManagement.dialog.addUser.superUserWarningAdmin')
                            : t('teamManagement.dialog.addUser.superUserWarning')
                          }
                        </MessageBlock>
                      ) : (
                        <MessageBlock variant="info">
                          {t('teamManagement.dialog.addUser.accessInfo')}
                        </MessageBlock>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {/*
                       // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'. */}
                      <EditPreQualPermissionList isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)} />
                      <MessageBlock variant="info" mt={3} width="100%">
                        {t('teamManagement.dialog.addUser.questionnairesAccessInfo')}
                      </MessageBlock>
                    </TabPanel>
                    <TabPanel>
                      {/*
                       // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'. */}
                      <EditRequestsPermissionList isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)} />
                      <MessageBlock variant="info" mt={3} width="100%">
                        {t('teamManagement.dialog.addUser.accessInfo')}
                      </MessageBlock>
                    </TabPanel>
                    <TabPanel>
                      {/*
                       // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'. */}
                      <EditContractsPermissionList isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)} />
                      <MessageBlock variant="info" mt={3} width="100%">
                        {t('teamManagement.dialog.addUser.contractsAccessInfo')}
                      </MessageBlock>
                    </TabPanel>
                    <TabPanel>
                      <EditReportingPermissionList
                        // @ts-ignore ts(2322) FIXME: Type '(itemId: string) => boolean | undefined' is not assignable to type '(itemId: string, globalRole: string) => boolean'.
                        isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                        globalRole={values.globalRole}
                      />
                    </TabPanel>
                  </TabPanels>
                </ModalBody>
              </Tabs>
              <ModalFooter>
                <CancelButton onClick={onCancel} />
                <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                  {t('teamManagement.addUser')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
