import * as React from 'react';
import { compact } from 'lodash';

import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { getPrimarySubtag } from '@deepstream/utils';
import { EditUserLocaleModal } from './EditUserLocaleModal';

export const UserPreferencesList: React.FC<{ user: any }> = ({ user }) => {
  const editUserLocaleModal = useModalState();
  const languageNames = React.useMemo(
    () => {
      return new Intl.DisplayNames([APP_ADMIN_LOCALE], { type: 'language' });
    },
    [],
  );

  const profileProperties = React.useMemo(
    () => {
      if (!user) return [];

      const primarySubtag = getPrimarySubtag(user.preferences?.locale);

      return compact([
        {
          name: 'Language',
          value: !user.preferences?.locale ? (
            <EmDash />
          ) : primarySubtag ? (
            languageNames.of(primarySubtag)
          ) : (
            user.preferences.locale
          ),
        },
      ]);
    },
    [user, languageNames],
  );

  return (
    <>
      <PropertyList properties={profileProperties}>
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editUserLocaleModal.open} rows={2} />
      </PropertyList>
      <EditUserLocaleModal
        user={user}
        languageNames={languageNames}
        onSave={editUserLocaleModal.close}
        onCancel={editUserLocaleModal.close}
        isOpen={editUserLocaleModal.isOpen}
      />
    </>
  );
};
