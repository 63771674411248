import { intersection, isEmpty, isEqual, keyBy, keys, map, mapValues, omitBy, values } from 'lodash';
import { getRegionName } from '../region';
import {
  AddressQuestionExchangeDefinition,
  DateTimeQuestionExchangeDefinition,
  Live,
  OptionQuestionExchangeDefinition,
  QuestionAddress,
  QuestionAddressField,
  QuestionExchangeDefinition,
  QuestionResponse,
  QuestionType,
  AnyScope,
  ShortTextQuestionExchangeDefinition,
  GridQuestionExchangeDefinition,
  YesNoQuestionExchangeDefinition,
} from './types';

export const optionBasedQuestionTypes = [QuestionType.CHECKBOXES, QuestionType.MULTIPLE_CHOICE];
export const textBasedQuestionTypes = [QuestionType.SHORT_TEXT, QuestionType.LONG_TEXT];

export const isShortTextQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is ShortTextQuestionExchangeDefinition<Scope> =>
  exchangeDef.questionType === QuestionType.SHORT_TEXT;

export const isOptionBasedQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is OptionQuestionExchangeDefinition =>
  [QuestionType.CHECKBOXES, QuestionType.MULTIPLE_CHOICE].includes(exchangeDef.questionType);

export const isAddressQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is AddressQuestionExchangeDefinition =>
  exchangeDef.questionType === QuestionType.ADDRESS;

export const isDateTimeQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is DateTimeQuestionExchangeDefinition<Scope> =>
  exchangeDef.questionType === QuestionType.DATE_TIME;

export const isGridQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is GridQuestionExchangeDefinition =>
  exchangeDef.questionType === QuestionType.GRID;

export const isYesNoQuestion = <Scope extends AnyScope = Live>(
  exchangeDef: QuestionExchangeDefinition<Scope>,
): exchangeDef is YesNoQuestionExchangeDefinition =>
  exchangeDef.questionType === QuestionType.YES_NO;

export const getOrderedAddressResponse = (address: QuestionAddress, locale: string): string[] => {
  const orderedFields = intersection(
    values(QuestionAddressField),
    keys(omitBy(address, isEmpty)),
  );

  return map(
    orderedFields,
    // Country fields should be mapped from the country code to the country name
    field => field === QuestionAddressField.COUNTRY
      ? getRegionName(address[field], locale)
      : address[field],
  );
};

export const getOrderedAddressFields = (fields: QuestionAddressField[]) =>
  intersection(values(QuestionAddressField), fields);

export const getEmptyResponseValue = (exchangeDef: QuestionExchangeDefinition) => {
  switch (exchangeDef.questionType) {
    case QuestionType.SHORT_TEXT:
      return 'schema' in exchangeDef
        ? null
        : '';
    case QuestionType.LONG_TEXT:
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.DATE_TIME:
      return null;
    case QuestionType.CHECKBOXES:
      return [];
    case QuestionType.PRICE:
      return {
        currencyCode: exchangeDef.currencies?.[0] ?? '',
        amount: null,
      };
    case QuestionType.ADDRESS: {
      return mapValues(
        keyBy(exchangeDef.visibleFields),
        () => '',
      );
    }
    case QuestionType.DOCUMENT:
      return {
        selectedOption: null,
        attachments: [],
        expiryDate: null,
      };
    case QuestionType.GRID:
      return {
        rows: [],
      };
    case QuestionType.YES_NO:
      return {
        selectedOption: null,
        moreInformation: '',
        attachments: [],
      };
    default:
      throw new Error(`Unknown question type: ${(exchangeDef as any).questionType}`);
  }
};

export const isEmptyResponse = (response: QuestionResponse, def: QuestionExchangeDefinition) => {
  const hasEmptyResponseValue = isEqual(response.value, getEmptyResponseValue(def));

  return def.isRequired
    ? hasEmptyResponseValue
    : hasEmptyResponseValue && !response.noAnswer;
};
