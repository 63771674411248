import * as React from 'react';
import { Text, TextProps } from 'rebass/styled-components';

export const UppercaseText: React.FC<TextProps> = ({ fontSize = 1, color = 'lightGray5', ...props }) => (
  <Text
    fontSize={fontSize}
    color={color}
    fontWeight={500}
    lineHeight={1}
    sx={{
      textTransform: 'uppercase',
    }}
    {...props}
  />
);
