import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { useContractSection } from '@deepstream/ui/modules/Contracts/contract';
import { AdminContractsTable } from './AdminContractsTable';

const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const TableWrapper = styled.div<any>`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
`;

export const AdminContractSection = () => {
  const { exchangeDefIds } = useContractSection();

  return exchangeDefIds.length ? (
    <TableWrapper>
      <PanelDivider />
      <AdminContractsTable />
    </TableWrapper>
  ) : (
    <Subtext>No contracts yet</Subtext>
  );
};
