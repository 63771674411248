import { useCallback } from 'react';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { size } from 'lodash';
import { Box } from 'rebass/styled-components';
import { Bold } from '@deepstream/ui/Bold';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useConfirmDialog, useModalState } from '@deepstream/ui/ui/useModalState';
import { TeamUser } from '@deepstream/ui/modules/PreQualification/Questionnaire/Team/TeamUserModal';
import { useQuestionnaireData } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { useRemoveTeamMember } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireMutations';
import { RemoveTeamMemberDialog } from '@deepstream/ui/RemoveTeamMemberDialog';
import { useAdminNavigation } from '../../AppRouting';

export const RemoveUserFromQuestionnaireButton = ({
  questionnaireId,
  company,
  user,
  ...props
}: ButtonProps & {
  questionnaireId: string;
  company: CompanyMinimized;
  user: TeamUser;
}) => {
  const { navigateToQuestionnaireCompany } = useAdminNavigation();
  const toaster = useToaster();
  const { teamById, summary } = useQuestionnaireData();
  const removeUserModal = useModalState();
  const [removeTeamMember] = useRemoveTeamMember();
  const { confirm, ...confirmDialogProps } = useConfirmDialog();

  const userId = user._id;
  const companyId = company._id;
  const companyName = company.name;

  const team = teamById[company._id];

  const isOnlyUser = size(team.users) === 1;
  const isOnlyOwner = team.owners.includes(userId) && size(team.owners) === 1;

  const removeUser = useCallback(
    async () => {
      await removeTeamMember(
        { companyId, questionnaireId, userId },
        {
          onSuccess: () => {
            removeUserModal.close();
            navigateToQuestionnaireCompany(questionnaireId, companyId, true);
          },
          onError: () => {
            removeUserModal.close();
          },
        },
      );
    },
    [companyId, navigateToQuestionnaireCompany, questionnaireId, removeTeamMember, removeUserModal, userId],
  );

  const warningText = isOnlyUser
    ? `${companyName} will have no users for this questionnaire`
    : isOnlyOwner
      ? `${companyName} will have no owners for this questionnaire`
      : '';

  return (
    <>
      <Box mb={3}>
        Remove <Bold>{user.name}</Bold> from the{' '}
        <Bold>{companyName}</Bold> team for{' '}
        <Bold>{summary.name}</Bold>
      </Box>
      <Button onClick={removeUserModal.open} variant="danger" {...props}>
        Remove
      </Button>
      <Dialog
        heading="Remove user from questionnaire?"
        body={(
          <>
            {warningText && (
              <MessageBlock variant="warn" mt={0}>
                {warningText}
              </MessageBlock>
            )}
            <MessageBlock variant="info" mb={2} mt={2}>
              User will be notified via email
            </MessageBlock>
          </>
        )}
        okButtonText="Remove"
        okButtonVariant="danger"
        isOpen={removeUserModal.isOpen}
        onOk={removeUser}
        onCancel={removeUserModal.close}
      />
      {user && confirmDialogProps.isOpen && (
        <RemoveTeamMemberDialog
          user={user}
          {...confirmDialogProps}
        />
      )}
    </>
  );
};
