import { DropdownQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { useField } from 'formik';
import { find } from 'lodash';
import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { getItemLabel } from '../form/SelectField';
import { Select } from '../ui/Select';
import { ContainerLabel, ElementProps, LockedAnswer, Modify, NoAnswer } from './common';

type EditableProps = {
  id: string;
  name: string;
  element: any;
  disabled?: boolean;
  error?: any;
  placeholder?: string;
  maxHeight?: any;
};

const Editable: React.FC<EditableProps> = ({ name, element, disabled, error, placeholder, maxHeight }) => {
  const [field, , helpers] = useField({ name }); // eslint-disable-line no-unused-vars

  const selectedItem = find(
    element.options,
    { value: field.value },
  )?.value;

  return (
    <>
      <Select
        selectedItem={selectedItem}
        items={element.options}
        getItemLabel={getItemLabel}
        itemToString={item => item.value || ''}
        onChange={({ selectedItem }) => helpers.setValue(selectedItem.value)}
        disabled={disabled || element.disabled}
        placeholder={placeholder || element.placeholder}
        maxHeight={maxHeight}
      />
      {error}
    </>
  );
};

type Select2Props = {
  placeholder?: string;
  maxHeight?: any;
};

export const Select2Element: React.FC<Modify<ElementProps, { element: DropdownQuestionElement }> & Select2Props> = ({
  name,
  element,
  answer,
  hideLabel,
  isReadOnly,
  isLocked,
  ...props
}) => {
  const id = useUniqueId();

  const selectedOption = element?.options?.find(option => option.value === answer);

  return (
    <ContainerLabel
      htmlFor={id}
      label={element.label}
      hideLabel={hideLabel}
      showAsterisk={element.validation?.required}
    >
      {isReadOnly ? (
        answer ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <Box>{selectedOption?.label ?? answer}</Box>
          )
        ) : (
          <NoAnswer />
        )
      ) : (
        <Box width="300px">
          <Editable id={id} name={name} element={element} {...props} />
        </Box>
      )}
    </ContainerLabel>
  );
};
