import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';
import { DateFormat } from '@deepstream/utils';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ContractOverview } from '@deepstream/common/contract';
import { formatAdminDate } from '../../formatDate';
import { ContractLinkCell } from '../Contracts/ContractLinkCell';
import { companyRoute, useAdminNavigation } from '../../AppRouting';

const getCompanyRole = (companyId: string) => ({ senders }: ContractOverview) => {
  const senderIds = senders.map(sender => sender._id);
  return senderIds.includes(companyId) ? 'Sender' : 'Recipient';
};

type TableProps = {
  contracts: ContractOverview[];
};

export const CompanyContractsTable = ({ contracts }: TableProps) => {
  const { t } = useTranslation('contracts');
  const { companyId } = companyRoute.useParams();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'autoReferenceNumber',
        Header: 'Ref.',
        accessor: 'autoReferenceNumber',
        width: 80,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        width: 320,
        Cell: nestCells(TruncateCell, ContractLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'role',
        Header: 'Role',
        accessor: getCompanyRole(companyId),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 120,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ status }: ContractOverview) => t(`status.${status}`),
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 160,
      },
      {
        id: 'createdAt',
        Header: 'Created',
        accessor: 'meta.createdAt',
        Cell: Datetime2Cell,
        format: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
        filter: filterMultipleValues,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate, menuWidth: 200 }),
        width: 180,
        sort: 'datetime',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [companyId, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={contracts}
        onRowClick={contract => navigation.navigateToContractCompany(contract._id, companyId)}
        isSortable
        noFilteredDataPlaceholder="No contracts match chosen filters"
        smallPageControls
      />
    </BasicTableStyles>
  );
};
