import { useField } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isBoolean } from 'lodash';
import { ControlledRadioGroup, ControlledRadioGroupProps } from '../ui/Radio';
import { ErrorMessage } from './Field';
import { FieldContainer } from './FieldContainer';

type RadioFieldBaseProps = {
  name?: string;
  value?: ControlledRadioGroupProps['value'];
  label?: string;
  description?: string;
  hideLabel?: boolean;
  variant?: 'inline' | 'stacked';
  options: any;
  required?: boolean;
  showError?: boolean;
  onContainerBlur?: (event: React.FocusEvent<HTMLDivElement, any>) => void;
  onChange?: any;
  disabled?: boolean;
  error?: string;
  gap?: number | string;
  labelStyle?: React.CSSProperties;
  autoFocus?: boolean;
  fieldLabelStyle?: React.CSSProperties;
};

export const RadioFieldBase = ({
  name,
  label,
  description,
  hideLabel,
  options,
  required,
  fieldLabelStyle,
  helperText,
  ...props
}: RadioFieldBaseProps & { helperText?: React.ReactNode }) => (
  <FieldContainer
    name={name}
    label={label}
    description={description}
    hideLabel={hideLabel}
    showAsterisk={required}
    labelStyle={fieldLabelStyle}
  >
    <ControlledRadioGroup
      name={name}
      inputs={options}
      {...props}
    />
    {helperText}
  </FieldContainer>
);

interface RadioFieldProps extends RadioFieldBaseProps {
  /**
   * Form field name
   */
  name: string;
  showError?: boolean;
  errorPosition?: 'top' | 'bottom';
  onKeyDown?: (event: any) => void;
  errorMessageStyle?: React.CSSProperties;
}

export const RadioField = ({
  name,
  label,
  description,
  hideLabel,
  options,
  required,
  onChange: onChangeProp,
  showError,
  errorPosition = 'bottom',
  onKeyDown,
  errorMessageStyle,
  fieldLabelStyle,
  ...props
}: RadioFieldProps) => {
  const [field, meta, helper] = useField({ name });

  const onChange = React.useCallback(
    (value) => {
      if (onChangeProp) {
        onChangeProp(value);
      }

      helper.setValue(value);
      field.onChange(value);
    },
    [field, helper, onChangeProp],
  );

  return (
    <FieldContainer
      name={name}
      label={label}
      description={description}
      hideLabel={hideLabel}
      showAsterisk={required}
      onKeyDown={onKeyDown}
      labelStyle={fieldLabelStyle}
    >
      {errorPosition === 'top' && showError && meta.touched && meta.error ? (
        <ErrorMessage error={meta.error} fontWeight="normal" sx={{ mb: 1 }} style={errorMessageStyle} />
      ) : (
        null
      )}
      <ControlledRadioGroup
        name={name}
        inputs={options}
        value={field.value}
        onChange={onChange}
        onContainerBlur={showError ? (
          (event: React.FocusEvent<HTMLDivElement, any>) => {
            if (event.relatedTarget?.name !== name) {
              helper.setTouched(true);
            }
          }
        ) : (
          undefined
        )}
        {...props}
      />
      {errorPosition === 'bottom' && showError && meta.touched && meta.error ? (
        <ErrorMessage error={meta.error} fontWeight="normal" style={errorMessageStyle} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export const YesNoRadioField = ({
  name,
  onChange,
  ...props
}: Omit<RadioFieldBaseProps, 'value' | 'onChange' | 'options'> & {
  helperText?: React.ReactNode;
  onChange?: (value: boolean) => Promise<void>;
}) => {
  const { t } = useTranslation('translation');
  // @ts-ignore ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
  const [field,, helper] = useField({ name });
  const options = React.useMemo(() => {
    return [
      { label: t('general.yes'), value: 1 },
      { label: t('general.no'), value: 0 },
    ];
  }, [t]);

  return (
    <RadioFieldBase
      name={name}
      options={options}
      value={isBoolean(field.value) ? Number(field.value) : field.value}
      onChange={async (value) => {
        const booleanValue = Boolean(Number(value));

        await helper.setValue(booleanValue);

        if (onChange) {
          await onChange(booleanValue);
        }
      }}
      {...props}
    />
  );
};
