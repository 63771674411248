import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useQuery } from 'react-query';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { productDashboardTabs } from './tabsConfig';
import { ProductDashboardGeneralTabContent } from './ProductDashboardGeneralTabContent';
import { ProductDashboardRequestsTabContent } from './ProductDashboardRequestsTabContent';
import { ProductDashboardIntegrationsTabContent } from './ProductDashboardIntegrationsTabContent';
import { ProductDashboardContractsTabContent } from './ProductDashboardContractsTabContent';
import { ProductDashboardPreQualificationTabContent } from './ProductDashboardPreQualificationTabContent';
import { ProductDashboardExcelSubmitTabContent } from './ProductDashboardExcelSubmitTabContent';

export const ProductDashboard = ({ tab }) => {
  const adminApi = useAdminApi();

  const { data, status } = useQuery(
    ['productDashboard'],
    wrap(adminApi.getProductDashboard),
  );

  return (
    <Stack gap={3}>
      {tab === productDashboardTabs.general ? (
        <ProductDashboardGeneralTabContent status={status} data={data} />
      ) : tab === productDashboardTabs.preQualification ? (
        <ProductDashboardPreQualificationTabContent status={status} data={data} />
      ) : tab === productDashboardTabs.requests ? (
        <ProductDashboardRequestsTabContent status={status} data={data} />
      ) : tab === productDashboardTabs.contracts ? (
        <ProductDashboardContractsTabContent status={status} data={data} />
      ) : tab === productDashboardTabs.integrations ? (
        <ProductDashboardIntegrationsTabContent status={status} data={data} />
      ) : null}
    </Stack>
  );
};
