import * as React from 'react';
import { UseQueryResult } from 'react-query';

export type TableData<T> = {
  data: any[];
  pageCount: number;
  pageSize: number;
  pageIndex: number;
  query: UseQueryResult<T>;
  setPageControls: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  selectedItemsIds?: string[];
  disabledItemIds?: string[];
  selectableItemIds?: string[];
  areAllItemsSelected?: boolean;
  selectItem?: (id: string) => void;
  unselectItem?: (id: string) => void;
  selectAllItems?: () => void;
  unselectAllItems?: () => void;
  selectAllItemsOnPage?: () => void;
};

export const TableDataContext = React.createContext<TableData<any> | null>(null);

export const TableDataProvider = <T,>({
  data,
  pageCount,
  pageSize,
  pageIndex,
  query,
  setPageControls,
  ...props
}: TableData<T>): React.ReactElement<TableData<T>> => {
  const contextValue = React.useMemo(
    () => ({
      data,
      pageCount,
      pageSize,
      pageIndex,
      query,
      setPageControls,
    }),
    [data, pageCount, pageIndex, pageSize, query, setPageControls],
  );

  return (
    <TableDataContext.Provider value={contextValue} {...props} />
  );
};

export const useTableData = <T extends TableData<any> = TableData<any>>({ required } = { required: false }) => {
  const tableData = React.useContext(TableDataContext);

  if (required && !tableData) {
    throw new Error('no tableData has been found');
  }

  return tableData as T;
};
