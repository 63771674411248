import * as React from 'react';
import { CompanyMinimized, User } from '@deepstream/common/rfq-utils';
import { useInView } from 'react-intersection-observer';
import { FlexProps } from 'rebass/styled-components';
import { find } from 'lodash';
import { useQuery } from 'react-query';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelText, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import * as rfx from '@deepstream/ui/rfx';
import { RequestTeamEditUserModal } from '@deepstream/ui/modules/Request/Team/RequestTeamEditUserModal';
import { RequestRole } from '@deepstream/ui/types';
import { RfqIdProvider } from '@deepstream/ui/useRfq';
import { useApi, wrap } from '@deepstream/ui/api';
import { PropertyList, Skip, PropertyListAction } from '@deepstream/ui/PropertyList';
import { CompanyLink } from './CompanyLink';
import { Page } from './Page';
import { PagePermissionsTable } from './PagePermissionsTable';
import { RemoveUser } from './RemoveUser';
import * as Title from './title';
import { RequestLink } from './RequestLink';
import { UserLink } from './UserLink';
import { getUser, useAdminRequest } from './adminRequest';
import { useSaveAdminChanges } from './useSaveAdminRequestTeamChanges';
import { Id } from './Id';
import { requestCompanyUserRoute } from './AppRouting';

const textColumn: React.CSSProperties = {
  width: 'auto',
  minWidth: '10%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const PageTitle = ({ flexDirection, company, requestId, user }: {
  flexDirection?: FlexProps['flexDirection'];
  requestId: string;
  company: CompanyMinimized;
  user: User;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <UserLink
        key="user"
        userId={user._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.User
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'large' : 'small'}
          user={user}
        />
      </UserLink>

      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '33%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          company={company}
        />
      </CompanyLink>

      <RequestLink
        requestId={requestId}
        style={flexDirection === 'column' ? undefined : { ...textColumn }}
      >
        <Title.Request
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          requestId={requestId}
        />
      </RequestLink>
    </Title.Container>
  );
};

const RequestCompanyUserPanels = ({
  requestId,
  company,
  user,
}: {
  requestId: string;
  company: CompanyMinimized;
  user: User;
}) => {
  const editRequestPermissionsModal = useModalState();
  const api = useApi();
  const { teamById } = rfx.useStructure();
  const [saveChanges] = useSaveAdminChanges({ companyId: company._id });

  const generalUserProperties = React.useMemo(
    () => [
      { name: 'ID', value: user._id, Component: Id },
      { name: 'Role', value: user.isOwner ? 'Owner' : 'Team member' },
    ],
    [user],
  );

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId: company._id }],
    wrap(api.getUsersForCompany),
  );

  const userForCompany = find(usersForCompany, { _id: user._id });

  return (
    <>
      <Panel heading="General" mb={4}>
        <PropertyList properties={generalUserProperties}>
          <Skip />
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={editRequestPermissionsModal.open}
            disabled={(
              !userForCompany ||
              userForCompany.roleRestrictionProfiles?.[company._id] === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
            )}
          />
        </PropertyList>
      </Panel>
      <rfx.SaveChangesProvider saveChanges={saveChanges}>
        <Panel mb={4}>
          <PanelHeader heading="Permissions">
            <Button
              small
              iconLeft="pencil-alt"
              onClick={editRequestPermissionsModal.open}
              disabled={!userForCompany}
            >
              Edit
            </Button>
          </PanelHeader>
          <PanelDivider />
          <PagePermissionsTable user={user} />
          {userForCompany && editRequestPermissionsModal.isOpen && (
            <RequestTeamEditUserModal
              user={{
                ...user,
                companyId: company._id,
                requestRole: teamById[company._id].owners.includes(user._id)
                  ? RequestRole.OWNER
                  : RequestRole.TEAM_MEMBER,
                roleRestrictionProfile: userForCompany.roleRestrictionProfiles?.[company._id] ?? null,
              }}
              isOpen={editRequestPermissionsModal.isOpen}
              close={editRequestPermissionsModal.close}
            />
          )}
        </Panel>
      </rfx.SaveChangesProvider>
      <Panel heading="Remove user from request" mb={4}>
        <PanelText>
          <RemoveUser
            requestId={requestId}
            company={company}
            user={user}
          />
        </PanelText>
      </Panel>
    </>
  );
};

export const RequestCompanyUserPage = () => {
  const { requestId, companyId, userId } = requestCompanyUserRoute.useParams();
  const [ref, inView, entry] = useInView();

  const { data, error, isLoading } = useAdminRequest(requestId);

  const company = find(data?.structure.senders, { _id: companyId })?.company ??
    find(data?.structure.recipients, { _id: companyId })?.company;

  const user = data ? getUser(data.structure, companyId, userId) : null;

  return (
    <RfqIdProvider rfqId={requestId}>
      <Page
        subHeading={data && company && user && !inView && entry && (
          <rfx.StructureProvider structure={data.structure}>
            <PageTitle
              requestId={requestId}
              company={company}
              user={user}
            />
          </rfx.StructureProvider>
        )}
      >
        {isLoading ? (
          <Panel>
            <PanelText>
              <Loading />
            </PanelText>
          </Panel>
        ) : error ? (
          <Panel heading="Error">
            <PanelText>Oh no</PanelText>
          </Panel>
        ) : data && company && user ? (
          <rfx.StructureProvider structure={data.structure}>
            <div ref={ref}>
              <PageTitle
                flexDirection="column"
                requestId={requestId}
                company={company}
                user={user}
              />
            </div>
            <RequestCompanyUserPanels
              requestId={requestId}
              company={company}
              user={user}
            />
          </rfx.StructureProvider>
        ) : null}
      </Page>
    </RfqIdProvider>
  );
};
