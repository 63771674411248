import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { UserLinkCell } from '../../UserLinkCell';
import { useAdminNavigation } from '../../AppRouting';

type CompanyUsersTableProps = {
  companyId: string;
  users: any;
};

export const CompanyUsersTable = ({ companyId, users }: CompanyUsersTableProps) => {
  const { t } = useTranslation();
  const { isLarge, isExtraLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, UserLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'userRole',
        Header: 'User role',
        accessor: (user) => {
          const roleRestrictionProfile = get(user, `roleRestrictionProfiles.${companyId}`);

          if (!roleRestrictionProfile) {
            return '—';
          }

          return t(`user.globalRole.${roleRestrictionProfile}`);
        },
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        width: 200,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [t, companyId],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        isSortable
        smallPageControls
        columns={columns}
        data={users}
        onRowClick={user => navigation.navigateToCompanyUser(companyId, user._id)}
        noFilteredDataPlaceholder="No users match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['name', 'isAdmin'] : []}
      />
    </BasicTableStyles>
  );
};
