import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Flex, Box } from 'rebass/styled-components';

import { IconSquare } from '@deepstream/ui-kit/elements/icon/Icon';
import { ProgressBar, getValueFromIntervals } from '../../ui/ProgressBar';

import { useRequestsReportingData } from './useRequestsReporting';

const IconText = ({ icon, color, children }) => {
  return (
    <Flex alignItems="center">
      <Box mr={2}>
        <IconSquare icon={icon} fontSize={2} color={color} size="30px" />
      </Box>
      {children}
    </Flex>
  );
};

export const RequestResponseRateChart = () => {
  const { data } = useRequestsReportingData();
  const { t } = useTranslation('reporting');

  let intervalVariant = 'noData';
  let value = 0;

  // @ts-ignore ts(2339) FIXME: Property 'supplierResponseRate' does not exist on type 'never'.
  if (isNumber(data?.supplierResponseRate)) {
    // @ts-ignore ts(2339) FIXME: Property 'supplierResponseRate' does not exist on type 'never'.
    value = Math.round(data.supplierResponseRate * 100);
    [intervalVariant] = getValueFromIntervals(value);
  }

  return (
    <>
      <Text fontWeight={700} fontSize={4} my="13px">
        {{
          'success': (
            <IconText icon="check" color="success">
              {t('excellent')}
            </IconText>
          ),
          'warn': (
            <IconText icon="exclamation-triangle" color="warning">
              {t('average')}
            </IconText>
          ),
          'error': (
            <IconText icon="exclamation" color="danger">
              {t('poor')}
            </IconText>
          ),
          'noData': (
            <IconText icon="exclamation" color="gray">
              {t('noData')}
            </IconText>
          ),
        }[intervalVariant]}
      </Text>

      <ProgressBar
        variant="valueBased"
        showValue={intervalVariant !== 'noData'}
        value={value}
      />
    </>
  );
};
