import * as React from 'react';
import { endsWith, isEmpty, isNil, noop, omitBy, sumBy, xor } from 'lodash';
import clsx from 'clsx';

import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import {
  DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT,
  DEFAULT_FROZEN_FOOTER_HEIGHT,
  DEFAULT_NAVIGABLE_RANGE,
} from './constants';
import {
  CellIndices,
  TExpandableRowDataBase,
  TOriginalColumnDataBase,
  TOriginalSubRowDataBase,
  checkBounds,
  getGridCellId,
  NavigationTarget,
  getGridContainerId,
  SelectedRange,
  getSelectedRange,
  ColumnData,
  getMaxSelectableEndIndices,
  useSplitColumnData,
  getMinNavigableStartIndices,
  getMaxNavigableEndIndices,
} from './utils';
import {
  getLastRowNextPageFromDom,
  getFirstRowPreviousPageFromDom,
  getScrollTopForCellVisibility,
  getScrollLeftForCellVisibility,
} from './scroll';
import { useGridData } from './useGridData';
import { RenderDataCellDefault } from './RenderDataCellDefault';
import { useHandleGridKeyDown } from './useHandleGridKeyDown';
import { ComparisonGridProps, getVisibleHeight, getVisibleWidth } from './ComparisonGrid';

const Table = styled.table`
  display: block;
  width: 100%;
  overflow-wrap: anywhere;
`;

const THead = styled.thead`
  display: block;
  width: 100%;

  position: sticky;
  box-sizing: content-box;
  background-color: rgb(247, 249, 251);
  top: 0;
  z-index: 100;
`;

const TBody = styled.tbody`
  display: block;
  width: 100%;
`;

const TFoot = styled.tfoot`
  display: block;
  width: calc(100% + 1px);
  position: sticky;
  z-index: 100;
  left: -1px;
  background-color: rgb(247, 249, 251);
  box-shadow: 0 -1px 1px 0 ${props => props.theme.colors.lightGray};
  bottom: 0;
`;

const Tr = styled.tr`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  width: 100%;
`;

const HeaderRow = styled.tr`
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  width: 100%;

  .vertical-scroll & {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    clip-path: inset(-8px 0px -8px -8px);
  }
`;

const FooterRow = styled.tr`
`;

const FooterCell = styled.td`
  box-sizing: border-box;
  color: rgb(89, 99, 119);
  width: 100%;
  display: block;
`;

const FooterContainer = styled.div`
  padding: 9px 10px;
  position: relative;
  display: flex;
`;

const Th = styled.th`
  height: 48px;
  color: rgb(89, 99, 119);
  font-weight: 500;
  box-shadow: inset 0 -1px 0 ${props => props.theme.colors.lightGray};
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  display: block;
  overflow: hidden;
`;

const StickyTh = styled(Th)`
  position: sticky;
  left: 0;
  z-index: 1;
  overflow: hidden;
`;

const Td = styled.td`
  min-height: 40px;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  display: block;
  overflow: hidden;
`;

const StickyTd = styled(Td)`
  position: sticky;
  left: 0;
  z-index: 1;
`;

const getCellFlexStyle = (width?: number | string) => {
  if (!width) {
    return '1 1 auto';
  }

  if (typeof width === 'number') {
    return `0 0 ${width}px`;
  }

  if (endsWith(width, 'px')) {
    return `0 0 ${width}`;
  }

  return `1 1 ${width}`;
};

/**
 * Table-based non-virtual grid component with frozen header,
 * frozen footer (optional), vertical scrolling (optional) and
 * support for content-dependent row heights.
 */
export const NonVirtualGridBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  columnData: colummnDataParam,
  contentAreaSubcolumnData,
  frozenLeftSubcolumnData,
  rowData,
  subRowHeight,
  collapsedRowIds = [],
  setCollapsedRowIds = noop,
  onDataCellKeyboardAction = noop,
  staticRowHeights,
  DataCell,
  FrozenHeaderCell,
  FirstColumnCell,
  RenderDataCell = RenderDataCellDefault,
  bodyPaddingLeft = 20,
  bodyPaddingRight = 20,
  defaultColumnWidth = 250,
  frozenFooterHeight = DEFAULT_FROZEN_FOOTER_HEIGHT,
  frozenHeaderSecondLineHeight = 0,
  frozenHeaderFirstLineHeight = DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT,
  navigableRange = DEFAULT_NAVIGABLE_RANGE,
  onActiveCellChange,
  idPrefix = 'grid',
  onScroll = noop,
  editedCell = null,
  subcolumnWidthsMap = {},
  FooterContent,
  focusedCellBottomOffset = bodyPaddingRight,
  selectableRange,
  canScrollHorizontally,
  frozenLeftColumnIds,
  onRowClick,
}: ComparisonGridProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const {
    columnData,
    frozenLeftColumnData,
    frozenLeftColumnWidth,
  } = useSplitColumnData(colummnDataParam, frozenLeftColumnIds, defaultColumnWidth);

  const data = useGridData<
    TOriginalColumnData,
    TOriginalSubRowData,
    TOriginalRowData
  >(
    columnData,
    rowData,
    collapsedRowIds,
    subRowHeight,
    defaultColumnWidth,
    contentAreaSubcolumnData,
    frozenLeftSubcolumnData,
    subcolumnWidthsMap,
  );

  const frozenHeaderHeight = React.useMemo(
    () => frozenHeaderFirstLineHeight + (frozenHeaderSecondLineHeight || 0),
    [frozenHeaderFirstLineHeight, frozenHeaderSecondLineHeight],
  );

  const toggleCollapsedRowState = React.useCallback(
    (rowId: string, rowIndex: number) => {
      setCollapsedRowIds((collapsedRowIds) => xor(collapsedRowIds, [rowId]));
    },
    [setCollapsedRowIds],
  );

  const [activeCellIndices, setActiveCellIndices] =
    React.useState<CellIndices>(getMinNavigableStartIndices(navigableRange));

  const [hoverCellIndices, setHoverCellIndices] =
    React.useState<CellIndices | null>(null);

  const [selectedRange, setSelectedRange] =
    React.useState<SelectedRange | null>(null);

  React.useEffect(() => {
    setSelectedRange(previousSelectedRange => {
      if (!selectableRange || !previousSelectedRange) {
        return null;
      }

      const { start, end, reference } = previousSelectedRange;

      const maxSelectableEndIndices = getMaxSelectableEndIndices(
        data.rows.length,
        data.columns.length,
        navigableRange,
        selectableRange,
      );

      const newSelectedRange = {
        start: {
          columnIndex: Math.max(selectableRange.startColumnIndex, Math.min(start.columnIndex, maxSelectableEndIndices.columnIndex - 1)),
          rowIndex: Math.max(navigableRange.startRowIndex, Math.min(start.rowIndex, maxSelectableEndIndices.rowIndex - 1)),
        },
        end: {
          columnIndex: Math.min(end.columnIndex, maxSelectableEndIndices.columnIndex),
          rowIndex: Math.min(end.rowIndex, maxSelectableEndIndices.rowIndex),
        },
        reference: {
          columnIndex: Math.max(selectableRange.startColumnIndex, Math.min(reference.columnIndex, maxSelectableEndIndices.columnIndex - 1)),
          rowIndex: Math.max(navigableRange.startRowIndex, Math.min(reference.rowIndex, maxSelectableEndIndices.rowIndex - 1)),
        },
      };

      return (
        newSelectedRange.start.rowIndex < newSelectedRange.end.rowIndex &&
        newSelectedRange.start.columnIndex < newSelectedRange.end.columnIndex
      )
        ? newSelectedRange
        : null;
    });

    setActiveCellIndices(previousActiveCellIndices => {
      if (!previousActiveCellIndices) {
        return previousActiveCellIndices;
      }

      const { columnIndex, rowIndex } = previousActiveCellIndices;

      const maxNavigationEndIndices = getMaxNavigableEndIndices(data.rows.length, data.columns.length, navigableRange);

      const newActiveCellIndices = {
        columnIndex: Math.max(navigableRange.startColumnIndex, Math.min(columnIndex, maxNavigationEndIndices.columnIndex - 1)),
        rowIndex: Math.max(navigableRange.startRowIndex, Math.min(rowIndex, maxNavigationEndIndices.rowIndex - 1)),
      };

      return newActiveCellIndices;
    });
  }, [
    data.columns.length,
    data.rows.length,
    navigableRange,
    selectableRange,
  ]);

  const handleActiveCellChange = React.useCallback((
    cellIndices,
    event,
    keepSelectedRange?: boolean | null,
    bottomRightCellIndices?: CellIndices | null,
  ) => {
    setActiveCellIndices((previousCellIndices) => {
      const newCellIndices = {
        ...previousCellIndices,
        ...cellIndices,
      };

      if (selectableRange) {
        if (bottomRightCellIndices) {
          setSelectedRange({
            start: newCellIndices,
            end: bottomRightCellIndices,
            reference: newCellIndices,
          });
        } else if (event?.shiftKey) {
          const selectedRange = getSelectedRange(previousCellIndices, newCellIndices);

          if (
            !selectedRange ||
            (
              selectedRange.start.columnIndex >= selectableRange.startColumnIndex &&
              (!selectableRange.endColumnIndex || selectedRange.end.columnIndex <= selectableRange.endColumnIndex)
            )
          ) {
            setSelectedRange(previousSelectedRange => ({
              ...selectedRange!,
              reference: previousSelectedRange?.reference || previousCellIndices,
            }));
          }

          // when selecting a new range, don't update the active cell
          return previousCellIndices;
        } else if (
          !keepSelectedRange &&
          // when activating a cell that's not selectable (for example, in the action column),
          // or when the active cell hasn't changed, don't reset the selected range
          (
            newCellIndices.columnIndex >= selectableRange.startColumnIndex &&
            (!selectableRange.endColumnIndex || newCellIndices.columnIndex <= selectableRange.endColumnIndex)
          ) &&
          (newCellIndices.rowIndex !== previousCellIndices.rowIndex || newCellIndices.columnIndex !== previousCellIndices.columnIndex)
        ) {
          setSelectedRange(null);
        }
      }

      return newCellIndices;
    });
  }, [setActiveCellIndices, setSelectedRange, selectableRange]);

  const selectAll = React.useCallback(() => {
    if (selectableRange && data.columns.length > 0 && data.rows.length > 0) {
      setSelectedRange(previousSelectedRange => {
        const start = {
          rowIndex: navigableRange.startRowIndex,
          columnIndex: selectableRange.startColumnIndex,
        };
        const reference = previousSelectedRange?.reference || start;
        const end = getMaxSelectableEndIndices(data.rows.length, data.columns.length, navigableRange, selectableRange);

        return { start, end, reference };
      });
    }
  }, [data.columns, data.rows, navigableRange, selectableRange]);

  const activateCellAndEnsureVisibility = React.useCallback(
    (
      cellIndices: Partial<CellIndices>,
      target?: NavigationTarget | null,
      event?: React.MouseEvent<unknown, MouseEvent> | React.KeyboardEvent<unknown> | null,
      keepSelectedRange?: boolean | null,
      bottomRightCellIndices?: CellIndices | null,
    ) => {
      const containerElement = containerRef.current;

      if (!containerElement) {
        return;
      }

      if (!isNil(target)) {
        const tBodyElement = containerElement.getElementsByTagName('tbody')[0];

        if (!tBodyElement) {
          return;
        }

        const containerRect = containerElement.getBoundingClientRect();

        let getRowOnPage: any;

        switch (target) {
          case NavigationTarget.LAST_ROW_NEXT_PAGE:
            getRowOnPage = getLastRowNextPageFromDom;
            break;
          case NavigationTarget.FIRST_ROW_PREVIOUS_PAGE:
            getRowOnPage = getFirstRowPreviousPageFromDom;
            break;
          default:
            assertUnreachable(target);
        }

        const row = getRowOnPage({
          currentScrollTop: containerElement.scrollTop,
          visibleHeight: getVisibleHeight(
            containerRect,
            focusedCellBottomOffset,
            Boolean(FooterContent),
            frozenHeaderHeight,
            frozenFooterHeight,
          ),
          frozenHeaderHeight,
          tBodyElement,
          containerTop: containerRect.top,
        });

        if (!row) {
          return;
        }

        const { scrollTop, rowIndex } = row;

        handleActiveCellChange({ ...cellIndices, rowIndex }, event, keepSelectedRange);

        if (!isNil(scrollTop)) {
          containerElement.scrollTo({ top: scrollTop });
        }

        onActiveCellChange?.();

        return;
      }

      if (!cellIndices || !checkBounds(cellIndices, navigableRange, data)) {
        return;
      }

      handleActiveCellChange(cellIndices, event, keepSelectedRange, bottomRightCellIndices);

      const targetElementId = getGridCellId(idPrefix, { rowIndex: cellIndices.rowIndex!, columnIndex: cellIndices.columnIndex! });

      const targetElement = document.getElementById(targetElementId);

      if (targetElement) {
        const containerRect = containerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const targetLeft = targetRect.left + containerElement.scrollLeft - containerRect.left - frozenLeftColumnWidth;

        const scrollLeft = !canScrollHorizontally || cellIndices.columnIndex! < frozenLeftColumnData.length
          ? null
          : getScrollLeftForCellVisibility({
            currentScrollLeft: containerElement.scrollLeft,
            visibleWidth: getVisibleWidth(
              containerRect,
              bodyPaddingLeft,
              frozenLeftColumnWidth,
            ),
            targetLeft,
            targetWidth: targetRect.width,
          });

        const targetTop = targetRect.top + containerElement.scrollTop - containerRect.top - frozenHeaderHeight;

        const scrollTop = getScrollTopForCellVisibility({
          currentScrollTop: containerElement.scrollTop,
          visibleHeight: getVisibleHeight(
            containerRect,
            focusedCellBottomOffset,
            Boolean(FooterContent),
            frozenHeaderHeight,
            frozenFooterHeight,
          ),
          targetTop,
          targetHeight: targetRect.height,
        });

        const newScrollPosition = omitBy({ left: scrollLeft, top: scrollTop }, isNil);

        if (!isEmpty(newScrollPosition)) {
          containerElement.scrollTo(newScrollPosition);
        }
      }

      onActiveCellChange?.();
    },
    [
      navigableRange,
      data,
      handleActiveCellChange,
      idPrefix,
      onActiveCellChange,
      focusedCellBottomOffset,
      FooterContent,
      frozenHeaderHeight,
      frozenFooterHeight,
      bodyPaddingLeft,
      frozenLeftColumnWidth,
      frozenLeftColumnData.length,
      canScrollHorizontally,
    ],
  );

  const dataAndCommands = React.useMemo(
    () => ({
      ...data,
      activeCellIndices,
      hoverCellIndices,
      setHoverCellIndices,
      selectedRange,
      activateCellAndEnsureVisibility,
      toggleCollapsedRowState,
      staticRowHeights,
      idPrefix,
      editedCell,
      onRowClick,
    }),
    [
      data,
      activeCellIndices,
      hoverCellIndices,
      setHoverCellIndices,
      selectedRange,
      activateCellAndEnsureVisibility,
      toggleCollapsedRowState,
      staticRowHeights,
      idPrefix,
      editedCell,
      onRowClick,
    ],
  );

  const dataCellItemData = React.useMemo(
    () => ({
      ...dataAndCommands,
      DataCell,
      FirstColumnCell,
      bodyPaddingLeft,
      frozenLeftColumnWidth,
      frozenLeftColumnCount: frozenLeftColumnData.length,
    }),
    [dataAndCommands, DataCell, FirstColumnCell, bodyPaddingLeft, frozenLeftColumnWidth, frozenLeftColumnData.length],
  );

  const [hasHorizontalScroll, setHasHorizontalScroll] = React.useState(false);
  const [hasVerticalScroll, setHasVerticalScroll] = React.useState(false);

  const handleDataGridScroll = React.useCallback(
    (event) => {
      setHasHorizontalScroll(event.target.scrollLeft > 0);
      setHasVerticalScroll(event.target.scrollTop > 0);
      onScroll();
    },
    [setHasVerticalScroll, onScroll],
  );

  const handleGridKeyDown = useHandleGridKeyDown(
    dataAndCommands,
    onDataCellKeyboardAction,
    selectAll,
    navigableRange,
    selectableRange,
  );

  const activeDescendant = React.useMemo(
    () =>
      activeCellIndices ? getGridCellId(idPrefix, activeCellIndices) : undefined,
    [idPrefix, activeCellIndices],
  );

  const [containerTabIndex, setContainerTabIndex] = React.useState(0);

  React.useEffect(() => {
    const activeDescendantElement = activeDescendant
      ? document.getElementById(activeDescendant)
      : null;

    if (activeDescendantElement) {
      const inputs = activeDescendantElement.getElementsByTagName('input');

      if (inputs[0]) {
        inputs[0].focus();
        setContainerTabIndex(-1);
      } else {
        setContainerTabIndex(0);
        containerRef.current?.focus();
      }
    }
  }, [activeDescendant]);

  const totalWidth = React.useMemo(() => {
    if (canScrollHorizontally) {
      return sumBy(data.columns, column => (column?.original as any).width);
    } else {
      return null;
    }
  }, [data.columns, canScrollHorizontally]);

  return (
    // eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex
    <div
      id={getGridContainerId(idPrefix)}
      ref={containerRef}
      className={clsx({
        'vertical-scroll': hasVerticalScroll,
        'horizontal-scroll': hasHorizontalScroll,
      })}
      role="treegrid"
      tabIndex={containerTabIndex}
      onKeyDown={handleGridKeyDown}
      aria-activedescendant={activeDescendant}
      style={{
        // hack: adding 1px to height to fix duplicate bottom borders issue
        // between grid cells and outer container
        height: 'calc(100% + 1px)',
        overflowY: 'scroll',
        width: canScrollHorizontally
          ? 'calc(100% + 1px)'
          : '100%',
        overflowX: canScrollHorizontally
          ? 'scroll'
          : 'clip',
      }}
      onScroll={handleDataGridScroll}
    >
      {data ? (
        (
          <Table
            style={{
              width: canScrollHorizontally
                ? `${totalWidth}px`
                : 'calc(100% + 1px)',
            }}
          >
            <THead>
              <HeaderRow>
                {(data.columns as ColumnData<TOriginalColumnData>[]).map((column, columnIndex) => {
                  const ThComponent = columnIndex < frozenLeftColumnData.length
                    ? StickyTh
                    : Th;

                  return (
                    <ThComponent
                      key={column.original._id}
                      style={{ flex: getCellFlexStyle((column.original as any).width) }}
                    >
                      <Box className="header-cell" sx={{ height: '100%', width: '100%' }}>
                        <FrozenHeaderCell column={column} />
                      </Box>
                    </ThComponent>
                  );
                })}
              </HeaderRow>
            </THead>
            <TBody>
              {data.rows.map((row, rowIndex) => {
                if (!row) {
                  return null;
                }

                return (
                  <Tr key={row.original._id}>
                    {columnData.map((column, columnIndex) => {
                      const TdComponent = columnIndex < frozenLeftColumnData.length
                        ? StickyTd
                        : Td;

                      return (
                        <TdComponent
                          key={column._id}
                          style={{ flex: getCellFlexStyle((column as any).width) }}
                        >
                          <RenderDataCell
                            key={columnIndex}
                            columnIndex={columnIndex}
                            data={dataCellItemData}
                            rowIndex={rowIndex}
                            style={{}}
                          />
                        </TdComponent>
                      );
                    })}
                  </Tr>
                );
              })}
            </TBody>
            {FooterContent && (
              <TFoot>
                <FooterRow style={{ height: frozenFooterHeight - 4 }}>
                  <FooterCell colSpan={data.columns.length}>
                    <FooterContainer>
                      <FooterContent height={0} width={0} idPrefix={idPrefix} />
                    </FooterContainer>
                  </FooterCell>
                </FooterRow>
              </TFoot>
            )}
          </Table>
        )
      ) : (
        null
      )}
    </div>
  );
};

export const NonVirtualGrid = React.memo(
  NonVirtualGridBase,
) as typeof NonVirtualGridBase;
