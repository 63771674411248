import { useMemo } from 'react';

import { Flex } from 'rebass/styled-components';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ExcelConversionFunnelUsersTable } from './ExcelConversionFunnelUsersTable';
import { renderPercentage } from '../reports/utils';

type ExcelUser = {
  proceeded?: boolean,
};

type ConditionalStep1Users = {
  showConversionFunnel: true;
  step1Users: ExcelUser[];
} | {
  showConversionFunnel?: false;
  step1Users?: never;
};

export const ExcelConversionFunnelModal = ({
  users,
  step1Users,
  heading,
  showConversionFunnel,
  ...props
}: Omit<DialogProps, 'body'> & ConditionalStep1Users & ({
  users: ExcelUser[];
})) => {
  const proceededUsersCount = useMemo(() => users.filter(user => user.proceeded).length, [users]);
  const notProceededUsersCount = useMemo(() => users.filter(user => !user.proceeded).length, [users]);

  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading={heading}
      body={(
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Users"
                align="left"
                labelProps={{ color: 'text' }}
                value={users.length}
                mr={4}
              />
              {showConversionFunnel ? (
                <>
                  <LabeledValue
                    label="Users (step 1)"
                    align="left"
                    labelProps={{ color: 'text' }}
                    value={step1Users.length}
                    mr={4}
                  />
                  <LabeledValue
                    label="Funnel conversion rate"
                    align="left"
                    labelProps={{ color: 'text' }}
                    value={renderPercentage({
                      count: users.length,
                      total: step1Users.length,
                    })}
                  />
                </>
              ) : (
                <>
                  <LabeledValue
                    label="Yes"
                    align="left"
                    labelProps={{ color: 'text' }}
                    value={(
                      <>
                        <Icon color="success" icon="arrow-right" regular mr={1} />
                        {proceededUsersCount}
                      </>
                    )}
                    mr={4}
                  />
                  <LabeledValue
                    label="No"
                    align="left"
                    labelProps={{ color: 'text' }}
                    value={(
                      <>
                        <Icon color="danger" icon="times" regular mr={1} />
                        {notProceededUsersCount}
                      </>
                    )}
                    mr={4}
                  />
                  <LabeledValue
                    label="Rate proceeded"
                    align="left"
                    labelProps={{ color: 'text' }}
                    value={renderPercentage({
                      count: proceededUsersCount,
                      total: users.length,
                    })}
                  />
                </>
              )}
            </Flex>
          </Flex>
          <ExcelConversionFunnelUsersTable users={users} hideProceededColumn={showConversionFunnel} />
        </Stack>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
