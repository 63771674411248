import * as React from 'react';
import { CellProps } from 'react-table';

export const nestCells = (A: React.FC<any>, B: React.FC<any>): React.FC<CellProps<any>> => ({ cell, ...props }) => (
  <A
    {...props}
    cell={{
      ...cell,
      value: <B cell={cell} {...props} />,
    }}
  />
);
