import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass/styled-components';

export const truncateStyle: React.CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textAlign: 'left',
};

export const truncateCss = css`
  max-width: max-content;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

// formerly, ui/src/ui/Truncate.tsx
export const Truncate = styled(Box)`
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;
