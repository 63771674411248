import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { MutationArgs } from '../../types';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useApi } from '../../api';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useMutation } from '../../useMutation';
import { useBulletinSection } from './useBulletinSection';

export const useChangeBulletinExchanges = ({
  isBulletinUpdate,
  refetchExchange,
  ...args
}: MutationArgs & { isBulletinUpdate?: boolean, refetchExchange?: () => Promise<any> }) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const bulletinSection = useBulletinSection();
  const rfqId = useRfqId();

  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    ignoreRecipientId: true,
  });

  return useMutation(
    api.changeExchanges,
    {
      onSuccess: callAll(
        () => isBulletinUpdate
          ? toaster.success(t('request.bulletin.edit.successToast'))
          : toaster.success(t('request.bulletin.create.successToast')),
        () => queryClient.invalidateQueries(['bulletinExchangeDefs', { rfqId, currentCompanyId, sectionId: bulletinSection?._id }]),
        () => queryClient.invalidateQueries('exchanges'), // used to refresh buyer's view of the supplier bid page
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        // We need to refresh the rfx structure in order to get the correct bulletin index (by using `section.exchangeDefIds`);
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        refetchExchange,
      ),
      onError: () => isBulletinUpdate
        ? toaster.error(t('request.bulletin.edit.errorToast'))
        : toaster.error(t('request.bulletin.create.errorToast')),
      ...args,
    },
  );
};
