import { useCallback } from 'react';
import { useSessionStatusHandler } from '@deepstream/ui/auth';
import { useEnv } from '@deepstream/ui/env';
import { useSystemFeatureFlags } from '@deepstream/ui/systemFeatureFlags';
import { User } from '@deepstream/ui/types';
import { useRedirectToOnboarding } from '@deepstream/ui/useAppInitRedirects';

const DS_LOGIN_REDIRECT = '@@deepstream/redirect-login';

const hasIncompleteUserProfile = (user: User) => (
  !user.firstName ||
  !user.lastName ||
  !user.hasAcceptedLiveTermsOfService
);

export const useAppInitRedirects = () => {
  const { ONBOARDING_URL } = useEnv();
  const systemFeatureFlags = useSystemFeatureFlags();

  useSessionStatusHandler({
    status: 'rejected',
    handler: useRedirectToOnboarding({ setLoginRedirect: true }),
  });

  useSessionStatusHandler({
    status: 'accepted',
    handler: useCallback(
      ({ user }) => {
        // When user's profile is incomplete redirect them to onboarding to complete
        // required fields
        if (systemFeatureFlags?.requiredTermsAndNameEnabled && user && hasIncompleteUserProfile(user)) {
          window.location.href = `${ONBOARDING_URL}/incomplete`;
        }

        const redirectUrl = localStorage.getItem(DS_LOGIN_REDIRECT);

        // If there is a redirect url stored in localstorage (usually due to accessing a route
        // while logged out), then we redirect to that location
        if (redirectUrl) {
          localStorage.removeItem(DS_LOGIN_REDIRECT);
          window.location.href = redirectUrl;
        }
      },
      [ONBOARDING_URL, systemFeatureFlags?.requiredTermsAndNameEnabled],
    ),
  });
};
