import { useMemo } from 'react';
import { values } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { User } from '@deepstream/common/rfq-utils';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';
import { UserLinkCell } from '../../UserLinkCell';
import { contractCompanyRoute, useAdminNavigation } from '../../AppRouting';

export const AdminContractUsersTable = () => {
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { navigateToContractCompanyUser } = useAdminNavigation();
  const { contractId, companyId } = contractCompanyRoute.useParams();

  const contract = useContractData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        Cell: UserLinkCell,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: withProps(FilterSelect, { itemToString: value => value, menuWidth: 240 }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Role',
        accessor: (row: User) => contract.teamById[companyId].owners.includes(row._id) ? 'Owner' : 'Team member',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        width: 200,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [companyId, contract.teamById],
  );

  const data = useMemo(
    () => values(contract.teamById[companyId].users),
    [contract, companyId],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={data}
        onRowClick={user => navigateToContractCompanyUser(contractId, companyId, user._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['name'] : []}
        noFilteredDataPlaceholder="No users match chosen filters"
        isSortable
      />
    </BasicTableStyles>
  );
};
