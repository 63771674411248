import { last } from 'lodash';

import { Box } from 'rebass/styled-components';
import { GridInnerElementType } from './GridInnerElementType';
import { GridData, TExpandableRowDataBase, TOriginalColumnDataBase, TOriginalSubRowDataBase } from './utils';

/**
 * Simplified grid component to be used in server-side rendering.
 */
export const SsrMockGrid = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  RenderDataCell,
  dataCellItemData,
  frozenHeaderHeight,
}: {
  data: GridData<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>;
  RenderDataCell: any;
  dataCellItemData: any;
  frozenHeaderHeight: number;
}) => {
  const lastRow = last(data.rows)!;
  const lastColumn = last(data.columns)!;

  const { frozenLeftColumnCount } = dataCellItemData;

  return data ? (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        willChange: 'transform',
        direction: 'ltr',
      }}
    >
      <GridInnerElementType
        leftColumnItems={data.rows.map((row, rowIndex) => {
          if (!row || rowIndex === 0) {
            return null;
          }

          return data.columns.map(((column, columnIndex) => {
            if (columnIndex >= frozenLeftColumnCount) {
              return null;
            }

            return (
              <RenderDataCell
                key={rowIndex}
                columnIndex={columnIndex}
                data={dataCellItemData}
                rowIndex={rowIndex}
                style={{
                  position: 'absolute',
                  top: `${frozenHeaderHeight + row.top}px`,
                  left: `${column.left}px`,
                  width: `${column.width}px`,
                  height: row.height,
                }}
              />
            );
          }));
        })}
        style={{
          height: `${lastRow.top + lastRow.height}px`,
          width: `${lastColumn.left + lastColumn.width}px`,
        }}
      >
        {data.rows.map((row, rowIndex) => {
          if (!row || rowIndex === 0) {
            return null;
          }

          return data.columns.map(((column, columnIndex) => {
            if (columnIndex < frozenLeftColumnCount || !row.height) {
              return null;
            }

            return (
              <RenderDataCell
                key={columnIndex}
                columnIndex={columnIndex}
                data={dataCellItemData}
                rowIndex={rowIndex}
                style={{
                  position: 'absolute',
                  top: `${frozenHeaderHeight + row.top}px`,
                  left: `${column.left}px`,
                  width: `${column.width}px`,
                  height: row.height,
                }}
              />
            );
          }));
        })}
      </GridInnerElementType>
    </Box>
  ) : (
    null
  );
};
