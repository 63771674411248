import { ProductTag } from '@deepstream/common/products';
import { createContext, memo, useContext } from 'react';

const SsrStaticProductsContext = createContext<ProductTag[] | null>(null);

export const SsrStaticProductsProvider = memo<{ products: ProductTag[]; children: React.ReactNode }>(({ products, ...props }) => (
  <SsrStaticProductsContext.Provider value={products} {...props} />
));

export const useSsrStaticProducts = () => {
  const products = useContext<ProductTag[] | null>(SsrStaticProductsContext);
  return products;
};
