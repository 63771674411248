import { useTranslation } from 'react-i18next';

import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { ContractTeamUser } from './types';
import { UserDetails } from './UserDetails';

export const RemoveTeamMemberDialog = ({
  user,
  ...props
}: {
  user: ContractTeamUser;
} & Omit<DialogProps, 'heading' | 'body'>) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.team.dialog.confirmRemoveRequestUser.heading')}
      body={
        <>
          <UserDetails user={user} />
          <MessageBlock variant="info" my={3}>
            {t('request.team.dialog.confirmRemoveRequestUser.info')}
          </MessageBlock>
        </>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('teamManagement.removeUser')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel')}
      showCloseIcon
      {...props}
    />
  );
};

export const RemoveSelfDialog = ({
  user,
  close,
  isOpen,
}: {
  user: ContractTeamUser;
  close: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.team.dialog.cannotRemoveUser.heading')}
      body={(
        <>
          <UserDetails user={user} />
          <MessageBlock variant="error" mt={3}>
            {t('request.team.dialog.cannotRemoveUser.body')}
          </MessageBlock>
        </>
      )}
      okButtonText={t('general.ok')}
      okButtonVariant="primary"
      isOpen={isOpen}
      onOk={close}
    />
  );
};
