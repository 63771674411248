import { createContext } from 'react';

export const initialContext = {
  isAuthenticated: false,
  isLoading: true,
  token: '',
  error: '',
  logout: () => {},
  getAccessTokenSilently: () => Promise.resolve(''),
};

export interface SimpleAuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  token: string | null;
  error: string;
  logout: (options: { logoutParams: { returnTo: string } }) => void;
  getAccessTokenSilently: () => Promise<string | null>;
}

export const SimpleAuthContext = createContext<SimpleAuthContextProps>(initialContext);
