import { QuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { useContext } from 'react';
import * as React from 'react';
import { Box, BoxProps, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LabelConfig, useLabelConfig } from '../LabelConfigProvider';
import { DownloadFn, UploadFn } from '../ui/types';
import { IsLoggedInContext } from './IsLoggedInProvider';

export type Modify<T, R> = Omit<T, keyof R> & R;

export type ElementProps = {
  name: string;
  element: QuestionElement;
  answer: any;
  error?: any;
  isReadOnly?: boolean;
  isLocked?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
  guardedValues?: any;
  dependentElements?: QuestionElement[];
  // Replaces the form element with anything else
  replacement?: any;
  upload?: UploadFn;
  download?: DownloadFn;
  onChange?: any;
};

export const LockedAnswer = () => {
  const { t } = useTranslation('companyProfile');
  const isLoggedIn = useContext(IsLoggedInContext);

  const text = isLoggedIn
    ? t('common.approvedCompaniesOnly')
    : t('common.registeredCompaniesOnly');

  return (
    <IconText icon="lock" text={text} color="gray" gap={2} />
  );
};

export const NoAnswer = () => {
  const { t } = useTranslation('companyProfile');

  return (
    <Text color="gray">{t('common.noAnswerProvided')}</Text>
  );
};

export const Label: React.FC<BoxProps & { htmlFor?: string; hidden?: boolean; width?: any }> = ({ children, sx, ...props }) => (
  <Box as="label" fontSize={1} {...props}>
    <Text color="lightNavy" fontWeight={500} sx={sx}>
      {children}
    </Text>
  </Box>
);

const RequiredAsterisk = () => {
  const { t } = useTranslation('general');

  return (
    <Text color="danger" display="inline" ml={1} aria-label={t('required')} fontWeight={500}>
      *
    </Text>
  );
};

export const ContainerLabel: React.FC<any> = ({ name, label, htmlFor, hideLabel, showAsterisk, children, ...props }) => {
  const {
    variant: labelVariant,
    width: labelContainerWidth,
    style: labelStyles = {},
  } = useLabelConfig();

  const containerStyle = [LabelConfig.LEFT, LabelConfig.NONE].includes(labelVariant) ? { display: 'flex' } : undefined;
  const elementWrapperStyle = [LabelConfig.LEFT, LabelConfig.NONE].includes(labelVariant) ? { flexGrow: 1 } : undefined;
  const labelWrapperStyle = labelVariant === LabelConfig.LEFT
    ? { width: labelContainerWidth, flexShrink: 0 }
    : labelVariant === LabelConfig.NONE
      ? { width: '0px' }
      : undefined;

  hideLabel = hideLabel ?? labelVariant === LabelConfig.NONE;

  return (
    <Stack gap={hideLabel ? 0 : 1} {...props} sx={containerStyle}>
      {label && (
        <Box sx={labelWrapperStyle}>
          <Label htmlFor={htmlFor} hidden={hideLabel} sx={labelStyles[name]}>
            {label}
            {showAsterisk ? (
              <RequiredAsterisk />
            ) : (
              null
            )}
          </Label>
        </Box>
      )}
      <Box sx={elementWrapperStyle}>
        {children}
      </Box>
    </Stack>
  );
};
