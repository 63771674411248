import { Flex, Box, Text, BoxProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Bold } from './Bold';
import { Counter } from './ui/Badge';

export const ChildrenCounter = ({
  numSelectedChildren,
  ...props
}: Omit<BoxProps, 'color'> & {
  numSelectedChildren: number;
}) => (
  <Counter
    color="primary"
    count={numSelectedChildren}
    sx={{
      fontSize: 1,
      fontWeight: 500,
      height: '20px',
      minWidth: '20px',
      lineHeight: '20px',
    }}
    {...props}
  />
);

export type Field = {
  label: string;
  value: string;
};

export type Item = {
  _id: string;
  label: string;
  level: number;
  parent: string | null;
  isSelected?: boolean;
  // Number of selected children (items under the current item in the hierarchy), but also including itself.
  numSelectedChildren: number;
  fields: Field[];
};

export const ListItemFields = ({ fields }: { fields?: Field[] }) =>
  fields?.length ? (
    <Truncate sx={{ display: 'flex', gap: 2, mt: '6px' }}>
      {fields.map(field => (
        <ListItemField key={field.label} field={field} />
      ))}
    </Truncate>
  ) : (
    null
  );

const ListItemField = ({ field }: { field: Field }) => (
  <Box as="span" flex="0 0 auto" fontSize={1} minWidth={0}>
    <Text as="span" color="subtext" mr={1}>{field.label}</Text>
    <Text as="span">{field.value}</Text>
  </Box>
);

const ListItem = ({
  item,
  depth,
  canOnlyDeselect,
  padded,
  isReadOnly,
  onToggle,
  onSelectFilter,
  isSearching,
}: {
  item: Item;
  depth: number;
  canOnlyDeselect?: boolean;
  padded?: boolean;
  isReadOnly?: boolean;
  onToggle: (id: string) => void;
  onSelectFilter?: (filterId: string) => void;
  isSearching?: boolean;
}) => {
  const { t } = useTranslation();
  const [listItemRef, listItemHovered] = useHover();
  const theme = useTheme();

  const canBeExpanded = isSearching && !canOnlyDeselect
    ? item.level <= depth
    : item.level < depth;

  const selectFilterId = isSearching && item.level === depth && item.parent
    ? item.parent
    : item._id;

  return (
    <Flex
      ref={listItemRef}
      as="li"
      py="12px"
      pr="20px"
      pl={padded ? '80px' : '20px'}
      alignItems="center"
      lineHeight="normal"
      sx={canBeExpanded
        ? {
          cursor: 'default',
          backgroundColor: listItemHovered
            ? transparentize(0.95, theme.colors.primary)
            : '',
        }
        : {}
      }
    >
      <Box
        flex="1 1 auto"
        // we need to compensate the "Open" button width so the row height doesn't change on hover
        pr={canBeExpanded && !listItemHovered ? '79px' : ''}
      >
        <Bold>
          {item.label}
        </Bold>
        <ListItemFields fields={item.fields} />
      </Box>
      <Box flex="0 0 auto">
        {canBeExpanded ? (
          <>
            <ChildrenCounter numSelectedChildren={item.numSelectedChildren} ml="20px" />
            {listItemHovered && (
              <Button
                small
                variant="secondary-outline"
                iconLeft="expand"
                type="button"
                onClick={() => onSelectFilter?.(selectFilterId)}
                ml={3}
              >
                {t('general.open')}
              </Button>
            )}
          </>
        ) : isReadOnly ? (
          item.isSelected && (
            <Icon
              icon="check"
              color="primary"
              ml="20px"
            />
          )
        ) : (
          <Button
            small
            variant={item.isSelected && !canOnlyDeselect ? 'primary' : 'secondary-outline'}
            iconLeft={!item.isSelected
              ? 'plus'
              : canOnlyDeselect
                ? 'times'
                : 'check'
            }
            type="button"
            onClick={() => onToggle(item._id)}
            ml="20px"
          />
        )}
      </Box>
    </Flex>
  );
};

/**
 * Renders a list of items "layered" on a number of levels, defined by the `depth` prop. The list can be
 * navigated by "opening" any item that is not on the lowest level (item's `level` must be higher than the `depth`).
 * Opening an item applies it as a parent filter and renders a list of its children.
 */
export const List = ({
  items,
  depth,
  canOnlyDeselect,
  padded,
  isReadOnly,
  sx = {},
  onToggleItem,
  onSelectFilter,
  isSearching,
}: {
  items: Item[];
  depth: number;
  canOnlyDeselect?: boolean;
  padded?: boolean;
  isReadOnly?: boolean;
  sx?: any;
  onToggleItem: (itemId: string) => void;
  onSelectFilter?: (filterId: string) => void;
  isSearching?: boolean;
}) => (
  <Box
    as="ul"
    sx={{
      listStyle: 'none',
      padding: 0,
      '> * + *': {
        borderTop: 'lightGray',
      },
      ...sx,
    }}
  >
    {items.map(item => (
      <ListItem
        key={item._id}
        item={item}
        depth={depth}
        canOnlyDeselect={canOnlyDeselect}
        isReadOnly={isReadOnly}
        padded={padded}
        onToggle={onToggleItem}
        onSelectFilter={onSelectFilter}
        isSearching={isSearching}
      />
    ))}
  </Box>
);
