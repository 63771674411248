export enum ExcelFlowTrackingEventType {
  FLOW_OPENED = 'excelSubmit:flowOpened',
  EXCEL_DOWNLOADED = 'excelSubmit:excelDownloaded',
  LINE_ITEMS_SUBMITTED = 'excelSubmit:lineItemsSubmitted',
}

export const ExcelFlowTrackingEventTypes = [
  ExcelFlowTrackingEventType.FLOW_OPENED,
  ExcelFlowTrackingEventType.EXCEL_DOWNLOADED,
  ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED,
] as const;

type BaseEvent = {
  eventType: ExcelFlowTrackingEventType;
  currentCompanyId: string;
  data: {
    companyId: string;
    requestId: string;
    requestStageNumber: number;
    requestStageName: string;
    totalLineItems?: number;
    lineItemsSubmitted?: number;
    lineItemsFailed?: number;
    lineItemsExcluded?: number;
  }
};

export type FlowOpenedEvent = BaseEvent & {
  eventType: ExcelFlowTrackingEventType.FLOW_OPENED;
  data: {
    companyId: string;
    requestId: string;
    requestStageNumber: number;
    requestStageName: string;
    totalLineItems: number;
  };
};

export type ExcelDownloadedEvent = BaseEvent & {
  eventType: ExcelFlowTrackingEventType.EXCEL_DOWNLOADED;
  data: {
    companyId: string;
    requestId: string;
    requestStageNumber: number;
    requestStageName: string;
    totalLineItems: number;
  };
};

export type LineItemsSubmittedEvent = BaseEvent & {
  eventType: ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED;
  data: {
    requestId: string;
    requestStageNumber: number;
    requestStageName: string;
    lineItemsSubmitted: number;
    lineItemsFailed: number;
    lineItemsExcluded: number;
  }
};

export type ExcelFlowTrackingEvent = FlowOpenedEvent | ExcelDownloadedEvent | LineItemsSubmittedEvent;
