import * as React from 'react';
import { useTranslation } from 'react-i18next';

type PaginationLocationProps = {
  pageIndex: number;
  pageSize: number;
  total: number;
};

export const PaginationLocation: React.FC<PaginationLocationProps> = ({ pageIndex, pageSize, total }) => {
  const { t } = useTranslation();
  const startingIndex = pageIndex * pageSize;
  const endingIndex = Math.min(total, (pageIndex + 1) * pageSize);

  return (
    <>
      {t('network.startToEndOfTotalCompanyCount', { start: startingIndex + 1, end: endingIndex, count: total })}
    </>
  );
};
