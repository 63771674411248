import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { identity, isFinite, isNil, isUndefined, mapValues, omitBy, pickBy, reject } from 'lodash';
import {
  Action,
  ExchangeDefinition,
  Attachment,
  CompanyMinimized,
  Bulletin,
  RfqEventChange,
  ExchangeChange,
  AssetChange,
  Stage,
  Draft,
  RevisionChange,
  UniversalBidStatus,
  BidStatus,
  RfqStatus,
  ExchangeRateDocument,
  PublishableSettings,
  UserId,
  CompanyId,
  QuestionExchangeDefinition,
  ExternalSystem,
  BulletinExchangeDefinition,
  RfxSpendAndSavings,
  RecipientSource,
  LotIntentionStatus,
  LotChange,
  LiveSettings,
  CostAndSavingsByRecipientId,
  AwardOrCloseRequestPayload,
  SavingsCalculationResultByRecipientIdBySavingsType,
} from '@deepstream/common/rfq-utils';
import { ModelLockState } from '@deepstream/common/eventSourcedModel';
import {
  Contract,
  ContractPage,
  ContractSection,
  ContractSummary,
  ReceivedContractOverview,
  SentContractOverview,
  ImportLegacyContractPayload,
  ImportLegacyContractError,
  ImportLegacyContractValidationPayload,
  ContractRoles,
  Reminder,
  ContractReporting,
  ContractHistoryEvent,
  Milestone,
} from '@deepstream/common/contract/contract';
import { ContractTemplate } from '@deepstream/common/contract/contractTemplate';
import { ProductTag } from '@deepstream/common/products';
import { DiscoveryQuery, RatingType, FeedbackContext, FeedbackMeta, ExtendedSupplierListOverview, SegmentContext, Sorting, SortingAccessor, SortingDirection, SupplierListOverview, SupplierListRule, SupplierListType, FeedbackEntityType } from '@deepstream/common';
import { TeamMembershipsByEntity, MembershipDecision, RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { ReceivedContractOverviews, SentContractOverviews } from '@deepstream/common/contract';

import {
  QuestionnaireTemplate,
  QuestionnaireTemplateOverviewsResponse,
  QuestionnaireSummary,
  QuestionnaireOverviewsResponse,
  Questionnaire,
  QuestionnaireTemplateVersion,
  QuestionnaireRenewalConfig,
  QuestionnaireInternalData,
  PreQualSuppliersResponse,
  QuestionnaireHistoryEvent,
  QuestionnaireRoles,
  UnsavedQuestionnaireTeamMember,
  QuestionnaireTemplateOverview,
  QuestionnaireExpiryConfig,
} from '@deepstream/common/preQual';
import { ProcessingTaskStatus } from '@deepstream/common/processingTask';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { stringify } from '@deepstream/ui-utils/queryParams';
import { TrackingEvent } from '@deepstream/common/featureTrackingEvent';
import {
  ProductSearchResult, SearchState, UploadPurpose, User,
  InvitedPerson, DashboardTemplate, SentRequestOverview,
  ReceivedRequestOverview, SentRequestOverviews, ReceivedRequestOverviews, OwnerFilterItem,
  BidStatusFilterItem, SenderFilterItem, CompanyRoles, UserPatch, RfxStructure, IntegrationDataType,
  ExchangeSnapshot, SystemFeatureFlags,
  PublicRequestOverviews, PublicBidIntention, PublicBidIntentionStatus, IdChanges, DashboardRole, RecipientFilterItem,
  AwardedRequestOverview, PreQualQuestion, CurrentUser, PreQualCategory, UserFilterItem,
  StatsByRecipientId,
} from './types';
import { Company as CompanyProfile, RequestTag } from './ui/types';
import { buildQueryParams } from './utils';
import { BasicTarget, Chat } from './modules/AI/chatbot/types';
import { RequestCommentsThread } from './modules/RequestComments/types';
import { ModelSizeLimits } from './modelSizeLimits';

export class ApiClient {
  instance: AxiosInstance;

  tokenGenerator: any;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      withCredentials: true,
      withXSRFToken: (config) => Boolean(config.withCredentials),
    });

    this.instance.interceptors.request.use(
      async config => {
        if (!config.withCredentials) {
          return config;
        }

        let token;

        try {
          token = await this.getToken();
        } catch (error) { } // eslint-disable-line no-empty

        return token
          ? {
            ...config,
            headers: { ...config.headers, Authorization: `Bearer ${token}` } as AxiosRequestHeaders,
          }
          : config;
      },
      error => {
        Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      response => response.config.responseType === 'blob'
        ? response
        : response.data,
    );
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
  }

  getToken() {
    return this.tokenGenerator
      ? this.tokenGenerator()
      : undefined;
  }

  getMe = () => this.instance.get<CurrentUser>('/ajax/currentUser');

  getMyCompanies = () =>
    this.instance
      .get<CompanyProfile[]>('/ajax/user/roles')
      .then((response: any) => {
        response.data = reject(response.data, { _id: '_app' });
        return response;
      });

  getUser = ({ userId }: { userId: string }) =>
    this.instance.get<User>(`/ajax/user/${userId}`);

  getTeamMembershipsByEntity = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get<TeamMembershipsByEntity>(`/ajax/company/${companyId}/user/${userId}/teamMembershipsByEntity`);

  sendResetPasswordEmail = ({ email }: { email: string }) =>
    this.instance.post('/login/reset', { email });

  searchCompanies = (searchState: Partial<SearchState> = {}) =>
    this.instance.get(`/ajax/companies/search?${stringify(searchState)}`);

  // Search companies in the DeepStream (external) network.
  // Includes aggregations as part of the result (used for dynamic filters)
  searchExternalCompaniesWithAggregations = ({
    currentCompanyId,
    searchState = {},
  }: {
    currentCompanyId: string;
    searchState: Partial<SearchState>;
  }) =>
    this.instance.get(`/ajax/companies/searchWithAggs/external/${currentCompanyId}?${stringify(searchState)}`);

  // Search companies in the current company's (internal) network.
  // Includes aggregations as part of the result (used for dynamic filters)
  searchInternalCompaniesWithAggregations = ({
    currentCompanyId,
    searchState = {},
  }: {
    currentCompanyId: string;
    searchState: Partial<SearchState>;
  }) =>
    this.instance.get(`/ajax/companies/searchWithAggs/internal/${currentCompanyId}?${stringify(searchState)}`);

  searchUserByEmail = (email: string) =>
    this.instance.get(`/ajax/user/search/${email}`);

  getSupplierLists = ({ companyId }: { companyId: string }) =>
    this.instance.get<SupplierListOverview[]>(`/ajax/company/${companyId}/supplier-lists`);

  getExtendedSupplierLists = ({ companyId }: { companyId: string }) =>
    this.instance.get<ExtendedSupplierListOverview[]>(`/ajax/company/${companyId}/supplier-lists?extended=true`);

  getSupplierList = ({ companyId, supplierListId }: { companyId: string; supplierListId: string }) =>
    this.instance.get<ExtendedSupplierListOverview>(`/ajax/company/${companyId}/supplier-list/${supplierListId}`);

  addSupplierList = ({
    companyId,
    name,
    description,
    listType,
    rules = [],
    supplierIds,
  }: {
    companyId: string;
    name: string;
    description: string;
    listType: SupplierListType;
    rules?: SupplierListRule[];
    supplierIds?: string[];
  }) =>
    this.instance.post(`/ajax/company/${companyId}/supplier-list`, { name, description, listType, rules, supplierIds });

  updateSupplierList = ({
    listId,
    companyId,
    name,
    description,
    listType,
    rules = [],
    supplierIds,
  }: {
    listId: string;
    companyId: string;
    name?: string;
    description?: string;
    listType?: SupplierListType;
    rules?: SupplierListRule[];
    supplierIds?: string[];
  }) =>
    this.instance.put(`/ajax/company/${companyId}/supplier-list/${listId}`, { name, description, listType, rules, supplierIds });

  updateSupplierListCompanies = ({
    companyId,
    supplierListId,
    addedCompanyIds,
    removedCompanyIds,
  }: {
    companyId: string;
    supplierListId: string;
    addedCompanyIds?: string[];
    removedCompanyIds?: string[];
  }) =>
    this.instance.put(`/ajax/company/${companyId}/supplier-lists/${supplierListId}/companies`, {
      addedCompanyIds,
      removedCompanyIds,
    });

  addSupplierListCompany = ({
    companyId,
    supplierListId,
    addedCompanyId,
  }: {
    companyId: string;
    supplierListId: string;
    addedCompanyId: string;
  }) =>
    this.instance.put(`/ajax/company/${companyId}/supplier-lists/${supplierListId}/add-company`, {
      addedCompanyId,
    });

  removeSupplierListCompany = ({
    companyId,
    supplierListId,
    removedCompanyId,
  }: {
    companyId: string;
    supplierListId: string;
    removedCompanyId: string;
  }) =>
    this.instance.put(`/ajax/company/${companyId}/supplier-lists/${supplierListId}/remove-company`, {
      removedCompanyId,
    });

  previewSuppliersForListRules = ({
    companyId,
    rules,
  }: {
    companyId: string;
    rules: SupplierListRule[];
  }) =>
    this.instance.post<{ _id: string; name: string }[]>(`/ajax/company/${companyId}/supplier-list/previewSuppliers`, { rules });

  deleteCompanyDocument = ({
    companyId,
    documentId,
  }: {
    companyId: string;
    documentId: string;
  }) =>
    this.instance.delete(`/ajax/company/${companyId}/document/${documentId}`);

  deleteSupplierList = ({
    companyId,
    supplierListId,
  }: {
    companyId: string;
    supplierListId: string;
  }) =>
    this.instance.delete(`/ajax/company/${companyId}/supplier-list/${supplierListId}`);

  updateCompanyDocument = ({
    companyId,
    documentId,
    payload,
  }: {
    companyId: string;
    documentId: string;
    payload: {
      expiryDate: string | null;
      name?: string;
      description?: string;
      attachmentId?: string;
      visibility?: string;
    };
  }) =>
    this.instance.put(`/ajax/company/${companyId}/document/${documentId}`, payload);

  createCompanyDocument = ({
    companyId,
    payload,
  }: {
    companyId: string,
    payload: {
      expiryDate: string | null;
      name: string;
      description: string;
      attachmentId: string;
      visibility: string;
    },
  }) =>
    this.instance.post(`/ajax/company/${companyId}/documents`, payload);

  getCompanyPublicDocuments = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/documents/public`);

  getPublicCompany = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}`);

  getIsCompanyProfileComplete = ({ companyId }: { companyId: string }) =>
    this.instance.get<boolean>(`/ajax/company/${companyId}/profile/status/complete`);

  getCompanyDocuments = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/documents`);

  getCompanyDocumentsHistory = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/documents/history`);

  getCompanyHistoryCSV = ({
    companyId,
    separator,
    locale,
  }: {
    companyId: string;
    separator?: 'comma' | 'semicolon' | 'space' | 'tab',
    locale: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale,
        csvSeparator: separator,
      } as Record<string, string>,
      isNil,
    ));

    return this.instance.get(
      `/ajax/company/${companyId}/documents/history/csv?${queryParams.toString()}`,
      { responseType: 'blob' },
    );
  };

  getDocumentDownloadUrl = ({
    currentCompanyId,
    targetCompanyId,
    documentId,
  }: {
    currentCompanyId: string;
    targetCompanyId: string;
    documentId: string;
  }) =>
    this.instance.post(`/ajax/company/${targetCompanyId}/document/${documentId}/downloads`, {
      downloaderCompanyId: currentCompanyId,
    });

  getDocumentsBatchDownloadUrl = ({
    companyId,
    documentIds,
    locale,
  }: {
    companyId: string;
    documentIds: string[];
    locale: string;
  }) => this.instance.post(`/ajax/company/${companyId}/documents/downloads`, {
    documentIds,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale,
  }, {
    responseType: 'blob',
  });

  downloadPreQualDocument = ({ currentCompanyId, attachmentId }: { currentCompanyId: string; attachmentId: string }) =>
    this.instance.post(`/ajax/pre-q/attachments/${attachmentId}/downloads`, {
      downloaderCompanyId: currentCompanyId,
    });

  downloadBlob = ({ method, url, data }: { method: 'GET' | 'POST'; url: string; data?: unknown }) =>
    this.instance({ method, url, data, responseType: 'blob' });

  getProductTags = () =>
    this.instance.get('/ajax/productTag');

  getProducts = ({ parent }: { parent: string }) => {
    const queryParams = new URLSearchParams(omitBy(
      { parent } as Record<string, string>,
      isNil,
    ));

    return this.instance.get<ProductTag[]>(`/ajax/products?${queryParams}`);
  };

  searchProducts = ({ text, codes }: { text?: string; codes?: string[] }) =>
    this.instance.post<ProductSearchResult>('/ajax/products/search', { text, codes });

  getRegionTags = () =>
    this.instance.get('/ajax/regionTags');

  getCountryCodes = () =>
    this.instance.get<string[]>('/ajax/countryCodes');

  getCurrencyCodes = () =>
    this.instance.get<string[]>('/ajax/currencyCodes');

  getExchangeRates = () =>
    this.instance.get<ExchangeRateDocument>('/ajax/exchangeRates');

  updateCompany = ({ companyId, companyPatch }: { companyId: string; companyPatch: any }) =>
    this.instance.put(`/ajax/company/${companyId}`, { _id: companyId, ...companyPatch });

  upload = ({
    companyId,
    purpose,
    file,
    onProgress,
  }: {
    companyId?: string;
    purpose: UploadPurpose;
    file: File;
    onProgress?: AxiosRequestConfig['onUploadProgress'];
  }) => {
    const formData = new FormData();

    formData.append('file', file);

    return this.instance.post(`/upload?purpose=${purpose}&companyId=${companyId}`, formData, {
      onUploadProgress: onProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  uploadAvatar = ({
    file,
    onProgress,
  }: {
    file: File;
    onProgress?: AxiosRequestConfig['onUploadProgress'];
  }) => {
    const formData = new FormData();

    formData.append('file', file);

    return this.instance.post('/ajax/avatars', formData, {
      onUploadProgress: onProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  getAvatar = ({ avatarId }: { avatarId: string }) => this.instance.get(`/ajax/avatar/${avatarId}`);

  getUserAvatar = ({ userId }: { userId: string }) => this.instance.get(`/ajax/user/${userId}/avatar`);

  getCompanyMembershipCount = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/members/count`);

  getCompanyInvites = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/sentInvites`);

  getCompanyUserInvites = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/invites/companyUser/${userId}`);

  getCompanyMemberEntitiesCounts = ({ companyId }: { companyId: string }) =>
    this.instance.get<{
      requestsCount: Record<string, number>;
      contractsCount: Record<string, number>;
    }>(`/ajax/company/${companyId}/members/entitiesCount`);

  getLegacyQuestionnaire = () =>
    this.instance.get('/ajax/pre-q/questionnaire/default');

  getAnswerSet = ({ currentCompanyId, companyId }: { currentCompanyId: string; companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/pre-q/answerset?currentCompanyId=${currentCompanyId}`);

  getAnswerSetAudit = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/pre-q/audit`);

  getUserRequestsList = ({
    currentCompanyId,
    pageIndex = 0,
    pageSize = 10,
    filter,
    sort,
  }: {
    currentCompanyId: string;
    pageIndex: number;
    pageSize: number;
    sort?: {
      accessor: 'status' | 'numRevisions' | 'createdAt' | 'issueDate' | 'lastEdited';
      direction: 'asc' | 'desc';
    },
    filter?: {
      statuses?: RfqStatus[];
      ids?: string[];
      type: 'sent' | 'received';
    },
  }) => {
    const serializedFilter = typeof filter === 'object' ? JSON.stringify(omitBy(filter, isNil)) : undefined;
    const serializedSort = typeof sort === 'object' ? JSON.stringify(omitBy(sort, isNil)) : undefined;

    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | null | undefined>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy(
      {
        filter: serializedFilter,
        sort: serializedSort,
        pageIndex: isFinite(pageIndex) ? `${pageIndex}` : null,
        pageSize: isFinite(pageSize) ? `${pageSize}` : null,
      }, isNil,
    ));

    return this.instance.get<{
      rfqs: {
        _id: string;
        status: RfqStatus;
        extendedStatus: RfqStatus;
        universalBidStatus?: UniversalBidStatus,
        bidStatus?: BidStatus;
        type: 'received' | 'sent';
        subject: string;
      }[];
      totalItems: number;
      pageIndex: number;
    }>(`/ajax/company/${currentCompanyId}/rfq/list?${queryParams.toString()}`);
  };

  getCompanyRequestTags = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/requestTags`);

  getCompanyRequestTag = ({ companyId, requestTagId }: { companyId: string; requestTagId: string }) =>
    this.instance.get(`/ajax/company/${companyId}/requestTags/${requestTagId}`);

  createCompanyRequestTag = ({ companyId, requestTag }: { companyId: string; requestTag: RequestTag }) =>
    this.instance.post(`/ajax/company/${companyId}/requestTags`, requestTag);

  updateCompanyRequestTag = ({ companyId, requestTag }: { companyId: string; requestTag: RequestTag }) =>
    this.instance.put(`/ajax/company/${companyId}/requestTags/${requestTag._id}`, requestTag);

  deleteCompanyRequestTag = ({ companyId, requestTagId }: { companyId: string; requestTagId: string }) =>
    this.instance.delete(`/ajax/company/${companyId}/requestTags/${requestTagId}`);

  updateAnswerSet = ({ companyId, answers }: { companyId: string; answers: any }) =>
    this.instance.post(`/ajax/company/${companyId}/answerset`, {
      answers,
    });

  updatePreQualAccess = ({ currentCompanyId, companyId, action }: { currentCompanyId: string; companyId: string; action: 'approve' | 'deny' }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/pre-q/access-request/${companyId}`, {
      action,
    });

  getPreQualAccessStatus = ({ currentCompanyId, companyId }: { currentCompanyId: string; companyId: string }) =>
    this.instance.get<'denied' | 'approved' | 'pending'>(`/ajax/company/${companyId}/pre-q/access-request/${currentCompanyId}`);

  requestPreQualAccess = ({ currentCompanyId, companyId }: { currentCompanyId: string; companyId: string }) =>
    this.instance.post(`/ajax/company/${companyId}/pre-q/access-requests`, {
      currentCompanyId,
    });

  deleteLegacyPreQual = ({ currentCompanyId }: { currentCompanyId: string }) =>
    this.instance.delete(`/ajax/company/${currentCompanyId}/pre-q`);

  getPreQualQuestionsList = ({ currentCompanyId, categoryId }: { currentCompanyId: string; categoryId?: string; }) => {
    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | undefined>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy(
      { categoryId }, isNil,
    ));

    return this.instance.get<PreQualQuestion[]>(`/ajax/company/${currentCompanyId}/preQual/questions?${queryParams.toString()}`);
  };

  getPreQualArchivedQuestions = ({ currentCompanyId }: { currentCompanyId: string }) => {
    return this.instance.get<PreQualQuestion[]>(`/ajax/company/${currentCompanyId}/preQual/questions/archived`);
  };

  getArchivedQuestionCategory = ({ questionId, currentCompanyId }: { questionId: string; currentCompanyId: string }) => {
    return this.instance.get<PreQualCategory>(`/ajax/company/${currentCompanyId}/preQual/category/archivedQuestion/${questionId}`);
  };

  getPreQualCategoriesList = ({
    currentCompanyId,
    filter,
  }: {
    currentCompanyId: string;
    filter?: { categoryIds?: string[] },
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ filter }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<PreQualCategory[]>(`/ajax/company/${currentCompanyId}/preQual/categories?${queryParams.toString()}`);
  };

  checkPreQualCategoryName = ({
    currentCompanyId,
    name,
  }: {
    currentCompanyId: string;
    name: string;
  }) => {
    return this.instance.get<boolean>(`/ajax/company/${currentCompanyId}/preQual/category/validate?name=${name}`);
  };

  createPreQualQuestion = ({
    currentCompanyId,
    categoryId,
    exchangeDef,
  }: {
    currentCompanyId: string;
    categoryId: string;
    exchangeDef: QuestionExchangeDefinition;
  }) =>
    this.instance.post<PreQualQuestion>(`/ajax/company/${currentCompanyId}/preQual/question`, {
      categoryId,
      exchangeDef,
    });

  updatePreQualQuestion = ({
    currentCompanyId,
    questionId,
    categoryId,
    exchangeDef,
  }: {
    currentCompanyId: string;
    questionId: string;
    categoryId: string;
    exchangeDef: QuestionExchangeDefinition;
  }) =>
    this.instance.put(`ajax/company/${currentCompanyId}/preQual/question/${questionId}`, {
      categoryId,
      exchangeDef,
    });

  archivePreQualQuestion = ({
    currentCompanyId,
    questionId,
  }: {
    currentCompanyId: string;
    questionId: string;
  }) =>
    this.instance.put(`ajax/company/${currentCompanyId}/preQual/question/${questionId}/archive`);

  restorePreQualQuestion = ({
    currentCompanyId,
    questionId,
    categoryId,
  }: {
    currentCompanyId: string;
    questionId: string;
    categoryId: string;
  }) =>
    this.instance.put(`ajax/company/${currentCompanyId}/preQual/question/${questionId}/restore`, { categoryId });

  createPreQualCategory = ({
    currentCompanyId,
    name,
  }: {
    currentCompanyId: string;
    name: string;
  }) =>
    this.instance.post<{ _id: string }>(`/ajax/company/${currentCompanyId}/preQual/category`, {
      name,
    });

  updatePreQualCategory = ({
    currentCompanyId,
    categoryId,
    name,
  }: {
    currentCompanyId: string;
    categoryId: string;
    name: string;
  }) =>
    this.instance.put<{ _id: string }>(`/ajax/company/${currentCompanyId}/preQual/category/${categoryId}`, {
      name,
    });

  deletePreQualCategory = ({
    currentCompanyId,
    categoryId,
    replacementCategoryId,
  }: {
    currentCompanyId: string;
    categoryId: string;
    replacementCategoryId?: string;
  }) => {
    const queryParams = new URLSearchParams(replacementCategoryId ? { replacementCategoryId } : undefined);

    return this.instance.delete<boolean>(`/ajax/company/${currentCompanyId}/preQual/category/${categoryId}?${queryParams.toString()}`);
  };

  moveRecipientsToNextStage = ({
    companyId,
    rfqId,
    payload,
  }: {
    companyId: string;
    rfqId: string;
    payload: { recipientId: string; nextStageId: string }[];
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/moveToNextStage`,
      { payload },
    );

  sendInvites = ({
    companyId,
    company,
    users,
    message,
    locale,
    proxyUserId,
    invitedFor,
  }: {
    message: string;
    locale: string;
    companyId: string;
    users: InvitedPerson[];
    company: CompanyMinimized;
    proxyUserId?: string;
    invitedFor?: 'preQualification' | 'requests';
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/invites`,
      { company, users, message, locale, proxyUserId, invitedFor },
    );

  bulkValidateInviteModal = ({
    invites,
    companyId,
  }: {
    invites: string[];
    companyId: string;
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/invites/bulkValidation`,
      { invites },
    );

  sendInviteReminders = ({
    rfqId,
    companyId,
    recipientIds,
  }: {
    rfqId: string;
    companyId: string;
    recipientIds: string[];
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/invites/reminders`,
      { recipientIds },
    );

  unlockItems = ({
    companyId,
    rfqId,
    sectionIds,
    recipientIds,
    exchangeIds,
  }: {
    companyId: string;
    rfqId: string;
    sectionIds: string[];
    recipientIds: string[];
    exchangeIds: string[];
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/exchanges/unlock`,
      { companyId, sectionIds, recipientIds, exchangeIds },
    );

  updateRequestSettings = ({
    companyId,
    rfqId,
    settings,
    isTemplate,
    isRevising,
  }: {
    companyId: string;
    rfqId: string;
    settings: Partial<PublishableSettings>;
    isTemplate: boolean;
    isRevising?: boolean;
  }) => {
    if (isTemplate) {
      return this.instance.put(
        `/ajax/company/${companyId}/template/${rfqId}/settings`,
        { settings },
      );
    } else {
      return this.instance.put(
        `/ajax/company/${companyId}/rfq/${rfqId}/settings`,
        { settings, isRevising },
      );
    }
  };

  updateRequestLiveSettings = ({
    companyId,
    rfqId,
    settings,
  }: {
    companyId: string;
    rfqId: string;
    settings: Partial<LiveSettings>;
  }) =>
    this.instance.put(
      `/ajax/company/${companyId}/rfq/${rfqId}/liveSettings`,
      { settings },
    );

  getRfqComputedCostAndSavings = ({
    rfqId,
    currentCompanyId,
    awardedSupplierIds,
  }: {
    rfqId: string;
    currentCompanyId: string;
    awardedSupplierIds?: string[];
  }) => {
    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | null>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy(
      {
        awardedSupplierIds: awardedSupplierIds ? JSON.stringify(awardedSupplierIds) : null,
      },
      isNil,
    ));

    return this.instance.get<{
      totalRequestValue: number | null;
      calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType;
    }>(
      `/ajax/company/${currentCompanyId}/rfq/${rfqId}/calculatedCostAndSavings?${queryParams.toString()}`,
      {},
    );
  };

  getRfqCostAndSavingsData = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) => this.instance.get<{ isLocked: true } | {
    isLocked: false;
    costAndSavingsByRecipientId: CostAndSavingsByRecipientId;
    exchangeRates: ExchangeRateDocument;
  }>(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/costAndSavingsData`);

  awardBid = ({
    rfqId,
    companyId,
    awarded,
    unsuccessful,
    spendAndSavings,
  }: {
    rfqId: string;
    companyId: string;
    awarded: any;
    unsuccessful: any;
    spendAndSavings: RfxSpendAndSavings;
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/awards`,
      pickBy({ awarded, unsuccessful, spendAndSavings }),
    );

  rejectBids = ({
    rfqId,
    companyId,
    unsuccessful,
  }: {
    rfqId: string;
    companyId: string;
    unsuccessful: any;
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/reject`,
      unsuccessful,
    );

  reinstateBids = ({
    rfqId,
    companyId,
    outcome,
  }: {
    rfqId: string;
    companyId: string;
    outcome: any;
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/reinstate`,
      outcome,
    );

  closeRequest = ({
    rfqId,
    companyId,
    message,
    attachments,
  }: {
    rfqId: string;
    companyId: string;
    message: string;
    attachments: any;
  }) =>
    this.instance.post(
      `/ajax/company/${companyId}/rfq/${rfqId}/close`,
      { message, attachments },
    );

  awardOrCloseRequest = ({
    rfqId,
    currentCompanyId,
    payload,
  }: {
    rfqId: string;
    currentCompanyId: string;
    payload: AwardOrCloseRequestPayload;
  }) =>
    this.instance.post(
      `/ajax/company/${currentCompanyId}/rfq/${rfqId}/awardOrClose`,
     payload,
    );

  changeExchanges = ({
    currentCompanyId,
    rfqId,
    changes,
  }: {
    rfqId: string;
    currentCompanyId: string;
    changes: ExchangeChange[];
  }) =>
    this.instance.post(
      `/ajax/rfq/${rfqId}/exchanges`,
      { currentCompanyId, changes },
    );

  initiateExchanges = ({
    rfqId,
    senderId,
    recipientId,
    currentCompanyId,
    sectionId,
    exchangeDefs,
  }: {
    rfqId: string;
    senderId: string;
    recipientId: string;
    currentCompanyId: string;
    sectionId: string;
    exchangeDefs: ExchangeDefinition[];
  }) =>
    this.instance.post(
      `/ajax/company/${senderId}/rfq/${rfqId}/bid/${recipientId}/exchanges`,
      { companyId: currentCompanyId, sectionId, exchangeDefs },
    );

  broadcastChatMessage = ({
    rfqId,
    senderId,
    currentCompanyId,
    auctionId,
    message,
  }: {
    rfqId: string;
    senderId: string;
    currentCompanyId: string;
    auctionId: string;
    message: string;
  }) =>
    this.instance.post(
      `/ajax/company/${senderId}/rfq/${rfqId}/auction/${auctionId}/chat-broadcast`,
      { message, currentCompanyId },
    );

  sendExchangeReply = ({
    rfqId,
    senderId,
    recipientId,
    exchangeId,
    currentCompanyId,
    action,
  }: {
    rfqId: string;
    senderId: string;
    recipientId?: string;
    exchangeId: string;
    currentCompanyId: string;
    action: Partial<Action>;
  }) =>
    recipientId
      ? this.instance.post(
        `/ajax/company/${senderId}/rfq/${rfqId}/bid/${recipientId}/exchangeReplies`,
        { companyId: currentCompanyId, exchangeId, action },
      )
      : this.instance.post(
        `/ajax/rfq/${rfqId}/document/${exchangeId}/actions`,
        { companyId: currentCompanyId, action },
      );

  sendBatchedExchangeReplies = ({
    rfqId,
    senderId,
    recipientId,
    actions,
  }: {
    rfqId: string;
    senderId: string;
    recipientId?: string;
    actions: Array<{
      exchangeId: string;
      companyId: string;
      action: Partial<Action>;
    }>;
  }) =>
    this.instance.post(
      `/ajax/company/${senderId}/rfq/${rfqId}/bid/${recipientId}/batchExchangeReplies`,
      { actions },
    );

  markAllNotificationsAsRead = ({ companyId }: { companyId: string }) =>
    this.instance.post(
      '/ajax/notifications/markAllAsRead',
      { companyId },
    );

  markNotificationsAsRead = ({ notificationIds }: { notificationIds: string[] }) =>
    this.instance.post(
      '/ajax/notifications/markAsRead',
      { notificationIds },
    );

  markNotificationsAsUnread = ({ notificationIds }: { notificationIds: string[] }) =>
    this.instance.post(
      '/ajax/notifications/markAsUnread',
      { notificationIds },
    );

  deleteNotifications = ({ notificationIds }: { notificationIds: string[] }) =>
    this.instance.post(
      '/ajax/notifications/delete',
      { notificationIds },
    );

  getNotifications = ({ offset, limit, filter, companyId }) => {
    const serializedFilter = typeof filter === 'object' && JSON.stringify(omitBy(filter, isNil));
    const queryParams = new URLSearchParams(omitBy(
      { companyId, offset, limit, filter: serializedFilter },
      isNil,
    ));

    return this.instance.get<{ notifications: any[]; totalItems: number }>(`/ajax/notifications?${queryParams.toString()}`);
  };

  createEventlessTemplateFromRequest = ({
    templateName,
    rfqId,
    currentCompanyId,
  }: { templateName: string; rfqId: string; currentCompanyId: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/template`, { templateName });

  addRequestCreatedEventFromRequest = ({
    templateId,
    rfqId,
    currentCompanyId,
    locale,
  }: { templateId: string; rfqId: string; currentCompanyId: string, locale: string }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/template/${templateId}/requestCreated`, { rfqId, locale });

  createEventlessTemplateFromTemplate = ({
    currentCompanyId,
    templateId,
    locale,
  }: { currentCompanyId: string; templateId: string; locale: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/template/${templateId}/duplicate`, { locale });

  addRequestCreatedEventFromTemplateToTemplate = ({
    currentCompanyId,
    templateId,
    modelTemplateId,
  }: { currentCompanyId: string; templateId: string; modelTemplateId: string }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/template/${templateId}/requestCreated`, { modelTemplateId });

  getTemplates = ({ companyId }: { companyId: string }) =>
    this.instance.get<DashboardTemplate[]>(`/ajax/companyId/${companyId}/templates`);

  getTemplate = ({ currentCompanyId, templateId }: { currentCompanyId: string; templateId: string }) =>
    this.instance.get(`/ajax/company/${currentCompanyId}/template/${templateId}`);

  deleteTemplate = ({ companyId, templateId }: { companyId: string; templateId: string }) =>
    this.instance.delete(`/ajax/companyId/${companyId}/template/${templateId}`);

  deleteRequest = ({ companyId, requestId }: { companyId: string; requestId: string }) =>
    this.instance.delete(`/ajax/company/${companyId}/rfq/${requestId}`);

  createEmptyRequest = ({ companyId, stages }: { companyId: string; stages: Stage<Draft>[] }) =>
    this.instance.post(`/ajax/company/${companyId}/rfqs`, { stages });

  createEventlessRequestFromTemplate = ({ currentCompanyId, templateId }: { currentCompanyId: string; templateId: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/rfqs/template/${templateId}`);

  addRequestCreatedEventFromTemplate = ({
    currentCompanyId,
    rfqId,
    templateId,
    options,
  }: { currentCompanyId: string; rfqId: string; templateId: string; options?: any }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/requestCreated`, { templateId, ...options });

  createBlankTemplate = ({ companyId, stages, changes }: { companyId: string; stages: Stage<Draft>[]; changes: any[] }) =>
    this.instance.post(`/ajax/company/${companyId}/template`, { stages, changes });

  updateTemplateField = ({ companyId, templateId, changes }: { companyId: string; templateId: string; changes: any[] }) =>
    this.instance.put(`/ajax/company/${companyId}/template/${templateId}`, { changes });

  getSentUserDashboardRequests = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => {
    const queryParams = new URLSearchParams({
      pageSize: '20',
      sort: JSON.stringify([{
        accessor: SortingAccessor.creationDate,
        direction: SortingDirection.DESC,
      }]),
    });

    return this.instance.get<SentRequestOverview[]>(
      `/ajax/rfq/sent/userDashboard/${currentCompanyId}?${queryParams.toString()}`,
    );
  };

  getReceivedUserDashboardRequests = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => {
    const queryParams = new URLSearchParams({
      pageSize: '20',
      sort: JSON.stringify([{
        accessor: SortingAccessor.issueDate,
        direction: SortingDirection.DESC,
      }]),
    });

    return this.instance.get<ReceivedRequestOverview[]>(
      `/ajax/rfq/received/userDashboard/${currentCompanyId}?${queryParams.toString()}`,
    );
  };

  getSentRequests = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    search,
    sort,
    filter,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    search?: {
      text: string;
    };
    sort: Sorting;
    filter: { [key: string]: any[] };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<SentRequestOverviews>(
      `/ajax/company/${currentCompanyId}/rfx/sent?${queryParams.toString()}`,
    );
  };

  getDraftRequests = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    search,
    sort,
    filter,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    search?: {
      text: string;
    };
    sort: Sorting;
    filter: { [key: string]: any[] };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<SentRequestOverviews>(
      `/ajax/company/${currentCompanyId}/rfx/draft?${queryParams.toString()}`,
    );
  };

  getReceivedRequests = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    search,
    sort,
    filter,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    search?: {
      text: string;
    };
    sort: Sorting;
    filter: { [key: string]: any[] };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<ReceivedRequestOverviews>(
      `/ajax/company/${currentCompanyId}/rfx/received?${queryParams.toString()}`,
    );
  };

  getPublicRequests = ({
    pageSize,
    pageIndex,
    search,
    sort,
    filter,
  }: {
    pageSize: number;
    pageIndex: number;
    sort: Sorting;
    search?: {
      text: string;
    };
    filter: { [key: string]: any[] };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<PublicRequestOverviews>(
      `/ajax/rfx/public?${queryParams.toString()}`,
    );
  };

  getSentRequestFilterItems = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => this.instance.get<{
    statuses: string[];
    users: UserFilterItem[];
    recipients: RecipientFilterItem[];
  }>(
    `/ajax/company/${currentCompanyId}/rfx/sent/filterItems`,
  );

  getReceivedRequestFilterItems = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => this.instance.get<{
    bidStatuses: BidStatusFilterItem[];
    universalBidStatuses: UniversalBidStatus[];
    senders: SenderFilterItem[];
    roles: DashboardRole[];
  }>(
    `/ajax/company/${currentCompanyId}/rfx/received/filterItems`,
  );

  getPublicRequestFilterItems = () => this.instance.get<{
    senders: SenderFilterItem[];
  }>(
    '/ajax/rfx/public/filterItems',
  );

  getRequestsSentAsCreator = ({ companyId }: { companyId: string }) =>
    this.instance.get<{ _id: string; subject: string }[]>(`/ajax/rfq/sent/dashboard/${companyId}/creator`);

  getAwardedRequestsSent = ({ companyId }: { companyId: string }) =>
    this.instance.get<AwardedRequestOverview[]>(`/ajax/rfq/sent/dashboard/${companyId}/awarded`);

  getAwardedRequestSent = ({ companyId, requestId }: { companyId: string; requestId: string }) =>
    this.instance.get<AwardedRequestOverview>(`/ajax/company/${companyId}/rfq/${requestId}/awarded`);

  getCompanyUsers = ({ companyId }: { companyId: string }) =>
    this.instance.get<User[]>(`/ajax/company/${companyId}/members`);

  getCompanyUsersByRole = ({ companyId, role, includeIfPending }: { companyId: string; role: 'receiveRFQ' | 'receiveContracts', includeIfPending?: string[] }) => {
    return this.instance.post<{
      auth0IsFederated: boolean;
      email: string;
      firstName: string;
      initials: string;
      jobTitles: Record<string, unknown>,
      lastName: string;
      name: string;
      _id: string;
    }[]>(`/ajax/company/${companyId}/users/role/${role}`, { includeIfPending });
  };

  getUsersForCompany = ({ companyId }: { companyId: string }) =>
    this.instance.get<User[]>(`/ajax/usersForCompany/${companyId}`);

  updateCompanyRoles = ({
    userId,
    companyId,
    roleRestrictionProfile,
    roles,
    questionnaireReplacementUserId,
    rfxReplacementUserId,
    contractReplacementUserId,
  }: {
    userId: string;
    companyId: string;
    roleRestrictionProfile: RoleRestrictionProfile;
    roles: CompanyRoles;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) =>
    this.instance.put(`/ajax/company/${companyId}/member/${userId}/roles`, {
      roleRestrictionProfile,
      roles,
      ...(pickBy({ questionnaireReplacementUserId, rfxReplacementUserId, contractReplacementUserId }, identity)),
    });

  removeUserFromCompany = ({
    companyId,
    userId,
    questionnaireReplacementUserId,
    rfxReplacementUserId,
    contractReplacementUserId,
  }: {
    companyId: string;
    userId: string;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      { questionnaireReplacementUserId, rfxReplacementUserId, contractReplacementUserId } as Record<string, string>,
      isNil,
    ));

    return this.instance.delete(`/ajax/company/${companyId}/user/${userId}?${queryParams.toString()}`);
  };

  verifyCompany = ({ companyId, userId, accepted }) =>
    this.instance.post(`/ajax/verifyCompany/${userId}/${companyId}`, { accepted });

  addTeamMember = ({
    companyId,
    user,
  }: {
    companyId: string;
    user: {
      email: string;
      roleRestrictionProfile: RoleRestrictionProfile;
      roles: CompanyRoles;
    };
  }) => this.instance.put(`/ajax/company/${companyId}/member`, user);

  getMembershipRequests = ({ companyId }: { companyId: string }) =>
    this.instance.get<User[]>(`/ajax/company/${companyId}/users/pending`);

  applyMembershipDecision = ({
    companyId,
    userId,
    decision,
    roleRestrictionProfile,
    roles,
  }: {
    companyId: string;
    userId: string;
    decision: MembershipDecision;
    roleRestrictionProfile?: RoleRestrictionProfile;
    roles?: CompanyRoles;
  }) => this.instance.put(
    `/ajax/company/${companyId}/user/${userId}/membership/${decision}`,
    omitBy({ roleRestrictionProfile, roles }, isUndefined),
  );

  updateUserProfile = (patch: Partial<UserPatch>) =>
    this.instance.put('/ajax/user', patch);

  updateUserPassword = () => this.instance.put<{ ticket: string }>('/ajax/user/password');

  requestUserEmailUpdate = ({ email }: { email: string }) =>
    this.instance.put('/ajax/user/email', {
      email,
    });

  cancelUserEmailUpdate = () =>
    this.instance.delete('/ajax/user/email/pending');

  addInternalDocument = ({
    rfqId,
    companyId,
    document,
    stageId,
  }: {
    rfqId: string;
    companyId: string;
    stageId: string;
    document: {
      name: string;
      comment: string;
      attachment: Attachment;
    };
  }) =>
    this.instance.post<{ _id: string }>(`/ajax/rfq/${rfqId}/internal/documents`, {
      companyId,
      document,
      stageId,
    });

  // TODO we should split this into explicit requests for
  // 1) bid structure (with recipientId, buyers and suppliers)
  // 2) live structure (without recipientId, buyer-only)
  getRfqBidStructure = ({
    currentCompanyId,
    rfqId,
    recipientId,
  }: {
    currentCompanyId: string;
    rfqId: string;
    recipientId?: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      { currentCompanyId, recipientId } as Record<string, string>,
      isNil,
    ));

    return this.instance.get<RfxStructure>(`/ajax/rfq/${rfqId}/structure?${queryParams.toString()}`);
  };

  getRfqDraftStructure = ({
    currentCompanyId,
    rfqId,
    isTemplate,
  }: {
    currentCompanyId: string;
    rfqId: string;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.get<RfxStructure<Draft>>(`/ajax/company/${currentCompanyId}/template/${rfqId}/structure`)
      : this.instance.get<RfxStructure<Draft>>(`/ajax/rfq/${rfqId}/draft/structure?currentCompanyId=${currentCompanyId}`);

  getRfqPublicStructure = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId
  }) =>
    currentCompanyId
      ? this.instance.get<RfxStructure>(`/ajax/rfq/${rfqId}/public/structure?currentCompanyId=${currentCompanyId}`)
      : this.instance.get<RfxStructure>(`/ajax/rfq/${rfqId}/public/structure`);

  getRfqPublicPreviewStructure = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: CompanyId;
  }) => this.instance.get<RfxStructure>(`/ajax/rfq/${rfqId}/publicPreview/structure?currentCompanyId=${currentCompanyId}`);

  getRfqApprovalExchanges = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) => this.instance.get<ExchangeSnapshot[]>(`/ajax/rfq/${rfqId}/draft/approvalExchanges?currentCompanyId=${currentCompanyId}`);

  getRfqExchange = ({
    rfqId,
    exchangeId,
    recipientId,
    currentCompanyId,
  }: {
    rfqId: string;
    exchangeId: string;
    recipientId?: string;
    currentCompanyId?: string;
  }) => {
    if (!exchangeId) {
      throw new Error('missing exchange id');
    }

    const queryParams = new URLSearchParams(omitBy(
      { currentCompanyId, recipientId } as Record<string, string>,
      isNil,
    ));

    return this.instance.get<ExchangeSnapshot>(`/ajax/rfq/${rfqId}/exchange/${exchangeId}?${queryParams.toString()}`);
  };

  getRfqExchanges = ({
    rfqId,
    sectionIds,
    recipientId,
    currentCompanyId,
  }: {
    rfqId: string;
    sectionIds: string[];
    recipientId?: string;
    currentCompanyId?: string;
  }) => {
    const queryParams = buildQueryParams(omitBy(
      { sectionIds, currentCompanyId, recipientId } as Record<string, string | string[]>,
      isNil,
    ));

    return this.instance.get<ExchangeSnapshot[]>(`/ajax/rfq/${rfqId}/exchanges?${queryParams.toString()}`);
  };

  getRfqExchangeByRecipient = ({
    rfqId,
    currentCompanyId,
    exchangeIdByRecipientId,
  }: {
    rfqId: string;
    currentCompanyId?: string;
    exchangeIdByRecipientId: Record<string, string>;
  }) => this.instance.post<Record<string, ExchangeSnapshot>>(`/ajax/rfq/${rfqId}/exchangeByRecipient`, { currentCompanyId, exchangeIdByRecipientId });

  getBulletinExchangeDefs = ({
    rfqId,
    sectionId,
    currentCompanyId,
  }: {
    rfqId: string;
    sectionId: string;
    currentCompanyId?: string;
  }) => {
    const queryParams = buildQueryParams(omitBy(
      { currentCompanyId, sectionId } as Record<string, string | string[]>,
      isNil,
    ));

    return this.instance.get<BulletinExchangeDefinition[]>(`/ajax/rfq/${rfqId}/bulletinExchangeDefs?${queryParams.toString()}`);
  };

  getAllExchanges = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) => this.instance.get<ExchangeSnapshot[]>(`/ajax/rfq/${rfqId}/live/allExchanges?currentCompanyId=${currentCompanyId}`);

  getStatsByRecipientId = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) => this.instance.get<StatsByRecipientId>(`/ajax/rfq/${rfqId}/live/statsByRecipientId?currentCompanyId=${currentCompanyId}`);

  getDeprecatedBulletins = ({ currentCompanyId, rfqId }: { currentCompanyId: string; rfqId: string }) =>
    this.instance.get(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/bulletins`);

  createDeprecatedBulletin = ({ currentCompanyId, rfqId, message }: { currentCompanyId: string; rfqId: string; message: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/bulletins`, { message });

  updateDeprecatedBulletin = ({ currentCompanyId, bulletin }: { currentCompanyId: string; bulletin: Bulletin }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${bulletin.rfqId}/bulletins/${bulletin._id}`, bulletin);

  updateDraftRequest = ({
    currentCompanyId,
    rfqId,
    changes,
    isRevising,
    isTemplate,
    message,
  }: {
    currentCompanyId: string;
    rfqId: string;
    changes: RfqEventChange[];
    isRevising?: boolean;
    isTemplate?: boolean;
    message?: string;
  }) => {
    if (isTemplate) {
      return this.instance.put(`/ajax/company/${currentCompanyId}/template/${rfqId}`, {
        changes,
      });
    } else {
      return this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}`, {
        changes,
        isRevising,
        message,
      });
    }
  };

  updateDraftLotsAndAwardScenarios = ({
    currentCompanyId,
    rfqId,
    lotChanges,
    sectionChanges,
    settingsUpdates,
    isRevising,
    isTemplate,
    message,
  }: {
    currentCompanyId: string;
    rfqId: string;
    lotChanges: LotChange[];
    sectionChanges?: RfqEventChange[];
    settingsUpdates: Partial<RfxStructure['settings']>;
    isRevising?: boolean;
    isTemplate?: boolean;
    message?: string;
  }) => {
    if (isTemplate) {
      return this.instance.put(`/ajax/company/${currentCompanyId}/template/${rfqId}/lotsAndAwardScenarios`, {
        lotChanges,
        sectionChanges,
        settingsUpdates,
      });
    } else {
      return this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/lotsAndAwardScenarios`, {
        lotChanges,
        sectionChanges,
        settingsUpdates,
        isRevising,
        message,
      });
    }
  };

  updateRequestCurrency = ({
    currentCompanyId,
    rfqId,
    isTemplate,
    currencyCode,
  }: {
    currentCompanyId: string;
    rfqId: string;
    isTemplate?: boolean;
    currencyCode: string;
  }) => {
    if (isTemplate) {
      return this.instance.put(`/ajax/company/${currentCompanyId}/template/${rfqId}/currency`, {
        currencyCode,
      });
    } else {
      return this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/currency`, {
        currencyCode,
      });
    }
  };

  updateSpendAndSavings = ({
    currentCompanyId,
    rfqId,
    isTemplate,
    spendAndSavings,
  }: {
    currentCompanyId: string;
    rfqId: string;
    isTemplate?: boolean;
    spendAndSavings: Partial<RfxSpendAndSavings>;
  }) => {
    if (isTemplate) {
      return this.instance.put(`/ajax/company/${currentCompanyId}/template/${rfqId}/spendAndSavings`, {
        spendAndSavings,
      });
    } else {
      return this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/spendAndSavings`, {
        spendAndSavings,
      });
    }
  };

  updateBidTeam = ({
    rfqId,
    senderId,
    recipientId,
    changes,
    isSender,
    currentCompanyId,
  }: {
    rfqId: string;
    senderId: string;
    recipientId: string;
    changes: RfqEventChange[];
    isSender?: boolean;
    currentCompanyId: string;
  }) => isSender
    ? this.instance.put(`/ajax/company/${senderId}/rfq/${rfqId}/bid/${recipientId}/team/sender`, { changes, currentCompanyId })
    : this.instance.put(`/ajax/company/${senderId}/rfq/${rfqId}/bid/${recipientId}/team`, { changes });

  updateExchanges = ({ currentCompanyId, rfqId, recipientId, sectionId, changes }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/bid/${recipientId}/exchanges`, {
      companyId: currentCompanyId,
      sectionId,
      changes,
    });

  getRfqAttachmentDownloadUrl = ({
    rfqId,
    attachmentId,
    isTemplate,
  }: {
    rfqId: string;
    attachmentId: string;
    isTemplate?: boolean;
  }) => {
    if (isTemplate) {
      return this.instance.post(`/ajax/template/${rfqId}/attachments/${attachmentId}/downloads`);
    } else {
      return this.instance.post(`/ajax/rfq/${rfqId}/attachments/${attachmentId}/downloads`);
    }
  };

  getOwnAttachmentDownloadUrl = ({ attachmentId }: { attachmentId: string; }) =>
    this.instance.get(`/download/own/${attachmentId}`);

  getAssets = ({ companyId, type }: { companyId: string; type: string }) =>
    this.instance.get(`/ajax/company/${companyId}/assets/${type}`);

  createAsset = ({ companyId, type, name }: { companyId: string; type: string; name: string }) =>
    this.instance.post(`/ajax/company/${companyId}/assets`, { type, name });

  updateBidAssets = ({
    rfqId,
    recipientId,
    companyId,
    sectionId,
    changes,
  }: {
    rfqId: string;
    recipientId: string;
    companyId: string;
    sectionId: string;
    changes: AssetChange[];
  }) =>
    this.instance.post(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/assets`,
      {
        companyId,
        sectionId,
        changes,
      },
    );

  reenableBidAssetResponses = ({
    rfqId,
    recipientId,
    companyId,
    sectionId,
    assetId,
    assetName,
    extendedStageId,
  }: {
    rfqId: string;
    recipientId: string;
    companyId: string;
    sectionId: string;
    assetId?: string;
    assetName?: string;
    extendedStageId: string;
  }) =>
    this.instance.post(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/assets/reenableResponses`,
      {
        companyId,
        sectionId,
        assetId,
        assetName,
        extendedStageId,
      },
    );

  validateBidExcel = ({
    rfqId,
    recipientId,
    attachmentId,
  }: {
    rfqId: string,
    recipientId: string,
    attachmentId: string,
  }) => {
    return this.instance.post(`/ajax/rfq/${rfqId}/bid/${recipientId}/validateBidExcel`, {
      attachmentId,
    });
  };

  processLineItemsSections = ({
    rfqId,
    recipientId,
    attachmentId,
  }: {
    rfqId: string;
    recipientId: string;
    attachmentId: string;
  }) => {
    return this.instance.post(`/ajax/rfq/${rfqId}/bid/${recipientId}/processBidExcelLineItemsSections`, {
      attachmentId,
    });
  };

  processSectionLineItems = ({
    rfqId,
    recipientId,
    attachmentId,
    sectionId,
    locale,
  }: {
    rfqId: string;
    recipientId: string;
    attachmentId: string;
    sectionId: string;
    locale: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      {
        locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      } as Record<string, string>,
      isNil,
    ));

    return this.instance.post(`/ajax/rfq/${rfqId}/bid/${recipientId}/processBidExcelSectionLineItems?${queryParams.toString()}`, {
      attachmentId,
      sectionId,
    });
  };

  processSectionsLineItemsValidation = ({
    rfqId,
    recipientId,
    attachmentId,
    sectionsIds,
  }: {
    rfqId: string;
    recipientId: string;
    attachmentId: string;
    sectionsIds: string[];
  }) => {
    return this.instance.post(`/ajax/rfq/${rfqId}/bid/${recipientId}/processExcelSectionsLineItemsValidation`, {
      attachmentId,
      sectionsIds,
    });
  };

  sendIntention = ({
    rfqId,
    recipientId,
    stageId,
    intention,
    reason,
    isBidWithdrawn,
  }: {
    rfqId: string;
    recipientId: string;
    stageId: string;
    intention: boolean;
    reason?: string;
    isBidWithdrawn: boolean;
  }) =>
    this.instance.put(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/intent`,
      {
        stageId,
        intention,
        reason,
        isBidWithdrawn,
      },
    );

  declineToBidOnBehalf = ({
    rfqId,
    recipients,
    currentCompanyId,
  }: {
    rfqId: string;
    recipients: { companyId: string; reason: string; stageId: string }[];
    currentCompanyId: string;
  }) =>
    this.instance.put(
      `/ajax/rfq/${rfqId}/bid/declineToBidOnBehalf`,
      { recipients, currentCompanyId },
    );

  withdrawBid = ({
    rfqId,
    recipientId,
    stageId,
    reason,
  }: {
    rfqId: string;
    recipientId: string;
    stageId: string;
    reason?: string;
  }) =>
    this.instance.put(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/withdraw`,
      {
        stageId,
        reason,
      },
    );

  reopenBid = ({
    rfqId,
    recipientId,
    stageId,
  }: {
    rfqId: string;
    recipientId: string;
    stageId: string;
  }) =>
    this.instance.put(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/reopen`,
      {
        stageId,
      },
    );

  sendLotIntention = ({
    rfqId,
    recipientId,
    lotId,
    intention,
  }: {
    rfqId: string;
    recipientId: string;
    lotId: string;
    intention: LotIntentionStatus;
  }) =>
    this.instance.put(
      `/ajax/rfq/${rfqId}/bid/${recipientId}/lot/${lotId}/intent`,
      {
        intention,
      },
    );

  downloadBid = ({
    rfqId,
    recipientId,
    stageId,
    locale,
  }: {
    rfqId: string;
    recipientId: string;
    stageId: string;
    locale: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      {
        locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      } as Record<string, string>,
      isNil,
    ));

    return this.instance.get(`/ajax/rfq/${rfqId}/bid/${recipientId}/download-xlsx/${stageId}?${queryParams.toString()}`,
      { responseType: 'blob' },
    );
  };

  sendPublicBidIntention = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) =>
    this.instance.post(`/ajax/rfq/${rfqId}/bid/${currentCompanyId}/publicBidIntention`);

  getPublicBidIntention = ({
    rfqId,
    currentCompanyId,
  }: {
    rfqId: string;
    currentCompanyId: string;
  }) =>
    this.instance.get<PublicBidIntention>(`/ajax/rfq/${rfqId}/bid/${currentCompanyId}/publicBidIntention`);

  getPublicBidIntentions = ({
    rfqId,
    currentCompanyId,
    filter,
  }: {
    rfqId: string;
    currentCompanyId: string;
    filter?: {
      status?: PublicBidIntentionStatus[];
    },
  }) => {
    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | null>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy({
      currentCompanyId,
      filter: filter ? JSON.stringify(filter) : null,
    }, isNil));

    return this.instance.get<PublicBidIntention[]>(`/ajax/rfq/${rfqId}/publicBidIntentions?${queryParams.toString()}`);
  };

  decidePublicBidIntentions = ({
    rfqId,
    recipientIds,
    currentCompanyId,
    isApproved,
  }: {
    rfqId: string;
    recipientIds: string[];
    currentCompanyId: string;
    isApproved: boolean;
  }) =>
    this.instance.put(`/ajax/rfq/${rfqId}/publicBidIntentions?currentCompanyId=${currentCompanyId}`, {
      recipientIds,
      isApproved,
    });

  updateUserFeedback = ({
    userFeedbackId,
    ratingScore,
    ratingType,
    comments,
  }: {
    userFeedbackId: string;
    ratingScore: number;
    ratingType: RatingType;
    comments?: string;
  }) =>
    this.instance.put(`/ajax/user-feedback/${userFeedbackId}`, {
      ratingScore,
      ratingType,
      comments,
    });

  createUserFeedback = ({
    companyId,
    entityType,
    feedbackContext,
    meta,
  }: {
    companyId: string;
    entityType: FeedbackEntityType;
    feedbackContext: FeedbackContext;
    meta: Partial<FeedbackMeta>;
  }) =>
    this.instance.post(`/ajax/user-feedback/${entityType}/${feedbackContext}`, {
      companyId,
      meta,
    });

  getAuditTrail = ({
    currentCompanyId,
    rfqId,
    recipientId,
  }: {
    currentCompanyId: string;
    rfqId: string;
    recipientId?: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      { currentCompanyId, recipientId } as Record<string, string>,
      isNil,
    ));

    return this.instance.get(`/ajax/rfq/${rfqId}/audit?${queryParams.toString()}`);
  };

  updateCollaborationInviteStatus = ({
    rfqId,
    companyId,
    invitationAccepted,
  }: {
    rfqId: string;
    companyId: string;
    invitationAccepted: boolean;
  }) => {
    return this.instance.put(`/ajax/rfq/${rfqId}/collaborationInviteStatus`, {
      companyId,
      hasAcceptedInvite: invitationAccepted,
    });
  };

  trackEvent = ({ currentCompanyId, eventType, data }: TrackingEvent) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/track/${eventType}`, { data });

  getSystemFeatureFlags = () => this.instance.get<SystemFeatureFlags>('/ajax/featureFlags');

  submitAuctionLotBid = ({
    rfqId,
    recipientId,
    auctionId,
    lotId,
    price,
    breakdown,
  }: {
    rfqId: string,
    recipientId: string,
    auctionId: string,
    lotId: string,
    price: number,
    breakdown: Record<string, { price: number; quantity: number }>
  }) =>
    this.instance.post(`/ajax/rfq/${rfqId}/bid/${recipientId}/auction/${auctionId}/lot/${lotId}/bid`, {
      bid: {
        price,
        breakdown,
      },
    });

  getRevisionChanges = ({
    rfqId,
    revisionDate,
    currentCompanyId,
  }: {
    rfqId: string;
    revisionDate?: number | string | Date;
    currentCompanyId: string;
  }) => {
    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | null>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy({
      revisionDate: revisionDate ? revisionDate.toString() : null,
      currentCompanyId,
    }, isNil));

    return this.instance.get<RevisionChange[]>(`/ajax/rfq/${rfqId}/revisionChanges?${queryParams.toString()}`);
  };

  getIntegrationData = ({ companyId, systemId, type }: { companyId: string, systemId: string, type: IntegrationDataType }) =>
    this.instance.get(`/ajax/company/${companyId}/integrationData/${systemId}?type=${type}`);

  addOrUpdateSupplierMapRecord = ({
    companyId,
    companies,
    systemId,
  }: {
    companyId: string;
    companies: [{
      externalCompanyId: string,
      companyName?: string,
      emails?: string,
      internalCompanyId?: string,
      internalCompanyName?: string,
    }];
    systemId: string;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/integration/addOrUpdateExternalCompany`, {
      companies,
      systemId,
    });

  deleteSupplierMapRecord = ({
    companyId,
    externalCompanyId,
    systemId,
  }: {
    companyId: string;
    externalCompanyId: string;
    systemId: string;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/integration/removeExternalCompany`, {
      externalCompanyId,
      systemId,
    });

  getUnmappedCompanies = ({ companyId, companyIds, systemId }: { companyId: string, companyIds: string[], systemId: string }) => {
    const queryParams = buildQueryParams(omitBy(
      {
        companyIds: JSON.stringify(companyIds),
        systemId,
      } as Record<string, string | string[]>,
      isNil,
    ));

    return this.instance.get(`/ajax/company/integrationData/${companyId}/getUnmappedCompanies?${queryParams.toString()}`);
  };

  getUnmappedExternalSuppliers = ({
    companyId,
    externalSupplierIds,
    systemId,
  }: {
    companyId: string,
    externalSupplierIds: string[],
    systemId: string
  }) =>
    this.instance.get(`/ajax/company/integrationData/${companyId}/getUnmappedExternalSuppliers?externalSupplierIds=${externalSupplierIds}&systemId=${systemId}`);

  configureSubscription = ({
    companyId,
    instanceId,
    integrationName,
    receiveNotifications,
  }: {
    companyId: string;
    instanceId: string;
    integrationName: string;
    receiveNotifications: boolean;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/integration/marketplace/subscription`, {
      instanceId,
      integrationName,
      receiveNotifications,
    });

  sendRequest = ({ rfqId, companyId }) =>
    this.instance.put(`/ajax/company/${companyId}/rfq/${rfqId}/live`);

  reviseRequest = ({ rfqId, companyId, message, bulletinSectionId }) =>
    this.instance.post(`/ajax/company/${companyId}/rfq/${rfqId}/revisions`, {
      bulletinSectionId,
      message,
    });

  getDraftContracts = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    sort,
    filter,
    search,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    sort: Sorting;
    filter: { [key: string]: any[] };
    search?: {
      text: string;
    };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<SentContractOverviews>(
      `/ajax/company/${currentCompanyId}/contracts/draft?${queryParams.toString()}`,
    );
  };

  getSentContracts = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    sort,
    filter,
    search,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    sort: Sorting;
    filter: { [key: string]: any[] };
    search?: {
      text: string;
    };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, sort, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<SentContractOverviews>(
      `/ajax/company/${currentCompanyId}/contracts/sent?${queryParams.toString()}`,
    );
  };

  getReceivedContracts = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    filter,
    search,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    filter: { [key: string]: any[] };
    search?: {
      text: string;
    };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, filter, search }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<ReceivedContractOverviews>(
      `/ajax/company/${currentCompanyId}/contracts/received?${queryParams.toString()}`,
    );
  };

  getSentContractsFilterItems = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => this.instance.get<{
    statuses: string[];
    owners: OwnerFilterItem[];
    recipients: RecipientFilterItem[];
    roles: DashboardRole[];
  }>(
    `/ajax/company/${currentCompanyId}/contracts/sent/filterItems`,
  );

  getReceivedContractsFilterItems = ({
    currentCompanyId,
  }: {
    currentCompanyId: string;
  }) => this.instance.get<{
    roles: DashboardRole[];
  }>(
    `/ajax/company/${currentCompanyId}/contracts/received/filterItems`,
  );

  getUserContractsList = ({
    currentCompanyId,
    pageIndex = 0,
    pageSize = 10,
    filter,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    filter?: {
      statuses?: RfqStatus[];
      ids?: string[];
    },
  }) => {
    const serializedFilter = typeof filter === 'object' ? JSON.stringify(omitBy(filter, isNil)) : undefined;

    // @ts-ignore ts(2345) FIXME: Argument of type 'Dictionary<string | null | undefined>' is not assignable to parameter of type 'string | string[][] | Record<string, string> | URLSearchParams | undefined'.
    const queryParams = new URLSearchParams(omitBy(
      {
        filter: serializedFilter,
        pageIndex: isFinite(pageIndex) ? `${pageIndex}` : null,
        pageSize: isFinite(pageSize) ? `${pageSize}` : null,
      }, isNil,
    ));

    // Overwrite `ReceivedContractOverview/SentContractOverview` optional _id type
    return this.instance.get<{
      overviews:(ReceivedContractOverview & { _id: string })[] | (SentContractOverview & { _id: string })[];
      totalItems: number;
      pageIndex: number;
    }>(
      `/ajax/company/${currentCompanyId}/contracts/list?${queryParams.toString()}`,
    );
  };

  createEmptyContract = ({ companyId }: { companyId: string }) =>
    this.instance.post(`/ajax/company/${companyId}/contracts/create`);

  createContractFromRequest = ({
    companyId,
    requestId,
    recipientId,
    locale,
  }: {
    companyId: string;
    requestId: string;
    recipientId: string;
    locale: string;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/contracts/create`, {
      requestId,
      recipientId,
      locale,
    });

  createContractFromTemplate = ({
    currentCompanyId,
    templateId,
  }: {
    currentCompanyId: string;
    templateId: string;
  }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/contracts/contractTemplate/${templateId}`);

  importLegacyContracts = ({
    companyId,
    contracts,
  }: {
    companyId: string;
    contracts: ImportLegacyContractPayload[]
  }) => {
    return this.instance.post(`/ajax/company/${companyId}/contracts/import/legacy`, {
      companyId,
      contracts,
    });
  };

  deleteContract = ({ companyId, contractId }: { companyId: string; contractId: string }) =>
    this.instance.delete(`/ajax/company/${companyId}/contract/${contractId}`);

  getContract = ({
    currentCompanyId,
    contractId,
    scope,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    scope: 'draft' | 'current';
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.get<Contract>(`/ajax/company/${currentCompanyId}/contractTemplate/${contractId}`)
      : this.instance.get<Contract>(`/ajax/company/${currentCompanyId}/contract/${contractId}/scope/${scope}`);

  getContractExchange = ({
    contractId,
    exchangeId,
    currentCompanyId,
  }: {
    contractId: string;
    exchangeId: string;
    currentCompanyId?: string;
  }) =>
    this.instance.get<ExchangeSnapshot>(`/ajax/company/${currentCompanyId}/contract/${contractId}/exchange/${exchangeId}`);

  updateContractSummary = ({
    currentCompanyId,
    contractId,
    summary,
    locale,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    summary: Partial<ContractSummary>;
    locale: string;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(`/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/update-summary`, { summary, locale })
      : this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/update-summary`, { summary, locale });

  updateContractCustomMilestones = ({
    currentCompanyId,
    contractId,
    customMilestones,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    customMilestones: Milestone[];
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(`/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/update-custom-milestones`, { customMilestones })
      : this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/update-custom-milestones`, { customMilestones });

  updateContractReminders = ({
    currentCompanyId,
    contractId,
    reminders,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    reminders: Reminder[];
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/update-reminders`,
        { reminders },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/update-reminders`,
        { reminders },
      );

  updateContractRecipients = ({
    currentCompanyId,
    contractId,
    recipientIds,
    userIdsByRecipientId,
  }: {
    currentCompanyId: string;
    contractId: string;
    recipientIds: IdChanges;
    userIdsByRecipientId: Record<string, IdChanges>;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/update-recipients`, {
      recipientIds,
      userIdsByRecipientId,
    });

  addContractPage = ({
    currentCompanyId,
    contractId,
    name,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    name: string;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/add-page`,
        { name },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/add-page`,
        { name },
      );

  duplicateContractPage = ({
    currentCompanyId,
    contractId,
    name,
    sourcePageId,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    name: string;
    sourcePageId: string;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/duplicate-page`,
        {
          name,
          sourcePageId,
        },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/duplicate-page`,
        {
          name,
          sourcePageId,
        },
      );

  updateContractPage = ({
    currentCompanyId,
    contractId,
    page,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    page: Partial<ContractPage>;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/update-page`,
        page,
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/update-page`,
        page,
      );

  removeContractPage = ({
    currentCompanyId,
    contractId,
    page,
    isTemplate,
  }: {
    currentCompanyId: string;
    contractId: string;
    page: Pick<ContractPage, '_id'>;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/remove-page`,
        page,
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/remove-page`,
        page,
      );

  reorderContractPages = ({
    currentCompanyId,
    contractId,
    pageIds,
    isTemplate,
  }: {
    currentCompanyId: string;
    contractId: string;
    pageIds: string[];
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/reorder-pages`,
        { pageIds },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/reorder-pages`,
        { pageIds },
      );

  addContractSection = ({
    currentCompanyId,
    contractId,
    section,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    section: Pick<ContractSection, 'type' | 'name' | 'pageId'>;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/add-section`,
        {
          ...section,
          name: section.name || '',
        },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/add-section`,
        {
          ...section,
          name: section.name || '',
        },
      );

  duplicateContractSection = ({
    currentCompanyId,
    contractId,
    sourceSectionId,
    locale,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    sourceSectionId: string;
    locale: string;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/duplicate-section`,
        {
          sourceSectionId,
          locale,
        },
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/duplicate-section`,
        {
          sourceSectionId,
          locale,
        },
      );

  removeContractSection = ({
    currentCompanyId,
    contractId,
    section,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    section: Pick<ContractSection, '_id'>;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(
        `/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/remove-section`,
        section,
      )
      : this.instance.put(
        `/ajax/company/${currentCompanyId}/contract/${contractId}/remove-section`,
        section,
      );

  getContractAttachmentDownloadUrl = ({
    contractId,
    attachmentId,
  }: {
    contractId: string;
    attachmentId: string;
  }) =>
    this.instance.post(`/ajax/contract/${contractId}/attachments/${attachmentId}/downloads`);

  updateContract = ({
    currentCompanyId,
    contractId,
    commands,
    isTemplate = false,
  }: {
    currentCompanyId: string;
    contractId: string;
    commands: any;
    isTemplate?: boolean;
  }) =>
    isTemplate
      ? this.instance.put(`/ajax/company/${currentCompanyId}/contractTemplate/${contractId}/commands`, { commands })
      : this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/commands`, { commands });

  issueContract = ({
    currentCompanyId,
    contractId,
  }: {
    currentCompanyId: string;
    contractId: string;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/publish`);

  reviseContract = ({
    currentCompanyId,
    contractId,
    message,
    locale,
  }: {
    currentCompanyId: string;
    contractId: string;
    message: string;
    locale: string;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/revise`, { message, locale });

  amendContract = ({
    currentCompanyId,
    contractId,
    message,
    locale,
  }: {
    currentCompanyId: string;
    contractId: string;
    message: string;
    locale: string;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/amend`, { message, locale });

  sendContractExchangeReply = ({
    contractId,
    exchangeId,
    currentCompanyId,
    action,
  }: {
    contractId: string;
    exchangeId: string;
    currentCompanyId: string;
    action: Partial<Action>;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/send-exchange-reply`,
      { exchangeId, action },
    );

  importDetailsFromRequest = ({
    contractId,
    currentCompanyId,
    exchangeIds,
  }: {
    contractId: string;
    currentCompanyId: string;
    exchangeIds: string[];
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/import/details`, {
      exchangeIds,
    });

  importProductTags = ({
    contractId,
    currentCompanyId,
  }: {
    contractId: string;
    currentCompanyId: string;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/contract/${contractId}/import/productsAndServices`);

  addContractTeamMember = ({
    contractId,
    currentCompanyId,
    user,
  }: {
    contractId: string;
    currentCompanyId: string;
    user: Pick<User, '_id' | 'email' | 'name'> & { isOwner?: boolean; contractRoles?: ContractRoles };
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/add-team-member`,
      { user },
    );

  updateContractTeamMemberRoles = ({
    contractId,
    currentCompanyId,
    userId,
    isOwner,
    contractRoles,
  }: {
    contractId: string;
    currentCompanyId: string;
    userId: UserId;
    isOwner?: boolean;
    contractRoles?: ContractRoles;
  }) => {
    return this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/update-roles`,
      { userId, isOwner, contractRoles },
    );
  };

  removeContractTeamMember = ({
    contractId,
    currentCompanyId,
    userId,
  }: {
    contractId: string;
    currentCompanyId: string;
    userId: string;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/remove-team-member`,
      { userId },
    );

  markContractAsFailed = ({
    contractId,
    currentCompanyId,
    message,
  }: {
    contractId: string;
    currentCompanyId: string;
    message: string;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/failed`,
      { message },
    );

  markContractAsTerminated = ({
    contractId,
    currentCompanyId,
    message,
  }: {
    contractId: string;
    currentCompanyId: string;
    message: string;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/contract/${contractId}/terminated`,
      { message },
    );

  getContractTemplates = ({ currentCompanyId }: { currentCompanyId: string }) =>
    this.instance.get<ContractTemplate[]>(`/ajax/company/${currentCompanyId}/contractTemplates`);

  getContractTemplate = ({ currentCompanyId, templateId }: { currentCompanyId: string, templateId: string }) =>
    this.instance.get<ContractTemplate>(`/ajax/company/${currentCompanyId}/contractTemplate/${templateId}/meta`);

  createEmptyContractTemplate = ({ currentCompanyId, name }: { currentCompanyId: string; name: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/contractTemplates/create`, { name });

  deleteContractTemplate = ({ currentCompanyId, templateId }: { currentCompanyId: string; templateId: string }) =>
    this.instance.delete(`/ajax/company/${currentCompanyId}/contractTemplate/${templateId}`);

  updateContractTemplateField = ({
    companyId,
    templateId,
    fieldName,
    fieldValue,
  }: {
    companyId: string;
    templateId: string;
    fieldName: string;
    fieldValue: string;
  }) =>
    this.instance.put(`/ajax/company/${companyId}/contractTemplate/${templateId}/update-field`, { fieldName, fieldValue });

  getRequestsReportingCardsData = ({
    currentCompanyId,
    section,
    config,
    startDate,
    endDate,
  }: {
    currentCompanyId: string;
    section: 'budget' | 'value' | 'savings';
    config: RequestsReportingConfig;
    startDate: Date;
    endDate: Date;
  }) => {
    const queryParams = new URLSearchParams({
      ...config,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

    return this.instance.get(`/ajax/company/${currentCompanyId}/reporting/requests/${section}/cards?${queryParams.toString()}`);
  };

  getRequestsReportingTableData = ({
    currentCompanyId,
    section,
  }: {
    currentCompanyId: string;
    section: 'budget' | 'value' | 'savings';
  }) => this.instance.get(`/ajax/company/${currentCompanyId}/reporting/requests/${section}/table`);

  getRequestsReportingOtherData = ({ currentCompanyId, currency }: { currentCompanyId: string; currency: string }) => {
    const queryParams = new URLSearchParams({ currency });

    return this.instance.get(`/ajax/company/${currentCompanyId}/reporting/requests?${queryParams.toString()}`);
  };

  getRequestsReportingDashboardData = ({
    currentCompanyId,
    config,
    startDate,
    endDate,
  }: {
    currentCompanyId: string;
    config: RequestsReportingConfig;
    startDate: Date;
    endDate: Date;
  }) => {
    const queryParams = new URLSearchParams({
      ...config,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

    return this.instance.get(`/ajax/company/${currentCompanyId}/reporting/dashboard?${queryParams.toString()}`);
  };

  getContractsReportingData = ({ companyId, currency }: { companyId: string; currency: string }) => {
    const queryParams = new URLSearchParams({ currency });

    return this.instance.get<ContractReporting>(`/ajax/company/${companyId}/reporting/contracts?${queryParams.toString()}`);
  };

  getRequestComments = ({ rfqId, currentCompanyId }: { rfqId: string; currentCompanyId: string }) => {
    const queryParams = new URLSearchParams({ currentCompanyId });

    return this.instance.get<RequestCommentsThread[]>(`/ajax/rfq/${rfqId}/threads?${queryParams.toString()}`);
  };

  postRequestCommentsThread = ({
    rfqId,
    subject,
    message,
    companyId,
    attachments = [],
  }: {
    rfqId: string;
    subject: string;
    message: string;
    companyId: string;
    attachments?: Attachment[];
  }) =>
    this.instance.post(`/ajax/rfq/${rfqId}/threads`, {
      subject,
      message,
      companyId,
      attachments,
    });

  postRequestCommentsResponse = ({
    rfqId,
    threadId,
    message,
    companyId,
    attachments = [],
  }: {
    rfqId: string;
    threadId: string;
    message: string;
    companyId: string;
    attachments?: Attachment[];
  }) =>
    this.instance.post(`/ajax/rfq/${rfqId}/thread/${threadId}/comments`, {
      message,
      companyId,
      attachments,
    });

  downloadCommentAttachment = ({
    rfqId,
    attachmentId,
    currentCompanyId,
  }: {
    rfqId: string;
    attachmentId: string;
    currentCompanyId: string;
  }) =>
    this.instance.post(`/ajax/rfq/${rfqId}/comments/attachments/${attachmentId}/downloads`, {
      currentCompanyId,
    });

  hasProductsAndServices = ({ rfqId }: { rfqId: string }) =>
    this.instance.get<{ hasProductsAndServices: boolean }>(`/ajax/rfq/${rfqId}/hasProductsAndServices`);

  getVesselPricingCsvReport = ({
    currentCompanyId,
    rfqId,
    separator,
    locale,
  }: {
    currentCompanyId: string;
    rfqId: string;
    separator?: 'comma' | 'semicolon' | 'space' | 'tab',
    locale: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      {
        csvSeparator: separator,
        locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      } as Record<string, string>,
      isNil,
    ));

    return this.instance.get(
      `/download/company/${currentCompanyId}/rfq/${rfqId}/vesselPricing?${queryParams.toString()}`,
      { responseType: 'blob' },
    );
  };

  createDiscoveryQuery = ({
    promptStrategyVersion,
    request,
    scope,
    similar,
    excludedSuppliers,
    details,
    includedLocations,
    companyId,
  }: {
    promptStrategyVersion: string;
    request: string;
    scope: string;
    similar: string;
    excludedSuppliers: string;
    details: string;
    includedLocations: string[];
    companyId: string;
  }) => {
    return this.instance.post(
      `/ajax/company/${companyId}/discovery/query`,
      {
        promptStrategyVersion,
        request,
        scope,
        similar,
        excludedSuppliers,
        details,
        includedLocations,
      },
    );
  };

  getDiscoveryQuery = ({
    companyId,
    queryId,
  }: {
    companyId: string,
    queryId: string,
  }) => this.instance.get<DiscoveryQuery>(`/ajax/company/${companyId}/discovery/query/${queryId}`);

  getDiscoveryQueryHistory = ({ companyId }: { companyId: string }) =>
    this.instance.get<DiscoveryQuery[]>(`/ajax/company/${companyId}/discovery/queryHistory`);

  sendDiscoveryFeedback = ({
    feedback,
    rating,
    companyId,
    queryId,
  }: {
    feedback: string;
    rating: number;
    companyId: string;
    queryId: string;
  }) => {
    return this.instance.post(
      `/ajax/company/${companyId}/discovery/feedback`,
      {
        feedback,
        rating,
        queryId,
      },
    );
  };

  validateLegacyContractsPayload = ({
    companyId,
    contracts,
  }: {
    companyId: string;
    contracts: ImportLegacyContractValidationPayload[];
  }) => {
    return this.instance.post<ImportLegacyContractError[]>(`/ajax/company/${companyId}/contracts/import/legacy/validate`, {
      contracts,
    });
  };

  sendEvaluationReminders = ({
    rfqId,
    currentCompanyId,
    message,
  }: {
    rfqId: string;
    currentCompanyId: string;
    message?: string;
  }) =>
    this.instance.post<{ _id: string }>(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/evaluation-reminders`, {
      message,
    });

  getEnabledLanguages = () => {
    return this.instance.get('/ajax/admin/enabledLanguages');
  };

  requestStageApproval = ({
    rfqId,
    stageId,
    recipientIds,
    approverIds,
    message,
    attachments,
    currentCompanyId,
  }: {
    rfqId: string;
    stageId?: string;
    recipientIds: string[];
    approverIds: string[];
    message: string;
    attachments: Attachment[];
    currentCompanyId: string;
  }) => {
    return this.instance.post(`/ajax/rfq/${rfqId}/stageApprovals`, {
      stageId,
      recipientIds,
      approverIds,
      message,
      attachments,
      currentCompanyId,
    });
  };

  submitStageApprovalResponse = ({
    rfqId,
    approvalRequestId,
    isApproved,
    message,
    currentCompanyId,
  }: {
    rfqId: string;
    approvalRequestId: string;
    isApproved: boolean;
    message: string;
    currentCompanyId: string;
  }) => {
    return this.instance.put(`/ajax/rfq/${rfqId}/stageApproval/${approvalRequestId}`, {
      isApproved,
      message,
      currentCompanyId,
    });
  };

  cancelStageApproval = ({
    rfqId,
    approvalRequestId,
    currentCompanyId,
  }: {
    rfqId: string;
    approvalRequestId: string;
    currentCompanyId: string;
  }) => {
    const queryParams = new URLSearchParams({ currentCompanyId });

    return this.instance.delete(`/ajax/rfq/${rfqId}/stageApproval/${approvalRequestId}?${queryParams.toString()}`);
  };

  createChatbotConversation = ({
    target,
    companyId,
  }: {
    companyId: string;
    target: BasicTarget | null;
  }) =>
    this.instance.post<{ _id: string }>(`/ajax/company/${companyId}/chatbot`, {
      target,
    });

  dispatchChatbotAction = ({
    chatbotId,
    companyId,
    action,
  }: {
    chatbotId: string;
    companyId: string;
    action: any;
  }) =>
    this.instance.post<boolean>(`/ajax/company/${companyId}/chatbot/${chatbotId}/action`, {
      action,
    });

  getChatbotChat = ({ companyId, chatbotId }: { companyId: string; chatbotId: string }) => {
    return this.instance.get<Chat>(`/ajax/company/${companyId}/chatbot/${chatbotId}`);
  };

  getContractAudit = ({
    currentCompanyId,
    contractId,
  }: {
    currentCompanyId: string;
    contractId: string;
  }) =>
    this.instance.get<ContractHistoryEvent[]>(`/ajax/company/${currentCompanyId}/contract/${contractId}/audit`);

  getExternalSystems = ({
    companyId,
  }: {
    companyId: string
  }) => {
    return this.instance.get<ExternalSystem[]>(`/ajax/company/${companyId}/externalSystems`);
  };

  getExternalSystem = ({
    companyId,
    systemId,
  }: {
    companyId: string;
    systemId: string;
  }) => {
    return this.instance.get<ExternalSystem>(`/ajax/company/${companyId}/externalSystem/${systemId}`);
  };

  getMarketplaceIntegrations = ({ companyId }: { companyId: string }) => {
    return this.instance.get(`/ajax/company/${companyId}/integration/marketplace`);
  };

  getMarketplaceToken = ({ companyId }: { companyId: string }) => {
    return this.instance.get(`/ajax/company/${companyId}/integration/marketplace/token`);
  };

  getQuestionnaireTemplate = ({
    currentCompanyId,
    templateId,
    scope,
  }: {
    currentCompanyId: string;
    templateId: string;
    scope: 'draft' | 'current';
  }) =>
    this.instance.get<QuestionnaireTemplate>(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/scope/${scope}`);

  createEmptyQuestionnaireTemplate = ({ currentCompanyId }: { currentCompanyId: string }) =>
    this.instance.post(`/ajax/company/${currentCompanyId}/questionnaireTemplate`);

  deleteQuestionnaireTemplate = ({
    companyId,
    templateId,
  }: {
    companyId: string;
    templateId: string;
  }) =>
    this.instance.delete<boolean>(`/ajax/company/${companyId}/questionnaireTemplate/${templateId}`);

  archiveQuestionnaireTemplate = ({
    currentCompanyId,
    templateId,
  }: {
    currentCompanyId: string;
    templateId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/archive`);

  restoreQuestionnaireTemplate = ({
    currentCompanyId,
    templateId,
  }: {
    currentCompanyId: string;
    templateId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/restore`);

  updateQuestionnaireTemplateSummary = ({
    currentCompanyId,
    templateId,
    summary,
  }: {
    currentCompanyId: string;
    templateId: string;
    summary: Partial<QuestionnaireSummary>;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/update-summary`, { summary });

  updateQuestionnaireTemplateExpiryAndRenewalConfig = ({
    currentCompanyId,
    templateId,
    renewalConfig,
    expiryConfig,
  }: {
    currentCompanyId: string;
    templateId: string;
    renewalConfig: Partial<QuestionnaireRenewalConfig>;
    expiryConfig: Partial<QuestionnaireExpiryConfig>;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/update-expiry-and-renewal-config`,
      { renewalConfig, expiryConfig },
    );

  updateQuestionnaireTemplateInternalData = ({
    currentCompanyId,
    templateId,
    internal,
  }: {
    currentCompanyId: string;
    templateId: string;
    internal: QuestionnaireInternalData;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/update-internal-data`,
      { internal },
    );

  updateQuestionnaireTemplate = ({
    currentCompanyId,
    templateId,
    commands,
  }: {
    currentCompanyId: string;
    templateId: string;
    commands: any;
  }) =>
    this.instance.put(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/commands`, { commands });

  publishQuestionnaireTemplate = ({
    currentCompanyId,
    templateId,
    shouldUpdateQuestionnaires,
  }: {
    currentCompanyId: string;
    templateId: string;
    shouldUpdateQuestionnaires?: boolean;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/publish`,
      { shouldUpdateQuestionnaires },
    );

  getActiveQuestionnaireTemplates = ({
    currentCompanyId,
    pageSize,
    pageIndex,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<QuestionnaireTemplateOverviewsResponse>(
      `/ajax/company/${currentCompanyId}/questionnaireTemplates/active?${queryParams.toString()}`,
    );
  };

  getArchivedQuestionnaireTemplates = ({
    currentCompanyId,
    pageSize,
    pageIndex,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<QuestionnaireTemplateOverviewsResponse>(
      `/ajax/company/${currentCompanyId}/questionnaireTemplates/archived?${queryParams.toString()}`,
    );
  };

  getDraftQuestionnaireTemplates = ({
    currentCompanyId,
    pageSize,
    pageIndex,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<QuestionnaireTemplateOverviewsResponse>(
      `/ajax/company/${currentCompanyId}/questionnaireTemplates/draft?${queryParams.toString()}`,
    );
  };

  getQuestionnaireTemplateHistory = ({ currentCompanyId, templateId }: { currentCompanyId: string; templateId: string }) =>
    this.instance.get<QuestionnaireTemplateVersion[]>(`/ajax/company/${currentCompanyId}/questionnaireTemplate/${templateId}/versions`);

  getSentQuestionnaires = ({
    currentCompanyId,
    pageSize,
    pageIndex,
    filter,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
    filter: { [key: string]: any[] };
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex, filter }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<QuestionnaireOverviewsResponse>(
      `/ajax/company/${currentCompanyId}/questionnaires/sent?${queryParams.toString()}`,
    );
  };

  getReceivedQuestionnaires = ({
    currentCompanyId,
    pageSize,
    pageIndex,
  }: {
    currentCompanyId: string;
    pageSize: number;
    pageIndex: number;
  }) => {
    const queryParams = new URLSearchParams(mapValues(
      omitBy({ pageSize, pageIndex }, isNil),
      value => JSON.stringify(value),
    ));

    return this.instance.get<QuestionnaireOverviewsResponse>(
      `/ajax/company/${currentCompanyId}/questionnaires/received?${queryParams.toString()}`,
    );
  };

  sendQuestionnaires = ({
    currentCompanyId,
    payload,
  }: {
    currentCompanyId: string;
    payload: {
      questionnaireTemplateIds: string[];
      senderTeamMembers: UnsavedQuestionnaireTeamMember[];
      recipients: {
        _id: string;
        userIds: string[];
      }[];
    }
  }) => this.instance.post(`/ajax/company/${currentCompanyId}/questionnaires`, payload);

  getQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.get<Questionnaire>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}`);

  getQuestionnaireExchange = ({
    questionnaireId,
    exchangeId,
    currentCompanyId,
  }: {
    questionnaireId: string;
    exchangeId: string;
    currentCompanyId: string;
  }) =>
    this.instance.get<ExchangeSnapshot>(
      `/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/exchange/${exchangeId}`,
    );

  getQuestionnaireAudit = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.get<QuestionnaireHistoryEvent[]>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/audit`);

  acceptQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/accept`);

  declineQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/decline`);

  submitQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/submit`);

  updateQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/update`);

  approveQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/approve`);

  rejectQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
    rejectionReason,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
    rejectionReason: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/reject`, { rejectionReason });

  reopenQuestionnaire = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/reopen`);

  markQuestionnaireAsExpired = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/mark-as-expired`);

  markQuestionnaireAsObsolete = ({
    currentCompanyId,
    questionnaireId,
  }: {
    currentCompanyId: string;
    questionnaireId: string;
  }) =>
    this.instance.put<boolean>(`/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/mark-as-obsolete`);

  addQuestionnaireTeamMembers = ({
    questionnaireId,
    currentCompanyId,
    users,
    companyId,
  }: {
    questionnaireId: string;
    currentCompanyId: string;
    users: (Pick<User, '_id' | 'name'> & { isOwner?: boolean; roles?: QuestionnaireRoles })[];
    companyId?: string;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/add-team-members`,
      { users, companyId },
    );

  updateQuestionnaireTeamMemberRoles = ({
    questionnaireId,
    currentCompanyId,
    userId,
    isOwner,
    roles,
  }: {
    questionnaireId: string;
    currentCompanyId: string;
    userId: UserId;
    isOwner?: boolean;
    roles?: QuestionnaireRoles;
  }) => {
    return this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/update-roles`,
      { userId, isOwner, roles },
    );
  };

  removeQuestionnaireTeamMember = ({
    questionnaireId,
    currentCompanyId,
    userId,
  }: {
    questionnaireId: string;
    currentCompanyId: string;
    userId: string;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/remove-team-member`,
      { userId },
    );

  sendQuestionnaireExchangeReply = ({
    questionnaireId,
    exchangeId,
    currentCompanyId,
    action,
  }: {
    questionnaireId: string;
    exchangeId: string;
    currentCompanyId: string;
    action: Partial<Action>;
  }) =>
    this.instance.put(
      `/ajax/company/${currentCompanyId}/questionnaire/${questionnaireId}/send-exchange-reply`,
      { exchangeId, action },
    );

  getQuestionnaireAttachmentDownloadUrl = ({
    questionnaireId,
    attachmentId,
  }: {
    questionnaireId: string;
    attachmentId: string;
  }) =>
    this.instance.post(`/ajax/questionnaire/${questionnaireId}/attachments/${attachmentId}/downloads`);

  getQuestionnaireTemplatesWithQuestion = ({ currentCompanyId, questionId }: { currentCompanyId: string; questionId: string }) =>
    this.instance.get<QuestionnaireTemplateOverview[]>(`/ajax/company/${currentCompanyId}/questionnaireTemplates/question/${questionId}`);

  getActiveQuestionnairesFromTemplate = ({ currentCompanyId, templateId }: { currentCompanyId: string; templateId: string }) =>
    this.instance.get(`/ajax/company/${currentCompanyId}/questionnaires/template/${templateId}`);

  getSentQuestionnairesByRecipient = ({ currentCompanyId }: { currentCompanyId }) =>
    this.instance.get(`/ajax/company/${currentCompanyId}/questionnaires/sent/byRecipient`);

  getPreQualSuppliers = ({
    currentCompanyId,
    searchState = {},
  }: {
    currentCompanyId: string;
    searchState: Partial<SearchState>;
  }) => {
    return this.instance.get<PreQualSuppliersResponse>(
      `/ajax/company/${currentCompanyId}/questionnaires/suppliers?${stringify(searchState)}`,
    );
  };

  getRfqLockState = ({ currentCompanyId, rfqId, isTemplate }: { currentCompanyId: string; rfqId: string; isTemplate?: boolean }) =>
    isTemplate
      ? this.instance.get<ModelLockState>(`/ajax/company/${currentCompanyId}/template/${rfqId}/lockState`)
      : this.instance.get<ModelLockState>(`/ajax/company/${currentCompanyId}/rfq/${rfqId}/lockState`);

  getRequestSizeLimits = ({ currentCompanyId, rfqId, isTemplate }: { currentCompanyId: string; rfqId: string; isTemplate?: boolean }) =>
    isTemplate
      ? this.instance.get<ModelSizeLimits>(`/ajax/company/${currentCompanyId}/template/${rfqId}/sizeLimits`)
      : this.instance.get<ModelSizeLimits>(`/ajax/rfq/${rfqId}/sizeLimits?currentCompanyId=${currentCompanyId}`);

  getContractSizeLimits = ({ currentCompanyId, contractId }: { currentCompanyId: string; contractId: string; }) =>
    this.instance.get<ModelSizeLimits>(`/ajax/company/${currentCompanyId}/contract/${contractId}/sizeLimits`);

  getProcessingTaskStatus = ({ processingTaskId }: { processingTaskId: string; }) =>
    this.instance.get<{ status: ProcessingTaskStatus }>(`/ajax/processingTask/${processingTaskId}/status`);

  updateRecipients = ({
    currentCompanyId,
    rfqId,
    recipientIds,
    userIdsByRecipientId,
    sourceByRecipientId,
    isTemplate,
  }: {
    currentCompanyId: string;
    rfqId: string;
    recipientIds: string[];
    userIdsByRecipientId: Record<string, string[]>;
    sourceByRecipientId: Record<string, RecipientSource>;
    isTemplate?: boolean;
  }) => {
    if (isTemplate) {
      return this.instance.put(
        `/ajax/company/${currentCompanyId}/template/${rfqId}/recipients`,
        { recipientIds, userIdsByRecipientId, sourceByRecipientId },
      );
    } else {
      return this.instance.put(
        `/ajax/company/${currentCompanyId}/rfq/${rfqId}/recipients`,
        { recipientIds, userIdsByRecipientId, sourceByRecipientId },
      );
    }
  };

  postActiveSession = (context: SegmentContext, companyId?: string, client?: string) => this.instance.post('/ajax/session/active/v2', { context, companyId, client });

  sendContractESignatureReminder = ({
    companyId,
    contractId,
    exchangeId,
  }: {
    companyId: string;
    contractId: string;
    exchangeId: string;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/contract/${contractId}/exchange/${exchangeId}/send-e-signature-reminder`);

  getContractESignatureLink = ({
    companyId,
    contractId,
    exchangeId,
  }: {
    companyId: string;
    contractId: string;
    exchangeId: string;
  }) =>
    this.instance.post(`/ajax/company/${companyId}/contract/${contractId}/exchange/${exchangeId}/get-e-sign-link`);

  checkContractESignatureUpdates = ({
    companyId,
    contractId,
    exchangeId,
  }: {
    companyId: string;
    contractId: string;
    exchangeId: string;
  }) =>
    this.instance.put(`/ajax/company/${companyId}/contract/${contractId}/exchange/${exchangeId}/check-e-signature-updates`);

  getServerVersion = (appName: string) => this.instance.get<{ version: string }>(`/ajax/version/${appName}`);

  getIncompleteTasks = ({ companyId }: { companyId }) =>
    this.instance.get(`/ajax/company/${companyId}/tasks/incomplete`);

  getQuestionnaireTemplateAttachmentDownloadUrl = ({
    questionnaireTemplateId,
    attachmentId,
  }: {
    questionnaireTemplateId: string;
    attachmentId: string;
  }) =>
    this.instance.post(`/ajax/questionnaireTemplate/${questionnaireTemplateId}/attachments/${attachmentId}/downloads`);

  requestQuestionnairesResponsesReport = ({
    currentCompanyId,
    questionnaireTemplateId,
  }: {
    currentCompanyId: string;
    questionnaireTemplateId: string;
  }) =>
    this.instance.post(
      `/ajax/company/${currentCompanyId}/userReports/questionnairesResponsesForTemplate`,
      {
        questionnaireTemplateId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    );

  requestQuestionnairesAuditReport = ({
    currentCompanyId,
    questionnaireTemplateId,
  }: {
    currentCompanyId: string;
    questionnaireTemplateId: string;
  }) =>
    this.instance.post(
      `/ajax/company/${currentCompanyId}/userReports/questionnairesAuditForTemplate`,
      {
        questionnaireTemplateId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    );

  requestSenderFilesZip = ({
    currentCompanyId,
    rfqId,
    sectionId,
    stageId,
  }: {
    currentCompanyId: string;
    rfqId: string;
    sectionId: string | null;
    stageId: string | null;
  }) =>
    this.instance.post(
      `/download/rfq/${rfqId}/recipientId/${currentCompanyId}/requestSenderFilesZip`,
      {
        sectionId,
        stageId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    );

  getUserReportDownloadUrl = ({ companyId, reportId }: { companyId: string; reportId: string }) =>
    this.instance.post<{ url: string }>(`/ajax/company/${companyId}/userReport/${reportId}/download`);
}
