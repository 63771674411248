// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgEmptyState = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={75}
    height={75}
    fill="none"
    {...props}
  >
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      d="M68.513 38.096c0 17.293-14.104 31.317-31.507 31.317S5.5 55.389 5.5 38.096 19.603 6.779 37.006 6.779c17.404 0 31.507 14.024 31.507 31.317Z"
    />
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      d="M37.006 70.413c17.95 0 32.507-14.466 32.507-32.317S54.956 5.779 37.006 5.779 4.5 20.245 4.5 38.096s14.556 32.317 32.506 32.317Z"
    />
    <mask
      id="empty-state_svg__a"
      width={65}
      height={64}
      x={5}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="#F9FAFB"
        stroke="#DDE4EE"
        d="M68.506 38c0 17.397-14.103 31.5-31.5 31.5S5.506 55.397 5.506 38s14.103-31.5 31.5-31.5 31.5 14.103 31.5 31.5Z"
      />
    </mask>
    <g mask="url(#empty-state_svg__a)">
      <path
        fill="#fff"
        stroke="#E2E8EF"
        d="M18.824 39.47H55.19c.497 0 .925.138 1.214.334.293.198.4.42.4.6v30.113c0 .18-.107.402-.4.6-.289.196-.717.334-1.214.334H18.824c-.497 0-.925-.138-1.214-.334-.292-.198-.4-.42-.4-.6V40.404c0-.18.108-.402.4-.6.29-.196.717-.334 1.214-.334Z"
      />
    </g>
    <path
      fill="#E2E8EF"
      d="M32.603 44.014h-11.1c-.707 0-1.28.521-1.28 1.164s.573 1.164 1.28 1.164h11.1c.707 0 1.28-.52 1.28-1.164s-.573-1.164-1.28-1.164M40.402 48.67H21.511c-.712 0-1.288.521-1.288 1.164s.576 1.164 1.288 1.164h18.891c.712 0 1.288-.52 1.288-1.164s-.576-1.164-1.288-1.164M32.603 54.102h-11.1c-.707 0-1.28.435-1.28.97 0 .536.573.97 1.28.97h11.1c.707 0 1.28-.434 1.28-.97 0-.535-.573-.97-1.28-.97M40.402 58.759H21.511c-.712 0-1.288.52-1.288 1.164 0 .642.576 1.164 1.288 1.164h18.891c.712 0 1.288-.522 1.288-1.164 0-.643-.576-1.165-1.288-1.165"
    />
    <path
      fill="#3498DB"
      d="M55.357 19.182h-36.7c-1.18 0-2.134.95-2.134 2.12V34.03c0 1.172.955 2.121 2.133 2.121h36.7c1.18 0 2.134-.95 2.134-2.12V21.302a2.127 2.127 0 0 0-2.133-2.122"
    />
    <path
      fill="#fff"
      d="M32.738 23.848H21.643c-.707 0-1.28.57-1.28 1.273s.573 1.272 1.28 1.272h11.095c.707 0 1.28-.57 1.28-1.272s-.573-1.273-1.28-1.273M40.42 29.363H21.643c-.707 0-1.28.57-1.28 1.273 0 .702.573 1.272 1.28 1.272H40.42c.707 0 1.28-.57 1.28-1.273 0-.702-.573-1.272-1.28-1.272"
    />
  </svg>
);
export default SvgEmptyState;
