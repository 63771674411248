import * as React from 'react';
import { Flex, FlexProps, SxStyleProp } from 'rebass/styled-components';
import { get, isString } from 'lodash';
import { Icon, IconProps } from '../icon/Icon';
import { truncateStyle } from './Truncate2';
import { Tooltip } from '../popup/Tooltip';
import { useTheme } from '../../theme/ThemeProvider';

export type IconTextProps =
  FlexProps &
  {
    name?: string;
    icon: IconProps['icon'];
    text: React.ReactNode | string;
    color?: string;
    iconColor?: string;
    isIconRegular?: boolean;
    isIconLight?: boolean;
    iconFontSize?: any;
    fontWeight?: number | string;
    iconFontWeight?: number | string;
    fixedWidth?: boolean;
    gap?: number | string;
    truncate?: boolean;
    lineHeight?: number | string;
    dot?: boolean;
    textStyle?: React.CSSProperties;
    iconPosition?: 'left' | 'right';
    iconSpin?: IconProps['spin'];
    showTooltip?: boolean;
    iconSx?: SxStyleProp;
  };

/**
 * Displays an `icon` to the left or the right of the `text`
 */
export const IconText: React.FC<IconTextProps> = ({
  icon,
  gap = 1,
  text,
  color,
  iconColor = color,
  fontSize = 2,
  fontWeight,
  iconFontSize = fontSize,
  isIconRegular = false,
  isIconLight = false,
  lineHeight,
  fixedWidth,
  alignItems = 'center',
  style,
  display = 'inline-flex',
  truncate = false,
  name,
  dot,
  sx,
  textStyle = {},
  iconFontWeight,
  iconPosition = 'left',
  iconSpin,
  showTooltip,
  iconSx = {},
  ...props
}) => {
  const theme = useTheme();

  const iconComponent = (
    <Icon
      icon={icon}
      color={get(theme, 'colors.iconColor', iconColor)}
      dot={dot}
      fixedWidth={fixedWidth}
      lineHeight={lineHeight}
      fontSize={iconFontSize}
      title={name}
      regular={isIconRegular}
      light={isIconLight}
      spin={iconSpin}
      sx={{
        minWidth: 'auto',
        fontWeight: iconFontWeight,
        ...iconSx,
      }}
    />
  );

  return (
    <Flex
      color={color}
      fontSize={fontSize}
      style={style}
      sx={{
        alignItems,
        display,
        fontWeight,
        lineHeight,
        maxWidth: '100%',
        columnGap: gap,
        ...sx,
      }}
      {...props}
    >
      {iconPosition === 'left' && iconComponent}
      <Tooltip content={showTooltip && isString(text) ? text : null}>
        <span
          style={{
            width: '100%',
            ...textStyle,
            ...(truncate ? truncateStyle : { wordBreak: 'normal' }),
          }}
        >
          {text}
        </span>
      </Tooltip>
      {iconPosition === 'right' && iconComponent}
    </Flex>
  );
};
