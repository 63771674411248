import { BaseTransportConfig, PublishTransport, SubscribeTransport, TransportType } from './base';
import { TransportError } from '../utils';

export const WS_LOGS_ENABLED = false;

export const PING_MESSAGE = JSON.stringify({ action: 'ping' });
export const PONG_MESSAGE = JSON.stringify({ type: 'pong' });
const PONG_TIMEOUT = 10000;
const PING_TIMEOUT = 10000;

export const enum CloseEventStatusCode {
  /**
   * Status code to indicate that a connection was closed because it's no
   * longer needed.
   * @see https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
   */
  PURPOSE_FULFILLED = 1000,
  /**
   * This status code is sent when AWS times out the connection.
   * @see https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
   */
  ENDPOINT_GOING_AWAY = 1001,
  /**
   * Private use status code to indicate that the server has not responded to
   * a ping message in time.
   * @see https://www.rfc-editor.org/rfc/rfc6455#section-7.4.2
   */
  NO_PONG_RECEIVED = 4000,
  /**
   * Private use status code to indicate that the connection gets closed
   * because it will be replaced with a new connection.
   * @see https://www.rfc-editor.org/rfc/rfc6455#section-7.4.2
   */
  REPLACING_CONNECTION = 4001,
}

const waitForOpenSocket = (websocket: WebSocket) => {
  return new Promise((resolve, reject) => {
    if (websocket.readyState === websocket.OPEN) {
      resolve(true);
    } else {
      const { onopen } = websocket;

      websocket.onopen = (event) => {
        if (onopen) {
          onopen.call(websocket, event);
        }
        websocket.onopen = onopen;
        resolve(true);
      };

      const { onclose } = websocket;

      websocket.onclose = (event) => {
        if (onclose) {
          onclose.call(websocket, event);
        }
        websocket.onclose = onclose;
        reject(new TransportError('Could not open websocket connection'));
      };
    }
  });
};

export type WebsocketsTransportConfig = BaseTransportConfig<TransportType.Websockets> & {
  url?: string | null;
  keepAliveSignal?: boolean;
  reconnectInterval?: number;
};

export class WebsocketsTransport implements PublishTransport, SubscribeTransport {
  public config: WebsocketsTransportConfig;

  private websocket: WebSocket | null = null;

  private onStartConnectingCallbacks: Set<() => void> = new Set();
  private onOpenCallbacks: Set<(event: Event) => void> = new Set();
  private onCloseCallbacks: Set<(event: CloseEvent) => void> = new Set();
  private onMessageCallbacks: Set<(message: string) => void> = new Set();
  private onErrorCallbacks: Set<(event: Event) => void> = new Set();

  private pongTimeout: number | undefined = undefined;
  private pingTimeout: number | undefined = undefined;
  private reconnectTimeout: number | undefined = undefined;

  public constructor(
    config: Omit<WebsocketsTransportConfig, 'type'>,
  ) {
    this.config = {
      ...config,
      type: TransportType.Websockets,
    };
  }

  public setUrl(url?: string | null) {
    this.config = {
      ...this.config,
      url,
    };
  }

  /**
   * @throws {TransportError} Will throw an error when the connection could
   * not get established.
   */
  public async initialize() {
    if (!this.config.url) {
      throw new TransportError('Cannot connect without a URL');
    }

    this.handleWebsocketStartConnecting();

    this.websocket = new WebSocket(this.config.url);

    this.websocket.onopen = this.handleWebsocketOpen;
    this.websocket.onclose = this.handleWebsocketClose;
    this.websocket.onmessage = this.handleWebsocketMessage;
    this.websocket.onerror = this.handleWebsocketError;

    await waitForOpenSocket(this.websocket);
  }

  public isReady() {
    return this.websocket && this.websocket.readyState === this.websocket.OPEN;
  }

  public isConnectingOrOpen() {
    return this.websocket && ([
      this.websocket.CONNECTING,
      this.websocket.OPEN,
    ] as number[]).includes(this.websocket.readyState);
  }

  /**
   * @throws {TransportError} Will throw an error when the connection could
   * not get established.
   */
  public async reconnect() {
    if (this.websocket) {
      // This will close the connection and reconnect after `reconnectTimeout` has passed
      this.close(CloseEventStatusCode.REPLACING_CONNECTION, 'Replacing with new connection');
    } else {
      // This will establish a new connection immediately
      await this.initialize();
    }
  }

  public close(code: CloseEventStatusCode, reason: string) {
    clearTimeout(this.pongTimeout);
    clearTimeout(this.pingTimeout);
    clearTimeout(this.reconnectTimeout);

    if (this.websocket) {
      this.websocket.onopen = null;
      this.websocket.onclose = null;
      this.websocket.onmessage = null;
      this.websocket.onerror = null;

      this.websocket.close(code, reason);

      this.handleWebsocketClose({ code, reason } as CloseEvent);
    }
  }

  public async send(payload: string): Promise<void> {
    if (!this.websocket || this.websocket.readyState !== this.websocket.OPEN) {
      throw new TransportError('Implementation error: Invalid state for sending message');
    }

    if (WS_LOGS_ENABLED) {
      console.log(new Date().toISOString(), 'sending message:', payload);
    }

    this.websocket.send(payload);
  }

  public onStartConnecting(callback: () => void): void {
    this.onStartConnectingCallbacks.add(callback);
  }

  public onOpen(callback: (event: Event) => void): void {
    this.onOpenCallbacks.add(callback);
  }

  public onClose(callback: (event: CloseEvent) => void): void {
    this.onCloseCallbacks.add(callback);
  }

  public onMessage(callback: (message: string) => void): void {
    this.onMessageCallbacks.add(callback);
  }

  public onError(callback: (event: Event) => void): void {
    this.onErrorCallbacks.add(callback);
  }

  private handleWebsocketStartConnecting = () => {
    for (const callback of this.onStartConnectingCallbacks) {
      callback();
    }
  };

  private handleWebsocketOpen = (event: Event) => {
    for (const callback of this.onOpenCallbacks) {
      callback(event);
    }

    if (this.config.keepAliveSignal) {
      this.sendPing();
    }
  };

  private handleWebsocketClose = (event: CloseEvent) => {
    for (const callback of this.onCloseCallbacks) {
      callback(event);
    }

    if (
      event.code !== CloseEventStatusCode.PURPOSE_FULFILLED &&
      this.config.reconnectInterval
    ) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = Number(setTimeout(() => {
        this.initialize().catch(error => console.error(error));
      }, this.config.reconnectInterval));
    }
  };

  private handleWebsocketMessage = (event: MessageEvent) => {
    if (this.config.keepAliveSignal) {
      if (WS_LOGS_ENABLED) {
        console.log(new Date().toISOString(), 'pong received', event);
      }
      clearTimeout(this.pingTimeout);
      clearTimeout(this.pongTimeout);
      this.sendPing();
    }

    if (event.data !== PONG_MESSAGE) {
      for (const callback of this.onMessageCallbacks) {
        callback(event.data);
      }
    }
  };

  private handleWebsocketError = (event: Event) => {
    // NB We shouldn't need to reconnect when an error occurs because a close
    // event is expected to get emitted after every error event.
    // If this turns out not to be the case, also reconnect on errors, but
    // make sure to avoid race conditions / duplicate init calls in cases
    // where both error and close events get emitted.

    for (const callback of this.onErrorCallbacks) {
      callback(event);
    }
  };

  private sendPing = () => {
    this.pingTimeout = Number(setTimeout(() => {
      // When the websocket is connecting, a new ping timeout will be set once the
      // connection is open, so we can just return here.
      if (!this.websocket || this.websocket.readyState === this.websocket.CONNECTING) {
        return;
      }

      if (this.websocket.readyState === this.websocket.OPEN) {
        this.send(PING_MESSAGE);
      }

      this.pongTimeout = Number(setTimeout(() => {
        this.handlePongTimeout();
      }, PONG_TIMEOUT));
    }, PING_TIMEOUT));
  };

  private handlePongTimeout = () => {
    if (WS_LOGS_ENABLED) {
      console.log(new Date().toISOString(), 'no pong received');
    }
    this.close(CloseEventStatusCode.NO_PONG_RECEIVED, 'No pong received');
  };
}
