import { useMemo } from 'react';
import {
  EvaluationPage,
  getPagesInDisplayOrder,
  isLinkedEvaluationPage,
} from '@deepstream/common/rfq-utils';
import { Text, Box, Flex, Heading } from 'rebass/styled-components';
import { size } from 'lodash';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@deepstream/ui/ui/Tabs';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { FactorAsPercent } from '@deepstream/ui/ui/Number';
import * as rfx from '@deepstream/ui/rfx';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useTranslation } from 'react-i18next';
import { LabeledValue, LabeledNumber } from '@deepstream/ui/draft/LabeledValue';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { RequestSection, Subtext } from './RequestDetails';

const EvaluationTabHeader = () => {
  const { t } = useTranslation('general');
  const { pages, pageById } = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage & { linkedPageId: string };
  const {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();

  const linkedEvaluationPages = pages.filter(isLinkedEvaluationPage);

  const linkedPage = pageById[page.linkedPageId];

  return (
    <Flex justifyContent="space-between" py="20px" px="12px">
      <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
        {linkedPage.name}
      </Heading>
      <Flex sx={{ gap: '16px' }}>
        {linkedEvaluationPages.length > 1 ? (
          <LabeledValue
            label={t('weight')}
            value={(
              <Flex>
                <Text mr={1}>
                  {page.weight && absolutePageWeightById[page._id] ? (
                    <FactorAsPercent value={absolutePageWeightById[page._id]} />
                  ) : (
                    <EmDash />
                  )}
                </Text>
              </Flex>
            )}
          />
        ) : (
          null
        )}
        <LabeledNumber label={t('section_other')} number={size(relativeSectionWeightById)} />
        <LabeledNumber label={t('criterion_other')} number={size(absoluteExchangeDefWeightById)} />
      </Flex>
    </Flex>
  );
};

export const AdminRequestEvaluationPanelContent = () => {
  const { pages, pageById, sectionById } = rfx.useStructure();

  const orderedPages = useMemo(
    () => getPagesInDisplayOrder(pages).filter(isLinkedEvaluationPage),
    [pages],
  );

  return orderedPages.length ? (
    <rfx.EvaluationWeightsProvider>
      <Tabs>
        <TabList>
          {orderedPages.map(page => (
            <Tab key={page._id}>{pageById[page.linkedPageId].name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {orderedPages.map(page => (
            <TabPanel key={page._id}>
              <rfx.PageProvider page={page}>
                <EvaluationTabHeader />
                {page.sections.map((sectionId, index) => {
                  const section = sectionById[sectionId];

                  return section ? (
                    <rfx.SectionProvider section={section} key={sectionId}>
                      <RequestSection isLast={index === page.sections.length - 1} />
                    </rfx.SectionProvider>
                  ) : (
                    <Box p={3} key={sectionId}>
                      <ErrorMessage error={`No section found with id ${sectionId}`} />
                    </Box>
                  );
                })}
              </rfx.PageProvider>
              {!page.sections.length && (
                <PanelPadding>
                  <Subtext>No sections yet</Subtext>
                </PanelPadding>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </rfx.EvaluationWeightsProvider>
  )
  : (
    <Subtext p={16}>No pages yet</Subtext>
  );
};
