import { createContext, useContext } from 'react';
import * as React from 'react';

export const LABEL_WIDTH = '170px';

export enum LabelConfig {
  ABOVE = 'above',
  LEFT = 'left',
  NONE = 'none',
  FLEX = 'flex',
}

type LabelConfigProps = {
  variant: LabelConfig;
  width?: string;
  gap?: number;
  style?: any;
};

const LabelConfigContext = createContext<LabelConfigProps>({ variant: LabelConfig.ABOVE, width: LABEL_WIDTH });

export const useLabelConfig = () => useContext(LabelConfigContext);

export interface LabelConfigProviderProps {
  variant?: LabelConfig;
  width?: string;
  gap?: number;
  style?: any;
  children: React.ReactNode;
}

export const LabelConfigProvider = ({
  variant = LabelConfig.ABOVE,
  width = LABEL_WIDTH,
  gap,
  style,
  children,
}: LabelConfigProviderProps) => {
  const contextValue = React.useMemo(
    () => ({ variant, width, gap, style }),
    [variant, width, gap, style],
  );

  return (
    <LabelConfigContext.Provider value={contextValue}>
      {children}
    </LabelConfigContext.Provider>
  );
};
