import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';

/**
 * Red asterisk used highlight that a field is required.
 */
export const RequiredAsterisk = () => {
  const { t } = useTranslation('translation');

  return (
    <Text color="danger" display="inline" ml={1} aria-label={t('general.required')} fontWeight={500}>
      *
    </Text>
  );
};
