import { last } from 'lodash';

/**
 * Reducer function that groups consecutive items together based on a predicate
 */
export const consecutiveGrouper = <T>(predicate: (previous: T | undefined, next: T) => boolean) => (groups: T[][], item: T) => {
  const group = last(groups);
  const previousItem = group?.[0];

  if (previousItem && predicate(previousItem, item)) {
    (group as any).push(item);
    return groups;
  } else {
    groups.push([item]);
    return groups;
  }
};
