import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { StaticTableStyles } from './TableStyles';
import { Table } from './Table';

type ExternalCompaniesModalProps = {
  data: Record<string, { internalCompanyId: string, companyName: string }>[];
  onClose: () => void;
} & Omit<ModalProps, 'data'>;

export const ExternalCompaniesModal: React.FC<ExternalCompaniesModalProps> = ({
  data,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['integration', 'general']);
  const companyData = Object
    .entries(data)
    .map(([externalCompanyId, { internalCompanyId, companyName }]) => ({
      externalCompanyId,
      internalCompanyId,
      companyName,
    }));

  const columns = React.useMemo(
    () => [
      {
        Header: t('externalCompanyId'),
        accessor: 'externalCompanyId',
      },
      {
        Header: t('companyName', { ns: 'general' }),
        accessor: 'companyName',
      },
      {
        Header: t('internalCompanyId'),
        accessor: 'internalCompanyId',
      },
    ],
    [t],
  );

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{ content: { maxWidth: '700px' } }}
      {...props}
    >
      <ModalHeader onClose={onClose}>
        {t('externalCompaniesData')}
      </ModalHeader>
      <ModalBody p={0}>
        <StaticTableStyles>
          <Table
            columns={columns}
            data={companyData}
          />
        </StaticTableStyles>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>
          {t('dismiss', { ns: 'general' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
