import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { GridClipboardEvent, GridClipboardEventType } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useToaster } from '../../toast';

export const useHandleGridClipboardEvent = (pasteErrorTranslationKey = 'gridEditor.cellNotPastedCount') => {
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const intercom = useIntercom();

  return useCallback((event: GridClipboardEvent) => {
    switch (event.type) {
      case GridClipboardEventType.COPY:
        toaster.success(t('gridEditor.cellCopiedCount', { count: event.cellCount }));

        intercom.trackEvent('grid-cells-copied');
        break;
      case GridClipboardEventType.PASTE:
        if (event.invalidCellCount) {
          toaster.error(t(pasteErrorTranslationKey, { count: event.invalidCellCount }));
        }
        if (event.validCellCount) {
          toaster.success(t('gridEditor.cellPastedCount', { count: event.validCellCount }));
        }

        if (event.invalidCellCount && event.validCellCount) {
          intercom.trackEvent('grid-cells-pasted-partial-success');
        } else if (event.validCellCount) {
          intercom.trackEvent('grid-cells-pasted-success');
        } else if (event.invalidCellCount) {
          intercom.trackEvent('grid-cells-pasted-failure');
        }
        break;
      case GridClipboardEventType.PASTE_ERROR:
        if (event.reason === 'accessBlocked') {
          toaster.error(t('gridEditor.clipboardAccessBlocked'));
        } else {
          toaster.error(t('gridEditor.couldNotPasteFromClipboard'));

          intercom.trackEvent('grid-cells-pasted-failure');
        }
        break;
    }
  }, [toaster, t, intercom, pasteErrorTranslationKey]);
};
