import { differenceBy, intersectionBy, zip, isEqual, first, entries, sortBy, spread, map, without, negate } from 'lodash';

export interface Diff<T> {
  added: Array<T>;
  removed: Array<T>;
  updated: Array<T>;
  next: Array<T>;
  previous: Array<T>;
}

export interface KeyValuePair {
  key: string;
  value: any;
}

export const diffArrayBy = (next: any[], previous: any[], key: any): Diff<any> => {
  // Determining the updated items requires that the two arrays are in the same
  // order. This doesn't apply to added/removed items because callers may expect
  // the order of additions/removals to be based on original order of the arrays.
  const sortedNext = sortBy(next, key);
  const sortedPrevious = sortBy(previous, key);

  return {
    next,
    previous,
    added: differenceBy(next, previous, key),
    removed: differenceBy(previous, next, key),
    updated: zip(intersectionBy(sortedNext, sortedPrevious, key), intersectionBy(sortedPrevious, sortedNext, key))
      .filter(([nextItem, previousItem]) => !isEqual(nextItem, previousItem))
      .map(first),
  };
};

const keyValueObj = ([key, value]: [any, any]): KeyValuePair => ({ key, value });

export const diffObject = (next: any, previous: any): Diff<KeyValuePair> => {
  const [nextPairs, previousPairs] = [next, previous].map(
    obj => entries(obj).map(keyValueObj),
  );

  return diffArrayBy(nextPairs, previousPairs, 'key');
};

/**
 * An "entity" is any object with an `_id`. This is usually data that is
 * persisted in some fashion on the backend.
 */
type Entity = { _id: string };

/**
 * Helper to get an array of entity ids
 */
const ids = <TItem extends Entity> (items: TItem[]) => map(items, '_id');

/**
 * Check if a sequence has changed, handles additions/removals.
 */
export const hasBeenReordered = <TEntity extends Entity> (diff: Diff<TEntity>) => {
  const nextSequence = ids(diff.next);

  // By dropping the removed items and appending the added items, we
  // represent the sequence that would have existed without any changes
  // to the order.
  const untouchedSequence = [
    ...without(ids(diff.previous), ...ids(diff.removed)),
    ...ids(diff.added),
  ];

  // When ids are not equal across identical indexes, the sequence has changed
  return zip(nextSequence, untouchedSequence).some(spread(negate(isEqual)));
};
