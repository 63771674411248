import { useState, useMemo } from 'react';
import { last, negate, upperFirst } from 'lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';

import { PropertyList, PropertyListAction, TopAlignedRow } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { AdminRequestMeta } from '@deepstream/ui/types';
import * as rfx from '@deepstream/ui/rfx';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Modal, ModalProps, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { callAll } from '@deepstream/utils/callAll';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';

import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { useMutation } from '@deepstream/ui/useMutation';
import { localeFormatNumber } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { useRfqId } from '@deepstream/ui/useRfq';
import { isEvaluationPage } from '@deepstream/common/rfq-utils';

import { Id } from './Id';
import { TemplateLink } from './TemplateLink';
import { useAdminRequestQueryKey } from './adminRequest';
import { EnabledDisabledPropertyValue } from './EnabledDisabledPropertyValue';
import { RequestLimitsPropertyValue } from './RequestLimitsPropertyValue';

const TemplateLinkOrDash = ({ value: template }) => {
  return template ? (
    <TemplateLink templateId={template?._id}>
      {template?.meta.name}
    </TemplateLink>
  ) : (
    <EmDash />
  );
};

const EditRequestSizeLimitOverrideModal = ({
  defaultRequestSizeLimits,
  requestSizeLimitOverrides,
  onCancel,
  onSave,
  propName,
  recipientsCount,
  exchangeDefsCount,
  ...props
}: ModalProps & {
  defaultRequestSizeLimits: {
    maxComplexity: number;
    maxExchangeDefCount: number;
  };
  requestSizeLimitOverrides: {
    maxComplexity?: number;
    maxExchangeDefCount?: number;
  };
  recipientsCount: number;
  exchangeDefsCount: number;
  propName: 'maxComplexity' | 'maxExchangeDefCount',
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const rfqId = useRfqId();
  const adminRequestQueryKey = useAdminRequestQueryKey(rfqId);

  const label = {
    maxComplexity: 'request size limit',
    maxExchangeDefCount: 'maximum exchange definition count',
  }[propName];

  const [updateRequestSizeLimits] = useMutation(
    adminApi.updateRequestSizeLimits,
    {
      onSuccess: callAll(
        onSave,
        (_, params) => {
          if (params[propName]) {
            toaster.success(`${upperFirst(label)} override updated successfully`);
          } else {
            toaster.success(`${upperFirst(label)} override removed successfully`);
          }
        },
      ),
      onError: (_, params) => {
        if (params[propName]) {
          toaster.error(`${upperFirst(label)} override could not be updated`);
        } else {
          toaster.error(`${upperFirst(label)} override could not be removed`);
        }
      },
      onSettled: () => queryClient.invalidateQueries(adminRequestQueryKey),
    },
  );

  const requestSizeProperties = useMemo(() => {
    if (propName === 'maxComplexity') {
      return [
        { value: recipientsCount * exchangeDefsCount, label: 'Request size' },
        {
          value:
            requestSizeLimitOverrides.maxComplexity ??
            defaultRequestSizeLimits.maxComplexity,
          label: 'General request size limit',
        },
        { value: exchangeDefsCount, label: 'Total exchange definitions' },
        { value: recipientsCount, label: 'Suppliers on request' },
      ];
    } else {
      return [
        { value: exchangeDefsCount, label: 'Total exchange definitions' },
        {
          value:
            requestSizeLimitOverrides.maxExchangeDefCount ??
            defaultRequestSizeLimits.maxExchangeDefCount,
          label: 'General maximum exchange definition limit',
        },
      ];
    }
  }, [
    defaultRequestSizeLimits.maxComplexity,
    defaultRequestSizeLimits.maxExchangeDefCount,
    exchangeDefsCount,
    propName,
    recipientsCount,
    requestSizeLimitOverrides.maxComplexity,
    requestSizeLimitOverrides.maxExchangeDefCount,
  ]);

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          override: requestSizeLimitOverrides[propName],
        }}
        validationSchema={
          yup.object().shape({
            override: yup
              .number()
              .min(250, 'Minimum of 250')
              .required('Required'),
          })
        }
        onSubmit={async ({ override }, { setSubmitting }) => {
          await updateRequestSizeLimits({
            rfqId,
            [propName]: override,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, isValid, setSubmitting }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit {label} override
            </ModalHeader>
            <ModalBody>
              <TextField
                required
                name="override"
                label={`${upperFirst(label)} override`}
                format="integer.positive"
              />
              <MessageBlock variant="info">
                This value will override the general {label} for
                this request only.
              </MessageBlock>
              <Flex flexDirection="column" sx={{ rowGap: 1, marginTop: 3 }}>
                {requestSizeProperties.map(({ label, value }) => (
                  <Flex sx={{ columnGap: 3 }} key={label} >
                    <Text sx={{ minWidth: 75 }}>{localeFormatNumber(value, { locale: APP_ADMIN_LOCALE })}</Text>
                    <Text>{label}</Text>
                  </Flex>
                ))}
              </Flex>
            </ModalBody>
            <ModalFooter>
              {requestSizeLimitOverrides[propName] && (
                <Button
                  variant="danger-outline"
                  onClick={async () => {
                    setSubmitting(true);
                    await updateRequestSizeLimits({
                      rfqId,
                      [propName]: null,
                    }, {
                      onSettled: () => setSubmitting(false),
                    });
                  }}
                  disabled={isSubmitting}
                >
                  Remove override
                </Button>
              )}
              <Flex flex={1} justifyContent="flex-end">
                <CancelButton onClick={onCancel} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty || !isValid} />
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const RequestPropertyList = ({
  requestId,
  meta,
}: {
  requestId: string;
  meta: AdminRequestMeta;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const template = rfx.useTemplate();
  const editRequestSizeLimitOverrideModal = useModalState();
  const [requestSizeLimitOverridePropName, setRequestSizeLimitOverridePropName] =
    useState<'maxComplexity' | 'maxExchangeDefCount' | null>(null);

  const { recipients, pageById, exchangeDefById } = structure;
  const recipientsCount = recipients.length;
  const recipientExchangeDefsCount = useMemo(() => {
    const recipientPages = Object.values(pageById).filter(negate(isEvaluationPage));
    const recipientSectionIds = recipientPages.flatMap((page) => page.sections);

    return Object.values(exchangeDefById).filter((exchangeDef) =>
      exchangeDef?.sectionId && recipientSectionIds.includes(exchangeDef.sectionId),
    ).length;
  }, [exchangeDefById, pageById]);

  const generalProperties = useMemo(
    () => {
      const { autoReferenceNumber, summary, stages, newFeaturesDisabled } = structure;

      return [
        { name: 'Status', value: t(`request.status.${structure.extendedStatus}`) },
        { name: 'ID', value: requestId, Component: Id },
        { name: 'Reference', value: autoReferenceNumber },
        { name: 'Created on', value: meta.creationTimestamp, Component: Datetime },
        { name: 'Created by', value: meta.creatorUsername },
        { name: 'Origin template', value: template, Component: TemplateLinkOrDash },
        { name: 'Deadline', value: last(stages)?.completionDeadline, Component: Datetime },
        { name: 'Enhanced?', value: summary.isEnhancedListing ? 'Yes' : 'No' },
        {
          requestSizeLimitPropName: 'maxComplexity',
          name: 'Request size',
          value: {
            current: recipientsCount * recipientExchangeDefsCount,
            limit: meta.requestSizeLimitOverrides.maxComplexity ?? meta.defaultRequestSizeLimits.maxComplexity,
            limitOverride: meta.requestSizeLimitOverrides.maxComplexity,
          },
          Component: RequestLimitsPropertyValue,
        },
        {
          requestSizeLimitPropName: 'maxExchangeDefCount',
          name: 'Exchange definition count',
          value: {
            current: recipientExchangeDefsCount,
            limit: meta.requestSizeLimitOverrides.maxExchangeDefCount ?? meta.defaultRequestSizeLimits.maxExchangeDefCount,
            limitOverride: meta.requestSizeLimitOverrides.maxExchangeDefCount,
          },
          Component: RequestLimitsPropertyValue,
        },
        {
          name: 'New request features – Lots; Award scenarios; New UX (Supplier and Buyer); New award flow.',
          value: !newFeaturesDisabled,
          Component: EnabledDisabledPropertyValue,
        },
      ].map(props => ({ ...props, heightAuto: true }));
    },
    [meta, requestId, structure, template, recipientsCount, recipientExchangeDefsCount, t],
  );

  return (
    <>
      <PropertyList properties={generalProperties} Row={TopAlignedRow}>
        {generalProperties.map(({ requestSizeLimitPropName }, index) => {
          return requestSizeLimitPropName ? (
            <Box
              key={index}
              py="14px"
            >
              <PropertyListAction
                label="Edit"
                icon="pencil-alt"
                onClick={() => {
                  setRequestSizeLimitOverridePropName(requestSizeLimitPropName as any);
                  editRequestSizeLimitOverrideModal.open();
                }}
              />
            </Box>
          ) : (
            <br key={index} />
          );
        }) as any}
      </PropertyList>
      {requestSizeLimitOverridePropName && (
        <EditRequestSizeLimitOverrideModal
          isOpen={editRequestSizeLimitOverrideModal.isOpen}
          onCancel={editRequestSizeLimitOverrideModal.close}
          onSave={editRequestSizeLimitOverrideModal.close}
          onRequestClose={editRequestSizeLimitOverrideModal.close}
          propName={requestSizeLimitOverridePropName}
          defaultRequestSizeLimits={meta.defaultRequestSizeLimits}
          requestSizeLimitOverrides={meta.requestSizeLimitOverrides}
          recipientsCount={recipientsCount}
          exchangeDefsCount={recipientExchangeDefsCount}
        />
      )}
    </>
  );
};
