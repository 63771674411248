import { RfqStatus, TotalSavingsCalculationMethod } from './rfq-utils';

export enum DataShown {
  ALL_REQUESTS = 'allRequests',
  OWN_REQUESTS = 'ownRequests',
}

export enum CountDate {
  REQUEST_CREATION = 'requestCreation',
  REQUEST_AWARD = 'requestAward',
  FINAL_DEADLINE = 'finalDeadline',
}

export enum Interval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER_YEAR = 'quarter',
  YEAR = 'year',
}

export type RequestsReportingConfig = {
  dataShown: DataShown;
  countDate: CountDate;
  interval: Interval;
  currency: string;
};

export type RequestReportingTotals = {
  allTime: {
    requestCount: number;
    amount: number;
  },
  currentInterval: {
    requestCount: number;
    amount: number;
  },
  previousInterval: {
    requestCount: number;
    amount: number;
  },
};

export type RequestsBudgetCardsData = {
  config: RequestsReportingConfig & {
    startDate: Date;
    endDate: Date;
  },
  totals: RequestReportingTotals;
  noDataRequestCount: number;
  requests: {
    noDataRequestCount: number;
    _id: string;
    subject: string;
    extendedStatus: RfqStatus;
    budgetedTotalValue: number;
    createdAt?: Date | string;
    prospectiveCompletionDate?: Date | string;
    awardedDate?: Date | string;
  }[]
};

export type RequestsValueCardsData = {
  config: RequestsReportingConfig & {
    startDate: Date;
    endDate: Date;
  },
  totals: RequestReportingTotals;
  unconfirmedTotals: RequestReportingTotals;
  noDataRequestCount: number;
  requests: {
    _id: string;
    subject: string;
    extendedStatus: RfqStatus;
    finalValue: number;
    isFinalValueConfirmed?: boolean;
    createdAt?: Date | string;
    prospectiveCompletionDate?: Date | string;
    awardedDate?: Date | string;
  }[]
};

export type RequestsSavingsCardsData = {
  config: RequestsReportingConfig & {
    startDate: Date;
    endDate: Date;
  },
  totals: RequestReportingTotals;
  unconfirmedTotals: RequestReportingTotals;
  noDataRequestCount: number;
  requests: {
    _id: string;
    subject: string;
    extendedStatus: RfqStatus;
    savings: number;
    areSavingsConfirmed?: boolean;
    savingsCalculationMethod?: TotalSavingsCalculationMethod;
    multiResponseLineItemSavings?: number;
    singleResponseLineItemSavings?: number;
    auctionLineItemSavings?: number;
    budgetFinalValueSavings?: number;
    manualSavings?: number;
    createdAt?: Date | string;
    prospectiveCompletionDate?: Date | string;
    awardedDate?: Date | string;
  }[];
};

export type RequestsReportingDashboardData = {
  config: RequestsReportingConfig & {
    startDate: Date;
    endDate: Date;
  };
  totals: {
    budget: {
      current: number | null;
      previous: number | null;
    };
    value: {
      current: number | null;
      previous: number | null;
    };
    savings: {
      current: number | null;
      previous: number | null;
    };
  };
  requests: RequestsSavingsCardsData['requests'];
};

export const getCardsPriceKey = (section: 'budget' | 'value' | 'savings') => {
  switch (section) {
    case 'budget': return 'budgetedTotalValue';
    case 'value': return 'finalValue';
    case 'savings': return 'savings';
  }
};

export const getCardsDateKey = (countDate: CountDate) => {
  switch (countDate) {
    case CountDate.REQUEST_CREATION: return 'createdAt';
    case CountDate.REQUEST_AWARD: return 'awardedDate';
    case CountDate.FINAL_DEADLINE: return 'prospectiveCompletionDate';
  }
};
