import isPlainObject from 'lodash.isplainobject';
import { hasOwnProperty } from '@deepstream/utils';

export enum NotificationTransportType {
  ApiGatewayCallback = 'ApiGatewayCallback',
}

export type BaseNotificationTransportConfig<T extends NotificationTransportType> = {
  type: T,
};

export type ApiGatewayCallbackConfig = BaseNotificationTransportConfig<NotificationTransportType.ApiGatewayCallback> & {
  region?: string,
  endpoint?: string,
  ConnectionId?: string
};

export type NotificationTransportConfig = ApiGatewayCallbackConfig; // | ...

export function isNotificationTransportConfig(config: unknown): config is NotificationTransportConfig {
  if (
    !(isPlainObject(config)) ||
    !hasOwnProperty(config, 'type') ||
    !(typeof config.type === 'string') ||
    !(Object.values(NotificationTransportType).includes(config.type as NotificationTransportType))
  ) {
      return false;
  }

  return true;
}
