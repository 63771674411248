import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { QuestionnaireOverview, QuestionnaireStatus } from '@deepstream/common/preQual';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { DateFormat } from '@deepstream/utils';
import { Datetime2 } from '../../../Datetime';

export const QuestionnaireRenewalDateCell = ({ value, row: { original: { status } } }: CellProps<QuestionnaireOverview, Date>) => {
  const { t } = useTranslation('preQualification');

  return status !== QuestionnaireStatus.APPROVED ? (
    <EmDash />
  ) : value ? (
    <Datetime2
      value={value}
      format={DateFormat.DD_MMM_YYYY}
    />
  ) : (
    t('questionnaire.noExpiry')
  );
};
