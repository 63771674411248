import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ChangeType, CompanyMinimized } from '@deepstream/common/rfq-utils';
import { pick } from 'lodash';
import { ModalForm } from '../../../ModalForm';
import * as rfx from '../../../rfx';
import { RequestRole } from '../../../types';
import { UserSelectField } from './UserSelectField';
import { RequestTeamRolePermissionFields } from './RequestTeamRolePermissionFields';
import { usePermissionRows } from './usePermissionRows';
import { getRfqRoles } from './RequestTeamEditUserModal';

export const RequestTeamAddUserModal = ({
  isOpen,
  close,
  company,
}: {
  isOpen: boolean;
  close: any;
  company: CompanyMinimized;
}) => {
  const { t } = useTranslation();
  const { pages, teamById } = rfx.useStructure();
  const saveChanges = rfx.useSaveChanges();

  const existingUserIds = Object.keys(teamById[company._id].users);

  const permissionRows = usePermissionRows(pages, {}, false);

  return (
    <ModalForm
      heading={t('teamManagement.addUser')}
      initialValues={{
        requestRole: RequestRole.TEAM_MEMBER,
        permissions: permissionRows,
        selectedUser: undefined,
      }}
      validationSchema={
        yup.object().shape({
          requestRole: yup.string().oneOf(Object.values(RequestRole)),
          selectedUser: yup.mixed().required(t('general.required')),
        })
      }
      disableSubmitIfNotDirty={true}
      disableSubmitIfInvalid={true}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async (values) => {
        const { permissions } = values;

        const changes = [{
          type: ChangeType.TEAM_MEMBER_ADDED,
          user: {
            ...pick(values.selectedUser, ['_id', 'name', 'email']),
            approvingPages: [],
            isOwner: values.requestRole === RequestRole.OWNER,
            company,
            rfqRoles: getRfqRoles(permissions),
          },
          companyId: company._id,
        }];

        await saveChanges({
          changes,
        }, {
          onSettled: close,
        });
      }}
      submitLabel={t('general.saveChanges')}
    >
      <UserSelectField companyId={company._id} existingUserIds={existingUserIds} />

      <RequestTeamRolePermissionFields
        initialPermissionRows={permissionRows}
        initialRequestRole={RequestRole.TEAM_MEMBER}
        companyId={company._id}
        isAddUser={true}
      />

    </ModalForm>
  );
};
