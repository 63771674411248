import styled from 'styled-components';
import { Link } from 'rebass/styled-components';

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
`;
