import { difference, map } from 'lodash';
import * as React from 'react';
import { Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelPadding, SidebarPanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { SupplierListOverview, SupplierListType } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import styled from 'styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CheckboxList } from './CheckboxList';
import { useSupplierLists } from './modules/SupplierLists/useSupplierLists';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { useSupplierListNavigation } from './appNavigation';
import { useIsAuthorizedStakeholder } from './useIsAuthorizedStakeholder';
import { listTypeIconProps } from './modules/SupplierLists/utils';

const CheckboxLabel = styled(Text)`
  width: 100%;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 400;
  line-height: 18px;
  padding-left: ${props => props.theme.space[2]}px;
`;

const useRenderListItem = () => {
  const { t } = useTranslation();

  return React.useCallback((supplierList: SupplierListOverview) => {
    const isAutomatic = supplierList.listType === SupplierListType.AUTOMATIC;

    return (
      <Flex alignItems="center" justifyContent="space-between" sx={{ gap: 2 }}>
        <Truncate>
          {supplierList.name}
        </Truncate>
        <Tooltip
          content={(
            <Stack gap={1}>
              <IconText
                icon={listTypeIconProps[supplierList.listType].icon}
                isIconRegular={listTypeIconProps[supplierList.listType].regular}
                text={isAutomatic ? t('network.automaticList') : t('network.manualList')}
                fontWeight={500}
              />
              <Text>
                {isAutomatic ? t('network.automaticListDescription') : t('network.manualListDescription')}
              </Text>
            </Stack>
          )}
        >
          <Icon {...listTypeIconProps[supplierList.listType]} fixedWidth flex="0 0 auto" color="subtext" />
        </Tooltip>
      </Flex>
    );
  }, [t]);
};

type SupplierListsPanelProps = {
  selectedIds: string[];
  onChange: (item: any) => void;
  selectableSupplierListIds?: string[];
  isInternalNetwork: boolean;
};

export const SupplierListsPanel = ({
  selectedIds,
  onChange,
  selectableSupplierListIds,
  isInternalNetwork,
}: SupplierListsPanelProps) => {
  const { t } = useTranslation();
  const { supplierLists, supplierListsStatus } = useSupplierLists();
  const { navigateToLists, navigateToCreateList } = useSupplierListNavigation();
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();
  const renderItem = useRenderListItem();

  const disabledSupplierListIds = React.useMemo(
    () => selectableSupplierListIds && supplierLists
      ? difference(map(supplierLists, '_id'), selectableSupplierListIds)
      : [],
    [selectableSupplierListIds, supplierLists],
  );

  return (
    <Panel>
      <SidebarPanelHeader heading={<IconText icon="list-ul" text={t('list_other', { ns: 'general' })} gap={2} />}>
        <Button
          small
          variant="secondary-outline"
          iconLeft="gear"
          onClick={() => navigateToLists({ from: isInternalNetwork ? 'internalNetwork' : 'externalNetwork' })}
        >
          {t('network.manageLists')}
        </Button>
        {!isAuthorizedStakeholder && (
          <Button small iconLeft="plus" ml={2} onClick={navigateToCreateList}>
            {t('supplierLists.newList')}
          </Button>
        )}
      </SidebarPanelHeader>
      <PanelPadding pt={0} mt={-2}>
        {supplierListsStatus === 'loading' ? (
          <Loading fontSize={2} />
        ) : supplierListsStatus === 'error' ? (
          <ErrorMessage error={t('network.errors.errorLoadingLists')} fontSize={2} />
        ) : supplierLists && supplierLists.length ? (
          <CheckboxList
            value={selectedIds}
            items={supplierLists}
            onChange={onChange}
            renderItem={renderItem}
            withClearButton
            disabledItems={disabledSupplierListIds}
            CheckboxLabel={CheckboxLabel}
          />
        ) : supplierLists && !supplierLists.length ? (
          <Text color="subtext" fontSize={2}>{t('network.noListsYet')}</Text>
        ) : (
          null
        )}
      </PanelPadding>
    </Panel>
  );
};
