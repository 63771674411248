import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Box } from 'rebass/styled-components';
import { useQueryClient, useQuery } from 'react-query';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LabelConfig, LabelConfigProvider } from '@deepstream/ui/LabelConfigProvider';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { FieldContainer } from '@deepstream/ui/form/FieldContainer';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';

import { RemoveLanguageButton } from './RemoveLanguageButton';

type EditLanguageModalProps =
  { language: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditLanguageModal = ({
  language,
  onCancel,
  onSave,
  ...props
}: EditLanguageModalProps) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['config']),
        () => queryClient.invalidateQueries(['languages']),
      ),
    },
  );
  const { data: existingLanguages } = useQuery(
    ['languages'],
    wrap(adminApi.getLanguages),
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          code: language.code,
          isEnabled: language.isEnabled,
        }}
        validationSchema={
          yup.object().shape({
            isEnabled: yup.bool(),
          })
        }
        onSubmit={async ({ isEnabled, code }, { setSubmitting }) => {
            const updatedLanguages = existingLanguages.map(language => {
              if (language.code !== code) {
                return language;
              }

              return {
                ...language,
                isEnabled,
              };
            });

            await updateConfig({
              languages: updatedLanguages,
            }, {
              onSettled: () => setSubmitting(false),
              onSuccess: callAll(
                onSave,
                () => toaster.success('Language updated successfully'),
              ),
              onError: () => toaster.error('Language could not be updated'),
            });
          }
        }
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <LabelConfigProvider
            variant={LabelConfig.LEFT}
            width="86px"
            style={{
              language: {
                fontWeight: 500,
              },
            }}
          >
            <Form>
              <ModalHeader onClose={onCancel}>
                Edit language
              </ModalHeader>
              <ModalBody>
                <Stack gap={4}>
                  <FieldContainer name="language" label="Language" fontSize={1}>
                    {language.name}
                  </FieldContainer>
                  <SwitchField
                    switchHeight="18px"
                    label="Status"
                    name="isEnabled"
                    checkedText="Enabled"
                    uncheckedText="Disabled"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={42}
                    infoTooltip="This controls whether the language can be selected only. Any existing users of the language are unaffected."
                    tooltipIcon="info-circle"
                    useDefaultLabelConfig={false}
                  />
                </Stack>
              </ModalBody>
              <ModalFooter justifyContent="space-between">
                <RemoveLanguageButton
                  onSave={onSave}
                  existingLanguages={existingLanguages}
                  languageCode={values.code}
                  updateConfig={updateConfig}
                />
                <Box>
                  <CancelButton onClick={onCancel} />
                  <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                    Save changes
                  </Button>
                </Box>
              </ModalFooter>
            </Form>
          </LabelConfigProvider>
        )}
      </Formik>
    </Modal>
  );
};
