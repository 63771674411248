import { find } from 'lodash';
import { StageType } from './types';

export const getRecipientsWithAuctionAccess = (structure) => {
  const auctionStageId = find(structure.stages, { type: StageType.AUCTION })?._id;

  if (!auctionStageId) {
    return [];
  }

  return structure.recipients.filter(recipient => {
    const enteredStageIds = structure.bidById[recipient._id]?.enteredStageIds;

    return enteredStageIds?.includes(auctionStageId);
  });
};
