import { z } from 'zod';
import { useQueryClient } from 'react-query';
import { ModalForm } from '@deepstream/ui/ModalForm';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { useToaster } from '@deepstream/ui/toast';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { TermsOfService } from '@deepstream/common';
import { CheckboxField2 } from '@deepstream/ui/form/CheckboxField';

const Schema = z.object({
  hasConfirmed: z.literal(true, { errorMap: () => ({ message: 'Required' }) }),
});

type MakeTermsLiveModalProps = ModalProps & {
  termsOfService: TermsOfService;
  onCancel: () => void;
};

export const MakeTermsLiveModal = ({
  termsOfService,
  isOpen,
  onCancel,
}: MakeTermsLiveModalProps) => {
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const { version } = termsOfService;

  const [makeTermsOfServiceLive] = useMutation(
    adminApi.makeTermsOfServiceLive,
    {
      onSettled: () => queryClient.invalidateQueries(['termsOfService']),
      onSuccess: () => toaster.success(`Version ${version} has been made live successfully`),
      onError: () => toaster.error(`Version ${version} could not be made live`),
    },
  );

  return (
    <ModalForm
      heading={`Make version ${version} live?`}
      initialValues={{
        hasConfirmed: false,
      }}
      validationSchema={toFormikValidationSchema(Schema)}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async () => {
        await makeTermsOfServiceLive({ termsId: termsOfService._id });
      }}
      submitLabel="Confirm"
      cancelLabel="Go back"
      style={{ content: { width: '450px' } }}
    >
      <MessageBlock variant="warn" mt={0}>
        On confirmation, all new and existing users must agree to this new version in order to use DeepStream and you will no longer be able to make any changes to this version. This cannot be undone.
      </MessageBlock>
      <CheckboxField2
        name="hasConfirmed"
        fieldLabel={`I understand and want to make version ${version} live`}
      />
    </ModalForm>
  );
};
