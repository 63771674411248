import * as React from 'react';
import { CellProps } from 'react-table';
import { upperFirst } from 'lodash';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CapitalizeCell } from './CapitalizeCell';
import { formatAdminDate } from './formatDate';

enum UserEventType {
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIGNUP = 'signup',
  EMAIL_VERIFICATION = 'email-verification',
  PENDING_EMAIL_VERIFICATION = 'pending-email-verification',
  TERMS_OF_SERVICE_ACCEPTED = 'terms-of-service-accepted',
}

const eventLabelByType = {
  [UserEventType.LOGIN]: 'Login',
  [UserEventType.LOGOUT]: 'Logout',
  [UserEventType.SIGNUP]: 'Sign up',
  [UserEventType.EMAIL_VERIFICATION]: 'Verify email',
  [UserEventType.PENDING_EMAIL_VERIFICATION]: 'Verify pending email',
  [UserEventType.TERMS_OF_SERVICE_ACCEPTED]: 'Accept terms',
};

const eventDetailMapping = {
  'login-page': 'Login page',
  'signup-page': 'Signup page',
  'email-verification': 'Email verification link',
  'pending-email-verification': 'Pending email verification link',
  'unexpected-error': 'Unexpected error',
  'maintenance': 'Maintenance',
  'different-user-logged-in': 'Logged in as a different user',
  'nonexistent-user': 'User does not exist',
  'user-suspended': 'User suspended',
  'email-already-verified': 'Email already verified',
  'verification-link-expired': 'Link has expired',
  'weak-password': 'Weak password',
  'user-already-exists': 'User already exists',
  'incorrect-password': 'Incorrect password',
  'unsupported-browser': 'Unsupported browser',
};

const EventDetailCell = ({ cell: { value } }: CellProps<any>) => {
  if (!value) return <EmDash />;

  if (eventDetailMapping[value]) {
    return <>{eventDetailMapping[value]}</>;
  }

  switch (value) {
    // cases below are deprecated
    case 'login-page-unknown-error':
      return <>Unexpected error</>;
    case 'login-page-maintenance':
      return <>Maintenance</>;
    case 'login-page-nonexistent-user':
      return <>User does not exist</>;
    case 'login-page-suspension':
      return <>User suspended</>;
    case 'login-page-incorrect-password':
      return <>Incorrect password</>;
    case 'signup-page-unknown-error':
      return <>Signup page – unknown error</>;
    case 'email-verification-unknown-error':
      return <>Email verification link – unexpected error</>;
    case 'email-verification-different-user-logged-in':
      return <>Email verification link – logged in as a different user</>;
    case 'email-verification-nonexistent-user':
      return <>Email verification link – user does not exist</>;
    case 'email-verification-user-suspended':
      return <>Email verification link – user suspended</>;
    case 'email-verification-email-already-verified':
      return <>Email verification link – email already verified</>;
    case 'email-verification-verification-link-expired':
      return <>Email verification link – link has expired</>;
    case 'pending-email-verification-unknown-error':
      return <>Pending email verification link – unexpected error</>;
    case 'pending-email-verification-different-user-logged-in':
      return <>Pending email verification link – logged in as a different user</>;
    case 'pending-email-verification-nonexistent-user':
      return <>Pending email verification link – user does not exist</>;
    case 'pending-email-verification-user-suspended':
      return <>Pending email verification link – user suspended</>;
    case 'pending-email-verification-email-already-verified':
      return <>Pending email verification link – email already verified</>;
    case 'pending-email-verification-verification-link-expired':
      return <>Pending email verification link – link has expired</>;
    default:
      return <>{value}</>;
  }
};

type EventsTableProps = {
  accessEvents: any[];
};

export const UserAccessEventsTable: React.FC<EventsTableProps> = ({ accessEvents }) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'date',
        Header: 'Date',
        accessor: 'date',
        Cell: DatetimeCell,
        sort: 'datetime',
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'eventType',
        Header: 'Event',
        accessor: ({ eventType }) => eventLabelByType[eventType],
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'outcome',
        Header: 'Outcome',
        accessor: 'outcome',
        Cell: CapitalizeCell,
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'detail',
        Header: 'Detail',
        accessor: 'detail',
        Cell: EventDetailCell,
        Filter: withProps(FilterSelect, { itemToString: (value) => eventDetailMapping[value] ?? value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'ipAddress',
        Header: 'IP',
        accessor: 'ipAddress',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={accessEvents}
        isSortable
        noFilteredDataPlaceholder="No access events match chosen filters"
      />
    </StaticTableStyles>
  );
};
