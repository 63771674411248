import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import React from 'react';
import { AdminTableLink } from './AdminTableLink';
import { requestRoute } from './AppRouting';

export const RequestLink = ({
  requestId,
  title,
  ...props
}: {
  requestId: string;
  title?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={requestRoute.to}
    params={{ requestId }}
    onClick={stopPropagation}
    {...props}
  />
);
