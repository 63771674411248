import { createGlobalStyle } from 'styled-components';
import ScrollToBottomBase from 'react-scroll-to-bottom';

export const AuctionChatScrollToBottomStyles = createGlobalStyle<any>`
  .ScrollToBottom__Container {
    overflow-y: auto;
    height: 100%;
  }

  .ScrollToBottom__FollowButton_hidden {
    display: none;
  }
`;

export const ChatbotScrollToBottomStyles = createGlobalStyle<any>`
  .ScrollToBottom__Container {
    overflow-y: auto;
    height: 0;
    min-height: 100%;
  }

  .ScrollToBottom__FollowButton_hidden {
    display: none;
  }
`;

export const ScrollToBottom = ({ children }) => {
  return (
    <ScrollToBottomBase
      className="ScrollToBottom__Container"
      followButtonClassName="ScrollToBottom__FollowButton_hidden"
    >
      {children}
    </ScrollToBottomBase>
  );
};
