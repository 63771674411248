import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ButtonProps, CancelButton, OkButton } from '../button/Button';
import { Popover, usePopover } from './usePopover';

export type ConfirmationPopoverProps = {
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  popover: ReturnType<typeof usePopover>;
  heading: string;
  body: React.ReactNode;
  onOk: () => void;
  onCancel?: () => void;
  okButtonVariant?: ButtonProps['variant'];
  okButtonText?: string;
  okButtonSmall?: boolean;
  okButtonDisabled?: boolean;
  okButtonType?: ButtonProps['type'];
  cancelButtonVariant?: ButtonProps['variant'];
  cancelButtonText?: string;
  cancelButtonSmall?: boolean;
};

export const ConfirmationPopover: React.FC<ConfirmationPopoverProps> = ({
  popover,
  heading,
  body,
  onOk,
  onCancel,
  okButtonVariant = 'danger',
  okButtonText,
  okButtonType,
  okButtonSmall,
  cancelButtonVariant = 'secondary',
  cancelButtonText,
  cancelButtonSmall,
  okButtonDisabled,
  ...props
}) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleOk = React.useCallback(
    async () => {
      try {
        setDisabled(true);
        try {
          await onOk();
        } catch (_) {
          // ignore
        }
      } finally {
        setDisabled(false);
      }
    },
    [onOk],
  );

  const handleCancel = React.useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    popover.close();
  }, [onCancel, popover]);

  return (
    <Popover
      onClickOutside={onCancel}
      {...popover}
      {...props}
    >
      <Box
        sx={{
          background: 'white',
          borderRadius: 'small',
          width: '100%',
        }}
      >
        <Box>
          <Flex
            flexDirection="column"
            pr={3}
            pl={3}
            pt={3}
            justifyContent="space-between"
            sx={{ gap: '8px' }}
          >
            <Text fontSize={2} fontWeight={500}>
              {heading}
            </Text>
            <Text fontSize={2}>{body}</Text>
          </Flex>

          <Flex p={3} justifyContent="flex-end">
            <CancelButton
              mr={2}
              variant={cancelButtonVariant}
              small={cancelButtonSmall}
              onClick={handleCancel}
              disabled={disabled}
            >
              {cancelButtonText}
            </CancelButton>
            <OkButton
              type={okButtonType}
              variant={okButtonVariant}
              small={okButtonSmall}
              onClick={handleOk}
              disabled={disabled || okButtonDisabled}
            >
              {okButtonText}
            </OkButton>
          </Flex>
        </Box>
      </Box>
    </Popover>
  );
};
