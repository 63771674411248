import * as React from 'react';
import { CellProps } from 'react-table';
import { Text } from 'rebass/styled-components';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useConfirmDialogWithConfig } from '@deepstream/ui/ui/useModalState';
import { Label } from '@deepstream/ui/ui/Label';
import { useMutation } from '@deepstream/ui/useMutation';
import { useApi } from '@deepstream/ui/api';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { useQueryClient } from 'react-query';
import { getCompanyStatus } from './CompanyGeneralPropertyList';

type DialogBodyProps = {
  userName: string;
  companyName: string;
  companyStatus: string;
  message: string;
};

const DialogBody = ({ userName, companyName, companyStatus, message }: DialogBodyProps) => (
  <>
    <Label label="User">
      <Text>{userName}</Text>
    </Label>
    <Label label="Company">
      <Text>{companyName}</Text>
    </Label>
    <Label label="Company status">
      <Text>{companyStatus}</Text>
    </Label>
    <MessageBlock variant="info" mt={3}>
      {message}
    </MessageBlock>
  </>
);

export const CompanyClaimActionsCell: React.FC<CellProps<any> & { company: any }> = ({ row, company }) => {
  const queryClient = useQueryClient();
  const api = useApi();
  const toaster = useToaster();

  const { configureDialog, ...dialogProps } = useConfirmDialogWithConfig();

  const [verifyCompany] = useMutation(
    api.verifyCompany,
    {
      onSuccess: () => toaster.success('Claim resolved successfully'),
      onError: () => toaster.error('Claim could not be resolved'),
      onSettled: callAll(
        (_data, _error, variables) => queryClient.invalidateQueries(['company', { companyId: variables.companyId }]),
        () => queryClient.invalidateQueries(['companies']),
        () => queryClient.invalidateQueries(['pendingCompanyClaims']),
      ),
    },
  );

  const confirmAccept = React.useCallback(
    () => {
      configureDialog({
        action: () => verifyCompany({
          companyId: company._id,
          userId: row.original.user._id,
          accepted: true,
        }),
        props: {
          heading: 'Accept claim',
          okButtonText: 'Accept claim',
          okButtonVariant: 'primary',
          body: (
            <DialogBody
              userName={row.original.user.name}
              companyName={company.name}
              companyStatus={getCompanyStatus(company)}
              message="Status will change to ‘Accepted’ and this company will no longer be shown in an ‘Invited’ state"
            />
          ),
        },
      });
    },
    [company, configureDialog, row.original.user._id, row.original.user.name, verifyCompany],
  );

  const confirmReject = React.useCallback(
    () => {
      configureDialog({
        action: () => verifyCompany({
          companyId: company._id,
          userId: row.original.user._id,
          accepted: false,
        }),
        props: {
          heading: 'Reject claim',
          okButtonText: 'Reject claim',
          okButtonVariant: 'danger',
          body: (
            <DialogBody
              userName={row.original.user.name}
              companyName={company.name}
              companyStatus={getCompanyStatus(company)}
              message="Status will change to ‘Rejected’ and this company will be hidden from search results"
            />
          ),
        },
      });
    },
    [company, configureDialog, row.original.user._id, row.original.user.name, verifyCompany],
  );

  return (
    <>
      <DropdownMenu
        buttonText="Resolve"
        small
        variant="primary-outline"
        iconLeft="share"
        iconRight="caret-down"
        wrapperStyle={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
        menuZIndex={2}
      >
        <DropdownMenuItem icon="check" onSelect={confirmAccept}>
          Accept
        </DropdownMenuItem>
        <DropdownMenuItem icon="times" onSelect={confirmReject}>
          Reject
        </DropdownMenuItem>
      </DropdownMenu>
      <Dialog style={{ content: { width: '500px' } }} {...dialogProps as any} />
    </>
  );
};
