import * as React from 'react';
import styled from 'styled-components';
import { SpaceProps, ColorProps } from 'styled-system';
import { Text, TextProps, Flex } from 'rebass/styled-components';
import clsx from 'clsx';
import { transparentize } from 'polished';
import { compact } from 'lodash';
import { IconValue } from '@deepstream/common';
import { useTheme } from '../../theme/ThemeProvider';

const Dot = styled.div`
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.danger};
  position: absolute;
  top: 0;
  right: -0.1em;
`;

export type IconProps =
  {
    icon: IconValue;
    sharp?: boolean;
    regular?: boolean;
    solid?: boolean;
    light?: boolean;
    duotone?: boolean;
    thin?: boolean;
    fixedWidth?: boolean;
    spin?: boolean;
    dot?: boolean;
  } &
  TextProps &
  SpaceProps &
  ColorProps;

export const Icon: React.FC<IconProps> = React.forwardRef(({
  icon,
  sharp,
  regular,
  solid,
  light,
  duotone,
  thin,
  spin,
  fixedWidth,
  sx,
  dot,
  className,
  ...props
}, ref) => {
  // These styles are mutually exclusive, only one can be active
  const styleModifier = compact([
    regular && 'fa-regular',
    solid && 'fa-solid',
    light && 'fa-light',
    duotone && 'fa-duotone',
    thin && 'fa-thin',
  ])[0] || 'fa';

  return (
    <Text
      as="i"
      ref={ref}
      className={clsx(
        styleModifier && `${styleModifier}`,
        `fa-${icon}`,
        spin && 'fa-spin',
        fixedWidth && 'fa-fw',
        sharp && 'fa-sharp',
        className,
      )}
      sx={{
        position: 'relative',
        minWidth: 'auto',
        ...sx,
      }}
      {...props}
    >
      {dot ? <Dot /> : null}
    </Text>
);
});

export const ObsoleteIcon = React.forwardRef((props: Omit<IconProps, 'icon'>, ref) => (
  <Icon icon="ban" ref={ref} {...props} />
));

export const BorderedIcon = styled(Icon)`
  border-radius: 3px;
  border: ${props => `1px solid ${props.theme.colors.lightGray}`};
  padding: 8px;
  overflow: hidden;
`;

export const IconSquare = ({
  icon,
  size,
  color,
  fontSize,
}: {
  icon: IconProps['icon'];
  fontSize: IconProps['fontSize'];
  size: React.CSSProperties['width'];
  color: NonNullable<React.CSSProperties['color']>;
}) => {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={transparentize(0.9, theme.colors[color] ?? color)}
      minWidth={size}
      maxWidth={size}
      minHeight={size}
      maxHeight={size}
      sx={{ borderRadius: 'small' }}
    >
      <Icon
        fixedWidth
        icon={icon}
        fontSize={fontSize}
        lineHeight={size}
        color={color}
      />
    </Flex>
  );
};
