import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from 'rebass/styled-components';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { QUESTIONS_PAGE_ID } from '@deepstream/common/preQual';
import { PageRole } from '@deepstream/common/rfq-utils';
import {
  getItemLabelWithIcon,
  getItemLabelWithIconAndDescription,
  SelectField,
} from '../../../../form/SelectField';
import { RequestRole } from '../../../../types';
import { Label } from '../../../../ui/Label';

const PagePermissionSelectField = ({
  name,
  buttonStyle = {},
}: {
  name: string;
  buttonStyle?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<any>();

  const options = useMemo(() => {
    const items = [
      { pageRole: PageRole.READER, icon: 'eye' },
      { pageRole: PageRole.COMMENTER, icon: 'comment-o' },
      { pageRole: PageRole.RESPONDER, icon: 'reply' },
    ];

    return map(items, (item) => ({
      value: item.pageRole,
      icon: item.icon,
      description: t(`teamManagement.questionsPageRoleDescription.${item.pageRole}`),
      label: t(`teamManagement.pageRole.${item.pageRole}`),
    }));
  }, [t]);

  const isDisabled = !values.selectedUser;

  return (
    <SelectField
      name={name}
      items={options}
      buttonStyle={{
        borderRadius: 0,
        height: '40px',
        borderColor: 'transparent',
        pointerEvents: isDisabled ? 'none' : 'auto',
        opacity: isDisabled ? 0.5 : 1,
        ...buttonStyle,
      }}
      getButtonLabel={getItemLabelWithIcon}
      getItemLabel={getItemLabelWithIconAndDescription}
      placement="bottom-end"
      menuWidth={220}
    />
  );
};

export const TeamUserPagesPermissions = () => {
  const { t } = useTranslation('preQualification');
  const { values } = useFormikContext<{ role: RequestRole; }>();

  return (
    <Box>
      <Label label={t('questionnaire.team.pagePermissionsTable.heading')} color="lightNavy" />
      {values.role === RequestRole.OWNER ? (
        <MessageBlock variant="info" mt={0}>
          <Text>{t('questionnaire.team.pagePermissionsTable.ownerPermissions')}</Text>
        </MessageBlock>
      ) : (
        <PagePermissionSelectField
          name={`pagesPermissions.${QUESTIONS_PAGE_ID}`}
          buttonStyle={{
            borderColor: 'lightGray2',
            borderWidth: 1,
            borderRadius: 'small',
          }}
        />
      )}
    </Box>
  );
};
