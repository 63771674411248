import { partition, sortBy } from 'lodash';
import { TFunction } from 'i18next';
import {
  EvaluationPage,
  Page,
  PageRole,
  PageType,
} from './types';

export const isChatPage = <TPage extends { type?: PageType }>(page: TPage): page is Extract<TPage, { type: PageType.CHAT }> =>
  page.type === PageType.CHAT;

export const isAuctionPage = <TPage extends { type?: PageType }>(page: TPage): page is Extract<TPage, { type: PageType.AUCTION }> =>
  page.type === PageType.AUCTION;

export const isEvaluationPage = <TPage extends { type?: PageType }>(page: TPage): page is Extract<TPage, { type: PageType.EVALUATION }> =>
  page.type === PageType.EVALUATION;

export const isLinkedEvaluationPage = <TPage extends { type?: PageType; linkedPageId?: string }>(
  page: TPage,
): page is Extract<TPage, { type: PageType.EVALUATION }> & { linkedPageId: string } => (
  page.type === PageType.EVALUATION &&
  Boolean(page.linkedPageId)
);

export const isNonLinkedEvaluationPage = <TPage extends { type?: PageType; linkedPageId?: string }>(
  page: TPage,
): page is Extract<TPage, { type: PageType.EVALUATION }> => (
  page.type === PageType.EVALUATION &&
  !page.linkedPageId
);

export const canAddEvaluationForPage = (page) => {
  return !page.type || page.type === PageType.AUCTION;
};

export type PagePermissions = {
  canEdit: boolean;
  canRead: boolean;
  canRespond: boolean;
  canComment: boolean;
  readOnly: boolean;
};

export const getPagePermissions = (pageRole: PageRole): PagePermissions => ({
  canEdit: [PageRole.EDITOR].includes(pageRole),
  canRespond: [PageRole.EDITOR, PageRole.RESPONDER].includes(pageRole),
  canComment: [PageRole.EDITOR, PageRole.RESPONDER, PageRole.COMMENTER].includes(pageRole),
  canRead: [PageRole.EDITOR, PageRole.RESPONDER, PageRole.COMMENTER, PageRole.READER].includes(pageRole),
  readOnly: pageRole === PageRole.READER,
});

export const canRespond = (role: PageRole) =>
  [PageRole.EDITOR, PageRole.RESPONDER].includes(role);

const MAX_PAGE_COUNT = 1000;

export const getPagesInDisplayOrder = (pages: Page[]) => {
  const [linkedEvaluationPages, otherPages] = partition(pages, isLinkedEvaluationPage) as [EvaluationPage[], Page[]];

  const orderedOtherPages = sortBy(
    otherPages,
    page => {
      switch (page.type) {
        case PageType.AUCTION: return MAX_PAGE_COUNT + 1;
        case PageType.EVALUATION: return MAX_PAGE_COUNT + 2;
        case PageType.CHAT: return MAX_PAGE_COUNT + 3;
        default: return otherPages.indexOf(page);
      }
    },
  );

  const orderedLinkedEvaluationPages = sortBy(
    linkedEvaluationPages,
    evaluationPage => orderedOtherPages.findIndex(page => page._id === evaluationPage.linkedPageId),
  );

  return [
    ...orderedOtherPages,
    ...orderedLinkedEvaluationPages,
  ];
};

/**
 * Returns the name of the provided page.
 *
 * Note: This function does not support linked evaluation pages.
 */
export const renderPageName = (page: Page | undefined, t: TFunction) => {
  if (!page) {
    return;
  }

  switch (page.type) {
    case PageType.CHAT:
      return t('request.messages', { ns: 'translation' });
    case PageType.AUCTION:
      return t('general.auction', { ns: 'translation' });
    case PageType.EVALUATION:
      return t('general.evaluation', { ns: 'translation' });
    default:
      return page.name;
  }
};

const orderedRoles = Object.values(PageRole);

export const getLeastPrivilegedPageRole = (roleA: PageRole, roleB: PageRole) => {
  const indexA = orderedRoles.indexOf(roleA);
  const indexB = orderedRoles.indexOf(roleB);

  return orderedRoles[Math.min(indexA, indexB)];
};
