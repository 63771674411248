import * as React from 'react';
import { useQuery } from 'react-query';
import { useApi } from './api';
import { SystemFeatureFlags } from './types';
import { useAuth } from './auth/getAuthHook';
import { FullScreenError, FullScreenLoading } from './ui/FullScreenLoading';

export const SystemFeatureFlagsContext = React.createContext<SystemFeatureFlags | undefined>(undefined);

export const SystemFeatureFlagsProvider = ({ children }: { children: React.ReactNode }) => {
  const api = useApi();
  const { isAuthenticated } = useAuth();

  const { data: systemFeatureFlags, isLoading, isSuccess, isError } = useQuery({
    queryKey: ['systemFeatureFlags'],
    queryFn: api.getSystemFeatureFlags,
    staleTime: 60 * 60 * 1000,
    enabled: isAuthenticated,
  });

  return (
    <SystemFeatureFlagsContext.Provider value={systemFeatureFlags}>
      {isLoading ? (
        <FullScreenLoading />
      ) : isError ? (
        <FullScreenError error="Error initializing DeepStream" />
      ) : isSuccess ? (
        <>{children}</>
      ) : null}
    </SystemFeatureFlagsContext.Provider>
  );
};

export function useSystemFeatureFlags(): SystemFeatureFlags | undefined;
export function useSystemFeatureFlags(a: { required: true }): SystemFeatureFlags;
export function useSystemFeatureFlags(a: { required: false }): SystemFeatureFlags | undefined;
export function useSystemFeatureFlags({ required = false } = {}) {
  const systemFeatureFlags = React.useContext(SystemFeatureFlagsContext);

  if (required && !systemFeatureFlags) {
    throw new Error('No SystemFeatureFlagsContext found');
  }

  return systemFeatureFlags;
}

export const WhenEnabled = ({
  feature,
  children,
}: {
  feature: keyof SystemFeatureFlags;
  children: React.ReactChild | React.ReactChildren;
}) => {
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });

  return systemFeatureFlags[feature]
    ? (
      <>
        {children}
      </>
    )
    : null;
};

type WhenDisabledProps = {
  feature: keyof SystemFeatureFlags;
  children: React.ReactNode;
};

export const WhenDisabled = ({ feature, children }: WhenDisabledProps) => {
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });

  return systemFeatureFlags[feature]
    ? null
    : (
      <>
        {children}
      </>
    );
};
