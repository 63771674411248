import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ExchangeStatus, ExchangeType } from './rfq-utils/types';

type Icon = {
  value: FontAwesomeIconProps['icon'];
  color?: string;
  isRegular?: boolean;
};

type Icons =
  Partial<Record<ExchangeType, Icon>> & { default: Icon };

interface StatusConfig {
  icon: Icons;
}

interface StatusesConfig {
  [key: string]: {
    buyer: StatusConfig | null,
    supplier: StatusConfig | null,
  };
}

export const exchangeStatusConfig: StatusesConfig = {
  [ExchangeStatus.ACTION_REQUIRED]: {
    buyer: {
      icon: {
        default: {
          value: 'exclamation-circle',
          color: 'danger',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'exclamation-circle',
          color: 'danger',
        },
      },
    },
  },
  [ExchangeStatus.WAITING_FOR_TEAM]: {
    buyer: {
      icon: {
        default: {
          value: 'exclamation-circle',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'exclamation-circle',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.WAITING_FOR_BUYER]: {
    buyer: null,
    supplier: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.WAITING_FOR_SUPPLIER]: {
    buyer: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
    supplier: null,
  },
  [ExchangeStatus.WAITING_FOR_COUNTER_PARTY]: {
    buyer: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.BLOCKED]: {
    buyer: {
      icon: {
        default: {
          value: 'circle',
          color: 'secondary',
          isRegular: true,
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'circle',
          color: 'secondary',
          isRegular: true,
        },
      },
    },
  },
  [ExchangeStatus.OPEN]: {
    buyer: {
      icon: {
        default: {
          value: 'circle-dashed',
          color: 'success',
        },
        [ExchangeType.CLARIFICATION]: {
          value: 'exclamation-circle',
          color: 'danger',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'circle-dashed',
          color: 'success',
        },
      },
    },
  },
  [ExchangeStatus.COMPLETE]: {
    buyer: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
  },
  [ExchangeStatus.SIGNED]: {
    buyer: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
  },
  [ExchangeStatus.RESOLVED]: {
    buyer: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
  },
  [ExchangeStatus.INCOMPLETE]: {
    buyer: {
      icon: {
        default: {
          value: 'circle',
          color: 'secondary',
          isRegular: true,
        },
        [ExchangeType.EVALUATION_CRITERION]: {
          value: 'exclamation-circle',
          color: 'subtext',
          isRegular: true,
        },
      },
    },
    supplier: null,
  },
  [ExchangeStatus.INFORMATION_ONLY]: {
    buyer: {
      icon: {
        default: {
          value: 'info-circle',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'info-circle',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.OBSOLETE]: {
    buyer: {
      icon: {
        default: {
          value: 'ban',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'ban',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.CLOSED]: {
    buyer: {
      icon: {
        default: {
          value: 'circle-xmark',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'circle-xmark',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.ACTIVE]: {
    buyer: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'check-circle',
          color: 'success',
        },
      },
    },
  },
  [ExchangeStatus.SETTING_UP_E_SIGNATURE]: {
    buyer: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'spinner',
          color: 'subtext',
        },
      },
    },
  },
  [ExchangeStatus.LOCKED]: {
    buyer: {
      icon: {
        default: {
          value: 'lock',
          color: 'text',
        },
      },
    },
    supplier: null,
  },
  [ExchangeStatus.PREVIOUS_STAGE_INCOMPLETE]: {
    buyer: {
      icon: {
        default: {
          value: 'xmark',
          color: 'subtext',
        },
      },
    },
    supplier: {
      icon: {
        default: {
          value: 'xmark',
          color: 'subtext',
        },
      },
    },
  },
};
