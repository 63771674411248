import * as React from 'react';
import { update } from 'lodash';
import { Flex } from 'rebass/styled-components';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Placement } from '@floating-ui/react';

const MenuButton: React.FC<ButtonProps> = (props) => (
  <Button
    variant="tooltip"
    fontWeight={400}
    borderRadius={0}
    p="10px"
    sx={{
      cursor: 'pointer',
    }}
    {...props}
  />
);

type PopoverMenuButton = {
  label: string;
  onClick: () => void;
  hidden?: boolean;
};

type PopoverMenuProps = {
  buttons: PopoverMenuButton[];
  placement?: Placement;
  children: React.ReactElement;
};

export const PopoverMenu: React.FC<PopoverMenuProps> = ({ placement, buttons, ...props }) => {
  // Wrap the onClick handler with function that closes the tooltip
  const buttonsWithWrappedOnClick = React.useMemo(
    () => buttons
      .filter(button => !button.hidden)
      .map(button =>
        update(
          button,
          'onClick',
          (onClick) => (event: any) => {
            if (event) {
              event.preventDefault();
              event.stopPropagation();
            }
            onClick();
          },
        ),
      ),
    [buttons],
  );

  return (
    <Tooltip
      placement={placement}
      content={
        <Flex flexDirection="column">
          {buttonsWithWrappedOnClick.map(({ label, onClick }, index) => (
            <MenuButton key={index} onClick={onClick}>
              {label}
            </MenuButton>
          ))}
        </Flex>
      }
      {...props}
    />
  );
};
