import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { PageRole, User, isEvaluationPage } from '@deepstream/common/rfq-utils';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import * as rfx from '@deepstream/ui/rfx';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { fromPairs, map, negate } from 'lodash';

const pageRoleOptions = [
  { value: PageRole.NONE, label: 'No access', icon: 'times' },
  { value: PageRole.READER, label: 'Can read', icon: 'eye' },
  { value: PageRole.COMMENTER, label: 'Can comment', icon: 'comment-o' },
  { value: PageRole.RESPONDER, label: 'Can respond', icon: 'reply' },
  { value: PageRole.EDITOR, label: 'Can edit', icon: 'pencil' },
] as const;

const labelByPageRole = fromPairs(
  map(pageRoleOptions, option => [option.value, option.label]),
);

const getRoleLabel = (pageRole: PageRole) => labelByPageRole[pageRole] ?? 'Cant view';

const PageRoleCell = ({ cell: { value } }: CellProps<PageRow>) => (
  <>{getRoleLabel(value)}</>
);

type PageRow = {
  _id: string;
  name: string;
  role: PageRole | null;
};

export const PagePermissionsTable = ({ user }: { user: User }) => {
  const { pages } = rfx.useStructure();
  const isSender = rfx.useIsSender();

  const pageRows: PageRow[] = useMemo(() => {
    const visiblePages = isSender
      ? pages
      : pages.filter(negate(isEvaluationPage));

    return visiblePages.map((page) => ({
      _id: page._id,
      name: page.name,
      role: user && user.rfqRoles ? user.rfqRoles[page._id] : null,
    }));
  }, [pages, user, isSender]);

  const columns = useMemo(
    () => [
      {
        Header: 'Page name',
        accessor: 'name',
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Permission',
        accessor: 'role',
        Cell: PageRoleCell,
        Filter: withProps(FilterSelect, {
          itemToString: (value) => getRoleLabel(value),
          transformSelectedValues: (value) => getRoleLabel(value),
        }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={pageRows}
        isSortable
        noFilteredDataPlaceholder="No permissions match chosen filters"
      />
    </StaticTableStyles>
  );
};
