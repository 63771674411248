import { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Text, Box, FlexProps } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading, PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useConfirmDialog } from '@deepstream/ui/ui/useModalState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from '@deepstream/ui/rfx';
import { RequestHooksProvider } from '@deepstream/ui/modules/Request/RequestHooksProvider';
import { RfqIdProvider } from '@deepstream/ui/useRfq';
import { TemplateStatus } from '@deepstream/ui/types';
import { useWaitForRfqUnlock } from '@deepstream/ui/useWaitForUnlock';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Page } from './Page';
import { RequestSendersTable } from './RequestSendersTable';
import { StagesTable } from './StagesTable';
import { RequestDetails } from './RequestDetails';
import * as Title from './title';
import { useAdminTemplate, useAdminTemplateQueryKey } from './adminRequest';
import { TemplatePropertyList } from './TemplatePropertyList';
import { TemplateRequestsTable } from './TemplateRequestsTable';
import { templateRoute } from './AppRouting';
import { LotAndAwardScenarioSettings } from './LotAndAwardScenarioSettings';

const TimersPanel = ({ templateId }: { templateId: string }) => {
  const [timer, setTimer] = useState<number | null>(null);

  const adminApi = useAdminApi();

  const [timeRequest] = useMutation(
    async () => {
      const start = Date.now();
      await adminApi.getAdminTemplateOverview({ templateId });

      const end = Date.now();

      setTimer((end - start) / 1000);
    },
  );

  return (
    <Panel heading="Timers" padded mb={4}>
      <Stack gap={2}>
        <Box>
          <Button small onClick={() => timeRequest({})} mr={2}>
            Template structure
          </Button>
          {timer ? <>{timer}s</> : null}
        </Box>
      </Stack>
    </Panel>
  );
};

const CachePanel = ({ templateId }: { templateId: string }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [updateCache, { isLoading }] = useMutation(
    (payload: { templateId: string, fromScratch?: boolean }) => waitForRfqUnlock({
      isTemplate: true,
      command: () => adminApi.updateTemplateCache(payload),
    }),
    {
      onSuccess: () => toaster.success('Cache updated successfully'),
      onError: () => toaster.success('Could not update cache'),
    },
  );

  return (
    <Panel heading="Cache" padded>
      <Button small variant="danger" disabled={isLoading} onClick={() => updateCache({ templateId })}>
        Update cache
      </Button>
      <Button small variant="danger" disabled={isLoading} onClick={() => updateCache({ templateId, fromScratch: true })} ml={2}>
        Regenerate cache
      </Button>
    </Panel>
  );
};

export const PageTitle = ({
  flexDirection,
}: {
  flexDirection?: FlexProps['flexDirection'];
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Template
      size={flexDirection === 'column' ? 'large' : 'small'}
    />
  </Title.Container>
);

export const AdminTemplatesPage = () => {
  const adminApi = useAdminApi();
  const { templateId } = templateRoute.useParams();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const [ref, inView, entry] = useInView();

  if (!templateId) {
    throw new Error('A templateId is required');
  }

  const queryKey = useAdminTemplateQueryKey(templateId);
  const { data, error, status } = useAdminTemplate(templateId);

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { templateId }],
    wrap(adminApi.getTemplateRequests),
  );

  const [deleteTemplate] = useMutation(
    adminApi.deleteTemplate,
    {
      onSuccess: () => toaster.success('Template successfully deleted'),
      onError: () => toaster.error('An error occurred when trying to delete the template'),
      onSettled: () => queryClient.invalidateQueries(queryKey),
    },
  );
  const requestsCount = data?.requestsCount;

  const isDeleted = data?.template?.meta?.status === TemplateStatus.DELETED;

  return (
    <RfqIdProvider rfqId={templateId}>
      <RequestHooksProvider>
        <Page
          subHeading={data?.template && entry && !inView && (
            <rfx.TemplateProvider template={data.template}>
              <PageTitle />
            </rfx.TemplateProvider>
          )}
        >
          {status === 'loading' ? (
            <Panel>
              <PanelText><Loading /></PanelText>
            </Panel>
          ) : status === 'error' ? (
            <Panel heading="Error">
              <PanelText color="danger">
                <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
              </PanelText>
            </Panel>
          ) : data ? (
            <rfx.TemplateProvider template={data.template}>
              <rfx.StructureProvider structure={data.structure}>
                <div ref={ref}>
                  <PageTitle flexDirection="column" />
                </div>

                <Panel heading="General" mb={4}>
                  <TemplatePropertyList requestsCount={requestsCount} />
                </Panel>

                <Panel heading="Senders" mb={4}>
                  <RequestSendersTable />
                </Panel>

                <Panel heading="Lots and award scenarios" mb={4}>
                  <LotAndAwardScenarioSettings />
                </Panel>

                <Panel heading="Stages" mb={4}>
                  <StagesTable />
                </Panel>

                <Panel heading="Details" mb={4}>
                  <RequestDetails />
                </Panel>

                <Panel heading="Requests created from template" mb={4}>
                  <PanelLoadingWrapper status={requestsStatus} data={requests} emptyDataMessage="No requests yet">
                    <TemplateRequestsTable requests={requests} />
                  </PanelLoadingWrapper>
                </Panel>

                {!isDeleted && (
                  <Panel heading="Delete template" mb={4}>
                    <PanelPadding>
                      <Text mb={2} fontSize={2}>
                        Template will remain in database but will not be visible to any users.
                      </Text>
                      {/*
                        redundant condition: isDeleted is known to be falsy at this point
                        TODO check whether this panel should get shown for deleted templates
                      */}
                      <Tooltip content={isDeleted ? 'This template was already deleted' : ''}>
                        <div style={{ display: 'inline-block' }}>
                          <Button
                            variant="danger"
                            onClick={() => confirm(() => deleteTemplate({ templateId }))}
                          >
                            Delete
                          </Button>
                        </div>
                      </Tooltip>
                    </PanelPadding>
                    <ConfirmDeleteDialog
                      heading="Delete template?"
                      message="This cannot be undone"
                      {...dialogProps}
                    />
                  </Panel>
                )}
              </rfx.StructureProvider>
            </rfx.TemplateProvider>
          ) : null}
          <TimersPanel templateId={templateId} />

          <CachePanel templateId={templateId} />
        </Page>
      </RequestHooksProvider>
    </RfqIdProvider>
  );
};
