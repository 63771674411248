import * as React from 'react';
import { CellProps } from 'react-table';
import { UserLink } from './UserLink';

export const UserLinkCell: React.FC<CellProps<any> & { getUserId?: any }> = ({ row, cell, getUserId }) => {
  const userId = getUserId ? getUserId(row.original) : row.original._id;

  if (!userId) {
    return cell.value;
  }

  return (
    <UserLink userId={userId}>
      {cell.value}
    </UserLink>
  );
};
