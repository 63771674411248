import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

export type StackProps = BoxProps & {
  gap?: string | number;
};

export const Stack: React.FC<StackProps> = ({ gap = 4, sx, ...props }) => (
  <Box {...props} sx={{ display: 'grid', gridGap: gap, ...sx }} />
);
