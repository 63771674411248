import { useMemo } from 'react';
import {
  EvaluationCriterionExchangeDefinition,
  EvaluationSection,
} from '@deepstream/common/rfq-utils';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { reject, sumBy } from 'lodash';
import { Flex } from 'rebass/styled-components';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { withProps } from '@deepstream/ui-utils/withProps';
import * as rfx from '@deepstream/ui/rfx';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { ObsoleteCell, ExchangeDefNumberCell, ExchangeDefDescriptionCell, NumberCell, PercentCell } from '@deepstream/ui/draft/cell';
import { nestCells } from '@deepstream/ui/nestCells';
import { LabeledNumber } from '@deepstream/ui/draft/LabeledValue';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';

type EvalDef = EvaluationCriterionExchangeDefinition;

export const AdminEvaluationSectionHeaderItems = () => {
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const { relativeSectionWeightById } = rfx.useEvaluationWeights();

  const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
  const areSectionExchangeDefsObsolete = exchangeDefs.length >= 1 && nonObsoleteExchangeDefs.length === 0;

  return (
    <Flex>
      <LabeledNumber
        label="Criteria"
        number={nonObsoleteExchangeDefs.length}
        mr={3}
      />
      <LabeledNumber
        label="Weight (no)"
        number={section.weight}
        mr={3}
      />
      <LabeledNumber
        label="Percent"
        number={areSectionExchangeDefsObsolete ? NaN : relativeSectionWeightById[section._id]}
        format="percent"
      />
    </Flex>
  );
};

export const AdminEvaluationTable = ({
  exchangeDefIds,
}: {
  exchangeDefIds: string[];
}) => {
  const structure = rfx.useStructure();
  const sectionNumber = rfx.useSectionWithPosition().number;

  const exchangeDefs = useMemo(() => exchangeDefIds.map(
    exchangeDefId => structure.exchangeDefById[exchangeDefId] as EvalDef,
  ), [exchangeDefIds, structure]);

  const getWeightAsPercent = useMemo(() => {
    const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
    const totalWeight = sumBy(nonObsoleteExchangeDefs, def => def.weight || 0);

    return (def: EvalDef): number => def.isObsolete
      ? NaN // PercentCell will render NaN as em-dash
      : (def.weight || 0) / totalWeight;
  }, [exchangeDefs]);

  const columns = useMemo(
    () => [
      {
        id: 'number',
        Header: 'No.',
        accessor: (row, index) => `${sectionNumber}.${index + 1}`,
        Cell: ObsoleteCell,
        ExchangeDefNumberCell,
        width: 56,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: 'description',
        Cell: nestCells(ObsoleteCell, ExchangeDefDescriptionCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'maxPoints',
        Header: 'Max.score (points)',
        accessor: 'maxPoints',
        Cell: nestCells(ObsoleteCell, NumberCell),
        format: 'number.positive',
        textAlign: 'right',
        width: 150,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'weight',
        Header: 'Weight (points)',
        accessor: 'weight',
        Cell: nestCells(ObsoleteCell, NumberCell),
        format: 'number.positive',
        textAlign: 'right',
        width: 150,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'percent',
        Header: 'Weight',
        accessor: getWeightAsPercent,
        Cell: nestCells(ObsoleteCell, PercentCell),
        format: 'number.positive',
        width: 100,
        textAlign: 'right',
        Filter: withProps(FilterSelect, { itemToString: value => localeFormatFactorAsPercent(value, { locale: APP_ADMIN_LOCALE }) }),
        filter: filterMultipleValues,
        sortType: 'number',
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [getWeightAsPercent, sectionNumber],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={exchangeDefs}
        isSortable
        noFilteredDataPlaceholder="No evaluation criteria match chosen filters"
      />
    </StaticTableStyles>
  );
};
