import { CellProps } from 'react-table';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink } from '../../AdminTableLink';
import { questionnaireRoute } from '../../AppRouting';

export const QuestionnaireLink = ({
  questionnaireId,
  style,
  children,
}: {
  questionnaireId: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <AdminTableLink
    to={questionnaireRoute.to}
    params={{ questionnaireId }}
    onClick={stopPropagation}
    style={style}
  >
    {children}
  </AdminTableLink>
);

export const QuestionnaireLinkCell = ({ row, cell }: CellProps<QuestionnaireOverview>) => (
  <QuestionnaireLink questionnaireId={row.original._id}>
    {cell.value}
  </QuestionnaireLink>
);
