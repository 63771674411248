import * as React from 'react';
import styled from 'styled-components';
import { SpaceProps, DisplayProps } from 'styled-system';
import { lighten, transparentize } from 'polished';
import { IconValue } from '@deepstream/common';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

const hiddenRadioStyle: React.CSSProperties = {
  position: 'absolute',
  opacity: 0,
  zIndex: -1,
};

interface Input {
  label: string;
  value: string;
  icon?: IconValue;
}

export type ControlledRadioButtonGroupProps = {
  name?: string;
  value?: RadioButtonProps['value'];
  onContainerBlur?: (event: React.FocusEvent<HTMLDivElement, any>) => void;
  disabled?: boolean;
  onChange?: any;
  inputs: Input[]
  autoFocus?: boolean;
  boxStyle?: React.CSSProperties;
  renderLabel?: (input: Input) => React.ReactNode;
};

export const ControlledRadioButtonGroup = ({
  name,
  inputs,
  onChange,
  onContainerBlur,
  value,
  disabled,
  boxStyle,
  renderLabel,
  ...props
}: ControlledRadioButtonGroupProps) => {
  return (
    <ButtonGroup marginBetween="-1px" {...props} onBlur={onContainerBlur}>
      {inputs.map((input: any) => (
        <RadioButton
          key={input.value}
          name={name}
          input={input}
          boxStyle={boxStyle}
          checked={input.value === value}
          disabled={disabled || input.disabled}
          onChange={event => {
            onChange((event.target as HTMLInputElement).value);
          }}
          autoFocus={input.autoFocus}
          renderLabel={renderLabel}
        />
      ))}
    </ButtonGroup>
  );
};

const ButtonLabel = styled.label`
  display: flex;
  height: 28px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes[1]}px;
  justify-content: center;
  align-items: center;
  margin-right: -1px;
  border-radius: ${props => props.theme.space[1]}px;
  padding: ${props => props.theme.space[2]}px;

  :not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  transition:
    color 300ms,
    background-color 300ms,
    border-color 300ms,
    box-shadow 300ms;

  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.white};
  border: ${props => props.theme.borders.lightGray};

  &:hover:not(:disabled):not(.disabled) {
    background-color: ${props => props.theme.colors.gray};
    color: ${props => props.theme.colors.white};
  };
`;

export type RadioButtonProps =
  {
    label?: string | React.ReactNode;
    boxStyle?: React.CSSProperties;
    input: { value: string; label: string };
    renderLabel?: (input: Input) => React.ReactNode;
  } &
  React.HTMLProps<HTMLInputElement> &
  SpaceProps &
  DisplayProps;

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(({
  checked,
  input,
  disabled,
  children,
  boxStyle,
  style,
  renderLabel = (input) => <>{input.label}</>,
  ...props
}, ref) => {
  const id = useUniqueId();
  const theme = useTheme();
  const [focused, setFocus] = React.useState(false);

  return (
    <ButtonLabel
      style={{
        color: checked ? theme.colors.text : undefined,
        backgroundColor: checked ? lighten(0.55, theme.colors.text) : undefined,
        outline: focused ? `2px solid ${transparentize(0.5, theme.colors.primary)}` : undefined,
        ...boxStyle,
        ...style,
      }}
    >
      <input
        id={id}
        type="radio"
        checked={checked}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        style={hiddenRadioStyle}
        value={input.value}
        disabled={disabled}
        ref={ref}
        {...props}
      />
      {renderLabel(input)}
    </ButtonLabel>
  );
});

RadioButton.displayName = 'RadioButton';
