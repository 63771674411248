import * as React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';

const Level1TagContainer = styled.div<any>`
  border-left: 5px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.white};
  background-color: ${props => props.selected ? '#FAFAFA' : props.theme.colors.white};
`;

const Level1Button = styled.button<any>`
  width: 100%;
  background-color: inherit;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const Counter = styled.span`
  margin-left: 0.25rem;
  border-radius: 100px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

type Level1TagProps = {
  selected?: boolean;
  count: number;
  name?: string;
  onClick?: () => void;
  isCaseSensitive?: boolean;
};

const Level1Tag: React.FC<Level1TagProps> = ({ selected, count, name, onClick, isCaseSensitive }) => (
  <Level1TagContainer selected={selected}>
    <Level1Button type="button" onClick={onClick} selected={selected}>
      <div style={{ flex: 1 }}>{isCaseSensitive ? name : capitalize(name)}</div>
      {count > 0 ? (
        <div>
          <Counter>{count}</Counter>
        </div>
      ) : (
        null
      )}
    </Level1Button>
  </Level1TagContainer>
);

export default Level1Tag;
