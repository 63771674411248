import { IconValue } from '@deepstream/common';

export const reportsTabs = {
  reports: 'reports',
  history: 'history',
};

export const logsTabs = {
  discovery: 'discovery',
  chatbotChats: 'chatbotChats',
};

export type SidebarConfig = {
  key: string;
  heading?: string;
  tabs: Record<string, { text: string; icon: IconValue; isIconRegular?: boolean }>;
};

export const reportsSidebarConfig: SidebarConfig = {
  key: 'reports',
  heading: 'Reports',
  tabs: {
    [reportsTabs.reports]: {
      text: 'Reports',
      icon: 'file-text-o',
    },
    [reportsTabs.history]: {
      text: 'History',
      icon: 'list-check',
    },
  },
};

export const logsTabsConfig: SidebarConfig = {
  key: 'logs',
  heading: 'Logs',
  tabs: {
    [logsTabs.discovery]: {
      text: 'Discovery',
      icon: 'crosshairs',
    },
    [logsTabs.chatbotChats]: {
      text: 'Chatbot',
      icon: 'brain-circuit',
    },
  },
};
