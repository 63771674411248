import * as React from 'react';
import { Form as FormikForm, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useCountryOptions } from '@deepstream/ui/ui/countries';
import { TextField } from '@deepstream/ui/form/TextField';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

type EditCompanyAddressProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

const Form = styled(FormikForm)`
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const EditCompanyAddressModal: React.FC<EditCompanyAddressProps> = ({ company, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const countryOptions = useCountryOptions();
  const { address = {} } = company;

  const [updateCompanyAddress] = useMutation(
    adminApi.updateCompanyAddress,
    {
      onSuccess: () => toaster.success('Address updated successfully'),
      onError: () => toaster.error('An error occurred while updating the address'),
      onSettled: () => queryClient.invalidateQueries(['company', { companyId: company._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          lineOne: address.lineOne || '',
          lineTwo: address.lineTwo || '',
          city: address.city || '',
          state: address.state || '',
          postcode: address.postcode || '',
          country: address.country || '',
        }}
        validationSchema={
          yup.object().shape({
            lineOne: yup.string().trim(),
            lineTwo: yup.string().trim(),
            city: yup.string().trim(),
            state: yup.string().trim(),
            postcode: yup.string().trim(),
            country: yup.string().trim(),
          })
        }
        onSubmit={({ lineOne, lineTwo, city, state, postcode, country }) =>
          updateCompanyAddress({
            companyId: company._id,
            lineOne,
            lineTwo,
            city,
            state,
            postcode,
            country,
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit address
            </ModalHeader>
            <ModalBody style={{ flex: 1, overflowY: 'auto' }}>
              <Stack gap={3}>
                <TextField name="lineOne" label="Address line 1" />
                <TextField name="lineTwo" label="Address line 2" />
                <TextField name="city" label="City" />
                <TextField name="state" label="State / Province / Region" />
                <TextField name="postcode" label="ZIP / Postcode" />
                <SelectField
                  name="country"
                  label="Country"
                  items={countryOptions}
                  placeholder="Choose a country"
                  placement="top"
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
