import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { FlexProps } from 'rebass/styled-components';
import { QuestionnaireProvider, useQuestionnaireData } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { TeamUserModal } from '@deepstream/ui/modules/PreQualification/Questionnaire/Team/TeamUserModal';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Loading } from '@deepstream/ui/ui/Loading';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { useCurrentUserLocale } from '@deepstream/ui/useCurrentUser';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { questionnaireCompanyRoute } from '../../AppRouting';
import { useAdminQuestionnaire } from './adminPreQual';
import { Page } from '../../Page';
import * as Title from '../../title';
import { CompanyLink } from '../../CompanyLink';
import { QuestionnaireLink } from './QuestionnaireLinkCell';
import { AdminQuestionnaireUsersTable } from './AdminQuestionnaireUsersTable';

const PageTitle = ({ flexDirection, questionnaireId, company }: {
  flexDirection?: FlexProps['flexDirection'];
  questionnaireId: string;
  company: CompanyMinimized;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          mr={flexDirection === 'column' ? 0 : 2}
          size={flexDirection === 'column' ? 'large' : 'small'}
          company={company}
        />
      </CompanyLink>
      <QuestionnaireLink
        questionnaireId={questionnaireId}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Questionnaire
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
        />
      </QuestionnaireLink>
    </Title.Container>
  );
};

const QuestionnaireCompanyPropertyList = ({ isRecipient }: { isRecipient: boolean }) => {
  const { t } = useTranslation('preQualification');
  const locale = useCurrentUserLocale();
  const questionnaire = useQuestionnaireData();

  const questionnaireCompanyProperties = useMemo(
    () => isRecipient ? ([
      { name: 'Role', value: 'Recipient' },
      { name: 'Status', value: t(`questionnaireStatus.${questionnaire.status}`) },
      { name: 'Completion', value: localeFormatFactorAsPercent(questionnaire.numResolvedRequirements / questionnaire.numRequirements, { locale, decimalPlaces: 0 }) },
    ]) : ([
      { name: 'Role', value: 'Sender' },
    ]),
    [isRecipient, locale, questionnaire.numRequirements, questionnaire.numResolvedRequirements, questionnaire.status, t],
  );

  return (
    <PropertyList properties={questionnaireCompanyProperties} />
  );
};

export const AdminQuestionnaireCompanyPage = () => {
  const { companyId, questionnaireId } = questionnaireCompanyRoute.useParams();
  const [ref, inView, entry] = useInView();
  const { isExtraSmall } = useDeviceSize();
  const addUserModal = useModalState();

  const { data: questionnaire, status } = useAdminQuestionnaire();

  const sender = find(questionnaire?.senders, { _id: companyId });
  const company = sender ?? questionnaire?.recipient;
  const isRecipient = !sender;

  return (
    <Page
      subHeading={company && questionnaire && entry && !inView && (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <PageTitle
            questionnaireId={questionnaireId}
            company={company}
          />
        </QuestionnaireProvider>
      )}
    >
      {status === 'loading' ? (
        <Panel>
          <PanelText><Loading /></PanelText>
        </Panel>
      ) : status === 'error' ? (
        <Panel heading="Error">
          <PanelText>Oh no</PanelText>
        </Panel>
      ) : (questionnaire && company) ? (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <div ref={ref}>
            <PageTitle
              flexDirection="column"
              questionnaireId={questionnaireId}
              company={company}
            />
          </div>

          <Panel heading="General" mb={4}>
            <QuestionnaireCompanyPropertyList isRecipient={isRecipient} />
          </Panel>

          <Panel>
            <PanelHeader heading="Team">
              <Button
                ml={isExtraSmall ? 3 : '24px'}
                variant="primary"
                iconLeft="plus"
                onClick={addUserModal.open}
                small
              >
                Add user
              </Button>
            </PanelHeader>
            <PanelDivider />
            <AdminQuestionnaireUsersTable />
            {addUserModal.isOpen && (
              <TeamUserModal
                close={addUserModal.close}
                companyId={companyId}
              />
            )}
          </Panel>
        </QuestionnaireProvider>
      ) : null}
    </Page>
  );
};
