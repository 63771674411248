import { getRegionName } from '@deepstream/common';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useCurrentUserLocale } from '../useCurrentUser';

const CountriesContext = React.createContext<string[]>([]);

type CountriesProviderProps = {
  countryCodes?: string[];
  getCountryCodes?: () => Promise<string[]>;
  children: React.ReactNode;
};

export const CountriesProvider = React.memo(({
  countryCodes: initialData,
  getCountryCodes,
  ...props
}: CountriesProviderProps) => {
  const { data: countryCodes = [] } = useQuery(
    'countryCodes',
    () => Promise.resolve(getCountryCodes?.()),
    {
      // If initialData is not `undefined` then it should just be held forever
      initialData,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return (
    <CountriesContext.Provider value={countryCodes} {...props} />
  );
});

export const useCountryCodes = () => React.useContext(CountriesContext);

export const useCountryOptions = () => {
  const countryCodes = useCountryCodes();
  const locale = useCurrentUserLocale();

  return React.useMemo(
    () => {
      if (!countryCodes) {
        return [];
      }

      const countryOptions = countryCodes.map(
        (code: string) => ({ label: getRegionName(code, locale), value: code }),
      );

      countryOptions.sort(
        (optionA, optionB) => optionA.label.localeCompare(optionB.label, locale),
      );

      return countryOptions;
    },
    [countryCodes, locale],
  );
};
