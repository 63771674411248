import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string, defaultState = false): boolean => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    let mounted = true;
    const mediaQuery = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) return;
      setState(!!mediaQuery.matches);
    };

    mediaQuery.addListener(onChange);
    setState(mediaQuery.matches);

    return () => {
      mounted = false;
      mediaQuery.removeListener(onChange);
    };
  }, [query]);

  return state;
};
