import { useMemo } from 'react';
import {
  ExchangeProvider,
  isLineItemExchangeDef,
  isCurrencyExchangeDef,
  RfxOtherSection,
} from '@deepstream/common/rfq-utils';
import { getCurrencySymbol } from '@deepstream/utils';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { propertyOf } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { useHooks } from '@deepstream/ui/useHooks';
import { AdminLineItemsTable } from './AdminLineItemsTable';

const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const TableWrapper = styled.div<any>`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: ${props => props.isLast ? '-16px' : '0px'};
`;

export const AdminLineItemsSection = ({ isLast }: { isLast?: boolean }) => {
  const { useExchangeDefById, useSection } = useHooks();
  const exchangeDefById = useExchangeDefById();
  const { exchangeDefIds, providedBy } = useSection<RfxOtherSection>();

  const { lineItemDefs, currencyExchange } = useMemo(
    () => {
      const sectionExchangeDefs = exchangeDefIds.map(propertyOf(exchangeDefById));

      return {
        lineItemDefs: sectionExchangeDefs.filter(isLineItemExchangeDef),
        currencyExchange: sectionExchangeDefs.find(isCurrencyExchangeDef),
      };
    },
    [exchangeDefById, exchangeDefIds],
  );

  let sectionCurrency: string | undefined;
  let currencySymbol: string | undefined;

  if (currencyExchange && currencyExchange.currencies && currencyExchange.currencies.length) {
    [sectionCurrency] = currencyExchange.currencies;
    currencySymbol = getCurrencySymbol(sectionCurrency);
  }

  return (
    <>
      <Subtext mb={2}>
        Additional line items?
        <Icon
          icon={providedBy === ExchangeProvider.BOTH ? 'check' : 'times'}
          style={{ marginLeft: 10 }}
        />
      </Subtext>
      {sectionCurrency && (
        <Subtext mb={lineItemDefs && lineItemDefs.length ? 3 : 2}>
          Currency: {currencySymbol} {sectionCurrency}
        </Subtext>
      )}
      {lineItemDefs && lineItemDefs.length ? (
        <TableWrapper isLast={isLast}>
          <PanelDivider />
          <AdminLineItemsTable lineItemDefs={lineItemDefs} />
          {!isLast && <PanelDivider />}
        </TableWrapper>
      ) : (
        <Subtext>No line items yet</Subtext>
      )}
    </>
  );
};
