import * as React from 'react';
import { useToaster } from '@deepstream/ui/toast';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import { Placement } from '@floating-ui/react';
import { PopoverMenu } from './PopoverMenu';

type CopyIdAndNameMenuProps = {
  data: any;
  nameKey?: string;
  children: React.ReactElement;
  placement?: Placement;
};

export const CopyIdAndNameMenu: React.FC<CopyIdAndNameMenuProps> = ({ data, placement, children, nameKey = 'name' }) => {
  const toaster = useToaster();

  const buttons = React.useMemo(
    () => [
      {
        label: 'Copy ID',
        onClick: () => {
          copyToClipboard(data._id);
          toaster.success('ID copied to clipboard');
        },
      },
      {
        label: 'Copy name',
        onClick: () => {
          copyToClipboard(data[nameKey]);
          toaster.success('Name copied to clipboard');
        },
      },
    ],
    [data, toaster, nameKey],
  );

  return (
    <PopoverMenu buttons={buttons} placement={placement}>
      {children}
    </PopoverMenu>
  );
};
