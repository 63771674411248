import { useMemo } from 'react';
import { get } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTranslation } from 'react-i18next';
import { RequestLinkCell } from '../../RequestLinkCell';
import { formatAdminDate } from '../../formatDate';
import { useAdminNavigation } from '../../AppRouting';

const getAutoReferenceNumber = (companyId: string) => ({ senderIds, sentDashboard }: any) =>
  senderIds.includes(companyId)
    ? sentDashboard.autoReferenceNumber
    : '-';

const getRole = (companyId: string) => ({ senderIds, recipientIds }: any) => {
  if (senderIds.includes(companyId)) {
    return 'Sender';
  } else if (recipientIds.includes(companyId)) {
    return 'Recipient';
  } else {
    return 'Unknown';
  }
};

type TableProps = {
  companyId: string;
  requests: any[];
};

export const CompanyRequestsTable = ({ companyId, requests }: TableProps) => {
  const { t } = useTranslation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'autoReferenceNumber',
        Header: 'Ref.',
        accessor: getAutoReferenceNumber(companyId),
        width: 80,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Name',
        accessor: 'receivedDashboard.subject',
        Cell: nestCells(TruncateCell, RequestLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Role',
        accessor: getRole(companyId),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 120,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item) => t(`request.status.${get(item, 'meta.extendedStatus')}`),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: 'sentDashboard.bidDeadline',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 222 : 162,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate, menuWidth: 200 }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [companyId, isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={requests}
        onRowClick={request => navigation.navigateToRequestCompany(request._id, companyId)}
        isSortable
        noFilteredDataPlaceholder="No requests match chosen filters"
        hiddenColumns={!isExtraLarge && !isLarge ? ['status', 'deadline'] : []}
        smallPageControls
      />
    </BasicTableStyles>
  );
};
