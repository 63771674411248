/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import * as React from 'react';
import clsx from 'clsx';
import { Icon } from '../../elements/icon/Icon';
import {
  TExpandableRowDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  DataCellProps,
  GridDataAndCommands,
} from '../core/utils';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from './utils';

const RenderGridDataCellBase = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  rowIndex,
  columnIndex,
  style,
}: {
  data: GridDataAndCommands<EditableGridColumn, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<
      EditableGridColumn,
      TOriginalSubRowData,
      TOriginalRowData
    >>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, activeCellIndices, editedCell, selectedRange } = data;

  if (rowIndex === 0 || !row) {
    return null;
  }

  const isActive = Boolean(
    activeCellIndices &&
    activeCellIndices.rowIndex === rowIndex &&
    activeCellIndices.columnIndex === columnIndex,
  );

  const isSelected = Boolean(
    selectedRange &&
    rowIndex >= selectedRange.start.rowIndex &&
    rowIndex < selectedRange.end.rowIndex &&
    columnIndex >= selectedRange.start.columnIndex &&
    columnIndex < selectedRange.end.columnIndex,
  );

  const { toggleMenu } = column.original;

  return toggleMenu ? (
    <>
      <div
        id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
        data-test="more-exchange-actions-button"
        aria-rowindex={rowIndex + 1}
        aria-colindex={columnIndex + 1}
        role="gridcell"
        aria-haspopup="true"
        className={clsx(
          isActive && 'active',
          (row.original as any).isSubHeader && 'subheader',
        )}
        style={{
          position: 'absolute',
          top: `${row.top}px`,
          left: `${data.bodyPaddingLeft}px`,
          width: `${ACTION_COLUMN_WIDTH}px`,
          height: `${row.height}px`,
        }}
        onClick={() => {
          data.activateCellAndEnsureVisibility({ rowIndex, columnIndex });
          toggleMenu(rowIndex, columnIndex);
        }}
      >
        <div className="column-action">
          <Icon
            icon="ellipsis-h"
            color="text"
            fixedWidth
            fontSize={2}
          />
        </div>
      </div>
      <div
        role="gridcell"
        style={{
          position: 'absolute',
          top: `${row.top}px`,
          left: `${data.bodyPaddingLeft + ACTION_COLUMN_WIDTH}px`,
          width: `${column.width - data.bodyPaddingLeft - ACTION_COLUMN_WIDTH}px`,
          height: `${row.height}px`,
        }}
      >
        <DataCell
          row={row}
          column={column}
          isActive={false}
          editedCell={editedCell}
        />
      </div>
    </>
  ) : (
    <div
      id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
      aria-rowindex={rowIndex + 1}
      aria-colindex={columnIndex + 1}
      role="gridcell"
      className={clsx(
        isActive && 'active',
        isSelected && 'selected',
        (row.original as any).isSubHeader && 'subheader',
        column.isLast && 'column-last',
      )}
      style={{
        ...style,
        // TODO simplify!
        top: columnIndex < data.frozenLeftColumnCount ? `${row.top}px` : style.top,
        height: isActive && !data.staticRowHeights ? undefined : style.height,
        minHeight: style.height,
        maxHeight: isActive ? row.height + 20 : undefined,
      }}
      onClick={(event) => {
        data.activateCellAndEnsureVisibility({ rowIndex, columnIndex }, null, event);
      }}
    >
      <DataCell
        row={row}
        column={column}
        isActive={isActive}
        editedCell={data.editedCell}
      />
    </div>
  );
};

export const RenderGridDataCell = React.memo(
  RenderGridDataCellBase,
) as typeof RenderGridDataCellBase;
