import { useTranslation } from 'react-i18next';
import { EntityTeamMembership } from '@deepstream/common/user-utils';
import { LabeledValue } from '../../../draft/LabeledValue';
import { EntityTeamMembershipList } from './EntityTeamMembershipList';

export const UserRequestsList = ({
  items,
}: {
  items: EntityTeamMembership[];
}) => {
  const { t } = useTranslation();

  return (
    <LabeledValue
      label={`${t('teamManagement.teamMemberFor')} ${t('teamManagement.requestsAccessCount', { count: items.length })}`}
      infoTooltip={t('teamManagement.eachRequest')}
      value={(
        <EntityTeamMembershipList
          items={items}
          noDataPlaceholder={t('requests.noRequests')}
        />
      )}
      align="left"
      labelProps={{ mt: 3, mb: 1 }}
      valueProps={{ width: '100%' }}
    />
  );
};
