import { map, compact } from 'lodash';
import { RfqEventChange, ChangeType } from '@deepstream/common/rfq-utils/changeTypes';
import { Diff, hasBeenReordered } from '@deepstream/utils';

export const mappings = {
  exchangeDef: {
    key: 'docXDef',
    addedType: ChangeType.EXCHANGE_ADDED,
    removedType: ChangeType.EXCHANGE_REMOVED,
    updatedType: ChangeType.EXCHANGE_UPDATED,
    reorderedType: ChangeType.EXCHANGES_REORDERED,
  },
  page: {
    key: 'page',
    addedType: ChangeType.PAGE_ADDED,
    removedType: ChangeType.PAGE_REMOVED,
    updatedType: ChangeType.PAGE_UPDATED,
    reorderedType: ChangeType.PAGES_REORDERED,
  },
  section: {
    key: 'section',
    addedType: ChangeType.SECTION_ADDED,
    removedType: ChangeType.SECTION_REMOVED,
    updatedType: ChangeType.SECTION_UPDATED,
    // Section reordering is handled by `page-updated` change type
    reorderedType: null,
  },
  stage: {
    key: 'stage',
    addedType: ChangeType.STAGE_ADDED,
    removedType: ChangeType.STAGE_REMOVED,
    updatedType: ChangeType.STAGE_UPDATED,
    reorderedType: ChangeType.STAGES_REORDERED,
  },
  hirePeriod: {
    key: 'hirePeriod',
    addedType: ChangeType.HIRE_PERIOD_ADDED,
    removedType: ChangeType.HIRE_PERIOD_REMOVED,
    updatedType: ChangeType.HIRE_PERIOD_UPDATED,
    reorderedType: ChangeType.HIRE_PERIOD_ORDER_UPDATED,
  },
  lot: {
    key: 'lot',
    addedType: ChangeType.LOT_ADDED,
    removedType: ChangeType.LOT_REMOVED,
    updatedType: ChangeType.LOT_UPDATED,
    reorderedType: ChangeType.LOTS_REORDERED,
  },
};

export const contractMappings = {
  exchangeDef: {
    key: 'exchangeDef',
    addedType: 'addExchangeDefinition',
    removedType: 'removeExchangeDefinition',
    updatedType: 'updateExchangeDefinition',
    reorderedType: 'updateSection',
  },
  section: {
    key: 'section',
    addedType: 'addSection',
    removedType: 'removeSection',
    updatedType: 'updateSection',
    // Section reordering is handled by `updatePage` command
    reorderedType: null,
  },
};

export const contractTemplateMappings = {
  exchangeDef: {
    key: 'exchangeDef',
    addedType: 'addContractTemplateExchangeDefinition',
    removedType: 'removeContractTemplateExchangeDefinition',
    updatedType: 'updateContractTemplateExchangeDefinition',
    reorderedType: 'updateContractTemplateSection',
  },
  section: {
    key: 'section',
    addedType: 'addContractTemplateSection',
    removedType: 'removeContractTemplateSection',
    updatedType: 'updateContractTemplateSection',
    // Section reordering is handled by `updatePage` command
    reorderedType: null,
  },
};

type Mapping = typeof mappings[keyof typeof mappings];

type ContractMapping = typeof contractMappings[keyof typeof contractMappings];

type ContractTemplateMapping = typeof contractTemplateMappings[keyof typeof contractTemplateMappings];

export const mapDiffToChanges = <T extends { _id: string }>(diff: Diff<T>, mapping: Mapping): RfqEventChange[] => {
  const { key, addedType, removedType, updatedType, reorderedType } = mapping;

  const changes = compact([
    ...diff.added.map(entity => ({ type: addedType, [key]: entity })),
    ...diff.removed.map(entity => ({ type: removedType, [`${key}Id`]: entity._id })),
    ...diff.updated.map(entity => ({ type: updatedType, [key]: entity })),
    ...(reorderedType && hasBeenReordered(diff) ? [{ type: reorderedType, [`${key}Ids`]: map(diff.next, '_id') }] : []),
  ]) as any;

  return changes;
};

export const mapDiffToCommands = <T extends { _id: string }>(diff: Diff<T>, mapping: ContractMapping | ContractTemplateMapping) => {
  const { key, addedType, removedType, updatedType, reorderedType } = mapping;

  const commands = compact([
    ...diff.added.map(entity => ({ name: addedType, payload: entity })),
    ...diff.removed.map(entity => ({ name: removedType, payload: { _id: entity._id } })),
    ...diff.updated.map(entity => updatedType ? ({ name: updatedType, payload: entity }) : undefined),
    ...(reorderedType && hasBeenReordered(diff)
      ? [{ name: reorderedType, payload: { [`${key}Ids`]: map(diff.next, '_id') } }]
      : []
    ),
  ]) as any;

  return commands;
};
