import { useMemo } from 'react';
import { get, compact } from 'lodash';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction, Skip } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Id } from '../../Id';
import { AdminTableLink } from '../../AdminTableLink';
import { subscriptionRoute } from '../../AppRouting';
import { EditPayingStatusModal } from './EditPayingStatusModal';
import { EditSubscriptionModal } from './EditSubscriptionModal';
import { EditSearchVisibilityModal } from './EditSearchVisibilityModal';
import { EditReferenceStartNumberModal } from './EditReferenceStartNumberModal';

export const getCompanyStatus = (company: any) => {
  if (!company.claims || !company.claims.length) {
    return 'Orphaned (no claims)';
  } else if (company.claims.some((claim: any) => claim.status === 'accepted')) {
    return 'Accepted';
  } else if (company.claims.some((claim: any) => claim.status === 'rejected')) {
    return 'Rejected';
  } else {
    return 'Pending';
  }
};

const isPayingCompany = (company: any) => company.subscription ? 'Yes' : 'No';
const isHidden = (company: any) => company.hidden ? 'Yes' : 'No';

const SubscriptionUuid = ({ value }) =>
  value ? (
    <AdminTableLink to={subscriptionRoute.to} params={{ subscriptionUuid: value }}>
      {value}
    </AdminTableLink>
  ) : (
    <EmDash />
  );

export const CompanyGeneralPropertyList = ({ company }: { company: any }) => {
  const editPayingStatusModal = useModalState();
  const editSubscriptionModal = useModalState();
  const editSearchVisibilityModal = useModalState();
  const editReferenceNumberOffsetModal = useModalState();

  const companyProperties = useMemo(
    () => {
      if (!company) return [];

      return compact([
        { name: 'ID', value: company._id, Component: Id },
        { name: 'Created on', value: get(company, 'createdByUser.date'), Component: Datetime },
        { name: 'Created by', value: get(company, 'createdByUser.name') },
        { name: 'Status', value: getCompanyStatus(company) },
        { name: 'Paying company?', value: isPayingCompany(company) },
        company.subscription
          ? { name: 'Recurly subscription ID', value: company.subscription.recurlySubscriptionUuid, Component: SubscriptionUuid }
          : null,
        company.planhatId
          ? { name: 'Planhat ID', value: company.planhatId }
          : null,
        { name: 'Hide from search?', value: isHidden(company) },
        { name: 'Reference start number', value: get(company, 'referenceNumberOffset', 0) + 1 },
      ]);
    },
    [company],
  );

  return (
    <>
      <PropertyList properties={companyProperties}>
        <Skip />
        <Skip />
        <Skip />
        <Skip />
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editPayingStatusModal.open} />
        {company.subscription ? (
          <PropertyListAction label="Edit" icon="pencil-alt" onClick={editSubscriptionModal.open} />
        ) : (
          null
        )}
        {company.planhatId ? (
          <Skip />
        ) : (
          null
        )}
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editSearchVisibilityModal.open} />
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={editReferenceNumberOffsetModal.open} />
      </PropertyList>
      <EditPayingStatusModal
        isOpen={editPayingStatusModal.isOpen}
        onCancel={editPayingStatusModal.close}
        onSave={editPayingStatusModal.close}
        onRequestClose={editPayingStatusModal.close}
        company={company}
      />
      <EditSubscriptionModal
        isOpen={editSubscriptionModal.isOpen}
        onCancel={editSubscriptionModal.close}
        onSave={editSubscriptionModal.close}
        onRequestClose={editSubscriptionModal.close}
        company={company}
      />
      <EditSearchVisibilityModal
        isOpen={editSearchVisibilityModal.isOpen}
        onCancel={editSearchVisibilityModal.close}
        onSave={editSearchVisibilityModal.close}
        onRequestClose={editSearchVisibilityModal.close}
        company={company}
      />
      <EditReferenceStartNumberModal
        isOpen={editReferenceNumberOffsetModal.isOpen}
        onCancel={editReferenceNumberOffsetModal.close}
        onSave={editReferenceNumberOffsetModal.close}
        onRequestClose={editReferenceNumberOffsetModal.close}
        company={company}
      />
    </>
  );
};
