import { useState, useEffect } from 'react';

export function usePromise<ReturnData>(promise: Promise<ReturnData> | ReturnData, defaultValue?: ReturnData) {
  const [data, setData] = useState<ReturnData | undefined>(defaultValue);
  const [error, setError] = useState();
  useEffect(
    function unwrapPromise() {
      let cancelled = false;
      if (promise) {
        // using Promise.resolve handles the case where promise is not a Promise
        Promise.resolve(promise).then((data) => {
          if (!cancelled) {
            setData(data);
          }
        }).catch((error) => {
          if (!cancelled) {
            setError(error);
          }
        });
      }
      return () => {
        cancelled = true;
      };
    },
    [promise],
  );
  return { data, error };
}
