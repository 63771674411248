import { isFinite, isNumber, values } from 'lodash';
import { DateFormat, localeFormatDate, localeFormatNumber, localeFormatPrice } from '@deepstream/utils';
import {
  getOrderedAddressResponse,
  isDateTimeQuestion,
  isEmptyResponse,
} from './question-exchange';
import {
  ExtendedDateTimeFactory,
} from './extended-datetime';
import {
  QuestionExchangeDefinition,
  QuestionResponseByType,
  QuestionType,
  PredefinedQuestionOption,
} from './types';

const extendedDateTimeFactory = new ExtendedDateTimeFactory();
const isQuestionResponseByType = <T extends QuestionType>(
  response: QuestionResponseByType<QuestionType>,
  exchangeDef: QuestionExchangeDefinition,
  questionType: T,
): response is QuestionResponseByType<T> => exchangeDef.questionType === questionType;

export const createFormatQuestionResponse = ({ currencyOptions, locale, t, timeZone }: {
  currencyOptions?: {
    hideSeparators?: boolean;
  };
  locale: string;
  t: (key: string, options: Record<string, any>) => string;
  timeZone?: string;
}) => ({
  response,
  exchangeDef,
  isMultiLine,
  hideExpiryDate,
  hideMoreInformation,
}: {
  response: any;
  exchangeDef: any;
  isMultiLine?: boolean;
  hideExpiryDate?: boolean; // Only used for document questions
  hideMoreInformation?: boolean; // Only used for yes/no questions
}) => {
  if (response.noAnswer) {
    return t('notApplicable', { ns: 'general' });
  } else if (isEmptyResponse(response, exchangeDef)) {
    return null;
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.ADDRESS)) {
    return getOrderedAddressResponse(response.value, locale).join(isMultiLine ? '\n' : ', ');
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.CHECKBOXES)) {
    return response.value.join(isMultiLine ? '\n' : '; ');
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.DATE_TIME) && isDateTimeQuestion(exchangeDef)) {
    if (!response.value) {
      return response.value;
    }
    const date = extendedDateTimeFactory.fromRepresentation(response.value);
    return timeZone
      ? date.toString({ locale, timeZone })
      : date.toString({ locale });
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.PRICE)) {
    return isNumber(response.value.amount)
      ? localeFormatPrice(response.value.amount, response.value.currencyCode, { locale, ...currencyOptions })
      : null;
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.DOCUMENT)) {
    const { selectedOption, expiryDate } = response.value;
    const { requireExpiry } = exchangeDef;

    const optionLabel = values(PredefinedQuestionOption).includes(response.value.selectedOption)
      ? t(`request.question.predefinedOption.${response.value.selectedOption}`, { ns: 'translation' })
      : selectedOption;
    const formattedDate = expiryDate && localeFormatDate(new Date(expiryDate), DateFormat.DD_MMM_YYYY, { locale });

    return requireExpiry && expiryDate && !hideExpiryDate
      ? `${optionLabel} (${t('request.question.document.expiresOn', { ns: 'translation', date: formattedDate })})`
      : optionLabel;
  } else if (isQuestionResponseByType(response, exchangeDef, QuestionType.YES_NO)) {
    const { selectedOption, moreInformation } = response.value;

    const optionLabel = [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO].includes(response.value.selectedOption)
      ? t(`request.question.predefinedOption.${response.value.selectedOption}`, { ns: 'translation' })
      : selectedOption;

    return moreInformation && !hideMoreInformation
      ? `${optionLabel}. ${moreInformation}`
      : optionLabel;
  } else if (isFinite(response.value)) {
    return localeFormatNumber(response.value, { locale });
  } else {
    return response.value;
  }
};

export const isQuestionResponseField = auditTrailFieldKey => ['previousResponse', 'updatedResponse'].includes(auditTrailFieldKey);

export const formatQuestionResponse = (response, exchangeDef, timeZone, locale, t) => {
  if (response.noAnswer) {
    return t('notApplicable', { ns: 'general' });
  } else if (isEmptyResponse(response, exchangeDef)) {
    return '—';
  } else if (exchangeDef.questionType === QuestionType.ADDRESS) {
    return getOrderedAddressResponse(response.value, locale).join(', ');
  } else if (exchangeDef.questionType === QuestionType.CHECKBOXES) {
    return response.value.join('; ');
  } else if (exchangeDef.questionType === QuestionType.DATE_TIME) {
    const date = extendedDateTimeFactory.fromRepresentation(response.value);
    return date.toString({ locale, timeZone });
  } else if (exchangeDef.questionType === QuestionType.PRICE) {
    return localeFormatPrice(response.value.amount, response.value.currencyCode, { locale });
  } else if (exchangeDef.questionType === QuestionType.DOCUMENT) {
    const { selectedOption, expiryDate } = response.value;
    const { requireExpiry } = exchangeDef;

    const optionLabel = values(PredefinedQuestionOption).includes(response.value.selectedOption)
      ? t(`request.question.predefinedOption.${response.value.selectedOption}`, { ns: 'translation' })
      : selectedOption;
    const formattedDate = expiryDate && localeFormatDate(new Date(expiryDate), DateFormat.DD_MMM_YYYY, { locale });

    return requireExpiry && expiryDate
      ? `${optionLabel} (${t('request.question.document.expiresOn', { ns: 'translation', date: formattedDate })})`
      : optionLabel;
  } else if (exchangeDef.questionType === QuestionType.YES_NO) {
    const { selectedOption, moreInformation } = response.value;

    const optionLabel = [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO].includes(response.value.selectedOption)
      ? t(`request.question.predefinedOption.${response.value.selectedOption}`, { ns: 'translation' })
      : selectedOption;

    return moreInformation
      ? `${optionLabel}. ${moreInformation}`
      : optionLabel;
  } else if (isFinite(response.value)) {
    return localeFormatNumber(response.value, { locale });
  } else {
    return response.value;
  }
};
