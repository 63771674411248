import { QuestionnaireStatus } from './preQual';

export enum SupplierListRuleType {
  QUESTIONNAIRE = 'questionnaire',
}

export type SupplierListQuestionnaireRule = {
  type: SupplierListRuleType.QUESTIONNAIRE;
  questionnaireTemplateId: string;
  statuses: QuestionnaireStatus[];
};

// Can be extended in the future with other types of rules
export type SupplierListRule = SupplierListQuestionnaireRule;

export enum SupplierListTriggerSource {
  USER = 'user',
  SYSTEM = 'system',
  CLI = 'cli',
  ADMIN = 'admin',
}

export enum SupplierListType {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export type SupplierListSupplier = {
  _id: string;
  addedAt: Date;
  addedBy: string;
  triggerSource: SupplierListTriggerSource;
};

// Extends the SupplierListSupplier type with the names of the supplier and the user who added them.
export type ExtendedSupplierListSupplier = Omit<SupplierListSupplier, 'addedBy'> & {
  name: string;
  addedBy: {
    _id: string;
    name: string;
  };
};

export type SupplierList = {
  _id: string;
  companyId: string;
  creationTimestamp: Date;
  lastUpdated: Date;
  name: string;
  description: string;
  isDefault: boolean;
  listType: SupplierListType;
  suppliers: SupplierListSupplier[];
  supplierIds: string[];
  status: SupplierListStatus;
  rules: SupplierListRule[];
};

export type SupplierListOverview = {
  _id: string;
  companyId: string;
  creationTimestamp: Date;
  lastUpdated: Date;
  name: string;
  description: string;
  isDefault: boolean;
  listType: SupplierListType;
  suppliers: SupplierListSupplier[];
  supplierIds: string[];
  status: SupplierListStatus;
  rules: SupplierListRule[];
};

export type ExtendedSupplierListRule = SupplierListRule & {
  questionnaireTemplateName: string;
};

export type ExtendedSupplierListOverview = Omit<SupplierListOverview, 'suppliers' | 'rules'> & {
  suppliers: ExtendedSupplierListSupplier[];
  rules: ExtendedSupplierListRule[];
};

export enum SupplierListStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}
