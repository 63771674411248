import { useCallback } from 'react';
import { ModelLockState } from '@deepstream/common/eventSourcedModel';
import { ONE_MINUTE, ONE_SECOND } from '@deepstream/common/constants';
import { isNumber } from 'lodash';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useRfqId } from './useRfq';
import { useGlobalProcessing, ProcessingContext } from './GlobalProcessingProvider';

const DEFAULT_POLLING_INTERVAL = 2 * ONE_SECOND;
const DEFAULT_TIMEOUT = 10 * ONE_MINUTE;

type WaitForUnlockParams = {
  command: () => Promise<void>,
  pollingInterval?: number,
  timeout?: number,
  getLockState: () => Promise<ModelLockState>,
  setIsProcessingModalVisible?: (processingContext: ProcessingContext | null) => void,
};

export const waitForUnlock = async ({
  command,
  pollingInterval,
  timeout,
  getLockState,
  setIsProcessingModalVisible,
}: WaitForUnlockParams) => {
  let showModalTimeoutId;

  try {
    showModalTimeoutId = setTimeout(() => {
      setIsProcessingModalVisible?.(ProcessingContext.REQUEST);
    }, 3000);
    await command();
    clearTimeout(showModalTimeoutId);
    setIsProcessingModalVisible?.(null);
  } catch (err: any) {
    const statusCode = err?.response?.status;

    // if command hasn't failed due to a timeout, rethrow
    if (
      isNumber(statusCode) &&
      ![
        408, // request timeout
        504, // gateway timeout
        444, // 'connection closed without response' (nginx)
        499, // 'client closed request' (nginx)
      ].includes(statusCode)
    ) {
      clearTimeout(showModalTimeoutId);
      setIsProcessingModalVisible?.(null);
      throw err;
    }

    const effectivePollingInterval = pollingInterval || DEFAULT_POLLING_INTERVAL;
    const effectiveTimeout = timeout || DEFAULT_TIMEOUT;

    await new Promise((resolve, reject) => {
      const startTime = Date.now();

      const intervalId = setInterval(async () => {
        if (Date.now() > startTime + effectiveTimeout) {
          clearTimeout(showModalTimeoutId);
          clearInterval(intervalId);
          setIsProcessingModalVisible?.(null);
          reject(err);
        } else {
          try {
            const lockState = await getLockState();

            if (lockState === ModelLockState.UNLOCKED) {
              clearTimeout(showModalTimeoutId);
              clearInterval(intervalId);
              setIsProcessingModalVisible?.(null);
              resolve(true);
            }
          } catch (err) {
            // When we couldn't get a lock state from the
            // server, we assume a network issue and try again
            // in the next intervallic invocation of this function.
          }
        }
      }, effectivePollingInterval);
    });
  }
};

type CallbackParams =
  & { isTemplate?: boolean; }
  & Omit<WaitForUnlockParams, 'getLockState' | 'setIsProcessingModalVisible'>;

export const useWaitForRfqUnlock = () => {
  const api = useApi();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { setProcessingContext } = useGlobalProcessing();

  return useCallback(
    async ({ isTemplate, ...options }: CallbackParams) => {
      return waitForUnlock({
        ...options,
        getLockState: () => api.getRfqLockState({ currentCompanyId, rfqId, isTemplate }),
        // @ts-ignore ts(2322) FIXME: Type '((isProcessing: ProcessingContext) => void) | undefined' is not assignable to type '((processingContext: ProcessingContext | null) => void) | undefined'.
        setIsProcessingModalVisible: setProcessingContext,
      });
    },
    [setProcessingContext, api, rfqId, currentCompanyId],
  );
};
