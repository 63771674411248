import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQuery, useQueryClient } from 'react-query';

import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '@deepstream/ui/form/TextField';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { SelectField } from '@deepstream/ui/form/SelectField';
import { SwitchField } from '@deepstream/ui/form/SwitchField';

type AddOrEditSubscriptionModalProps =
  { externalSystemId: string, subscription?: any; onCancel: any; onSave: any } &
  ModalProps;

export const AddOrEditSubscriptionModal = ({
  externalSystemId,
  subscription,
  onCancel,
  onSave,
  ...props
}: AddOrEditSubscriptionModalProps) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();

  const isEditing = Boolean(subscription);

  const { data: eventTypes } = useQuery(
    ['eventTypes'],
    wrap(adminApi.getSubscriptionEventTypes),
  );

  const [addSubscription] = useMutation(
    adminApi.addSubscription,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Subscription added'),
      ),
      onError: () => toaster.error('Subscription could not be added'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['externalSystem', { externalSystemId }]),
      ),
    },
  );

  const [updateSubscription] = useMutation(
    adminApi.updateSubscription,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Subscription updated'),
      ),
      onError: () => toaster.error('Subscription could not be updated'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['externalSystem', { externalSystemId }]),
      ),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={
          isEditing ? subscription : {
            description: '',
            systemEventType: '',
            webhook: {
              url: '',
              apiKey: '',
            },
            receiveNotifications: false,
          }
        }
        validationSchema={
          yup.object().shape({
            description: yup.string(),
            systemEventType: yup.string(),
            webhook: yup.object().shape({
              url: yup.string(),
              apiKey: yup.string(),
            }),
            receiveNotifications: yup.boolean(),
          })
        }
        onSubmit={async ({ description, systemEventType, webhook, receiveNotifications }) => {
          if (isEditing) {
            return updateSubscription({
              _id: subscription._id,
              externalSystemId,
              description,
              systemEventType,
              webhookUrl: webhook.url,
              webhookApiKey: webhook.apiKey,
              receiveNotifications,
            });
          } else {
            return addSubscription({
              externalSystemId,
              description,
              systemEventType,
              webhookUrl: webhook.url,
              webhookApiKey: webhook.apiKey,
              receiveNotifications,
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {isEditing ? 'Update subscription' : 'Add subscription'}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <SelectField
                  name="systemEventType"
                  label="System event type"
                  items={eventTypes ? eventTypes.map(type => ({ label: type, value: type })) : []}
                  placeholder="Choose an event type"
                />
                <TextField
                  isMultiLine
                  name="description"
                  label="Description"
                />
                <TextField
                  name="webhook.url"
                  label="Webhook URL"
                />
                <TextField
                  name="webhook.apiKey"
                  label="Webhook API Key"
                />
                <SwitchField
                  name="receiveNotifications"
                  label="Can receive notifications?"
                  disabled={isEditing}
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {isEditing ? 'Update subscription' : 'Add subscription'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
