import * as React from 'react';
import { castArray, isFunction, iteratee, overSome, map, filter as _filter } from 'lodash';
import { Counter, BadgeProps } from './ui/Badge';
import { useTasks } from './TasksProvider';

export type TaskCounterProps = BadgeProps & {
  filter: any;
};

export const getFilteredTasksCount = (tasks: any[], filter: any) => {
  const filterFnArray = map(
    castArray(filter),
    currentFilter => isFunction(currentFilter) ? currentFilter : iteratee(currentFilter),
  );

  const filteredTasks = _filter(
    tasks,
    overSome(filterFnArray),
  );

  return filteredTasks.length;
};

export const TaskCounter: React.FC<TaskCounterProps> = ({
  filter,
  ...props
}) => {
  const { tasks } = useTasks();

  const count = React.useMemo(
    () => getFilteredTasksCount(tasks, filter),
    [tasks, filter],
  );

  return <Counter count={count} {...props} />;
};
