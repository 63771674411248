export const SECTION_PADDING_TOP = 20;

export const DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT = 48;
export const DEFAULT_FROZEN_HEADER_SECOND_LINE_HEIGHT = 30;
export const DEFAULT_FROZEN_FOOTER_HEIGHT = 40;
export const SECTION_ROW_HEIGHT = 48;
export const SUB_HEADER_HEIGHT = 32;
export const INLINE_SUB_ROW_HEIGHT = 32;
export const ESTIMATED_DATA_CELL_HEIGHT = 48 + SECTION_PADDING_TOP;

export const BORDER_ADJUSTMENT = 1;

export const DEFAULT_FROZEN_LEFT_COLUMN = {
  _id: 'description',
  width: 350,
};

export const DEFAULT_FROZEN_LEFT_COLUMN_IDS = ['description'];

export const DEFAULT_NAVIGABLE_RANGE = {
  startRowIndex: 1,
  startColumnIndex: 1,
};
