import { useMemo } from 'react';
import { getPagesInDisplayOrder, isEvaluationPage, isLinkedEvaluationPage, Page, PageRole, PageType, renderPageName } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { negate } from 'lodash';
import * as rfx from '../../../rfx';

export type PermissionRow = {
  _id: string;
  name: string;
  role?: PageRole;
  page?: {
    pageId: string;
    type?: PageType;
    role: PageRole;
  },
  linkedEvaluationPage?: {
    pageId: string;
    type?: PageType;
    role: PageRole;
  }
};

export const usePermissionRows = (
  pages: Page[],
  rfqRoles: Record<string, PageRole>,
  isOwner: boolean,
): PermissionRow[] => {
  const { t } = useTranslation();
  const isSender = rfx.useIsSender();

  // @ts-ignore ts(2322) FIXME: Type '{ _id: string; name: string | undefined; page: { pageId: string; type: PageType | undefined; role: PageRole; }; linkedEvaluationPage: { pageId: string; type: PageType | undefined; role: PageRole; } | null; }[]' is not assignable to type 'PermissionRow[]'.
  return useMemo(() => {
    const visiblePages = isSender
      ? pages
      : pages.filter(negate(isEvaluationPage));

    const orderedPages = getPagesInDisplayOrder(visiblePages)
      .filter(page => !isLinkedEvaluationPage(page));

    return orderedPages
      .map((page) => {
        const linkedEvaluationPage = visiblePages.find(
          evaluationPage => (evaluationPage as any).linkedPageId === page._id,
        );

        return {
          _id: page._id,
          name: renderPageName(page, t),
          page: {
            pageId: page._id,
            type: page.type,
            role: rfqRoles?.[page._id] ?? (
              isOwner ? PageRole.EDITOR : PageRole.NONE
            ),
          },

          linkedEvaluationPage: linkedEvaluationPage
            ? {
              pageId: linkedEvaluationPage._id,
              type: linkedEvaluationPage.type,
              role: rfqRoles?.[linkedEvaluationPage._id] ?? (
                isOwner ? PageRole.EDITOR : PageRole.NONE
              ),
            }
            : null,
        };
      });
  }, [isOwner, pages, rfqRoles, t, isSender]);
};
