import { isEmpty } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Panel, PanelText } from '@deepstream/ui-kit/elements/Panel';

import { TaskCounter } from '@deepstream/ui/TaskCounter';
import { Flex } from 'rebass/styled-components';
import * as Title from './title';
import { Page } from './Page';
import { CompaniesTable } from './CompaniesTable';
import { CompaniesToVerifyTable } from './CompaniesToVerifyTable';
import { RecentActivityRequestsTable } from './RecentActivityRequestsTable';
import { RecentActivityAuctionsTable } from './RecentActivityAuctionsTable';
import { AdminRecentContractsTable } from './modules/Contracts/AdminRecentContractsTable';
import { UsersTable } from './UsersTable';
import { RecentActivityTemplatesTable } from './RecentActivityTemplatesTable';
import { RecentActivityQuestionnaireTemplatesTable } from './modules/PreQualification/RecentActivityQuestionnaireTemplatesTable';

const PageTitle: React.FC = () => (
  <Title.Container>
    <Title.RecentActivity size="large" />
  </Title.Container>
);

export const AdminRecentActivityPage: React.FC = () => {
  const adminApi = useAdminApi();

  const {
    data: claimsWithCompanyAndUser,
    status: claimsStatus,
  } = useQuery(
    ['pendingCompanyClaims'],
    wrap(adminApi.getPendingCompanyClaims),
  );

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { limit: 50 }],
    wrap(adminApi.getRecentlyCreatedRequests),
  );

  const { data: users, status: usersStatus } = useQuery(
    ['users', { limit: 50 }],
    wrap(adminApi.getRecentlyActiveUsers),
  );

  const { data: companies, status: companiesStatus } = useQuery(
    ['companies', { limit: 50 }],
    wrap(adminApi.getRecentlyCreatedCompanies),
  );

  const { data: auctions, status: auctionsStatus } = useQuery(
    ['auctions', { limit: 50 }],
    wrap(adminApi.getRecentAuctions),
  );

  const { data: contractOverviews, status: contractsStatus } = useQuery(
    ['contracts', { limit: 50 }],
    wrap(adminApi.getRecentlyCreatedContracts),
  );

  const { data: templates, status: templatesStatus } = useQuery(
    ['templates', { limit: 50 }],
    wrap(adminApi.getRecentlyCreatedTemplates),
  );

  const { data: questionnaireTemplatesOverview, status: questionnaireTemplatesStatus } = useQuery(
    ['questionnaireTemplatesOverview', { limit: 50 }],
    wrap(adminApi.getRecentlyCreatedQuestionnaireTemplatesOverview),
  );

  return (
    <Page>
      <PageTitle />
      {claimsStatus === 'error' ? (
        <Panel heading="Companies to verify" mb={4}>
          <PanelText>Could not load companies with pending claims</PanelText>
        </Panel>
      ) : !claimsWithCompanyAndUser || isEmpty(claimsWithCompanyAndUser) ? (
        null
      ) : (
        <Panel
          heading={
            <Flex alignItems="center">
              Companies to verify
              <TaskCounter filter={[{ type: 'decideApproval' }]} ml={2} />
            </Flex>
          }
          mb={4}
        >
          <CompaniesToVerifyTable claimsWithCompanyAndUser={claimsWithCompanyAndUser} />
        </Panel>
      )}
      <Panel heading="Auctions" mb={4}>
        {auctionsStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : auctionsStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : auctions.length ? (
          <RecentActivityAuctionsTable auctions={auctions} />
        ) : (
          <PanelText color="gray">No auctions yet</PanelText>
        )}
      </Panel>
      <Panel heading="Templates" mb={4}>
        {templatesStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : templatesStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : templates.length ? (
          <RecentActivityTemplatesTable templates={templates} />
        ) : (
          <PanelText color="gray">No templates yet</PanelText>
        )}
      </Panel>
      <Panel heading="Questionnaire templates" mb={4}>
        {questionnaireTemplatesStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : questionnaireTemplatesStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : !questionnaireTemplatesOverview ? (
          null
        ) : questionnaireTemplatesOverview.templates.length ? (
          <RecentActivityQuestionnaireTemplatesTable
            templates={questionnaireTemplatesOverview.templates}
            companyById={questionnaireTemplatesOverview.companyById}
          />
        ) : (
          <PanelText color="gray">No questionnaires yet</PanelText>
        )}
      </Panel>
      <Panel heading="Requests" mb={4}>
        {requestsStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : requestsStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : requests.length ? (
          <RecentActivityRequestsTable requests={requests} />
        ) : (
          <PanelText color="gray">No requests yet</PanelText>
        )}
      </Panel>
      <Panel heading="Contracts" mb={4}>
        {contractsStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : contractsStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : !contractOverviews ? (
          null
        ) : contractOverviews.length ? (
          <AdminRecentContractsTable contractOverviews={contractOverviews} />
        ) : (
          <PanelText color="gray">No contracts yet</PanelText>
        )}
      </Panel>
      <Panel heading="Users" mb={4}>
        {usersStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : usersStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : users.length ? (
          <UsersTable users={users} />
        ) : (
          <PanelText color="gray">No users yet</PanelText>
        )}
      </Panel>
      <Panel heading="Companies" mb={4}>
        {companiesStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : companiesStatus === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : companies.length ? (
          <CompaniesTable companies={companies} />
        ) : (
          <PanelText color="gray">No companies yet</PanelText>
        )}
      </Panel>
    </Page>
  );
};
