import * as React from 'react';
import { isNil, noop } from 'lodash';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  intervalToDuration,
  isBefore,
  isEqual,
} from 'date-fns';

import { ONE_SECOND } from '@deepstream/common/constants';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useInterval } from '@deepstream/ui-kit/hooks/useInterval';
import { useForceUpdate } from '@deepstream/ui-kit/hooks/useForceUpdate';

/*
 * NOTES:
 * To make it easier to share exchange-related components across contexts where
 * deadlines are/aren't relevant, we use an "infinite date" to represent the absence
 * of a deadline (ie: an infinite deadline is equivalent to no deadline). This
 * enables identical types for both absence and existence, instead of requiring
 * special cases to handle contexts with no relation to deadlines.
 */

/**
 * Unfortunately, JS can't represent infinite dates, so this is the best we can do!
 */
export const WAYYYYYY_THE_FUCK_IN_THE_FUTURE = new Date(1e15);

const NO_DEADLINE = null;

export type Deadline = {
  date: any;
  remainingMs: number;
  countdown: {
      seconds: number;
      minutes: number;
      hours: number;
      days: number;
  };
  hasPassed: boolean;
};

export const useDeadline = ({
  deadline: deadlineProp,
  referenceDate,
  onPassed = noop,
  refreshRateMs = 250,
}: {
  deadline?: Date;
  referenceDate?: Date | null;
  onPassed?: any;
  refreshRateMs?: number;
} = {}): Deadline => {
  const forceUpdate = useForceUpdate();
  const now = new Date();
  const startDate = referenceDate ? new Date(referenceDate) : now;

  const deadline = new Date(deadlineProp || now);
  const isInfiniteDeadline = deadline.valueOf() === WAYYYYYY_THE_FUCK_IN_THE_FUTURE.valueOf();
  const timeRemaining = intervalToDuration({
    start: startDate,
    end: deadline,
  });

  const countdown = {
    seconds: isInfiniteDeadline ? Infinity : (timeRemaining.seconds || 0),
    minutes: isInfiniteDeadline ? Infinity : (timeRemaining.minutes || 0),
    hours: isInfiniteDeadline ? Infinity : (timeRemaining.hours || 0),
    days: isInfiniteDeadline ? Infinity : (timeRemaining.days || 0),
  };

  const remainingMs = deadline.valueOf() - startDate.valueOf();
  const remainingSeconds = remainingMs / ONE_SECOND;

  const hasPassed = remainingSeconds <= 0;

  useInterval(
    () => {
      forceUpdate();

      if (remainingSeconds <= 1) {
        onPassed();
      }
    },
    // No need to run the interval if the deadline is infinite
    isInfiniteDeadline || referenceDate ? null : refreshRateMs,
  );

  return {
    date: isInfiniteDeadline ? NO_DEADLINE : deadline,
    remainingMs,
    countdown,
    hasPassed,
  };
};

const DeadlinesPassedContext = React.createContext<boolean>(true);

export const DeadlinesPassedProvider = ({
  haveDeadlinesPassed,
  children,
}: {
  haveDeadlinesPassed: boolean;
  children: React.ReactNode;
}) => (
  <DeadlinesPassedContext.Provider value={haveDeadlinesPassed}>
    {children}
  </DeadlinesPassedContext.Provider>
);

export const useHaveDeadlinesPassed = () => {
  const haveDeadlinesPassed = React.useContext<boolean>(DeadlinesPassedContext);

  if (isNil(haveDeadlinesPassed)) {
    throw new Error('No DeadlinesPassedContext found');
  }

  return haveDeadlinesPassed;
};

type CountdownTime = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const useCountdown = ({
  deadline,
  referenceDate,
  isActive = true,
  // The lower the rate the less stuttering from the interval running late
  refreshRateMs = 250,
}: {
  deadline: Date,
  referenceDate: Date;
  isActive?: boolean;
  refreshRateMs?: number;
}) => {
  const getCountdownTime = React.useCallback(() => {
    if (isBefore(deadline, referenceDate)) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: differenceInDays(deadline, referenceDate),
      hours: differenceInHours(deadline, referenceDate) % 24,
      minutes: differenceInMinutes(deadline, referenceDate) % 60,
      seconds: differenceInSeconds(deadline, referenceDate) % 60,
    };
  }, [deadline, referenceDate]);

  const [countdown, setCountdown] = React.useState<CountdownTime>(getCountdownTime());
  const [isRunning, setIsRunning] = React.useState(isActive);

  const updateTime = React.useCallback(
    () => {
      const time = getCountdownTime();
      const isEnded = time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0;

      setCountdown(time);

      if (isEnded) {
        setIsRunning(false);
      }
    },
    [getCountdownTime],
  );

  useWatchValue(
    deadline,
    updateTime,
    isEqual,
  );

  useWatchValue(
    referenceDate,
    updateTime,
    isEqual,
  );

  useInterval(updateTime, isRunning ? refreshRateMs : null);

  return countdown;
};
