import { useMemo } from 'react';
import { get } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { Company } from '@deepstream/ui/ui/types';
import { useTranslation } from 'react-i18next';
import { useAdminNavigation } from './AppRouting';

type UserCompaniesTableProps = {
  user: any;
  companies: Company[];
};

export const UserCompaniesTable = ({ user, companies }: UserCompaniesTableProps) => {
  const { t } = useTranslation();
  const { isLarge, isExtraLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: CompanyMinimizedNameAndLogoCell,
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'userRole',
        Header: 'User role',
        accessor: (company: any) => {
          const roleRestrictionProfile = get(user, `roleRestrictionProfiles.${company._id}`);

          if (!roleRestrictionProfile) {
            return '—';
          }

          return t(`user.globalRole.${roleRestrictionProfile}`);
        },
        Filter: withProps(FilterSelect, { itemToString: (value) => value }),
        filter: filterMultipleValues,
        width: 200,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [t, user],
  );

  return (
    <BasicTableStyles>
      <Table
        hasStaticHeight={false}
        isPaginated
        columns={columns}
        data={companies}
        onRowClick={company => navigation.navigateToCompanyUser(company._id, user._id)}
        isSortable
        noFilteredDataPlaceholder="No companies match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['isAdmin'] : []}
      />
    </BasicTableStyles>
  );
};
