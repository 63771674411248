import { RfqStatus } from '@deepstream/common/rfq-utils';

export const budgetColorById = {
  [RfqStatus.AWARDED]: 'rgb(243,156,18)', // #F39C12
  [RfqStatus.DEADLINES_PASSED]: 'rgb(200,100,100)', // #C86464
  [RfqStatus.DRAFT]: 'rgb(126,136,144)', // #7E8890
  [RfqStatus.LIVE]: 'rgb(24,188,156)', // #18BC9C
  noBudget: 'rgb(226,232,239)', // #E2E8Ef
};

export const inactiveBudgetColorById = {
  [RfqStatus.AWARDED]: 'rgba(243,156,18, 0.3)',
  [RfqStatus.DEADLINES_PASSED]: 'rgba(200,100,100, 0.3)',
  [RfqStatus.DRAFT]: 'rgba(126,136,144, 0.3)',
  [RfqStatus.LIVE]: 'rgba(24,188,156, 0.3)',
  noBudget: 'rgba(226,232,239, 0.3)',
};

export const confirmStatusColorById = {
  confirmed: 'rgb(24,188,156)', // #18BC9C
  unconfirmed: 'rgb(126,136,144)', // #7E8890
  noValue: 'rgb(226,232,239)', // #E2E8Ef
};

export const inactiveConfirmStatusColorById = {
  confirmed: 'rgba(24,188,156, 0.3)',
  unconfirmed: 'rgba(126,136,144, 0.3)',
  noValue: 'rgba(226,232,239, 0.3)',
};

export const savingsMethodColorById = {
  'singleResponseLineItemSavings': 'rgb(195,171,244)', // #C3ABF4
  'multiResponseLineItemSavings': 'rgb(220,234,56)', // #DCEA38
  'manualSavings': 'rgb(226,232,239)', // #E2E8EF
  'auctionLineItemSavings': 'rgb(245,172,120)', // #F5AC78
  'budgetFinalValueSavings': 'rgb(52,152,219)', // #3498DB
};

export const inactiveSavingsMethodColorById = {
  'singleResponseLineItemSavings': 'rgba(195,171,244, 0.3)', // #C3ABF4
  'multiResponseLineItemSavings': 'rgba(220,234,56, 0.3)', // #DCEA38
  'manualSavings': 'rgba(226,232,239, 0.3)', // #E2E8EF
  'auctionLineItemSavings': 'rgba(245,172,120, 0.3)', // #F5AC78
  'budgetFinalValueSavings': 'rgba(52,152,219, 0.3)', // #3498DB
};
