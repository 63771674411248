import * as React from 'react';
import { get, capitalize } from 'lodash';
import { useQueryClient, useQuery } from 'react-query';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction, Skip } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { isUserSuspended } from '@deepstream/common/user-utils';
import { callAll } from '@deepstream/utils/callAll';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { Id } from './Id';
import { UserSuspensionModal } from './UserSuspensionModal';

export const getStatus = (user: any) =>
  user.pendingCreation ? 'Pending' : user.status ? capitalize(user.status) : 'Active';

type UserGeneralPropertyListProps = { user: any };

export const UserGeneralPropertyList: React.FC<UserGeneralPropertyListProps> = ({ user }) => {
  const userSuspensionModal = useModalState();
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const { data: lastVerificationEmail } = useQuery(
    ['verificationEmailSentDate', { userId: user._id, emailType: 'verifyEmail' }],
    wrap(adminApi.getLastEmailByType),
  );

  const [resendVerificationEmail, { status: verificationEmailStatus }] = useMutation(
    adminApi.resendVerificationEmail, {
      onSuccess: callAll(
        () => toaster.success('Verification email resent'),
        () => {
          queryClient.invalidateQueries(['verificationEmailSentDate', { userId: user._id }]);
          queryClient.invalidateQueries(['emails', { userId: user._id }]);
        },
      ),
      onError: callAll(
        () => toaster.error('Verification email could not be resent'),
      ),
    });

  const generalProperties = React.useMemo(
    () => {
      if (!user) return [];

      const properties = [
        { name: 'Account status', value: user.pendingCreation ? 'Not signed up' : 'Signed up' },
        { name: 'Verification status', value: user.verified ? 'Verified' : 'Unverified' },
        { name: 'Suspended?', value: isUserSuspended(user) ? 'Yes' : 'No' },
        { name: 'ID', value: user._id, Component: Id },
        { name: 'Created on', value: get(user, 'createdAt'), Component: Datetime },
        { name: 'Last seen', value: get(user, 'lastSeen'), Component: Datetime },
      ];

      if (isUserSuspended(user)) {
        const suspensionType = {
          name: 'Suspension type',
          value: user.suspendedUntil ? 'Incorrect password' : 'Admin initiated',
        };
        const suspensionExpires = {
          name: 'Suspension expires',
          value: user.suspendedUntil,
          Component: Datetime,
        };
        properties.splice(3, 0, suspensionType);
        properties.splice(4, 0, suspensionExpires);
      }

      if (!user.pendingCreation && !user.verified) {
        const verificationLastSent = {
          name: 'Verification last sent',
          value: get(lastVerificationEmail, 'date'),
          Component: Datetime,
        };
        properties.splice(2, 0, verificationLastSent);
      }

      return properties;
    },
    [user, lastVerificationEmail],
  );

  const canResendVerificationEmail = !user.pendingCreation && !user.verified;

  return (
    <>
      <PropertyList properties={generalProperties}>
        <Skip />
        <Skip />
        {canResendVerificationEmail ? (
          <PropertyListAction
            label="Resend"
            icon="paper-plane"
            onClick={() => {
              resendVerificationEmail(user._id);
            }}
            disabled={verificationEmailStatus === 'loading'}
          />
        ) : (
          null
        )}
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={userSuspensionModal.open} />
      </PropertyList>
      <UserSuspensionModal
        onSave={userSuspensionModal.close}
        onCancel={userSuspensionModal.close}
        isOpen={userSuspensionModal.isOpen}
        onRequestClose={userSuspensionModal.close}
        user={user}
      />
    </>
  );
};
