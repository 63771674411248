import { useState, useCallback, useMemo } from 'react';

export const useOpenState = (initialIsOpen = false) => {
  const [isOpen, setOpen] = useState(initialIsOpen);
  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const toggle = useCallback(() => setOpen(isOpen => !isOpen), [setOpen]);

  const openState = useMemo(
    () => ({ isOpen, open, close, toggle }),
    [isOpen, open, close, toggle],
  );

  return openState;
};
