import { useAuth0 } from '@auth0/auth0-react';
import { useSimpleAuth } from '../local-auth/useSimpleAuth';
import { useEnv } from '../env';

export const useAuth = () => {
  const env = useEnv();

  // Since the environment is immutable, this is safe
  return (env.AUTH === 'simple' ? useSimpleAuth : useAuth0)();
};
