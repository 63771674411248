import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { RfxOtherSection } from '@deepstream/common/rfq-utils';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '@deepstream/ui/rfx';
import { AdminQuestionsTable } from './AdminQuestionsTable';

const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const TableWrapper = styled.div<any>`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: ${props => props.isLast ? '-16px' : '0px'};
`;

export const AdminQuestionsSection = ({ isLast }: { isLast?: boolean }) => {
  const { exchangeDefIds } = rfx.useSection<RfxOtherSection>();

  return exchangeDefIds && exchangeDefIds.length ? (
    <TableWrapper isLast={isLast}>
      <PanelDivider />
      <AdminQuestionsTable exchangeDefIds={exchangeDefIds} />
      {!isLast && <PanelDivider />}
    </TableWrapper>
  ) : (
    <Subtext>No questions added yet</Subtext>
  );
};
