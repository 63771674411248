import { useMemo } from 'react';
import { noop } from 'lodash';
import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { TasksContext } from '@deepstream/ui/TasksProvider';

export const AdminTasksProvider = (props) => {
  const adminApi = useAdminApi();

  const {
    data: claimsWithCompanyAndUser,
  } = useQuery({
    queryKey: ['pendingCompanyClaims'],
    queryFn: wrap(adminApi.getPendingCompanyClaims),
  });

  const value = useMemo(
    () => ({
      tasks: claimsWithCompanyAndUser?.map(claim => ({
        _id: `${claim.company._id}/${claim.user._id}`,
        type: 'decideApproval',
      })),
      removeTasks: noop,
    }),
    [claimsWithCompanyAndUser],
  );

  return <TasksContext.Provider value={value} {...props} />;
};
