import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { SelectField } from '@deepstream/ui/form/SelectField';

type EditCompanyTypeProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

const typeOptions = [
  { value: 'buyer', label: 'Buyer' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'both', label: 'Both' },
];

export const EditCompanyTypeModal: React.FC<EditCompanyTypeProps> = ({ company, onCancel, onSave, ...props }) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateCompanyType] = useMutation(
    adminApi.updateCompanyType,
    {
      onSettled: () => queryClient.invalidateQueries(['company', { companyId: company._id }]),
    },
  );

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          type: company.group,
        }}
        validationSchema={
          yup.object().shape({
            type: yup.string().required('Required'),
          })
        }
        onSubmit={async ({ type }, { setSubmitting }) => {
          try {
            await updateCompanyType({ companyId: company._id, type });
            toaster.success('Company type updated successfully');
            setSubmitting(false);
            onSave();
          } catch (error) {
            toaster.error('An error occurred while updating the company type');
            setSubmitting(false);
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit company type
            </ModalHeader>
            <ModalBody>
              <SelectField
                name="type"
                label="Company type"
                placeholder="Choose a company type"
                items={typeOptions}
              />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
