// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={75}
    height={75}
    fill="none"
    {...props}
  >
    <circle cx={38} cy={37} r={32} fill="#3498DB" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="m38.401 38.816 4.17-2.317a6.56 6.56 0 0 0 3.372-5.73 7.217 7.217 0 0 0-7.602-6.758 7.37 7.37 0 0 0-7.482 5.696M38.718 48.836a.314.314 0 1 1-.629-.001.314.314 0 0 1 .629 0"
    />
  </svg>
);
export default SvgQuestion;
