import { useEffect, useRef } from 'react';

export const useInterval = (callback: ((...args: any[]) => void), delay: number | null) => {
  const savedCallback: any = useRef();

  useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback],
  );

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const handler = (...args: any[]) => savedCallback.current(...args);

      if (delay !== null) {
        const id = setInterval(handler, delay);
        return () => clearInterval(id);
      }
    },
    [delay],
  );
};
