import { identity, noop } from 'lodash';
import { useMemo, useCallback } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Pictogram } from '@deepstream/ui-kit';
import { usePrevious } from '@deepstream/ui-kit/hooks/usePrevious';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Panel, PanelDivider, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { SearchForm } from './SearchForm';
import { CompaniesList } from './CompaniesList';
import { Pagination } from './Pagination';
import { SearchState, SearchStateProvider, useCompanySearch } from './useCompanySearch';
import { PaginationLocation } from './PaginationLocation';
import { SupplierListsPanel } from './SupplierListsPanel';
import { SelectSupplierLists } from './SelectSupplierLists';
import { CompanyFiltersPanel } from './CompanyFiltersPanel';
import { useModalState } from './ui/useModalState';
import { InviteModal } from './InviteModal';
import { useIsAuthorizedStakeholder } from './useIsAuthorizedStakeholder';

type SearchCompaniesProps = {
  isInternalNetwork?: boolean;
  navigateToSentInvites?: () => void;
  navigateToCompany?: (companyId: string) => void;
};

export const SearchCompanies = ({
  isInternalNetwork = false,
  navigateToCompany = noop,
  navigateToSentInvites = noop,
}: SearchCompaniesProps) => {
  const { t } = useTranslation();
  const inviteCompaniesModalState = useModalState();
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

  const {
    request: { data: result, isLoading, refetch: searchCompanies },

    text,
    pageIndex,
    pageSize,
    supplierListIds,
    deliveryCountries,
    products,
    productsOperator,

    updateText,
    nextPage,
    previousPage,
    updatePageSize,
    updateSupplierListIds,
    updateDeliveryCountries,
    updateProducts,
    setPageIndex,

    productTags,
    updateProductTags,
  } = useCompanySearch({ isInternalNetwork });

  const searchState: SearchState = useMemo(
    () => ({
      text,
      pageIndex,
      pageSize,
      supplierListIds,
      deliveryCountries,
      productTags,
      products,
      productsOperator,
    }),
    [
      pageIndex,
      pageSize,
      text,
      supplierListIds,
      deliveryCountries,
      productTags,
      products,
      productsOperator,
    ],
  );

  // `identity` is used to only store truthy values as the "previous" result
  const prevResult = usePrevious(result, identity);
  const visibleResult = result || prevResult;

  const renderCompanyActions = useCallback(
    (company: any) => (
      <SelectSupplierLists
        targetCompanyId={company._id}
        onChange={searchCompanies}
      />
    ),
    [searchCompanies],
  );

  return (
    <>
      <Panel mb={4} p={20}>
        <Flex>
          <InviteModal
            isOpen={inviteCompaniesModalState.isOpen}
            close={inviteCompaniesModalState.close}
            customizedMessage={t('invite.customizedMessages.forNetwork')}
            onInvitesSent={() => {}}
          />
          <SearchForm
            key={text}
            text={text}
            onSubmit={updateText}
            disabled={isLoading}
            mr={3}
            flex={1}
          />
          <ButtonGroup marginBetween="1px">
            <Button variant="primary" iconLeft="envelope" onClick={inviteCompaniesModalState.open}>
              {t('network.sendAnInvite')}
            </Button>
            <DropdownMenu variant="primary" iconLeft="ellipsis-h" menuStyle={{ width: '177px' }}>
              <DropdownMenuItem icon="paper-plane" onSelect={navigateToSentInvites}>
                {t('network.viewSentInvites')}
              </DropdownMenuItem>
            </DropdownMenu>
          </ButtonGroup>
        </Flex>
      </Panel>
      <Flex flexDirection={['column', null, 'row']}>
        <Stack gap={4} mr={4} width={['100%', null, '300px', '364px']} height="fit-content">
          <SupplierListsPanel
            selectedIds={supplierListIds}
            onChange={updateSupplierListIds}
            selectableSupplierListIds={visibleResult?.availableFilters.supplierListIds}
            isInternalNetwork={isInternalNetwork}
          />
          <SearchStateProvider searchState={searchState}>
            <CompanyFiltersPanel
              isInternalNetwork={isInternalNetwork}
              availableFilters={visibleResult?.availableFilters}
              updateDeliveryCountries={updateDeliveryCountries}
              updateProductTags={updateProductTags}
              updateProducts={updateProducts}
            />
          </SearchStateProvider>
        </Stack>
        <Box flex={1} mt={[4, null, 0]}>
          {visibleResult ? (
            visibleResult.total === 0 ? (
              <Panel height="165px">
                <Pictogram
                  variant="empty-search-companies-state"
                  label={t('network.noCompaniesFound')}
                  labelPosition="bottom"
                />
              </Panel>
            ) : (
              <Panel>
                <PanelText>
                  <PaginationLocation
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    total={visibleResult.total}
                  />
                </PanelText>
                <PanelDivider />
                <CompaniesList
                  companies={visibleResult.companies}
                  navigateToCompany={navigateToCompany}
                  renderActions={!isAuthorizedStakeholder ? renderCompanyActions : undefined}
                />
                <Pagination
                  small
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  canPreviousPage={pageIndex > 0}
                  canNextPage={pageIndex < visibleResult.numPages - 1}
                  pageCount={visibleResult.numPages}
                  gotoPage={setPageIndex}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  setPageSize={updatePageSize}
                />
              </Panel>
            )
          ) : null}
        </Box>
      </Flex>
    </>
  );
};
