import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { IconValue } from '@deepstream/common';
import { Icon, IconProps } from './Icon';

const BorderedIconWrapper = styled(Box)`
  border: 1px solid ${props => props.theme.colors.lightGray2};  
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px
`;

type BorderedIconProps = {
  icon: IconValue;
  iconProps?: Partial<IconProps>;
} & BoxProps;

export const BorderedIcon = ({ icon, iconProps, ...props }: BorderedIconProps) => (
  <BorderedIconWrapper {...props}>
    <Icon fontSize={2} color="gray" icon={icon} {...iconProps} />
  </BorderedIconWrapper>
);
