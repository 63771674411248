import * as React from 'react';
import { Box, Flex, SxStyleProp, Text } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';
import { identity, range } from 'lodash';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Select, SelectProps } from './ui/Select';
import { Bold } from './Bold';
import { Input } from './ui/Input';
import { Required } from './Required';
import { getItemLabel, getItemValue } from './form/SelectField';

const GotoNavigation = ({
  currentIndex,
  count,
  gotoIndex,
}: {
  currentIndex: number;
  count: number;
  gotoIndex: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(currentIndex);

  const popover = usePopover({
    placement: 'top-start',
  });

  const handleGotoClick = () => {
    gotoIndex(Number(index) - 1);
    popover.close();
  };

  const handlePopoverOpen = () => {
    setIndex(currentIndex);
    popover.open();
  };

  return (
    <>
      <Button
        variant="secondary-outline"
        type="button"
        size="small"
        onClick={handlePopoverOpen}
        // @ts-ignore ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'Ref<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        small
      >
        {t('table.pagination.goTo')}
      </Button>
      <Popover width={200} onClickOutside={popover.close} {...popover}>
        <Flex
          flexDirection="column"
          height="100%"
          width="100%"
          p={3}
          justifyContent="space-between"
          bg="white"
          sx={{ gap: '8px' }}
        >
          <Text fontSize={2}>
            {t('table.pagination.goToNumber')}
            <Required />
          </Text>
          <Input type="number" min={1} max={count} value={index} onChange={event => setIndex(event.target.value)} />
          <Text fontSize={1} color="subtext">
            {t('table.pagination.minMax', { min: 1, max: count })}
          </Text>
          <Flex sx={{ gap: '8px' }} alignSelf="flex-end">
            <Button type="button" variant="secondary" onClick={() => popover.close()}>
              {t('table.pagination.cancel')}
            </Button>
            <Button type="button" disabled={index < 1 || index > count} onClick={handleGotoClick}>
              {t('table.pagination.go')}
            </Button>
          </Flex>
        </Flex>
      </Popover>
    </>
  );
};

type PageSizeSelectProps = {
  pageSize: number;
  pageSizes: number[];
  setPageSize: UsePaginationInstanceProps<any>['setPageSize'];
  translationKey?: string;
} & Omit<SelectProps, 'selectedItem' | 'onChange' | 'items' | 'itemToString'>;

export const PageSizeSelect: React.FC<PageSizeSelectProps> = ({ pageSize, pageSizes, setPageSize, translationKey = 'table.pagination.showSize', ...props }) => {
  const { t } = useTranslation();

  const options = React.useMemo(
    () => pageSizes.map((size: number) => ({
      key: size.toString(),
      label: t(translationKey, { size }),
      value: size,
    })),
    [pageSizes, translationKey, t],
  );

  const onChange = React.useCallback(
    (changes) => {
      const { value } = changes.selectedItem;
      setPageSize(value);
    },
    [setPageSize],
  );

  return (
    <Select
      selectedItem={pageSize}
      onChange={onChange}
      items={options}
      itemToString={getItemValue}
      getItemLabel={getItemLabel}
      {...props}
    />
  );
};

export const PageInfo = ({
  pageIndex,
  setPageIndex,
  maxPageIndex,
}: {
  pageIndex: number;
  setPageIndex: (newPageIndex: number) => void;
  maxPageIndex: number;
}) => {
  const { t } = useTranslation('translation');

  const pageSelectItems = React.useMemo(() => {
    return range(maxPageIndex + 1).map(index => `${index + 1}`);
  }, [maxPageIndex]);

  return (
    <Flex alignItems="center" fontSize={1} sx={{ gap: '3px' }}>
      {t('general.page', { count: 1 })}
      <Select
        small
        items={pageSelectItems}
        itemToString={identity}
        selectedItem={`${pageIndex + 1}`}
        menuWidth="80px"
        onChange={value => {
          const newPageIndex = Number(value.selectedItem) - 1;
          setPageIndex(newPageIndex);
        }}
      />
      {t('table.pagination.ofTotal', { total: maxPageIndex + 1 })}
    </Flex>
  );
};

type PageButtonProps = ButtonProps & {
  small?: boolean;
};

const PreviousPageButton: React.FC<PageButtonProps> = ({ disabled, small, ...props }) => (
  <Button
    type="button"
    variant="secondary-outline"
    iconLeft="chevron-left"
    px={small ? 0 : 3}
    width={small ? '28px' : undefined}
    disabled={disabled}
    sx={{ zIndex: disabled ? 0 : 1 }}
    small={small}
    {...props}
  />
);

const NextPageButton: React.FC<PageButtonProps> = ({ disabled, small, ...props }) => (
  <Button
    type="button"
    variant="secondary-outline"
    iconLeft="chevron-right"
    px={small ? 0 : 3}
    width={small ? '28px' : undefined}
    disabled={disabled}
    sx={{ zIndex: disabled ? 0 : 1 }}
    small={small}
    {...props}
  />
);

export type PaginationProps =
  UsePaginationState<any> &
  Omit<UsePaginationInstanceProps<any>, 'page' | 'pageOptions' | 'gotoPage' | 'setPageSize'> &
  {
    pageSizes?: number[];
    small?: boolean;
    mt?: number;
    goToItem?: (item: number) => void;
    gotoPage?: (item: number) => void;
    currentItem?: number;
    itemsCount?: number;
    setPageSize?: (item: number) => void;
    sx?: SxStyleProp;
  };

export const Pagination: React.FC<PaginationProps> = ({
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageSizes = [5, 10, 20, 30, 40, 50],
  small,
  mt,
  previousPage,
  nextPage,
  setPageSize,
  goToItem,
  currentItem,
  itemsCount,
  sx = {},
}) => (
  <Flex
    mt={mt}
    px={20}
    py={small ? 16 : 20}
    alignItems="center"
    justifyContent="space-between"
    sx={{ borderTop: 'lightGray', ...sx }}
  >
    {setPageSize && (
      <Box style={{ width: 110 }}>
        <PageSizeSelect
          small={small}
          pageSize={pageSize}
          pageSizes={pageSizes}
          setPageSize={setPageSize}
        />
      </Box>
    )}
    <Flex alignItems="center" sx={{ gap: '16px' }}>
      {/*
       // @ts-ignore ts(18048) FIXME: 'currentItem' is possibly 'undefined'. */}
      {goToItem && <GotoNavigation currentIndex={currentItem + 1} count={itemsCount} gotoIndex={goToItem} />}
      <Text fontSize={small ? 1 : 2} lineHeight={1}>
        <Trans
          i18nKey="table.pagination.pageNumOfTotal"
          values={{ num: pageIndex + 1, total: pageCount }}
          components={{ 1: <Bold />, 2: <Bold /> }}
        />
      </Text>
      <ButtonGroup marginBetween="-1px">
        <PreviousPageButton small={small} onClick={previousPage} disabled={!canPreviousPage} />
        <NextPageButton small={small} onClick={nextPage} disabled={!canNextPage} />
      </ButtonGroup>
    </Flex>
  </Flex>
);
