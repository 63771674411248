import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import React from 'react';
import { AdminTableLink } from './AdminTableLink';
import { templateRoute } from './AppRouting';

export const TemplateLink = ({ templateId, children }: { templateId: string; children: React.ReactNode }) => (
  <AdminTableLink
    to={templateRoute.to}
    params={{ templateId }}
    onClick={stopPropagation}
  >
    {children}
  </AdminTableLink>
);
