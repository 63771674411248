import * as React from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useWatchValue } from '../../hooks/useWatchValue';
import { useHover } from '../../hooks/useHover';
import {
  TExpandableRowDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  DataCellProps,
  GridDataAndCommands,
} from '../core/utils';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from './utils';
import { Icon } from '../../elements/icon/Icon';

const RenderHoverableGridDataCellBase = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  rowIndex,
  columnIndex,
  style,
}: {
  data: GridDataAndCommands<EditableGridColumn, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<
      EditableGridColumn,
      TOriginalSubRowData,
      TOriginalRowData
    >>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, hoverCellIndices, editedCell, activeCellIndices } = data;

  useWatchValue(
    cellHovered,
    (hovered) => {
      if (hovered) {
        data.setHoverCellIndices({ rowIndex, columnIndex });
      } else {
        data.setHoverCellIndices((hoverCellIndices) => {
          // only set hoverCellIndices to null when the current value
          // hasn't been set to a different cell
          return isEqual(hoverCellIndices, { rowIndex, columnIndex })
            ? null
            : hoverCellIndices;
        });
      }
    },
  );

  const isHover = Boolean(
    hoverCellIndices &&
    hoverCellIndices.rowIndex === rowIndex,
  );

  const isActive = Boolean(
    activeCellIndices &&
    activeCellIndices.rowIndex === rowIndex &&
    activeCellIndices.columnIndex === columnIndex,
  );

  if (rowIndex === 0 || !row) {
    return null;
  }

  const { toggleMenu } = column.original;

  return toggleMenu ? (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <div
        ref={cellRef}
        id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
        aria-rowindex={rowIndex + 1}
        aria-colindex={columnIndex + 1}
        role="gridcell"
        className={clsx(
          isActive && 'active',
          isHover && 'hover',
          (row.original as any).isSubHeader && 'subheader',
        )}
        style={{
          position: 'absolute',
          top: `${row.top}px`,
          left: `${data.bodyPaddingLeft}px`,
          width: `${ACTION_COLUMN_WIDTH}px`,
          height: `${row.height}px`,
        }}
        onClick={() => {
          data.activateCellAndEnsureVisibility({ rowIndex, columnIndex });
          toggleMenu(rowIndex, columnIndex);
        }}
      >
        <div className="column-action">
          <Icon
            icon="ellipsis-h"
            color="text"
            fixedWidth
            fontSize={2}
          />
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <div
        role="gridcell"
        className={clsx(
          isHover && 'hover',
        )}
        style={{
          position: 'absolute',
          top: `${row.top}px`,
          left: `${data.bodyPaddingLeft + ACTION_COLUMN_WIDTH}px`,
          width: `${column.width - data.bodyPaddingLeft - ACTION_COLUMN_WIDTH}px`,
          height: `${row.height}px`,
        }}
      >
        <DataCell
          row={row}
          column={column}
          isActive={false}
          editedCell={editedCell}
        />
      </div>
    </>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      ref={cellRef}
      id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
      aria-rowindex={rowIndex + 1}
      aria-colindex={columnIndex + 1}
      role="gridcell"
      className={clsx(
        isHover && 'hover',
      )}
      style={{
        ...style,
        // TODO simplify!
        top: columnIndex < data.frozenLeftColumnCount ? `${row.top}px` : style.top,
        height: style.height,
        minHeight: style.height,
        cursor: data.onRowClick ? 'pointer' : undefined,
      }}
      onClick={() => {
        data.onRowClick?.(row.original);
      }}
    >
      <DataCell
        row={row}
        column={column}
        isActive={false}
        editedCell={editedCell}
      />
    </div>
  );
};

export const RenderHoverableGridDataCell = React.memo(
  RenderHoverableGridDataCellBase,
) as typeof RenderHoverableGridDataCellBase;
