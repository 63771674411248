import { createContext, memo, useContext } from 'react';
import { find } from 'lodash';
import { ActionType } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from './types';

export const ExchangeContext = createContext<ExchangeSnapshot | null>(null);

export const ExchangeProvider = memo<{ exchange: ExchangeSnapshot | null; children: React.ReactNode }>(({ exchange, ...props }) => (
  <ExchangeContext.Provider value={exchange} {...props} />
));

export function useExchange<TExchange extends ExchangeSnapshot = ExchangeSnapshot>(): TExchange {
  const exchange = useContext(ExchangeContext);
  if (!exchange) throw new Error('No exchange found');
  return exchange as TExchange;
}

export const useExchangeAction = (actionType: ActionType) =>
  find(useExchange().actions, action => action.type === actionType);
