import { QuestionAddressField } from '@deepstream/common/rfq-utils';

export const addressFields = [
  QuestionAddressField.COMPANY_NAME,
  QuestionAddressField.LINE_ONE,
  QuestionAddressField.LINE_TWO,
  QuestionAddressField.CITY,
  QuestionAddressField.STATE,
  QuestionAddressField.POSTCODE,
  QuestionAddressField.COUNTRY,
];

export const contactFields = [
  QuestionAddressField.CONTACT_NAME,
  QuestionAddressField.EMAIL,
  QuestionAddressField.PHONE,
];
