import * as React from 'react';
import { useQueryClient } from 'react-query';
import { TeamMembershipsByEntity } from '@deepstream/common/user-utils';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { useApi, useAdminApi } from '@deepstream/ui/api';
import { useIsOnlyAdmin } from '@deepstream/ui/modules/Company/TeamManagement/useIsOnlyAdmin';
import { useCompanyUsers } from '@deepstream/ui/modules/Company/TeamManagement/useCompanyUsers';
import { useMutation } from '@deepstream/ui/useMutation';
import { User } from '@deepstream/ui/types';
import { callAll } from '@deepstream/utils/callAll';
import { RemoveSoleOwnerModal } from '@deepstream/ui/modules/Company/TeamManagement/RemoveSoleOwnerModal';
import { useWaitForProcessingTask } from '@deepstream/ui/useWaitForProcessingTask';
import { useAdminNavigation } from './AppRouting';

type RemoveUserFromCompanyButtonProps = {
  user: User;
  companyId: string;
};

export const RemoveUserFromCompanyButton: React.FC<RemoveUserFromCompanyButtonProps> = ({
  user,
  companyId,
}) => {
  const userId = user._id;
  const adminApi = useAdminApi();
  const api = useApi();
  const navigation = useAdminNavigation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const deleteConfirmationModal = useModalState();
  const soleOwnerModal = useModalState();
  const [teamMembershipsByEntity, setTeamMembershipsByEntity] = React.useState<TeamMembershipsByEntity | null>(null);

  const isOnlyAdmin = useIsOnlyAdmin({ userId, companyId });
  const { isSuccess } = useCompanyUsers(companyId);
  const waitForProcessingTask = useWaitForProcessingTask();

  const [getTeamMembershipsByEntity] = useMutation(
    api.getTeamMembershipsByEntity,
    {
      onError: () => toaster.error('Could not get entity team member overviews'),
      onSettled: () => queryClient.invalidateQueries(['company', { companyId }]),
    },
  );

  const handleRemoveUserClick = async () => {
    const teamMembershipsByEntity = await getTeamMembershipsByEntity({
      companyId,
      userId,
    });

    const {
      request,
      contract,
      questionnaire,
    } = teamMembershipsByEntity;

    if (request.isOnlyOwner || contract.isOnlyOwner || questionnaire.isOnlyOwner) {
      setTeamMembershipsByEntity(teamMembershipsByEntity);

      soleOwnerModal.open();
    } else {
      deleteConfirmationModal.open();
    }
  };

  const [removeUserFromCompany] = useMutation(
    (payload) => waitForProcessingTask({
      command: () => adminApi.removeUserFromCompany(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success('User successfully removed from company'),
        () => navigation.navigateToCompany(companyId),
      ),
      onError: () => toaster.error('An error occurred when trying to remove the user'),
    },
  );

  const removeUser = React.useCallback(
    async ({
      user,
      questionnaireReplacementUserId,
      rfxReplacementUserId,
      contractReplacementUserId,
    }: {
      user: User;
      questionnaireReplacementUserId?: string;
      rfxReplacementUserId?: string;
      contractReplacementUserId?: string;
    }) => {
      await removeUserFromCompany({
        userId: user._id,
        companyId,
        questionnaireReplacementUserId,
        rfxReplacementUserId,
        contractReplacementUserId,
      });
    },
    [companyId, removeUserFromCompany],
  );

  return (
    <>
      <Button
        variant="danger"
        onClick={handleRemoveUserClick}
        disabled={!isSuccess}
      >
        Remove
      </Button>
      <Dialog
        heading="Remove user from company?"
        style={{ content: { maxWidth: '500px' } }}
        body={isOnlyAdmin ? (
          <MessageBlock variant="warn" my={2}>
            No email or notification will be sent. This user is the only admin
            and will also be removed from all questionnaires, requests and
            contracts belonging to this company.
          </MessageBlock>
        ) : (
          <MessageBlock variant="info" my={2}>
            No email or notification will be sent. This user will also be
            removed from all questionnaires, requests and contracts belonging
            to this company.
          </MessageBlock>
        )}
        okButtonText="Remove"
        okButtonVariant="danger"
        isOpen={deleteConfirmationModal.isOpen}
        onOk={() => removeUserFromCompany({
          userId,
          companyId,
        }, {
          onSuccess: deleteConfirmationModal.close,
        })}
        onCancel={deleteConfirmationModal.close}
      />
      {soleOwnerModal.isOpen && teamMembershipsByEntity && (
        <RemoveSoleOwnerModal
          isOpen={soleOwnerModal.isOpen}
          teamMembershipsByEntity={teamMembershipsByEntity}
          user={user}
          companyId={companyId}
          close={soleOwnerModal.close}
          removeUser={removeUser}
        />
      )}
    </>
  );
};
