import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { Truncate } from '../elements/text/Truncate2';
import { getActiveColor, getInactiveColor, PieChartSlice } from './PieChart';

const Circle = ({ color, ...props }: { color: string } & React.SVGProps<SVGSVGElement>) => (
  <svg width="8" height="8" {...props}>
    <g>
      <circle cx="4" cy="4" r="4" fill={color} />
    </g>
  </svg>
);

export const PieChartSliceList = ({
  slices,
  activeSliceId,
  setActiveSliceId,
}: {
  slices: PieChartSlice[];
  activeSliceId: string | null;
  setActiveSliceId: (activeSliceId: string | null) => void;
}) => {
  return (
    <Box as="ul" sx={{ listStyle: 'none', p: 0 }}>
      {slices.map((slice, index) => (
        <Box
          as="li"
          key={slice.id}
          fontSize={2}
          color={!activeSliceId || slice.id === activeSliceId ? (
            'text'
          ) : (
            'subtext'
          )}
          pb={1}
          pl={3}
          onMouseEnter={() => setActiveSliceId(slice.id)}
          onMouseLeave={() => setActiveSliceId(null)}
          sx={{ cursor: 'default', position: 'relative' }}
          fontWeight={slice.id === activeSliceId ? 500 : undefined}
        >
          <Circle
            color={!activeSliceId || slice.id === activeSliceId ? (
              getActiveColor(index)
            ) : (
              getInactiveColor(index)
            )}
            style={{ position: 'absolute', top: '7px', left: '0px' }}
          />
          <Truncate>
            {slice.label}
          </Truncate>
        </Box>
      ))}
    </Box>
  );
};
