import * as React from 'react';
import { ContextType, HooksContext } from '../../useHooks';
import {
  useBidProgress,
  useContractData,
  useContractId,
  useContractSection,
  useExchangeDefById,
  useExchanges,
  usePagePermissions,
  useSummary,
  useContractActions,
  useStatus,
  useIsSuperUserOrOwner,
} from './contract';
import { useShowValidationErrors } from './draftContract';
import { useESign } from './useESign';

export const ContractHooksProvider = ({ children }: { children: React.ReactNode }) => {
  const contextValue = React.useMemo(
    () => ({
      contextType: ContextType.CONTRACT,
      useStatus,
      useContextId: useContractId,
      useSummary,
      useExchangeDefById,
      useExchanges,
      useShowValidationErrors,
      useSection: useContractSection as any, // TODO Fix type
      useAuction: () => null,
      usePagePermissions,
      useBidProgress,
      useCommentNotifications: () => [],
      useRecipients: () => useContractData()?.recipients || [],
      useAvailableBulletins: () => [],
      useUpdateBulletin: () => null,
      useActions: useContractActions,
      useESign,
      useIsSuperUserOrOwner,
    }),
    [],
  );

  return (
    <HooksContext.Provider value={contextValue}>
      {children}
    </HooksContext.Provider>
  );
};
