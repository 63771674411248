import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { Flex, Text, Box } from 'rebass/styled-components';
import ReactSwitch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

export const CheckedIcon = ({ label }: { label: string }) => (
  <Text fontFamily="primary" color="white" fontSize={1} pl="12px" pt="4px">
    {label}
  </Text>
);

export const UncheckedIcon = ({ label }: { label: string }) => (
  <Text fontFamily="primary" color="text" fontSize={1} pl="7px" pt="4px">
    {label}
  </Text>
);

const Yes = () => {
  const { t } = useTranslation();

  return (
    <CheckedIcon label={t('general.yes')} />
  );
};

const No = () => {
  const { t } = useTranslation();

  return (
    <UncheckedIcon label={t('general.no')} />
  );
};

export type SwitchProps =
  ReactSwitch['props'] &
  {
    text?: string;
    appendedText?: string;
    checkedText?: React.ReactNode;
    uncheckedText?: React.ReactNode;
    switchHeight?: string | number;
    switchTooltip?: React.ReactChild;
  };

export const Switch = ({
  text,
  appendedText: propsAppendedText,
  checkedIcon,
  uncheckedIcon,
  checkedText,
  uncheckedText,
  switchHeight,
  width,
  checked,
  switchTooltip,
  ...props
}: SwitchProps) => {
  const { colors } = React.useContext(ThemeContext);

  const appendedText = propsAppendedText || (checked ? checkedText : uncheckedText);

  return (
    <Flex as="label" fontSize={2} alignItems="center" height={switchHeight}>
      {text ? (
        <Text display="inline-block" fontWeight={400} color="text" mr={2}>
          {text}
        </Text>
      ) : (
        null
      )}
      <Tooltip content={switchTooltip}>
        <Box height={24}>
          <ReactSwitch
            checked={checked}
            checkedIcon={checkedIcon ?? <Yes />}
            uncheckedIcon={uncheckedIcon ?? <No />}
            onColor={colors.primary}
            offColor={colors.lightGray2}
            width={width ?? 60}
            height={24}
            handleDiameter={20}
            {...props}
          />
        </Box>
      </Tooltip>
      {appendedText ? (
        <Text display="inline-block" ml={2}>
          {appendedText}
        </Text>
      ) : (
        null
      )}
    </Flex>
  );
};
