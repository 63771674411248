import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { ContractStatus } from '@deepstream/common/contract';
import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CurrencyCodeProvider } from '@deepstream/ui/ui/Currency';
import { ContractsReportingDataContext } from '@deepstream/ui/modules/Reporting/useContractsReporting';
import * as dashboard from '@deepstream/ui/modules/Reporting/dashboard';
import { ContractStatusStatPanel } from '@deepstream/ui/modules/Reporting/ContractStatusStatPanel';
import { TotalContractsByProductOrService } from '@deepstream/ui/modules/Reporting/TotalContractsByProductOrService';
import { ContractsValueByProductOrService } from '@deepstream/ui/modules/Reporting/ContractsValueByProductOrService';
import { CounterpartyStats } from '@deepstream/ui/modules/Reporting/CounterpartyStats';
import { UserStats } from '@deepstream/ui/modules/Reporting/UserStats';
import { ExpiringContracts } from '@deepstream/ui/modules/Reporting/ExpiringContracts';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { useAdminContractsReporting } from './useAdminContractsReporting';

export const ContractsReporting = ({
  navigateToContract,
  config,
}: {
  navigateToContract: (contract: { _id: string }) => void;
  config: RequestsReportingConfig;
}) => {
  const { t } = useTranslation(['reporting', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { currency } = config;

  const { data } = useAdminContractsReporting(currentCompanyId, currency);

  const contextValue = useMemo(
    () => ({ data: data ?? {} }),
    [data],
  );

  return (
    <ContractsReportingDataContext.Provider value={contextValue}>
      <CurrencyCodeProvider code={currency}>
        <Stack gap={5} mb={4}>
          <dashboard.Section heading={t('contractVolume.heading')}>
            {data && (
              <dashboard.Row sx={{ gap: '80px' }}>
                <dashboard.Column>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="volume" />
                    <ContractStatusStatPanel type="volume" status={ContractStatus.NEGOTIATION} />
                  </dashboard.Row>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="volume" status={ContractStatus.AGREED} />
                    <ContractStatusStatPanel type="volume" status={ContractStatus.ACTIVE} />
                  </dashboard.Row>
                </dashboard.Column>
                <dashboard.Column flex={1}>
                  <TotalContractsByProductOrService />
                </dashboard.Column>
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contractValue.heading')}>
            {data && (
              <dashboard.Row sx={{ gap: '80px' }}>
                <dashboard.Column>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="value" />
                    <ContractStatusStatPanel type="value" status={ContractStatus.NEGOTIATION} />
                  </dashboard.Row>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="value" status={ContractStatus.AGREED} />
                    <ContractStatusStatPanel type="value" status={ContractStatus.ACTIVE} />
                  </dashboard.Row>
                </dashboard.Column>
                <dashboard.Column flex={1}>
                  <ContractsValueByProductOrService />
                </dashboard.Column>
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.teamProductivity')}>
            {data && (
              <dashboard.Row>
                <UserStats />
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.counterparty')}>
            {data && (
              <dashboard.Row>
                <CounterpartyStats />
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.expiringContracts')}>
            {data && (
              <dashboard.Row>
                <ExpiringContracts navigateToContract={navigateToContract} />
              </dashboard.Row>
            )}
          </dashboard.Section>
        </Stack>
        <Text fontSize={1} color="subtext" sx={{ textTransform: 'uppercase' }} mt={4}>
          {t('contractsTotalDisclaimer')}
        </Text>
      </CurrencyCodeProvider>
    </ContractsReportingDataContext.Provider>
  );
};
