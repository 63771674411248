import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { RequestTeamUser } from '../../../types';
import { UserDetails } from '../../../UserDetails';

type ConfirmRemoveUserDialogProps = Omit<DialogProps, 'heading' | 'body'> & {
  user: RequestTeamUser;
};

export const ConfirmRemoveUserDialog = ({ user, ...props }: ConfirmRemoveUserDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.team.dialog.confirmRemoveRequestUser.heading')}
      body={
        <>
          <UserDetails user={user} />
          <MessageBlock variant="info" my={3}>
            {t('request.team.dialog.confirmRemoveRequestUser.info')}
          </MessageBlock>
        </>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('teamManagement.removeUser')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel')}
      showCloseIcon
      {...props}
    />
  );
};
