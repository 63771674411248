import { MessageDto } from './message';
import { Serializer } from './transport/serializer';

export class MessageDtoSerializer implements Serializer<string, MessageDto> {
  public toInternalValue(rawMessage: string): MessageDto {
    return JSON.parse(rawMessage);
  }

  public toRepresentation(messageData: MessageDto): string {
    return JSON.stringify(messageData);
  }
}
