import { useQuery } from 'react-query';
import { ExtendedSupplierListOverview, SupplierListOverview } from '@deepstream/common';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const getSupplierListsQueryKey = (companyId: string) => ['supplierLists', { companyId }];
export const getExtendedSupplierListsQueryKey = (companyId: string) => ['extendedSupplierLists', { companyId }];

export const useSupplierLists = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: supplierLists = [], status: supplierListsStatus, refetch: refetchSupplierLists } = useQuery<SupplierListOverview[]>(
    getSupplierListsQueryKey(currentCompanyId),
    wrap(api.getSupplierLists),
    {
      staleTime: 100,
    },
  );

  return {
    supplierLists,
    supplierListsStatus,
    refetchSupplierLists,
  };
};

export const useExtendedSupplierLists = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: supplierLists = [], status: supplierListsStatus, refetch: refetchSupplierLists } = useQuery<ExtendedSupplierListOverview[]>(
    getExtendedSupplierListsQueryKey(currentCompanyId),
    wrap(api.getExtendedSupplierLists),
    {
      staleTime: 100,
    },
  );

  return {
    supplierLists,
    supplierListsStatus,
    refetchSupplierLists,
  };
};
