import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import { CellProps } from 'react-table';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { useContractsReportingData } from './useContractsReporting';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { CompanyMinimizedNameAndLogoCell } from '../../CompanyMinimizedNameAndLogoCell';
import { TruncateCell } from '../../TruncateCell';
import { Datetime2Cell } from '../../DatetimeCell';
import { NumberCell } from '../../draft/cell';
import { UsersAvatarList } from '../../UsersAvatarList';
import { useNavigate } from '../../tanstackRouter';
// import { contractLiveSummaryRoute } from '../../AppRouting';
import { useCurrentCompanyId } from '../../currentCompanyId';

const OwnersCell = ({
  row,
}: CellProps<any>) => {
  const { owners } = row.original;

  return (
    <UsersAvatarList users={owners} />
  );
};

export const ExpiringContracts = ({
  navigateToContract,
}: {
  navigateToContract: (contract) => void;
}) => {
  const { t } = useTranslation('reporting');
  const { data: { expiringContracts } } = useContractsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('contracts.contractName'),
        accessor: 'name',
        Cell: TruncateCell,
      },
      {
        id: 'counterparty',
        Header: t('contracts.counterparty'),
        accessor: 'counterpartyName',
        Cell: withProps(
          CompanyMinimizedNameAndLogoCell,
          { minHeight: '47px', height: '47px', hasLink: false, companyIdField: 'counterpartyId' },
        ),
      },
      {
        id: 'owners',
        Header: t('contracts.contractOwners'),
        accessor: 'owners',
        Cell: OwnersCell,
      },
      {
        id: 'expiryDate',
        Header: t('contracts.expiryDate'),
        accessor: 'expiryDate',
        Cell: Datetime2Cell,
        format: DateFormat.DD_MMM_YYYY,
      },
      {
        id: 'daysUntilExpiry',
        Header: t('contracts.daysUntilExpiry'),
        accessor: contract => differenceInDays(new Date(contract.expiryDate), new Date()),
        Cell: NumberCell,
      },
    ],
    [t],
  );

  return (
    <Panel>
      <BasicTableStyles>
        <Table
          isSortable
          isPaginated={expiringContracts.length > 5}
          smallPageControls
          columns={columns}
          data={expiringContracts}
          onRowClick={navigateToContract}
          noDataPlaceholder={t('contracts.noExpiringContracts')}
        />
      </BasicTableStyles>
    </Panel>
  );
};
