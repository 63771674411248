import { useMemo } from 'react';
import { flatMap } from 'lodash';
import { useQuery } from 'react-query';
import { CellProps } from 'react-table';
import { Text } from 'rebass/styled-components';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { nestCells } from '@deepstream/ui/nestCells';
import { Table } from '@deepstream/ui/Table';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useTranslation } from 'react-i18next';
import { useAdminNavigation } from './AppRouting';
import { expandByCompanies } from './utils';
import { QuestionnaireLinkCell } from './modules/PreQualification/QuestionnaireLinkCell';

type ExpandedQuestionnaire = QuestionnaireOverview & {
  _rowId: string;
  company: {
    _id: string;
    role: 'Sender' | 'Recipient';
  }
};

// Returns array of duplicated questionnaires based on the number of companies teams the `userId` belongs to
const expandQuestionnaire = (userId: string) => (questionnaire: QuestionnaireOverview) => {
  const { senders, recipient } = questionnaire;

  const senderIds = senders
    .filter(sender => sender.userIds.includes(userId))
    .map(sender => sender._id);

  const recipientIds = recipient.userIds.includes(userId) ? [recipient._id] : [];

  return [
    ...expandByCompanies(questionnaire, senderIds, 'Sender'),
    ...expandByCompanies(questionnaire, recipientIds, 'Recipient'),
  ];
};

const GetCompanyNameCell = ({ cell: { value: companyId } }: CellProps<any>) => {
  const adminApi = useAdminApi();

  const { data: name, error } = useQuery(
    ['companyName', { companyId }],
    wrap(adminApi.getCompanyName),
  );

  return (
    <Truncate>
      {error ? (
        <Text color="danger">
          <Icon icon="exclamation-circle" mr={1} />Error
        </Text>
      ) : name ? (
        <span>{name}</span>
      ) : (
        null
      )}
    </Truncate>
  );
};

type QuestionnairesTableProps = {
  userId: string;
  questionnaires: QuestionnaireOverview[];
};

export const UserQuestionnairesTable = ({ userId, questionnaires }: QuestionnairesTableProps) => {
  const { t } = useTranslation('preQualification');
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const expandedQuestionnaires = useMemo(
    () => flatMap(questionnaires, expandQuestionnaire(userId)),
    [questionnaires, userId],
  );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, QuestionnaireLinkCell),
        sortType: 'caseInsensitive',
      },
      {
        id: 'company',
        Header: 'Company',
        accessor: 'company._id',
        Cell: GetCompanyNameCell,
        width: isExtraLarge ? undefined : 250,
        sortType: 'caseInsensitive',
      },
      {
        id: 'companyRole',
        Header: 'Company role',
        accessor: 'company.role',
        width: 170,
        sortType: 'caseInsensitive',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (item: ExpandedQuestionnaire) => t(`questionnaireStatus.${item.status}`),
        sortType: 'caseInsensitive',
        width: 170,
      },
    ],
    [isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={expandedQuestionnaires}
        onRowClick={(questionnaire: ExpandedQuestionnaire) =>
          navigation.navigateToQuestionnaireCompanyUser(questionnaire._id, questionnaire.company._id, userId)
        }
        isSortable
        noFilteredDataPlaceholder="No questionnaires match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['status'] : []}
      />
    </BasicTableStyles>
  );
};
