import { SpendSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';

export const spendSectionStatusIconProps: Record<SpendSectionStatus, Partial<IconTextProps> & { icon: IconProps['icon']; }> = {
  complete: {
    icon: 'check-circle',
    isIconRegular: true,
    iconColor: 'success',
    iconFontWeight: 400,
  },
  incomplete: {
    icon: 'exclamation-circle',
    iconColor: 'danger',
  },
  disabled: {
    icon: 'ban',
    iconColor: 'subtext',
    iconFontWeight: 400,
  },
};
