import * as React from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { Bold } from './Bold';
import { ChildrenCounter, Field, Item, List, ListItemFields } from './List';
import { SearchInput } from './ui/Input';

export type SearchProps = {
  placeholder: string;
  debounceTime?: number;
  isSearching: boolean;
  canClearSearch: boolean;
  initialSearchText: string;
  label: string;
  numSelectedChildren: number;
  onSearch: (text: string) => void;
  onClearSearch: () => void;
};

export const SearchRow = ({
  label,
  placeholder,
  debounceTime = 300,
  isSearching,
  canClearSearch,
  initialSearchText,
  numSelectedChildren,
  onSearch,
  onClearSearch,
}: SearchProps) => {
  const [debouncedSearch] = useDebouncedCallback(
    onSearch,
    debounceTime,
  );

  const onChange = React.useCallback(
    (event: any) => debouncedSearch(event.target.value),
    [debouncedSearch],
  );

  const onKeyDown = React.useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        debouncedSearch(event.target.value);
      }
    },
    [debouncedSearch],
  );

  return (
    <Box
      as="li"
      px="20px"
      py="12px"
      lineHeight="normal"
      sx={{
        borderBottom: 'lightGray',
        bg: 'lightGray3',
      }}
    >
      <Flex alignItems="center">
        <Bold flex="1 1 auto">
          {label}
        </Bold>
        <Box flex="1 1 auto" ml={4}>
          <SearchInput
            placeholder={placeholder}
            isLoading={isSearching}
            canClear={canClearSearch}
            initialValue={initialSearchText}
            onChange={onChange}
            clearSearch={onClearSearch}
            onKeyDown={onKeyDown}
          />
        </Box>
        <ChildrenCounter numSelectedChildren={numSelectedChildren} ml={4} />
      </Flex>
    </Box>
  );
};

export type FilterItem = {
  _id: string;
  label: string;
  fields?: Field[];
  // Specifies if the filter item can be selected/deselected as a regular item.
  // Toggling a filter item changes the `isSelected` and `numSelectedChildren` props.
  canToggle?: boolean;
  // Specifies if the filter item was selected as regular item
  isSelected?: boolean;
  // Number of selected children (items under the filter item in the hierarchy), but also including itself.
  numSelectedChildren: number;
  // Specifies if the underlying list can be filtered by the filter item (ie select as filter).
  canSelectFilter?: boolean;
};

/**
 * Renders a list item from the filters list.
 */
export const FilterListItem = ({
  item,
  isReadOnly,
  onSelectFilter,
  onToggleItem,
}: {
  item: FilterItem;
  isReadOnly?: boolean;
  onSelectFilter: (filterId: string) => void;
  onToggleItem?: (itemid: string) => void;
}) => (
  <Flex
    as="li"
    px="20px"
    height="63px"
    alignItems="center"
    backgroundColor="lightGray3"
    lineHeight="normal"
    sx={{
      borderBottom: 'lightGray',
    }}
  >
    {item.canSelectFilter ? (
      <Button
        small
        variant="secondary-outline"
        iconLeft="chevron-left"
        width="28px"
        onClick={() => onSelectFilter(item._id)}
      />
    ) : (
      <Box width="28px" />
    )}
    <Box mx={2} flex="1 1 auto">
      <Bold>
        <Truncate>
          {item.label}
        </Truncate>
      </Bold>
      <ListItemFields fields={item.fields} />
    </Box>
    {isReadOnly ? (
      item.isSelected && (
        <Icon
          icon="check"
          color="primary"
        />
      )
    ) : item.canToggle ? (
      <Button
        small
        ml={2}
        variant={item.isSelected ? 'primary' : 'secondary-outline'}
        iconLeft={item.isSelected ? 'check' : 'plus'}
        type="button"
        flex="0 0 auto"
        onClick={() => onToggleItem?.(item._id)}
      />
    ) : (
      <ChildrenCounter numSelectedChildren={item.numSelectedChildren} />
    )}
  </Flex>
);

/**
 * Renders a `List` component together with a list of filters applied to it.
 */
export const FilteredList = ({
  items,
  filterItems,
  depth,
  isLoading,
  isError,
  isReadOnly,
  errorText,
  emptyListText,
  sx,
  header,
  onToggleItem,
  onSelectFilter,
  onRetry,
  showSearchResults,
}: {
  items: Item[];
  filterItems: FilterItem[];
  depth: number;
  canOnlyDeselect?: boolean;
  isLoading: boolean;
  isError: boolean;
  isReadOnly?: boolean;
  errorText?: string;
  emptyListText?: string;
  sx?: any;
  header: React.ReactElement;
  onToggleItem: (itemId: string) => void;
  onSelectFilter: (filterId: string | null) => void;
  onRetry: () => void;
  showSearchResults?: boolean;
}) => {
  const { t } = useTranslation();
  const hasFilter = Boolean(filterItems.length);

  return (
    <>
      <Box
        as="ul"
        sx={{
          listStyle: 'none',
          padding: 0,
        }}
      >
        {header}
        {filterItems.map((item) => (
          <FilterListItem
            key={item._id}
            item={item}
            isReadOnly={isReadOnly}
            onSelectFilter={() => onSelectFilter(item._id)}
            onToggleItem={onToggleItem}
          />
        ))}
      </Box>
      {isLoading ? (
        <Box p="20px" pl={hasFilter ? '80px' : '20px'}>
          <Loading />
        </Box>
      ) : isError ? (
        <Box p="20px" pl={hasFilter ? '80px' : '20px'}>
          <Flex justifyContent="space-between" alignItems="center">
            <ErrorMessage error={errorText || t('errors.unexpected')} fontSize={2} />
            <Button small variant="secondary-outline" iconLeft="refresh" onClick={onRetry}>
              {t('general.retry')}
            </Button>
          </Flex>
        </Box>
      ) : items.length ? (
        <List
          items={items}
          depth={depth}
          padded={Boolean(filterItems.length)}
          isReadOnly={isReadOnly}
          isSearching={showSearchResults}
          sx={sx}
          onToggleItem={onToggleItem}
          onSelectFilter={onSelectFilter}
        />
      ) : emptyListText ? (
        <Box p="20px">
          {emptyListText}
        </Box>
      ) : (
        null
      )}
    </>
  );
};
