import * as React from 'react';
import { Formik, Form } from 'formik';
import { noop } from 'lodash';
import * as yup from 'yup';
import { SpaceProps } from 'styled-system';
import { Box, FlexProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { FormGroup } from './ui/FormGroup';
import { TextField } from './form/TextField';

type FindFormProps =
  SpaceProps &
  Omit<FlexProps, 'onSubmit'> &
  {
    text?: string;
    disabled?: boolean;
    onSubmit?: (result: any) => void;
    placeholder?: string;
    inputStyle?: React.CSSProperties;
    buttonProps?: Partial<ButtonProps>;
  };

export const SearchForm: React.FC<FindFormProps> = ({ text = '', disabled = false, onSubmit = noop, placeholder, inputStyle = {}, buttonProps = {}, ...props }: FindFormProps) => {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [globalError, setGlobalError] = React.useState('');

  React.useEffect(
    () => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef],
  );

  return (
    <Box {...props}>
      <Formik
        initialValues={{
          text,
        }}
        validationSchema={
          yup.object().shape({
            text: yup.string().trim().default(''),
          })
        }
        onSubmit={async ({ text }, { setSubmitting }) => {
          try {
            onSubmit(text);
          } catch (error: any) {
            setGlobalError(error.toString());
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormGroup>
              <Box width="100%">
                <TextField
                  name="text"
                  inputStyle={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', ...inputStyle }}
                  inputType="search"
                  placeholder={placeholder ?? t('network.searchForACompanyName')}
                  error={globalError}
                  ref={inputRef}
                />
              </Box>
              <Button
                type="submit"
                variant="primary"
                disabled={disabled || isSubmitting}
                width={50}
                iconLeft="search"
                {...buttonProps}
              />
            </FormGroup>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
