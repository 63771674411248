import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import * as React from 'react';

export class ErrorBoundary extends React.Component<
  { message?: string; children: React.ReactNode },
  { error: Error | null }
> {
  constructor(props: any) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  override render() {
    if (this.state.error) {
      return (
        <ErrorMessage
          error={this.props.message ?? 'An unexpected error has occurred'}
          fontSize={2}
          gap={2}
        />
      );
    }

    return <>{this.props.children}</>;
  }
}
