import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { MenuLink } from '@reach/menu-button';
import { motion } from 'framer-motion';

import { useSession } from '@deepstream/ui/auth';
import DeepStreamLogo from '@deepstream/ui/DeepStreamLogo';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { UnstyledLink } from '@deepstream/ui/ui/UnstyledLink';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { NAVBAR_HEIGHT } from '@deepstream/ui/constants';
import { TaskCounter } from '@deepstream/ui/TaskCounter';
import { useOpenState } from '@deepstream/ui-kit/hooks/useOpenState';
import { MOBILE_MENU_ITEM_HEIGHT, MobileMenu, MobileNavButton } from '@deepstream/ui/page-helpers/MobileNav';
import { QuickFind } from './QuickFind';
import { CreateUserModal } from './CreateUserModal';
import { CreateCompanyModal } from './CreateCompanyModal';
import { indexRoute, useAdminNavigation } from './AppRouting';

const HeaderContainer = styled(Flex)`
  color: white;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 500;
  line-height: 18px;
  height: 65px;
  width: 100%;
  padding: 0 30px;
`;

type NavButtonProps = ButtonProps & {
  icon?: IconProps['icon'];
};

const NavButton: React.FC<NavButtonProps> = ({ icon, children, ...props }) => {
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isMobile = isSmall || isExtraSmall;

  return (
    <Button
      px={isMobile ? '5px' : '12px'}
      height="100%"
      sx={{ position: 'relative', cursor: 'pointer' }}
      variant="wrapper"
      {...props}
    >
      <Flex alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
        {icon && <Icon fontSize={4} icon={icon} regular mr={2} />}
        {children}
      </Flex>
    </Button>
  );
};

export const NewEntityDropdown = ({ isExtraSmall }: { isExtraSmall: boolean }) => {
  const createUserModal = useModalState();
  const createCompanyModal = useModalState();
  const { t } = useTranslation();

  return (
    <Box>
      <DropdownMenu
        variant="wrapper"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        buttonText={(
          <Flex>
            <Icon fontSize={5} icon="plus" mr={2} />
            {!isExtraSmall && <Text>{t('general.new')}</Text>}
          </Flex>
        )}
        menuZIndex={2}
      >
        <DropdownMenuItem icon="building" onSelect={createCompanyModal.open}>
          {t('general.company')}
        </DropdownMenuItem>
        <DropdownMenuItem icon="user" onSelect={createUserModal.open}>
          {t('general.user', { count: 1 })}
        </DropdownMenuItem>
      </DropdownMenu>
      <CreateCompanyModal
        onCancel={createCompanyModal.close}
        onSave={createCompanyModal.close}
        isOpen={createCompanyModal.isOpen}
      />
      <CreateUserModal
        onCancel={createUserModal.close}
        onSave={createUserModal.close}
        isOpen={createUserModal.isOpen}
      />
    </Box>
  );
};

const AdminDropdownMenuItem = styled(DropdownMenuItem)`
  width: 150px;
  height: 35px;
  padding: 8px 12px !important;
`;

export const Header = React.memo(() => {
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isMobile = isSmall || isExtraSmall;
  const mobileMenu = useOpenState();
  const { logout } = useSession();
  const navigation = useAdminNavigation();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HeaderContainer as="header" justifyContent="space-between" alignItems="center" bg="#333C4E">
      <Flex alignItems="center">
        {isExtraSmall && (
          <NavButton icon="navicon" onClick={mobileMenu.toggle} mr={1} />
        )}
        <Link to={indexRoute.to} style={{ height: '24px' }}>
          <DeepStreamLogo variant="white" />
        </Link>
        {!isExtraSmall && (
          <Heading as="h1" fontSize="22px" fontWeight={500} ml={2} color="subtext">
            Admin
          </Heading>
        )}
        {isMobile ? (
          mobileMenu.isOpen && (
            <motion.div
              key="mobileMenu"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: `${MOBILE_MENU_ITEM_HEIGHT * 3 + 4}px` },
                collapsed: { height: 0 },
              }}
              style={{
                position: 'fixed',
                top: NAVBAR_HEIGHT,
                left: 0,
                right: 0,
                zIndex: 102,
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <MobileMenu onClick={mobileMenu.toggle} height="100%" overflow="hidden">
                <MobileNavButton
                  icon="tachometer"
                  iconStyleModifier={{ regular: true }}
                  onClick={() => navigation.navigateToRecentActivity()}
                >
                  Activity
                  <TaskCounter filter={[{ type: 'decideApproval' }]} ml={2} />
                </MobileNavButton>
                <MobileNavButton
                  icon="rocket"
                  iconStyleModifier={{ regular: true }}
                  onClick={() => navigation.navigateToDashboard()}
                >
                  Dashboard
                </MobileNavButton>
                <MobileNavButton
                  icon="chart-line"
                  iconStyleModifier={{ regular: true }}
                  onClick={() => navigation.navigateToReports()}
                >
                  Reporting
                </MobileNavButton>
              </MobileMenu>
            </motion.div>
          )
        ) : (
          <>
            <NavButton ml={4} pl={0} variant="wrapper" icon="tachometer" onClick={() => navigation.navigateToRecentActivity()}>
              Activity
              <TaskCounter filter={[{ type: 'decideApproval' }]} ml={2} />
            </NavButton>
            <NavButton variant="wrapper" icon="rocket" onClick={() => navigation.navigateToDashboard()}>
              Dashboard
            </NavButton>
            <NavButton variant="wrapper" icon="chart-line" onClick={() => navigation.navigateToReports()}>
              Reporting
            </NavButton>
          </>
        )}
      </Flex>

      <Flex alignItems="center">
        <NewEntityDropdown isExtraSmall={isExtraSmall} />
        <QuickFind />
        <DropdownMenu
          variant="wrapper"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          menuZIndex={2}
          buttonText={<Icon fontSize={5} icon="ellipsis-h" />}
          menuStyle={{ width: '200px' }}
        >
          <AdminDropdownMenuItem icon="users" onSelect={() => navigation.navigateToAdminTeam()}>
            Team
          </AdminDropdownMenuItem>
          <DropdownMenuDivider />
          <AdminDropdownMenuItem icon="cog" onSelect={() => navigation.navigateToConfig()}>
            Configuration
          </AdminDropdownMenuItem>
          <AdminDropdownMenuItem icon="toolbox" onSelect={() => navigation.navigateToTools()}>
            Tools
          </AdminDropdownMenuItem>
          <DropdownMenuDivider />
          <AdminDropdownMenuItem onSelect={logout}>
            Sign out
          </AdminDropdownMenuItem>
          <DropdownMenuDivider />
          <MenuLink
            as={UnstyledLink}
            style={{ display: 'block' }}
            href={`${process.env.NX_APP_URL}/network`}
          >
            DeepStream app
          </MenuLink>
        </DropdownMenu>
      </Flex>
    </HeaderContainer>
  );
});

Header.displayName = 'Header';
