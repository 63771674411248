import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { flatMap, upperFirst } from 'lodash';
import { CellProps } from 'react-table';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { nestCells } from '@deepstream/ui/nestCells';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ContractOverview } from '@deepstream/common/contract';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { ContractLinkCell } from './ContractLinkCell';
import { CompanyLinkCell } from '../../CompanyLinkCell';
import { RequestLink } from '../../RequestLink';
import { useAdminNavigation } from '../../AppRouting';
import { expandByCompanies } from '../../utils';

type ContractsTableProps = {
  userId: string;
  contractOverviews: ContractOverview[];
};

// Returns array of duplicated contracts based on the number of companies teams the `userId` belongs to
const expandContract = (userId: string) => (contract: ContractOverview) => {
  const { senders, recipient } = contract;

  const senderIds = senders
    .filter(sender => sender.userIds.includes(userId))
    .map(sender => sender._id);

  const recipientIds = recipient?.userIds.includes(userId) ? [recipient._id] : [];

  return [
    ...expandByCompanies(contract, senderIds, 'Sender'),
    ...expandByCompanies(contract, recipientIds, 'Recipient'),
  ];
};

export const LinkedRequestCell = ({ row, cell }: CellProps<ContractOverview>) => {
  const contractOverview = row.original;
  const { requestId } = contractOverview;

  return requestId ? (
    <RequestLink requestId={requestId}>
      {cell.value}
    </RequestLink>
  ) : (
    <EmDash />
  );
};

const getCreatorCompanyId = (contractOverview: ContractOverview) => contractOverview.senders[0]._id;

export const AdminUserContractsTable = ({ userId, contractOverviews }: ContractsTableProps) => {
  const { t } = useTranslation('contracts');
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const expandedContracts = useMemo(
    () => flatMap(contractOverviews, expandContract(userId)),
    [contractOverviews, userId],
  );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, ContractLinkCell),
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Company',
        accessor: 'company._id',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId: getCreatorCompanyId })),
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        width: isExtraLarge ? undefined : 250,
      },
      {
        id: 'companyRole',
        Header: 'Company role',
        accessor: 'company.role',
        Cell: TruncateCell,
        width: 170,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ status }) => t(`status.${status}`),
        width: 160,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
      },
      {
        id: 'linkedRequest',
        Header: 'Linked request',
        accessor: 'requestSubject',
        Cell: LinkedRequestCell,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [isExtraLarge, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        isSortable
        columns={columns}
        data={expandedContracts}
        onRowClick={(contract) =>
          contract._id && navigation.navigateToContractCompanyUser(contract._id, contract.company._id, userId)
        }
        noFilteredDataPlaceholder="No contracts match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['status', 'linkedRequest'] : []}
      />
    </BasicTableStyles>
  );
};
