import styled from 'styled-components';

export const InlineButton = styled('button')`
  color: ${props => props.theme.colors.primary};
  font: inherit;
  font-size: inherit;
  background: inherit;
  line-height: inherit;
  font-weight: 500;
  display: inline;
  border: 0;
  padding: 0;
  cursor: pointer;
`;
