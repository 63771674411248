import { useMemo } from 'react';
import { find } from 'lodash';
import { SectionType } from '@deepstream/common/rfq-utils';
import * as rfx from '../../rfx';

export const useBulletinSection = () => {
  const { sectionById } = rfx.useStructure();
  return useMemo(
    () => find(sectionById, { type: SectionType.BULLETINS }),
    [sectionById],
  );
};
