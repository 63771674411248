// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgDanger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={67}
    height={67}
    fill="none"
    {...props}
  >
    <path
      fill="#C86464"
      d="M28.5 3.598a9 9 0 0 1 9 0l19.146 11.054a9 9 0 0 1 4.5 7.794v22.108a9 9 0 0 1-4.5 7.794L37.5 63.402a9 9 0 0 1-9 0L9.354 52.348a9 9 0 0 1-4.5-7.794V22.446a9 9 0 0 1 4.5-7.794z"
    />
    <g
      stroke="#F7F9FB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
    >
      <path d="M40.25 27.578 27.828 40M26.315 27.578 38.736 40" />
    </g>
  </svg>
);
export default SvgDanger;
