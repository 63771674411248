import { useMemo } from 'react';
import {
  getPagesInDisplayOrder,
  isLinkedEvaluationPage,
  SectionType,
} from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Text, Box, Flex } from 'rebass/styled-components';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@deepstream/ui/ui/Tabs';
import { LockedTooltip } from '@deepstream/ui/LockedTooltip';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import * as rfx from '@deepstream/ui/rfx';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { AdminLineItemsSection } from './AdminLineItemsSection';
import { AdminDocumentSection } from './AdminDocumentSection';
import { AdminEvaluationSection } from './AdminEvaluationSection';
import { AdminQuestionsSection } from './AdminQuestionsSection';
import { AdminEvaluationSectionHeaderItems } from './AdminEvaluationTable';

export const Subtext = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: 14px;
  line-height: 18px;
`;

const SectionHeader = styled(Flex)`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
`;

export const iconsBySectionType = {
  [SectionType.DOCUMENT]: {
    icon: 'copy',
    regular: true,
  },
  [SectionType.LINE_ITEMS]: {
    icon: 'list-ul',
  },
  [SectionType.VESSEL_PRICING]: {
    icon: 'ship',
  },
  [SectionType.DELIVERY]: {
    icon: 'truck',
  },
  [SectionType.PAYMENT]: {
    icon: 'calendar-alt',
    regular: true,
  },
  [SectionType.CHAT]: {
    icon: 'comment',
    regular: true,
  },
  [SectionType.CLARIFICATIONS]: {
    icon: 'comment',
    regular: true,
  },
  [SectionType.EVALUATION]: {
    icon: 'balance-scale',
  },
  [SectionType.QUESTION]: {
    icon: 'question',
  },
  [SectionType.CONTRACT]: {
    icon: 'file-contract',
  },
} as const;

export const RequestSection = ({
  isLast,
}: {
  isLast?: boolean;
}) => {
  const { senders } = rfx.useStructure();
  const section = rfx.useSectionWithPosition();

  const sectionIcon: { icon: IconProps['icon']; regular?: boolean } =
    iconsBySectionType[section.type as keyof typeof iconsBySectionType];

  return (
    <Box p={3} pb={isLast ? 3 : 4}>
      <SectionHeader pb={2}>
        <Flex alignItems="center">
          <Icon
            regular={sectionIcon?.regular}
            icon={sectionIcon?.icon}
            style={{ fontSize: '16px', marginRight: '5px', width: '20px', textAlign: 'center' }}
          />
          {section.type === SectionType.EVALUATION ? (
            `${section.number} `
          ) : (
            null
          )}
          {section.name}
        </Flex>
        <Box>
          {Boolean(section.locking) && (
            <LockedTooltip lock={section.locking} senders={senders}>
              <div style={{ display: 'inline-block' }}>
                <Icon
                  style={{ fontSize: '14px', marginLeft: 10 }}
                  icon="lock"
                  aria-hidden
                />
              </div>
            </LockedTooltip>
          )}
          {section.type === SectionType.EVALUATION && (
            <AdminEvaluationSectionHeaderItems />
          )}
        </Box>
      </SectionHeader>
      {section.type === SectionType.DOCUMENT ? (
        <AdminDocumentSection isLast={isLast} />
      ) : section.type === SectionType.LINE_ITEMS ? (
        <AdminLineItemsSection isLast={isLast} />
      ) : section.type === SectionType.EVALUATION ? (
        <AdminEvaluationSection isLast={isLast} />
      ) : section.type === SectionType.QUESTION ? (
        <AdminQuestionsSection isLast={isLast} />
      ) : (
        <Subtext>See debug for more details</Subtext>
      )}
    </Box>
  );
};

export const RequestDetails = () => {
  const { pages, sectionById } = rfx.useStructure();

  const orderedPages = useMemo(
    () => getPagesInDisplayOrder(pages).filter(page => !isLinkedEvaluationPage(page)),
    [pages],
  );

  return orderedPages.length
    ? (
      <rfx.EvaluationWeightsProvider>
        <Tabs>
          <TabList>
            {orderedPages.map(page => (
              <Tab key={page._id}>{page.name}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {orderedPages.map(page => (
              <TabPanel key={page._id}>
                <rfx.PageProvider page={page}>
                  {page.sections.map((sectionId, index) => {
                    const section = sectionById[sectionId];

                    return section ? (
                      <rfx.SectionProvider section={section} key={sectionId}>
                        <RequestSection isLast={index === page.sections.length - 1} />
                      </rfx.SectionProvider>
                    ) : (
                      <Box p={3} key={sectionId}>
                        <ErrorMessage error={`No section found with id ${sectionId}`} />
                      </Box>
                    );
                  })}
                </rfx.PageProvider>
                {!page.sections.length && (
                  <PanelPadding>
                    <Subtext>No sections yet</Subtext>
                  </PanelPadding>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </rfx.EvaluationWeightsProvider>
    )
    : (
      <Subtext p={16}>No pages yet</Subtext>
    );
};
